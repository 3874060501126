<template>
    <div>
        <sui-modal
            size="small"
            id="image-selector-modal"
            :open="isOpen"
            v-on:clickAwayModal="clickAway()"
        >
            <sui-modal-header>
                {{ modalHeader }}
            </sui-modal-header>
            <sui-modal-content scrolling style="max-height: 70svh;">

                <image-gallery
                    :baseFilePath="baseFilePath"
                    :isOpen="isOpen"
                    @select-image="selectImage"
                />

            </sui-modal-content>

        </sui-modal>
       
    </div>                               
</template>

<script>

import ImageGallery from './ImageGallery.vue'
export default {
    name: 'ImageSelectorModal',

    components: {
        ImageGallery
    },

    props: {
        isOpen: Boolean,
        baseFilePath: {
            type: String,
            required: true
        },
        modalHeader: {
            type: String,
            default: 'Vælg et billede'
        }
    },

    methods: {  
        clickAway() {
            this.$emit('close');
        },

        selectImage(imageRef) {
            this.$emit('select-image', imageRef);
            this.clickAway();
        },
    },
}
</script>

<style scoped>

</style>