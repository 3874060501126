<template>
    <div class="gallery">
        <div class="gallery-grid" ref="galleryGrid" v-if="imageRefs.length">
            <ImageCard
                v-for="imageRef in imageRefs"
                :key="imageRef.fullPath"
                :imageRef="imageRef"
                @select-image="selectImage"
            />
        </div>
        <div v-else>
            <p>Ingen billeder fundet</p>
        </div>
    </div>                             
</template>

<script>

import { storageRef } from '../../firebase'
import ImageCard from './ImageCard.vue'

export default {
    name: 'ImageGallery',

    components: {
        ImageCard
    },

    props: {
        isOpen: Boolean,
        baseFilePath: String,
    },

    data() {
        return {
            imageRefs: [],
            imagesLoadedCount: 0,
        }
    },

    methods: {
        fetchImageRefs() {
            storageRef.child(this.baseFilePath).listAll()
            .then((result) => {
                this.imageRefs = result.items;
            })
            .catch((error) => {
                console.error('Error fetching image refs: ', error);
            })
        },

        selectImage(imageRef) {
            this.$emit('select-image', imageRef);
        },

    },

    watch: {
        isOpen: {
            handler(open){
                if (open) {
                    this.fetchImageRefs()
                }
            }
        },
    }
}
</script>

<style scoped>
.gallery {
    padding: 10px;
}

.gallery-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 10px !important;
}

@media (max-width: 600px) {
    .gallery-grid {
        grid-template-columns: 1fr;
    }
}
</style>