<template>
    <div>
        <div class="ui negative message">
            <div class="header">
                Warning!
            </div>
            <p>
                Modifies appointments, and uses one read and one write for every active appointment in the database
            </p>
        </div>

        <strong>Description:</strong>
        <p>
            Will loop through appointments, and find the appropriate AppointmentType, and TaskRoles, to add to that appointment
        </p>

        <hr />

        <sui-button primary size="tiny" :loading="isRunning" :disabled="isRunning" @click="triggerDry">Run Dry</sui-button>
        <sui-button negative size="tiny" :loading="isRunning" :disabled="isRunning" @click="triggerNormal">Run</sui-button>
    </div>
</template>
<script>
import { db } from '../../../firebase.js'
import { DataAPI } from '../../../lib/DataAPI.js'
import TaskCode from '../../../lib/Enums/TaskCode.js'
import AppointmentType from '@/lib/Enums/AppointmentType.js'

export default {
    name: 'MultipleAppointmentsMigration',

    mixins: [DataAPI],

    enums: {
        TaskCode, 
        AppointmentType
    },

    data() {
        return {
            isRunning: false,
            dryRun: false,

            appointments: [],
        }
    },

    methods: {
        log(message) {
            this.$emit('log', message)
        },

        async triggerDry() {
            this.dryRun = true
            await this.trigger()
        },

        async triggerNormal() {
            this.dryRun = false
            await this.trigger()
        },

        async trigger() {
            this.isRunning = true
            this.log(`----------------`)
            this.log(`Starting process`)

            await db.collection('Appointments').where('State', '==', 'active').orderBy('TimeWindowString', 'desc').get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    this.appointments.push({
                        ...doc.data(),
                        id: doc.id
                    })
                })
            }).catch((error) => {
                this.log(`ERROR getting appointments: ${error}`)
            })
            let appointmentsLength = this.appointments.length
            this.log(`Got ${appointmentsLength} active appointments from Firebase`)

            let counter = 0
            var errorCount = 0
            this.log(`-----Starting loop-----`)
            for (let app of this.appointments) {
                counter += 1
                this.log(`---Appointment ${counter}/${appointmentsLength} id: ${app.id}---`)

                //FIND THE RELEVANT APPOINTMENT-TYPE(S)
                const taskCodes = app.ProjectTasks.reduce((arr, task) => {
                    return [...arr, task.Code]
                }, [])
                const possibleTypes = AppointmentType.appointmentTypesFromTaskCodes(taskCodes)
                const typeCount = possibleTypes.length
                const typeGuess = possibleTypes[0] //TODO: Make a better guess
                this.log(`The following ${typeCount} AppointmentType(s) are valid: ${possibleTypes.join(', ')}\nGuessing the type is ${typeGuess}`)

                if (!typeGuess) {
                    this.log(`ERROR: Coud not guess AppointmentType for task codes: ${taskCodes.join(', ')}`) //TODO: Get tasks from PilotBI, to try to fix the error by getting the missing task(s)
                    errorCount += 1
                    continue;
                }

                let updateDoc = {
                    AppointmentType: typeGuess,
                    ProjectTasks: [],
                }

                let taskModel = this.AppointmentType.AppointmentTasks[typeGuess] //The model describes the kinds of tasks expected to be part of this type of appointment

                let bookTask = taskModel.Book ? this.cloneJson(app.ProjectTasks.find((task) => (task.Code || this.TICKET) == taskModel.Book.TaskCode)) : null // If the model specifies a booking task, find that task in app.ProjectTasks
                if (bookTask) {
                    bookTask.Role = AppointmentType.TaskRoles.Book //Add the role to the task
                    updateDoc.ProjectTasks.push(bookTask)
                }

                let primaryTask = taskModel.Primary ? this.cloneJson(app.ProjectTasks.find((task) => (task.Code || this.TICKET) == taskModel.Primary.TaskCode)) : null // If the model specifies a primary task, find that task in taskList
                if (primaryTask) {
                    primaryTask.Role = AppointmentType.TaskRoles.Primary
                    updateDoc.ProjectTasks.push(primaryTask)
                }

                let otherTasks = app.ProjectTasks.filter((task) => (task.Id != bookTask?.Id && task.Id != primaryTask?.Id))
                for (let task of otherTasks) {
                    task.Role = AppointmentType.TaskRoles.Other,
                    updateDoc.ProjectTasks.push(task)
                }

                if (this.dryRun){
                    this.log(`DryRun preventet writing to document '${app.id}': ${JSON.stringify(updateDoc)}`)
                } else {
                    await db.collection('Appointments').doc(app.id).update(updateDoc).then(() => {
                        this.log(`Sucessfully wrote to doc '${app.id}': ${JSON.stringify(updateDoc)}`)
                    }).catch((error) => {
                        this.log(`ERROR writing to doc '${app.id}': ${JSON.stringify(error)}\nShould have written: ${JSON.stringify(updateDoc)}`)
                    })
                }
            }

            this.log('----------------')
            this.log(`FINISHED looping through ${counter}/${appointmentsLength} appointments with ${errorCount} errors.`)
            this.log('----------------')
            this.isRunning = false
        },
    }
}
</script>