<template>
    <sui-modal mini :open="isOpen" v-on:clickAwayModal="closeModal()">
        <sui-modal-header>
            <h1>Installationsopslag for Installation {{installationLabel}}</h1>
        </sui-modal-header>
        <sui-modal-content v-if="!!installationLabel" scrolling class="centered" style="max-height: 65vh;">
            <installation-check-content
                :installationLabel="installationLabel"
            />
        </sui-modal-content>
        <sui-modal-actions>
            <sui-button
                negative
                @click="closeModal()"
            >
                Luk
            </sui-button>
        </sui-modal-actions>
    </sui-modal>
</template>
<script>
import { Mixin } from '../../lib/Mixins/mixin'
import EventBus from '../../EventBus'
import { DataAPI } from '../../lib/DataAPI'
import InstallationCheckContent from '../../pages/InstallationCheckContent.vue'

export default {
    name: 'InstallationsopslagModal',
    mixins: [Mixin, EventBus, DataAPI],
    components: {
        InstallationCheckContent,
    },

    props: {
        installationLabel: {
            type: String,
            requred: true,
        },
        isOpen: {
            type: Boolean,
            requred: true,
            default: false,
        },
    },

    methods: {
        closeModal() {
            EventBus.$emit('port-check-modal-close')
        },
    },
}
</script>