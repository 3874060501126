<template>
    <div class="illnessStatsFitter" :id="`illnessStatsFitter-${fitterIllness ? fitterIllness.fitterSalaryID : 'undefined'}`">
        <div class="row">
            <div class="col-sm-4">
                <Bar
                    :chart-id="`chart-illnessHoursByWeekday-${fitterIllness ? fitterIllness.fitterSalaryID : 'undefined'}`" 
                    :chart-data="fitterIllnessByWeekday" 
                    :chart-options="illnessChartOptions"
                />
            </div>
            <div class="col-sm-4">
                <Bar
                    :chart-id="`chart-illnessHoursByWeekday-${fitterIllness ? fitterIllness.fitterSalaryID : 'undefined'}`" 
                    :chart-data="fitterIllnessByMonth" 
                    :chart-options="illnessChartOptions"
                />
            </div>
            <div class="col-sm-4" v-if="fitterIllness">
                <sui-statistics-group horizontal>
                    <sui-statistic in-group>
                        <sui-statistic-value>{{ fitterIllness.totalIllnessHours }}</sui-statistic-value>
                        <sui-statistic-label>Sygetimer</sui-statistic-label>
                    </sui-statistic>
                    <sui-statistic in-group v-if="typeof fitterIllness.illnessHoursPercentage != 'undefined'">
                        <sui-statistic-value>{{ fitterIllness.illnessHoursPercentage.toFixed(1) }} %</sui-statistic-value>
                        <sui-statistic-label>Sygetimer</sui-statistic-label>
                    </sui-statistic>
                    <sui-statistic in-group>
                        <sui-statistic-value>{{ fitterIllness.totalIllnessDays }}</sui-statistic-value>
                        <sui-statistic-label>Syge dage</sui-statistic-label>
                    </sui-statistic>
                    <sui-statistic in-group v-if="typeof fitterIllness.illnessDaysPercentage != 'undefined'">
                        <sui-statistic-value>{{ fitterIllness.illnessDaysPercentage.toFixed(1) }} %</sui-statistic-value>
                        <sui-statistic-label>Syge dage</sui-statistic-label>
                    </sui-statistic>
                    <sui-statistic in-group>
                        <sui-statistic-value>{{ fitterIllness.totalHours }}</sui-statistic-value>
                        <sui-statistic-label>Timer totalt</sui-statistic-label>
                    </sui-statistic>
                </sui-statistics-group>
            </div>
        </div>
        <!-- <sui-table>
            <sui-table-header>
                <sui-table-row>
                    <sui-table-header-cell>Dato</sui-table-header-cell>
                    <sui-table-header-cell>Timer</sui-table-header-cell>
                    <sui-table-header-cell>Løndel</sui-table-header-cell>
                    <sui-table-header-cell>Sag</sui-table-header-cell>
                    <sui-table-header-cell>Evt note</sui-table-header-cell>
                </sui-table-row>
            </sui-table-header>
            <sui-table-body>
                <sui-table-row v-for="illness of fitterIllness" :key="illness.id">
                    <sui-table-cell>{{ toUserFriendlyDate(illness.Date) }}</sui-table-cell>
                    <sui-table-cell>{{ illness.Hours }}</sui-table-cell>
                    <sui-table-cell>{{ illness.CategoryName }}</sui-table-cell>
                    <sui-table-cell>{{ illness.ProjectDescription }}</sui-table-cell>
                    <sui-table-cell>{{ illness.Note }}</sui-table-cell>
                </sui-table-row>
            </sui-table-body>
        </sui-table> -->
    </div>
</template>
<script>
import { DateMixin } from '../../lib/Mixins/dateMixin'
// import PureVueChart from 'pure-vue-chart';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
import { Bar } from 'vue-chartjs/legacy'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
    name: 'illnessStatsFitter',
    components: {
        Bar,
    },
    mixins: [DateMixin],

    props: {
        fitterIllness: Object,
        dataIsReady: Boolean,
    },

    data(){
        return {
            illnessChartOptions: {
                responsive: true,
                scales: {
                    y: {
                        type: 'linear',
                        display: true,
                        position: 'left',
                    },
                    y1: {
                        type: 'linear',
                        display: true,
                        position: 'right',
                        grid: {
                            drawOnChartArea: false,
                        },
                    },
                },
            },
        }
    },

    computed: {
        fitterIllnessByWeekday() {
            if (!this.fitterIllness?.illnessHoursByWeekday || !this.fitterIllness?.illnessHoursPercentageByWeekday) return {datasets: [], labels: []}
            let tempObj = {
                datasets: [
                    { label: 'Sygdoms %', data: this.fitterIllness.illnessHoursPercentageByWeekday.map((val, i) => {return {x: i, y: val}}), backgroundColor: '#d69d56', yAxisID: 'y' },
                    { label: 'Sygdoms timer', data: this.fitterIllness.illnessHoursByWeekday.map((val, i) => {return {x: i, y: val}}), backgroundColor: '#ebd4be', yAxisID: 'y1' },
                ],
                labels: ['man', 'tirs', 'ons', 'tors', 'fre', 'lør', 'søn'],
            }
            if (!tempObj.datasets[1].data[6]?.y || tempObj.datasets[1].data[6].y == 0) {//Remove sunday if no hours
                tempObj.datasets[0].data.splice(6)
                tempObj.datasets[1].data.splice(6)
                tempObj.labels.splice(6)
            }
            if (!tempObj.datasets[1].data[5]?.y || tempObj.datasets[1].data[5].y == 0) {//Remove saturday if no hours
                tempObj.datasets[0].data.splice(5)
                tempObj.datasets[1].data.splice(5)
                tempObj.labels.splice(5)
            }
            // console.log(tempObj)
            return tempObj
        },
        fitterIllnessByMonth() {
            if (typeof this.fitterIllness?.statsByMonth?.[0]?.illnessHoursPercentage == 'undefined' && typeof this.fitterIllness?.statsByMonth?.[0]?.illnessHours == 'undefined') return {datasets: [], labels: []}
            let tempObj = {
                datasets: [
                    { label: 'Sygdoms %', data: this.fitterIllness.statsByMonth.map(month => month.illnessHoursPercentage).map((val, i) => {return {x: i, y: val}}), backgroundColor: '#d69d56', yAxisID: 'y' },
                    { label: 'Sygdoms timer', data: this.fitterIllness.statsByMonth.map(month => month.illnessHours).map((val, i) => {return {x: i, y: val}}), backgroundColor: '#ebd4be', yAxisID: 'y1' },
                ],
                labels: this.fitterIllness.statsByMonth.map(month => month.label),
            }
            return tempObj
        },
        containerWidth() {
            return document?.getElementById(`illnessStatsFitter-${this.fitterIllness ? this.fitterIllness.fitterSalaryID : 'undefined'}`)?.offsetWidth || window.innerWidth*0.8
        },
        graphWidth() {
            // if (!this.dataIsReady) return 300
            console.log(document.getElementsByClassName('col-sm-4')[0]?.offsetWidth - 50)
            return document.getElementsByClassName('col-sm-4')[0]?.offsetWidth - 50 || 300
            // return (this.containerWidth / 3) - 20
        },
    },
}
</script>
<style scoped>
    .graph-col {
        width: 100%;
    }
</style>