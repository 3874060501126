<template>
    <div>
        <portal to="page-title">
            <sui-button icon="arrow left" @click="up" />
            <span v-if="configurationItem.address != null">{{this.formatAddress(this.configurationItem.address, false)}} &rarr; <span v-if="task">{{task.number}}</span></span><span v-else-if="configurationItem">{{configurationItem.label}}</span>
        </portal>
        <portal to="semantic-ui-modal">
            <sui-modal small v-model="noteModalOpen">
                <sui-modal-header>
                    Ny Note
                </sui-modal-header>
                <sui-modal-content>
                    <sui-form :success="noteFormSuccess" @submit.prevent="submitNote" :loading="noteFormLoading">
                        <sui-form-fields inline>
                            <label>Intern eller ekstern note?</label>
                            <sui-form-field>
                                <sui-checkbox label="Intern" radio value="Intern" v-model="noteType"/>
                            </sui-form-field>
                            <sui-form-field>
                                <sui-checkbox label="Ekstern" radio value="Ekstern" v-model="noteType"/>
                            </sui-form-field>
                        </sui-form-fields>
                        <sui-message> Eksterne noter skrives på sagen i PilotBI, Interne noter kan kun ses gennem ftth.fiberlan.dk <br> Der skrives automatisk navn på alle noter <br> Noter der er oprettet kan ikke slettes </sui-message>
                        <sui-form-field>
                            <label>Note tekst</label>
                            <textarea rows="5" v-model="noteText"></textarea>
                        </sui-form-field>
                        <sui-button type="submit" :disabled="!noteType">Gem</sui-button>
                    </sui-form>
                </sui-modal-content>
            </sui-modal>
            <sui-modal small v-model="uploadFileModalOpen">
                <sui-modal-header>
                    Vedhæft fil
                </sui-modal-header>
                <sui-modal-content>
                    <sui-form :success="fileFormSuccess" @submit.prevent="submitFile" :loading="fileFormLoading">
                        <sui-form-field>
                            <label>Navngiv den uploadede fil</label>
                            <input type="text" placeholder="FilNavn" v-model="fileNameInput"/>
                        </sui-form-field>
                        <sui-form-field>
                            <input type="file" @change="setFileUpload"/>
                        </sui-form-field>
                        <sui-message> Uploadede filer sendes til PilotBI </sui-message>
                        <sui-button type="submit" :disabled="!fileUpload">Gem</sui-button>
                        <sui-message success>Fil sendt til PilotBI</sui-message>
                    </sui-form>
                </sui-modal-content>
            </sui-modal>
            <file-viewer-modal
                :openFile="openFile"
                :isOpen="openFileModalOpen"
                :openFileLoading="openFileLoading"
            />
            <state-change-modal :isOpen="stateModalOpen" :serviceOrder="serviceOrder" :task="task" :projectProp="this.project" :installationLabel="this.configurationItem.label" :technicalData="configurationItem.technicalData"/>
        </portal>
        <sui-item-group>
            <sui-item>
                <sui-item-content>
                    <sui-item-meta v-if="task && configurationItem">{{task.number}} - {{ configurationItem.type }} #{{configurationItem.label}} <span v-if="configurationItem.area"><span v-if="configurationItem.area.sonWinProjectId"> - {{configurationItem.area.name}} ( SonWin projekt {{ configurationItem.area.sonWinProjectId }} ) </span></span></sui-item-meta>
                    <div class="row">
                        <div class="col-sm-4">
                            <DataCard title="Opgave" v-if="task" :subtitle="task.number" no-padding class="half-height">
                                <div class="scrollable">
                                    <sui-table striped>
                                        <sui-table-body>
                                            <sui-table-row>
                                                <sui-table-cell>
                                                    Opgave #
                                                </sui-table-cell>
                                                <sui-table-cell>
                                                    {{ task.number }}
                                                </sui-table-cell>
                                            </sui-table-row>
                                            <sui-table-row>
                                                <sui-table-cell>
                                                    Opgave ID
                                                </sui-table-cell>
                                                <sui-table-cell>
                                                    {{ task.id }}
                                                </sui-table-cell>
                                            </sui-table-row>
                                            <sui-table-row>
                                                <sui-table-cell>
                                                    Kort beskrivelse
                                                </sui-table-cell>
                                                <sui-table-cell>
                                                    {{ task.shortDescription }}
                                                </sui-table-cell>
                                            </sui-table-row>
                                            <sui-table-row>
                                                <sui-table-cell>
                                                    Medarbejder
                                                </sui-table-cell>
                                                <sui-table-cell>
                                                    {{ task.assignee }}
                                                </sui-table-cell>
                                            </sui-table-row>
                                            <sui-table-row>
                                                <sui-table-cell>
                                                    Beskrivelse
                                                </sui-table-cell>
                                                <sui-table-cell>
                                                    {{ task.description }}
                                                </sui-table-cell>
                                            </sui-table-row>
                                            <sui-table-row>
                                                <sui-table-cell>
                                                    Status
                                                </sui-table-cell>
                                                <sui-table-cell>
                                                    <div v-if="task.state"><sui-button size="mini" :color="getStateColor(task.state)" @click.stop="statusClicked" >{{ task.state.label }}</sui-button></div>
                                                </sui-table-cell>
                                            </sui-table-row>
                                            <sui-table-row v-if="task.state && task.state.reason">
                                                <sui-table-cell>
                                                    Status-årsag
                                                </sui-table-cell>
                                                <sui-table-cell>
                                                    <div>{{ task.state.reason }}</div>
                                                </sui-table-cell>
                                            </sui-table-row>
                                            <sui-table-row>
                                                <sui-table-cell>
                                                    Planlagt start
                                                </sui-table-cell>
                                                <sui-table-cell>
                                                    {{ toUserFriendlyTimestamp(task.plannedStart) }}
                                                </sui-table-cell>
                                            </sui-table-row>
                                            <sui-table-row>
                                                <sui-table-cell>
                                                    Planlagt slut
                                                </sui-table-cell>
                                                <sui-table-cell>
                                                    {{ toUserFriendlyTimestamp(task.plannedEnd) }}
                                                </sui-table-cell>
                                            </sui-table-row>
                                            <sui-table-row>
                                                <sui-table-cell>
                                                    Opstarts-dag
                                                </sui-table-cell>
                                                <sui-table-cell>
                                                    {{ toUserFriendlyDate(task.connectionDate) }}
                                                </sui-table-cell>
                                            </sui-table-row>
                                        </sui-table-body>
                                    </sui-table>
                                </div>
                            </DataCard>
                        </div>
                        <div class="col-sm-4">
                            <DataCard :title="configurationItem.type" :subtitle="configurationItem.label" no-padding class="half-height">
                                <div class="scrollable">
                                    <sui-table striped>
                                        <sui-table-body>
                                            <sui-table-row>
                                                <sui-table-cell>
                                                    Nr
                                                </sui-table-cell>
                                                <sui-table-cell>
                                                    {{ configurationItem.label }} <sui-button primary size="mini" compact @click="goToBroadBandSupplierPortal(configurationItem.label)">Bredbåndsportal</sui-button>
                                                </sui-table-cell>
                                            </sui-table-row>
                                            <sui-table-row>
                                                <sui-table-cell>
                                                    PilotBI 'value'
                                                </sui-table-cell>
                                                <sui-table-cell>
                                                    {{ configurationItem.value }}
                                                </sui-table-cell>
                                            </sui-table-row>
                                            <sui-table-row>
                                                <sui-table-cell>
                                                    Adresse
                                                </sui-table-cell>
                                                <sui-table-cell>
                                                    <span v-if="configurationItem.address">
                                                        <i
                                                            class="fas fa-map-marker-alt text-primary"
                                                            :title="`${formatAddress(configurationItem.address, false)}\nKlik for at navigere i Google Maps`"
                                                            @click="linkToGoogleMapsDirections(coordinates, configurationItem.address)"
                                                        style="cursor: pointer;"
                                                        ></i>
                                                        <!-- <a :href="generateGoogleMapsLink(coordinates, configurationItem.address)" target="_blank">
                                                        </a> -->
                                                        {{this.formatAddress(configurationItem.address, false)}}
                                                    </span>
                                                </sui-table-cell>
                                            </sui-table-row>
                                            <sui-table-row>
                                                <sui-table-cell>Forening</sui-table-cell>
                                                <sui-table-cell>{{configurationItem.association}}</sui-table-cell>
                                            </sui-table-row>
                                            <sui-table-row>
                                                <sui-table-cell>
                                                    Skab
                                                </sui-table-cell>
                                                <sui-table-cell>
                                                    <span v-if="configurationItem.cabinet">{{ configurationItem.cabinet.name }}</span>
                                                </sui-table-cell>
                                            </sui-table-row>
                                            <sui-table-row>
                                                <sui-table-cell>
                                                    Område
                                                </sui-table-cell>
                                                <sui-table-cell>
                                                    <span v-if="configurationItem.area"><span v-if="configurationItem.area.sonWinProjectId">{{ configurationItem.area.name }} ({{ configurationItem.area.sonWinProjectId }})</span></span>
                                                </sui-table-cell>
                                            </sui-table-row>
                                            <sui-table-row>
                                                <sui-table-cell>Type</sui-table-cell>
                                                <sui-table-cell><span v-if="task.project">{{ProjectType.getValueFromCode(task.project.type.value)}}</span></sui-table-cell>
                                            </sui-table-row>
                                            <sui-table-row>
                                                <sui-table-cell colspan="2">
                                                    <strong>Teknisk Data</strong>
                                                </sui-table-cell>
                                            </sui-table-row><!-- Make this load async -->
                                            <template v-if="configurationItem.technicalData">
                                                <sui-table-row>
                                                    <sui-table-cell>Rack</sui-table-cell>
                                                    <sui-table-cell>{{ configurationItem.technicalData.rack }}</sui-table-cell>
                                                </sui-table-row>
                                                <sui-table-row>
                                                    <sui-table-cell>ASR Name</sui-table-cell>
                                                    <sui-table-cell>{{ configurationItem.technicalData.asrName }}</sui-table-cell>
                                                </sui-table-row>
                                                <sui-table-row>
                                                    <sui-table-cell>ASR Port</sui-table-cell>
                                                    <sui-table-cell>{{ configurationItem.technicalData.asrPort }}</sui-table-cell>
                                                </sui-table-row>
                                                <sui-table-row>
                                                    <sui-table-cell>CATV Port</sui-table-cell>
                                                    <sui-table-cell>{{ configurationItem.technicalData.caTVPort }}</sui-table-cell>
                                                </sui-table-row>
                                                <sui-table-row v-if="configurationItem.technicalData.signalStrengthData">
                                                    <sui-table-cell>Signalstyrke Data</sui-table-cell>
                                                    <sui-table-cell>{{configurationItem.technicalData.signalStrengthData}}</sui-table-cell>
                                                </sui-table-row>
                                                <sui-table-row v-if="configurationItem.technicalData.signalStrengthCaTV">
                                                    <sui-table-cell>Signalstyrke CATV</sui-table-cell>
                                                    <sui-table-cell>{{configurationItem.technicalData.signalStrengthCaTV}}</sui-table-cell>
                                                </sui-table-row>
                                                <sui-table-row>
                                                    <sui-table-cell>Port tjekket</sui-table-cell>
                                                    <sui-table-cell> {{stringToBool(configurationItem.technicalData.portCheckPerformed) ? 'Ja' : 'Nej'}} </sui-table-cell>
                                                </sui-table-row>
                                                <sui-table-row v-if="configurationItem.technicalData.speedTestFiberbox">
                                                    <sui-table-cell>Hastighedstest</sui-table-cell>
                                                    <sui-table-cell>{{configurationItem.technicalData.speedTestFiberbox}}</sui-table-cell>
                                                </sui-table-row>
                                            </template>
                                            <sui-table-row v-else>
                                                <sui-table-cell colspan="2">
                                                    <em>Ingen teknisk data</em>
                                                </sui-table-cell>
                                            </sui-table-row>
                                        </sui-table-body>
                                    </sui-table>
                                </div>
                            </DataCard>
                        </div>
                        <div class="col-sm-4">
                            <DataCard title="Service Order" :no-padding="!!serviceOrder" class="half-height">
                                <div class="scrollable" v-if="serviceOrder">
                                    <sui-table striped>
                                        <sui-table-body>
                                            <sui-table-row>
                                                <sui-table-cell>
                                                    ServiceOrder # <br>
                                                    ServiceOrder ID
                                                </sui-table-cell>
                                                <sui-table-cell>
                                                    {{ serviceOrder.number }} <br>
                                                    {{ serviceOrder.id }}
                                                </sui-table-cell>
                                            </sui-table-row>
                                            <sui-table-row>
                                                <sui-table-cell>
                                                    Service Provider # <br>
                                                    Service Provider ID
                                                </sui-table-cell>
                                                <sui-table-cell>
                                                    <div v-if="serviceOrder.serviceProvider">
                                                        {{ serviceOrder.serviceProvider.number }} <br>
                                                        {{ serviceOrder.serviceProvider.id }}
                                                    </div>
                                                </sui-table-cell>
                                            </sui-table-row>
                                            <sui-table-row>
                                                <sui-table-cell>
                                                    Kort beskrivelse
                                                </sui-table-cell>
                                                <sui-table-cell>
                                                    {{ serviceOrder.shortDescription }}
                                                </sui-table-cell>
                                            </sui-table-row>
                                            <sui-table-row>
                                                <sui-table-cell>
                                                    Oprettet
                                                </sui-table-cell>
                                                <sui-table-cell>
                                                    {{ serviceOrder.createdAt }}
                                                </sui-table-cell>
                                            </sui-table-row>
                                            <sui-table-row>
                                                <sui-table-cell>
                                                    sonWin ID
                                                </sui-table-cell>
                                                <sui-table-cell>
                                                    {{ serviceOrder.sonWinId }}
                                                </sui-table-cell>
                                            </sui-table-row>
                                            <sui-table-row>
                                                <sui-table-cell>
                                                    Projekt # <br>
                                                    Projekt ID
                                                </sui-table-cell>
                                                <sui-table-cell>
                                                    <div v-if="serviceOrder.project">
                                                        {{ serviceOrder.project.number }} <br>
                                                        {{ serviceOrder.project.id }}
                                                    </div>
                                                </sui-table-cell>
                                            </sui-table-row>
                                            <sui-table-row>
                                                <sui-table-cell>
                                                    Projekt beskrivelse
                                                </sui-table-cell>
                                                <sui-table-cell>
                                                    <div v-if="serviceOrder.project">
                                                        {{ serviceOrder.project.shortDescription }} <br v-if="serviceOrder.project.description">
                                                        <span v-if="serviceOrder.project.description"> {{ serviceOrder.project.description }} </span>
                                                    </div>
                                                </sui-table-cell>
                                            </sui-table-row>
                                            <sui-table-row v-if="serviceOrder.project">
                                                <sui-table-cell>
                                                    projekt status
                                                </sui-table-cell>
                                                <sui-table-cell>
                                                    <div>
                                                        {{ serviceOrder.project.state.label }}
                                                    </div>
                                                </sui-table-cell>
                                            </sui-table-row>
                                            <sui-table-row v-if="serviceOrder.project">
                                                <sui-table-cell>
                                                    Projekt start <br>
                                                    Projekt slut
                                                </sui-table-cell>
                                                <sui-table-cell>
                                                    {{ serviceOrder.project.startDate }} <br>
                                                    {{ serviceOrder.project.endDate }}
                                                </sui-table-cell>
                                            </sui-table-row>
                                            <sui-table-row v-for="task in serviceOrderTasks" :key="task.id">
                                                <sui-table-cell>
                                                    {{task.number}} <br>
                                                    {{task.id}} <br>
                                                    <strong>{{task.shortDescription}}</strong> <br v-if="task.assignee">
                                                    <span v-if="task.assignee">Medarbejder:</span> <br>
                                                    Planlagt start: <br>
                                                    Planlagt slut: 
                                                </sui-table-cell>
                                                <sui-table-cell>
                                                    <span v-if="task.state">{{ task.state.label }}</span> <br>
                                                    <br><br>
                                                    <br v-if="task.assignee">
                                                    {{task.assignee}} <br>
                                                    {{task.plannedStart}} <br>
                                                    {{task.plannedEnd}} <br>
                                                </sui-table-cell>
                                            </sui-table-row>
                                        </sui-table-body>
                                    </sui-table>
                                </div>
                                <p v-else>Der ser ikke ud til at være nogen Service Order tilknyttet denne {{configurationItem.type}}</p>
                            </DataCard>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-4">
                            <DataCard 
                                v-if="task"
                                title="Noter" 
                                :subtitle="task.number" 
                                :no-padding="!!task.notes && !!task.notes.length" 
                                :actions="[{type: 'button', icon: 'plus', eventName: 'newNote'}]"
                                class="half-height">
                                <div class="scrollable" v-if="task.notes && task.notes.length">
                                    <sui-table striped>
                                        <sui-table-body>
                                            <sui-table-row v-for="note in task.notes" :key="note.id">
                                                <sui-table-cell>
                                                    <strong>{{ note.createdBy }}</strong> - 
                                                    <span>{{ note.createdAt }}</span>
                                                    <p class="note-body"> {{note.value}} </p>
                                                </sui-table-cell>
                                            </sui-table-row>
                                        </sui-table-body>
                                    </sui-table>
                                </div>
                                <p v-else>
                                    Der er tilsyneladende ingen noter tilknyttet denne opgave
                                </p>
                            </DataCard>
                        </div>
                        <div class="col-sm-4">
                            <DataCard title="Kontaktpersoner" :subtitle="contacts ? String(contacts.length) : null" :no-padding="!!contacts.length" class="half-height">
                                <div class="scrollable">
                                    <sui-table striped v-if="contacts && contacts.length">
                                        <sui-table-body>
                                            <sui-table-row v-for="(contact, index) in contacts" :key="index">
                                                <sui-table-cell>
                                                    <p><strong v-if="contact.name">{{contact.name}}</strong> <span v-if="contact.role">({{contact.role}})</span></p>
                                                    <div class="contactData">
                                                        <p v-if="contact.phone">Tele: {{ contact.phone }} <a :href="'tel:' + contact.phone"><sui-icon name="phone" link/></a> <a v-if="checkIfMobile(contact.phone)" :href="'sms:' + contact.phone"> <i class="fas fa-sms link"></i> </a> </p>
                                                        <p v-if="contact.mobile">Mob: {{ contact.mobile }} <a :href="'tel:' + contact.mobile"><sui-icon name="phone" link/></a> <a v-if="checkIfMobile(contact.mobile)" :href="'sms:' + contact.mobile"><i class="fas fa-sms link"></i></a></p>
                                                        <p v-if="contact.email">Mail: {{ contact.email }} <a :href="'mailto:' + contact.email"><sui-icon name="envelope" link/></a> </p>
                                                    </div>
                                                </sui-table-cell>
                                            </sui-table-row>
                                        </sui-table-body>
                                    </sui-table>
                                    <p v-else>Der er ingen kontaktpersoner knyttet til denne {{configurationItem.type}}</p>
                                </div>
                            </DataCard>
                            <DataCard 
                                title="Splidse-planer"
                                no-padding 
                                class="half-height"
                            >
                                <splice-reports-table v-if="this.configurationItem.cabinet" :id="this.configurationItem.cabinet.name"/>
                            </DataCard>
                        </div>
                        <div class="col-sm-4">
                            <DataCard title="Filer" 
                                v-if="task"
                                :subtitle="task.number" 
                                :no-padding="!!task.attachments && !!task.attachments.length" 
                                :actions="[{type: 'button', icon: 'plus', eventName: 'newFile'}]"
                                class="half-height">
                                <div class="scrollable" v-if="task.attachments && task.attachments.length">
                                    <sui-table striped selectable>
                                        <sui-table-header>
                                            <sui-table-row>
                                                <sui-table-header-cell>Navn</sui-table-header-cell>
                                                <sui-table-header-cell>Type</sui-table-header-cell>
                                                <sui-table-header-cell>Str</sui-table-header-cell>
                                            </sui-table-row>
                                        </sui-table-header>
                                        <sui-table-body>
                                            <sui-table-row 
                                                v-for="file in task.attachments" 
                                                :key="file.id"
                                                @click="openAttachment(file.id)">
                                                <sui-table-cell> {{file.name}} </sui-table-cell>
                                                <sui-table-cell> {{file.type}} </sui-table-cell>
                                                <sui-table-cell> {{formatBytes(file.sizeBytes)}} </sui-table-cell>
                                            </sui-table-row>
                                        </sui-table-body>
                                    </sui-table>
                                </div>
                                <p v-else>
                                    Der er tilsyneladende ingen filer tilknyttet denne opgave
                                </p>
                            </DataCard>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6">        
                            <sui-accordion exclusive styled>
                                <sui-accordion-title>
                                    <sui-icon name="dropdown" />
                                    Se rå data
                                </sui-accordion-title>
                                <sui-accordion-content>
                                    <pre>
                                        {{task}}
                                    </pre>
                                </sui-accordion-content>
                            </sui-accordion>
                        </div>
                        <div class="col-sm-6">
                            <sui-accordion exclusive styled>
                                <sui-accordion-title>
                                    <sui-icon name="dropdown" />
                                    Se rå serviceOrder data
                                </sui-accordion-title>
                                <sui-accordion-content>
                                    <pre>
                                        {{serviceOrder}}
                                    </pre>
                                </sui-accordion-content>
                            </sui-accordion>
                        </div>
                    </div>
                </sui-item-content>
            </sui-item>
        </sui-item-group>
    </div>
</template>

<script>
import { Mixin } from '../../lib/Mixins/mixin'
import { DateMixin } from '../../lib/Mixins/dateMixin'
import { Maps } from '../../lib/maps'
import { DataAPI } from '../../lib/DataAPI'
import TaskType from '../../lib/Enums/TaskType'
import ProjectType from '../../lib/Enums/ProjectType'
import EventBus from '../../EventBus'
import StateChangeModal from '../../components/Project/StateChangeModal.vue'
import FileViewerModal from '../../components/Project/FileViewerModal.vue'
import SpliceReportsTable from '../../components/Project/SpliceReportsTable.vue'
import { mapGetters } from 'vuex'

export default {
    name: 'TaskDetailView',
    mixins: [Mixin, DateMixin, Maps, DataAPI],
    enums: {
        TaskType,
        ProjectType
    },
    components: {
        StateChangeModal,
        FileViewerModal,
        SpliceReportsTable,
    },

    data() {
        return {
            project: {},
            task: {},
            configurationItem: {},
            contacts: [],
            serviceOrder: {},
            serviceOrderTasks: [],
            noteModalOpen: false,
            noteType: "",
            noteText: "",
            noteFormSuccess: false,
            noteFormLoading: false,
            uploadFileModalOpen: false,
            fileNameInput: null,
            fileUpload: null,
            fileFormSuccess: false,
            fileFormLoading: false,
            openFileModalOpen: false,
            openFile: null,
            openFileLoading: false,
            stateModalOpen: false,
            coordinates: null,
        }
    },

    computed: {
        ...mapGetters({
            project: 'activeProject'
        }),
    },

    async beforeMount() {
        EventBus.$emit('function-activity', {functionName: 'TaskDetail_beforeMount', isActive: true})
        await this.getTask()
        this.getCoordinates()
        EventBus.$emit('function-activity', {functionName: 'TaskDetail_beforeMount', isActive: false})
    },

    created() {
        EventBus.$on('newNote-click', () => {
            this.openUploadNoteModal()
        })

        EventBus.$on('newFile-click', () => {
            this.openFileModal()
        })

        EventBus.$on('state-changed', () => {
            this.getTask()
        })

        //TODO: listen for 'add-unit-work' event, and open the editUnitWork form with note pre-filled

        EventBus.$on('stateChange-modal-closing', () => {
            this.stateModalOpen = false
        })

        EventBus.$on('file-viewer-modal-close', () => {
            this.openFileModalOpen = false
        })
    },

    methods: {
        async getProject(){
            EventBus.$emit('function-activity', {functionName: 'TaskDetail_getProject', isActive: true})
            let project = await this.dataGetProjectById(this.$route.params.projectIdentifier)
            this.project = project
            EventBus.$emit('function-activity', {functionName: 'TaskDetail_getProject', isActive: false})
        },

        async getTask() {
            EventBus.$emit('function-activity', {functionName: 'TaskDetail_getTask', isActive: true})
            await this.getProject()
            const tasks = await this.dataGetTasksV2(TaskType.PROJECTTASK, this.$route.params.installationId, null, null)
            let _this = this
            let filteredtasks = await tasks.projectTasks.filter(function(task){
                if(!task || !task.configurationItem) return false
                return (String(task.configurationItem.value) == String(_this.$route.params.installationId) && String(task.number) == String(_this.$route.params.taskNum))
            })
            // console.log(filteredtasks)
            let fulltask = await this.dataGetProjectTask(filteredtasks[0])
            if(!fulltask.project) fulltask.project = filteredtasks[0].project
            let attachments = fulltask.attachments
            fulltask.attachments = attachments.filter((value, index, self) => {
                return self.map(a => a.id).indexOf(value.id) === index
            })
            this.task = fulltask
            if(filteredtasks[0].serviceOrder.id){
                let fullServiceOrder = await this.dataGetServiceOrder(filteredtasks[0].serviceOrder.id)
                this.serviceOrder = fullServiceOrder
                this.serviceOrderTasks = fullServiceOrder.project.tasks
                //console.log(this.serviceOrderTasks)
            } else {
                this.serviceOrder = null
                this.serviceOrderTasks = null
                }
            this.configurationItem = fulltask.configurationItem
            this.contacts = filteredtasks[0].contacts
            EventBus.$emit('function-activity', {functionName: 'TaskDetail_getTask', isActive: false})
        },

        async getCoordinates() {
            // console.log(this.configurationItem.label)
            if (!this.configurationItem.label) return null
            EventBus.$emit('function-activity', {functionName: 'TaskDetail_getCoordinates', isActive: true})
            this.coordinates = await this.dataGetCoordinatesFromFirebase(this.configurationItem.label, this.project.id)
            // console.log(this.coordinates)
            EventBus.$emit('function-activity', {functionName: 'TaskDetail_getCoordinates', isActive: false})
        },
        async getAttachment(attachmentId){
            EventBus.$emit('function-activity', {functionName: 'TaskDetail_getAttachment', isActive: true})
            let response = await this.dataGetAttachment(attachmentId)
            EventBus.$emit('function-activity', {functionName: 'TaskDetail_getAttachment', isActive: false})
            return response
        },
        async openAttachment(attachmentId){
            EventBus.$emit('function-activity', {functionName: 'TaskDetail_openAttachment', isActive: true})
            this.openFileLoading = true //Start loading animation
            this.openFileModalOpen = true //Open modal
            this.openFile = await this.getAttachment(attachmentId) //Get attachment from API
            this.openFileLoading = false //End loading animation
            EventBus.$emit('function-activity', {functionName: 'TaskDetail_openAttachment', isActive: false})
        },
        openUploadNoteModal(){
            this.noteModalOpen = true //Open the note modal
        },
        async postNote(){
            EventBus.$emit('function-activity', {functionName: 'TaskDetail_postNote', isActive: true})
            let response = await this.dataPostNote(this.task.id,this.configurationItem.label,this.noteText,this.noteType,this.$route.params.projectIdentifier)
            EventBus.$emit('function-activity', {functionName: 'TaskDetail_postNote', isActive: false})
            return(response)
        },
        async submitNote(){
            EventBus.$emit('function-activity', {functionName: 'TaskDetail_submitNote', isActive: true})
            this.noteFormLoading = true //Start loading animation
            await this.postNote() //Post note to database
            this.noteText = "" //Clear textfield
            this.noteType = "" //Clear radio-buttons
            await this.getTask() //Retrieve latest task data
            this.noteModalOpen = false //Close modal
            this.noteFormLoading = false //End loading animation
            EventBus.$emit('function-activity', {functionName: 'TaskDetail_submitNote', isActive: false})
        },
        openFileModal(){
            this.uploadFileModalOpen = true //Open the file modal
        },
        setFileUpload(e){
            var files = e.target.files || e.dataTransfer.files;
            console.log(files)
            if (!files || !files.length) {
                this.fileUpload = null
                return false
            }
            this.fileUpload = files[0]
            return true
        },
        async encodeFile(file){
            EventBus.$emit('function-activity', {functionName: 'TaskDetail_encodeFile', isActive: true})
            const toBase64 = file => new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = () => resolve (reader.result)
                reader.onerror = error => reject(error)
            })
            let encodedFile = await toBase64(file).catch(e => Error(e))
            if(encodedFile instanceof Error) {
                console.log('Error encoding file: ',encodedFile.message)
                EventBus.$emit('function-activity', {functionName: 'TaskDetail_encodeFile', isActive: false})
                return
            }
            EventBus.$emit('function-activity', {functionName: 'TaskDetail_encodeFile', isActive: false})
            return encodedFile
        },
        async submitFile(){
            EventBus.$emit('function-activity', {functionName: 'TaskDetail_submitFile', isActive: true})
            this.fileFormLoading = true //Start loading animation
            let base64Content = await this.encodeFile(this.fileUpload)
            base64Content = String(base64Content).substring(base64Content.indexOf('base64,')+7)
            let fileName = this.fileNameInput
            if (!fileName) {fileName = this.fileUpload.name}
            await this.dataPostAttachment(this.task.id, fileName, this.fileUpload.type, base64Content)
            this.uploadFileModalOpen = false //Close file modal
            this.fileNameInput = null
            await this.getTask()
            this.fileFormLoading = false //End loading animation
            EventBus.$emit('function-activity', {functionName: 'TaskDetail_submitFile', isActive: false})
        },
        statusClicked() {
            this.stateModalOpen = true
        },

        async changeState() {
            EventBus.$emit('function-activity', {functionName: 'TaskDetail_changeState', isActive: true})
            this.stateFormLoading = true //Start loading animation

            //Check if selected state is different from current state, and otherwise display warning message 

            //Gather relevant data
            let task = this.task
            // console.log('Before:', task)

            let projectTaskId = task.id
            let state = this.selectedStatus
            let assignee = task.assignee
            let connectionDate = task.connectionDate
            let onHoldReason = task.onHoldReason

            if (this.stateReasonNote && this.stateReasonNote.length > 1){ //Has note
                await this.dataPostNote(projectTaskId, task.configurationItem.label, this.stateReasonNote, "Ekstern", this.$route.params.projectIdentifier)//Append note to task
                if (this.selectedStatus == "5") { //On hold
                    onHoldReason = this.stateReasonNote
                }
            } else if (this.selectedStatus == "5"){ //On hold, without note
                alert('Ved status `on Hold` skal der angives en årsag i noten')
                this.stateFormLoading = false //End loading animation
                EventBus.$emit('function-activity', {functionName: 'TaskDetail_changeState', isActive: false})
            }

            await this.dataUpdateProjectTask(projectTaskId, state, assignee, connectionDate, onHoldReason) //Update task with API

            //Clear form data
            this.selectedStatus = null
            this.stateReasonNote = null
            this.stateSendEmail = true

            await this.getTask() //Get updated data from API
            this.stateFormLoading = false //End loading animation
            this.stateModalOpen = false //Close modal 
            EventBus.$emit('function-activity', {functionName: 'TaskDetail_changeState', isActive: false})
        }
    },

    watch: {
        task: {
            immediate: true,
            handler(task) {
                document.title = `${task.code}: ${this.formatAddress(task.configurationItem.address)} ${this.project.Name} - FiberTeam`
            }
        }
    }
}
</script>
<style scoped>
    .half-height .scrollable {
        overflow-y: auto;
        overflow-x: auto;
        height: 100%;
        max-height: calc(50vh - 157px); /*Hack to keep scrollable divs (and sui-table's) inside half-height cards*/
    }

    .full-height .scrollable{
        overflow-y: auto;
        overflow-x: auto;
        height: 100%;
        max-height: calc(100vh - 252px); /*Hack to keep scrollable divs (and sui-table's) inside full-height cards*/
    }

    .scrollable{
        overflow-y: auto;
        overflow-x: auto;
        height: 100%;
    }

    .full-height {
        height: calc(100vh - 205px);
    }

    .half-height {
        height: auto;
        max-height: calc(50vh - 110px);
    }

    .card-body.p-0 .table tbody>tr>td:first-of-type {
        padding-left: 0.7rem;
    }

    table.selectable tr:hover td {
        cursor: pointer;
    }

    .note-body {
    white-space: break-spaces;
    }

    .centered{
        text-align: center;
    }

    .imageAttachment {
    min-width: 35%;
    max-width: 100%;
    max-height: 600px;
}
</style>