<template>
    <div id="adobe-dc-view" :style="`height: ${this.height};`"></div>
</template>
<script>
import { mapGetters } from 'vuex';
import EventBus from '../../EventBus';

export default {
    name: 'AdobeEmbedPdf',

    props: {
        openFile: {
            type: Object,
            requred: true,
        },
        openFileIsURL: {
            type: Boolean,
            default: false
        },
        height: {
            type: String,
            default: '65vh'
        }
    },

    computed: {
        ...mapGetters(['adobeClientId'])
    },

    data(){
        return {
            previewConfig: {
                showAnnotationTools: false,
                enableFormFilling: false,
                defaultViewMode: 'FIT_WIDTH',
            }
        }
    },

    methods: {
        base64ToArrayBuffer(base64) {
            var binary_string = window.atob(base64);
            var len = binary_string.length;
            var bytes = new Uint8Array(len);
            for (var i = 0; i < len; i++) {
                bytes[i] = binary_string.charCodeAt(i);
            }
            return bytes.buffer;
        },

        refreshOpenFile(){
            console.log('refreshing open file:',this.openFile)
            var adobeDCView = new window.AdobeDC.View({clientId: this.adobeClientId, divId: "adobe-dc-view"});
            if (!this.openFileIsURL){
                adobeDCView.previewFile({
                    content:{ promise: Promise.resolve(this.base64ToArrayBuffer(this.openFile.base64Content)) },
                    metaData:{fileName: this.openFile.name, hasReadOnlyAccess: true}
                }, this.previewConfig);
            } else {
                console.log("Downloading from URL", this.openFile.url)
                adobeDCView.previewFile({
                    content:{ location: {url: this.openFile.url} },
                    metaData:{fileName: this.openFile.name, hasReadOnlyAccess: true}
                }, this.previewConfig);
            }
            // adobeDCView.previewFile({
            //     content:{ promise: Promise.resolve(this.base64ToArrayBuffer(this.openFile.base64Content)) },
            //     metaData:{fileName: this.openFile.name, hasReadOnlyAccess: true}
            // }, this.previewConfig);
        }
    },

    mounted(){
        
        if (window.AdobeDC && window.AdobeDC.View){
            console.log('pdf embed already mounted')
            this.refreshOpenFile()
        } else {
            console.log('pdf embed just mounted')
    
            let adobeScript = document.createElement('script')
            adobeScript.setAttribute('src', 'https://documentcloud.adobe.com/view-sdk/main.js')
            document.head.appendChild(adobeScript)
    
            document.addEventListener("adobe_dc_view_sdk.ready", () => {
                this.refreshOpenFile()
            });

            EventBus.$on('AdobeRefreshOpenFile', () => {
                this.refreshOpenFile()
            })
        }
    },

    watch: {
        openFile: {
            handler(){
                this.refreshOpenFile()
            }
        }
    }
}
</script>