<template>
    <div style="overflow: auto; width: 100vw;">
        <div class="login-box" v-if="state=='verify' || state=='notFound' || state=='expired'">
            <div class="login-logo">
                <img class="logo" src="https://firebasestorage.googleapis.com/v0/b/fiberlan-ftth-dev.appspot.com/o/assets%2FFiberLAN_logo.svg?alt=media&token=229c6da0-c5a7-4b4b-938f-120ba435f6b2" />
                <!-- <img class="logo" src="https://firebasestorage.googleapis.com/v0/b/fiberlan-ftth-dev.appspot.com/o/assets%2FFiberLAN_logo_negativ.svg?alt=media&token=5f76bd57-8cde-4c3b-8a74-11f070e0bf13" /> -->
            </div>

            <DataCard class="login-card-body">
                <div style="height: 20px; margin-top: -20px; margin-bottom: 10px;">
                    <img class="lang-flag" src="https://firebasestorage.googleapis.com/v0/b/fiberlan-ftth.appspot.com/o/assets%2Fda.svg?alt=media&token=b6f74453-c309-4a38-974e-c1a2516fdabe" @click="changeLang('da')"/>
                    <img class="lang-flag" src="https://firebasestorage.googleapis.com/v0/b/fiberlan-ftth.appspot.com/o/assets%2Fen.svg?alt=media&token=563007c2-7298-430d-a390-29d5a453581f" @click="changeLang('en')"/>
                </div>

                <div v-if="state=='verify'">
                    <h3 class="login-box-msg"> {{ headerText }}</h3>
                    <p class="login-box-msg"> {{ smsText }} <br/> {{ verifySMSPhone }}</p>

                    <div style="display: flex; justify-content: center; margin-bottom: 15px;">
                        <sui-button 
                            class="fiberlan-button" 
                            circular 
                        > {{ resendButtonText }} </sui-button>
                    </div>

                    <sui-form>
                        <sui-form-field style="font-family: 'FiberLAN Regular'; font-weight: normal;">
                            <label style="font-family: 'FiberLAN Bold'; font-weight: normal;"> {{ formFieldHeader }} </label>
                            <input
                                type="number"
                                :placeholder="formFieldPlaceholder"
                                v-model="verificationCode"
                                maxlength="10"
                            />
                        </sui-form-field>
                    </sui-form>

                    <div style="display: flex; justify-content: center; margin-top: 10px;">
                        <sui-button 
                            class="fiberlan-button" 
                            :disabled="enterVerificationCodeButtonDisabled"
                            circular 
                            @click.prevent="verifySmsCode"
                        > <i class="fa-solid fa-right-to-bracket"></i> </sui-button>
                    </div>
                </div>

                <div v-if="state=='notFound' && lang=='da'">
                    <h3>404 - Siden blev ikke fundet!</h3>
                    <p>Den side du leder efter blev ikke fundet. Prøv at genindlæse siden, eller kontakt support.</p>
                </div>
                <div  v-if="state=='notFound' && lang=='en'">
                    <h3>404 - Page not found!</h3>
                    <p>The page you are looking for was not found. Try reloading the page, or contact support.</p>
                </div>

                <div v-if="state=='expired' && lang=='da'">
                    <h3>Din session er udløbet!</h3>
                    <p>Det er ikke længere muligt at se eller underskrive dette dokument.</p>
                </div>
                <div v-if="state=='expired' && lang=='en'">
                    <h3>Your session has expired!</h3>
                    <p>It is no longer possible to view or sign this document.</p>
                </div>
            </DataCard>
            <div style="height: 10px !important;"></div>
        </div>

        <div v-if="state=='main'" style="height: 100svh;"> 
            <div style="background-color: white;">
                <sui-container text-align="left">
                    <img class="main-logo" src="https://firebasestorage.googleapis.com/v0/b/fiberlan-ftth-dev.appspot.com/o/assets%2FFiberLAN_logo.svg?alt=media&token=229c6da0-c5a7-4b4b-938f-120ba435f6b2" />
                </sui-container>
            </div>

            <sui-container text-align="left">
                <DataCard style="margin-top: 20px;">
                    <div style="height: 20px; margin-top: 0px; margin-bottom: 10px;">
                        <img class="lang-flag" src="https://firebasestorage.googleapis.com/v0/b/fiberlan-ftth.appspot.com/o/assets%2Fda.svg?alt=media&token=b6f74453-c309-4a38-974e-c1a2516fdabe" @click="changeLang('da')"/>
                        <img class="lang-flag" src="https://firebasestorage.googleapis.com/v0/b/fiberlan-ftth.appspot.com/o/assets%2Fen.svg?alt=media&token=563007c2-7298-430d-a390-29d5a453581f" @click="changeLang('en')"/>
                    </div>
                    <div v-if="lang=='da'">
                        <h3>Velkommen til FiberLAN's Elektroniske Underskriftsportal</h3>
                        <p>Vi gør det nemt og sikkert for dig at underskrive dine dokumenter elektronisk. Følg blot disse trin for at gennemføre processen:</p>
                        <ol>
                            <li><span style="font-family: 'FiberLAN Bold';">Gennemgå Dokumentet:</span> Før du underskriver, anbefaler vi, at du læser dokumentet grundigt igennem for at sikre, at du forstår og er enig med indholdet.</li>
                            <li><span style="font-family: 'FiberLAN Bold';">Elektronisk Underskrift:</span> Når du er klar, kan du underskrive dokumentet elektronisk ved at følge anvisningerne på skærmen.</li>
                            <li><span style="font-family: 'FiberLAN Bold';">Bekræftelse:</span> Når du har underskrevet vil du modtage en bekræftelse, og en kopi af det underskrevne dokument vil blive sendt til din e-mail.</li>
                        </ol>
                    </div>
                    <div v-if="lang=='en'">
                        <h3>Welcome to FiberLAN's Electronic Signature Portal</h3>
                        <p>We make it easy and secure for you to sign your documents electronically. Simply follow these steps to complete the process:</p>
                        <ol>
                            <li><span style="font-family: 'FiberLAN Bold';">Review the Document:</span> Before signing, we recommend that you read the document carefully to ensure that you understand and agree with the content.</li>
                            <li><span style="font-family: 'FiberLAN Bold';">Electronic Signature:</span> When you are ready, you can sign the document electronically by following the on-screen instructions.</li>
                            <li><span style="font-family: 'FiberLAN Bold';">Confirmation:</span> Once you have signed, you will receive a confirmation, and a copy of the signed document will be sent to your email.</li>
                        </ol>
                    </div>

                    <adobe-embed-pdf v-if="pdfReady" :openFile="pdfFile" :openFileIsURL="true" :height="pdfViewportHeight"/>
                    <div style="display: flex; justify-content: right; margin-bottom: 15px; margin-top: 15px;">
                        <sui-button 
                            v-if="lang=='da'"
                            class="fiberlan-button-inverted" 
                            circular 
                            style="margin-right: 10px;"
                        > Afvis </sui-button>

                        <sui-button 
                            v-if="lang=='da'"
                            class="fiberlan-button" 
                            circular 
                        > Underskriv Dokument </sui-button>

                        <sui-button 
                            v-if="lang=='en'"
                            class="fiberlan-button-inverted" 
                            circular 
                            style="margin-right: 10px;"
                        > Reject </sui-button>

                        <sui-button 
                            v-if="lang=='en'"
                            class="fiberlan-button" 
                            circular
                        > Sign Document </sui-button>
                    </div>
                </DataCard>
                <div style="height: 10px !important;"></div>
            </sui-container>
        </div>

    </div>
</template>

<script>
// import { auth } from '../../../firebase'
import { db, storageRef } from '../../../firebase'
// import EventBus from '../../../EventBus'
// import swal from 'sweetalert'
import AdobeEmbedPdf from '@/components/Project/AdobePdfEmbed.vue'

export default {
    name: 'DocumentApproval',

    mixins: [
        db,
        storageRef,
    ],

    data() {
        return {

            verifySMSPhone: '+45 ** ** 56 78',

            lang: 'da',

            verificationCode: '',

            state: 'verify', // verify, notFound, expired, main

            pdfFile: {
                name: "test.pdf", 
                url: null
            },
            pdfReady: false,

        }
    },

    components: {
        AdobeEmbedPdf,
    },

    computed: {
        currentMedia(){
            return this.$root.$children[0].currentMedia
        },

        pdfViewportHeight() {
            return this.currentMedia.type === 'mobile' ? '100vh' : '65vh'
        },

        headerText() {
            return this.lang === 'da' ? 'Velkommen til FiberLAN\'s kundeportal' : 'Welcome to FiberLAN\'s customer portal'
        },

        smsText() {
            return this.lang === 'da' ? 'Vi har nu sendt dig en SMS-kode på' : 'We have now sent you an SMS code on'
        },

        resendButtonText() {
            return this.lang === 'da' ? 'Gensend SMS-kode' : 'Resend SMS code'
        },

        formFieldHeader() {
            return this.lang === 'da' ? 'Indtast SMS-kode' : 'Enter SMS code'
        },

        formFieldPlaceholder() {
            return this.lang === 'da' ? 'SMS-kode' : 'SMS code'
        },

        enterVerificationCodeButtonDisabled() {
            return this.verificationCode.length < 6
        },
    },

    methods: {
        changeLang(lang) {
            this.lang = lang
            const url = new URL(window.location);
            url.searchParams.set('lang', this.lang);
            window.history.pushState({}, '', url);
        },

        async verifySmsCode() {
            console.log('verifying sms code:', this.verificationCode)
            this.state = 'main'

            let apiDoc = await db.collection('API').doc('PilotBI').get()
            this.$store.commit('setAdobeClientId', apiDoc.data().AdobeClientId)
            await this.getDocument()
            this.pdfReady = true
            // window.document.getElementsByTagName('body')[0].setAttribute('class', 'customer-portal-main-page')
        },

        async getDocument() {
            let docRef = await storageRef.child('FormToPdf/TestEFB/FiberLAN A_S - Kærvej 39.pdf')
            let downloadURL = await docRef.getDownloadURL()
            let metadata = await docRef.getMetadata()
            console.log("Document metadata:", metadata)
            this.pdfFile.url = downloadURL
            this.pdfFile.name = metadata.name
        }
    },

    beforeMount() {
        if (this.state === 'main'){
            window.document.getElementsByTagName('body')[0].setAttribute('class', 'customer-portal-main-page')
        } else {
            window.document.getElementsByTagName('body')[0].setAttribute('class', 'customer-portal-login-page')
        }

    },

    watch: {
        $route: {
            immediate: true,
            handler(newVal) {
                if (newVal.query.lang) {
                    this.lang = newVal.query.lang
                } else {
                    this.lang = 'da'
                }
            }
        },

        $state: {
            immediate: true,
            handler(newVal) {
                console.log('state changed:', newVal)
                if (newVal === 'main')
                    window.document.getElementsByTagName('body')[0].setAttribute('class', 'customer-portal-main-page')
            }
        }
    },

    mounted() {
        //
    }
}
</script>
<style scoped>
    h3 {
        font-family: 'FiberLAN Bold';
        color: black;
    }

    p {
        font-family: 'FiberLAN Regular';
    }

    li {
        font-family: 'FiberLAN Regular';
        margin-bottom: 7px;
    }

    .login-box {
        align-content: center;
        height: 100svh;
        margin-left: auto;
        margin-right: auto;
    }
    img.logo {
        width: 100%;
    }

    img.main-logo {
        margin-top: 10px;
        margin-bottom: 10px;
        min-width: 150px !important;
        width: 15%;
    }

    img.lang-flag {
        float: right;
        width: 10%;
        max-width: 30px !important;
        margin-left: 10px;
    }

    img.lang-flag:hover {
        cursor: pointer;
        transform: scale(1.125);
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        appearance: textfield;
        -moz-appearance: textfield;
    }
</style>
<style>
    .customer-portal-login-page {
        /* background-color: #ccb19d; */
        background-image: url('https://fiberlan.dk/wp-content/uploads/2023/04/FiberLAN-lokationer-4-1.svg');
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-size: cover;
        background-position: center;
        background-color: var(--fiberlan-orange-light);
    }

    .customer-portal-main-page {
        /* background-color: #ccb19d; */
        background-image: url('https://fiberlan.dk/wp-content/uploads/2023/04/FiberLAN-SOL-kobber.svg');
        background-repeat: no-repeat;
        background-attachment: fixed;
        /* background-size: cover; */
        background-position: center;
        background-color: white;
    }
</style>
