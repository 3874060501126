<template>
    <div class="salary-check-page">
        <portal to="page-title">
            <h1>Løntime-kontrol</h1>
        </portal>
        <h2>Vælg lønperiode</h2>
        <sui-table selectable>
            <sui-table-header>
                <sui-table-row>
                    <sui-table-header-cell>Lønperiode</sui-table-header-cell>
                    <sui-table-header-cell>Type</sui-table-header-cell>
                    <sui-table-header-cell>Uger</sui-table-header-cell>
                    <sui-table-header-cell>Fra</sui-table-header-cell>
                    <sui-table-header-cell>Til</sui-table-header-cell>
                    <sui-table-header-cell>Afsluttet?</sui-table-header-cell>
                </sui-table-row>
            </sui-table-header>
            <sui-table-body>
                <sui-table-row 
                    v-for="salaryPeriod in salaryPeriods" 
                    :key="salaryPeriod.ID" 
                    @click="selectPeriod(salaryPeriod)" 
                    :active="salaryPeriod.Description == activeSalaryPeriodName" 
                    :warning="salaryPeriodeIsNow(salaryPeriod)"
                >
                    <sui-table-cell>{{ salaryPeriod.Description }}</sui-table-cell>
                    <sui-table-cell>{{ salaryPeriod.Type }}</sui-table-cell>
                    <sui-table-cell>
                        <sui-dropdown text="Vælg evt uge...">
                            <sui-dropdown-menu>
                                <sui-dropdown-item v-for="week in salaryPeriod.Weeks" :key="`WEEK${week.number}`" @click.stop="selectPeriod(week)">
                                    Uge {{ week.number }}
                                </sui-dropdown-item>
                            </sui-dropdown-menu>
                        </sui-dropdown>
                    </sui-table-cell>
                    <sui-table-cell>{{ toUserFriendlyTimestamp(salaryPeriod.FromDate, false) }}</sui-table-cell>
                    <sui-table-cell>{{ toUserFriendlyTimestamp(salaryPeriod.ToDate, false) }}</sui-table-cell>
                    <sui-table-cell>{{ salaryPeriod.IsFinalized ? 'Ja' : 'Nej' }}</sui-table-cell>
                </sui-table-row>
            </sui-table-body>
        </sui-table>
        <sui-segment>
            <h2>Egne timer</h2>
            <span style="float: right; margin-top: -55px">
                Forventet antal "Arbejdsdag-TBT" timer: 
                <sui-input
                    type="number" min="0" max="74" step="0.25" 
                    v-model="expectedTBTWorkHours"
                />
            </span>
            <salary-check-fitter-table 
                v-for="(fitter, key) of ownHours" 
                :key="key" 
                :groupedHours="fitter" 
                :expectedTBTWorkHours="expectedTBTWorkHours" 
                :dates="dates" 
                :fitterName="key" 
                :tableHeader="getHourTableHeader(key, fitter)" 
                :projectResponsible="selectedProjectResponsibleFilter" 
                :userHasHourCheckRole="userHasHourCheckRole"
                :userInitials="userInitials"
                :activeSalaryPeriodName="activeSalaryPeriodName"
            />
        </sui-segment>
        <sui-segment v-if="userHasHourCheckRole && resourceGroupHours && Object.keys(resourceGroupHours).length">
            <span>
                <h2 id="fitter-list-header">Resourcegruppe montører</h2>
                <span>
                    Montørsøgning:
                    <sui-input placeholder="Søg..." v-model="fitterSearchInput" style="margin-right: 10px;"/>
                    <sui-button :disabled="fitterSearch==fitterSearchInput" @click.prevent="fitterSearch = fitterSearchInput" title="Søg"><i class="fa-solid fa-search"/></sui-button>
                </span>
                <sui-button class="sticky-top-right" @click.prevent="scrollToFitterList()" title="Scroll til montør liste"><i class="fa-solid fa-arrow-up"/></sui-button>
                <span style="float: right; margin: 0 10px 0 10px;">
                    Vis kun montører med timer med sagsansvarlig: 
                    <sui-dropdown
                        multiple
                        :options="projectResponsibleOptions"
                        placeholder="Alle"
                        search
                        selection
                        v-model="selectedProjectResponsibleFilter"
                    />
                </span>
                <span style="float: right; margin: 0 10px">
                    Forventet antal "Arbejdsdag-TBT" timer: 
                    <sui-input
                        type="number" min="0" max="74" step="0.25" 
                        v-model="expectedTBTWorkHours"
                    />
                </span>
            </span>
            <sui-table striped selectable>
                <sui-table-header>
                    <sui-table-row>
                        <sui-table-header-cell></sui-table-header-cell>
                        <sui-table-header-cell>Navn</sui-table-header-cell>
                        <sui-table-header-cell>Lønningstype</sui-table-header-cell>
                        <sui-table-header-cell>Grundtimer</sui-table-header-cell>
                        <sui-table-header-cell>TBT</sui-table-header-cell>
                        <sui-table-header-cell>Arbejdsdag-TBT</sui-table-header-cell>
                        <sui-table-header-cell>Andet</sui-table-header-cell>
                        <sui-table-header-cell>Overtid</sui-table-header-cell>
                    </sui-table-row>
                </sui-table-header>
                <sui-table-body>
                    <sui-table-row v-for="(fitter, key) of filteredResourceGroupHours" :key="`${key}-summary`" @click="scrollToFitter(key)">
                        <sui-table-cell>
                            <i class="fa-solid fa-check" style="color: green;" v-if="fitter.TOTAL.total.allApproved" title="Alle noterede timer er godkendt" />
                        </sui-table-cell>
                        <sui-table-cell>{{ key }}</sui-table-cell>
                        <sui-table-cell>{{ getSalaryPeriodTypeNameFromFitter(fitter) }}</sui-table-cell>
                        <sui-table-cell>
                            {{ fitter.TOTAL.total.basisHours }}
                            <i 
                                v-if="showInternalWarning(fitter)"
                                title="Der er noteret en lønddel, som normalt kun er for interne timer, på en sag som ikke er intern"
                                class="fa-solid fa-triangle-exclamation" style="color: orange;"
                            />
                            <i
                                v-if="showBasisHoursWarning(fitter.TOTAL.total, weeksCount)"
                                :title="showBasisHoursWarning(fitter.TOTAL.total, weeksCount)"
                                class="fa-solid fa-triangle-exclamation" style="color: orange;"
                            />
                        </sui-table-cell>
                        <sui-table-cell>
                            <span v-if="fitter.TOTAL.total.takeBackTime">{{ fitter.TOTAL.total.takeBackTime }}</span>
                            <i 
                                v-if="showTbTWarning(fitter.TOTAL, expectedTBTWorkHours)" 
                                :title="`${showTbTWarning(fitter.TOTAL, expectedTBTWorkHours)}\nSkrevet TBT: ${fitter.TOTAL.total.takeBackTime} - Beregnet TBT: ${estimateTbTFromTotalsObject(fitter.TOTAL, expectedTBTWorkHours)}`" 
                                class="fa-solid fa-triangle-exclamation" style="color: red;"
                            />
                        </sui-table-cell>
                        <sui-table-cell>
                            <span v-if="fitter.TOTAL.total.tbtWorkday">{{ fitter.TOTAL.total.tbtWorkday }}</span>
                            <i
                                v-if="showTbTWorkWarning(fitter.TOTAL, expectedTBTWorkHours)"
                                :title="showTbTWorkWarning(fitter.TOTAL, expectedTBTWorkHours)"
                                class="fa-solid fa-triangle-exclamation" style="color: red;"
                            />
                        </sui-table-cell>
                        <sui-table-cell>{{ fitter.TOTAL.total.otherHours || "-" }}</sui-table-cell>
                        <sui-table-cell>{{ fitter.TOTAL.total.overtimeHours || "-" }}</sui-table-cell>
                    </sui-table-row>
                </sui-table-body>
            </sui-table>
            <h2>Resourcegruppe timer</h2>
            <salary-check-fitter-table 
                v-for="(fitter, key) of filteredResourceGroupHours" 
                :key="key" :groupedHours="fitter" 
                :expectedTBTWorkHours="expectedTBTWorkHours" 
                :dates="dates" 
                :fitterName="key" 
                :tableHeader="getHourTableHeader(key, fitter)" 
                :projectResponsible="selectedProjectResponsibleFilter" 
                :id="key"
                :userHasHourCheckRole="userHasHourCheckRole"
                :userInitials="userInitials" 
                :activeSalaryPeriodName="activeSalaryPeriodName"
            />
        </sui-segment>
    </div>
</template>

<script>
import EKomplet from '@/lib/DataProviders/EKomplet.js'
import { Mixin } from '@/lib/Mixins/mixin.js'
import { DateMixin } from '../../lib/Mixins/dateMixin'
import { FitterHourMixin } from '@/lib/Mixins/FitterHourMixin.js'
import swal from 'sweetalert'
import SalaryCheckFitterTable from '@/components/Salary/SalaryCheckFitterTable.vue'
import EventBus from '@/EventBus.js'
import UserRoles from '@/lib/Enums/UserRoles.js'

const EKompletInstance = new EKomplet()

export default {
    name: 'SalaryCheckPage',
    mixins: [Mixin, DateMixin, FitterHourMixin],
    components: { SalaryCheckFitterTable},
    enums: {UserRoles},

    data() {
        return {
            salaryPeriods: [],
            activeSalaryPeriodName: '',
            fromDate: null,
            toDate: null,
            selfFitter: {},
            ownHours: {},
            resourceGroupHours: {},
            resourceGroupFitters: {},
            projectResponsibleOptions: [],
            selectedProjectResponsibleFilter: [],
            fitterSearchInput: '',
            fitterSearch: '',
            expectedTBTWorkHours: 0,
        }
    },
    methods: {
        /**
         * Gets the salary periods from the EKomplet API, and sets this.salaryPeriods
         * @returns {Array<Object>} The salary periods
         */
        async getSalaryPeriods() {
            EventBus.$emit('function-activity', {functionName: 'salaryCheckPage_getSalaryPeriods', isActive: true})
            const periods = await EKompletInstance.getSalaryPeriods('14 dage') //Not including monthly periods
            
            //Get individual weeks in periods
            for (let period of periods) {
                let iterationDate = this.parseDate(period.FromDate)
                // console.log(iterationDate)
                let weeks = {}
                while (iterationDate <= this.parseDate(period.ToDate)) { //Save week number to array
                    const weekNum = this.getWeekNum(iterationDate)
                    let iterationDateClone = new Date(iterationDate)
                    iterationDateClone.setDate(iterationDateClone.getDate() - iterationDateClone.getDay() + 1)
                    // console.log(`First day of week ${weekNum} is:`, iterationDateClone)
                    weeks[weekNum] = {
                        number: weekNum,
                        FromDate: this.formatMachineTimestamp(iterationDateClone),
                    }
                    iterationDateClone.setDate(iterationDateClone.getDate() + 6)
                    weeks[weekNum].ToDate = this.formatMachineTimestamp(iterationDateClone)
                    iterationDate.setDate(iterationDate.getDate()+7) //Increment one week
                }
                period.Weeks = weeks
                // console.log(weeks)
            }
            
            this.salaryPeriods = periods
            EventBus.$emit('function-activity', {functionName: 'salaryCheckPage_getSalaryPeriods', isActive: false})
            return periods
        },
        /**
         * Sets the fromDate and toDate, and gets the hours for the selected period
         * @param {Object} salaryPeriod A salary period object from the EKomplet API, as returned by getSalaryPeriods()
         */
        selectPeriod(salaryPeriod) {
            this.fromDate = this.parseDate(salaryPeriod.FromDate)
            this.toDate = this.parseDate(salaryPeriod.ToDate)
            this.activeSalaryPeriodName = salaryPeriod.Description || `Uge ${salaryPeriod.number}`
            const ressourceGroupString = this.$parent.firebaseUser?.RessouceGroup || undefined
            // console.log(ressourceGroupString)

            this.getOwnHours(this.parseDate(this.fromDate), this.parseDate(this.toDate)).then((hours) => {
                this.ownHours = this.groupFitterHours(hours, this.selfFitter, false)
            }).catch((error) => {
                console.error(error)
                swal('Fejl i hentning af timer', `Kunne ikke hente dine montørtimer fra e-komplet, pga følgende fejl:\n${JSON.stringify(error, null, 2)}`, 'error')
            })
            this.getResourceGroupHours(this.parseDate(this.fromDate), this.parseDate(this.toDate), ressourceGroupString).then((hours) => {
                this.resourceGroupHours = this.groupFitterHours(hours)
            }).catch((error) => {
                console.error(error)
                swal('Fejl i hentning af timer', `Kunne ikke hente resourcegruppens montørtimer fra e-komplet, pga følgende fejl:\n${error}`, 'error')
            })
        },
        /**
         * Gets the hours for the logged in user
         * @param {Date} fromDate The first date to include, must be midnight danish time
         * @param {Date} toDate The last date to include, must be midnight danish time
         */
        async getOwnHours(fromDate, toDate) {
            EventBus.$emit('function-activity', {functionName: 'salaryCheckPage_getOwnHours', isActive: true})
            let response = await EKompletInstance.getFitterHours(fromDate, toDate, 'FitterSalaryID', this.userInitials)
            if (!response || !response.length) {
                let selfFitter = await EKompletInstance.getFitter(this.userInitials)
                this.selfFitter = selfFitter
            } else if (response[0]) {
                this.selfFitter = {
                    "ID": response[0].FitterID,
                    "OldReference": response[0].FitterReferenceNumber,
                    "Name": response[0].FitterName,
                    "SalaryID": response[0].FitterSalaryID,
                    "Username": response[0].FitterName,
                    "SalaryPeriodTypeName": response[0].SalaryPeriodTypeName,
                }
            }
            EventBus.$emit('function-activity', {functionName: 'salaryCheckPage_getOwnHours', isActive: false})
            return response
        },
        /**
         * Gets the hours for the resource group of the logged in user
         * @param {Date} fromDate The first date to include, must be midnight danish time
         * @param {Date} toDate The last date to include, must be midnight danish time
         */
        async getResourceGroupHours(fromDate, toDate, ressourceGroupString = 'Fiber Odense') {
            if (!this.userHasHourCheckRole) return []
            console.log(`Getting fitterHours for ressourceGroup: '${ressourceGroupString}'`)
            EventBus.$emit('function-activity', {functionName: 'salaryCheckPage_getResourceGroupHours', isActive: true})
            let response = await EKompletInstance.getFitterHours(fromDate, toDate, 'RessourceGroupString', ressourceGroupString)
            EventBus.$emit('function-activity', {functionName: 'salaryCheckPage_getResourceGroupHours', isActive: false})
            return response
        },
        async getResourceGroupFitters(ressourceGroupString = 'Fiber Odense') {
            if (!this.userHasHourCheckRole) return []
            console.log(`Getting fitters for ressourceGroup: '${ressourceGroupString}'`)
            EventBus.$emit('function-activity', {functionName: 'salaryCheckPage_getResourceGroupFitters', isActive: true})
            let response = await EKompletInstance.getFitters(ressourceGroupString)
            EventBus.$emit('function-activity', {functionName: 'salaryCheckPage_getResourceGroupFitters', isActive: false})
            return response
        },
        /**
         * Groups the hours by fitter name, project and category, and then by date, summing the hours for each day and fitter
         * @param {Array<Object>} fitterHoursArray Array of hour-object from EKomplet
         */
        groupFitterHours(fitterHoursArray, fallbackFitterObj, includeResourceGroupFitters = true) {
            // console.log(`groupFitterHours on ${fitterHoursArray.length} entries from ${startDate} to ${endDate}`)
            
            const totalObjTemplate = {basisHours: 0, otherHours: 0, overtimeHours: 0, effectiveHours: 0, takeBackTime: 0, tbtWorkday: 0, allApproved: true, searchableFields: [], loading: false}

            let dateObj = {}
            for (let d of this.dates) {
                dateObj[d] = []
            }
            let groupedObject = {}
            const groupedObjectTemplate = {TOTAL: {...JSON.parse(JSON.stringify(dateObj).replaceAll('[]',JSON.stringify(totalObjTemplate))), total: this.cloneJson({...totalObjTemplate, projectResponsibles: []})}}

            if (!fitterHoursArray.length && fallbackFitterObj) {
                console.log('No fitter hours to be grouped, creating empty group for fitter:', fallbackFitterObj)
                groupedObject[fallbackFitterObj.Name] = this.cloneJson(groupedObjectTemplate)
                groupedObject[fallbackFitterObj.Name].TOTAL.total.searchableFields = [fallbackFitterObj.Name, fallbackFitterObj.SalaryID, fallbackFitterObj.OldReference, fallbackFitterObj.ID]
                groupedObject[fallbackFitterObj.Name].TOTAL.total.fitterID = fallbackFitterObj.ID
                groupedObject[fallbackFitterObj.Name].TOTAL.total.salaryPeriodTypeName = fallbackFitterObj.SalaryPeriodTypeName
            }

            if (this.userHasHourCheckRole && includeResourceGroupFitters) {
                //Add the resource group fitters to the groupedObject
                for (let fitter of this.resourceGroupFitters) {
                    if (!groupedObject[fitter.Name]) {
                        groupedObject[fitter.Name] = this.cloneJson(groupedObjectTemplate)
                        groupedObject[fitter.Name].TOTAL.total.searchableFields = [fitter.Name, fitter.SalaryID, fitter.OldReference, fitter.ID]
                        groupedObject[fitter.Name].TOTAL.total.fitterID = fitter.ID
                        groupedObject[fitter.Name].TOTAL.total.salaryPeriodTypeName = fitter.SalaryPeriodTypeName
                    }
                }
            }

            for (let hour of fitterHoursArray) {

                //Add the project responsible to the projectResponsibleOptions, if it is not already there
                if (!this.projectResponsibleOptions.find((option) => option.value === hour.ProjectResponsible)) {
                    this.projectResponsibleOptions.push({text: hour.ProjectResponsible, value: hour.ProjectResponsible})
                    this.projectResponsibleOptions.sort((a,b) => a.text.localeCompare(b.text))
                }

                if (!groupedObject[hour.FitterName]) { //If the fitter is not in the groupedObject, add it, using the dateObj as a template for the date-totals, and the total object as a template for the total
                    groupedObject[hour.FitterName] = this.cloneJson(groupedObjectTemplate)
                }

                const projectandCategory = hour.ProjectName + " // " + hour.CategoryName //concatenate the project and category name, to make a unique key for the /projectandCategory/ combination
                
                if (!groupedObject[hour.FitterName][projectandCategory]) { //If the projectandCategory is not in the fitter, add it, using the dateObj as a template
                    groupedObject[hour.FitterName][projectandCategory] = this.cloneJson(dateObj)
                }
                let hourDate = new Date(hour.Date)
                const formattedDate = hourDate.getFullYear() + "-" + (hourDate.getMonth() + 1) + "-" + hourDate.getDate() //Format the date to YYYY-MM-DD without time, and without leading zeroes
                if (!groupedObject[hour.FitterName][projectandCategory][formattedDate]) {
                    console.error('groupFitterHours: date not found in dateObj') //Skip the hour if the date is not in the dateObj, but log an error
                    continue;
                }
                groupedObject[hour.FitterName][projectandCategory][formattedDate].push(hour)
                
                //Sum the hours for each day
                groupedObject[hour.FitterName].TOTAL[formattedDate].basisHours = Number(groupedObject[hour.FitterName].TOTAL[formattedDate].basisHours) + Number(hour.BasisTotalHours)
                groupedObject[hour.FitterName].TOTAL[formattedDate].otherHours = Number(groupedObject[hour.FitterName].TOTAL[formattedDate].otherHours) + Number(hour.FitterHourWorkTypeOtherTotalHours)
                groupedObject[hour.FitterName].TOTAL[formattedDate].overtimeHours = Number(groupedObject[hour.FitterName].TOTAL[formattedDate].overtimeHours) + Number(hour.OvertimeTotalHours)

                //Sum the hours for each fitter (accross all days)
                groupedObject[hour.FitterName].TOTAL.total.basisHours = Number(groupedObject[hour.FitterName].TOTAL.total.basisHours) + Number(hour.BasisTotalHours)
                groupedObject[hour.FitterName].TOTAL.total.otherHours = Number(groupedObject[hour.FitterName].TOTAL.total.otherHours) + Number(hour.FitterHourWorkTypeOtherTotalHours)
                groupedObject[hour.FitterName].TOTAL.total.overtimeHours = Number(groupedObject[hour.FitterName].TOTAL.total.overtimeHours) + Number(hour.OvertimeTotalHours)

                //TODO: Avoid running these lines for each hour, as they are the same for all hours of a fitter
                groupedObject[hour.FitterName].TOTAL.total.searchableFields = [hour.FitterName, hour.FitterSalaryID, hour.FitterReferenceNumber, hour.FitterID]
                groupedObject[hour.FitterName].TOTAL.total.fitterID = hour.FitterID
                groupedObject[hour.FitterName].TOTAL.total.salaryPeriodTypeName = hour.SalaryPeriodTypeName

                if (!this.nonEffectiveCategories.includes(String(hour.CategoryName))){ //If the hour category is not on the list of non-effective categories, add the hours to the effective hours
                    groupedObject[hour.FitterName].TOTAL[formattedDate].effectiveHours = Number(groupedObject[hour.FitterName].TOTAL[formattedDate].effectiveHours) + Number(hour.BasisTotalHours)
                    groupedObject[hour.FitterName].TOTAL.total.effectiveHours = Number(groupedObject[hour.FitterName].TOTAL.total.effectiveHours) + Number(hour.BasisTotalHours)
                }

                if (hour.CategoryName == 'TAKE BACK TIME') {
                    groupedObject[hour.FitterName].TOTAL[formattedDate].takeBackTime = Number(groupedObject[hour.FitterName].TOTAL[formattedDate].takeBackTime) + Number(hour.BasisTotalHours)
                    groupedObject[hour.FitterName].TOTAL.total.takeBackTime = Number(groupedObject[hour.FitterName].TOTAL.total.takeBackTime) + Number(hour.BasisTotalHours)
                }

                if (hour.CategoryName == 'ARBEJDSDAG - TBT') {
                    groupedObject[hour.FitterName].TOTAL[formattedDate].tbtWorkday = Number(groupedObject[hour.FitterName].TOTAL[formattedDate].tbtWorkday) + Number(hour.BasisTotalHours)
                    groupedObject[hour.FitterName].TOTAL.total.tbtWorkday = Number(groupedObject[hour.FitterName].TOTAL.total.tbtWorkday) + Number(hour.BasisTotalHours)
                }

                if (!groupedObject[hour.FitterName].TOTAL.total.projectResponsibles.includes(hour.ProjectResponsible)) { //If the project responsible is not already in the projectResponsibles array, add it
                    groupedObject[hour.FitterName].TOTAL.total.projectResponsibles.push(hour.ProjectResponsible)
                }

                if (!hour.ApprovedBy){ //If the hour is not approved, set the allApproved flag to false, both for the day and for the fitter overall
                    groupedObject[hour.FitterName].TOTAL[formattedDate].allApproved = false
                    groupedObject[hour.FitterName].TOTAL.total.allApproved = false
                }
            }

            //Order by key (fitter name) alphabetically
            let orderedObject = {}
            Object.keys(groupedObject).sort().forEach(function(key) {
                orderedObject[key] = groupedObject[key];
            });

            return orderedObject
        },

        getSalaryPeriodTypeNameFromFitter(fitter) {
            let salaryPeriodTypeName = 'Ukendt'
            if (!fitter) return salaryPeriodTypeName
            return fitter.TOTAL.total.salaryPeriodTypeName || salaryPeriodTypeName
        },

        getHourTableHeader(key, fitter){
            return `${key} (Lønningstype: ${this.getSalaryPeriodTypeNameFromFitter(fitter)})`
        },

        /**
         * Scrolls to the salary-check-fitter-table with the given fitter name
         */
        scrollToFitter(fitterName) {
            let fitterElement = document.getElementById(fitterName)
            if (fitterElement) {
                fitterElement.scrollIntoView({behavior: "smooth", block: "start"})
            }
        },
        /**
         * Scrolls to the table of fitters
         */
        scrollToFitterList() {
            let fitterListHeaderElement = document.getElementById('fitter-list-header')
            if (fitterListHeaderElement) {
                fitterListHeaderElement.scrollIntoView({behavior: "smooth", block: "start"})
            }
        },
        /**
         * Updates hours for specefied fitter
         * @param {String} fitterName
         * @returns {Promise<Object>} groupedFitterHours
         */
        async updateFitterHours(fitterName) {
            EventBus.$emit('function-activity', {functionName: 'salaryCheckPage_updateFitterHours', isActive: true})

            try {
                //Set loading flags
                if (this.ownHours[fitterName]) {this.ownHours[fitterName].TOTAL.total.loading = true}
                if (this.resourceGroupHours[fitterName]) {this.resourceGroupHours[fitterName].TOTAL.total.loading = true}
    
                //Get hours from EKomplet
                if (!this.fromDate || !this.toDate) {throw new Error('updateFitterHours: fromDate or toDate is not set')}
                let response = await EKompletInstance.getFitterHours(this.parseDate(this.fromDate), this.toDate, 'FitterName', fitterName)
                var groupedFitterHours = this.groupFitterHours(response) //Group the hours by fitter, project and category
                // console.log(groupedFitterHours)
    
                if (this.ownHours[fitterName]) { //If the fitter is in the ownHours object, update the hours
                    this.ownHours[fitterName] = groupedFitterHours[fitterName]
                    this.ownHours[fitterName].TOTAL.total.loading = false
                }
                if (this.resourceGroupHours[fitterName]) { //If the fitter is in the resourceGroupHours object, update the hours
                    this.resourceGroupHours[fitterName] = groupedFitterHours[fitterName]
                    this.resourceGroupHours[fitterName].TOTAL.total.loading = false
                }
            } catch (error) {
                console.error('updateFitterHours failed: ' + error)
                EventBus.$emit('function-activity', {functionName: 'salaryCheckPage_updateFitterHours', isActive: false})
                if (this.ownHours[fitterName]) {this.ownHours[fitterName].TOTAL.total.loading = false}
                if (this.resourceGroupHours[fitterName]) {this.resourceGroupHours[fitterName].TOTAL.total.loading = false}
                return
            }

            EventBus.$emit('function-activity', {functionName: 'salaryCheckPage_updateFitterHours', isActive: false})
            return groupedFitterHours
        },
        salaryPeriodeIsNow(salaryPeriod) {
            const now = new Date()
            return now >= this.parseDate(salaryPeriod.FromDate) && now <= this.parseDate(salaryPeriod.ToDate)
        },
    },
    computed: {
        /**
         * Returns the initials of the logged in user, extracted from the email address
         */
        userInitials() {
            return this.$parent.user?.email?.substring(0, this.$parent.user?.email?.indexOf('@'))?.toUpperCase() || null
        },
        /**
         * Returns true if the logged in user has the HourCheck role, so they should be allowed to see other users' hours
         */
        userHasHourCheckRole() {
            return this.$parent.firebaseUser?.Roles?.includes(UserRoles.HOUR_CHECK) || false
        },
        /**
         * Returns an array of dates between this.fromDate and this.toDate, all midnight danish time
         */
        dates() {
            if (!this.fromDate || !this.toDate) return []
            let dateArray = []
            for (let d = this.parseDate(this.fromDate); d <= this.toDate; d.setDate(d.getDate() + 1)) {
                let formattedDate = d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate() //Format: YYYY-MM-DD
                dateArray.push(formattedDate)
            }
            // console.log(`Generated ${dateArray.length} dates from ${this.fromDate} to ${this.toDate}`)
            return dateArray
        },
        /**
         * Returns this.resourceGroupHours, filtered for project responsible and search string
         */
        filteredResourceGroupHours() {
            // console.log('Filtering resourceGroupHours to make filteredResourceGroupHours')
            
            let filteredKeys = Object.keys(this.resourceGroupHours).filter((fitter) => { //Filter keys, as objects are not filterable
                let shouldPass = false
                if (this.fitterSearch != '' && !JSON.stringify(this.resourceGroupHours[fitter].TOTAL.total.searchableFields).toLowerCase().includes(this.fitterSearch.toLowerCase())){
                    return false
                }
            
                if (!this.selectedProjectResponsibleFilter || !this.selectedProjectResponsibleFilter.length) return true //If no filter is set, return unfiltered data
                for (let pr of this.selectedProjectResponsibleFilter) {
                    if (this.resourceGroupHours[fitter].TOTAL.total.projectResponsibles.includes(pr)) {
                        shouldPass = true
                        break
                    }
                }
                return shouldPass
            })
            let filteredGroups = {}
            for (let fitter of filteredKeys) {
                filteredGroups[fitter] = this.resourceGroupHours[fitter]
            }
            return filteredGroups
        },
        /**
         * Returns the number of weeks in the selected period
         */
        weeksCount() {
            if (!this.dates || !this.dates.length) return 0
            return Math.ceil(this.dates.length / 7)
        },
        firstDayOfAllSalaryPeriods() {
            let firstDate = new Date().toISOString()
            if (!this.salaryPeriods || !this.salaryPeriods.length) return firstDate
            for (let period of this.salaryPeriods) {
                if (firstDate > period.FromDate) firstDate = period.FromDate
            }
            return firstDate
        }
    },
    watch: {
        //Watch
    },
    async mounted() {
        this.getSalaryPeriods().then(() => {
            this.getResourceGroupFitters().then((fitters) => {
                this.resourceGroupFitters = fitters
            })
        })
        EventBus.$on('update-fitter-hours', (fitterName) => {
            this.updateFitterHours(fitterName)
        })
    },
}
</script>
<style scoped>
    table.selectable tr:hover td {
        cursor: pointer;
    }

    .sticky-top-right {
        position: sticky !important;
        float: right;
        top: 10px;
    }
</style>