<template>
    <div class="container-fluid">

        <portal to="page-title">Son Win Projekter</portal>
        <div class="row">

            <div class="col-sm-6">
                <DataCard title="Ledige SonWin Projekter" no-padding>
                    <sui-table>
                        <sui-table-header>
                            <sui-table-row>
                                <sui-table-header-cell>ID</sui-table-header-cell>
                                <sui-table-header-cell>Omr&aring;de</sui-table-header-cell>
                            </sui-table-row>
                        </sui-table-header>
                        <sui-table-body v-if="projects.length">
                            <sui-table-row v-for="(project, index) in projects" :key="index">
                                <sui-table-cell>{{ project.id }}</sui-table-cell>
                                <sui-table-cell>{{ project.name }}</sui-table-cell>
                            </sui-table-row>
                        </sui-table-body>
                        <sui-table-body v-else>
                            <sui-table-row>
                                <sui-table-cell colspan="2">
                                    <TableLoader />
                                </sui-table-cell>
                            </sui-table-row>
                        </sui-table-body>
                    </sui-table>
                </DataCard>
            </div>

            <div class="col-sm-6">
                <DataCard v-if="projectInView" title="Kunder i projekt" :subtitle="projectInView.name">
                    <pre>{{ projectInView }}</pre>
                </DataCard>
            </div>


        </div>
    </div>
</template>
<script>
import TableLoader from '../../components/TableLoader'
import { DataAPI } from '../../lib/DataAPI'

export default {
    name: 'AvailableSonWin',
    mixins: [DataAPI],

    components: {
        TableLoader
    },

    data() {
        return {
            projects: [],
            projectInView: null
        }
    },

    async created() {
        let projects = await this.dataGetProjectTasks([])
        this.projects = projects
    }
}
</script>
