<template>
    <sui-modal
        name="editWorkerModal"
        :open="isModalOpen" 
        v-on:clickAwayModal="clickAway"
        v-if="worker"
    >
        <sui-modal-header>
            <div  v-if="isNewWorker">Opret bruger</div>
            <div v-else>Redigér bruger</div>
            <sui-item-meta v-if="!isNewWorker" style="font-size: small"> {{ worker.Name }} </sui-item-meta>
        </sui-modal-header>
        <sui-modal-content scrolling style="max-height: 70svh;">
            <sui-form :warning="email != worker.Email" :loading="loading">
                <sui-form-field>
                    <label>Fulde Navn</label>
                    <input placeholder="Jens Jensen" v-model="fullName"/>
                </sui-form-field>
                <sui-form-fields>
                    <sui-form-field>
                        <label>Initialer</label>
                        <input placeholder="JEJ" v-model="initials"/>
                    </sui-form-field>
                    <sui-form-field>
                        <label>Email</label>
                        <input placeholder="jej@fiberlan.dk" v-model="email"/>
                    </sui-form-field>
                </sui-form-fields>
                <sui-message warning>
                    <sui-message-header>Ændret e-mail</sui-message-header>
                    <p>E-mail adressen er nøgle-værdi, så hvis den ændres oprettes en ny bruger med de indtastede oplysninger</p>
                </sui-message>
                <sui-form-fields>
                    <sui-form-field>
                        <label>Telefon</label>
                        <input placeholder="+4512345678" v-model="phone"/>
                    </sui-form-field>
                    <sui-form-field>
                        <label>Mobil</label>
                        <input placeholder="+4587654321" v-model="mobile"/>
                    </sui-form-field>
                </sui-form-fields>
                <sui-form-field>
                    <label>Firma</label>
                    <sui-dropdown 
                        placeholer="Firma"
                        selection
                        :options="companyOptions"
                        v-model="company"
                    />
                </sui-form-field>
                <sui-form-field>
                    <label>Rolle(r)</label>
                    <sui-dropdown 
                        direction="upward"    
                        placeholer="Rolle(r)"
                        multiple
                        selection
                        :options="roleOptions"
                        v-model="roles"
                    />
                </sui-form-field>
                <sui-form-field v-if="roles && roles.includes(UserRoles.HOUR_CHECK)">
                    <label>RessourceGruppe til løntjek</label>
                    <sui-input placeholder="Fiber Odense" v-model="resGroup"/>
                </sui-form-field>
            </sui-form>
        </sui-modal-content>
        <sui-modal-actions>
            <sui-button @click="clickAway()">Luk</sui-button>
            <sui-button v-if="!isNewWorker" negative @click.prevent="deleteUser">Slet bruger</sui-button>
            <sui-button positive @click="saveUser()">{{ saveBtnText }}</sui-button>
        </sui-modal-actions>
    </sui-modal>
</template>
<script>
import EventBus from '@/EventBus'
import { DataAPI } from '@/lib/DataAPI.js'
import { analytics, db } from '@/firebase.js'
import swal from 'sweetalert'
import { Mixin } from '@/lib/Mixins/mixin.js'
import UserRoles from '@/lib/Enums/UserRoles.js'

export default {
    name: 'EditWorkerModal',

    mixins: [DataAPI, Mixin],

    enums: {
        UserRoles
    },

    props: {
        isModalOpen: {
            type: Boolean,
            required: true
        },
        worker: {
            type: Object,
            required: true
        },
    },

    data(){
        return {
            fullName: '',
            initials: '',
            email: '',
            phone: '',
            mobile: '',
            company: '',
            roles: [],
            resGroup: '',

            companies: [],
            roleOptions: [],

            loading: false,
        }
    },

    computed: {
        user() {
            return this.$store.state.userProfile
        },

        firebaseUser() {
            return this.$root.$children[0].firebaseUser
        },

        companyOptions(){
            return this.companies.reduce((acc, cur) => {
                let arr = acc
                let option = {}
                option.value = cur.Name
                option.text = cur.Name
                arr.push(option)
                return arr
            }, [])
        },

        // is new worker if worker is an empty object
        isNewWorker(){
            return Object.keys(this.worker).length === 0
        },

        saveBtnText(){
            return this.isNewWorker ? 'Opret' : 'Gem'
        }
    },

    methods: {
        clickAway() {
            EventBus.$emit('edit-worker-modal-closing')
        },
        populateFormFromWorker(){
            this.fullName = this.worker.Name || ''
            this.initials = this.worker.Initials || '' 
            this.email = this.worker.Email || ''
            this.phone = this.worker.Phone || ''
            this.mobile = this.worker.Mobile || ''
            this.company = this.worker.Company || null
            this.roles = this.worker.Roles  || []
            this.resGroup = this.worker.RessouceGroup || ''
        },
        getCompanies(){
            this.$bind('companies', db.collection('Companies'))
        },
        async saveUser(){
            EventBus.$emit('function-activity', {functionName: 'EditWorkerModal_saveUser', isActive: true})
            this.loading = true

            let doc = {
                Company: this.company,
                Email: this.email,
                Mobile: this.parsePhoneNumber(this.mobile),
                Phone: this.parsePhoneNumber(this.phone),
                Initials: this.initials,
                Name: this.fullName,
                Roles: this.roles,
                RessouceGroup: this.resGroup || '',
            }


            // Check if firestore doc exists
            if (this.isNewWorker){
                await db.collection('Users').doc(this.email).set(doc)
                this.loading = false
                this.clickAway()
                EventBus.$emit('function-activity', {functionName: 'EditWorkerModal_saveUser', isActive: false})
                return
            }

            // Update firestore doc
            let docref = db.collection('Users').doc(this.email)
            await docref.update(doc)

            this.loading = false
            this.clickAway()
            EventBus.$emit('function-activity', {functionName: 'EditWorkerModal_saveUser', isActive: false})
        },
        async deleteUser(){
            EventBus.$emit('function-activity', {functionName: 'EditWorkerModal_deleteUser', isActive: true})

            let confirm = await swal({
                title: 'Vil du slette denne bruger?',
                text: 'Brugeren vil ikke længere have adgang til FiberTeam, men slettes ikke automatisk fra PilotBI, eller Google Workspace.',
                icon: 'warning', //Can be warning, error, success, info
                dangerMode: true,
                buttons: {
                    cancel: {
                        text: 'Annuller',
                        value: false,
                        visible: true,
                    },
                    confirm: {
                        text: 'Slet bruger',
                        value: true,
                        visible: true,
                    },
                }
            })

            if (!confirm) {
                console.error('Cancelled user deletion')
                EventBus.$emit('function-activity', {functionName: 'EditWorkerModal_deleteUser', isActive: false})
                return
            }

            this.loading = true
            await db.collection('Users').doc(this.worker.Email).delete()
            this.loading = false
            EventBus.$emit('function-activity', {functionName: 'EditWorkerModal_deleteUser', isActive: false})
            this.clickAway()
            this.$router.push('/admin/workers')
        }  
    },

    watch: {
        initials: {
            immediate: false,
            handler(initials) {
                if (!initials) return
                this.email = `${initials.toLowerCase()}@fiberlan.dk`
            }
        },
    },

    mounted(){
        this.populateFormFromWorker()
        this.getCompanies()

        if (this.firebaseUser.Roles.includes(UserRoles.SUPER_ADMIN)){
            this.roleOptions = UserRoles.DROPDOWN_OPTIONS_ALL
        } else {
            this.roleOptions = UserRoles.DROPDOWN_OPTIONS_USER_ADMIN
        }

        analytics.logEvent('worker_edit_form_mounted')
    }
    
}
</script>