<template>
    <div class="image-card" @click="selectImage">
        <img :src="imageUrl" :alt="imageRef.name" class="image" @load="resizeImage"/>
    </div>                             
</template>

<script>

export default {
    name: 'ImageCard',
    props: {
        imageRef: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            imageUrl: '',
        }
    },

    methods: {
        fetchImageUrl() {
            this.imageRef.getDownloadURL()
            .then((url) => {
                this.imageUrl = url;
            })
            .catch((error) => {
                console.error('Error fetching image URL: ', error);
            })
        },

        selectImage() {
            this.$emit('select-image', this.imageRef);
        },

        resizeImage(event) {
            const image = event.target;
            const imageCard = image.parentElement;
            if (imageCard) {
                const rowHeight = 10; // This should match the grid-auto-rows value in the parent
                const span = Math.ceil((image.offsetHeight + 10) / rowHeight); // Adding 10 to account for any padding/margin
                imageCard.style.gridRowEnd = `span ${span}`;
            }
        },

    },

    mounted() {
        this.fetchImageUrl();
    }
}
</script>

<style scoped>
.image-card {
    position: relative;
    margin: 0px;
    overflow: hidden;
    transition: transform 0.3s ease; /* Smooth scaling */
    border-radius: 10px; /* Rounded corners for the card */
}

.image-card :hover {
    cursor: pointer;
    transform: scale(1.15);
}

.image {
    width: 100%;
    height: auto;
    display: block;
    object-fit: cover; /* Ensure images cover the container while preserving aspect ratio */
    border-radius: 10px; /* Rounded corners for the image */
}
</style>