<template>
    <div>
        <sui-modal
            size="tiny"
            id="cancellationListModal"
            :open="isOpen"
            v-on:clickAwayModal="clickAway()"
        >
            <sui-modal-header>
                Afbudsliste
            </sui-modal-header>
            <sui-modal-content scrolling>
                <h4>Disse kunder står på vores afbudsliste.</h4>
                <h5 style="margin-top: -15px">Prøv at give dem et ring.</h5>
                <sui-form :loading="!prePopFinished">
                    <h5 v-if="!cancellationList.length">Der var desværre ikke fundet nogle kunder i systemtet!</h5>
                    <div class="accordion-spacing" v-for="(entry, index) in cancellationList" :key="index">
                        <sui-accordion is="sui-menu" vertical exclusive styled fluid>
                            <sui-accordion-title is="sui-menu-header">
                                <sui-icon name="dropdown" />
                                    <small>
                                        <!-- Installation status icons -->
                                        <InstallationStatusIcons
                                            :installation="entry.ins"
                                            :settings="settings"
                                            :settingsOverride="settingsOverride"
                                        />
                                    </small>
                                    {{ entry.address }}
                                
                            </sui-accordion-title>
                            <sui-accordion-content style="padding: 0px;">
                                <sui-table striped compact size="small">  
                                    <sui-table-row v-if="!entry.contacts || !entry.contacts.length">
                                        <sui-table-cell colspan="2">
                                            <em>Ingen kontaktpersoner</em>
                                        </sui-table-cell>
                                    </sui-table-row>
                                    <template v-else>
                                        <template v-for="(contact, index) in entry.contacts">
                                            <sui-table-row :key="`c-${index}`" v-if="contact">
                                                <sui-table-cell>{{ contact.name }}</sui-table-cell>
                                                <sui-table-cell>
                                                    <span v-if="contact.email">{{ contact.email }}<a :href="'mailto:' + contact.email"><sui-icon name="envelope" link/></a><br /></span>
                                                    <span v-if="contact.mobile">
                                                        {{ formatPhoneNumber(contact.mobile) }}
                                                        <a :href="'tel:' + contact.mobile"><sui-icon name="phone" link/></a> 
                                                        <i class="fas fa-sms link" v-if="checkIfMobile(contact.mobile)" @click="sendEvent('open-sms-modal',contact.mobile)"></i>
                                                        <br /> 
                                                    </span>
                                                    <span v-if="contact.phone && contact.phone != contact.mobile">
                                                        {{ formatPhoneNumber(contact.phone) }}
                                                        <a :href="'tel:' + contact.phone"><sui-icon name="phone" link/></a> 
                                                        <i class="fas fa-sms link" v-if="checkIfMobile(contact.phone)" @click="sendEvent('open-sms-modal',contact.phone)"></i>
                                                    </span>
                                                </sui-table-cell>
                                            </sui-table-row>
                                        </template>
                                    </template>
                                </sui-table>
                            </sui-accordion-content>
                        </sui-accordion>
                    </div>
                </sui-form>
            </sui-modal-content>
            <sui-modal-actions>
                <sui-button 
                    positive
                    @click.prevent="clickAway"
                >
                    Ok
                </sui-button>
            </sui-modal-actions>
        </sui-modal>
    </div>
</template>
<script>
// import swal from 'sweetalert'
import { Mixin } from '../../lib/Mixins/mixin'
import { DataAPI } from '../../lib/DataAPI'
import EventBus from '../../EventBus'
import InstallationStatusIcons from './InstallationStatusIcons.vue'

export default {
    mixins: [Mixin, DataAPI],

    components: {
        InstallationStatusIcons
    },
    
    props: {
        isOpen: Boolean,
        availableInsts: {
            type: [Object, Array],
            required: true
        },
        bookedInsts: {
            type: [Object, Array],
            required: true
        },
        settings: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            cancellationList: [],
            prePopFinished: false,
            localSettings: null,

            settingsOverride: {
                cancellationListIcon: {value: false},
                isDraftIcon: {value: false},
                instTypeIcon: {value: false}
            },
            // currentAppointmentState: 'null',
            // newState: null,
            // appointment: null,

        }
    },

    computed: {
        // ...mapGetters({
        //     project: 'activeProject',
        //     // user: 'userProfile'
        // }),

    },

    methods: {
        clickAway(){
            this.cancellationList = []
            EventBus.$emit('cancellation-list-modal-closing')
        },


        prePopulateForm(){
            this.cancellationList = []
            this.prePopFinished = false
            for (let i in this.bookedInsts){
                for (let j in this.bookedInsts[i]){
                    if (this.bookedInsts[i][j].appointment?.IsOnCancellationList){

                        let doc = {
                            isBooked: true,
                            address: this.bookedInsts[i][j].appointment.AddressLong,
                            contacts: this.bookedInsts[i][j].tasks[0].contacts,
                            timestamp: this.bookedInsts[i][j].appointment.CancellationListTimestamp,
                            ins: this.bookedInsts[i][j]
                        }
                        this.cancellationList.push(doc)
                    }  
                }
            }

            for (let i in this.availableInsts){
                for (let j in this.availableInsts[i]){
                    for (let k in this.availableInsts[i][j]){
                        if (this.availableInsts[i][j][k]?.updates?.IsOnCancellationList){
                            let doc = {
                                isBooked: false,
                                address: this.formatAddress(this.getConfiguration(this.availableInsts[i][j][k].tasks[0]).address, false),
                                contacts: this.availableInsts[i][j][k].tasks[0].contacts,
                                timestamp: this.availableInsts[i][j][k].updates.CancellationListTimestamp,
                                ins: this.availableInsts[i][j][k]
                            }
                            this.cancellationList.push(doc)
                        }
                    }
                }
            }

            this.cancellationList = this.cancellationList.sort((a,b) => {
                if (a.isBooked && !b.isBooked) return -1
                if (!a.isBooked && b.isBooked) return 1

                if (a.timestamp.seconds < b.timestamp.seconds) return -1
                if (a.timestamp.seconds > b.timestamp.seconds) return 1

                if (a.timestamp.nanoseconds < b.timestamp.nanoseconds) return -1
                if (a.timestamp.nanoseconds > b.timestamp.nanoseconds) return 1

                return 0
            })
            this.prePopFinished = true  
        },
    },

    watch: {
        isOpen: {
            handler(open){
                if (open) {
                    this.prePopulateForm()
                }
            }
        }
    },

    beforeMount() {
        EventBus.$on('cancellation-list-modal-open-if-any', () => {
            this.prePopulateForm()
            if (this.cancellationList.length){
                EventBus.$emit('cancellation-list-modal-open')
            }
        })
    }
}
</script>
<style scoped>
    .accordion-spacing {
        margin-top: 5px;
        margin-bottom: 5px;
    }
</style>