
class AppointmentState {

    static ACTIVE = 'active'
    static CLOSED = 'closed'
    static CANCELED = 'canceled'

    static AllArray = [ //Array of all the types from above
        this.ACTIVE, this.CLOSED, this.CANCELED
    ]

    static DropdownOptions = [ //Array of objects, with 'value' and 'text' properties, for dropdown options etc
        { value: this.ACTIVE, text: "ACTIVE" },
        { value: this.CLOSED, text: "CLOSED" },
        { value: this.CANCELED, text: "CANCELED" },
    ]

}

export default AppointmentState

