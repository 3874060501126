import TaskCode from './TaskCode'

class AppointmentType {

    static INSTALLATION = 'installation' //'BOOKKUNDE' & 'CPE' tasks
    static INSPECTION = 'inspection' //'KONBESIGT' & 'FIBERKONSU' tasks
    static TECHNICIAN = 'technician' //'BOOKKUNDE' & 'UDVID' tasks
    static PATCH = 'patch' //'PATCH' task without 'CPE' task
    static TICKET = 'ticket' //TroubleTickets
    static CUSTOM = 'Custom' //Used for appointments without tasks (yellow and red on timeline) //I don't know why this is capitalized, but I can't be bothered to 'fix' it
    static UNKNOWN = 'unknown'

    static DEFAULT = this.INSTALLATION //Default appointment type
    
    static AllArray = [ //Array of all the types from above
        this.INSTALLATION, this.INSPECTION, this.TECHNICIAN, this.PATCH, this.TICKET, this.CUSTOM, this.UNKNOWN
    ]

    static DropdownOptions = [ //Array of objects, with 'value' and 'text' properties, for dropdown options etc
        { value: this.INSTALLATION, text: this.translate(this.INSTALLATION) },
        { value: this.INSPECTION, text: this.translate(this.INSPECTION) },
        { value: this.TECHNICIAN, text: this.translate(this.TECHNICIAN) },
        { value: this.PATCH, text: this.translate(this.PATCH) },
        { value: this.TICKET, text: this.translate(this.TICKET) },
        // { value: this.CUSTOM, text: this.translate(this.CUSTOM) } //Custom is not an option in the dropdown
    ]

    static TaskRoles = { //The role a task plays in the appointment
        Book: 'BOOK', //The task that should be completed when an appointment has been made
        Primary: 'PRIMARY', //The task that the appointment is about
        Other: 'OTHER' //Any other tasks that should also be required to be closed before the appointment can be considered done
    }

    //Model of each AppointmentType, with their expected tasks (Book, Primary, Other), and weather an expected task is required for the appointment to be valid:

    // Old order:
    //   installation
    //   inspection
    //   technician
    //   patch
    //   ticket
    //   Custom

    static allowCustomerCommunication = (type) => {
        switch (type) {
            case this.INSTALLATION: return true
            case this.INSPECTION: return true
            case this.TECHNICIAN: return true
            case this.PATCH: return false
            case this.TICKET: return true
            case this.CUSTOM: return true
            default: return false
        }
    }
    
    static AppointmentTasks = { 
        ticket: {
            Book: null,
            Primary: {
                TaskCode: TaskCode.TICKET,
                Required: true
            },
            Other: []
        },
        inspection: {
            Book: {
                TaskCode: TaskCode.BOOKBESIGT,
                Required: false
            },
            Primary: {
                TaskCode: TaskCode.FIBERKONSU,
                Required: true
            },
            Other: []
        },
        installation: {
            Book: {
                TaskCode: TaskCode.BOOKKUNDE,
                Required: true
            },
            Primary: {
                TaskCode: TaskCode.CPE,
                Required: true
            },
            Other: [
                {
                    TaskCode: TaskCode.PATCH,
                    Required: false
                },
                {
                    TaskCode: TaskCode.BLAES,
                    Required: false
                },
            ]
        },
        technician: {
            Book: {
                TaskCode: TaskCode.BOOKKUNDE,
                Required: true
            },
            Primary: {
                TaskCode: TaskCode.UDVID,
                Required: true
            },
            Other: []
        },
        patch: {
            Book: null,
            Primary: {
                TaskCode: TaskCode.PATCH,
                Required: true
            },
            Other: [],
        },
        Custom: { //With a captical 'C' for some reason...
            Book: null,
            Primary: null,
            Other: []
        }
    }

    /**
     * Takes an appointment type, and returns an array of required task codes for that type of appointment, based on 'Required' property in AppointmentTasks
     * @param {String} type one of the types defined at the top of this file
     * @returns {Array} Array of task codes, required for the appointment type
     */
    static requiredTaskCodes(type) {
        let taskModel = this.AppointmentTasks[type]
        let requiredTaskCodes = []
        if (taskModel.Book && taskModel.Book.Required) {
            requiredTaskCodes.push(taskModel.Book.TaskCode)
        }
        if (taskModel.Primary && taskModel.Primary.Required) {
            requiredTaskCodes.push(taskModel.Primary.TaskCode)
        }
        taskModel.Other.forEach((task) => {
            if (task.Required) {
                requiredTaskCodes.push(task.TaskCode)
            }
        })
        return requiredTaskCodes
    }

    static minimumRequirementsTaskCodes(type) {
        let taskModel = this.AppointmentTasks[type]
        let requiredTaskCodes = []
        if (taskModel.Book && type === this.INSPECTION) {
            requiredTaskCodes.push(taskModel.Book.TaskCode)
        }
        if (taskModel.Primary) {
            requiredTaskCodes.push(taskModel.Primary.TaskCode)
        }
        taskModel.Other.forEach((task) => {
            requiredTaskCodes.push(task.TaskCode)
        })
        return requiredTaskCodes
    }

    /**
     * Takes an array of task codes, and returns an array of appointment types where all Required tasks are present in the task code array
     * @param {Array} taskCodes Array of task codes
     * @returns {Array} Array of appointment types
     */
    static appointmentTypesFromTaskCodes(taskCodes) {
        let appointmentTypes = []
        for (let type in this.AppointmentTasks) {
            if (type === this.CUSTOM) continue //Custom is not releavant if there are any tasks at all
            let requiredTaskCodes = this.requiredTaskCodes(type)
            let allRequiredTasksPresent = true
            requiredTaskCodes.forEach((code) => {
                if (!taskCodes.includes(code)) {
                    allRequiredTasksPresent = false
                }
            })
            if (allRequiredTasksPresent) {
                appointmentTypes.push(type)
            }
        }
        return appointmentTypes
    }

    static appointmentTypesFromTaskCodesNeglectRequirements(taskCodes) {
        let appointmentTypes = []
        for (let type in this.AppointmentTasks) {
            if (type === this.CUSTOM) continue //Custom is not releavant if there are any tasks at all
            let requiredTaskCodes = this.minimumRequirementsTaskCodes(type)
            
            let anyRequiredTasksPresent = false
            requiredTaskCodes.forEach((code) => {
                if (taskCodes.includes(code)) {
                    anyRequiredTasksPresent = true
                }
            })
            if (anyRequiredTasksPresent) {
                appointmentTypes.push(type)
            }
        }
        return appointmentTypes
    }

    /**
     * Takes one of the standard AppointmentTypes, and 
     * @param {String} type one of the types defined at the top of this file
     * @returns {String} one or two Danish words, describing the type of appointment, in all lowercase
     */
    static translate(type) {
        switch (type) {
            case this.INSTALLATION:
                return 'installation'
            case this.INSPECTION:
                return 'besigtigelse'
            case this.TECHNICIAN:
                return 'montørhjælp'
            case this.PATCH:
                return 'patch'
            case this.TICKET:
                return 'driftsag'
            case this.CUSTOM:
                return 'brugerdefineret aftale'
            default:
                console.error(`Did not recognize AppointmentType '${type}', returning value as is`)
                return type
        }
    }

    /**
     * Takes array of tasks, and returns a compacted version of only the relevant tasks, ready to be saved in Firestore
     * @param {Array} taskList Array of tasks associated with a customer, to be filtered for association with specific appointment
     * @param {String} appType One of the types of appointments in AppointmentType.AllArray
     * @returns {Array} Array of tasks in compact version, ready to be saved to firestore
     * @throws {Error}
     */
    static relevantCompactTasks(taskList, appType = this.INSTALLATION, ignoreBook = false) { //TODO: Throw an error or something, if there are more than 1 tasks matching the code for Book or Primary task
        let taskModel = this.AppointmentTasks[appType] //The model describes the kinds of tasks expected to be part of this type of appointment
        let bookTask = taskModel.Book ? taskList.find((task) => (task.code || this.TICKET) == taskModel.Book.TaskCode) : null // If the model specifies a booking task, find that task in taskList
        let primaryTask = taskModel.Primary ? taskList.find((task) => (task.code || this.TICKET) == taskModel.Primary.TaskCode) : null // If the model specifies a primary task, find that task in taskList
        if ((!primaryTask && taskModel.Primary && taskModel.Primary.Required)) { //If the model specifies a primary task, but it is not present in taskList, throw an error
            throw new Error('A primary task was required, but not present in taskList (AppointmentType.relevantCompactTasks())')
        }
        if (!bookTask && taskModel.Book && taskModel.Book.Required && !ignoreBook) { //If the model specifies a booking task, but it is not present in taskList, throw an error
            throw new Error('A Booking-task was required, but not present in taskList (AppointmentType.relevantCompactTasks())')  
        }

        let compactList = [] //Initialize the array to be returned
        if (bookTask) { //If there is a bookTask, add the compact version to the compactList
            compactList.push({
                Role: this.TaskRoles.Book,
                Code: bookTask.code || null,
                Id: bookTask.id || null,
                Number: bookTask.number || null
            })
        }
        if (primaryTask) { //If there is a primaryTask, add the compact version to the compactList
            compactList.push({
                Role: this.TaskRoles.Primary,
                Code: primaryTask.code || null,
                Id: primaryTask.id || null,
                Number: primaryTask.number || null
            })
        }
        
        for (let modelTask of taskModel.Other) { //Loop through the "other" tasks in the model
            let foundTask = taskList.find((task) => (task.code || this.TICKET) == modelTask) //Find the task with the matching code in the taskList
            if (!foundTask) { //If there was no task to match the models expectations
                if (modelTask.Required) { //Check if that task is required in the model, and throw an error if it is
                    throw new Error(`A required task code (${modelTask.TaskCode}) was not found in tasklist (AppointmentType.relevantCompactTasks)`)
                }
                continue; //If a non-required task from the model was not found, skip it
            } else {
                compactList.push({ //Push each "other" task to the array to be returned
                    Role: this.TaskRoles.Other,
                    Code: foundTask.code,
                    Id: foundTask.id,
                    Number: foundTask.number
                })
            }
        }
        console.log(compactList)
        return compactList //{Array} of compacted tasks
    }

}

export default AppointmentType