import axios from 'axios'
// import swal from 'sweetalert'
// import NetworkError from '../Errors/Network'
// import EventBus from '../../EventBus'
// import { analytics } from '../../firebase'

export default class EKomplet {
    
    //API is documented at https://apidoc.e-komplet.dk/
    ekompletBaseUrl = "https://externalaccessapi.e-komplet.dk" //TODO: Move to firebase
    ekompletAPIKey = "9a4bf618-dc54-4fd1-b1ea-0429ed6335c4" //TODO: Move to firebase
    ekompletSiteName = "fiberlan" //TODO: Move to firebase

    AxiosConfig = {
        headers: {
            'apikey': this.ekompletAPIKey,
            'Content-Type': 'application/json',
            'siteName': this.ekompletSiteName
        },
    }

    async callAPI(method, endpoint, query, payload) {
        try {
            let response;

            let combinedResponseObject = {
                data: [{
                    data: [],
                    total: 0,
                    aggregateResults: [],
                    errors: null,
                }],
                errorMessage: null,
                validationErrors: [],
                modelStateErrors: {},
                hasErrors: false,
            }
            let objectCount = 0
            let page = 1
            const maxPages = 100
            const pageSize = 1000
            let finishedQuery

            switch (method) {
                case 'post':
                    response = await axios.post(`${this.ekompletBaseUrl}${endpoint}`, payload, this.AxiosConfig)
                    break;
                case 'get':
                default: 
                    if (query && query.includes('page=')) { //If query already contains a page number, don't automatically handle pagination
                        response = await axios.get(`${this.ekompletBaseUrl}${endpoint}?${query}`, this.AxiosConfig)
                        return response
                    }
                    do {
                        finishedQuery = query ? `${query}&page=${page}&pageSize=${pageSize}` : `page=${page}&pageSize=${pageSize}`
                        response = await axios.get(`${this.ekompletBaseUrl}${endpoint}?${finishedQuery}`, this.AxiosConfig)
                        // console.log(response)

                        combinedResponseObject.data[0].data = combinedResponseObject.data[0].data.concat(response.data?.data?.[0]?.data || []) //Add data to combined response
                        if (response.data?.[0]?.errors) combinedResponseObject.data[0].errors = response.data[0].errors //Add errors to combined response
                        if (response.errorMessage) combinedResponseObject.errorMessage = response.errorMessage //Add errorMessage to combined response
                        if (response.validationErrors) combinedResponseObject.validationErrors.concat(response.validationErrors) //Add validationErrors to combined response
                        if (response.hasErrors) combinedResponseObject.hasErrors = response.hasErrors //Add hasErrors to combined response

                        objectCount += response.data?.data?.[0]?.data.length || 0
                        page++
                        if (page >= maxPages) {
                            console.error(`Reached max page count of ${maxPages} in callAPI`)
                        }
                    } while (objectCount < response.data?.data?.[0].total && page < maxPages) //Max 100 pages
                    break;
            }
            // console.log(combinedResponseObject)
            return {data: combinedResponseObject}
        } catch (error) {
            console.error(error)
            throw error
        }
    }

    /**
     * Get salary periods from eKomplet
     * @param {Date} fromDate 
     * @param {Date} toDate 
     * @returns {Array} of salary period objects
     */
    async getSalaryPeriods(typeName, fromDate, toDate){
        //Handle missing parameters
        if (!fromDate) { //If no fromDate is provided, default to thirty days ago
            fromDate = new Date()
            fromDate.setHours(0,0,0,0)
            fromDate.setDate(fromDate.getDate() - 40) 
        }
        if (!toDate) { //IF no toDate is provided, default to two weeks from now
            toDate = new Date()
            toDate.setHours(0,0,0,0)
            toDate.setDate(toDate.getDate() + 30) //One extra day to make sure we get the current salary period
        }

        //Build query
        let query = `fromDate=${fromDate.toISOString()}&toDate=${toDate.toISOString()}`
        if (typeName) {
            query += `&searchAttribute=Type&search=${typeName}`
        }

        //Call API
        let response = null
        try {
            response = await this.callAPI('get', `/api/v3.0/salaryperiods`, query)
            // console.log(response)
        } catch (error) {
            throw new Error(`Failed to get salary periods from eKomplet. Error: ${JSON.stringify(error)}`)
        }
        return response.data?.data?.[0]?.data?.sort((a,b) => new Date(b.FromDate) - new Date(a.FromDate)) || [] //Sorted by date descending
    }

    async getFitterHours(fromDate, toDate, searchAttribute, search, searchAttribute2, search2) {
        //Guard against missing parameters
        if (!fromDate || !toDate) {
            throw new Error('getFitterHours: Missing parameters')
        }
        if (fromDate > toDate) {
            throw new Error('getFitterHours: fromDate must be before toDate')
        }

        //Build query
        let query = `fromDate=${fromDate.toISOString()}&toDate=${toDate.toISOString()}` 
        if (searchAttribute && search) {
            query += `&searchAttribute=${searchAttribute}&search=${search}`
        }
        if (searchAttribute2 && search2) {
            query += `&searchAttribute=${searchAttribute2}&search=${search2}`
        }

        //Call API
        try {
            let response = await this.callAPI('get', `/api/v3.0/fitterhours`, query)
            // console.log(response)
            return response.data?.data?.[0]?.data?.sort((a,b) => a.Date > b.Date ? 1 : -1) //Sorted by date ascending
        } catch (error) {
            throw new Error(`getFitterHours: Failed to get fitter hours from eKomplet. Error: ${JSON.stringify(error)}`)
        }
    }

    async getFitterCategories() {
        try {
            let response = await this.callAPI('get', '/api/v3.0/fittercategories')
            let responseData = response.data?.data?.[0]?.data || []
            responseData = responseData.filter((category) => category.ShowInApp == true).sort((a,b) => a.Reference-b.Reference)
            return responseData
        } catch (error) {
            throw new Error(`getFitterCategories: Failed to get fitter categories from eKomplet. Error: ${JSON.stringify(error.message)}`)
        }
    }

    async getFitters(resourceGroup) {
        try {
            let query
            if (resourceGroup) {
                query = `searchAttribute=RessourceGroupString&search=${resourceGroup}&${query}`
            }
            let response = await this.callAPI('get', `/api/v3.0/fitters`, query) 
            // console.log(response)
            let responseData = response.data?.data?.[0]?.data || []
            responseData = responseData.filter((fitter) => !fitter.EndDate).sort((a,b) => (a.Name < b.Name ? -1 : 1))
            return responseData
        } catch (error) {
            throw new Error(`getFitters: Failed to get fitters from eKomplet. Error: ${JSON.stringify(error.message)}`)
        }
    }

    async getFitter(initials) {
        try {
            let query = `searchAttribute=SalaryID&search=${initials}`
            let response = await this.callAPI('get', `/api/v3.0/fitters`, query) 
            let responseData = response.data?.data?.[0]?.data || []
            if (responseData.length == 1) {
                return responseData[0]
            } else {
                throw new Error(`getFitter: Expected search for initials ${initials} to mach 1 fitter, but matched ${responseData.length}`)
            }
        } catch (error) {
            throw new Error(`getFitter: Failed to get fitter with initials '${initials}' from eKomplet. Error: ${JSON.stringify(error.message)}`)
        }
    }

    async getProjects() {
            try {
                let query = null //'searchAttribute=Department&search=ODENSE'
                let response = await this.callAPI('get', `/api/v3.0/projects`, query)
                let responseData = response.data?.data?.[0]?.data || []
                responseData = responseData.sort((a,b) => a.LastFitterHourDate > b.LastFitterHourDate ? -1 : 1)
                return responseData
            } catch (error) {
                throw new Error(`getFitterCategories: Failed to get projects from eKomplet. Error: ${JSON.stringify(error.message)}`)
            }
    }

    // POST REQUESTS - POST REQUESTS - POST REQUESTS - POST REQUESTS - POST REQUESTS - POST REQUESTS - POST REQUESTS - POST REQUESTS

    async postFitterHours(data) {
        if (!data) {
            throw new Error('postFitterHours called with no data to post')
        }
        if (!Array.isArray(data)) {
            data = [data]
        }
        console.log(data)
        try {
            let response = await this.callAPI('post', `/api/v3.0/fitterhours`, null, data)
            console.log(response)
            // if (response?.data?.HasErrors){
            //     throw new Error(`postFitterHours returned the following error(s): ${JSON.stringify(response?.data?[0]?.data)}`)
            // }
            return response
        } catch (error) {
            throw new Error(`postFitterHours: Failed to post hours to eKomplet. Error: ${JSON.stringify(error.message)}`)
        }
    }
}