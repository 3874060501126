
class ProjectStatus {

    static PLANNED = 5
    static IN_PROGRESS = 1
    static CLOSED = 10

    static getValueFromCode(code) {
        switch(code) {
            case this.PLANNED:
                return 'Planlagt'
            
            case this.IN_PROGRESS:
                return 'Igangværende'
            
            case this.CLOSED:
                return 'Afsluttet'
            
            default:
                return 'unknown'
        }
    }

    static getCodeFromValue(value) {
        switch(value) {
            case 'Planlagt':
                return this.PLANNED
            
            case 'Igangværende':
                return this.IN_PROGRESS
            
            case 'Afsluttet':
                return this.CLOSED
            
            default:
                return 'unknown'
        }
    }

    static getColorFromCode(code) {
        switch(code) {
            case this.PLANNED:
                return 'yellow'
            
            case this.IN_PROGRESS:
                return 'green'
            
            case this.CLOSED:
                return 'grey'
            
            default:
                return 'black'
        }
    }

}

export default ProjectStatus

