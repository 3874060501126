import { Mixin } from './mixin.js'
import { DateMixin } from './dateMixin.js'
import TaskState from '../Enums/TaskState.js'
import TicketState from '../Enums/TicketState.js'

export const mapMarkerMixin = {
    mixins: [Mixin, DateMixin],
    enums: [TaskState, TicketState],
    methods: { 
        getMarkerTypeFromInstallation(ins, activeInsLabel) {
            let appointmentType = this.getCurrentAppointmentType(ins)
            if (ins?.label == activeInsLabel) {
                return `${appointmentType}-active`
            }
            let status = 'pending' //STATUSES: pending, unbooked, draft, booked, active, overdue
            if (ins?.tasks?.length) {
                for (let task of ins.tasks) {
                    if (task?.number?.substring(0,3) == 'TRT' && TicketState.openStatesArray.includes(task.state?.value)) {
                        status = 'unbooked'
                        break;
                    } else if (task?.number?.substring(0,3) == 'TRT' && task.state?.value == TicketState.ON_HOLD) {
                        status = 'onhold'
                        break;
                    } else if (task?.number?.substring(0,3) != 'TRT' && TaskState.openStatesArray.includes(task.state?.value)) {
                        status = 'unbooked'
                        break;
                    } else if (task?.number?.substring(0,3) != 'TRT' && task.state?.value == TaskState.ON_HOLD) {
                        status = 'onhold'
                        break;
                    }
                }
            } else {
                console.error('installation has no tasks', ins)
            }
            if (ins?.appointments) {
                let appointmentKeys = Object.keys(ins.appointments)
                if (appointmentKeys.length) {
                    status = 'booked'
                    for (let key of appointmentKeys) {
                        let thisappointment = ins.appointments[key]
                        if (!thisappointment.Confirmed) {
                            status = 'draft'
                        }
                        if (thisappointment.timeWindowString < this.formatMachineDate(new Date(), '/')) {
                            status = 'overdue'
                        }
                    }
                    
                }
            }
            return `${appointmentType}-${status}`
        }
    },
}