import EventBus from "../../EventBus.js"
import { DataAPI } from "../DataAPI.js"
import { db } from "../../firebase.js"
import AppointmentState from '@/lib/Enums/AppointmentState.js'
export const TransferMixin = {

    mixins: [
        DataAPI, // Import the DataAPI mixin
    ],

    enums: {
        AppointmentState,
    },

    methods: {
        
        async transferUnitWork(data){
            EventBus.$emit('function-activity', {functionName: 'TransferMixin_transferUnitWork', isActive: true})
            EventBus.$emit('unitwork-transfer-started')
            let unitWorkArray = []
            if (data.selection === 'all'){
                let allUnitWorkForInst = await this.dataGetUnitWorkForInst(data.unitWork.ConfigurationItem.Label)
                unitWorkArray = allUnitWorkForInst
            } else {
                unitWorkArray.push(data.unitWork)
            }

            let i = 0
            for (let unitWork of unitWorkArray) {
                i++;
                let progress = Math.round((i / unitWorkArray.length) * 100)
                await this.copyOrMoveUnitWork(unitWork, data.currentProjectId, data.targetProjectId, data.moveMode)
                EventBus.$emit('unitwork-transfer-progress-percentage', progress)
            }
            EventBus.$emit('function-activity', {functionName: 'TransferMixin_transferUnitWork', isActive: false})
        },

        async copyOrMoveUnitWork(unitWork, currentProjectId, targetProjectId, mode = "move"){
            if (!unitWork || !currentProjectId || !targetProjectId) return

            EventBus.$emit('function-activity', {functionName: 'TransferMixin_copyOrMoveUnitWork', isActive: true})

            let linkedProjects = []
            if (mode === 'copy'){
                linkedProjects = [currentProjectId, targetProjectId]
            } else {
                linkedProjects = [targetProjectId]
            }

            unitWork.LinkedProjects = linkedProjects
            await this.dataGenericUpdateDocument('UnitWork', unitWork.id, unitWork)
            EventBus.$emit('function-activity', {functionName: 'TransferMixin_copyOrMoveUnitWork', isActive: false})
        },

        async copyOrMoveAppointment(appointment, currentProjectId, targetProjectId, mode = "move"){
            if (!appointment || !currentProjectId || !targetProjectId) return

            EventBus.$emit('function-activity', {functionName: 'TransferMixin_copyOrMoveAppointment', isActive: true})

            let linkedProjects = []
            if (mode === 'copy'){
                linkedProjects = appointment.LinkedProjects ? [...appointment.LinkedProjects, targetProjectId] : [currentProjectId, targetProjectId]
            } else {
                linkedProjects = [targetProjectId]
            }

            appointment.LinkedProjects = linkedProjects
            await this.dataGenericUpdateDocument('Appointments', appointment.id, appointment)
            EventBus.$emit('function-activity', {functionName: 'TransferMixin_copyOrMoveAppointment', isActive: false})
        },

        async transferAppointments(currentProjectId, targetProjectId){
            EventBus.$emit('function-activity', {functionName: 'TransferMixin_transferAppointments', isActive: true})
            
            // Get all appointments for the current project
            let response = await db.collection('Appointments').where('LinkedProjects', 'array-contains', currentProjectId)
            .where('State', '==', AppointmentState.ACTIVE).get()

            let appointmentArray = []
            response.forEach((doc) => {
                let _obj = doc.data()
                _obj.id = doc.id
                appointmentArray.push(_obj)
            })

            // Move each appointment to the target project
            let promises = []
            appointmentArray.forEach(appointment => {
                promises.push(this.copyOrMoveAppointment(appointment, currentProjectId, targetProjectId))
            })
            Promise.all(promises)

            console.log("Found appointments", appointmentArray)
            console.log("target project id", targetProjectId)
            EventBus.$emit('function-activity', {functionName: 'TransferMixin_transferAppointments', isActive: false})
        },

        async copyOrMoveInternalSubtask(subtask, currentProjectId, targetProjectId, mode = "move"){
            if (!subtask || !currentProjectId || !targetProjectId) return

            EventBus.$emit('function-activity', {functionName: 'TransferMixin_copyOrMoveInternalSubtask', isActive: true})

            let linkedProjects = []
            if (mode === 'copy'){
                linkedProjects = subtask.LinkedProjects ? [...subtask.LinkedProjects, targetProjectId] : [currentProjectId, targetProjectId]
            } else {
                linkedProjects = [targetProjectId]
            }

            subtask.LinkedProjects = linkedProjects
            await this.dataGenericUpdateDocument('InternalSubTasks', subtask.id, subtask)
            EventBus.$emit('function-activity', {functionName: 'TransferMixin_copyOrMoveInternalSubtask', isActive: false})
        },

        async copyOrMoveInternalSubtasksForInst(installationLabel, currentProjectId, targetProjectId, mode = "move"){
            EventBus.$emit('function-activity', {functionName: 'TransferMixin_copyOrMoveInternalSubtasksForInst', isActive: true})

            let subtasks = await this.dataGetSubtasksForInst(installationLabel)

            let promises = []
            subtasks.forEach(subtask => {
                promises.push(this.copyOrMoveInternalSubtask(subtask, currentProjectId, targetProjectId, mode))
            })
            Promise.all(promises)

            EventBus.$emit('function-activity', {functionName: 'TransferMixin_copyOrMoveInternalSubtasksForInst', isActive: false})
        },

        async transferInternalSubtaskForAllActiveAppointments(apptProjectId, currentProjectId, targetProjectId){
            EventBus.$emit('function-activity', {functionName: 'TransferMixin_transferInternalSubtaskForAllActiveAppointments', isActive: true})

            let response = await db.collection('Appointments').where('LinkedProjects', 'array-contains', apptProjectId)
            .where('State', '==', AppointmentState.ACTIVE).get()

            let appointmentArray = []
            response.forEach((doc) => {
                let _obj = doc.data()
                _obj.id = doc.id
                appointmentArray.push(_obj)
            })
            console.log("Found appointments", appointmentArray)
            console.log("target project id", currentProjectId, targetProjectId)

            let promises = []
            appointmentArray.forEach(appointment => {
                promises.push(this.copyOrMoveInternalSubtasksForInst(appointment.InstallationLabel, currentProjectId, targetProjectId))
            })
            Promise.all(promises)

            EventBus.$emit('function-activity', {functionName: 'TransferMixin_transferInternalSubtaskForAllActiveAppointments', isActive: false})
        },

        async transferInternalSubtasksForAllNonBookedInstallations(installations, currentProjectId, targetProjectId){
            EventBus.$emit('function-activity', {functionName: 'TransferMixin_transferInternalSubtasksForAllNonBookedInstallations', isActive: true})

            console.log("Found installations", installations, currentProjectId, targetProjectId)

            let response = await db.collection('Appointments').where('LinkedProjects', 'array-contains', currentProjectId)
            .where('State', '==', AppointmentState.ACTIVE).get()

            let appointmentInstallationLabelArray = []
            response.forEach((doc) => {
                let _obj = doc.data()
                appointmentInstallationLabelArray.push(_obj.InstallationLabel)
            })

            let promises = []
            for (let key of Object.keys(installations)){
                installations[key].forEach(installation => {
                    if (appointmentInstallationLabelArray.includes(installation.label)) return
                    // console.log('Installation', installation.label)
                    promises.push(this.copyOrMoveInternalSubtasksForInst(installation.label, currentProjectId, targetProjectId))
                })
            }
            // installations.forEach(installation => {
            //     promises.push(this.copyOrMoveInternalSubtasksForInst(installation.Label, currentProjectId, targetProjectId))
            // })
            Promise.all(promises)

            EventBus.$emit('function-activity', {functionName: 'TransferMixin_transferInternalSubtasksForAllNonBookedInstallations', isActive: false})
        },
    },
}
