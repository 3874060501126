export const Maps = {

    methods: {

        getCluster(clusterGroup, callback) {
            let vm = this
            let counter = 0
            function checkForCluster() {
                if (vm.clusterGroups[clusterGroup].cluster) callback(vm.clusterGroups[clusterGroup].cluster)
                else {
                    counter += 1
                    setTimeout(checkForCluster, 200)
                }
            }
            if (counter > 100) throw new Error(`Could not find cluster after ${counter} attempts`)
            checkForCluster()
            // return this.clusterGroups[clusterGroup].cluster
        },
        
        getMap(callback) {
            let vm = this
            let counter = 0
            function checkForMap() {
                if (vm.map) callback(vm.map)
                else {
                    counter += 1
                    setTimeout(checkForMap, 200)
                }
            }
            if (counter > 100) throw new Error(`Could not find cluster after ${counter} attempts`)
            checkForMap()
        }
    }

}

export const MapMarkerImages = {
    'Yellow': 'data:image/svg+xml;base64,ICAgICAgICA8c3ZnCiAgICAgICAgICAgIHhtbG5zOmRjPSJodHRwOi8vcHVybC5vcmcvZGMvZWxlbWVudHMvMS4xLyIKICAgICAgICAgICAgeG1sbnM6Y2M9Imh0dHA6Ly9jcmVhdGl2ZWNvbW1vbnMub3JnL25zIyIKICAgICAgICAgICAgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIgogICAgICAgICAgICB4bWxuczpzdmc9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogICAgICAgICAgICB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCiAgICAgICAgICAgIGlkPSJzdmc4IgogICAgICAgICAgICB2ZXJzaW9uPSIxLjEiCiAgICAgICAgICAgIHZpZXdCb3g9IjAgMCA1LjI5MTY2NjUgNS40MjM5NjAyIgogICAgICAgICAgICBoZWlnaHQ9IjI1IgogICAgICAgICAgICB3aWR0aD0iMjUiPgogICAgICAgICAgICA8ZGVmcwogICAgICAgICAgICAgICAgaWQ9ImRlZnMyIiAvPgogICAgICAgICAgICA8ZwogICAgICAgICAgICAgICAgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTk5Ljg2NzcyNCwtMTk4LjYwMzEzKSIKICAgICAgICAgICAgICAgIGlkPSJsYXllcjEiPgogICAgICAgICAgICAgICAgPGNpcmNsZQogICAgICAgICAgICAgICAgcj0iMi41MTM1NDE3IgogICAgICAgICAgICAgICAgc3R5bGU9Im9wYWNpdHk6MTtmaWxsOiNjM2FkMDA7ZmlsbC1vcGFjaXR5OjE7c3Ryb2tlOiNmZmZmZmY7c3Ryb2tlLXdpZHRoOjAuMjY0NTgzMzI7c3Ryb2tlLW1pdGVybGltaXQ6NDtzdHJva2UtZGFzaGFycmF5Om5vbmUiCiAgICAgICAgICAgICAgICBpZD0iY2lyY2xlODIxIgogICAgICAgICAgICAgICAgY3g9IjEwMi41MTM1NiIKICAgICAgICAgICAgICAgIGN5PSIyMDEuMjAwMDAiIC8+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L3N2Zz4KICAgIA==',
    'Blue': 'data:image/svg+xml;base64,CiAgICAgICAgPHN2ZwogICAgICAgICAgICB4bWxuczpkYz0iaHR0cDovL3B1cmwub3JnL2RjL2VsZW1lbnRzLzEuMS8iCiAgICAgICAgICAgIHhtbG5zOmNjPSJodHRwOi8vY3JlYXRpdmVjb21tb25zLm9yZy9ucyMiCiAgICAgICAgICAgIHhtbG5zOnJkZj0iaHR0cDovL3d3dy53My5vcmcvMTk5OS8wMi8yMi1yZGYtc3ludGF4LW5zIyIKICAgICAgICAgICAgeG1sbnM6c3ZnPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKICAgICAgICAgICAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogICAgICAgICAgICBpZD0ic3ZnOCIKICAgICAgICAgICAgdmVyc2lvbj0iMS4xIgogICAgICAgICAgICB2aWV3Qm94PSIwIDAgNS4yOTE2NjY1IDUuNDIzOTYwMiIKICAgICAgICAgICAgaGVpZ2h0PSIyNSIKICAgICAgICAgICAgd2lkdGg9IjI1Ij4KICAgICAgICAgICAgPGRlZnMKICAgICAgICAgICAgICAgIGlkPSJkZWZzMiIgLz4KICAgICAgICAgICAgPGcKICAgICAgICAgICAgICAgIHRyYW5zZm9ybT0idHJhbnNsYXRlKC05OS44Njc3MjQsLTE5OC42MDMxMykiCiAgICAgICAgICAgICAgICBpZD0ibGF5ZXIxIj4KICAgICAgICAgICAgICAgIDxjaXJjbGUKICAgICAgICAgICAgICAgIHI9IjIuNTEzNTQxNyIKICAgICAgICAgICAgICAgIHN0eWxlPSJvcGFjaXR5OjE7ZmlsbDojMDM3YmU0O2ZpbGwtb3BhY2l0eToxO3N0cm9rZTojZmZmZmZmO3N0cm9rZS13aWR0aDowLjI2NDU4MzMyO3N0cm9rZS1taXRlcmxpbWl0OjQ7c3Ryb2tlLWRhc2hhcnJheTpub25lIgogICAgICAgICAgICAgICAgaWQ9ImNpcmNsZTgyMSIKICAgICAgICAgICAgICAgIGN4PSIxMDIuNTEzNTYiCiAgICAgICAgICAgICAgICBjeT0iMjAxLjIwMDAwIiAvPgogICAgICAgICAgICA8L2c+CiAgICAgICAgPC9zdmc+CiAgICA=',
    'Grey': 'data:image/svg+xml;base64,ICAgICAgICA8c3ZnCiAgICAgICAgICAgIHhtbG5zOmRjPSJodHRwOi8vcHVybC5vcmcvZGMvZWxlbWVudHMvMS4xLyIKICAgICAgICAgICAgeG1sbnM6Y2M9Imh0dHA6Ly9jcmVhdGl2ZWNvbW1vbnMub3JnL25zIyIKICAgICAgICAgICAgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIgogICAgICAgICAgICB4bWxuczpzdmc9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogICAgICAgICAgICB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCiAgICAgICAgICAgIGlkPSJzdmc4IgogICAgICAgICAgICB2ZXJzaW9uPSIxLjEiCiAgICAgICAgICAgIHZpZXdCb3g9IjAgMCA1LjI5MTY2NjUgNS40MjM5NjAyIgogICAgICAgICAgICBoZWlnaHQ9IjI1IgogICAgICAgICAgICB3aWR0aD0iMjUiPgogICAgICAgICAgICA8ZGVmcwogICAgICAgICAgICAgICAgaWQ9ImRlZnMyIiAvPgogICAgICAgICAgICA8ZwogICAgICAgICAgICAgICAgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTk5Ljg2NzcyNCwtMTk4LjYwMzEzKSIKICAgICAgICAgICAgICAgIGlkPSJsYXllcjEiPgogICAgICAgICAgICAgICAgPGNpcmNsZQogICAgICAgICAgICAgICAgcj0iMi41MTM1NDE3IgogICAgICAgICAgICAgICAgc3R5bGU9Im9wYWNpdHk6MTtmaWxsOiM2NjY7ZmlsbC1vcGFjaXR5OjE7c3Ryb2tlOiNmZmZmZmY7c3Ryb2tlLXdpZHRoOjAuMjY0NTgzMzI7c3Ryb2tlLW1pdGVybGltaXQ6NDtzdHJva2UtZGFzaGFycmF5Om5vbmUiCiAgICAgICAgICAgICAgICBpZD0iY2lyY2xlODIxIgogICAgICAgICAgICAgICAgY3g9IjEwMi41MTM1NiIKICAgICAgICAgICAgICAgIGN5PSIyMDEuMjAwMDAiIC8+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L3N2Zz4KICAgIA==',
    'Light green': 'data:image/svg+xml;base64,ICAgICAgICA8c3ZnCiAgICAgICAgICAgIHhtbG5zOmRjPSJodHRwOi8vcHVybC5vcmcvZGMvZWxlbWVudHMvMS4xLyIKICAgICAgICAgICAgeG1sbnM6Y2M9Imh0dHA6Ly9jcmVhdGl2ZWNvbW1vbnMub3JnL25zIyIKICAgICAgICAgICAgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIgogICAgICAgICAgICB4bWxuczpzdmc9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogICAgICAgICAgICB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCiAgICAgICAgICAgIGlkPSJzdmc4IgogICAgICAgICAgICB2ZXJzaW9uPSIxLjEiCiAgICAgICAgICAgIHZpZXdCb3g9IjAgMCA1LjI5MTY2NjUgNS40MjM5NjAyIgogICAgICAgICAgICBoZWlnaHQ9IjI1IgogICAgICAgICAgICB3aWR0aD0iMjUiPgogICAgICAgICAgICA8ZGVmcwogICAgICAgICAgICAgICAgaWQ9ImRlZnMyIiAvPgogICAgICAgICAgICA8ZwogICAgICAgICAgICAgICAgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTk5Ljg2NzcyNCwtMTk4LjYwMzEzKSIKICAgICAgICAgICAgICAgIGlkPSJsYXllcjEiPgogICAgICAgICAgICAgICAgPGNpcmNsZQogICAgICAgICAgICAgICAgcj0iMi41MTM1NDE3IgogICAgICAgICAgICAgICAgc3R5bGU9Im9wYWNpdHk6MTtmaWxsOiM1OEQ2OEQ7ZmlsbC1vcGFjaXR5OjE7c3Ryb2tlOiNmZmZmZmY7c3Ryb2tlLXdpZHRoOjAuMjY0NTgzMzI7c3Ryb2tlLW1pdGVybGltaXQ6NDtzdHJva2UtZGFzaGFycmF5Om5vbmUiCiAgICAgICAgICAgICAgICBpZD0iY2lyY2xlODIxIgogICAgICAgICAgICAgICAgY3g9IjEwMi41MTM1NiIKICAgICAgICAgICAgICAgIGN5PSIyMDEuMjAwMDAiIC8+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L3N2Zz4KICAgIA==',
    'Green': 'data:image/svg+xml;base64,ICAgICAgICA8c3ZnCiAgICAgICAgICAgIHhtbG5zOmRjPSJodHRwOi8vcHVybC5vcmcvZGMvZWxlbWVudHMvMS4xLyIKICAgICAgICAgICAgeG1sbnM6Y2M9Imh0dHA6Ly9jcmVhdGl2ZWNvbW1vbnMub3JnL25zIyIKICAgICAgICAgICAgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIgogICAgICAgICAgICB4bWxuczpzdmc9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogICAgICAgICAgICB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCiAgICAgICAgICAgIGlkPSJzdmc4IgogICAgICAgICAgICB2ZXJzaW9uPSIxLjEiCiAgICAgICAgICAgIHZpZXdCb3g9IjAgMCA1LjI5MTY2NjUgNS40MjM5NjAyIgogICAgICAgICAgICBoZWlnaHQ9IjI1IgogICAgICAgICAgICB3aWR0aD0iMjUiPgogICAgICAgICAgICA8ZGVmcwogICAgICAgICAgICAgICAgaWQ9ImRlZnMyIiAvPgogICAgICAgICAgICA8ZwogICAgICAgICAgICAgICAgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTk5Ljg2NzcyNCwtMTk4LjYwMzEzKSIKICAgICAgICAgICAgICAgIGlkPSJsYXllcjEiPgogICAgICAgICAgICAgICAgPGNpcmNsZQogICAgICAgICAgICAgICAgcj0iMi41MTM1NDE3IgogICAgICAgICAgICAgICAgc3R5bGU9Im9wYWNpdHk6MTtmaWxsOiMxZWIyMzA7ZmlsbC1vcGFjaXR5OjE7c3Ryb2tlOiNmZmZmZmY7c3Ryb2tlLXdpZHRoOjAuMjY0NTgzMzI7c3Ryb2tlLW1pdGVybGltaXQ6NDtzdHJva2UtZGFzaGFycmF5Om5vbmUiCiAgICAgICAgICAgICAgICBpZD0iY2lyY2xlODIxIgogICAgICAgICAgICAgICAgY3g9IjEwMi41MTM1NiIKICAgICAgICAgICAgICAgIGN5PSIyMDEuMjAwMDAiIC8+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L3N2Zz4KICAgIA==',
    'Dark green': 'data:image/svg+xml;base64,ICAgICAgICA8c3ZnCiAgICAgICAgICAgIHhtbG5zOmRjPSJodHRwOi8vcHVybC5vcmcvZGMvZWxlbWVudHMvMS4xLyIKICAgICAgICAgICAgeG1sbnM6Y2M9Imh0dHA6Ly9jcmVhdGl2ZWNvbW1vbnMub3JnL25zIyIKICAgICAgICAgICAgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIgogICAgICAgICAgICB4bWxuczpzdmc9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogICAgICAgICAgICB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCiAgICAgICAgICAgIGlkPSJzdmc4IgogICAgICAgICAgICB2ZXJzaW9uPSIxLjEiCiAgICAgICAgICAgIHZpZXdCb3g9IjAgMCA1LjI5MTY2NjUgNS40MjM5NjAyIgogICAgICAgICAgICBoZWlnaHQ9IjI1IgogICAgICAgICAgICB3aWR0aD0iMjUiPgogICAgICAgICAgICA8ZGVmcwogICAgICAgICAgICAgICAgaWQ9ImRlZnMyIiAvPgogICAgICAgICAgICA8ZwogICAgICAgICAgICAgICAgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTk5Ljg2NzcyNCwtMTk4LjYwMzEzKSIKICAgICAgICAgICAgICAgIGlkPSJsYXllcjEiPgogICAgICAgICAgICAgICAgPGNpcmNsZQogICAgICAgICAgICAgICAgcj0iMi41MTM1NDE3IgogICAgICAgICAgICAgICAgc3R5bGU9Im9wYWNpdHk6MTtmaWxsOiMxRTg0NDk7ZmlsbC1vcGFjaXR5OjE7c3Ryb2tlOiNmZmZmZmY7c3Ryb2tlLXdpZHRoOjAuMjY0NTgzMzI7c3Ryb2tlLW1pdGVybGltaXQ6NDtzdHJva2UtZGFzaGFycmF5Om5vbmUiCiAgICAgICAgICAgICAgICBpZD0iY2lyY2xlODIxIgogICAgICAgICAgICAgICAgY3g9IjEwMi41MTM1NiIKICAgICAgICAgICAgICAgIGN5PSIyMDEuMjAwMDAiIC8+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L3N2Zz4KICAgIA==',
    'Red': 'data:image/svg+xml;base64,ICAgICAgICA8c3ZnCiAgICAgICAgICAgIHhtbG5zOmRjPSJodHRwOi8vcHVybC5vcmcvZGMvZWxlbWVudHMvMS4xLyIKICAgICAgICAgICAgeG1sbnM6Y2M9Imh0dHA6Ly9jcmVhdGl2ZWNvbW1vbnMub3JnL25zIyIKICAgICAgICAgICAgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIgogICAgICAgICAgICB4bWxuczpzdmc9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogICAgICAgICAgICB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCiAgICAgICAgICAgIGlkPSJzdmc4IgogICAgICAgICAgICB2ZXJzaW9uPSIxLjEiCiAgICAgICAgICAgIHZpZXdCb3g9IjAgMCA1LjI5MTY2NjUgNS40MjM5NjAyIgogICAgICAgICAgICBoZWlnaHQ9IjI1IgogICAgICAgICAgICB3aWR0aD0iMjUiPgogICAgICAgICAgICA8ZGVmcwogICAgICAgICAgICAgICAgaWQ9ImRlZnMyIiAvPgogICAgICAgICAgICA8ZwogICAgICAgICAgICAgICAgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTk5Ljg2NzcyNCwtMTk4LjYwMzEzKSIKICAgICAgICAgICAgICAgIGlkPSJsYXllcjEiPgogICAgICAgICAgICAgICAgPGNpcmNsZQogICAgICAgICAgICAgICAgcj0iMi41MTM1NDE3IgogICAgICAgICAgICAgICAgc3R5bGU9Im9wYWNpdHk6MTtmaWxsOiNjNzE0MWE7ZmlsbC1vcGFjaXR5OjE7c3Ryb2tlOiNmZmZmZmY7c3Ryb2tlLXdpZHRoOjAuMjY0NTgzMzI7c3Ryb2tlLW1pdGVybGltaXQ6NDtzdHJva2UtZGFzaGFycmF5Om5vbmUiCiAgICAgICAgICAgICAgICBpZD0iY2lyY2xlODIxIgogICAgICAgICAgICAgICAgY3g9IjEwMi41MTM1NiIKICAgICAgICAgICAgICAgIGN5PSIyMDEuMjAwMDAiIC8+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L3N2Zz4KICAgIA==',
    'Violet': 'data:image/svg+xml;base64,ICAgICAgICA8c3ZnCiAgICAgICAgICAgIHhtbG5zOmRjPSJodHRwOi8vcHVybC5vcmcvZGMvZWxlbWVudHMvMS4xLyIKICAgICAgICAgICAgeG1sbnM6Y2M9Imh0dHA6Ly9jcmVhdGl2ZWNvbW1vbnMub3JnL25zIyIKICAgICAgICAgICAgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIgogICAgICAgICAgICB4bWxuczpzdmc9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogICAgICAgICAgICB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciCiAgICAgICAgICAgIGlkPSJzdmc4IgogICAgICAgICAgICB2ZXJzaW9uPSIxLjEiCiAgICAgICAgICAgIHZpZXdCb3g9IjAgMCA1LjI5MTY2NjUgNS40MjM5NjAyIgogICAgICAgICAgICBoZWlnaHQ9IjI1IgogICAgICAgICAgICB3aWR0aD0iMjUiPgogICAgICAgICAgICA8ZGVmcwogICAgICAgICAgICAgICAgaWQ9ImRlZnMyIiAvPgogICAgICAgICAgICA8ZwogICAgICAgICAgICAgICAgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTk5Ljg2NzcyNCwtMTk4LjYwMzEzKSIKICAgICAgICAgICAgICAgIGlkPSJsYXllcjEiPgogICAgICAgICAgICAgICAgPGNpcmNsZQogICAgICAgICAgICAgICAgcj0iMi41MTM1NDE3IgogICAgICAgICAgICAgICAgc3R5bGU9Im9wYWNpdHk6MTtmaWxsOiNjMTgyYzE7ZmlsbC1vcGFjaXR5OjE7c3Ryb2tlOiNmZmZmZmY7c3Ryb2tlLXdpZHRoOjAuMjY0NTgzMzI7c3Ryb2tlLW1pdGVybGltaXQ6NDtzdHJva2UtZGFzaGFycmF5Om5vbmUiCiAgICAgICAgICAgICAgICBpZD0iY2lyY2xlODIxIgogICAgICAgICAgICAgICAgY3g9IjEwMi41MTM1NiIKICAgICAgICAgICAgICAgIGN5PSIyMDEuMjAwMDAiIC8+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L3N2Zz4=',
}

