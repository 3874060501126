<template>
    <sui-modal
        small
        :open="isOpen"
        v-on:clickAwayModal="closeModal()"
    >
        <sui-modal-header>
            {{toUserFriendlyDate(date)}}
            <sui-button @click="toggleShowWorkersWithoutHours" floated="right">
                <i v-if="showWorkersWithoutHours" class="far fa-check-square"/><i v-else class="far fa-square"/>
                Vis medarbejdere uden timer
            </sui-button>
        </sui-modal-header>
        <sui-modal-content scrolling>
            <sui-table>
                <sui-table-header>
                    <sui-table-row>
                        <sui-table-header-cell>Medarbjeder</sui-table-header-cell>
                        <sui-table-header-cell>Timetal</sui-table-header-cell>
                        <sui-table-header-cell>Fakturérbare timer</sui-table-header-cell>
                        <sui-table-header-cell>Fakturérbar %</sui-table-header-cell>
                    </sui-table-row>
                </sui-table-header>
                <sui-table-body>
                    <sui-table-row v-for="worker in shownTempWorkers" :key="worker.email">
                        <sui-table-cell>{{worker.name}}</sui-table-cell>
                        <sui-table-cell>
                            <sui-input type="Number" step="0.25" v-model="tempWorkers[worker.email].actualHours"/>
                        </sui-table-cell>
                        <sui-table-cell><span>{{decimalToHourString(worker.billableHours)}}</span></sui-table-cell>
                        <sui-table-cell>{{Math.round(billablePercentForWorkers[worker.email]*100)}}%</sui-table-cell>
                    </sui-table-row>
                </sui-table-body>
                <sui-table-footer>
                    <sui-table-row>
                        <sui-table-header-cell>{{Object.keys(tempWorkers).length}} Montører</sui-table-header-cell>
                        <sui-table-header-cell>{{decimalToHourString(sumWorkers(tempWorkers).actualHours)}}</sui-table-header-cell>
                        <sui-table-header-cell>{{decimalToHourString(sumWorkers(tempWorkers).billableHours)}}</sui-table-header-cell>
                        <sui-table-header-cell>{{Math.round(sumWorkers(tempWorkers).billablePercent*100)}}%</sui-table-header-cell>
                    </sui-table-row>
                </sui-table-footer>
            </sui-table>
        </sui-modal-content>
        <sui-modal-actions>
            <sui-button
                negative
                @click="closeModal"
            >
                Luk
            </sui-button>
            <sui-button
                positive
                type="submit"
                @click="saveChanges()"
            >
                Gem
            </sui-button>
        </sui-modal-actions>
    </sui-modal>
</template>
<script>
import EventBus from '../../EventBus'
import { Mixin } from '../../lib/Mixins/mixin'
import { DateMixin } from '../../lib/Mixins/dateMixin'
import { DataAPI } from '../../lib/DataAPI'

export default {
    name: 'WorkDayModal',

    mixins: [Mixin, DateMixin, DataAPI],

    props: {
        isOpen: {
            type: Boolean,
            requred: true,
            default: false,
        },
        availableWorkers: {
            type: Array,
            required: false,
        },
        user: {
            type: Object,
            required: false,
        },
        userHasLeadPermissions: {
            type: Boolean,
            required: false,
            default: false,
        },
        date: {
            type: String,
            requred: true
        },
        unitWorkDate: {
            type: Object,
            requred: false,
        },
        firWorkDay: {
            type: Object,
            requred: false,
        },
        firebaseUsers: {
            type: Array,
            required: false,
        }
    },

    data() {
        return {
            tempWorkers: {},
            showWorkersWithoutHours: localStorage.getItem('unitWorkDay-show-workers-without-hours') == 'true',
        }
    },

    computed: {
        billablePercentForWorkers(){
            let obj = {}
            for (let i in this.tempWorkers) {
                let worker = this.tempWorkers[i]
                obj[worker.email] = worker.billableHours / worker.actualHours
            }
            return obj
        },
        dateIsFriday(){
            return this.parseDate(this.date).getDay() == 5
        },
        shownTempWorkers(){
            if (this.showWorkersWithoutHours) return this.tempWorkers
            let filteredTempWorkers = {...this.tempWorkers}
            for (let key of Object.keys(filteredTempWorkers)) {
                if (!filteredTempWorkers[key].actualHours) delete filteredTempWorkers[key]
            }
            return filteredTempWorkers
        },
    },

    methods: {
        closeModal() {
            EventBus.$emit('workday-modal-close')
        },
        billableHoursForWorker(workerEmail){
            if (!this.unitWorkDate) return 0
            const units = this.unitWorkDate.units
            let hourSum = 0
            for (let i in units) {
                let unit = units[i]
                if (unit.Workers.includes(workerEmail)) {
                    hourSum += unit.EquivalentHours / unit.Workers.length
                }
            }
            return hourSum
        },
        workerHasRegisteredWork(workerEmail){
            if (!this.unitWorkDate) return false
            for (let i in this.unitWorkDate.units) {
                let unit = this.unitWorkDate.units[i]
                if (unit.Workers.includes(workerEmail)) return true
            }
            return false
        },
        generateTempWorkerObj(workerEmail){
            let tempWorkerObj = {}
            let firebaseUser = this.firebaseUsers.find(u => u.Email == workerEmail)
            tempWorkerObj.email = workerEmail
            let initials = this.getInitialsFromEmail(workerEmail)
            tempWorkerObj.name = initials
            if (firebaseUser && firebaseUser.Name) tempWorkerObj.name = `${firebaseUser.Name} (${initials})`
            else tempWorkerObj.name = initials
            // let availableWorker = this.availableWorkers.find(w => w.value == workerEmail)
            // if (availableWorker && availableWorker.text) tempWorkerObj.name = availableWorker.text
            tempWorkerObj.billableHours = this.billableHoursForWorker(workerEmail)
            // console.log(Number(this.firWorkDay?.Workers.find(w => w.Email == workerEmail)?.ActualHours))
            tempWorkerObj.actualHours = this.workerHasRegisteredWork(workerEmail) ? this.standardHoursForDate(this.date) : 0
            if (firebaseUser && firebaseUser.Roles && Array.isArray(firebaseUser.Roles) && firebaseUser.Roles.includes('Planning')) {
                tempWorkerObj.actualHours = tempWorkerObj.billableHours
            }
            if (Number(this.firWorkDay?.Workers.find(w => w.Email == workerEmail)?.ActualHours)) {
                tempWorkerObj.actualHours = Number(this.firWorkDay?.Workers.find(w => w.Email == workerEmail)?.ActualHours)
            }
            return tempWorkerObj
        },
        generateTempWorkers() {
            // console.log(`Generating tempWorkers obj from ${this.availableWorkers.length} available workers`)
            if (!this.availableWorkers || !this.availableWorkers.length) {
                // console.error('Could not generate tempWorkers obj, because availableWorkers array was empty')
                return
            }
            let tempWorkersObj = {}
            for (let worker of this.availableWorkers) {
                tempWorkersObj[worker.value] = this.generateTempWorkerObj(worker.value)
            }
            for (let workerInitials of this.unitWorkDate.workers) {
                let workerEmail = `${workerInitials.toLowerCase()}@fiberlan.dk`
                if (!tempWorkersObj[workerEmail]) {
                    tempWorkersObj[workerEmail] = this.generateTempWorkerObj(workerEmail)
                }
            }
            this.tempWorkers = tempWorkersObj
        },
        sumWorkers(workerArray){
            // console.log(workerArray)
            let actualHours = 0
            let billableHours = 0
            let billablePercent = 0

            for (let i in workerArray) {
                let worker = workerArray[i]
                actualHours += Number(worker.actualHours)
                billableHours += Number(worker.billableHours)
            }

            if (actualHours != 0){
                billablePercent = billableHours / actualHours
            }

            return {
                actualHours,
                billableHours,
                billablePercent,
            }
        },
        async saveChanges(){
            EventBus.$emit('function-activity', {functionName: 'WorkDayModal_saveChanges', isActive: true})
            this.loading = true
            let updateDoc = {
                Date: this.date,
                Workers: [],
            }
            for (let i in this.tempWorkers){
                let worker = this.tempWorkers[i]
                if (!worker.actualHours) continue; //Skip workers with no actual hours
                let workerObj = {
                    Email: worker.email,
                    ActualHours: Number(worker.actualHours),
                    Name: this.availableWorkers.find((w) => w.value == worker.email).text
                }
                updateDoc.Workers.push(workerObj)
            }
            try{
                await this.dataAddOrUpdateWorkDay(updateDoc, this.$route.params.projectIdentifier)
            } catch (e) {
                console.error(e)
            }
            this.loading = false
            EventBus.$emit('function-activity', {functionName: 'WorkDayModal_saveChanges', isActive: false})
            this.closeModal()
        },
        toggleShowWorkersWithoutHours(){
            this.showWorkersWithoutHours = !this.showWorkersWithoutHours
            localStorage.setItem('unitWorkDay-show-workers-without-hours', this.showWorkersWithoutHours)
        },
    },

    watch: {
        firWorkDay: {
            immediate: true,
            handler(){
                this.generateTempWorkers()
            }
        },
        unitWorkDate: {
            immediate: false,
            handler(){
                this.generateTempWorkers()
            }
        }
    },

    // mounted(){
        
    // },
}
</script>