<template>
    <sui-container>
        <DataCard title="Adresse Søgning">
            <p>Søger og henter data fra det danske adresse register (DAR)</p>
            <sui-form @submit.prevent="getAddresses">
                <sui-form-field>
                    <label>Vejnavn</label>
                    <input placeholder="ex. 'Kærvej'" v-model="roadName"/>
                </sui-form-field>
                <sui-form-fields>
                    <sui-form-field>
                        <label>Husnr nøjagtig</label>
                        <input placeholder="ex. '39'" v-model="numberExact"/>
                    </sui-form-field>
                    <sui-form-field>
                        <label>Husnr fra</label>
                        <input type="Number" v-model="numberFrom"/>
                    </sui-form-field>
                    <sui-form-field>
                        <label>Husnr til</label>
                        <input type="Number" v-model="numberTo"/>
                    </sui-form-field>
                    <sui-form-field>
                        <label>Etage</label>
                        <input placeholder="k9-k2, kl, st, 1-99" v-model="floor"/>
                    </sui-form-field>
                    <sui-form-field>
                        <label>Dør</label>
                        <input placeholder="1-9999 a-z / -" v-model="door"/>
                    </sui-form-field>
                </sui-form-fields>
                <sui-form-fields>
                    <sui-form-field>
                        <label>Postnr</label>
                        <input placeholder="ex. '5220'" v-model="zipCode"/>
                    </sui-form-field>
                    <sui-form-field>
                        <label>Supplerende bynavn</label>
                        <input placeholder="ex. 'Holluf Pile'" v-model="town"/>
                    </sui-form-field>
                    <sui-form-field>
                        <label>Zone</label>
                        <sui-dropdown
                            placeholder="Zone"
                            selection
                            :options="zones"
                            v-model="zone"/>
                    </sui-form-field>
                    <sui-form-field>
                        <label>Bebyggelses-type(r)</label>
                        <sui-dropdown
                            placeholder="Bebyggelses-type"
                            selection multiple
                            :options="buildTypes"
                            v-model="buildType"/>
                    </sui-form-field>
                </sui-form-fields>
                <sui-button type="submit">Søg</sui-button>
            </sui-form>
        </DataCard>
        <DataCard title="Resultater" no-padding class="overflow-x">
            <sui-table striped selectable single-line>
                <sui-table-header>
                    <sui-table-row>
                        <sui-table-header-cell>{{addresses.length}} Adresser fundet</sui-table-header-cell>
                        <sui-table-header-cell>Supplerende bynavn</sui-table-header-cell>
                        <sui-table-header-cell>Bo-areal</sui-table-header-cell>
                        <sui-table-header-cell>Erhv-areal</sui-table-header-cell>
                        <sui-table-header-cell>Værelser</sui-table-header-cell>
                        <sui-table-header-cell>Etager</sui-table-header-cell>
                        <sui-table-header-cell>Opført år</sui-table-header-cell>
                        <sui-table-header-cell>Udlejningsforhold</sui-table-header-cell>
                    </sui-table-row>
                </sui-table-header>
                <sui-table-body v-if="loading">
                    <TableLoader/>
                </sui-table-body>
                <sui-table-body v-if="!addresses.length && !loading">
                    <sui-table-row>
                        <sui-table-cell colspan="8">
                            <em>Ingen resultater.</em>
                        </sui-table-cell>
                    </sui-table-row>
                </sui-table-body>
                <sui-table-body v-if="!loading">
                    <sui-table-row v-for="address in addresses" :key="address.id">
                        <sui-table-cell>
                            <a :href="address.href" target="_blank"><i class="fas fa-info-square"></i></a>
                            {{address.adressebetegnelse}}
                        </sui-table-cell>
                        <sui-table-cell>
                            {{address.adgangsadresse.supplerendebynavn}}
                        </sui-table-cell>
                        <sui-table-cell>
                            <!-- <span v-if="address.BBR">{{address.BBR.BEBO_ARL}}</span> -->
                            <span v-html="asyncProp(address.BBR, 'BEBO_ARL', `<span class='loading-text half-w'></span>`)"></span>
                        </sui-table-cell>
                        <sui-table-cell>
                            <!-- <span v-if="address.BBR">{{address.BBR.ENH_ERHV_ARL}}</span> -->
                            <span v-html="asyncProp(address.BBR, 'ENH_ERHV_ARL', `<span class='loading-text half-w'></span>`)"></span>
                        </sui-table-cell>
                        <sui-table-cell>
                            <!-- <span v-if="address.BBR">{{address.BBR.VAERELSE_ANT}}</span> -->
                            <span v-html="asyncProp(address.BBR, 'VAERELSE_ANT', `<span class='loading-text half-w'></span>`)"></span>
                        </sui-table-cell>
                        <sui-table-cell>
                            <span v-if="address.BBR">{{address.BBR.bygning.ETAGER_ANT}}</span>
                            <!-- <span v-if="address.BBR" v-html="asyncProp(address.BBR.bygning, 'ETAGER_ANT', `<span class='loading-text half-w'></span>`)"></span> -->
                        </sui-table-cell>
                        <sui-table-cell>
                            <span v-if="address.BBR">{{address.BBR.bygning.OPFOERELSE_AAR}}</span>
                            <!-- <span v-if="address.BBR" v-html="asyncProp(address.BBR.bygning, 'OPFOERELSE_AAR', `<span class='loading-text half-w'></span>`)"></span> -->
                        </sui-table-cell>
                        <sui-table-cell>
                            <span v-if="address.BBR">{{readRentCode(address.BBR.ENH_UDLEJ2_KODE)}}</span>
                        </sui-table-cell>
                    </sui-table-row>
                </sui-table-body>
            </sui-table>
        </DataCard>
    </sui-container>
</template>
<script>
import axios from 'axios'
// import { MarkerClusterer } from '@googlemaps/markerclusterer';
// import { mapGetters } from 'vuex'
// import swal from 'sweetalert'

import { Mixin } from '../lib/Mixins/mixin'
// import { Maps, MapMarkerImages } from '../../lib/maps'
// import { DataAPI } from '../../lib/DataAPI'
// import Timeline from '../../components/Project/Timeline'
import EventBus from '../EventBus';
// import NetworkError from '../../lib/Errors/Network';
import TableLoader from '../components/TableLoader'
// import CoordinatesError from '../../lib/Errors/Coordinates';
// import { db } from '../../firebase'

export default {
    name: 'AddressSearch',
    mixins: [Mixin],

    components: {
        TableLoader
    },

    data() {
        return {
            loading: false,
            addresses: [],

            roadName: "",
            numberExact: "",
            numberFrom: null,
            numberTo: null,
            floor: "",
            door: "",
            zipCode: "",
            town: "",
            zone: null,
            buildType: [],

            zones: [
                {
                    value: null,
                    text: null
                },
                {
                    value: 1,
                    text: "Byzone"
                },
                {
                    value: 2,
                    text: "Landzone"
                },
                {
                    value: 3,
                    text: "Sommerhusområde"
                }
            ],

            buildTypes: [
                {
                    value: null,
                    text: null
                },
                {
                    value: "by",
                    text: "By"
                },
                {
                    value: "bydel",
                    text: "Bydel"
                },
                {
                    value: "spredtBebyggelse",
                    text: "Spredt bebyggelse"
                },
                {
                    value: "sommerhusområde",
                    text: "Sommerhus-område"
                },
                {
                    value: "sommerhusområdedel",
                    text: "Sommerhus-område del"
                },
                {
                    value: "industriområde",
                    text: "Industri-område"
                },
                {
                    value: "storby",
                    text: "Storby"
                }
            ]
        }
    },

    computed: {
        
    },

    methods: {
        async getAddresses() {
            EventBus.$emit('function-activity', {functionName: 'AddressSearch_getAddresses', isActive: true})
            // let Ministructure = true
            this.loading = true
            let baseURL = "https://dawa.aws.dk/adresser?"
            let params = []
            if (this.roadName) {
                params.push(`vejnavn=${this.roadName}`)
            }
            if (this.numberExact) {
                params.push(`husnr=${this.numberExact}`)
            }
            if (this.numberFrom) {
                params.push(`husnrfra=${this.numberFrom}`)
            }
            if (this.numberTo) {
                params.push(`husnrtil=${this.numberTo}`)
            }
            if (this.floor) {
                params.push(`etage=${this.floor}`)
            }
            if (this.door) {
                params.push(`dør=${this.door}`)
            }
            if (this.zipCode) {
                params.push(`postnr=${this.zipCode}`)
            }
            if (this.town) {
                params.push(`supplerendebynavn=${this.town}`)
            }
            if (this.zone) {
                params.push(`zonekode=${this.zone}`)
            }
            if (this.buildType.length) {
                let buildTypeString = this.buildType.join("|")
                params.push(`bebyggelsestype=${buildTypeString}`)
            }
            if (!params.length){
                console.error('Udfyld mindst ét søgefelt')
                this.loading = false
                return null
            }
            let paramString = params.join('&')
            let DARresponse = await axios.get(baseURL+paramString)
            this.addresses = DARresponse.data
            this.loading = false

            for (var a=0; a<DARresponse.data.length; a++){
                let BBRinfo = await this.getBBRinfo(DARresponse.data[a].id)
                this.$set(this.addresses[a], 'BBR', BBRinfo.data[0])
                DARresponse.data[a].BBR = BBRinfo.data[0]
            }

            this.addresses = DARresponse.data
            EventBus.$emit('function-activity', {functionName: 'AddressSearch_getAddresses', isActive: false})
        },

        async getBBRinfo(id){
            EventBus.$emit('function-activity', {functionName: 'AddressSearch_getBBRinfo', isActive: true})
            let baseURL = "https://dawa.aws.dk/bbrlight/enheder?"
            let response = await axios.get(baseURL+"adresseid="+id)
            EventBus.$emit('function-activity', {functionName: 'AddressSearch_getBBRinfo', isActive: false})
            return response
        },

        readRentCode(code){
            code = String(code)
            switch (code){
                case null: 
                case "0": 
                    return ""
                case "1":
                    return "Udlejet"
                case "2": 
                    return "Benyttet af ejer"
                case "3": 
                    return "Ikke benyttet"
            }
        }
    },

    beforeMount() {
    }
}
</script>
<style>
.scrollable {
    overflow-y: auto;
    overflow-x: auto;
    height: 100%;
}

.full-height {
    height: calc(100vh - 205px);
}

.half-height {
    height: calc(50vh - 110px);
}
</style>
