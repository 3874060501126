<template>
    <div>
        
        <portal to="page-actions">
            <div style="zoom: 0.85">
                <!-- <sui-dropdown
                    class="labeled icon"
                    icon="sort"
                    button
                    text="Sortering"
                    :options="sortingOptions"
                    v-model="sortingCurrent"
                /> -->
               

                <sui-button v-if="showSettings"
                    class="labeled icon button"
                    icon="cog"
                    @click="onEditSettingsButtonClicked()"
                >
                    Indstillinger
                </sui-button>
            </div>
        </portal>

        <DataCard no-padding title="Kundeliste">
            <sui-table striped single-line selectable>
                <sui-table-header>
                    <sui-table-row>
                        <sui-table-header-cell><i class="fas fa-icons"></i></sui-table-header-cell>
                        <sui-table-header-cell>Adresse</sui-table-header-cell>
                        <sui-table-header-cell>{{sortedByColumn}}</sui-table-header-cell>
                        <sui-table-header-cell>Installation</sui-table-header-cell>
                    </sui-table-row>
                </sui-table-header>
                <sui-table-body v-if="filteredInsts.length != 0">
                    <sui-table-row
                        v-for="ins in filteredInsts"
                        :key="ins.label"
                        :class="classes(insContainsOnlyState(ins, TaskState.PENDING) ? 'dis' : '')"
                        @click="$router.push({ name: 'InstallationDetail', params: {installationId: ins.instId} })"
                    >
                        <template v-if="Object.values(ins.tasks)[0] && Object.values(ins.tasks)[0].configurationItem">
                            <sui-table-cell class="iconCell">
                                <InstallationStatusIcons
                                    :installation="ins"
                                    :settings="settingsBD"
                                    :user="user"
                                    v-if="ins.tasks[0].configurationItem.type == 'Installation'"
                                />
                            </sui-table-cell>
                            <sui-table-cell>
                                <div v-if="Object.values(ins.tasks)[0].configurationItem.address">
                                    <i
                                    class="fas fa-map-marker-alt text-primary"
                                    :title="`${formatAddress(ins.tasks[0].configurationItem.address, false)}\nKlik for at navigere i Google Maps`"
                                    @click.stop="linkToGoogleMapsDirections(ins.coordinates, ins.tasks[0].configurationItem.address)"
                                ></i>
                                    {{ formatAddress(Object.values(ins.tasks)[0].configurationItem.address, false) }}
                                </div>
                                <div v-else>
                                    {{ins.label}}
                                </div>
                            </sui-table-cell>
                            <sui-table-cell> {{typeof resolveObjPath(settingsBD.get('sortInstsBy'), Object.values(ins.tasks)[0]) != typeof {} ? headerFromGroupKey(formatBookingGroup(resolveObjPath(settingsBD.get('sortInstsBy'), Object.values(ins.tasks)[0]))) : ''}}</sui-table-cell>
                            <sui-table-cell> {{ ins.label }} </sui-table-cell>
                        </template>
                    </sui-table-row>
                </sui-table-body>
                <sui-table-body v-if="installations.length == 0">
                    <sui-table-cell colspan="4">
                        <TableLoader />
                    </sui-table-cell>
                </sui-table-body>
                <sui-table-body v-if="installations.length != 0 && filteredInsts.length == 0">
                    <sui-table-cell colspan="4">
                        <em>Ingen resultater</em>
                    </sui-table-cell>
                </sui-table-body>
            </sui-table>
        </DataCard>

        <sui-accordion exclusive styled>
            <sui-accordion-title>
                <sui-icon name="dropdown" />
                Se rå data fra API'en
            </sui-accordion-title>
            <sui-accordion-content>
                <pre>{{installations}}</pre>
            </sui-accordion-content>
        </sui-accordion>

        <edit-settings-modal
            :isOpen="editSettings"
            :project="project"
            :settings="settingsBD"
            :hasProjectTasks="loadProjectTasks"
            :hasTroubleTickets="loadTickets"
            page="installation list"
        />
    </div>
</template>

<script>
import { db, auth } from '../../firebase'
import { mapGetters } from 'vuex'
import { DataAPI } from '../../lib/DataAPI'
import { Bookingmixin } from '../../lib/Bookingmixin'
import { SortingMixin } from '../../lib/Mixins/SortingMixin.js'
import { Mixin } from '../../lib/Mixins/mixin'
import { DateMixin } from '../../lib/Mixins/dateMixin'
import EventBus from '../../EventBus'
import TaskCode from '../../lib/Enums/TaskCode'
import TaskState from '../../lib/Enums/TaskState'
import AppointmentState from '../../lib/Enums/AppointmentState'
import { EmptySubTasks } from '../../lib/Enums/SubtaskType'
import EditSettingsModal from '@/modules/Settings/EditSettingsModal.vue'
import ProjectType from '../../lib/Enums/ProjectType'
import TableLoader from '../../components/TableLoader'
import InstallationStatusIcons from '../../components/Project/InstallationStatusIcons'

export default {
    name: 'InstallationList',
    mixins: [DataAPI, Bookingmixin, Mixin, DateMixin, SortingMixin],
    components: {
        TableLoader,
        InstallationStatusIcons,
        EditSettingsModal,
    },
    enums: {
        TaskCode,
        TaskState,
        ProjectType,
    },

    data() {
        return {
            appointments: [],
            searchFilterValue: '',

            firebaseInternalSubtasks: [],

            stashedTechnicals: {
                hubs: {},
                uubs: {}
            },

            inVainVisits: [],
            editSettings: false,
        }
    },

    computed: {
        ...mapGetters({
            project: 'activeProject'
        }),

        user() {
            return auth.currentUser
        },

        currentMedia() {
            return this.$root.$children[0].currentMedia
        },

        showSettings() {
            if (this.currentMedia.type == 'Mobile' && this.currentMedia.orientation == 'Portrait') return false
            return true
        },

        sortAscending() {
            if (this.settingsBD.get('instListSortAscending') == '0') return true
            return false
        },

        filteredInsts() {
            if (!this.installations) return []

            let installations = this.installations
            installations.sort(this.sortInstallations)

            installations = installations.filter(ins => {
                if (!this.settingsBD.get('showPendingTasks') && ins.tasks[0].state.value == TaskState.PENDING) return false
                if (!this.settingsBD.get('showOnHoldTasks')  && ins.tasks[0].state.value == TaskState.ON_HOLD) return false

                let appointment = this.appointments.find(app => {
                    return app.InstallationLabel == ins.label
                })

                if (appointment) {
                    let timeWindowStringObj = this.readTimeWindowString(appointment.TimeWindowString)
                    ins.appointment = appointment
                    ins.appointment.Date = timeWindowStringObj.Date
                    ins.appointment.Time = timeWindowStringObj.Time
                    ins.tasks[0].AppointmentDate = timeWindowStringObj.Date
                }

                if (this.internalSubTasks[ins.label]) {
                    ins.subtasks = Object.assign({}, ...this.internalSubTasks[ins.label].map(entry => { 
                        return { [entry.code]: entry }
                    }))
                }

                ins.inVainVisits = this.inVainVisits.filter(ivv => ivv.ConfigurationItem.Label == ins.label)

                return true
            })

            if (!this.searchFilterValue) return installations

            return installations.filter(ins => {
                return this.matchSearchCriteria(ins)
            })
        },

        internalSubTasks() {
            let outputData = {}
            const firebaseData = this.firebaseInternalSubtasks
            for (let i in firebaseData) {
                const instSubtasks = firebaseData[i]
                outputData[instSubtasks.InstallationLabel] = this.constructSubTasksArray(instSubtasks)
            }
            for (let i in this.installations){
                const inst = this.installations[i]
                if (!outputData[inst.label]) {
                    outputData[inst.label] = [...EmptySubTasks]
                }
            }
            return outputData
        },

        sortedByColumn() {
            let option = this.instListSortingOptions.find(opt => opt.value == this.settingsBD.get('sortInstsBy'))
            return option.text
        },
    },

    methods: {

        onEditSettingsButtonClicked(){
            this.editSettings = true
            console.log("Opening settings modal")
        },

        // matchSearchCriteria(ins) {
        //     let task = Object.values(ins.tasks)[0]
        //     if (!task) return true
        //     return !!(
        //         String(ins.label).toLowerCase().match(this.searchFilterValue) || 
        //         (task.configurationItem.address && String(task.configurationItem.address.city).toLowerCase().match(this.searchFilterValue)) ||
        //         (task.configurationItem.address && String(task.configurationItem.address.road).toLowerCase().match(this.searchFilterValue))
        //     )
        // },

        sortInstallations(a, b) {
            const taskA = a.tasks[0]
            const taskB = b.tasks[0]

            if (!taskA || !taskB) return 0 //One of the tasks is missing / falsy

            //Sort by selected sorting
            if (this.formatBookingGroup(this.resolveObjPath(this.settingsBD.get('sortInstsBy'), taskA)) > this.formatBookingGroup(this.resolveObjPath(this.settingsBD.get('sortInstsBy'), taskB))) return this.sortAscending ? 1 : -1
            if (this.formatBookingGroup(this.resolveObjPath(this.settingsBD.get('sortInstsBy'), taskB)) > this.formatBookingGroup(this.resolveObjPath(this.settingsBD.get('sortInstsBy'), taskA))) return this.sortAscending ? -1 : 1

            //Guard-clause checks that both tasks have an address
            if (!this.getConfiguration(taskA) || !this.getConfiguration(taskA).address || !this.getConfiguration(taskB) || !this.getConfiguration(taskB).address) {
                console.error(`Could not sort by address, due to at least one address missing`,this.getConfiguration(taskA),this.getConfiguration(taskB))
                return 0;
            }

            //Sort by address alfabetically 
            if (this.formatAddress(this.getConfiguration(taskA).address) > this.formatAddress(this.getConfiguration(taskB).address)) return this.sortAscending ? 1 : -1
            if (this.formatAddress(this.getConfiguration(taskB).address) > this.formatAddress(this.getConfiguration(taskA).address)) return this.sortAscending ? -1 : 1

            //We should very rarely reach this return statement, it is only reached when both selected sorting and formatted address are identical
            return 0;
        },

        triggerLoadBookingData() {
            // Failsafe loading of booking data. Project must be propulated before this.
            if (this.project.Name) {
                let projectTypes = this.project.Type
                if (projectTypes.indexOf(ProjectType.CABINET) != -1){
                    projectTypes.splice(projectTypes.indexOf(ProjectType.CABINET), 1)
                }
                this.bookGetBaseBookingData(this.project.AreaCodes, projectTypes, this.settingsBD.get('showPendingTasks'), this.settingsBD.get('showOnHoldTasks'), null, null, null, this.project.ReferenceIdWhitelist, this.project.ReferenceIdBlacklist) //TODO: Option for showing closed incomplete/skipped
            } else {
                console.log('Delay')
                setTimeout(this.triggerLoadBookingData, 100)
            }
        },
    },

    beforeMount() {
        this.settingsBD.setValue('bookedSelectedOptions',this.bookedSortingOptions)
        this.settingsBD.setOptions('sortUnbookedPTBy',this.unbookedSortingOptProjTasks)
        this.settingsBD.setOptions('sortUnbookedTTBy',this.unbookedSortingOptTroubleTickets)
        this.settingsBD.setOptions('sortInstsBy',this.instListSortingOptions)
        EventBus.$on('edit-settings-modal-closing', () => {this.editSettings = false})
        EventBus.$on('edit-settings-modal-open', () => {this.editSettings = true})

        EventBus.$on('top-search-type', (payload) => {
            this.searchFilterValue = String(payload).toLowerCase()
            if (!payload) this.searchFilterValue = ''
        })
    },

    mounted() {
        this.$bind('firebaseInternalSubtasks', db
            .collection(`InternalSubTasks`)
            .where('LinkedProjects', 'array-contains', this.$route.params.projectIdentifier)
        )
        this.$bind('inVainVisits', db
            .collection('UnitWork')
            .where('Unit.Id', '==', '931.F.99')
            .where('LinkedProjects', 'array-contains', this.$route.params.projectIdentifier))
        
        this.triggerLoadBookingData()

        localStorage.setItem('last-visited-page', 'installations')
    },

    watch: {
        sortAscending: {
            immediate: true,
            handler(sortAscending) {
                let sorting = sortAscending ? 'asc' : 'desc'
                this.$bind('appointments', db.collection(`Appointments`)
                    .where('LinkedProjects', 'array-contains', this.$route.params.projectIdentifier)
                    .where('State', '!=', AppointmentState.CLOSED)
                    .orderBy('State', sorting)
                    .orderBy('TimeWindowString', sorting)
                )
            },
        },
        appointments: {
            immediate: false,
            handler() {
            }
        },
        firebaseInternalSubtasks: {
            immediate: false,
            handler() {
                EventBus.$emit('reload-subtask-status-icons')
            }
        },
        project: {
            immediate: true,
            async handler(){
                
                if (this.project.Name) {
                    document.title = `${this.project.Name} Kundeliste - FiberTeam`
                    await this.settingsBD.setActiveProject(this.project)

                    if (this.project.Type.includes(ProjectType.TROUBLE_TICKETS)){ // Project includes Trouble Tickets
                        this.loadTickets = true
                        if (this.project.Type.length == 1){ // Project does not include Project Tasks
                            this.loadProjectTasks = false
                        }
                    }
                    this.hasActiveProject = true
                }
            }
        },
    }
}
</script>
<style scoped>
    table.selectable tr:hover td {
        cursor: pointer;
    }

    .fa-stopwatch {
        color: grey
    }

    .fa-check {
        color: green
    }

    .fa-times {
        color: red
    }

    .fa-calendar-edit {
        color: #58D68D
    }

    .iconCell {
        width: 20px;
    }

    tr.dis td,
    tr.dis .text-danger,
    tr.dis .text-primary {
        color: #acacac !important;
    }
</style>
