<template>
    <div :style="getStyle">
        <sui-button
            v-if="onDeleteClicked"
            :disabled="disableDeleteButton"
     
            size="mini"
            style="padding: 9.87755px; float: right;"
            :title="deleteButtonTitle"
            @click.prevent="onDeleteClicked()"
        ><i class="fa-solid fa-trash"></i></sui-button>
        <sui-button
            v-if="onEditClicked"
            :disabled="disableEditButton"
     
            size="mini"
            style="padding: 9.87755px; float: right;"
            :title="editButtonTitle"
            @click.prevent="onEditClicked()"
        ><i class="fa-solid fa-pen-to-square"></i></sui-button>
        <sui-button
            v-if="onCreateClicked"
 
            size="mini"
            style="padding: 9.87755px; float: right;"
            :title="createButtonTitle"
            @click.prevent="onCreateClicked()"
        ><i class="fa-solid fa-plus"></i></sui-button>
        <sui-button
            v-if="onMoveDownClicked"
            :disabled="disableMoveDownButton"
         
            size="mini"
            style="padding: 9.87755px; float: right;"
            :title="moveDownButtonTitle"
            @click.prevent="onMoveDownClicked()"
        ><i class="fa-solid fa-down"></i></sui-button>
        <sui-button
            v-if="onMoveUpClicked"
            :disabled="disableMoveUpButton"
    
            size="mini"
            style="padding: 9.87755px; float: right;"
            :title="moveUpButtonTitle"
            @click.prevent="onMoveUpClicked()"
        ><i class="fa-solid fa-up"></i></sui-button>
    </div>                             
</template>

<script>
    export default {
        props: {
            groupLength: Number,
            groupIndex: Number,
            componentIndex: Number,
            onDeleteClicked: Function,
            onEditClicked: Function,
            onCreateClicked: Function,
            onMoveDownClicked: Function,
            onMoveUpClicked: Function,
        },

        computed: {
            getStyle() {
                if (this.componentIndex === null){
                    return {
                        'margin-top': "-12px",
                        height: "30px",
                    }
                }
                return {
                    height: "30px",
                }
            },

            deleteButtonTitle() {
                if (this.componentIndex === null){
                    return "Slet gruppe i skalabelonen"
                }
                return "Slet element i skalabelonen"
            },

            editButtonTitle() {
                if (this.componentIndex === null){
                    return "Redigér gruppe i skalabelonen"
                }
                return "Redigér element i skalabelonen"
            },

            createButtonTitle() {
                if (this.componentIndex === null){
                    return "Tilføj ny gruppe til skalabelonen"
                }
                return "Tilføj nyt element til skalabelonen"
            },

            moveDownButtonTitle() {
                if (this.componentIndex === null){
                    return "Ryk gruppe ned i skalabelonen"
                }
                return "Ryk element ned i skalabelonen"
            },

            moveUpButtonTitle() {
                if (this.componentIndex === null){
                    return "Ryk gruppe op i skalabelonen"
                }
                return "Ryk element op i skalabelonen"
            },

            disableDeleteButton() {
                return this.groupIndex === 0 && this.componentIndex === 0 || this.groupIndex === 0 && this.componentIndex === null;
            },

            disableEditButton() {
                return this.groupIndex === 0 && this.componentIndex === 0 || this.groupIndex === 0 && this.componentIndex === null;
            },

            disableMoveDownButton() {
                if (this.groupIndex === 0 && this.componentIndex === 0) return true;
                if (this.groupIndex === 0 && this.componentIndex === null) return true;
                if (this.groupIndex === this.groupLength - 1) return true;
                if (this.componentIndex === this.groupLength - 1) return true;

                return false;
            },

            disableMoveUpButton() {
                if (this.groupIndex === 0 && this.componentIndex === 0) return true;
                if (this.groupIndex === 0 && this.componentIndex === null) return true;
                if (this.groupIndex === 1 && this.componentIndex === null) return true;
                if (this.groupIndex === 0 && this.componentIndex === 1) return true;
                if (this.componentIndex === 0) return true;

                return false;
            },
        }
    }
</script>

<style>
    
</style>