<template>
    <sui-form
        :loading="loading"
        @submit.prevent="onSaveBooking"
    >
        <sui-segment vertical>
            <h3 style="margin-top: -25px; margin-bottom: -5px;">Book installation</h3>       
        </sui-segment>
        
        <sui-segment vertical>
            <sui-form-field required>
                <label for="appointmentType">Aftale type</label>
                <sui-dropdown 
                    :options="appointmentTypeOptions" 
                    v-model="selectedAppointmentType"
                />
                <i 
                    class="fa-solid fa-triangle-exclamation"
                    style="color: red; margin-left: 5px;"
                    title="Det ser ikke ud til at denne kunde har de relevante opgaver for denne aftale type."
                    v-if="selectedAppointmentType && !validAppointmentTypesForTasks.includes(selectedAppointmentType)" 
                ></i>
            </sui-form-field>
        </sui-segment>

        <sui-segment vertical>
            <sui-form-field required :error="bookingFormError" :disabled="lockAppointment">
                <label for="bookingDate">Dato</label>
                <input type="date" required class="ui mini input" placeholder="Dato" id="bookingDate" v-model="bookingData.date" @change="onDateChanged" />
            </sui-form-field>
            <sui-form-field required :error="bookingFormError" :disabled="lockAppointment">
                <div class="inline">
                    <label for="bookingTimeFrom"> Tidsrum </label>
                </div>
                <div>
                    <input type="text" required title="Tal og/eller kolon" pattern="\d{1,2}([:]\d{1,2})*" class="ui mini input size-3" placeholder="Fra" id="bookingTimeFrom" v-model="bookingData.timeFrom" />
                    <input type="text" required title="Tal og/eller kolon" pattern="\d{1,2}([:]\d{1,2})*" class="ui mini input size-3" placeholder="Til" id="bookingTimeTo" v-model="bookingData.timeTo" />
                    <sui-dropdown pointing="top right" class="icon mini" icon="clock" button :options="suggestedTimes" v-model="selectedTime" />
                </div>
            </sui-form-field>
        </sui-segment>

        <sui-segment vertical>
            <sui-form-field>
                <label for="bookingWorker">Medarbejder</label>
                <div class="zoom-8">
                    <sui-dropdown
                        selection
                        placeholder="Medarbejder"
                        direction="downward"
                        :options="workerOptions"
                        v-model="selectedWorker"
                    />
                </div>
            </sui-form-field>
        </sui-segment>

        <sui-segment vertical>
            <label>Send påmindelse til:</label><br />
            <label><small>Mobil (SMS)</small></label>
            <div class="zoom-8">
                <sui-dropdown
                    multiple
                    fluid
                    :options="contactMobilesFormatted"
                    placeholder="SMS"
                    search
                    selection
                    @filtered="(v) => { this.contactMobileCustomInput = v }"
                    @input="onSelectMobileInput"
                    v-model="selectedContactMobiles"
                />
            </div>

            <p></p>

            <label for="sendEmail"><small>Email</small></label>
            <div class="zoom-8">
                <sui-dropdown
                    multiple
                    fluid
                    :options="contactEmailsFormatted"
                    placeholder="Email"
                    search
                    selection
                    @filtered="(v) => { this.contactEmailCustomInput = v }"
                    @input="onSelectEmailInput"
                    v-model="selectedContactEmails"
                />
            </div>
        </sui-segment>

        <sui-segment vertical>   
             
            <div class="checkbox-spacing"> 
                <sui-form-field>
                    <div class="row">
                        <div class="col-sm-9">
                            <label for="bookingConfirm">
                                <i class="fa-solid fa-file-invoice-dollar" style="color: #158FAD" title="Skriver automatisk ekstra-arbejde for telefonisk (om)booking af kunde."></i>
                                Bekræftet med kunde
                                <sui-popup flowing position="top right">
                                    <sui-popup-content>
                                        Skriver automatisk ekstra-arbejde for telefonisk (om)booking af kunde.
                                    </sui-popup-content>
                                    <sui-label
                                        basic
                                        circular
                                        class="info"
                                        size="mini"
                                        color="grey"
                                        slot="trigger"
                                    >?</sui-label>
                                </sui-popup>
                            </label>
                        </div>
                        <div class="col-sm-3">
                            <sui-checkbox toggle v-model="talkedToCustomer" @change="onChangeTalkedToCustomer" />
                        </div>
                    </div>
                </sui-form-field>
            </div>

            <div class="checkbox-spacing">
                <sui-form-field>
                    <div class="row">
                        <div class="col-sm-9">
                            <label for="bookingConfirm">
                                <i title="Gemmer aftale i PilotBI / Virkplan">
                                    <img src="../../../public/assets/icons/energi-fyn-e.png" style="width: 15px; height: 15px;">
                                </i>
                                Varslet
                                    <sui-popup flowing position="top right">
                                        <sui-popup-content>
                                            <span>Gemmer aftale i PilotBI</span><br>
                                            <em><small>(EFB aktiverer evt produkter på aftalt dato)</small></em>
                                        </sui-popup-content>
                                        <sui-label
                                            basic
                                            circular
                                            class="info"
                                            size="mini"
                                            color="grey"
                                            slot="trigger"
                                        >?</sui-label>
                                    </sui-popup>
                            </label>
                        </div>
                        <div class="col-sm-3">
                            <sui-checkbox toggle v-model="bookingData.confirmed" @change="onChangeConfirmed" />
                        </div>
                    </div>
                    <!-- <small><em>Gemmer aftale i PilotBI,<br>og sender påmindlser til kunden</em></small> -->
                </sui-form-field>
            </div>
            
            <div class="checkbox-spacing">
                <sui-form-field>
                    <div class="row">
                        <div class="col-sm-9">
                            <label>
                                <i class="fa-solid fa-lock" style="color: dimgray" title="Lås aftalen, så dato og tidspunkt ikke kan ændres"></i>
                                Lås for ombooking
                                <sui-popup flowing position="top right">
                                    <sui-popup-content>
                                        Lås aftalen, så dato og tidspunkt ikke kan ændres
                                    </sui-popup-content>
                                    <sui-label
                                        basic
                                        circular
                                        class="info"
                                        size="mini"
                                        color="grey"
                                        slot="trigger"
                                    >?</sui-label>
                                </sui-popup>
                            </label>
                        </div>
                        <div class="col-sm-3">
                            <sui-checkbox toggle v-model="lockAppointment" />
                        </div>
                    </div>
                </sui-form-field>
            </div>
            
            <div class="checkbox-spacing">
                <sui-form-field>
                    <div class="row">
                        <div class="col-sm-9">
                            <label for="bookingConfirm" :class="classes(!allowCustomerCommunication && 'grey-text')">
                                <i class="fa-solid fa-paper-plane" style="color: #2D2D2D" title="Sender SMS- og Email booking-bekræftigelse til kunde på ovenstående kontaktinfo."></i>
                                Send bekræftigelse
                                <sui-popup flowing position="top right">
                                    <sui-popup-content>
                                        Sender SMS- og Email booking-bekræftigelse til kunde på ovenstående kontaktinfo.
                                    </sui-popup-content>
                                    <sui-label
                                        basic
                                        circular
                                        class="info"
                                        size="mini"
                                        color="grey"
                                        slot="trigger"
                                    >?</sui-label>
                                </sui-popup>
                            </label>
                        </div>
                        <div class="col-sm-3">
                            <sui-checkbox toggle v-model="sendConfirmation" :disabled="!allowCustomerCommunication"/>
                        </div>
                    </div>
                </sui-form-field>
            </div>

            <div class="checkbox-spacing">
                <sui-form-field>
                    <div class="row">
                        <div class="col-sm-9">
                            <label :class="classes(!allowCustomerCommunication && 'grey-text')">
                                <i class="fa-solid fa-bell" style="color: #2D2D2D" title="Sender SMS- og Email-påmindelse, til ovenstående kontaktinfo, dagen før aftalen kl 9"></i>
                                Send p&aring;mindelser
                                <sui-popup flowing position="top right">
                                    <sui-popup-content>
                                        Sender SMS- og Email-påmindelse, til ovenstående kontaktinfo, dagen før aftalen kl 9
                                    </sui-popup-content>
                                    <sui-label
                                        basic
                                        circular
                                        class="info"
                                        size="mini"
                                        color="grey"
                                        slot="trigger"
                                    >?</sui-label>
                                </sui-popup>
                            </label>
                        </div>
                        <div class="col-sm-3">
                            <sui-checkbox toggle v-model="sendReminder" :disabled="!allowCustomerCommunication"/>
                        </div>
                    </div>
                </sui-form-field>
            </div>

            <div class="checkbox-spacing">
                <sui-form-field>
                    <div class="row">
                        <div class="col-sm-9">
                            <label>
                                <i class="fa-solid fa-flag" style="color: tomato" title="Markerer aftalen med et flag for, at gøre opmærksom på noget vigtigt der står i noterne"></i>
                                Markér med flag
                                <sui-popup flowing position="top right">
                                    <sui-popup-content>
                                        Markerer aftalen med et flag for, at gøre opmærksom på noget vigtigt der står i noterne
                                    </sui-popup-content>
                                    <sui-label
                                        basic
                                        circular
                                        class="info"
                                        size="mini"
                                        color="grey"
                                        slot="trigger"
                                    >?</sui-label>
                                </sui-popup>
                            </label>
                        </div>
                        <div class="col-sm-3">
                            <sui-checkbox toggle v-model="flagAppointment" />
                        </div>
                    </div>
                </sui-form-field>
            </div>

            <div class="checkbox-spacing">
                <sui-form-field>
                    <div class="row">
                        <div class="col-sm-9">
                            <label>
                                <i class="fa-kit fa-solid-list-clock pink-1" title="Føj kunden til listen af kunder, vi kan kontakte i tilfælde af afbud."></i>
                                <!-- Fleksibel Kunde / Venteliste -->
                                Skriv på afbudsliste
                                <sui-popup flowing position="top right">
                                    <sui-popup-content>
                                        Føj kunden til listen af kunder, vi kan kontakte i tilfælde af afbud.
                                    </sui-popup-content>
                                    <sui-label
                                        basic
                                        circular
                                        class="info"
                                        size="mini"
                                        color="grey"
                                        slot="trigger"
                                    >?</sui-label>
                                </sui-popup>
                            </label>
                        </div>
                        <div class="col-sm-3">
                            <sui-checkbox toggle v-model="addToCancellationList" />
                        </div>
                    </div>
                </sui-form-field>
            </div>

            <div class="checkbox-spacing">
                <sui-form-field>
                    <div class="row">
                        <div class="col-sm-9">
                            <label>
                                <i class="fa-solid fa-house-building" title="Markerer aftalen som værende boligforening" style="color: #007bff"></i>
                                Installation er BF
                                <sui-popup flowing position="top right">
                                    <sui-popup-content>
                                        Markerer aftalen som værende boligforening
                                    </sui-popup-content>
                                    <sui-label
                                        basic
                                        circular
                                        class="info"
                                        size="mini"
                                        color="grey"
                                        slot="trigger"
                                    >?</sui-label>
                                </sui-popup>
                            </label>
                        </div>
                        <div class="col-sm-3">
                            <sui-checkbox toggle v-model="isHouseAssociation" />
                        </div>
                    </div>
                </sui-form-field>
            </div>

            <div class="checkbox-spacing" style="margin-bottom: -10px;"> 
                <sui-form-field>
                    <div class="row">
                        <div class="col-sm-9">
                            <label for="bookingConfirm">
                                <i class="fa-solid fa-phone" style="color: #FF9A14" title="Markerer aftalen med et ikon for at gøre opmærksom på, at kunden ønsker opringning inden ankomst"></i>
                                Opringning før ankomst
                                <sui-popup flowing position="top right">
                                    <sui-popup-content>
                                        Markerer aftalen med et ikon for at gøre opmærksom på, at kunden ønsker opringning inden ankomst
                                    </sui-popup-content>
                                    <sui-label
                                        basic
                                        circular
                                        class="info"
                                        size="mini"
                                        color="grey"
                                        slot="trigger"
                                    >?</sui-label>
                                </sui-popup>
                            </label>
                        </div>
                        <div class="col-sm-3">
                            <sui-checkbox toggle v-model="callInAdvance" @change="onChangeCallInAdvance" />
                        </div>
                    </div>
                </sui-form-field>
            </div>

            <div class="checkbox-spacing" v-if="callInAdvance">
            <!-- <div class="checkbox-spacing"> -->
                <sui-form-field>
                    <sui-form-field>
                        <div class="inline">
                            <label for="callInAdvanceTime"> <small>Kunden ønsker opringning</small> </label>
                        </div>
                        <div>
                            <input type="text" required class="ui mini input size-2" placeholder="Varighed" id="callInAdvanceTime" v-model="bookingData.callInAdvanceDuration" />
                            <sui-dropdown pointing="top right" class="icon mini" icon="clock" button :options="callInAdvanceTimeOptions" v-model="selectedDurationCallInAdvance" />
                        </div>
                    </sui-form-field>

                    <label><small>før ankomst, på (tlf.)</small></label>
                    <div class="zoom-8" required>
                        <sui-dropdown      
                            fluid
                            :options="contactMobilesFormatted"
                            placeholder="Tlf. Nr."
                            search
                            selection
                            @filtered="(v) => { this.contactCallInAdvanceCustomInput = v }"
                            @input="onCallInAdvanceMobileInput"
                            v-model="selectedCallInAdavanceMobile"
                        />
                    </div>
                </sui-form-field>
            </div>
        </sui-segment>
        <sui-segment vertical>
            <sui-button
                v-if="saveButtonText != 'Gem på ikke booket'"
                primary
                size="tiny"
            >
                {{ saveButtonText }}
            </sui-button>
            <sui-button
                v-if="saveButtonText == 'Gem på ikke booket'"
                primary
                size="tiny"
                @click="onSaveNonScheduled"
            >
                {{ saveButtonText }}
            </sui-button>
            <sui-button v-bind:style="currentMedia.type == 'Mobile' ? 'margin-bottom: 60pt' : 'margin-bottom: 0pt'" 
                basic
                size="tiny"
                @click="onCloseButtonClick"
            >
                Luk
            </sui-button>
        </sui-segment>
    </sui-form>
</template>
<script>
import { mapGetters } from 'vuex'
import EventBus from '@/EventBus.js'
import TaskCode from '@/lib/Enums/TaskCode.js'
import SMSTemplate from '@/lib/Enums/SMSTemplate.js'
import EmailTemplate from '@/lib/Enums/EmailTemplate.js'
import { Mixin } from '@/lib/Mixins/mixin.js'
import { DateMixin } from '../../lib/Mixins/dateMixin'
import { Bookingmixin } from '@/lib/Bookingmixin.js'
import { DataAPI } from '@/lib/DataAPI.js'
import { db } from '../../firebase'
import AppointmentType from '@/lib/Enums/AppointmentType.js'
import ChangeLogEntry from '../../lib/changeLogEntry'
// import Vue from 'vue'
// import PortalVue from 'portal-vue'

// Vue.use(PortalVue)

export default {
    name: 'BookingFormModal',
    mixins: [Mixin, DateMixin, Bookingmixin, DataAPI],

    enums: {
        TaskCode, SMSTemplate, EmailTemplate
    },

    props: ['installationData', 'bookingDataProp', 'workers', 'loading', 'settings'],

    data() {
        return {
            bookingData: {},
            bookingFormError: null,

            selectedContactMobiles: [], // Array of keys of those that are selected from the list.
            contactMobileCustomInput: null, // Current manual input.
            contactMobileCustomInputList: [], // List of manual inputs to include.

            selectedCallInAdavanceMobile: '',
            contactCallInAdvanceCustomInput: null,

            selectedContactEmails: [], // Array of keys of those that are selected from the list.
            contactEmailCustomInput: null, // Current manual input.
            contactEmailCustomInputList: [], // List of manual inputs to include.

            callInAdvance: false,
            sendReminder: false,
            sendConfirmation: false,
            talkedToCustomer: false,
            flagAppointment: false,
            addToCancellationList: false,
            lockAppointment: false,
            isHouseAssociation: false,

            selectedTime: '',
            selectedDurationCallInAdvance: '',
            selectedWorker: null,

            appointmentTypeOptions: AppointmentType.DropdownOptions,
            selectedAppointmentType: AppointmentType.INSTALLATION,
            recomputeSaveBtnText: false,
        }
    },

    computed: {
        ...mapGetters({
            user: 'userProfile'
        }),

        currentMedia(){
            return this.$root.$children[0].currentMedia
        },

        user() {
            return this.$root.$children[0].user
        },

        task() {
            return this.installationData.tasks[0]
        },

        /**
         * Creates the list of possible phone numbers to select from.
         * Returned as 1D indexed array.
         * @returns {Array}
         */
        contactMobiles() {
            // if (!this.task || !this.task.contacts) return []
            // if (!this.task.contacts.length) return []

            let contacts = this.task.contacts
            let phoneNumbers = []
            contacts.forEach((c) => {
                if (c.mobile && this.checkIfMobile(c.mobile)) phoneNumbers.push(this.parsePhoneNumber(c.mobile))
                if (c.phone && this.checkIfMobile(c.phone)) phoneNumbers.push(this.parsePhoneNumber(c.phone))
            })

            if (String(process.env.VUE_APP_ENV).toLowerCase() == 'development') {
                // For test only!!
                phoneNumbers.push('+4530559140')
            }

            if (this.bookingData.contacts && this.bookingData.contacts.Mobiles) {
                this.bookingData.contacts.Mobiles.forEach(n => phoneNumbers.push(n))
            }

            if (this.bookingData.callInAdvance && this.bookingData.callInAdvancePhone != ''){
                phoneNumbers.push(this.bookingData.callInAdvancePhone)
            }

            // Include the list of custom made inputs
            this.contactMobileCustomInputList.forEach(n => phoneNumbers.push(n))

            // Include the custom typed text from the current "search" input.
            if (this.contactMobileCustomInput && this.contactMobileCustomInput != '') {
                phoneNumbers.push(this.contactMobileCustomInput)
            }

            let uniqueNumbers = [...new Map(phoneNumbers.map(n => [n, n])).values()]

            return uniqueNumbers
        },


        /**
         * Formats contactMobiles into the required format for Semantic UI Vue.
         * @return {Object[]}
         */
        contactMobilesFormatted() {
            return this.contactMobiles.map((n, i) => { return { value: i, key: i, text: n }})
        },


        saveButtonText(){
            this.recomputeSaveBtnText
            this.selectedTime
            this.talkedToCustomer
            if (this.bookingData.date && this.bookingData.timeTo && this.bookingData.timeFrom && this.bookingData.confirmed){
                return 'Gem Aftale'
            } else if (this.bookingData.date && this.bookingData.timeTo && this.bookingData.timeFrom){
                return 'Gem Klade'
            } else if ((!this.bookingData.date && !this.bookingData.timeTo && !this.bookingData.timeFrom && 
                (this.flagAppointment || this.selectedWorker || this.addToCancellationList || this.isHouseAssociation)) || 
                (this.installationData.updates && this.installationData.updates.id && this.installationData.updates.BookingState == 'active')){
                return 'Gem på ikke booket'
            }
            return 'Gem'
        },

        callInAdvanceTimeOptions() {
            const options = [
                { text: 'Ved ankomst', value: JSON.stringify({ data: 'ved ankomsten'})},
                { text: '15 min', value: JSON.stringify({ data: '15 min'})},
                { text: '30 min', value: JSON.stringify({ data: '30 min'})},
                { text: '45 min', value: JSON.stringify({ data: '45 min'})},
                { text: '1 time', value: JSON.stringify({ data: '1 time'})}
        ]
            return options
        },

        /**
         * Creates the list of possible email addresses to select from.
         * Returned as 1D indexed array.
         * @returns {Array}
         */
        contactEmails() {
            if (!this.task || !this.task.contacts) return []
            if (!this.task.contacts.length) return []

            let contacts = this.task.contacts
            let emails = []
            contacts.forEach((c) => {
                if (c.email) emails.push(c.email)
            })

            if (String(process.env.VUE_APP_ENV).toLowerCase() == 'development') {
                // For test only!!
                emails.push('fmb@fiberlan.dk')
            }

            let filteredEmails = emails.filter(e => this.checkIfEmail(e))

            if (this.bookingData.contacts && this.bookingData.contacts.Emails) {
                this.bookingData.contacts.Emails.forEach(e => filteredEmails.push(e))
            }

            // Include the list of custom made inputs
            this.contactEmailCustomInputList.forEach(n => filteredEmails.push(n))

            // Include the custom typed text from the current "search" input.
            if (this.contactEmailCustomInput && this.contactEmailCustomInput != '') {
                filteredEmails.push(this.contactEmailCustomInput)
            }

            let uniqueEmails = [...new Map(filteredEmails.map(e => [e, e])).values()]
            return uniqueEmails
        },


        /**
         * Formats contactMobiles into the required format for Semantic UI Vue.
         * @return {Object[]}
         */
        contactEmailsFormatted() {
            return this.contactEmails.map((e, i) => { return { value: i, key: i, text: e }})
        },

        workerOptions() {
            return [
                {text: 'Ingen', value: null},
                ...this.workers,
            ]
        },

        suggestedTimes() {
            let projectWindows = this.$parent.$parent.project.TimeWindows
            // console.log(this.$parent.$parent,projectWindows)
            if (!projectWindows || typeof projectWindows != typeof []) return [
                { text: '07:00-11:00', value: JSON.stringify({ from: '7', to: '11' }) },
                { text: '10:00-13:00', value: JSON.stringify({ from: '10', to: '13' }) },
                { text: '12:00-14:30', value: JSON.stringify({ from: '12', to: '14:30' }) },
            ]
            let optionsArr = []
            for (let tw of projectWindows) {
                optionsArr.push({
                    value: JSON.stringify(tw),
                    text: `${this.formatTimeString(tw.from)} - ${this.formatTimeString(tw.to)}`
                })
            }
            return optionsArr
        },

        availableTaskCodes() {
            if (!this.installationData || !this.installationData.tasks) return []
            let codes = []
            for (let t of this.installationData.tasks) {
                if (t.code) {
                    codes.push(t.code)
                }
            }
            return codes
        },

        validAppointmentTypesForTasks() {
            return AppointmentType.appointmentTypesFromTaskCodes(this.availableTaskCodes)
        },

        allowCustomerCommunication() {
            return AppointmentType.allowCustomerCommunication(this.selectedAppointmentType)
        }
    },

    methods: {
        /**
         * Sends event, that parent listener uses to close the modal.
         */
        onCloseButtonClick() {
            this.clearForm()
            EventBus.$emit('book-button-click')
        },

        clearForm(){
            this.callInAdvance = false
            this.sendReminder = false
            this.sendConfirmation = false
            this.talkedToCustomer = false
            this.flagAppointment = false
            this.addToCancellationList = false
            this.lockAppointment = false
            this.isHouseAssociation = false

            this.selectedTime = ''
            this.selectedDurationCallInAdvance = ''

            this.selectedWorker = null
            this.contactCallInAdvanceCustomInput = null
            this.selectedAppointmentType = AppointmentType.INSTALLATION
            this.selectedContactMobiles = []
            this.selectedCallInAdavanceMobile = ''
            this.selectedContactEmails = []
        },

        generateChangeLog(mobiles, emails, callInAdvanceNote) {
            let logEntry = new ChangeLogEntry(this.user)
            let sendToEFB = false

            if (this.bookingData.appointmentId){
                if (this.selectedAppointmentType != this.bookingData.appointmentType){
                    logEntry.setEntry('selectedAppointmentType','Aftale type',this.bookingData.appointmentType || 'N/A', this.selectedAppointmentType)
                }

                if (JSON.stringify(mobiles) != JSON.stringify(this.bookingData?.contacts?.Mobiles)){
                    logEntry.setEntry('bookingData.contacts.Mobiles', 'Mobil', this.bookingData.contacts.Mobiles || 'N/A', mobiles)
                }

                if (JSON.stringify(emails) != JSON.stringify(this.bookingData?.contacts?.Emails)){
                    logEntry.setEntry('bookingData.contacts.Emails', 'Email', this.bookingData.contacts.Emails || 'N/A', emails)
                }

                if (this.talkedToCustomer){
                    logEntry.setNote("Aftalen er bekræftet med kunden telefonisk")
                }
                
                if (this.lockAppointment != this.bookingData.lock){
                    logEntry.setEntry('bookingData.lock', 'Lås for ombooking', this.bookingData.lock || 'N/A', this.lockAppointment)
                }

                if (this.sendConfirmation){
                    logEntry.setNote("Bekræftigelse er sendt til kunden")
                }

                if (this.sendReminder != this.bookingData.sendReminder){
                    logEntry.setEntry('bookingData.sendReminder', 'Send påmindelser', this.bookingData.sendReminder || 'N/A', this.sendReminder)
                }

                if (this.flagAppointment != this.bookingData.flag){
                    logEntry.setEntry('bookingData.flag', 'Markér med flag', this.bookingData.flag || 'N/A', this.flagAppointment)
                }

                if (this.addToCancellationList != this.bookingData.isOnCancellationList){
                    logEntry.setEntry('bookingData.isOnCancellationList', 'Skriv på afbudsliste', this.bookingData.isOnCancellationList || 'N/A', this.addToCancellationList)
                }

                if (this.callInAdvance != this.bookingData.callInAdvance){
                    logEntry.setEntry('bookingData.callInAdvance', 'Opringning før ankomst', this.bookingData.callInAdvance || 'N/A', this.callInAdvance)
                }

                if (this.isHouseAssociation != this.bookingData.isHouseAssociation){
                    logEntry.setEntry('bookingData.isHouseAssociation', 'Installation er EMB', this.bookingData.isHouseAssociation || 'N/A', this.isHouseAssociation)
                }

                if (callInAdvanceNote != this.bookingData.callInAdvanceNoteBody && this.callInAdvance){
                    logEntry.setNote(callInAdvanceNote)
                }

            } else {
                logEntry.setNote("Aftalen oprettet")
            }

            if (this.bookingData.date != this.bookingDataProp.date){
                if (this.bookingData.confirmed) {sendToEFB = true}
                if (this.bookingData.appointmentId){
                    logEntry.setEntry('bookingData.date', 'Dato', this.bookingDataProp.date, this.bookingData.date)
                }
            }

            if (this.bookingData.timeFrom != this.bookingDataProp.timeFrom || this.bookingData.timeTo != this.bookingDataProp.timeTo){
                if (this.bookingData.confirmed) {sendToEFB = true}
                if (this.bookingData.appointmentId){
                    let oldVal = this.formatTimeString(this.bookingDataProp.timeFrom)+";"+this.formatTimeString(this.bookingDataProp.timeTo)
                    let newVal = this.formatTimeString(this.bookingData.timeFrom)+";"+this.formatTimeString(this.bookingData.timeTo)
                    logEntry.setEntry('bookingData.timeFrom/timeTo', 'Tidsrum', oldVal, newVal)
                }
            }

            if (this.selectedWorker != this.bookingData.worker){
                if (this.bookingData.confirmed) {sendToEFB = true}
                if (this.bookingData.appointmentId){
                    let worker = this.selectedWorker
                    if (this.selectedWorker == null){worker = 'Ingen'}
                    logEntry.setEntry('bookingData.worker', 'Medarbejder', this.bookingData.worker, worker)
                }
            }

            if (this.bookingData.confirmed != this.bookingDataProp.confirmed){
                if (this.bookingData.confirmed) {sendToEFB = true}
                if (this.bookingData.appointmentId){
                    logEntry.setEntry('bookingData.confirmed', 'Varslet', this.bookingDataProp.confirmed, this.bookingData.confirmed)
                }
            }

            if (!logEntry.entries.length){
                logEntry.setNote("Aftalen blev gemt. Ingen ændringer foretaget")
            }

            return {logEntry: logEntry.getFirebaseDoc(), sendToEFB: sendToEFB}
        },


        /**
         * Sanitizes worker for saving.
         * Parses phone numbers and emails.
         * Emits event to parent listener, to save booking in databases.
         * Conditionally uses sendConfirmations() to send confirmations to contacts.
         */
        onSaveBooking() {
            // Find worker
            let worker = null
            if (typeof this.selectedWorker === typeof undefined) { //Ensure worker cannot be undefined, use null instead.
                this.selectedWorker = null
            }
            if (this.selectedWorker !== null) {
                worker = this.selectedWorker
            }

            // Get the values of the selected mobiles.
            let contactMobiles = []
            if (this.selectedContactMobiles.length) {
                for (let i in this.selectedContactMobiles) {
                    let v = this.selectedContactMobiles[i]
                    let no = this.parsePhoneNumber(this.contactMobiles[v])
                    if (no != '') contactMobiles.push(no)
                }
            }

            // Get the values of the selected emails.
            let contactEmails = []
            if (this.selectedContactEmails.length) {
                for (let i in this.selectedContactEmails) {
                    let v = this.selectedContactEmails[i]
                    contactEmails.push(this.contactEmails[v])
                }
            }
            
            // Generate note for call in advance 
            let callInAdvanceData = {call: this.callInAdvance}
            if (this.callInAdvance){
                callInAdvanceData.duration = this.bookingData.callInAdvanceDuration
                callInAdvanceData.phone = this.contactCallInAdvanceCustomInput

                let callInAdvanceNoteBody = ''
                if (this.bookingData.callInAdvanceDuration == 'ved ankomsten'){
                    callInAdvanceNoteBody = 'Tekniker bedes ringe til kunden ved ankomsten på adressen'
                } else {
                    callInAdvanceNoteBody = `Tekniker bedes ringe til kunden ${this.bookingData.callInAdvanceDuration} før ankomsten på adressen`
                }
                if (this.contactCallInAdvanceCustomInput && this.contactCallInAdvanceCustomInput != ''){
                    callInAdvanceNoteBody = callInAdvanceNoteBody + `, på ${this.contactCallInAdvanceCustomInput}`
                }
                callInAdvanceData.noteBody = callInAdvanceNoteBody

                // Only write note if new data
                if (callInAdvanceData.noteBody != this.bookingData.callInAdvanceNoteBody){
                    EventBus.$emit('booking-form-write-call-in-advance-note',callInAdvanceData.noteBody)
                }
            }

            // Generate change log
            let log = this.generateChangeLog(contactMobiles, contactEmails, callInAdvanceData.noteBody)
            // console.log("Advicér EFB",log.sendToEFB)

            let changeLog = []
            let changeLogOverFlow = false
            if (this.bookingData?.changeLog && this.bookingData?.changeLog?.length){
                changeLog = this.bookingData.changeLog
            }
            changeLog.push(log.logEntry)
            if (changeLog.length > 20) {
                changeLogOverFlow = true
                changeLog = changeLog.slice(changeLog.length - 20)
            }

            let user = {
                displayName: this.user.displayName,
                email: this.user.email
            }

            let updateNote = ''
            if (log.sendToEFB){ // Write external note when appointment is confirmed
                let confBefore = this.bookingDataProp.confirmed
                let confAfter = this.bookingData.confirmed
                let timeWindowString = this.makeTimeWindowString(this.bookingData.date.replaceAll("/","-"), this.bookingData.timeFrom, this.bookingData.timeTo)
                if ((typeof confBefore === typeof undefined && confAfter) || (!confBefore && confAfter)){
                    let noteText = `${user.displayName} har oprettet aftale:\n`
                    noteText += `Aftale indhold: \nTidspunkt ${timeWindowString}.`
                    updateNote = noteText
                } else {
                    let noteText = `${user.displayName} har opdateret aftalen:\n`
                    let timeWindowStringOld = this.makeTimeWindowString(this.bookingDataProp.date, this.bookingDataProp.timeFrom, this.bookingDataProp.timeTo)
                    noteText += `Aftale ændret fra: ${timeWindowStringOld} \nAftale ændret til: ${timeWindowString}.`
                    updateNote = noteText
                }
            }

            let document = {
                appointmentId: this.bookingData.appointmentId,
                appointmentType: this.selectedAppointmentType,
                date: this.bookingData.date.replaceAll("/","-"),
                timeFrom: this.bookingData.timeFrom,
                timeTo: this.bookingData.timeTo,
                confirmed: this.bookingData.confirmed,
                worker,
                contactMobiles,
                contactEmails,
                sendReminder: this.sendReminder || false,
                talkedToCustomer: this.talkedToCustomer || false,
                flag: this.flagAppointment || false,
                lock: this.lockAppointment || false,
                isHouseAssociation: this.isHouseAssociation || false,
                installationData: this.installationData,
                isOnCancellationList: this.addToCancellationList,
                cancellationListTimestamp: this.getISOTimestamp(),
                callInAdvance: callInAdvanceData,
                changeLog: changeLog,
                changeLogOverflow: changeLogOverFlow,
                sendToEFB: log.sendToEFB,
                user: user,
                metadata: this.bookingData.metadata || null,
                externalUpdateNote: updateNote
            }

            if (this.bookingData.isOnCancellationList){
                document.cancellationListTimestamp = null
            }
            
            // Emit event with data and let n-level parent handle the save.
            EventBus.$emit('booking-form-save', document)

            if (this.sendConfirmation) {
                this.sendConfirmations(contactEmails, contactMobiles)
            }

            // Delete update to installation (only relevant for unscheduled)
            if (this.installationData.updates && this.installationData.updates.id){
                db.collection('UpdatesToInstallations').doc(this.installationData.updates.id).delete()
            }
        },

        async onSaveNonScheduled(){
            let user = {
                displayName: this.user.displayName,
                email: this.user.email,
            }

            let projectTaskIds = []
            this.installationData.tasks.forEach((task) => {
                projectTaskIds.push(task.id)
            })

            let tmpWorker = this.selectedWorker
            if (this.$route.name == 'TtProjectBooking'){
                let workerIndex = this.workerOptions.findIndex((w) => {return w.value == this.selectedWorker})
                tmpWorker = this.workerOptions[workerIndex].text
            }

            if (this.installationData.updates && this.installationData.updates.id){

                let newState = 'active'
                if ((this.tmpWorker == null || this.tmpWorker == 'Ingen') && this.flagAppointment == false && this.addToCancellationList == false && this.isHouseAssociation == false){
                    newState = 'passive'
                }

                let document = {
                    State: 'active',
                    BookingState: newState,
                    Updated: new Date(),
                    UpdatedBy: user,
                    Flag: this.flagAppointment,
                    IsHouseAssociation: this.isHouseAssociation,
                    IsOnCancellationList: this.addToCancellationList,
                    Worker: tmpWorker,
                    InstallationLabel: this.getConfigurationLabel(this.installationData.tasks[0]),
                    ProjectTaskIds: projectTaskIds
                }

                if (!this.installationData.updates.IsOnCancellationList){
                    document.CancellationListTimestamp = new Date()
                }

                await db.collection('UpdatesToInstallations').doc(
                    this.installationData.updates.id).update(document) // Update document in firestore

            } else {
                let document = {
                    State: 'active',
                    BookingState: 'active',
                    Updated: new Date(),
                    UpdatedBy: user,
                    Created: new Date(),
                    CreatedBy: user,
                    Flag: this.flagAppointment,
                    IsHouseAssociation: this.isHouseAssociation,
                    IsOnCancellationList: this.addToCancellationList,
                    CancellationListTimestamp: new Date(),
                    Worker: tmpWorker,
                    InstallationLabel: this.getConfigurationLabel(this.installationData.tasks[0]),
                    ProjectTaskIds: projectTaskIds
                }

                console.log("Doc to FireBase",document)
                let docRef = db.collection('UpdatesToInstallations').doc() // Create document and get auto generated ID
                await docRef.set(document) // Save document to firestore
            }
            
            this.onCloseButtonClick() // Close Modal
        },

        onSelectMobileInput(payload) {
            if (payload.includes(this.contactMobiles.length - 1)) {
                if (this.contactMobileCustomInput && this.contactMobileCustomInput != '') {
                    this.contactMobileCustomInputList.push(this.contactMobileCustomInput)
                }
            }
        },

        onCallInAdvanceMobileInput(payload) {
            this.contactCallInAdvanceCustomInput = this.contactMobilesFormatted[payload].text
        },

        onSelectEmailInput(payload) {
            if (payload.includes(this.contactEmails.length - 1)) {
                if (this.contactEmailCustomInput && this.contactEmailCustomInput != '') {
                    this.contactEmailCustomInputList.push(this.contactEmailCustomInput)
                }
            }
        },

        /**
         * Formats address to string for use in sms and email templates.
         * Sends confirmations to contacts, without looping, as a single email/sms can take multiple recipients.
         * @param {string[]} contactEmails Array of emails to send confirmations to.
         * @param {string[]} contactMobiles Array of mobile numbers to send confirmations to.
         */
        sendConfirmations(contactEmails, contactMobiles) {
            const address = this.formatAddress(this.getConfiguration(this.installationData?.tasks?.[0])?.address) || '<Ugyldig adresse>'

            if (contactEmails.length >= 1) {
                let joinedEmailAddresses = contactEmails.join(',')
                console.log("Sending confirmation e-mail",joinedEmailAddresses,this.user.email)
                let reminderString = this.composeReminderString(true, contactMobiles.length >= 1)

                let bccEmail = ''
                if (this.settings.get('setUserAsBccOnBooking')){
                    bccEmail = this.user.email
                }

                this.dataAddEmail({
                    to: joinedEmailAddresses,
                    bcc: bccEmail,
                    template: {
                        name: this.getTemplateName(),
                        data: {
                            address,
                            reminderString,
                            date: this.toUserFriendlyDate(this.bookingData.date, false),
                            timeFrom: this.bookingData.timeFrom,
                            timeTo: this.bookingData.timeTo
                        }
                    }
                })
            }

            if (contactMobiles.length >= 1) {
                let reminderString = this.composeReminderString(contactEmails.length >= 1, true)

                this.dataAddSMS({
                    To: contactMobiles,
                    Template: SMSTemplate.APPOINTMENT_CONFIRMATION,
                    Data: {
                        Address: address,
                        ReminderString: reminderString,
                        Date: this.toUserFriendlyDate(this.bookingData.date, false),
                        TimeFrom: this.bookingData.timeFrom,
                        TimeTo: this.bookingData.timeTo
                    }
                })
            }
        },

        /**
         * Composes a reminder string for use in sms and email templates.
         * @param {boolean} useEmail Whether to use email in reminder string.
         * @param {boolean} useMobiles Whether to use mobiles in reminder string.
         * @returns {string} Reminder string. '' if no reminder is to be sent.
         */
        composeReminderString(useEmail, useMobiles) {
            if (this.sendReminder) {
                if (useEmail) {
                    if (useMobiles) {
                        return `Vi sender dig en email og sms-påmindelse dagen før.`
                    }
                    return `Vi sender dig en email-påmindelse dagen før.`
                } else {
                    if (useMobiles) {
                        return `Vi sender dig en sms-påmindelse dagen før.`
                    }
                }
            }

            return ''
        },

        /**
         * 
         * @param {Boolean} payload from callInAdvance
         */
        onChangeCallInAdvance(payload) {
            if (payload == true) {
                console.log("Generate note for call customer in advance")
            }
        },

        /**
         * Force `sendConfirmation` to true when `bookingData.confirmed` is set to true.
         * @param {boolean} payload from bookingData.confirmed
         */
        onChangeConfirmed(payload) {
            if (payload == true && this.allowCustomerCommunication) {
                this.sendConfirmation = true
                this.sendReminder = true
            }
            if (!this.allowCustomerCommunication){
                this.sendConfirmation = false
                this.sendReminder = false
            }

            this.recomputeSaveBtnText = !this.recomputeSaveBtnText
        },

        /**
         * Force `sendConfirmation` to true when `talkedToCustomer` is set to true.
         * @param {boolean} payload from talkedToCustomer
         */
        onChangeTalkedToCustomer(payload) {
            if (payload == true) {
                this.bookingData.confirmed = true
                this.sendConfirmation = true
                this.sendReminder = true
            }
            this.recomputeSaveBtnText = !this.recomputeSaveBtnText
        },

        /**
         * Force `sendConfirmation` to true when `flagAppointment` is set to true.
         * @param {boolean} payload from flagAppointment
         */
        onDateChanged() {
            if ( this.bookingData.confirmed == true ){
                this.talkedToCustomer = true
            }
        },

        /**
         * Guess the appointment type based on the tasks available.
         * @returns {string|null} The appointment type, or null if no guess could be made.
         */
        guessAppointmentType() {
            let bestGuess = null

            if (!this.installationData || !this.installationData.tasks || !this.installationData.tasks.length) {
                return null
            }

            //Attempt to guess the appointment type based on the type of the tasks available
            let meetsAllRequirements = this.validAppointmentTypesForTasks //Computed property
            console.log(meetsAllRequirements)
            if (meetsAllRequirements.length == 1) { // If precisely one appointment type is valid, pick that one
                bestGuess = meetsAllRequirements[0]
            } else if (meetsAllRequirements.length > 1) { //If there are multiple valid appointment types, pick the one with the lowest task number on its primary task, excluding closed tasks
                let taskList = this.cloneJson(this.installationData.tasks)
                taskList = taskList
                    .filter(task => this.taskHasClosedState(task) == false) //Filter out closed tasks
                    .sort((a, b) => a.taskNumber - b.taskNumber) //Sort by task number (ascending)
                for (let task of taskList) {
                    let shouldBreak = false
                    for (let appointmentType of meetsAllRequirements) {
                        if (task.code == AppointmentType.AppointmentTasks[appointmentType].Primary?.TaskCode) {
                            bestGuess = appointmentType
                            shouldBreak = true
                            break;
                        }
                    }
                    if (shouldBreak) break;
                }
            } else { //If no appointmentTypes would be valid, try different methods of guessing

                //If an available task is a primary task for any appointment type, pick that appointment type
                for (let appointmentType of Object.keys(AppointmentType.AppointmentTasks)) {
                    if (this.availableTaskCodes.includes(AppointmentType.AppointmentTasks[appointmentType].Primary.TaskCode)) {
                        bestGuess = appointmentType
                        break;
                    }
                }

                //If no appointment type could be guessed, pick the default appointment type
                if (!bestGuess) {
                    bestGuess = AppointmentType.DEFAULT
                }

            }

            console.log(`Guessed appointment type '${bestGuess}' based on tasks ${this.availableTaskCodes.join(', ')}`)
            return bestGuess
        },

        /**
         * Resets the dropdowns to their default values, to the values saved in the bookingData, or to a guess based on the tasks available.
         */
        setDropdownDefaultValues() {
            let contacts = this.task.contacts

            // If there's any saved appointmentType data from existing appointment.
            if (this.bookingData.appointmentType) {
                this.selectedAppointmentType = this.bookingData.appointmentType
            } else { //Attempt to guess the appointment type based on the type of the tasks available
                this.selectedAppointmentType = this.guessAppointmentType()
            }

            // If there's any saved mobile data from existing appointment.
            if (this.bookingData.contacts && this.bookingData.contacts.Mobiles) {
                this.bookingData.contacts.Mobiles.forEach(n => {
                    const k = this.contactMobiles.indexOf(n)
                    this.selectedContactMobiles.push(k)
                })

                const k = this.contactMobiles.indexOf(this.bookingData.callInAdvancePhone)
                this.selectedCallInAdavanceMobile= k
            } else {
                // If not, push the first valid entry
                let phoneNumbers = []
                contacts.forEach((c) => {
                    if (c.mobile) phoneNumbers.push(c.mobile)
                    if (c.phone) phoneNumbers.push(c.phone)
                })
                let filteredNumbers = phoneNumbers.filter(n => this.checkIfMobile(n))
                if (filteredNumbers.length) this.selectedContactMobiles.push(0)
            }

            // If there's any saved email data from existing appointment.
            if (this.bookingData.contacts && this.bookingData.contacts.Emails) {
                this.bookingData.contacts.Emails.forEach(e => {
                    const k = this.contactEmails.indexOf(e)
                    this.selectedContactEmails.push(k)
                })
            } else {
                // If not, push the first valid entry
                let emails = []
                contacts.forEach((c) => {
                    if (c.email) emails.push(c.email)
                })
                let filteredEmails = emails.filter(e => this.checkIfEmail(e))
                if (filteredEmails.length) this.selectedContactEmails.push(0)
            }
        },

        getTemplateName() {
            let templateName = EmailTemplate.APPT_CFM_INSTALLATION

            if (this.selectedAppointmentType == AppointmentType.INSPECTION) {templateName = EmailTemplate.APPT_CFM_INSPECTION}
            if (this.selectedAppointmentType == AppointmentType.TICKET) {templateName = EmailTemplate.APPT_CFM_TICKET}
            if (this.selectedAppointmentType == AppointmentType.TECHNICIAN) {templateName = EmailTemplate.APPT_CFM_TECHNICIAN}

            return templateName
        },

        prepopulateForm() {
            this.clearForm()
            setTimeout(() => {
                this.setDropdownDefaultValues()
            }, 100)

            if (this.bookingData.sendReminder === true || this.bookingData.sendReminder === false) {
                this.sendReminder = this.bookingData.sendReminder
            }

            if (this.bookingData.flag === true || this.bookingData.flag === false) {
                this.flagAppointment = this.bookingData.flag
            }

            if (this.bookingData.isOnCancellationList === true || this.bookingData.isOnCancellationList === false) {
                this.addToCancellationList = this.bookingData.isOnCancellationList
            }

            if (this.bookingData.isHouseAssociation === true || this.bookingData.isHouseAssociation === false) {
                this.isHouseAssociation = this.bookingData.isHouseAssociation
            }

            if (this.installationData.updates && this.installationData.updates.IsOnCancellationList){
                this.addToCancellationList = this.installationData.updates.IsOnCancellationList
            }

            if (this.installationData.updates && this.installationData.updates.Flag) {
                this.flagAppointment = this.installationData.updates.Flag
            }

            if (this.installationData.updates && this.installationData.updates.IsHouseAssociation) {
                this.isHouseAssociation = this.installationData.updates.IsHouseAssociation
            }

            if (this.installationData.updates && this.installationData.updates.Worker) {
                this.selectedWorker = this.installationData.updates.Worker
                if (this.$route.name == 'TtProjectBooking'){
                    let workerIndex = this.workerOptions.findIndex((w) => {return w.text == this.selectedWorker})
                    this.selectedWorker = this.workerOptions[workerIndex].value
                }
            }

            if (this.bookingData.lock === true || this.bookingData.lock === false) {
                this.lockAppointment = this.bookingData.lock
            }

            if (this.bookingData.callInAdvance === true || this.bookingData.callInAdvance === false){
                this.callInAdvance = this.bookingData.callInAdvance
                this.contactCallInAdvanceCustomInput = this.bookingData.callInAdvancePhone
            }
        },
    
    },

    mounted() {
        // this.prepopulateForm()
    },

    watch: {
        bookingData: {
            immediate: true,
            handler() {
                if (!this.bookingData || !this.bookingData.worker) return
                this.selectedWorker = this.bookingData.worker
            }
        },

        selectedTime: {
            immediate: false,
            handler(selectedTime) {
                if(selectedTime){
                    let selectedTimeObj = JSON.parse(selectedTime)
                    this.bookingData.timeFrom = selectedTimeObj.from
                    this.bookingData.timeTo = selectedTimeObj.to
                    this.selectedTime = null
                }
            }
        },

        selectedDurationCallInAdvance: {
            immediate: false,
            handler(selectedDurationCallInAdvance) {
                if(selectedDurationCallInAdvance){
                    let selectedDurationCallInAdvanceObj = JSON.parse(selectedDurationCallInAdvance)
                    this.bookingData.callInAdvanceDuration = selectedDurationCallInAdvanceObj.data
                    this.selectedDurationCallInAdvance = null
                }
            }
        },

        bookingDataProp: {
            immediate: true,
            handler(bookingDataProp) {
                if (bookingDataProp) {
                    this.bookingData = this.cloneJson(bookingDataProp)
                    this.prepopulateForm()
                } else {
                    this.bookingData = {}
                }
            }
        },
    }
}
</script>
<style scoped>
.zoom-8 .dropdown {
    width: 100% !important;
}

.checkbox-spacing {
    margin-top: 3px;
    margin-bottom: 3px;
}

.input.size-3 {
    width: 116px !important;
    /* width: 94px !important; */
    margin-right: 10px !important;
}

.input.size-2 {
    width: 243px !important;
    /* width: 228px !important; wide screen (21/9) */
    margin-right: 10px !important;
}

.info.label {
    width: 21.28px;
    cursor: help;
}

.grey-text {
    color: lightgray;
}

/*
.dropdown .menu.visible {
    left: -80px;
}
*/
</style>
