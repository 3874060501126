<template>
    <sui-modal
        small
        :open="isOpen"
        v-on:clickAwayModal="closeModal()"
    >
        <sui-modal-header>
            {{unitWorkId ? 'Redigér' : 'Tilføj'}} arbejds-enhed
        </sui-modal-header>
        <sui-modal-content scrolling>
            <sui-form warning :loading="formLoading">
                <sui-message v-if="beforeChange && beforeChange.Billed && beforeChange.Billed.Bool">
                    <sui-message-header>Denne postering er låst</sui-message-header>
                    <p>Denne postering er godkendt af projektleder, hvorfor den ikke længere kan redigeres</p>
                </sui-message>
                <sui-form-fields>
                    <sui-form-field :disabled="billedCheckbox">
                        <label>Installations-nummer</label>
                        <sui-input disabled placeholder="Installationsnummer" v-model="installationLabelInput"/>
                    </sui-form-field>
                    <sui-form-field>
                        <label>Installations-adresse</label>
                        <sui-input disabled placeholder="Adresse" v-model="installationAddress"/>
                    </sui-form-field>
                    <sui-form-field :disabled="billedCheckbox">
                        <label>Service-Provider</label>
                        <sui-input disabled placeholder="N/A" v-model="serviceProviderOption.text"/>
                    </sui-form-field>
                </sui-form-fields>
                <sui-form-fields>
                    <sui-form-field :disabled="billedCheckbox">
                        <label>Tilslutnings type</label>
                        <sui-dropdown :disabled="taskHasProjectType" placeholder="Type" selection :options="ProjectType.dropdownOptions" v-model="selectedProjectType"/>
                    </sui-form-field>
                    <sui-form-field :disabled="billedCheckbox" :required="!taskHasAreaCode" title="Område-kode i EnergiFyns systemer, til inddeling i projekter">
                        <label>Område kode</label>
                        <sui-input placeholder="Område-nummer" :readonly="taskHasAreaCode" v-model="areaInput"/>
                    </sui-form-field>
                    <sui-form-field :disabled="billedCheckbox" title="Reference/Sagsnummer i EnergiFyns systemer, til inddeling i projekter">
                        <label>Reference Id</label>
                        <sui-input placeholder="ReferenceId" :readonly="taskHasReferenceId" v-model="referenceIdInput"/>
                    </sui-form-field>
                </sui-form-fields>
                <sui-form-fields>
                    <sui-form-field :disabled="billedCheckbox">
                        <label>Kategori</label>
                        <sui-dropdown placeholder="Enheds-kategori" selection search :options="UnitWorkCategories.DROPDOWN_OPTIONS" v-model="selectedUnitWorkCategory"/>
                    </sui-form-field>
                    <sui-form-field :disabled="billedCheckbox" required>
                        <label>Enhed</label>
                        <sui-dropdown placeholder="Enhed" selection search :options="unitWorkUnitsDropdown" v-model="selectedUnit"/>
                    </sui-form-field>
                    <sui-form-field :disabled="billedCheckbox" required>
                        <label>Antal</label>
                        <sui-input placeholder="Antal" type="number" step="0.25" v-model="amountInput"/>
                    </sui-form-field>
                    <sui-form-field :disabled="billedCheckbox">
                        <label>Tilsvarende Timer</label>
                        <span>{{decimalToHourString(calculateEquivalentHours(selectedUnit, amountInput))}}</span>
                    </sui-form-field>
                </sui-form-fields>
                <sui-message warning v-if="this.unitWorkAllUnits && this.unitWorkAllUnits.find((u) => u.id == this.selectedUnit) && this.unitWorkAllUnits.find((u) => u.id == this.selectedUnit).Unit == 't'">
                    <p>Husk at inkludere evt ekstra kørsel når du skriver timer som ekstra-arbejde :-)</p>
                </sui-message>
                <sui-form-fields>
                    <sui-form-field :disabled="billedCheckbox" required>
                        <label>Medarbejder(e)</label>
                        <sui-dropdown placeholder="Medarbejdere" selection search multiple :options="availableWorkersPlusSelf" v-model="selectedWorkers"/>
                    </sui-form-field>
                    <sui-form-field :disabled="billedCheckbox" v-if="selectedWorkers.length > 1">
                        <label>Timer per mand</label>
                        <span>{{decimalToHourString(calculateEquivalentHours(selectedUnit, amountInput/selectedWorkers.length))}}</span>
                    </sui-form-field>
                    <sui-form-field :disabled="billedCheckbox" required>
                        <label>Dato udført</label>
                        <sui-input type="date" v-model="dateInput"/>
                    </sui-form-field>
                </sui-form-fields>
                <label>Betales af</label>
                <span><small><em><br>Vælg Energi Fyn for arbejde på selve fiberen, eller CPE'en. Vælg indholdsudbyder for arbejde på udstyr der sidder efter CPE'en.<br>
                    For arbejde der både dækker før og efter CPE'en laves to seperate poster.</em></small></span>
                <sui-form-fields>
                    <sui-form-field inline required>
                        <sui-dropdown placeholder="Hvem skal betale?" selection search :options="payedByOptions" v-model="payedBy"/>
                        <sui-message warning v-if="showServiceProviderWarning">
                            <p>Din beskrivelse ser ud til at nævne ydelser der bør betales af indholdsudbyder, men du har valgt at Energi Fyn skal betale - overvej at ændre til {{serviceProviderOption.text}}</p>
                        </sui-message>
                    </sui-form-field>
                </sui-form-fields>
                <sui-form-field :disabled="billedCheckbox">
                    <label>Beskrivelse</label>
                    <textarea v-model="descriptionInput" placeholder="Eventuel beskrivelse af det udførte arbejde, påføres regningen..."/>
                </sui-form-field>
                <sui-form-field :disabled="!userHasLeadPermissions" title="Godkendt af projektleder/admin?">
                    <sui-checkbox v-model="billedCheckbox" label="Godkendt"/>
                </sui-form-field>
            </sui-form>
        </sui-modal-content>
        <sui-modal-actions>
            <sui-button
                v-if="(this.unitWorkId)"
                negative
                @click="deleteUnitWork"
            >
                <i class="fas fa-trash-alt"/> Slet
            </sui-button>
            <sui-button
                negative
                @click="closeModal"
            >
                Luk
            </sui-button>
            <sui-button
                positive
                type="submit"
                :disabled="billedCheckbox && !userHasLeadPermissions"
                @click="saveChanges(false)"
            >
                Gem og luk
            </sui-button>
            <sui-button
                positive
                type="submit"
                :disabled="billedCheckbox && !userHasLeadPermissions"
                @click="saveChanges(true)"
            >
                Gem og opret ny
            </sui-button>
        </sui-modal-actions>
    </sui-modal>
</template>
<script>
import EventBus from '../../EventBus'
import ProjectType from '../../lib/Enums/ProjectType'
import UnitWorkCategories from '../../lib/Enums/UnitWorkCategories'
import { Mixin } from '../../lib/Mixins/mixin'
import { DateMixin } from '../../lib/Mixins/dateMixin'
import { db } from '../../firebase'
import { DataAPI } from '../../lib/DataAPI'
import { unitWorkMixin } from '../../lib/unitWorkMixin'
import swal from 'sweetalert'

export default {
    name: 'EditUnitWorkModal',
    mixins: [Mixin, DateMixin, DataAPI, unitWorkMixin],

    enums: {
        ProjectType,
        UnitWorkCategories,
    },

    props: {
        isOpen: {
            type: Boolean,
            requred: true,
            default: false,
        },
        task: {
            type: Object,
            required: true
        },
        availableWorkers: {
            type: Array,
            required: false,
        },
        user: {
            type: Object,
            required: false,
        },
        unitWorkId: {
            type: String,
            required: false,
        },
        beforeChange: {
            type: Object,
            required: false,
        },
        userHasLeadPermissions: {
            type: Boolean,
            required: false,
            default: false,
        },
        preFilledDescription: {
            type: String,
            requred: false,
            default: '',
        },
    },

    data() {
        return {
            formLoading: false,

            unitWorkUnits: [],
            unitWorkCompositeUnits: [],

            serviceProviders: null,

            installationLabelInput: null,
            installationAddress: null,
            selectedProjectType: null,
            areaInput: null,
            referenceIdInput: null,
            selectedUnitWorkCategory: null,
            selectedUnit: null,
            amountInput: null,
            selectedWorkers: null,
            dateInput: null,
            descriptionInput: null,
            billedCheckbox: false,

            payedBy: "EFB",
        }
    },

    computed: {
        unitWorkAllUnits() {
            return [
                ...this.unitWorkCompositeUnits,
                ...this.unitWorkUnits,
            ]
        },
        unitWorkUnitsDropdown() {
            let dropdownArray = []
            for (let i in this.unitWorkAllUnits) {
                const unit = this.unitWorkAllUnits[i]
                if (this.selectedUnitWorkCategory && !unit.Categories?.includes(this.selectedUnitWorkCategory)) continue
                dropdownArray.push({
                    value: unit.id,
                    text: `${unit.id}: ${unit.Name}`,
                })
            }
            return dropdownArray
        },
        serviceProviderOption() {
            let serviceProviderOption = {}
            if (!this.task || !this.task.serviceOrder || !this.task.serviceOrder.serviceProvider){
                // console.error('no service provider in task') //This fires a lot, probably due to just the order things load
                return serviceProviderOption
            }
            serviceProviderOption = {
                value: this.task.serviceOrder.serviceProvider.id,
                text: this.task.serviceOrder.serviceProvider.name || `Udbyder nr ${this.task.serviceOrder.serviceProvider.number}`
            }

            let serviceProviderIndex = this.serviceProviders.findIndex(sp => sp.Id == serviceProviderOption.value)
            if (serviceProviderIndex != -1){
                serviceProviderOption.text = this.serviceProviders[serviceProviderIndex].Name
            }

            return serviceProviderOption
        },
        payedByOptions() {
            let options = [{value: "EFB", text: "Energi Fyn Bredbånd"}]

            // This code adds ALL saved serviceProviders to the dropdown, which I don't think is desired
            // for (let i in this.serviceProviders) {
            //     if (options.findIndex(o => o.value == this.serviceProviders[i].Id) == -1){
            //         options.push({
            //             value: this.serviceProviders[i].Id,
            //             text: this.serviceProviders[i].Name
            //         })
            //     }
            // }

            if (options.findIndex(o => o.value == this.serviceProviderOption.value) == -1){
                options.push(this.serviceProviderOption)
            }

            return options
        },
        showServiceProviderWarning() {
            if (!this.descriptionInput || this.payedBy != 'EFB') return false
            let lowerCaseDescription = this.descriptionInput.toLowerCase()
            if (lowerCaseDescription.includes('smartwifi') ||
                lowerCaseDescription.includes('smart wifi') ||
                lowerCaseDescription.includes('kabeltræk') ||
                lowerCaseDescription.includes('kabel træk') ||
                lowerCaseDescription.includes('tvboks') ||
                lowerCaseDescription.includes('tvbox') ||
                lowerCaseDescription.includes('tv boks') ||
                lowerCaseDescription.includes('tv box') ||
                lowerCaseDescription.includes('coax') ||
                lowerCaseDescription.includes('pds') ||
                lowerCaseDescription.includes('sw '))
                {
                    return true
                }
            return false
        },
        taskHasProjectType() {
            return !!(this.task.project && this.task.project.type && this.task.project.type.value)
        },
        taskHasAreaCode() {
            return !!this.task.configurationItem?.area?.sonWinProjectId
        },
        taskHasReferenceId() {
            return !!this.task.referenceId
        },
        availableWorkersPlusSelf() {
            let arr = this.availableWorkers
            let selfWorker = {
                key: String(arr.length),
                text: `${this.user.displayName || this.user._delegate.displayName} (${this.getInitialsFromEmail(this.user.email || this.user._delegate.email)})`,
                value: this.user.email || this.user._delegate.email
            }
            if (arr.findIndex(w => w.value == selfWorker.value) == -1) { //Add self if not added from prop
                arr.push(selfWorker)
            }
            for (let selectedWorker of this.selectedWorkers) { //Loop through selected workers, and ensure any selected worker is also an option (so they are visible)
                if (arr.findIndex(w => w.value == selectedWorker) != -1) continue;
                arr.push({
                    key : String(arr.length),
                    text: this.getInitialsFromEmail(selectedWorker),
                    value: selectedWorker,
                })
            }
            return arr
        }
    },

    methods: {
        closeModal() {
            EventBus.$emit('edit-unit-work-modal-close')
        },
        prePopulateForm() {
            // console.log('pre-populating form')
            this.installationLabelInput = this.task.configurationItem?.label 
            this.installationAddress = this.formatAddress(this.task.configurationItem?.address, false)
            if (!this.unitWorkId) {
                this.areaInput = this.task.configurationItem?.area?.sonWinProjectId || null
                this.referenceIdInput = this.task.referenceId,
                this.selectedProjectType = parseInt(this.task.project?.type?.value)
                this.selectedUnitWorkCategory = UnitWorkCategories.GENERAL
                this.selectedUnit = '951.F.01'
                this.amountInput = 1
                this.selectedWorkers = [this.user.email],
                this.dateInput = this.formatMachineDate(new Date(), '-')
                this.payedBy = "EFB"
                this.descriptionInput = this.preFilledDescription
                this.billedCheckbox = false
            } else {
                this.areaInput = this.task.configurationItem?.area?.sonWinProjectId || this.beforeChange.AreaCode
                this.referenceIdInput = this.task.referenceId || this.beforeChange.ReferenceId
                this.selectedProjectType = parseInt(this.beforeChange.ProjectInstallationType)
                this.selectedUnitWorkCategory = this.beforeChange.Unit.Categories[0]
                this.selectedUnit = this.beforeChange.Unit.Id
                this.amountInput = this.beforeChange.Amount
                this.selectedWorkers = this.beforeChange.Workers
                this.dateInput = this.beforeChange.Date
                this.payedBy = this.beforeChange.PayedBy
                this.descriptionInput = this.beforeChange.Description
                this.billedCheckbox = this.beforeChange.Billed.Bool
            }
        },
        async saveChanges(startOver = false) {
            EventBus.$emit('function-activity', {functionName: 'EditUnitWorkModal_saveChanges', isActive: true})
            this.formLoading = true
            console.log('Saving changes...')
            let Unit = {
                Id: this.unitWorkAllUnits.find((u) => u.id == this.selectedUnit).id,
                ...this.unitWorkAllUnits.find((u) => u.id == this.selectedUnit)
            }
            const unitWorkDocument = {
                AreaCode: this.areaInput,
                ProjectInstallationType: this.task.project?.type?.value || this.selectedProjectType,
                ConfigurationItem: {
                    Label: this.task.configurationItem?.label || null,
                    Area: this.task.configurationItem?.area || null,
                    Cabinet: this.task.configurationItem?.cabinet || null,
                    TechnicalData: this.task.configurationItem?.technicalData || null,
                    Type: this.task.configurationItem?.type || this.selectedProjectType,
                    Value: this.task.configurationItem?.value || null,
                    Address: this.formatAddress(this.task.configurationItem?.address, false) || null,
                },
                ReferenceId: this.referenceIdInput,
                ServiceOrder: this.task.serviceOrder,
                PayedBy: this.payedBy,
                Unit,
                Amount: this.amountInput,
                EquivalentHours: this.calculateEquivalentHours(this.selectedUnit, this.amountInput),
                Description: this.descriptionInput,
                Workers: this.selectedWorkers,
                TimeStamp: new Date(),
                CreatedBy: {
                    Name: this.user.displayName,
                    Email: this.user.email,
                    Initials: this.user?.email?.substr(0, this.user.email.indexOf('@')).toUpperCase()
                },
                AutoGenerated: false,
                Date: this.dateInput,
                Billed: {
                    Bool: this.billedCheckbox,
                    Updated: new Date()
                },
                LinkedProjects: [this.$route.params.projectIdentifier],
                Price: this.calculatePrice(this.selectedUnit, this.amountInput),
                FromDocumentation: {},
            }

            console.log(`Price after: ${unitWorkDocument.Price}, Price before: ${this.beforeChange.Price}`)

            if (this.unitWorkId) {
                console.log(`Deleting keys 'Timestamp', 'CreatedBy', 'AutoGenerated', 'LinkedProjects' and 'FromDocumentation' from update document, to avoid overwriting them`)
                delete unitWorkDocument.TimeStamp
                delete unitWorkDocument.CreatedBy
                delete unitWorkDocument.AutoGenerated
                delete unitWorkDocument.LinkedProjects
                delete unitWorkDocument.FromDocumentation

                let unchangedKeys = []
                let changedKeys = []

                if (unitWorkDocument.Billed.Bool == this.beforeChange.Billed.Bool) {
                    unchangedKeys.push('Billed')
                    delete unitWorkDocument.Billed
                }

                let unitWorkDocumentKeys = Object.keys(unitWorkDocument)
                for (let i in unitWorkDocumentKeys) {
                    let key = unitWorkDocumentKeys[i]
                    if (( this.nestedEquality(unitWorkDocument[key], this.beforeChange[key]) && this.beforeChange[key]) || !(unitWorkDocument[key] || unitWorkDocument[key] === 0)) {
                        delete unitWorkDocument[key]
                        unchangedKeys.push(key)
                    } else {
                        changedKeys.push(key)
                    }
                }
                if (unchangedKeys.length) console.log(`these keys were deleted from the update document, because no change was detected: ${unchangedKeys.join(', ')}`)
                if (changedKeys.length) console.log(`these keys were left in the update document, because change was detected: ${changedKeys.join(', ')}`)
                console.log('Final update document:', unitWorkDocument)
                if (Object.keys(unitWorkDocument).length) {
                    await this.dataAddOrUpdateUnitWork(unitWorkDocument, this.unitWorkId).then(() => {console.log('document updated')})
                } else console.log('nothing to update, skipping update call')
            } else {
                await this.dataAddOrUpdateUnitWork(unitWorkDocument).then(() => {console.log('document added')})
            }

            this.prePopulateForm()
            this.formLoading = false
            if(startOver){
                console.log('Restarting form...') 
                EventBus.$emit('function-activity', {functionName: 'EditUnitWorkModal_saveChanges', isActive: false})  
            }
            else {
                this.closeModal()
                EventBus.$emit('function-activity', {functionName: 'EditUnitWorkModal_saveChanges', isActive: false})
            }
        },

        async deleteUnitWork(){
            EventBus.$emit('function-activity', {functionName: 'EditUnitWorkModal_deleteUnitWork', isActive: true})
            let shouldDelete = await swal({
                title: 'Er du sikker?',
                text: 'Slettet enhedsarbejde kan ikke genkaldes',
                icon: 'warning',
                buttons: true,
                dangerMode: true,
            })
            if (!shouldDelete) {
                EventBus.$emit('function-activity', {functionName: 'EditUnitWorkModal_deleteUnitWork', isActive: false})
                return false
            }
            this.formLoading = true
            await this.dataDeleteUnitWork({id: this.unitWorkId})
            this.closeModal()
            this.formLoading = false
            EventBus.$emit('function-activity', {functionName: 'EditUnitWorkModal_deleteUnitWork', isActive: false})
            return true
        }
    },

    beforeMount(){
        this.$bind('serviceProviders', db.collection('ServiceProviders'))
        EventBus.$on('add-note-to-unitWork', (str) => {
            console.log('adding note to unitWork')
            this.descriptionInput = String(str)
        })
    },

    watch: {
        task: {
            immediate: true,
            handler() {
                this.prePopulateForm()
            }
        }, 
        beforeChange: {
            immediate: false,
            handler() {
                this.prePopulateForm()
            }
        },
        // serviceOrder: { //serviceOrder does not seem to be a used variable anywhere
        //     immediate: true,
        //     async handler() {
        //         if (this.task?.serviceOrder && !this.task.serviceOrder.serviceProvider) {
        //             let retrievedServiceOrder = await this.dataGetServiceOrder(this.task.serviceOrder.id)
        //             console.log(retrievedServiceOrder)

        //             //If one of the objects include the project type (not included in all API responses), make sure it is kept
        //             if (!retrievedServiceOrder.project.type) {
        //                 retrievedServiceOrder.project.type = this.task.project.type
        //             }
        //             this.task.project = retrievedServiceOrder.project //Unexpected mutation of "task" prop  vue/no-mutating-props

        //             //Anonymize data for GDPR reasons
        //             delete retrievedServiceOrder.contacts
        //             delete retrievedServiceOrder.installation.address

        //             this.task.serviceOrder = retrievedServiceOrder //Unexpected mutation of "task" prop  vue/no-mutating-props
        //         }
        //     }
        // }
    },
}
</script>