import swal from "sweetalert"
import TaskCode from './Enums/TaskCode'
import EventBus from '../EventBus'
import { SubtaskMixin } from './SubtaskMixin'
import { Mixin } from './Mixins/mixin'
import { DateMixin } from "./Mixins/dateMixin"
import { ZipCodeMixin } from "./helpers/zipCodeMixin.js"
import TaskType from "./Enums/TaskType"
import AppointmentType from "./Enums/AppointmentType"
import TaskState from "./Enums/TaskState"
import TicketState from "./Enums/TicketState"
import ProjectType from "./Enums/ProjectType"
import CachedSettings from "@/modules/Settings/settingsClass"
import { ProjectSettingsMixin } from '@/modules/ProjectSettings/projectSettingsMixin.js'
import { mapGetters } from 'vuex'

export const Bookingmixin = {

    mixins: [
        SubtaskMixin, 
        Mixin, 
        DateMixin, 
        ProjectSettingsMixin, 
        ZipCodeMixin
    ],

    enums: {
        TaskCode
    },

    data() {
        return {
            hasCheckedAppointments: false,
            loadAttempt: 0,

            rawTasks: new Map(),

            closedTasks: [], // TODO. Update to map instead of array
            tasks: new Map(),

            installations: [], // TODO: Update to map instead of array
            allUpdates: [],

            failedInstallationMissingCoordinates: [],

            stashedTechnicals: {
                hubs: {},
                uubs: {},
            },

            tasksWithoutInstallationLabels: [],

            settingsBD: new CachedSettings(),

            loadProjectTasks: true,
            loadTickets: false,
            hasActiveProject: false,
            getBaseBookingDataComplete: false,

        }
    },

    computed: {
        ...mapGetters({
            project: 'activeProject',
        }),
    },

    methods: {

        onSettingUpdate(key) {
            switch (key) {
                case 'projectPreferences': console.log(key,"This setting does not require an update method") 
                    break
                case 'showMap': if(this.settingsBD.get("showMap")){
                    // this.initializeMaps()
                    // location.reload() //When map is enabled, a reload is required
                    this.bookFormatBookingData(true)
                }
                    break
                case 'mapUseAutoZoom': console.log(key,"This setting does not require an update method")
                    break
                case 'bookedSelectedOptions':
                    for (let i in this.bookedSortingOptions)
                    {
                        let index = this.settingsBD.get("bookedSelectedOptions").findIndex(p => {
                            return p.value == this.bookedSortingOptions[i].value})
                        this.bookedSortingOptions[i].rank = this.settingsBD.get("bookedSelectedOptions")[index].rank
                    } 
                    break
                case 'bookSortAscending': console.log(key,"This setting does not require an update method") 
                    break
                case 'sortUnbookedPTBy': if (this.settingsBD.get("sortUnbookedPTBy") == "productDeliveryDate") this.getAllProducts()
                    break
                case 'sortInstsBy': if (this.settingsBD.get("sortInstsBy") == "productDeliveryDate") this.getAllProducts()
                    break
                case 'showUnbookedCard': console.log(key,"This setting does not require an update method") 
                    break
                case 'showPendingTasks': if (this.settingsBD.get('showPendingTasks')){
                    this.bookAddOrUpdatePendingTasks(
                        this.project.AreaCodes, 
                        this.project.Type, 
                        this.project.ReferenceIdWhitelist, 
                        this.project.ReferenceidBlacklist)
                    }
                    this.regenerateMapMarkers()
                    break
                case 'showOnHoldTasks': if (this.settingsBD.get('showOnHoldTasks')){
                    this.bookAddOrUpdateOnHoldTasks(
                        this.project.AreaCodes, 
                        this.project.Type, 
                        this.project.ReferenceIdWhitelist, 
                        this.project.ReferenceidBlacklist)
                    }
                    this.regenerateMapMarkers()
                    break
                case 'showResolved': if (this.settingsBD.get('showResolved')){
                    this.bookAddOrUpdateResolvedTasks(
                        this.project.AreaCodes,
                        this.project.ReferenceIdWhitelist,
                        this.project.ReferenceidBlacklist)
                    }
                    break
                case 'showClosed': this.showHideClosedTasks(this.project.AreaCodes)
                    break
                case 'showCloseCPETaskShortcut': console.log(key,"This setting does not require an update method") 
                    break
                case 'showTaskTypeIcon': console.log(key,"This setting does not require an update method")
                    break
                case 'showSubtaskStatus': console.log(key,"This setting does not require an update method")
                    break
                case 'showCounters': console.log(key,"This setting does not require an update method")
                    break
                case 'showWeekNums': console.log(key,"This setting does not require an update method")
                    break
                case 'highlightOwnAppointments': console.log(key,"This setting does not require an update method")
                    break
                case 'showNavigationIcon': console.log(key,"This setting does not require an update method")
                    break
                case 'showCallInAdvanceIcon': console.log(key,"This setting does not require an update method")
                    break
                case 'showApptTypeInspection': this.regenerateMapMarkers()
                    break
                case 'showApptTypeInstallation': this.regenerateMapMarkers()
                    break
                case 'showApptTypeTechnician': this.regenerateMapMarkers()
                    break
                case 'showApptTypePatch': this.regenerateMapMarkers()
                    break
                case 'showApptTypeTickets': this.regenerateMapMarkers()
                    break
                case 'bookedDuration': 
                    this.getAppointments('asc')
                    this.getCustomAppointments('asc')
                    break
                default: console.log("An update method have not been implemented for this setting",key)
            }
        }, 

        /**
         * Fetches base data for project tasks from Pilotbi.
         * @param {Array} areaCodes
         */
        async bookGetBaseBookingData(
            areaCodes, 
            projectTypes, 
            includePending = false, 
            includeOnHold = true, 
            includeClosedIncomplete = false, 
            includeClosedSkipped = false, 
            mustHaveConnectionDate = false, 
            referenceIdWhitelist = [], 
            referenceIdBlacklist = []) 
        {
            EventBus.$emit('function-activity', {functionName: 'Bookingmixin_bookGetBaseBookingData', isActive: true})
            EventBus.$emit('loading-timelines', true)
            this.getBaseBookingDataComplete = false

            if (this.loadProjectTasks){
                let apiData = await this.dataGetProjectTasks(areaCodes, projectTypes, includePending, 
                    includeOnHold, includeClosedIncomplete, includeClosedSkipped, 
                    mustHaveConnectionDate, referenceIdWhitelist, referenceIdBlacklist)

                for (let i in apiData.projectTasks) {
                    let task = apiData.projectTasks[i]
                    this.setOrUpdateTaskInRawTasks(task)
                }
            }

            if (this.loadTickets){
                let apiData = await this.dataGetTroubleTickets(areaCodes, includeOnHold, 
                    this.settingsBD.get("showResolved"))

                for (let i in apiData.troubleTickets) {
                    let task = apiData.troubleTickets[i]
                    this.setOrUpdateTaskInRawTasks(task)
                }
            }

            await this.bookFormatBookingData(this.settingsBD.get("showMap"))
            await this.bookAppointmentCheckTasks()
            // await this.getAllInstUpdates()
            // await this.getAllUpdatesToInstallations()
            // if (this.settingsBD.get('sortUnbookedPTBy') == 'productDeliveryDate'){
            //     this.getAllProducts()
            // }

            for (let i in this.installations){
                let taskCodes = []
                for (let j in this.installations[i].tasks){
                    taskCodes.push(this.installations[i].tasks[j].code)
                }
                if (taskCodes.includes(TaskCode.TICKET) && taskCodes.length > 1){
                    console.log("This installations includes tickets",this.installations[i])
                }
            }

            this.getBaseBookingDataComplete = true
            console.log("Get base booking data complete")
            // for (let appointment of this.appointments){
            //     this.allTasksComplete(appointment.id,true)
            // }

            EventBus.$emit('function-activity', {functionName: 'Bookingmixin_bookGetBaseBookingData', isActive: false})
            return
        },

        setOrUpdateTaskInRawTasks(task, isFullTask = false){
            if ((task?.isFullTask != false && task?.isFullTask != true) || isFullTask == true){
                task.isFullTask = isFullTask
            }
            
            if (!this.rawTasks.has(task.id)){ // Set task in rawTasks
                this.rawTasks.set(task.id, task)
            } else { // Update task in rawTasks
                if (!task.project?.type?.value) task.project = this.rawTasks.get(task.id).project //Preserve project data (not included in full task)
                if (!task.products || !task.products.length) task.products = this.rawTasks.get(task.id).products //Preserve products array
                if (!task.productDeliveryDate) task.productDeliveryDate = this.rawTasks.get(task.id).productDeliveryDate //Preserve productDeliveryDate
                this.rawTasks.set(task.id, task)
            }

            // TODO: Update task state on appointment and or maybe cached tasks 
            if (this.getBaseBookingDataComplete){
                this.allTasksComplete(task.id)
            }
        },

        updateTaskStateInRawTasks(id, newState){
            let rawTask = this.rawTasks.get(id)
            rawTask.state = newState
            this.rawTasks.set(id, rawTask)

            // TODO: Update task state on appointment if any
            if (this.getBaseBookingDataComplete){
                this.allTasksComplete(id)
            }
        },

        async allTasksComplete(id, isAppointmentId = false){
            EventBus.$emit('function-activity', {functionName: 'Bookingmixin_allTasksComplete', isActive: true})
            // Default is task id
            
            let appointment = null
            if (!isAppointmentId){
                // Find associate appointment if any
                if (this.appointments){
                    for (let i in this.appointments){
                        for (let j in this.appointments[i].ProjectTasks){
                            if (this.appointments[i].ProjectTasks[j].Id === id){
                                appointment = this.cloneJson(this.appointments[i])
                                appointment.id = this.appointments[i].id
                                break
                            }
                        }
                    }
                    if (!appointment){
                        // console.log("No appointment found")
                        EventBus.$emit('function-activity', {functionName: 'Bookingmixin_allTasksComplete', isActive: false})
                        return false
                    }
                } else {
                    EventBus.$emit('function-activity', {functionName: 'Bookingmixin_allTasksComplete', isActive: false})
                    return false
                }
            } else {
                // Find appointment based on id
                if (this.appointments){
                    for (let i in this.appointments){
                        if (this.appointments[i].id === id){
                            appointment = this.cloneJson(this.appointments[i])
                            appointment.id = this.appointments[i].id
                            break
                        }
                    }
                    if (!appointment){
                        // console.log("Error appointment not found")
                        EventBus.$emit('function-activity', {functionName: 'Bookingmixin_allTasksComplete', isActive: false})
                        return false
                    }
                } else {
                    EventBus.$emit('function-activity', {functionName: 'Bookingmixin_allTasksComplete', isActive: false})
                    return false
                }
            }

            let allTasksComplete = true
            for (let i in appointment.ProjectTasks){
                let task = this.rawTasks.get(appointment.ProjectTasks[i].Id)
                let state = task?.state?.value
                let code = task?.code
                if (state && code){
                    if (code === TaskCode.TICKET){
                        let options = [TicketState.CLOSED, TicketState.RESOLVED]
                        if (!options.includes(state)){
                            allTasksComplete = false
                            break
                        }
                    } else {
                        if (state != TaskState.CLOSED_COMPLETE){
                            allTasksComplete = false
                            break
                        }
                    }  
                } else {
                    allTasksComplete = false
                }
            }

            let currentAllTasksComplete = appointment?.AllTasksComplete
            if (currentAllTasksComplete != allTasksComplete){
                // console.log("All tasks complete state does not match with appointment")
                // console.log("Appointment id",appointment.id)
                await this.dataAddOrUpdateAppointment(null, appointment.id, {AllTasksComplete: allTasksComplete})
            }
            EventBus.$emit('function-activity', {functionName: 'Bookingmixin_allTasksComplete', isActive: false})
            return true
        },

        async bookGetCabinetTasks(cabinetArray, includePending, includeOnHold) {
            EventBus.$emit('function-activity', {functionName: 'Bookingmixin_bookGetCabinetTasks', isActive: true})
            EventBus.$emit('loading-timelines', true)

            let disallowedStates = []
            if (!includePending) {
                disallowedStates.push(TaskState.PENDING)
            }
            if (!includeOnHold) {
                disallowedStates.push(TaskState.ON_HOLD)
            }
            
            // CONSTRUCT QUERY STRING
            let queryString = this.dataConstructQueryString(null, [ProjectType.CABINET], null, disallowedStates, null, null, false, cabinetArray)
            console.log(queryString)
            // let one = 1
            // if (one == 1) return //Ignores the rest of the function, while debugging queryString

            let apiData = await this.dataGetTasksV2(TaskType.PROJECTTASK, null, null, queryString)
            
            /*
             * Replaces each task and adds new tasks, instead of replacing the whole dataset.
             * Because some tasks may derive from `bookAppointmentCheckTasks`
             */
            for (let i in apiData.projectTasks) {
                this.setOrUpdateTaskInRawTasks(apiData.projectTasks[i])
            }

            await this.bookAppointmentCheckTasks()
            EventBus.$emit('function-activity', {functionName: 'Bookingmixin_bookGetCabinetTasks', isActive: false})
        },

        /**
         * Formats the `rawTasks` data.
         * @param {Boolean} withLocationData 
         */
        async bookFormatBookingData(withLocationData = true) {
            EventBus.$emit('function-activity', {functionName: 'Bookingmixin_bookFormatBookingData', isActive: true})
            // console.log('formatting booking data')
            // const rawTaskArray = this.rawTasks.sort((a,b) => { //Format based on rawTasks, sorted by task number to ensure chonology
            //     return a.number > b.number ? 1 : -1
            // })
            const installationModel = JSON.stringify({
                label: '',
                tasks: [], // Indexed array, not key-value pair
                appointments: {}, //Object with document id's as properties
            })

            // Get coordinates if withLocationData flag is on.
            let coordinates;
            let tasksWithoutCoordinates = []
            try {
                if (withLocationData) {
                    if (!this.$route.params.projectIdentifier) {
                        throw new Error(`Route params did not include property 'projectIdentifier': ${JSON.stringify(this.$route.params)}`)
                    }
                    // console.log('Getting coordinates from firestore')
                    coordinates = await this.dataGetAllProjectCoordinates(this.$route.params.projectIdentifier)
                }
            } catch (error) {
                console.error(`Error getting coordinates from Firestore: ${error}`)
            }

            // Loops through and format rawTaskArray into dataMap with format from `installationModel`
            let dataMap = new Map()
            for await (let taskKey of this.rawTasks.keys()) {
                let task = this.rawTasks.get(taskKey)
                var label = this.getConfiguration(task)?.label || this.getConfiguration(task)?.number //Installation Number
                if (!label) { //Handle missing installation number, by using task ID
                    console.error(`task '${task.number}' has no configurationItem label`)
                    if (!this.tasksWithoutInstallationLabels.includes(task.number)){
                        this.tasksWithoutInstallationLabels.push(task.number)
                        swal('Opgave uden installationsnummer', `Der blev fundet en eller flere opgaver i PilotBI der tilsyneladende ikke er tilknyttet nogen installation:\n${this.tasksWithoutInstallationLabels.join('\n')}`, 'warning')
                    }
                    label = task.number ? task.number : task.id
                }
                let model;
                if (dataMap.has(label)) { //If the data map already contains the same installation
                    model = dataMap.get(label) //Add this task to that installation
                } else { //Otherwise make a new installation for the task
                    model = JSON.parse(installationModel)
                    model.label = label
                    model.instId = this.getConfiguration(task).value
                }

                if (!Array.isArray(model.tasks)) {
                    model.tasks = []
                }

                //If any task has referenceId, share that with the other tasks
                if (task.referenceId && !model.tasks[0]?.referenceId){ //If current task has a referenceId, but the first task on the model does not
                    for (let i in model.tasks) { //Loop through all tasks on the model
                        model.tasks[i].referenceId = task.referenceId //Add referenceId to each task
                    }
                } else if (!task.referenceId && model.tasks[0]?.referenceId) { //If current task does not have a referenceId, but the first task on the model does
                    task.referenceId = model.tasks[0].referenceId //Add the referenceId from the first task to the model
                }

                //If any task has project (used for project.type.value), share that with tasks that doesn't
                if (task.project && !model.tasks[0]?.project){ //If current task has a project, but the first task on the model does not
                    for (let i in model.tasks) { //Loop through all tasks on the model
                        if (!model.tasks[i].project) {
                            model.tasks[i].project = task.project //Add project to each task that doesn't have it
                        }
                    }
                } else if (!task.project && model.tasks[0]?.project) { //If current task does not have a project, but the first task on the model does
                    task.project = model.tasks[0].project //Add the project from the first task to the model
                }

                model.tasks.push(task) // Push current task to the model
                dataMap.set(label, model)

                if ((model.tasks[0] || task) && (this.getConfiguration(model.tasks[0])?.cabinet?.name || this.getConfiguration(task)?.cabinet?.name)) {
                    let id = this.getConfiguration(model.tasks[0]).cabinet.name || this.getConfiguration(task).cabinet.name
                    let hubId = id.replaceAll(' ', '')
                    hubId = hubId.substring(0, hubId.indexOf('-') - 1)
                    let technicals = []
                    if (this.stashedTechnicals.hubs[hubId]) {
                        technicals = technicals.concat(this.stashedTechnicals.hubs[hubId])
                    }
                    if (this.stashedTechnicals.uubs[id]) {
                        technicals = technicals.concat(this.stashedTechnicals.uubs[id])
                    }
                    model.technicals = technicals // Add technicals (hub and uub) to the model
                    dataMap.set(label, model)
                }

                /* 
                 * If `withLocationData` is on, then attach coords if exists or fail the task 
                 * by pushing the task to `tasksWithoutCoordinates`
                 */
                if (withLocationData && typeof(coordinates) != 'undefined') {
                    if (!this.getConfiguration(model.tasks[0])?.address?.road) continue
                    if (!coordinates.has(label)) {
                        // console.log(`Installation ${label} has no coordinates in store`)
                        tasksWithoutCoordinates.push(task)
                        continue
                    }
                    model.coordinates = coordinates.get(label) //Add coordinates to the model
                    dataMap.set(label, model)
                }
            }

            if (this.appointments) {
                for (let appointment of this.appointments) {
                    const appointmentId = appointment.id
                    if (!dataMap.get(appointment.InstallationLabel)) {
                        if (!appointment?.AllTasksComplete){
                            console.error(`No installation for appointment '${appointmentId}' with inst#${appointment.InstallationLabel}`)
                        }
                        continue;
                    }
                    if (!dataMap.get(appointment.InstallationLabel).appointments){
                        dataMap.get(appointment.InstallationLabel).appointments = {}
                    }
                    const timeWindowObj = this.readTimeWindowString(appointment.TimeWindowString)
                    dataMap.get(appointment.InstallationLabel).appointments[appointmentId] = {
                        ...appointment,
                        Date: timeWindowObj.Date,
                        Time: timeWindowObj.Time,
                    }
                }
            }

            // If there was any tasks without coordinates, throw exception
            if (withLocationData && tasksWithoutCoordinates.length !== 0) {
                // console.log(`Found ${tasksWithoutCoordinates.length} tasks with missing coordinates`)
                this.installationMissingCoordinates = tasksWithoutCoordinates
                if (tasksWithoutCoordinates.length > 0 && tasksWithoutCoordinates.length <= 50) {
                    try {
                        console.log('Importing coordinates...')
                        await this.startCoordinateImport()
                    }
                    catch (error) {
                        swal('Fejl', `Kunne ikke starte import at koordinater.\n${JSON.stringify(error)}`, 'error')
                    }
                } else {
                    this.showCoordinateImportModal = true
                }
            }
            
            this.tasks = dataMap
            EventBus.$emit('loading-timelines', false)

            if (withLocationData){
                this.sleep(100).then(async () => {
                    await this.regenerateMapMarkers() //TODO: Find a way to run this function less frequently
                })
            }

            if(this.settingsBD.get('sortUnbookedPTBy') == 'productDeliveryDate') {
                this.getAllProducts()
            }
            EventBus.$emit('function-activity', {functionName: 'Bookingmixin_bookFormatBookingData', isActive: false})
            return true
        },

        /**
         * Get the projectTasks for all appointments, by querying by installationLabel.
         * For more than 100 installationLabels, making one API-call per ${bunchSize} labels.
         * Updates tasks in rawTasks, and optionally (by default) triggers bookFormatBookingData when data is retrieved.
         * @param {Array} appointments Array of appointments, with property 'InstallationLabel', or array of Strings ('installationLabel')
         * @param {Number} bunchSize Max number of installationLabels to include per query
         * @param {Boolean} shouldReFormat weather or not bookFormatBookingData should run after data is retrieved
         * @returns {Array} of projectTasks from the API
         */
        async bookGetTasksForAppointments(appointments, bunchSize = 100, shouldReFormat = true){
            EventBus.$emit('function-activity', {functionName: 'Bookingmixin_bookGetTasksForAppointments', isActive: true})
            let installationLabels = []
            for (let appointment of appointments) {
                if (appointment.InstallationLabel) {
                    installationLabels.push(appointment.InstallationLabel)
                } else if (typeof appointment == typeof 'string') {
                    installationLabels.push(appointment)
                } else {
                    try {
                        throw new Error(`appointment has no valid installation label: ${JSON.stringify(appointment)}`)
                    } catch (error) {
                        console.error(error)
                        continue;
                    }
                }
            }
            if (!installationLabels.length){
                // console.log('No appointments to get tasks for')
                EventBus.$emit('function-activity', {functionName: 'Bookingmixin_bookGetTasksForAppointments', isActive: false})
                return []
            } else if (installationLabels.length > bunchSize) { //If there are more installationLabels than the bunch size, handle them in bunches
                let promiseArray = [] //Array of promises
                let returnArray = [] //Array of returnValues, concatenated
                for (let i=0; i<installationLabels.length; i+=bunchSize){
                    const thisBunch = installationLabels.slice(i, i+bunchSize)
                    // console.log(thisBunch)
                    promiseArray.push(this.bookGetTasksForAppointments(thisBunch, bunchSize, false).then((returnVal) => { //Call this function for each bunch
                        returnArray.concat(returnVal)
                    }))
                }
                return Promise.allSettled(promiseArray).then(() => {
                    this.bookFormatBookingData(this.settingsBD.get("showMap"))
                    EventBus.$emit('function-activity', {functionName: 'Bookingmixin_bookGetTasksForAppointments', isActive: false})
                    return returnArray
                })
            }
            try {
                var apiData = await this.dataGetProjectTasks(null, null, true, true, true, true, false, null, null, installationLabels, null)
                // console.log(apiData)
            } catch (error) {
                EventBus.$emit('function-activity', {functionName: 'Bookingmixin_bookGetTasksForAppointments', isActive: false})
                console.error(error)
                throw error
            }
            for (let task of apiData.projectTasks) {
                this.setOrUpdateTaskInRawTasks(task)
            }
            if (shouldReFormat){
                this.bookFormatBookingData(this.settingsBD.get("showMap"))
            }
            EventBus.$emit('function-activity', {functionName: 'Bookingmixin_bookGetTasksForAppointments', isActive: false})
            return apiData.projectTasks
        },


       async migrateUnitWorkAndSubtasksForInsts(instID){
            // console.log("Migrate subtasks and unitwork to this project")
            // console.log("  Project ID", this.project.id)
            // console.log("  Installation label", instID)

            let subtasks = await this.dataGetSubtasksForInst(instID)
            let unitwork = await this.dataGetUnitWorkForInst(instID)

            for (let doc of subtasks){
                if (!doc.LinkedProjects.includes(this.project.id)){
                    doc.LinkedProjects.push(this.project.id)
                    let docID = doc.id
                    delete doc.id
                    await this.dataGenericUpdateDocument('InternalSubTasks', docID, doc)
                }
            }

            for (let doc of unitwork){
                if (!doc.LinkedProjects.includes(this.project.id)){
                    doc.LinkedProjects.push(this.project.id)
                    let docID = doc.id
                    delete doc.id
                    await this.dataGenericUpdateDocument('UnitWork', docID, doc)
                }
            }
        },

        async migrateUnitWorkAndSubtasks(){
            let shouldContinue = await swal({
                title: 'Er du sikker på at du vil migrére enhedsarbejde og interne delopgaver til dette projekt?',
                text: 'Dette er en meget krævende handling!',
                icon: 'warning',
                buttons: true,
                dangerMode: true,
            })

            if (shouldContinue){
                let i = 0
                EventBus.$emit('data-migration-started')
                for (let inst of this.installations){
                    i++
                    let progress = Math.ceil((i / this.installations.length) * 100)
                    await this.migrateUnitWorkAndSubtasksForInsts(inst.label)
                    EventBus.$emit('data-migration-progress-percentage',progress)
                }
                EventBus.$emit('data-migration-complete')
            }

        },

        /**
         * Checks if `rawTasks` contains the associated task for each appointment.
         * If not, it fetches it and pushes to `rawTasks`.
         */
        async bookAppointmentCheckTasks() {
            if (this.hasCheckedAppointments) return
            EventBus.$emit('function-activity', {functionName: 'Bookingmixin_bookAppointmentCheckTasks', isActive: true})
            const appointments = this.appointments
            
            let promiseArray = []
            for (let appointment of appointments) {
                for (let appTask of appointment.ProjectTasks){
                    if (appTask.Role != 'PRIMARY') continue; //Skip non-primary tasks, so we don't waste resources getting all booking tasks for booked installations
                    if (!this.rawTasks.has(appTask.Id)){
                        if (appTask.Code != TaskCode.TICKET){
                            promiseArray.push(
                                this.dataGetProjectTaskWithoutNotes(appTask.Id).then((task) => {
                                    return this.setOrUpdateTaskInRawTasks(task, true)
                                }).catch((error) => {
                                    console.error(error)
                                    swal('Fejl i aftaler', error.message, 'error')
                                })
                            )
                        } else {
                            promiseArray.push(
                                this.dataGetTroubleTicketWithoutNotes(appTask.Id).then((task) => {
                                    return this.setOrUpdateTaskInRawTasks(task, true)
                                }).catch((error) => {
                                    console.error(error)
                                    swal('Fejl i aftaler', error.message, 'error')
                                })
                            )
                        }
                    }
                }
            }

            return Promise.allSettled(promiseArray).then(() => {
                // console.log('finished checking appointments')
                this.hasCheckedAppointments = true
                this.bookFormatBookingData(this.settingsBD.get("showMap"))
                EventBus.$emit('function-activity', {functionName: 'Bookingmixin_bookAppointmentCheckTasks', isActive: false})
            })

        },

        /**
         * Watcher for `appointments`
         */
        async onAppointmentsMutated() {
            EventBus.$emit('function-activity', {functionName: 'Bookingmixin_onAppointmentsMutated', isActive: true})
            this.hasCheckedAppointments = false
            try {
                await this.bookGetTasksForAppointments(this.appointments)
                if (this.rawTasks.length) {
                    await this.bookAppointmentCheckTasks()
                }
            } catch (error) { //Ensure loading animation stops if error is thrown
                EventBus.$emit('function-activity', {functionName: 'Bookingmixin_onAppointmentsMutated', isActive: false})
                throw error
            }
            EventBus.$emit('function-activity', {functionName: 'Bookingmixin_onAppointmentsMutated', isActive: false})
        },

        updateInstallations() {
            let installations = []
            // Formats the map to an array
            this.tasks.forEach((value) => {
                let tasks = []
                for (const [label, task] of Object.entries(value.tasks)) {
                    tasks[label] = task
                }
                value.tasks = tasks
                installations.push(value)
            })

            this.installations = installations
        },

        filterRelevantServiceOrderTasks(serviceOrder) {
            if(!serviceOrder?.project?.tasks){
                throw new Error('Needs serviceOrder to get relevant serviceOrderTasks')
            }
            let filteredServiceOrderTasks = serviceOrder.project.tasks.filter((task) => {
                // console.log(task.code)
                return TaskCode.AllArray.includes(task.code)
            })
            return filteredServiceOrderTasks
        },

        async bookGetCPETask(tasks, serviceOrder) {
            EventBus.$emit('function-activity', {functionName: 'Bookingmixin_bookGetCPETask', isActive: true})
            
            // If tasks contains CPE then fetch that and update the entry in rawTasks
            let indexOfCPETask = tasks.findIndex(t => t.code == TaskCode.CPE)
            if (indexOfCPETask != -1) {
                let task = tasks[indexOfCPETask]

                let updatedTask = await this.dataGetProjectTask(task)
                this.setOrUpdateTaskInRawTasks(updatedTask, true)
                EventBus.$emit('function-activity', {functionName: 'Bookingmixin_bookGetCPETask', isActive: false})
                return updatedTask
            }
            /*
            * If the CPE task is not yet fetched, go get it
            * and then add it to raw tasks.
            */
            let CPERawTask = {}
            if (serviceOrder) {
                CPERawTask = serviceOrder.project.tasks.find(t => t.code == TaskCode.CPE)
            } else {
                let tasks = await this.dataGetTasksV2(TaskType.PROJECTTASK, this.getConfiguration(tasks[0]).value)
                CPERawTask = tasks.find(t => t.code == TaskCode.CPE)
            }
            if (!CPERawTask) {
                EventBus.$emit('function-activity', {functionName: 'Bookingmixin_bookGetCPETask', isActive: false})
                throw new Error('No CPE Task found in service order')
            }

            let CPETask = await this.dataGetProjectTask(CPERawTask)
            this.setOrUpdateTaskInRawTasks(CPETask, true)

            EventBus.$emit('function-activity', {functionName: 'Bookingmixin_bookGetCPETask', isActive: false})
            return CPETask
        },

        async bookGetLastTask(tasks, serviceOrder) {
            EventBus.$emit('function-activity', {functionName: 'Bookingmixin_bookGetLastTask', isActive: true})
            if (!serviceOrder){
                serviceOrder = tasks[0].serviceOrder
            }
            if (!serviceOrder){
                EventBus.$emit('function-activity', {functionName: 'Bookingmixin_bookGetLastTask', isActive: false})
                return this.findLastTask(tasks)
            }
            let relevantTasks = this.filterRelevantServiceOrderTasks(serviceOrder)
            if (!relevantTasks.length) {
                EventBus.$emit('function-activity', {functionName: 'Bookingmixin_bookGetLastTask', isActive: false})
                throw new Error('No relevant tasks in serviceOrder')
            }
            let lastTask = this.findLastTask(relevantTasks)
            let updatedTask = await this.dataGetProjectTask(lastTask)
            this.setOrUpdateTaskInRawTasks(updatedTask, true)
            EventBus.$emit('function-activity', {functionName: 'Bookingmixin_bookGetLastTask', isActive: false})
            return updatedTask
        },

        async startCoordinateImport() {
            // console.log('startCoordinateImport function started')
            EventBus.$emit('function-activity', {functionName: 'Bookingmixin_startCoordinateImport', isActive: true})
            this.coordinateImportStage = 1
            let startTime = new Date()
            let index = 0
            let installations = this.installationMissingCoordinates.reduce((group, ins) => {
                if (!group[index]) group[index] = []
                if (group[index].length == this.googleCoordinateLookupsPerSecond) {
                    index++
                    group[index] = []
                }
                group[index].push(ins)
                return group
            }, [])
            
            index = 0
            let results = new Map()
            let coordinatesImportIntervalLoopRef = 0 //Interval variable must be initialized before starting the interval, otherwise it is not accessible (to clear) inside the function
            coordinatesImportIntervalLoopRef = setInterval(async () => {
                if (!installations[index]) {
                    clearInterval(coordinatesImportIntervalLoopRef)
                    let stopTime = new Date()
                    let timeDiff = stopTime - startTime
                    this.onFinishedCoordinateImport(results, timeDiff)
                    console.log(`Finished coordinate import on group ${index}/${installations.length} (group size ${installations[0].length})`)
                    EventBus.$emit('function-activity', {functionName: 'Bookingmixin_startCoordinateImport', isActive: false})
                    return
                }
                for (let i in installations[index]) {
                    let ins
                    try {
                        ins = installations[index][i]
                        let result = await this.externalGetGeoCoordinatesFromAddress(this.getConfiguration(ins).address)
                        const label = this.getConfigurationLabel(ins)
                        const type = ins?.project?.type?.value  || 'TroubleTickets' //TODO: Should be more resilient to missing data, and not assume Ticket for all missing data
                        if (!label) {
                            throw new Error(`Cannot save coordinates with no label, on inst: ${JSON.stringify(ins)}`)
                        }
                        results.set(label, { 
                            ...result, 
                            areaCode: this.getConfiguration(ins)?.area?.sonWinProjectId, 
                            type,
                        })
                        this.installationMissingCoordinatesIteratorIndex++
                    }
                    catch (error) {
                        this.failedInstallationMissingCoordinates.push(ins)
                        console.error(error.message)
                    }
                }
                index++
            }, 1000)
        },

        async onFinishedCoordinateImport(results, timeDiff) {
            EventBus.$emit('function-activity', {functionName: 'Bookingmixin_onFinishedCoordinateImport', isActive: true})
            this.coordinateImportStage = 2
            this.coordinateImportTime = parseInt(timeDiff / 1000, 10)

            try {
                await this.dataSaveCoordinates(results, this.project.id)
            }
            catch (error) {
                swal('System Fejl', `Kunne ikke gemme data i systemet. Firebase fejl.\n\n${error}`, 'error')
                console.error(error)
            }

            const countSucceeded = this.installationMissingCoordinates.length - this.failedInstallationMissingCoordinates.length
            if (countSucceeded == this.installationMissingCoordinates.length) {
                // console.log(`Re-formatting booking data with ${countSucceeded} new coordinates`)
                await this.bookFormatBookingData(true)
            } else {
                console.log(`Will not re-format booking data because only ${countSucceeded}/${this.installationMissingCoordinates.length} coordinates were successfully retrieved`)
            }
            EventBus.$emit('function-activity', {functionName: 'Bookingmixin_onFinishedCoordinateImport', isActive: false})
        },

        removeClosedTaskFromRawTasks(task){
            let hasRawTask = this.rawTasks.has(task.id)
            if(!hasRawTask){
                console.error(`Kunne ikke fjerne opgave ${task.number} fra rawTasks, fordi den ikke kunne findes iblandt rawTasks`)
            } else {
                console.log(`Removing task from rawTasks id ${task.id}`)
                this.rawTasks.delete(task.id)
            }
        },

        async showHideClosedTasks(areaCodes){
            EventBus.$emit('function-activity', {functionName: 'Bookingmixin_showHideClosedTasks', isActive: true})
            console.log('showHideClosedTasks')
            this.getClosedLoading = true
            try {
                if (this.settingsBD.get("showClosed")) {
                    this.closedTasks = await this.dataGetClosedProjectTasks(new Date(this.closedSince), areaCodes)
                    console.log("Closed tasks",this.closedTasks)

                    this.closedTasks.projectTasks.forEach(task => this.setOrUpdateTaskInRawTasks(task))
                    this.closedTasks.troubleTickets.forEach(ticket => this.setOrUpdateTaskInRawTasks(ticket))

                } else {
                    let rawTaskCount = this.rawTasks.size

                    this.closedTasks.projectTasks.forEach(task => this.removeClosedTaskFromRawTasks(task))
                    this.closedTasks.troubleTickets.forEach(task => this.removeClosedTaskFromRawTasks(task))

                    console.log(`rawTaskCount before start: ${rawTaskCount}\nrawTaskCount after finish: ${this.rawTasks.size}`)
                }
    
                await this.bindClosedAppointments(this.settingsBD.get("showClosed"))
                await this.bookFormatBookingData(this.settingsBD.get("showMap"))
            }
            catch (error){
                swal('Fejl i hentning af lukkede opgaver', error.message, 'error')
            }
            this.getClosedLoading = false
            EventBus.$emit('function-activity', {functionName: 'Bookingmixin_showHideClosedTasks', isActive: false})
        },

        async bookAddOrUpdateResolvedTasks(areaCodes, allowedReferenceIds, disallowedReferenceIds){
            EventBus.$emit('function-activity', {functionName: 'BookingMixin_bookAddOrUpdateResolvedTasks', isActive: true})
            EventBus.$emit('loading-timelines', true)
            let queryString = this.dataConstructQueryString(areaCodes, null, 
                [TicketState.RESOLVED], null, null, null, null, null, null, allowedReferenceIds, disallowedReferenceIds)

            let resolvedTasks = await this.dataGetTasksV2(TaskType.TROUBLETICKET, null, null, queryString)
            resolvedTasks = resolvedTasks.troubleTickets.filter(item => this.AreaCodeProjectTypeFilter(item, areaCodes))
            for (let i in resolvedTasks){
                let resolvedTask = resolvedTasks[i]
                if (!resolvedTask.id){
                    console.log(i)
                    console.log(resolvedTask)
                }
                this.setOrUpdateTaskInRawTasks(resolvedTask)
            }
            await this.bookFormatBookingData(true)
            EventBus.$emit('loading-timelines', false)
            EventBus.$emit('function-activity', {functionName: 'BookingMixin_bookAddOrUpdateResolvedTasks', isActive: false})
        },

        async bookAddOrUpdateOnHoldTasks(areaCodes, projectTypes, referenceIds, disallowedReferenceIds){
            EventBus.$emit('function-activity', {functionName: 'Bookingmixin_bookAddOrUpdateOnHoldTasks', isActive: true})
            EventBus.$emit('loading-timelines', true)
            
            let onHoldProjectTasks = []
            let onHoldTroubleTickets = []

            if (this.loadProjectTasks){
                let queryProjectTypes = projectTypes.filter(type => type != ProjectType.CABINET)
                let queryString = this.dataConstructQueryString(areaCodes, queryProjectTypes, 
                    [TaskState.ON_HOLD], null, null, null, null, null, null, referenceIds, disallowedReferenceIds)
            
                onHoldProjectTasks = await this.dataGetTasksV2(TaskType.PROJECTTASK, null, null, queryString)
                onHoldProjectTasks = onHoldProjectTasks.projectTasks.filter(item => this.AreaCodeProjectTypeFilter(item, areaCodes))

                onHoldProjectTasks.forEach(task => this.setOrUpdateTaskInRawTasks(task))
            }

            if(this.loadTickets){
                let queryString = this.dataConstructQueryString(areaCodes, null, 
                    [TicketState.ON_HOLD], null, null, null, null, null, null, referenceIds, disallowedReferenceIds)

                onHoldTroubleTickets = await this.dataGetTasksV2(TaskType.TROUBLETICKET, null, null, queryString)
                onHoldTroubleTickets = onHoldTroubleTickets.troubleTickets.filter(item => this.AreaCodeProjectTypeFilter(item, areaCodes))

                onHoldTroubleTickets.forEach(task => this.setOrUpdateTaskInRawTasks(task))
            }

            await this.bookFormatBookingData(this.settingsBD.get("showMap"))
            EventBus.$emit('loading-timelines', false)
            EventBus.$emit('function-activity', {functionName: 'Bookingmixin_bookAddOrUpdateOnHoldTasks', isActive: false})
        },

        async bookAddOrUpdatePendingTasks(areaCodes, projectTypes, referenceIds, disallowedReferenceIds){
            EventBus.$emit('function-activity', {functionName: 'Bookingmixin_bookAddOrUpdatePendingTasks', isActive: true})
            EventBus.$emit('loading-timelines', true)
            let queryProjectTypes = projectTypes.filter(type => type != ProjectType.CABINET)
            let queryString = this.dataConstructQueryString(areaCodes, queryProjectTypes, 
                [TaskState.PENDING], null, null, null, null, null, null, referenceIds, disallowedReferenceIds)

            let PendingTasks = await this.dataGetTasksV2(TaskType.PROJECTTASK, null, null, queryString)
            PendingTasks = PendingTasks.projectTasks.filter(item => this.AreaCodeProjectTypeFilter(item, areaCodes))

            for (let i in PendingTasks){
                let PendingTask = PendingTasks[i]
                if (!PendingTask.id){
                    console.log(i)
                    console.log(PendingTask)
                }
                this.setOrUpdateTaskInRawTasks(PendingTask)
            }
            await this.bookFormatBookingData(this.settingsBD.get("showMap"))
            EventBus.$emit('loading-timelines', false)
            EventBus.$emit('function-activity', {functionName: 'Bookingmixin_bookAddOrUpdatePendingTasks', isActive: false})
            return true
        },

        async bookUpdateTaskStatesForInst(instLabel){
            EventBus.$emit('function-activity', {functionName: 'Bookingmixin_bookUpdateTaskStatesForInst', isActive: true})
            let serviceOrders = await this.dataGetAllServiceOrdersByInst(instLabel)
            console.log(serviceOrders)
            let updatedTasks = []
            for (let serviceOrder of serviceOrders) {
                if (!serviceOrder?.project?.tasks) continue;
                console.table(serviceOrder.project.tasks)
                for (let task of serviceOrder.project.tasks) {
                    if (TaskCode.AllArray.includes(task.code)){
                        updatedTasks.push(task)
                        let hasRawTask = this.rawTasks.has(task.id) //findIndex(t => t.id == task.id)
                        if (hasRawTask) {
                            this.updateTaskStateInRawTasks(task.id, task.state)
                        } else {
                            console.error("Task", task.id, "was not found in rawTasks when trying to update task state")
                        }
                    }
                }
            }
            EventBus.$emit('function-activity', {functionName: 'Bookingmixin_bookUpdateTaskStatesForInst', isActive: false})
            return updatedTasks
        },

        formatBookingGroup(groupKey) {
            if (!groupKey || !String(groupKey)) return 'N/A'
            if (Number(groupKey) < 6000) { //Header looks like a zipcode or areaCode
                return String(groupKey)
            }
            if (String(groupKey).match(/[a-z]/g)) { //String containing lowercase letters
                return String(groupKey)
            }
            let dateFormatted = this.formatMachineDate(groupKey)
            if (!dateFormatted || dateFormatted == 'N/A') {
                return String(groupKey)
            }
            return dateFormatted
        },

        findFirstProductDeliveryDate(productsArr) {
            if (!productsArr) return null
            if (typeof productsArr[Symbol.iterator] != 'function'){ //If productsArr is non-iterable, the loop will throw an error, so we dont bother
                console.error('findFirstProductDeliveryDate function called with invalid (non iterable) input', productsArr)
                return null
            }
            let firstExpectedDeliveryDate = null
            for (let product of productsArr) {
                if (!firstExpectedDeliveryDate || product.expectedDeliveryDate < firstExpectedDeliveryDate) {
                    firstExpectedDeliveryDate = product.expectedDeliveryDate
                }
            }
            return firstExpectedDeliveryDate
        },

        filterProductOrderlines(APIresponse, fallbackToUnfiltered = true){ //Expected response is an array of objects, with an 'orderLines' property which is an array
            if (!APIresponse) return []
            let orderLines = []
            for (let i in APIresponse) {
                let product = APIresponse[i]
                for (let l in product.orderLines){
                    orderLines.push(product.orderLines[l])
                }
            }

            // let allowedProductGroups = ['Internet', 'Hardware Wifi', 'Internet Service for Hardware', 'Wholesale - FiberBSA', 'Tilslutningsbidrag']
            let disallowedProductGroups = ['Rabatter', 'OTT', 'Internet Service']
            let filteredOrderLines = orderLines.filter(orderLine => {
                let containsDisallowedGroup = false
                if (!orderLine.product || !orderLine.product.productGroups) {
                    return false //Do not include orderLines with no prodct or no productGroups
                } 
                orderLine.product.productGroups.forEach(group => {
                    if (disallowedProductGroups.includes(group.name)) {
                        containsDisallowedGroup = true
                    }
                })
                return !containsDisallowedGroup
            })
            
            if (fallbackToUnfiltered && !filteredOrderLines.length){
                filteredOrderLines = orderLines
            }

            return filteredOrderLines
        },

        getClusterGroup(ins) {

            const task = ins.tasks[0]

            if (!task) {
                return 'default'
            }

            let appointment = this.appointments.find(a => a.InstallationLabel == ins.label)

            switch (true) {
                case ins.label == this.activeInstallationLabel:
                    return 'active'
                case (!!task.connectionDate && appointment && this.formatMachineDate(this.readTimeWindowString(appointment.TimeWindowString).Date) == this.formatMachineDate(task.connectionDate)):
                    return 'done'
                
                case this.objExistsInArray('InstallationLabel', ins.label, this.appointments): // Check in appointment.ProjectTasks array
                    return 'draft'
                
                case this.insContainsOnlyState(ins, TaskState.PENDING):
                    return 'pending'
                
                case this.insContainsState(ins, TaskState.OPEN):
                    return 'open'
                
                default:
                    return 'default'
            }
        },

        // async getAllServiceOrdersForInst(instLabel){
        //     if (this.loadProjectTasks){
        //         console.log("Getting all service orders")
        //         try {
        //             let serviceOrders = await this.dataGetAllServiceOrdersByInst(instLabel, this.settingsBD.get('showClosed'))
        //             return serviceOrders
        //         } catch (error) {
        //             console.error('Error getting serviceOrders:',error)
        //             throw error
        //         }
        //     } 
            
        //     console.log(instLabel)
        //     return null


        //     // if load project tasks (loadProjectTasks)
        //     // if forceFetch or no so: get all service orders
        //     // this.dataGetAllServiceOrdersByInst(instID, true if get closed)
        //     // update so on installation
        //     // update task state in rawTasks

            
        // },

        getAllTroubleTicketsForInst(instLabel, forceFetch = false){
            // if load trouble tickets (loadTickets)
            // if forcefetch get all tickets
            // get full tickets

            console.log(instLabel, forceFetch)
        },

        uniqueBookingGroups(installations, groupField, returnAsKeys) {
            let uniqueGroups = []
            for (let ins of installations) {
                let groupKey
                if (ins.appointments?.length && groupField == 'AppointmentDate'){
                    for (let app of ins.appointments) {
                        groupKey = this.formatMachineDate(app.Date)
                        if (!groupKey) groupKey = 'N/A'
                        if (!uniqueGroups.includes(groupKey)){
                            uniqueGroups.push(this.formatBookingGroup(groupKey))
                        }
                    }
                } else if (ins.appointment?.Date && groupField == 'AppointmentDate') {
                    groupKey = this.formatMachineDate(ins.appointment.Date)
                } else if (ins.tasks && ins.tasks[0]) {
                    if (groupField.indexOf('.') == -1) {
                        groupKey = ins.tasks[0][groupField]
                    } else {
                        groupKey = this.resolveObjPath(groupField, ins.tasks[0])
                    }
                }
                if (!groupKey) groupKey = 'N/A'
                if (!uniqueGroups.includes(groupKey)){
                    uniqueGroups.push(this.formatBookingGroup(groupKey))
                }
            }

            // console.log(uniqueGroups)
            if (returnAsKeys) {
                //convert array of strings to object with strings as property keys and empty arrays as values
                return uniqueGroups.reduce((v,i) => (v[i] = [], v), {})
            }
            return uniqueGroups
        },

        combineUniqueBookingGroups(group0, group1){
            let keys = Object.keys(group0)
            let groupsToBeDeleted = []
            for (let key in group1){
                if (keys.includes(key)){
                    console.log("Same key found",key)
                    for (let i in group1[key]){
                        group0[key].push(group1[key][i])
                    }
                    groupsToBeDeleted.push(key)
                }
            }

            for (let i in groupsToBeDeleted){
                delete group1[groupsToBeDeleted[i]]
            }
        },

        bookingGetInitials(booking, noAppointment = false) {
            if (!booking) return null
            if (noAppointment){
                return booking.tasks[0]?.assignee
            } else {
                return booking.appointment?.Worker?.Email?.split('@')[0].toLowerCase() || booking.appointment?.WorkerInitials?.toLowerCase()
            }
        },

        bookingGetTimeWindow(booking, noAppointment = false) {
            if (!booking) return null
            if (noAppointment){
                return null
            } else {
                if (!booking.appointment) return null
                return booking.appointment.TimeWindowString
            }
        },

        bookingGetZipCode(booking, noAppointment = false) {
            if (booking?.appointment?.AppointmentType == "Custom") return 0
            if (noAppointment){
                return this.getConfiguration(booking.tasks[0])?.address?.zipcode
            } else {
                if (booking?.appointment?.AddressLong == 'null' || !booking?.appointment?.AddressLong) return 0
                return booking?.appointment?.AddressLong.split(',')[1].replace(/\D/g,'')
            }
        },

        bookingGetAddress(booking, noAppointment = false) {
            if (noAppointment){
                return this.formatAddress(this.getConfiguration(booking.tasks[0])?.address).split(',')[0]
            } else {
                if (booking?.appointment?.AppointmentType == "Custom" || (typeof(booking?.appointment?.AddressLong) == "undefined")) return 0
                return booking?.appointment?.AddressLong.split(',')[0]
            }

        },

        bookingGetDueDate(booking, noAppointment = false){
            if (noAppointment){
                if (!booking.troubleTicketCount) return null
                return booking.tasks[0].dueDate
            }
            return null
        },

        /**
         * Function that returns true if the booking should be marked as a draft
         * @param {Object} booking installation Object containing property appointment
         * @returns {Boolean} weather or not the booking is a draft
         */
        bookingIsDraft(booking){
            if (!booking || !booking.appointment) return false //If there is no booking, it cannot be a draft
            if (!booking.appointment.Confirmed) return true //If the appointment is specifically not confirmed, it must be a draft

            const timeWindowStringArr = booking.appointment.TimeWindowString.split(';')
            if (timeWindowStringArr.length != 3) return false //timeWindowString seems invalid
            const plannedStartToMatch = `${this.formatMachineDate(booking.appointment.Date, '-')}T${timeWindowStringArr[1]}:00`
            const plannedEndToMatch = `${this.formatMachineDate(booking.appointment.Date, '-')}T${timeWindowStringArr[2]}:00`
            const primaryTaskCode = AppointmentType.AppointmentTasks[booking.appointment.AppointmentType].Primary.TaskCode
            const primaryTask = booking.tasks.find((task) => task.code == primaryTaskCode)
            if (!primaryTask) return false
            // console.log(primaryTask.plannedStart, plannedStartToMatch, primaryTask.plannedStart == plannedStartToMatch)
            // console.log(primaryTask.plannedEnd, plannedEndToMatch, primaryTask.plannedEnd == plannedEndToMatch)
            return (
                plannedStartToMatch != primaryTask.plannedStart ||
                plannedEndToMatch != primaryTask.plannedEnd
            )
        },

        appointmentIsDraft(inst){
            if (!inst || !inst.appointment) return false
            if (!inst.appointment.Confirmed) return true
            return false
        },

        headerFromGroupKey(groupKey, settings) {
            if (Number(groupKey) < 6000 && Number(groupKey) > 4999) { //Is funen zipcode
                return `${String(groupKey)} ${this.zipCodeName(groupKey) || ''}`
            }
            if (typeof groupKey == 'string' && groupKey.match(/[a-z-]|[A-Z]{3,}/g)) { //Either normal words, camelCase string, or string containing '-'
                if (groupKey.includes(' ') || groupKey.includes('-')) return groupKey
                return this.camelCaseToSentence(groupKey)
            }

            if (groupKey == '9999/12/31' && this.getProjectSetting(this.project,"sortOverrideProductDeliveryDate")){
                let groupKeyHeader = this.toUserFriendlyDate(groupKey, true, settings.get('showWeekNums'))
                groupKeyHeader += ' (HASTER!)'
                return groupKeyHeader
            }

            return this.toUserFriendlyDate(groupKey, true, settings.get('showWeekNums'))
        },
    },

    watch: {
        'appointments': 'onAppointmentsMutated',
        'tasks': 'updateInstallations',
    }

}
