class UnitWorkCategory {
    static HUB = 'HUB'
    static UUB = 'UUB'
    static BLOW = 'BLOW'
    static SPLICE = 'SPLICE'
    static PATCH = 'PATCH'
    static INSTALLATION = 'INSTALLATION'
    static BUSINESS = 'BUSINESS'
    static GENERAL = 'GENERAL'

    static categoryName(category) {
        switch(category) {
            case this.HUB: 
                return 'Accesshus'
            case this.UUB: 
                return 'Skab/Brønd'
            case this.BLOW: 
                return 'Blæsning'
            case this.SPLICE: 
                return 'Splidsning'
            case this.PATCH: 
                return 'Patchning'
            case this.INSTALLATION: 
                return 'Installation'
            case this.BUSINESS: 
                return 'Erhverv'
            case this.GENERAL: 
                return 'Generelt'
            default: 
                console.error(`${category} is not a known UnitWorkCategory`)
                return category
        }
    }

    static DROPDOWN_OPTIONS = [
        {value: this.HUB, text: this.categoryName(this.HUB)},
        {value: this.UUB, text: this.categoryName(this.UUB)},
        {value: this.BLOW, text: this.categoryName(this.BLOW)},
        {value: this.SPLICE, text: this.categoryName(this.SPLICE)},
        {value: this.PATCH, text: this.categoryName(this.PATCH)},
        {value: this.INSTALLATION, text: this.categoryName(this.INSTALLATION)},
        {value: this.BUSINESS, text: this.categoryName(this.BUSINESS)},
        {value: this.GENERAL, text: this.categoryName(this.GENERAL)},
    ]
}

export default UnitWorkCategory