class SingletonRunner {
    constructor(){

        // This can use used to make sure that only one instance of the class is created (a true singleton)
        // if (!SingletonRunner.instance) {
        //     this.hasRun = false;
        //     this.runKeys = new Map();
        //     SingletonRunner.instance = this;
        // }

        // return SingletonRunner.instance;

        this.hasRun = false;
        this.runKeys = new Map();
        this.result = undefined;
          
    }

    _run(callbackFunction) {
        return new Promise((resolve, reject) => {
            try {
                const result = callbackFunction();
                resolve(result);
            } catch (error) {
                reject(error)
            }
        })
    }

    runOnce(callbackFunction) {
        // let callbackName = callbackFunction.name || 'Anonymous Function'; 
        // console.log(`Running ${callbackName}`)

        if (!this.hasRun && typeof callbackFunction === 'function') {
            this.result = this._run(callbackFunction);
            this.hasRun = true;
        }
        return this.result;
    }

    runIf(condition, callbackFunction) {
        if (!this.hasRun && typeof callbackFunction === 'function' && condition) {
            this.result = this._run(callbackFunction);
            this.hasRun = true;
        }
        return undefined;
    }

    reset() {
        this.hasRun = false;
        this.result = undefined;
    }

    getStatus() {
        return this.hasRun;
    }


    // Use like this: console.log(await runner.runOncePerKey('one', () => {return "First method"}))
    runOncePerKey(key, callbackFunction) {
        if (!this.runKeys.has(key) && typeof callbackFunction === 'function') {
            const result =  this._run(callbackFunction);
            this.runKeys.set(key, { hasRun: true, result }); // Store the result
            return result;
        }
        return this.runKeys.get(key)?.result; // Return the stored result if it exists
    }

    runOncePerKeyIf(condition, key, callbackFunction) {
        if (!this.runKeys.has(key) && typeof callbackFunction === 'function' && condition) {
            const result =  this._run(callbackFunction);
            this.runKeys.set(key, { hasRun: true, result }); // Store the result
            return result;
        }
        return this.runKeys.get(key)?.result; // Return the stored result if it exists
    }

    resetKey(key, clearAll = false) {
        if (clearAll) {
            this.runKeys.clear(); // Remove all keys and results
            return;
        }
        this.runKeys.delete(key); // Remove a specific key and its result
    }

    getStatusForKey(key) {
        return this.runKeys.has(key); // Check if the key has been used
    }
}

export default SingletonRunner;