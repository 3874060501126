class TaskState {

    static PENDING = '-5'
    static OPEN = '1'
    static WORK_IN_PROGRESS = '2'
    static CLOSED_COMPLETE = '3'
    static CLOSED_INCOMPLETE = '4'
    static ON_HOLD = '5'
    static CLOSED_SKIPPED = '7'

    static getValueFromCode(code) {
        switch(code) {
            case this.PENDING:
                return 'Pending'
            
            case this.OPEN:
                return 'Open'
            
            case this.WORK_IN_PROGRESS:
                return 'Work in Progress'
            
            case this.CLOSED_COMPLETE:
                return 'Closed Complete'
            
            case this.CLOSED_INCOMPLETE:
                return 'Closed Incomplete'
            
            case this.ON_HOLD:
                return 'On Hold'
            
            case this.CLOSED_SKIPPED:
                return 'Closed Skipped'
            
            default:
                return 'Unknown State'
        }
    }

    static DROPDOWN_OPTIONS = [
        {value: this.PENDING, text: this.getValueFromCode(this.PENDING)},
        {value: this.OPEN, text: this.getValueFromCode(this.OPEN)},
        {value: this.WORK_IN_PROGRESS, text: this.getValueFromCode(this.WORK_IN_PROGRESS)},
        {value: this.ON_HOLD, text: this.getValueFromCode(this.ON_HOLD)},
        {value: this.CLOSED_COMPLETE, text: this.getValueFromCode(this.CLOSED_COMPLETE)},
        {value: this.CLOSED_INCOMPLETE, text: this.getValueFromCode(this.CLOSED_INCOMPLETE)},
        {value: this.CLOSED_SKIPPED, text: this.getValueFromCode(this.CLOSED_SKIPPED)},
    ]

    static openStatesArray = [
        this.OPEN,
        this.WORK_IN_PROGRESS
    ]

    static closedStatesArray = [
        this.CLOSED_COMPLETE,
        this.CLOSED_INCOMPLETE,
        this.CLOSED_SKIPPED,
    ]
}

export default TaskState
