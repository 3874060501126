<template>
    <div class="login-box">
        <div class="login-logo">
            <!-- <img class="logo" src="https://firebasestorage.googleapis.com/v0/b/fiberlan-ftth-dev.appspot.com/o/assets%2FFiberLAN_logo_negativ.svg?alt=media&token=5f76bd57-8cde-4c3b-8a74-11f070e0bf13" /> -->
            <img class="logo" src="https://firebasestorage.googleapis.com/v0/b/fiberlan-ftth-dev.appspot.com/o/assets%2FFiberLAN_logo.svg?alt=media&token=229c6da0-c5a7-4b4b-938f-120ba435f6b2" />
        </div>

        <div v-if="isLoading">
            <TableLoader />
        </div>

        <DataCard class="login-card-body" v-if="!isLoading">
            <p class="login-box-msg">Log ind for at bruge FiberTeam</p>
            
            <div v-if="this.error" class="alert alert-danger">
                <p>Der er opstået en fejl.</p>
                Fejl: {{ this.error }}
            </div>

            <!-- <div class="social-auth-links text-center mb-3">
                <a href="#/login" @click.prevent="login" class="btn btn-block btn-primary">
                    <i class="fa-brands fa-microsoft" style="margin-right: 5px"></i>
                    Login med din FiberLAN Konto
                </a>
            </div> -->

            <div style="display: flex; justify-content: center; margin-bottom: 15px; margin-right: -10px; margin-left: -10px;">
                <sui-button 
                    class="fiberlan-button" 
                    fluid
                    circular
                    @click.prevent="login"
                    size="tiny"
                    style="padding-right: 5px; padding-left: 5px;"
                > <i class="fa-brands fa-microsoft" style="margin-right: 2px;"></i>
                Login med din FiberLAN Konto </sui-button>
            </div>

            <p class="login-box-msg">Problemer med at logge ind?</p>
            <p class="login-box-msg">Kontakt din administrator <br/> på (+45) 63 10 12  58 eller <br/> på afd-q@fiberlan.dk</p>

        </DataCard>
    </div>
</template>

<script>
import { auth } from '../firebase'
import EventBus from '../EventBus'
import swal from 'sweetalert'
import TableLoader from '../components/TableLoader.vue'

export default {
    name: 'LoginScreen',
    data() {
        return {
            error: null,
            isLoading: false
        }
    },

    components: {
        TableLoader
    },

    methods: {
        login: function()
        {
            try {
                this.$store.dispatch('login')
            } catch (error) {
                console.error(error)
            }
        }
    },

    beforeMount() {

        if (auth.currentUser != null) {
            this.$router.push('/')
            return
        }

        EventBus.$on('login-failure', error => {
            this.error = error.code
            console.error(error)
        })

        EventBus.$on('event-login-success-start-loading', () => {
            this.isLoading = true
        })

        this.$parent.$emit('update:breadcrumbs', [
            {
                name: 'Login',
                url: '/login'
            }
        ])

        window.document.getElementsByTagName('body')[0].setAttribute('class', 'login-page')
    },

    mounted() {
        //If URL param 'TokenRevoked' exists, and is true, show a swal stating that the user has been logged out by an admin
        if (this.$route.query.tokenRevoked === 'true') {
            swal({
                title: 'Logget ud',
                text: 'Du er blevet logget ud af systemet af en administrator.',
                icon: 'warning',
            })
        }

        //IF URL param 'logOutReason' exists, show a swal stating that the user has been logged out, with the reason provided.
        if (this.$route.query.logOutReason) {
            swal({
                title: 'Logget ud',
                text: `Du er blevet logget ud af systemet, fordi ${this.$route.query.logOutReason}`,
                icon: 'info',
            })
        }
    }
}
</script>
<style scoped>

.login-box {
    align-content: center;
    height: 100svh;
    margin-left: auto;
    margin-right: auto;
}
img.logo {
    width: 100%;
}
</style>
<style>
.login-page {
    /* background-color: #ccb19d; */
    background-image: url('https://fiberlan.dk/wp-content/uploads/2023/04/FiberLAN-lokationer-4-1.svg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
    background-color: #e0aa66;
}
</style>
