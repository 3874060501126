<template>
    <sui-modal
        small
        :open="isOpen"
        v-on:clickAwayModal="closeModal()"
    >
        <sui-modal-header>
            {{beforeChange ? 'Redigér' : 'Tilføj'}} arbejds-enhed
        </sui-modal-header>
        <sui-modal-content scrolling>
            <sui-form warning :loading="formLoading">
                <div class="row">
                    <div class="col-sm-8">
                        <sui-form-fields>
                            <sui-form-field width="five" required>
                                <label>Enheds ID</label>
                                <sui-input required placeholder='Pos Nr' v-model="unitIdInput" />
                            </sui-form-field>
                            <sui-form-field width="eleven" required>
                                <label>Enheds Navn</label>
                                <sui-input required placeholder="Kort beskrivelse af enheden" v-model="unitNameInput"/>
                            </sui-form-field>
                        </sui-form-fields>
                        <sui-form-fields>
                            <sui-form-field width="five" required>
                                <label>Enhed <small>(stk, m, t, ...)</small></label>
                                <sui-input required placeholder="stk" v-model="unitInput"/>
                            </sui-form-field>
                            <sui-form-field width="five" required>
                                <label>Enheds Pris <small>(kr)</small></label>
                                <sui-input type="number" min="0" max="999999" step="0.01" required placeholder="0" v-model="priceInput" @blur="reCalculateEH('hours')" />
                            </sui-form-field>
                            <sui-form-field width="six" required>
                                <label>Tilsvarende timer <small>(hhh:mm:ss)</small></label>
                                <sui-input required placeholder="0:00:00" v-model="equivalentHoursInput" @blur="reCalculateEH('price')" />
                            </sui-form-field>
                        </sui-form-fields>
                    </div>
                    <div class="col-sm-4">
                        <sui-form-field required>
                            <label>Kategorier</label>
                            <sui-dropdown
                                fluid multiple search selection
                                :options="UnitWorkCategories.DROPDOWN_OPTIONS"
                                v-model="selectedCategories"
                            />
                        </sui-form-field>
                    </div>
                </div>
                <sui-form-field>
                    <label>Beskrivelse</label>
                    <textarea v-model="descriptionInput" placeholder="Eventuel beskrivelse af det arbejde der hører med i enheden"/>
                </sui-form-field>
            </sui-form>
        </sui-modal-content>
        <sui-modal-actions>
            <sui-button
                negative
                @click="closeModal"
            >
                Luk
            </sui-button>
            <sui-button
                positive
                type="submit"
                @click="saveChanges()"
            >
                Gem og luk
            </sui-button>
            <sui-button
                positive
                type="submit"
                @click="saveChanges(true)"
            >
                Gem og opret ny
            </sui-button>
        </sui-modal-actions>
    </sui-modal>
</template>
<script>
import EventBus from '../../EventBus'
import ProjectType from '../../lib/Enums/ProjectType'
import UnitWorkCategories from '../../lib/Enums/UnitWorkCategories'
import { Mixin } from '../../lib/Mixins/mixin'
import { DateMixin } from '../../lib/Mixins/dateMixin'
import { db } from '../../firebase'
import { DataAPI } from '../../lib/DataAPI'
import { unitWorkMixin } from '../../lib/unitWorkMixin'
import swal from 'sweetalert'

export default {
    name: 'EditUnitWorkUnitModal',
    mixins: [Mixin, DateMixin, DataAPI, unitWorkMixin],

    enums: {
        ProjectType,
        UnitWorkCategories,
    },

    props: {
        isOpen: {
            type: Boolean,
            requred: true,
            default: false,
        },
        user: {
            type: Object,
            required: false,
        },
        beforeChange: {
            type: Object,
            required: false,
        },
        baseData: {
            type: Object,
            requred: true,
        },
    },

    data() {
        return {
            formLoading: false,

            unitIdInput: null,
            unitNameInput: null,
            selectedCategories: [],
            unitInput: null,
            priceInput: 0,
            equivalentHoursInput: null,
            equivalentHoursNumber: 0,
            descriptionInput: null,
        }
    },

    computed: {
        
    },

    methods: {
        closeModal() {
            EventBus.$emit('edit-unit-work-unit-modal-close')
        },
        prePopulateForm() {
            console.log('pre-populating form')
            this.unitIdInput = this.beforeChange?.id 
            this.unitNameInput = this.beforeChange?.Name 
            this.unitInput = this.beforeChange?.Unit
            this.priceInput = this.beforeChange?.UnitPrice
            this.equivalentHoursInput = this.decimalToHourString(this.beforeChange?.EquivalentHours, true)
            this.selectedCategories = this.beforeChange?.Categories 
            this.descriptionInput = this.beforeChange?.Description
        },
        async saveChanges(startOver = false) {
            EventBus.$emit('function-activity', {functionName: 'EditUnitWorkUnitModal_saveChanges', isActive: true})
            this.formLoading = true
            console.log('Saving changes...')
            
            let unitObject = {
                Id: this.unitIdInput,
                Name: this.unitNameInput,
                Unit: this.unitInput,
                UnitPrice: this.priceInput,
                EquivalentHours: this.equivalentHoursNumber,
                Categories: this.selectedCategories ? this.selectedCategories : [],
                Description: this.descriptionInput || '',
                LastUpdated: new Date(),
            }

            try {
                db.collection('UnitWorkUnits').doc(unitObject.Id).set(unitObject)
                console.log(unitObject)
            } catch (error) {
                swal('Fejl i opdatering af enhed', error.message, 'error')
                console.error(`Error in updating unit: ${error.message}`)
            }

            this.prePopulateForm()
            this.formLoading = false
            EventBus.$emit('function-activity', {functionName: 'EditUnitWorkUnitModal_saveChanges', isActive: false})
            if(startOver){
                console.log('Restarting form...')   
            }
            else this.closeModal()
        },
        reCalculateEH(updateValue){
            console.log(`Updating ${updateValue}`)
            let hourPrice = 0
            if (this.baseData.EquivalentHourPrice) {
                hourPrice = this.baseData.EquivalentHourPrice
            } else {
                try {
                    throw new Error('Could not calculate price from hours, because hourly price was not available')
                } catch (error) {
                    console.error(error)
                    return 0
                }
            }
            var ehDecimal = 0
            switch (updateValue) {
                case 'price': 
                    ehDecimal = this.hourStringToDecimal(this.equivalentHoursInput)
                    this.priceInput = Math.round( ehDecimal * hourPrice * 100 ) / 100
                    return this.priceInput
                case 'hours': 
                default:
                    ehDecimal = this.priceInput / hourPrice
                    this.equivalentHoursInput = this.decimalToHourString(ehDecimal, true)
                    return this.equivalentHoursInput
            }
        }
    },

    beforeMount(){

    },

    watch: {
        beforeChange: {
            immediate: false,
            handler() {
                this.prePopulateForm()
            }
        },
        equivalentHoursInput: {
            immediate: true,
            handler(input) {
                this. equivalentHoursNumber = this.hourStringToDecimal(input)
            }
        }
    },
}
</script>