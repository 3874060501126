<template>
    <div>
        <sui-modal
            id="viewTroubleTicketModal"
            :open="isOpen"
            v-on:clickAwayModal="clickAway()"
        >
            <sui-modal-header>
                {{modalTitle}}
            </sui-modal-header>
            <sui-modal-content scrolling style="max-height: 70vh" v-if="troubleTicket">
                <sui-table striped :columns="3" color="red">
                    <sui-table-header>
                        <sui-table-row>
                            <sui-table-headerCell colspan="3">
                                Generelt
                            </sui-table-headerCell>
                        </sui-table-row>
                    </sui-table-header>
                    <sui-table-body>
                        <sui-table-row>
                            <sui-table-cell>InstallationsNr</sui-table-cell>
                            <sui-table-cell>{{ troubleTicket.installation.number }}</sui-table-cell>
                        </sui-table-row>
                        <sui-table-row>
                            <sui-table-cell>Oprettet</sui-table-cell>
                            <sui-table-cell>{{ toUserFriendlyTimestamp(troubleTicket.createdAt) }}</sui-table-cell>
                        </sui-table-row>
                        <sui-table-row>
                            <sui-table-cell>Frist</sui-table-cell>
                            <sui-table-cell>{{ toUserFriendlyTimestamp(troubleTicket.dueDate) }}</sui-table-cell>
                        </sui-table-row>
                        <sui-table-row>
                            <sui-table-cell>Løst</sui-table-cell>
                            <sui-table-cell>{{ toUserFriendlyTimestamp(troubleTicket.resolvedAt) }}</sui-table-cell>
                        </sui-table-row>
                        <sui-table-row>
                            <sui-table-cell>Medarbejder</sui-table-cell>
                            <sui-table-cell>{{ troubleTicket.assignee }}</sui-table-cell>
                        </sui-table-row>
                    </sui-table-body>
                </sui-table>

                <sui-table striped :columns="3" color="orange">
                    <sui-table-header>
                        <sui-table-row>
                            <sui-table-headerCell colspan="3">
                                Beskrivelse
                            </sui-table-headerCell>
                        </sui-table-row>
                    </sui-table-header>
                    <sui-table-body>
                        <sui-table-row :key="`${troubleTicket.id}-shortDescription`">
                            <sui-table-cell>Kort beskrivelse</sui-table-cell>
                            <sui-table-cell>{{ troubleTicket.shortDescription }}</sui-table-cell>
                        </sui-table-row>
                        <sui-table-row :key="`${troubleTicket.id}-description`">
                            <sui-table-cell>Beskrivelse</sui-table-cell>
                            <sui-table-cell>{{ troubleTicket.description }}</sui-table-cell>
                        </sui-table-row>
                    </sui-table-body>
                </sui-table>

                <sui-table striped :columns="3" color="yellow">
                    <sui-table-header>
                        <sui-table-row>
                            <sui-table-headerCell colspan="3">
                                Status
                            </sui-table-headerCell>
                        </sui-table-row>
                    </sui-table-header>
                    <sui-table-body>
                        <sui-table-row>
                            <sui-table-cell>Prioritet</sui-table-cell>
                            <sui-table-cell v-if="troubleTicket.priority">{{ troubleTicket.priority.label }}</sui-table-cell>
                            <sui-table-cell v-if="!troubleTicket.priority"></sui-table-cell>
                        </sui-table-row>
                        <sui-table-row>
                            <sui-table-cell>Vigtighed</sui-table-cell>
                            <sui-table-cell v-if="troubleTicket.urgency">{{ troubleTicket.urgency.label }}</sui-table-cell>
                            <sui-table-cell v-if="!troubleTicket.urgency"></sui-table-cell>
                        </sui-table-row>
                        <sui-table-row>
                            <sui-table-cell>Status</sui-table-cell>
                            <sui-table-cell v-if="troubleTicket.state">{{ troubleTicket.state.label }}</sui-table-cell>
                            <sui-table-cell v-if="!troubleTicket.state"></sui-table-cell>
                        </sui-table-row>
                    </sui-table-body>
                </sui-table>

                <sui-table striped :columns="3" color="green">
                    <sui-table-header>
                        <sui-table-headerCell colspan="3">
                            Kontakt
                        </sui-table-headerCell>
                    </sui-table-header>
                    <sui-table-body>
                        <sui-table-row>
                            <sui-table-cell colspan="2">Adresse</sui-table-cell>
                            <sui-table-cell colspan="1">{{ formatAddress(troubleTicket.installation.address, false) }}</sui-table-cell>
                        </sui-table-row>
                        <sui-table-row>
                            <sui-table-cell colspan="2">Navn</sui-table-cell>
                            <sui-table-cell colspan="1">{{ troubleTicket.contacts[0].name }}</sui-table-cell>
                        </sui-table-row>
                        <sui-table-row>
                            <sui-table-cell colspan="2">E-mail</sui-table-cell>
                            <sui-table-cell colspan="1">{{ troubleTicket.contacts[0].email }}</sui-table-cell>
                        </sui-table-row>
                        <sui-table-row>
                            <sui-table-cell colspan="2">Mobil</sui-table-cell>
                            <sui-table-cell colspan="1">{{ troubleTicket.contacts[0].mobile }}</sui-table-cell>
                        </sui-table-row>
                        <sui-table-row>
                            <sui-table-cell colspan="2">Telefon</sui-table-cell>
                            <sui-table-cell colspan="1">{{ troubleTicket.contacts[0].phone }}</sui-table-cell>
                        </sui-table-row>
                        <sui-table-row>
                            <sui-table-cell colspan="2">Rolle</sui-table-cell>
                            <sui-table-cell colspan="1">{{ troubleTicket.contacts[0].role }}</sui-table-cell>
                        </sui-table-row>
                    </sui-table-body>
                </sui-table>

                <sui-table striped color="blue" v-if="troubleTicket.notes">
                    <sui-table-header>
                        <sui-table-row>
                            <sui-table-headerCell v-if="troubleTicket.notes.length"> 
                                Noter 
                            </sui-table-headerCell>
                            <sui-table-headerCell v-if="!troubleTicket.notes.length"> 
                                Der er tilsyneladende ingen noter tilknyttet denne driftsag
                            </sui-table-headerCell>
                        </sui-table-row>
                    </sui-table-header>
                    <sui-table-body>
                        <sui-table-row 
                            v-for="note in troubleTicket.notes"
                            :key="note.id"
                        >
                            <sui-table-cell>
                                <p>
                                    <strong><i v-if="note.createdBy.includes('@fiberlan.dk')" class="fa-solid fa-eye-slash" title="Intern note, kan ikke ses af andre end FiberLAN"/> {{ note.createdBy }}<span v-if="parseNote(note.value).author"> ({{ parseNote(note.value).author }})</span></strong> - 
                                    <span>{{ toUserFriendlyTimestamp(note.createdAt) }}</span>
                                    <span v-if="parseNote(note.value).origin"><br><small>{{ parseNote(note.value).origin }}</small></span>
                                </p>
                                <p class="note-body">{{ parseNote(note.value).body }}</p>
                            </sui-table-cell>
                        </sui-table-row>
                    </sui-table-body>
                </sui-table>
            </sui-modal-content>
            <sui-modal-actions>
                <sui-button @click="clickAway()">Luk</sui-button>
            </sui-modal-actions>
        </sui-modal>
    </div>
</template>

<script>
import { Mixin } from '../../lib/Mixins/mixin.js'
import { DateMixin } from '../../lib/Mixins/dateMixin'
import { DataAPI } from '../../lib/DataAPI.js'
import EventBus from '../../EventBus.js'
import TaskType from '../../lib/Enums/TaskType'

export default {
    mixins: [Mixin, DateMixin, DataAPI],

    enums: {
        TaskType,
    },

    props: {
        isOpen: Boolean,
        providedTroubleTicket: Object,
    },

    data(){
        return {
            troubleTicket: null,
            modalTitle: "Henter Trouble Ticket...",
        }
    },

    computed: {
        user() {
            return this.$parent.$parent.$parent.user
        },
    },

    methods: {
        clickAway(){
            EventBus.$emit('view-trouble-ticket-modal-closing')
            this.clearForm()
        },

        clearForm(){
            this.troubleTicket = null
            this.modalTitle = "Henter Trouble Ticket..."
        },

        async getTroubleTicket() {
            if (this.providedTroubleTicket){
                EventBus.$emit('function-activity', {functionName: 'NexelServiceOrder_getTroubleTicket', isActive: true})
                console.log("provided trouble ticket", this.providedTroubleTicket)
                const resp = await this.dataGetTroubleTicket(this.providedTroubleTicket.id, this.providedTroubleTicket.installation.number) 
                console.log("Trouble Ticket", resp)

                this.troubleTicket = resp
                this.modalTitle = `Trouble Ticket ${this.troubleTicket.number}`

                EventBus.$emit('function-activity', {functionName: 'NexelServiceOrder_getTroubleTicket', isActive: false})
            }
        },

        expandDescription(desc){
            if (desc != null) {
                let tmpStr = desc.split(' ')[0]
                if (tmpStr == "HC") return desc.replace("HC","House Connected")
                return desc.replace("HP","House Passed")
            }
        },
    },

    watch: {
        providedTroubleTicket: {
            immediate: true,
            handler() {
                this.getTroubleTicket()
            }
        },
    }
}
</script>

<style scoped>
    table.selectable tr:hover td {
        cursor: pointer;
    }
</style>