<template v-if="apiResponse">
    <sui-table-body>
            <sui-table-row v-if="appointmentCount">
                <sui-table-headerCell>
                    Dato
                </sui-table-headerCell>
                <sui-table-headerCell>
                    Tid
                </sui-table-headerCell>
                <sui-table-headerCell>
                    Montør
                </sui-table-headerCell>
                <sui-table-headerCell>
                    Status
                </sui-table-headerCell>
                <sui-table-headerCell>
                    Opdateret
                </sui-table-headerCell>
                <!-- <sui-table-headerCell></sui-table-headerCell> -->
            </sui-table-row>
            <sui-table-row v-else>
                <sui-table-headerCell>
                    Der er ingen aftaler tilknyttet denne installation
                </sui-table-headerCell>
            </sui-table-row>
            <sui-table-row
                v-for="appointment in apiResponse" 
                :key="appointment.id"
            >
                <sui-table-cell> 
                    {{toUserFriendlyDate(readTimeWindowString(appointment.TimeWindowString).Date)}} 
                </sui-table-cell>
                <sui-table-cell> 
                    {{readTimeWindowString(appointment.TimeWindowString).Time.From}} - {{readTimeWindowString(appointment.TimeWindowString).Time.To}} 
                </sui-table-cell>
                <sui-table-cell>
                    <span v-if="appointment.Worker && appointment.Worker.Email"> {{appointment.Worker.Email.substr(0,appointment.Worker.Email.indexOf('@')).toUpperCase()}} </span>
                </sui-table-cell>
                <sui-table-cell> 
                    {{appointment.State}} 
                </sui-table-cell>
                <sui-table-cell> 
                    {{toUserFriendlyTimestamp(appointment.Updated)}} 
                </sui-table-cell>
                <sui-table-cell>
                    <span v-if="appointment.Flag">
                        <i class="fa-solid fa-flag" style="color: tomato;" title="Husk at læse noterne inden du kører ud til kunden"></i>
                    </span>
                    <span v-if="appointment.Lock">
                        <i class="fa-solid fa-lock" style="color: dimgray;" title="Aftalen er låst for ombooking"></i>
                    </span>
                    <span v-if="appointment.Confirmed"> 
                        <i class="fas fa-check" title="Aftalen er bekræftet"></i>
                    </span>
                    <span v-if="!appointment.Confirmed"> 
                        <i class="fas fa-divide fa-rotate-135" title="Aftalen er ikke bekræftet"></i>
                    </span>
                </sui-table-cell>
            </sui-table-row>
    </sui-table-body>
</template>

<script>
import { Mixin } from '../../lib/Mixins/mixin'
import { DateMixin } from '../../lib/Mixins/dateMixin'
import { DataAPI } from '../../lib/DataAPI'
import EventBus from '../../EventBus'

export default {
    mixins: [Mixin, DateMixin, DataAPI],

    props: {
        instLabel: String,
    },

    data(){
        return {
            apiResponse: null,
            appointmentCount: null,
        }
    },

    methods: {

        async getAppointments() {
            EventBus.$emit('function-activity', {functionName: 'InstCheckAppointments_getAppointments', isActive: true})
            this.apiResponse = null
            EventBus.$emit('retrieved-appointments', {data: 'Henter aftaler...', loading: true})
            if (!this.instLabel) {
                EventBus.$emit('retrieved-appointments', {data: 'Intet installations-nr', loading: false})
                EventBus.$emit('function-activity', {functionName: 'InstCheckAppointments_getAppointments', isActive: false})
                return null
            }
            try {
                this.apiResponse = await this.dataGetAppointmentsOnInst(this.instLabel)
                if (this.apiResponse && this.apiResponse.length) {
                    this.apiResponse = this.apiResponse.sort((a, b) => (b.TimeWindowString > a.TimeWindowString) ? 1 : -1)
                    EventBus.$emit('retrieved-appointments', {data: this.apiResponse[0].InstallationLabel, loading: false})
                    EventBus.$emit('retrieved-appointments-title', `Aftaler (${this.apiResponse.length})`)
                }
                else {
                    EventBus.$emit('retrieved-appointments', {data: this.instLabel, loading: false})
                    EventBus.$emit('retrieved-appointments-title', 'Aftaler')
                }
            } catch (error) {
                EventBus.$emit('retrieved-appointments', {data: 'Kunne ikke hente data!', loading: false})
                console.error(error)
            }
            EventBus.$emit('function-activity', {functionName: 'InstCheckAppointments_getAppointments', isActive: false})
            this.appointmentCount = this.apiResponse?.length
            return this.apiResponse
        },
    },

    mounted() {
        EventBus.$on('reload-appointments-click', () => {this.getAppointments()})
    },

    beforeDestroy() {
        EventBus.$off('reload-appointments-click')
    },

    watch: {
        instLabel: {
            immediate: true,
            handler() {
                this.getAppointments()
            }
        }
    }
}
</script>