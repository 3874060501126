<template>
    <div> 
        <!-- CUSTOM APPOINTMENT MODAL -->
        <sui-modal 
            id="editCustomAppointmentModal"
            :open="isOpen" 
            v-on:clickAwayModal="clickAway()"
            v-if="openCustomAppointment"
        >
            <sui-modal-header>
                Brugerdefineret Aftale
            </sui-modal-header>
            <sui-modal-content scrolling>
                <sui-form :loading="formLoading">
                    <sui-form-field :required="true">
                        <label>Primær tekst</label>
                        <input placeholder="Helligdag" v-model="titleInput"/>
                    </sui-form-field>
                    <sui-form-fields fields="three">
                        <sui-form-field>
                            <label>Dato</label>
                            <input type="date" v-model="dateInput" />
                        </sui-form-field>
                        <sui-form-field>
                            <label>Evt. tidsrum</label>
                            <div class="row">
                                <div class="col-sm-6">
                                    <input placeholder="fra" v-model="timeWindowFromInput"/>
                                </div>
                                <div class="col-sm-6">
                                    <input placeholder="til" v-model="timeWindowToInput"/>
                                </div>
                            </div>
                        </sui-form-field>
                        <sui-form-field>
                            <label>Evt montør-initialer</label>
                            <input placeholder="ABC" v-model="workerInitialsInput"/>
                        </sui-form-field>
                    </sui-form-fields>
                    <sui-button 
                        type="button"
                        class="ui labeled icon button"
                        icon="sync"
                        @click="onMakeSeriesClick()"
                    >
                        {{isPartOfSeries ? 'Rediger serie' : 'Gør aftalen gentagende'}}
                    </sui-button>

                    <sui-form-field>
                        <label>Evt note</label>
                        <textarea v-model="noteInput"/>
                    </sui-form-field>
                    <sui-form-field>
                        <sui-checkbox label="Gælder alle projekter" v-model="appliesToAllProjectsInput"/>
                    </sui-form-field>
                    <sui-form-field :disabled="appliesToAllProjectsInput">
                        <label>Gælder disse projekter:</label>
                        <sui-dropdown multiple placeholder="projekter" selection :options="allProjectsDropdown" v-model="appliesToTheseProjectsInput"/>
                    </sui-form-field>
                </sui-form>
            </sui-modal-content>
            <sui-modal-actions>
                <sui-button negative @click="deleteAppointment()" :disabled="(!this.customAppointment || !this.customAppointmentId)">Slet</sui-button>
                <sui-button negative @click="clickAway()">Luk</sui-button>
                <sui-button positive @click="saveChanges()" :disabled="seriesSaveButtonDisabled">Gem</sui-button>
            </sui-modal-actions>
        </sui-modal>

        <!-- RECURRING APPOINTMENTS MODAL -->
        <sui-modal
            :open="openEditSeries"
            v-on:clickAwayModal="openEditSeries"
        >
            <sui-modal-header>
                Tilbagevendende Aftale
            </sui-modal-header>
            <sui-modal-content scrolling>
                <sui-form :loading="formLoading">
                    <!-- Recurrence pattern -->
                    <sui-header dividing>Gentages</sui-header>
                    <sui-form-fields inline>
                        <sui-form-fields grouped>
                            <sui-form-field>
                                <sui-checkbox radio
                                    name="recurrenceFrequency"
                                    label="Dagligt"
                                    value="daily"
                                    id="repeat0"
                                    v-model="recurrenceFrequency"/>
                            </sui-form-field>
                            <sui-form-field>
                                <sui-checkbox radio
                                    name="recurrenceFrequency"
                                    label="Ugeligt"
                                    value="weekly"
                                    id="repeat1"
                                    v-model="recurrenceFrequency"/>
                            </sui-form-field>
                            <sui-form-field>
                                <sui-checkbox radio
                                    name="recurrenceFrequency"
                                    label="Månedligt"
                                    value="monthly"
                                    id="repeat2"
                                    v-model="recurrenceFrequency"/>
                            </sui-form-field>
                            <sui-form-field>
                                <sui-checkbox radio
                                    name="recurrenceFrequency"
                                    label="Årligt"
                                    value="yearly"
                                    id="repeat3"
                                    v-model="recurrenceFrequency"/>
                            </sui-form-field>
                        </sui-form-fields>
                
                        <!-- Daily -->
                        <sui-form-field v-if="recurrenceFrequency=='daily'">
                            <sui-form-field>
                                <label>For hver</label>
                                <input type="number" v-model="repeatFreqDays"/>
                                <label>&nbsp; dag(e)</label>
                            </sui-form-field>
                        </sui-form-field>

                        <!-- Weekly -->
                        <sui-form-field v-if="recurrenceFrequency=='weekly'">
                            <sui-form-fields grouped>
                                <sui-form-field>
                                    <label>Gentages for hver</label>
                                    <input type="number" v-model="repeatFreqWeeks"/>
                                    <label>&nbsp; uge(r)</label>
                                </sui-form-field>
                                <sui-form-fields inline></sui-form-fields> <!-- dummy line -->
                                <sui-form-fields inline>
                                    <sui-form-field>
                                        <sui-checkbox
                                            label="Mandag"
                                            id="repeatMon"
                                            v-model="repeatWeekdays[0]"/>
                                    </sui-form-field>
                                    <sui-form-field>
                                        <sui-checkbox
                                            label="Tirsdag"
                                            id="repeatTue"
                                            v-model="repeatWeekdays[1]"/>
                                    </sui-form-field>
                                    <sui-form-field>
                                        <sui-checkbox
                                            label="Onsdag"
                                            id="repeatWed"
                                            v-model="repeatWeekdays[2]"/>
                                    </sui-form-field>
                                </sui-form-fields>
                                <sui-form-fields inline>
                                    <sui-form-field> 
                                        <sui-checkbox
                                            label="Torsdag"
                                            id="repeatThu"
                                            v-model="repeatWeekdays[3]"/>
                                    </sui-form-field>
                                    <sui-form-field>
                                        <sui-checkbox
                                            label="Fredag"
                                            id="repeatFri"
                                            v-model="repeatWeekdays[4]"/>
                                    </sui-form-field>
                                </sui-form-fields>
                            </sui-form-fields> 
                        </sui-form-field>

                        <!-- Monthly -->
                        <sui-form-field v-if="recurrenceFrequency=='monthly'">
                            <sui-form-fields grouped>
                                <sui-form-fields inline>
                                    <sui-form-field>
                                        <sui-checkbox radio 
                                            name="monthlyOptions"
                                            label="Dag"
                                            value="monthlyOpt0"
                                            id="monthlyOpt0"
                                            v-model="monthlyRecurrenceState"/>
                                    </sui-form-field>
                                    <sui-form-field
                                        :disabled="monthlyRecurrenceState=='monthlyOpt1'">
                                        <input type="number" v-model="repeatFreqDays">
                                    </sui-form-field>
                                    <sui-form-field
                                        :disabled="monthlyRecurrenceState=='monthlyOpt1'">
                                        <label>for&nbsp;hver</label>
                                    </sui-form-field>
                                    <sui-form-field
                                        :disabled="monthlyRecurrenceState=='monthlyOpt1'">
                                        <input type="number" v-model="repeatFreqMonths">
                                    </sui-form-field>
                                    <sui-form-field 
                                        :disabled="monthlyRecurrenceState=='monthlyOpt1'">
                                        <label>måned(er)</label>
                                    </sui-form-field>
                                </sui-form-fields>

                                <sui-form-fields inline>
                                    <sui-form-field>
                                        <sui-checkbox radio
                                            name="monthlyOptions"
                                            label="Den"
                                            value="monthlyOpt1"
                                            id="monthlyOpt1"
                                            v-model="monthlyRecurrenceState"/>
                                    </sui-form-field>
                                    <sui-form-field>
                                        <sui-dropdown
                                            placeholder="Første" 
                                            selection 
                                            fluid 
                                            :disabled="monthlyRecurrenceState=='monthlyOpt0'" 
                                            :options="ordinalOptions" 
                                            v-model="selectedOrdinal"/>
                                    </sui-form-field>
                                    <sui-form-field>
                                        <sui-dropdown
                                            placeholder="Mandag" 
                                            selection 
                                            fluid 
                                            :disabled="monthlyRecurrenceState=='monthlyOpt0'"
                                            :options="weekdayOptions" 
                                            v-model="selectedWeekday"/>
                                    </sui-form-field>  
                                    <sui-form-field 
                                        :disabled="monthlyRecurrenceState=='monthlyOpt0'">
                                        <label>for&nbsp;hver</label>
                                    </sui-form-field>
                                    <sui-form-field
                                        :disabled="monthlyRecurrenceState=='monthlyOpt0'">
                                        <input type="number" v-model="repeatFreqMonths">
                                    </sui-form-field>
                                    <sui-form-field
                                        :disabled="monthlyRecurrenceState=='monthlyOpt0'">
                                        <label>måned(er)</label>
                                    </sui-form-field>
                                </sui-form-fields>
                            </sui-form-fields>
                        </sui-form-field>

                        <!-- Yearly -->
                        <sui-form-field v-if="recurrenceFrequency=='yearly'">
                            <sui-form-fields grouped>
                                <sui-form-fields inline>
                                    <sui-form-field>
                                        <label>Gentages for hvert</label>
                                    </sui-form-field>
                                    <sui-form-field>
                                        <input type="number" v-model="repeatFreqYears"/>
                                    </sui-form-field>
                                    <sui-form-field>
                                        <label>år</label>
                                    </sui-form-field>
                                </sui-form-fields>
                                
                                <sui-form-fields inline>
                                    <sui-form-field width="four">
                                        <sui-checkbox radio
                                            name="yearlyOptions"
                                            label="Den"
                                            value="yearlyOpt0"
                                            id="yearlyOpt0"
                                            v-model="yearlyRecurrenceState"/>
                                    </sui-form-field>
                                    <sui-form-field :disabled="yearlyRecurrenceState=='yearlyOpt1'">
                                        <input type="number" v-model="repeatFreqDays">
                                    </sui-form-field>
                                    <sui-form-field :disabled="yearlyRecurrenceState=='yearlyOpt1'">
                                        <label>.</label>
                                    </sui-form-field>
                                    <sui-form-field inline>
                                        <sui-dropdown 
                                            placeholder="Januar" 
                                            selection 
                                            fluid 
                                            :disabled="yearlyRecurrenceState=='yearlyOpt1'" 
                                            :options="monthsOptions" 
                                            v-model="selectedMonth"/>
                                    </sui-form-field>
                                </sui-form-fields>

                                <sui-form-fields inline>
                                    <sui-form-field width="four">
                                        <sui-checkbox radio
                                            name="yearlyOptions"
                                            label="På den"
                                            value="yearlyOpt1"
                                            id="yearlyOpt1"
                                            v-model="yearlyRecurrenceState"/>
                                    </sui-form-field>
                                    <sui-form-field>
                                        <sui-dropdown 
                                            placeholder="Første" 
                                            selection 
                                            fluid 
                                            :disabled="yearlyRecurrenceState=='yearlyOpt0'" 
                                            :options="ordinalOptions" 
                                            v-model="selectedOrdinal"/>
                                    </sui-form-field>
                                    <sui-form-field>
                                        <sui-dropdown 
                                            placeholder="Mandag" 
                                            selection 
                                            fluid 
                                            :disabled="yearlyRecurrenceState=='yearlyOpt0'" 
                                            :options="weekdayOptions" 
                                            v-model="selectedWeekday"/>
                                    </sui-form-field>  
                                    <sui-form-field :disabled="yearlyRecurrenceState=='yearlyOpt0'">
                                        <label>i</label>
                                    </sui-form-field>
                                    <sui-form-field>
                                        <sui-dropdown 
                                            placeholder="Januar" 
                                            selection 
                                            fluid 
                                            :disabled="yearlyRecurrenceState=='yearlyOpt0'" 
                                            :options="monthsOptions" 
                                            v-model="selectedMonth"/>
                                    </sui-form-field>
                                </sui-form-fields>
                            </sui-form-fields>
                        </sui-form-field>
                    </sui-form-fields>

                    <!-- Range of recurrence -->
                    <sui-header dividing>Gentagelsesperiode</sui-header>
                    <sui-form-fields inline>
                        <sui-form-field width="five">
                            <label>Start</label>
                            <input type="date" v-model="dateFromInput"/>
                        </sui-form-field>
                        <sui-form-field width="three">
                            <sui-checkbox radio
                                name="repeatPeriodType"
                                label="Afslut den"
                                value="endAtDate"
                                id="endAt"
                                v-model="recurrencePeriodState"
                            />
                        </sui-form-field>
                        <sui-form-field width="four"
                            :disabled="recurrencePeriodState=='endAfterOccurences'">
                            <input type="date" v-model="dateToInput"/>
                        </sui-form-field>
                    </sui-form-fields>
                    <sui-form-fields inline>
                        <sui-form-field width="five"></sui-form-field>
                            <sui-form-field width="three">
                                <sui-checkbox radio
                                    name="repeatPeriodType"
                                    label="Afslut efter"
                                    value="endAfterOccurences"
                                    id="endAfter"
                                    v-model="recurrencePeriodState"
                                />
                            </sui-form-field>
                            <sui-form-field width="four"
                                :disabled="recurrencePeriodState=='endAtDate'">
                                <input type="number" v-model="numberOfOccurences"/>
                            </sui-form-field>
                            <sui-form-field
                                :disabled="recurrencePeriodState=='endAtDate'">
                                <label>forekomster</label>
                            </sui-form-field>
                    </sui-form-fields>
                        
                </sui-form>

            </sui-modal-content>
            <sui-modal-actions>
                <sui-button negative @click="deleteSeries()" :disabled="!this.isPartOfSeries">Slet serie</sui-button>
                <sui-button negative @click="openEditSeries=false">Luk</sui-button>
                <sui-button positive @click="saveChangesSeries()" :disabled="false">Gem</sui-button>
            </sui-modal-actions>
        </sui-modal>
    </div>
</template>
<script>

import EventBus from '../../EventBus'
import { db, FieldValue } from '../../firebase'
import { Mixin } from '../../lib/Mixins/mixin'
import { DateMixin } from '../../lib/Mixins/dateMixin'
import swal from 'sweetalert'

export default {

    mixins: [EventBus, Mixin, DateMixin, db],

    props: {
        isOpen: Boolean,
        customAppointment: Object,
        customAppointmentId: String,
        allProjects: Array,
    },

    data() {
        return {
            formLoading: false,

            openCustomAppointment: {},
            appointmentsInSeriesID: [],

            titleInput: null,
            dateInput: null,
            timeWindowFromInput: null,
            timeWindowToInput: null,
            workerInitialsInput: null,
            noteInput: null,
            appliesToAllProjectsInput: false,
            appliesToTheseProjectsInput: null,

            // Series of custom appointments
            dateFromInput: null,
            dateToInput: null,
            isPartOfSeries: null, // null otherwise equal to customAppointmentSeriesId
            openEditSeries: false,

            repeatWeekdays: [false, false, false, false, false],
            
            repeatFreqDays: 1,
            repeatFreqWeeks: 1,
            repeatFreqMonths: 1,
            repeatFreqYears: 1,
            numberOfOccurences: 1,
            selectedOrdinal: null,
            selectedWeekday: null,
            selectedMonth: null,

            // State variables
            recurrenceFrequency: "weekly",
            monthlyRecurrenceState: "monthlyOpt1",
            yearlyRecurrenceState: "yearlyOpt0",
            recurrencePeriodState: "endAtDate",

            // Options
            ordinalOptions: [
                {text: 'Første', value: 'first'},
                {text: 'Anden', value: 'second'},
                {text: 'Tredje', value: 'third'},
                {text: 'Fjerde', value: 'fourth'},
                {text: 'Sidste', value: 'last'}
            ],

            weekdayOptions: [
                {text: 'Mandag', value: 1},
                {text: 'Tirsdag', value: 2},
                {text: 'Onsdag', value: 3},
                {text: 'Torsdag', value: 4},
                {text: 'Fredag', value: 5}
            ],

            monthsOptions: [
                {text: 'Januar', value: 0},
                {text: 'Februar', value: 1},
                {text: 'Marts', value: 2},
                {text: 'April', value: 3},
                {text: 'Maj', value: 4},
                {text: 'Juni', value: 5},
                {text: 'Juli', value: 6},
                {text: 'August', value: 7},
                {text: 'September', value: 8},
                {text: 'Oktober', value: 9},
                {text: 'November', value: 10},
                {text: 'December', value: 11}
            ],

            disableClearForm: false,
        }
    },

    computed: {
        allProjectsDropdown(){
            let arr = []
            for (let i in this.allProjects){
                arr.push({
                    text: this.allProjects[i].Name,
                    value: this.allProjects[i].id,
                })
            }
            return arr
        },

        saveButtonDisabled(){
            return (!this.titleInput || !this.dateInput)
        },

        seriesSaveButtonDisabled(){
            return false
        },
    },

    methods: {
        clickAway(){
            EventBus.$emit('edit-custom-appointment-modal-closing')
        },

        clickAwaySeries(){
            this.openEditSeries = false
        },

        /**
         * Returns a date object if the date is valid otherwise a null-ptr
         * 
         * @param {Date} inputDate
         * @returns {Date | null}  
         */
        isValidDate(inputDate){
            let date0 = new Date(inputDate)
            let dateString = date0.toISOString().split('T')[0]
            if (inputDate == dateString){return date0}
            return null
        },

        /**
         * Method to find the first, second... last weekday of a given month
         * 
         * The method sets its working date to the first day of that month
         * Then the first selected weekday of that month is found and added to an array
         * Then the method loops though the remaining days of the months
         * and adds the remaining found weekdays to the array
         * 
         * Based on the selected ordinal the corresponding date will be returned
         * If no valid date: Returns a null-ptr
         * @param {Date} inputDate 
         * @param {string} ordinal - Options: 'first', 'second', 'third', 'fourth', 'last' 
         * @param {string} weekday - Options: 'mon', 'tue', 'wed', 'thu', 'fri'
         * @returns {Date | null}
         */
        getFirstToLast(inputDate, ordinal, weekDay){
            let currentDate = new Date(inputDate)
            let workingMonth = currentDate.getUTCMonth()
            let workingYear = currentDate.getFullYear()

            // Set current date to first day of that month
            currentDate = new Date(currentDate.setUTCDate(1))

            // Goto first selected weekday
            let diff = 0
            if (currentDate.getDay() < weekDay){
                diff = weekDay - currentDate.getDay()   
            } else if (currentDate.getDay() > weekDay) {
                diff = 7 - currentDate.getDay() + weekDay
            }
            currentDate = new Date(currentDate.setHours(12)) // To fix summer/winter time bug
            currentDate = new Date(currentDate.setUTCDate(currentDate.getUTCDate() + diff))

            // Add date to tmp array and increment one week until next month
            let foundWeekDays = []
            while (currentDate.getUTCMonth() <= workingMonth && currentDate.getFullYear() <= workingYear){
                foundWeekDays.push(new Date(currentDate))
                currentDate = new Date(currentDate.setUTCDate(currentDate.getUTCDate() + 7)) // Increment one week
            }

            // Return the corresponding date if valid
            let returnValue = null
            if (foundWeekDays.length){
                switch(ordinal){
                    case 'first': 
                        returnValue = foundWeekDays[0]; break;
                    case 'second': 
                        if (foundWeekDays.length > 1) returnValue = foundWeekDays[1]; break;
                    case 'third':
                        if (foundWeekDays.length > 2) returnValue = foundWeekDays[2]; break;
                    case 'fourth':
                        if (foundWeekDays.length > 3) returnValue = foundWeekDays[3]; break;
                    case 'last':
                        returnValue = foundWeekDays[foundWeekDays.length - 1]; break;
                    default: returnValue = null; break;
                }
            }       
            return returnValue
        },

        /**
         * General method for generating a series of dates on a daily basis
         * 
         * The method has two modes 
         * (end at a date / end after a specific number of occurences)
         * The method starts at the input start date,
         * adds the date to the finished array and increments the date
         * Done until one of the two terminating conditions are met
         * 
         * @returns {Array} - Array of found dates in the series
         */
        generateDaily(){
            let finalDates = []
            let currentDate = new Date(this.dateFromInput)
            let repeatFrequency = Number(this.repeatFreqDays) // Weird shit happens if this is a string

            if (this.recurrencePeriodState == "endAtDate"){
                while(currentDate <= new Date(this.dateToInput)){
                    finalDates.push(new Date(currentDate))
                    currentDate = new Date(currentDate.setHours(12)) // To fix summer/winter time bug
                    currentDate = new Date(currentDate.setDate(currentDate.getDate() + repeatFrequency))
                }
            } 
            else{
                for(let i = 0; i < Number(this.numberOfOccurences); i++){
                    finalDates.push(new Date(currentDate))
                    currentDate = new Date(currentDate.setHours(12)) // To fix summer/winter time bug
                    currentDate = new Date(currentDate.setDate(currentDate.getDate() + repeatFrequency))
                }
            }

            return finalDates
        },

        /**
         * General method for generating a series of dates on a weekly basis
         * 
         * The method has two modes 
         * (end at a date / end after a specific number of occurences)
         * The method starts at the input date,
         * loops through a week and adds the selected weekdays to the finished array of dates
         * Adds one week and start over until one of the terminating conditions are met
         * 
         * @returns {Array} - Array of found dates in the series
         */
        generateWeekly(){
            let finalDates = []
            let currentDate = new Date(this.dateFromInput)

            if (this.recurrencePeriodState == "endAtDate"){
                while(currentDate <= new Date(this.dateToInput)) { // While end date have not been reached
                    let currentWeekDay = (currentDate.getDay() - 1)
                    for (let i = currentWeekDay; i < 5; i++) // Loop though weekdays
                    {
                        if (this.repeatWeekdays[i]) {finalDates.push(new Date(currentDate))} // If weekday selected: Add to list of dates
                        currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1))
                        if (currentDate > new Date(this.dateToInput)) break
                    }
                    currentDate = new Date(currentDate.setHours(12)) // To fix summer/winter time bug
                    currentDate = new Date(currentDate.setDate(currentDate.getDate() + (this.repeatFreqWeeks*7-5))) // Goto next week in series
                }  
            }
            else{ // TODO: This does not work as it should (generates 6 when I ask for 4)
                while (finalDates.length < Number(this.numberOfOccurences)) { // Loop though number of occurences
                    let currentWeekDay = (currentDate.getDay() - 1)
                    if (currentWeekDay == -1) currentWeekDay = 7
                    for (let j = currentWeekDay; j < 5; j++) // Loop though weekdays
                    {
                        if (this.repeatWeekdays[j]) {finalDates.push(new Date(currentDate))} // If weekday selected: Add to list of dates
                        currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1))
                        if (finalDates.length==Number(this.numberOfOccurences)) break
                    }
                    currentDate = new Date(currentDate.setHours(12)) // To fix summer/winter time bug
                    currentDate = new Date(currentDate.setDate(currentDate.getDate() + (this.repeatFreqWeeks*7-5))) // Goto next week in series
                }
            }

            return finalDates
        },

        /**
         * General method for generating a series of dates on a monthly basis
         * 
         * The method has two modes overall 
         * (end at a date / end after a specific number of occurences)
         * and two sub modes
         * 
         * Submode 1:
         * The day of the month is set to the specified day (input)
         * The new date is then added to the finished array,
         * if the date are valid and within the boundaries of the series
         * A month is then added and the method continues,
         * until one of the terminating conditions are met
         * 
         * Submode 2:
         * A method finds the corresponding date 
         * based on the selected ordinal and weekday (ex. first monday)
         * The date is added to finished array if the date are valid 
         * and within the boundaries of the series
         * A month is then added and the method continues,
         * until one of the terminating conditions are met
         * 
         * @returns {Array} - Array of found dates in the series
         */
        generateMonthly(){
            let finalDates = []
            let currentDate = new Date(this.dateFromInput) 

            if(this.recurrencePeriodState == "endAtDate"){
                if (this.monthlyRecurrenceState == "monthlyOpt1"){
                    while(currentDate <= new Date(this.dateToInput)){  
                        let foundDate = this.getFirstToLast(currentDate, this.selectedOrdinal, this.selectedWeekday)
                        if (foundDate != null){
                            if (new Date(this.dateFromInput) <= foundDate && foundDate <= new Date(this.dateToInput)){
                                finalDates.push(new Date(foundDate))
                                currentDate = new Date(foundDate)
                            }
                        }
                        currentDate = new Date(currentDate.setHours(12)) // To fix summer/winter time bug
                        currentDate = new Date(currentDate.setMonth(currentDate.getMonth() + Number(this.repeatFreqMonths)))
                        currentDate = new Date(currentDate.setDate(1))
                    }
                } else {
                    while(currentDate <= new Date(this.dateToInput)){
                        let workingDate = new Date(currentDate)
                        let tmpDateStr = workingDate.toISOString().split('T')[0]
                        tmpDateStr = tmpDateStr.substring(0,7)
                        tmpDateStr += '-' + this.repeatFreqDays.toString().padStart(2,'0')
                        workingDate.setUTCDate(Number(this.repeatFreqDays))
                        if (tmpDateStr == workingDate.toISOString().split('T')[0] && workingDate >= new Date(this.dateFromInput)){
                            finalDates.push(new Date(workingDate))
                        }
                        currentDate = new Date(currentDate.setHours(12)) // To fix summer/winter time bug
                        currentDate = new Date(currentDate.setUTCMonth(currentDate.getUTCMonth() + Number(this.repeatFreqMonths)))
                    
                    }
                }
            } else{
                if (this.monthlyRecurrenceState == "monthlyOpt1"){
                    while (finalDates.length < Number(this.numberOfOccurences)){
                        let foundDate = this.getFirstToLast(currentDate, this.selectedOrdinal, this.selectedWeekday)
                        if (new Date(this.dateFromInput) <= foundDate){
                            finalDates.push(new Date(foundDate))
                            currentDate = new Date(foundDate)
                        }
                        currentDate = new Date(currentDate.setHours(12)) // To fix summer/winter time bug
                        currentDate = new Date(currentDate.setMonth(currentDate.getMonth() + Number(this.repeatFreqMonths)))
                    }
                } else {
                    while (finalDates.length < Number(this.numberOfOccurences)){
                        let workingDate = new Date(currentDate)
                        let tmpDateStr = workingDate.toISOString().split('T')[0]
                        tmpDateStr = tmpDateStr.substring(0,7)
                        tmpDateStr += '-' + this.repeatFreqDays.toString().padStart(2,'0')
                        workingDate.setUTCDate(Number(this.repeatFreqDays))
                        if (tmpDateStr == workingDate.toISOString().split('T')[0] && workingDate >= new Date(this.dateFromInput)){
                            finalDates.push(new Date(workingDate))
                            currentDate = new Date(workingDate)
                        }
                        currentDate = new Date(currentDate.setHours(12)) // To fix summer/winter time bug
                        currentDate = new Date(currentDate.setUTCMonth(currentDate.getUTCMonth() + Number(this.repeatFreqMonths)))
                    }
                }
            }

            return finalDates
        },

        /**
         * General method for generating a series of dates on a yearly basis
         * 
         * The method has two modes overall 
         * (end at a date / end after a specific number of occurences)
         * and two sub modes
         * 
         * Submode 1:
         * The day of the month and month is set to the specified day (inputs)
         * The new date is then added to the finished array,
         * if the date are valid and within the boundaries of the series
         * A year is then added and the method continues,
         * until one of the terminating conditions are met
         * 
         * Submode 2:
         * A method finds the corresponding date 
         * based on the selected ordinal, weekday and month (ex. first monday in January)
         * The date is added to finished array if the date are valid 
         * and within the boundaries of the series
         * A year is then added and the method continues,
         * until one of the terminating conditions are met
         * 
         * @returns {Array} - Array of found dates in the series
         */
        generateYearly(){
            let finalDates = []
            let currentDate = new Date(this.dateFromInput) 

            if(this.recurrencePeriodState == "endAtDate"){
                if (this.yearlyRecurrenceState == "yearlyOpt0"){
                    while(currentDate <= new Date(this.dateToInput)){       
                        let workingDate = new Date(currentDate)
                        let workingYear = workingDate.getFullYear()
                        workingDate = new Date(workingDate.setFullYear(
                                workingYear,
                                Number(this.selectedMonth),
                                Number(this.repeatFreqDays)))
                        let tmpDateStr = workingYear.toString().padStart(4,'0') 
                        tmpDateStr += '-' + (this.selectedMonth + 1).toString().padStart(2,'0')
                        tmpDateStr += '-' + this.repeatFreqDays.toString().padStart(2,'0')
                        if (tmpDateStr == workingDate.toISOString().split('T')[0] && workingDate >= new Date(this.dateFromInput)){
                            finalDates.push(new Date(workingDate))
                            currentDate = new Date(workingDate)
                        }
                        currentDate = new Date(currentDate.setHours(12)) // To fix summer/winter time bug
                        currentDate = new Date(currentDate.setFullYear(
                            currentDate.getFullYear() + Number(this.repeatFreqYears)))
                    }
                } else {
                    while (currentDate <= new Date(this.dateToInput)){
                        currentDate = new Date(currentDate.setMonth(Number(this.selectedMonth)))
                        let foundDate = this.getFirstToLast(currentDate, this.selectedOrdinal, this.selectedWeekday)
                        if (new Date(this.dateFromInput) <= foundDate){
                            finalDates.push(new Date(foundDate))
                            currentDate = new Date(foundDate)
                        }
                        currentDate = new Date(currentDate.setHours(12)) // To fix summer/winter time bug
                        currentDate = new Date(currentDate.setFullYear(
                            currentDate.getFullYear() + Number(this.repeatFreqYears)))
                        currentDate = new Date(currentDate.setDate(1))
                    }
                }
            } else{
                if (this.yearlyRecurrenceState == "yearlyOpt0"){
                    while (finalDates.length < Number(this.numberOfOccurences)){
                        let workingDate = new Date(currentDate)
                        let workingYear = workingDate.getFullYear()
                        workingDate = new Date(workingDate.setFullYear(
                            workingYear,
                            Number(this.selectedMonth),
                            Number(this.repeatFreqDays)))
                        let tmpDateStr = workingYear.toString().padStart(4,'0')
                        tmpDateStr += '-' + (this.selectedMonth + 1).toString().padStart(2,'0')
                        tmpDateStr += '-' + this.repeatFreqDays.toString().padStart(2,'0')
                        if (tmpDateStr == workingDate.toISOString().split('T')[0] && workingDate >= new Date(this.dateFromInput)){
                            finalDates.push(new Date(workingDate))
                            currentDate = new Date(workingDate)
                        }
                        currentDate = new Date(currentDate.setHours(12)) // To fix summer/winter time bug
                        currentDate = new Date(currentDate.setFullYear(
                            currentDate.getFullYear() + Number(this.repeatFreqYears)))
                    }
                } else {
                    while (finalDates.length < Number(this.numberOfOccurences)){
                        currentDate = new Date(currentDate.setMonth(Number(this.selectedMonth)))
                        let foundDate = this.getFirstToLast(currentDate, this.selectedOrdinal, this.selectedWeekday)
                        if (new Date(this.dateFromInput) <= foundDate){
                            finalDates.push(new Date(foundDate))
                            currentDate = new Date(foundDate)
                        }
                        currentDate = new Date(currentDate.setHours(12)) // To fix summer/winter time bug
                        currentDate = new Date(currentDate.setFullYear(
                            currentDate.getFullYear() + Number(this.repeatFreqYears)))
                    }
                }
            }

            return finalDates
        },

        /**
         * Opens modal for editing series 
         * and parses dates from appointment 
         */
        onMakeSeriesClick(){
            this.openEditSeries = true
            if (this.dateFromInput == null) this.dateFromInput = this.dateInput
            if (this.dateToInput == null) this.dateToInput = this.dateInput
        },

        /**
         * General method for clearing the variables
         */
        clearForm(){
            this.titleInput = null
            this.dateInput = null
            this.timeWindowFromInput = null
            this.timeWindowToInput = null
            this.workerInitialsInput = null
            this.noteInput = null
            this.appliesToAllProjectsInput = false
            this.appliesToTheseProjectsInput = [ this.$route.params.projectIdentifier ]
        
            this.isPartOfSeries = null

            this.dateFromInput = null
            this.dateToInput = null
            this.repeatWeekdays = [false, false, false, false, false]
            this.repeatFreqDays = 1
            this.repeatFreqWeeks = 1
            this.repeatFreqMonths = 1
            this.repeatFreqYears = 1
            this.numberOfOccurences = 1
            this.selectedOrdinal = null
            this.selectedWeekday = null
            this.selectedMonth = null
            this.recurrenceFrequency = "weekly"
            this.monthlyRecurrenceState = "monthlyOpt1"
            this.yearlyRecurrenceState = "yearlyOpt0"
            this.recurrencePeriodState = "endAtDate"
        },

        /**
         * Method to prepopulate the form
         * 
         * If an appointment already excists the form variables are set
         * and if the appointment is a part of a series, the series is retrieved
         * 
         * If no excisting appointment, the form is cleared
         * 
         * @returns {boolean} - Returns true if an appointment already excists
         */
        prePopulateForm(){
            if (!this.customAppointment || !this.customAppointmentId) {
                if (!this.disableClearForm) this.clearForm()
                return false
            }
            let two = this.readTimeWindowString(this.customAppointment.TimeWindowString)
            this.titleInput = this.customAppointment.Title
            this.dateInput = two.Date.replaceAll('/','-')
            this.timeWindowFromInput = String(two.Time.From)
            this.timeWindowToInput = String(two.Time.To)
            this.workerInitialsInput = this.customAppointment.WorkerInitials
            this.noteInput = null
            this.appliesToAllProjectsInput = !this.customAppointment.LinkedProjects.length
            this.appliesToTheseProjectsInput = this.customAppointment.LinkedProjects
            if (this.customAppointment?.LinkedSeries != null){
                this.isPartOfSeries = this.customAppointment.LinkedSeries
                this.retriveSeries()
            }
            return true
        },

        /**
         * This method retrieves a series document from database
         * 
         * Retrieves the document and assigns interal variables with saved values
         */
        async retriveSeries(){
            EventBus.$emit('function-activity', {functionName: 'EditCustomAppointmentModal_retrieveSeries', isActive: true})
            let docSnap = await db.collection('CustomAppointmentsSeries').doc(this.isPartOfSeries).get()

            this.dateFromInput = docSnap.data().StartDate
            this.dateToInput = docSnap.data().EndDate
            this.repeatWeekdays = docSnap.data().SelectedWeekDays
            this.repeatFreqDays = Number(docSnap.data().FreqDays)
            this.repeatFreqWeeks = Number(docSnap.data().FreqWeeks)
            this.repeatFreqMonths = Number(docSnap.data().FreqMonths)
            this.repeatFreqYears = Number(docSnap.data().FreqYears)
            this.numberOfOccurences = Number(docSnap.data().NumbOfOccurences)
            this.selectedOrdinal = docSnap.data().SelectedOrdinal
            this.selectedWeekday = docSnap.data().SelectedWeekDay
            this.selectedMonth = docSnap.data().SelectedMonth
            this.recurrenceFrequency = docSnap.data().RecurrenceFreq
            this.monthlyRecurrenceState = docSnap.data().MonthlyRecurrence
            this.yearlyRecurrenceState = docSnap.data().YearlyRecurrence
            this.recurrencePeriodState = docSnap.data().RecurrencePeriodType

            this.appointmentsInSeriesID = this.getAllSeriesAppointmentIDs()
            EventBus.$emit('function-activity', {functionName: 'EditCustomAppointmentModal_retrieveSeries', isActive: false})
        },

        /**
         * A method for retrieving document IDs for all appointments in a series
         * 
         * @returns {Array} - Array of document IDs (String)
         */
        getAllSeriesAppointmentIDs(){
            // Return a list of all appointments in series
            let appointmentIDs = []
            let query = db.collection('CustomAppointments').where("LinkedSeries", "==", this.isPartOfSeries)
            query.get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    appointmentIDs.push(doc.id)
                })
            })
            return appointmentIDs
        },

        /**
         * Method for saving a custom appointment
         * If the appointment is a part of a series, 
         * a swal will popup to handle whether or not 
         * the changes should be applied to the other appointments in the series
         */
        async saveChanges(){
            EventBus.$emit('function-activity', {functionName: 'EditCustomAppointmentModal_saveChanges', isActive: true})
            if (this.customAppointmentId && this.isPartOfSeries){
                let updateAll = await swal({
                    title: 'Denne aftale er en del af en serie',
                    text: 'Ønsker du kun at opdatere denne aftale, eller alle aftaler i serien?',
                    icon: 'warning',
                    buttons: ['Opdatér Kun Denne', 'Opdatér Alle'],
                    dangerMode: false
                })
                if (updateAll) {
                    await this.saveChangesSeries()
                } else {
                    await this.saveAppointment()
                    this.clickAway() //Close form
                    this.clearForm()
                }
            } else {
                await this.saveAppointment()
                this.clickAway() //Close form
                this.clearForm()  
            }
            EventBus.$emit('function-activity', {functionName: 'EditCustomAppointmentModal_saveChanges', isActive: false})
        },

        /**
         * General method for saving an appointment in firestore
         * 
         * A user obj is created to document who made the appointment
         * A combined timewindow string is created based on date and timewindow
         * A note obj is created if any
         * A final firestore document is created and saved to firestore
         * If the appointment already excists, an update document is created instead
         * and the firestore document is updated
         * 
         * @param {Date | null} date - Date object to specify a date in a series
         */
        async saveAppointment(date = null){
            //Start loading animation
            EventBus.$emit('function-activity', {functionName: 'EditCustomAppointmentModal_saveAppointment', isActive: true})
            this.formLoading = true

            try {
                //Create update document
                //User Obj
                let user = {
                    displayName: this.$parent.user.displayName,
                    email: this.$parent.user.email,
                }
                //Time Window String
                let tws = this.makeTimeWindowString(
                    this.dateInput, 
                    this.timeWindowFromInput, 
                    this.timeWindowToInput)

                if (date != null){
                    tws = this.makeTimeWindowString(
                        date, 
                        this.timeWindowFromInput, 
                        this.timeWindowToInput)
                }

                //Note object
                let notes = this.customAppointment?.Notes || []
                let note = null
                if (this.noteInput) {
                    note = {
                        authorEmail: user.email,
                        configurationItemLabel: 'CUSTOMAPPOINTMENT',
                        noteBody: `@${user.displayName}:\n${this.noteInput}`,
                        projectTaskId: 'CUSTOMAPPOINTMENT',
                        timeStamp: this.formatMachineTimestamp(new Date()),
                    }
                    notes.push(note)
                }
                //Final firebase document
                let document = {
                    Title: this.titleInput,
                    WorkerInitials: this.workerInitialsInput,
                    Notes: notes,
                    AppointmentType: 'Custom',
                    Confirmed: true,
                    LinkedProjects: this.appliesToTheseProjectsInput || [],
                    State: 'active',
                    TimeWindowString: tws,
                    Updated: new Date(),
                    UpdatedBy: user,
                    Created: new Date(),
                    CreatedBy: user,
                    InstallationLabel: "CUSTOMAPPOINTMENT",
                    LinkedSeries: this.isPartOfSeries
                }
    
                //Update firebase with document
                if (this.customAppointmentId && date == null) { //If there is already an ID, the document should be updated and not created
                    let updateDocument = {
                        Title: document.Title,
                        WorkerInitials: document.WorkerInitials,
                        LinkedProjects: document.LinkedProjects,
                        TimeWindowString: tws,
                        Updated: new Date(),
                        UpdatedBy: user,
                    }
                    if (note) {
                        updateDocument.Notes = FieldValue.arrayUnion(note) //Add the note to any existing notes from Firebase, without risk of overwriting any
                    }
                    console.log(updateDocument)
                    await db.collection('CustomAppointments').doc(this.customAppointmentId).update(updateDocument)
                } else { //else, if there is no ID, the document can be assumed not to exist, and should be created
                    console.log(document)
                    await db.collection('CustomAppointments').add(document) //Add document to firestore with auto ID
                }
    
                this.formLoading = false //End loading animation
                if (this.isPartOfSeries == null){
                    this.clickAway() //Close form
                    this.clearForm()  
                }
                EventBus.$emit('function-activity', {functionName: 'EditCustomAppointmentModal_saveAppointment', isActive: false})
            } catch (error) {
                console.error(error)
                await swal('Fejl ved upload af aftale', `Fejlmeddelelse: ${error.message}`, 'error')
                this.formLoading = false //End loading animation
                this.clickAway() //Close form
                this.clearForm()
                EventBus.$emit('function-activity', {functionName: 'EditCustomAppointmentModal_saveAppointment', isActive: false})
            }
            EventBus.$emit('customAppointmentSaved')
        },

        /**
         * General method for saving a series to firestore
         * 
         * @param {Array} generatedDates - Array of Date objs 
         */
        async saveSeries(generatedDates){
            // Start loading animation
            EventBus.$emit('function-activity', {functionName: 'EditCustomAppointmentModal_saveSeries', isActive: true})
            this.formLoading = true

            try {
                // User Obj
                let user = {
                        displayName: this.$parent.user.displayName,
                        email: this.$parent.user.email,
                    }

                let modifiedDates = []
                for (let i in generatedDates){
                    modifiedDates.push(generatedDates[i].toISOString().split('T')[0])
                }

                // Final firestore document
                let document = {
                        State: 'active',
                        Updated: new Date(),
                        UpdatedBy: user,
                        Created: new Date(),
                        CreatedBy: user,
                        StartDate: this.dateFromInput,
                        EndDate: this.dateToInput,
                        RecurrenceFreq: this.recurrenceFrequency,
                        MonthlyRecurrence: this.monthlyRecurrenceState,
                        YearlyRecurrence: this.yearlyRecurrenceState,
                        RecurrencePeriodType: this.recurrencePeriodState,
                        SelectedWeekDays: this.repeatWeekdays,
                        SelectedWeekDay: this.selectedWeekday,
                        SelectedMonth: this.selectedMonth,
                        SelectedOrdinal: this.selectedOrdinal,
                        NumbOfOccurences: this.numberOfOccurences,
                        FreqDays: this.repeatFreqDays,
                        FreqWeeks: this.repeatFreqWeeks,
                        FreqMonths: this.repeatFreqMonths,
                        FreqYears: this.repeatFreqYears,
                        DatesInSeries: modifiedDates,
                    }
                let docRef = db.collection('CustomAppointmentsSeries').doc() // Create document and get auto generated ID
                await docRef.set(document) // Save document to firestore
                this.isPartOfSeries = docRef.id

                this.formLoading = false // End loading animation
                EventBus.$emit('function-activity', {functionName: 'EditCustomAppointmentModal_saveSeries', isActive: false})

            } catch(error) {
                console.error(error)
                await swal('Fejl ved upload af serie', `Fejlmeddelelse: ${error.message}`, 'error')
                this.formLoading = false // End loading animation
                this.clickAwaySeries()
                this.clickAway() // Close form
                this.clearForm()
                EventBus.$emit('function-activity', {functionName: 'EditCustomAppointmentModal_saveSeries', isActive: false})
            }
        },

        /**
         * General method for updating a series
         * 
         * @param {Array} generatedDates - Array of Date objs 
         */
        async updateSeries(generatedDates){
            // Start loading animation
            EventBus.$emit('function-activity', {functionName: 'EditCustomAppointmentModal_updateSeries', isActive: true})
            this.formLoading = true

            try {
                // User Obj
                let user = {
                        displayName: this.$parent.user.displayName,
                        email: this.$parent.user.email,
                    }

                let modifiedDates = []
                for (let i in generatedDates){
                    modifiedDates.push(generatedDates[i].toISOString().split('T')[0])
                }

                // Updated firestore document
                let updateDocument = {
                        Updated: new Date(),
                        UpdatedBy: user,
                        StartDate: this.dateFromInput,
                        EndDate: this.dateToInput,
                        RecurrenceFreq: this.recurrenceFrequency,
                        MonthlyRecurrence: this.monthlyRecurrenceState,
                        YearlyRecurrence: this.yearlyRecurrenceState,
                        RecurrencePeriodType: this.recurrencePeriodState,
                        SelectedWeekDays: this.repeatWeekdays,
                        SelectedWeekDay: this.selectedWeekday,
                        SelectedMonth: this.selectedMonth,
                        SelectedOrdinal: this.selectedOrdinal,
                        NumbOfOccurences: this.numberOfOccurences,
                        FreqDays: this.repeatFreqDays,
                        FreqWeeks: this.repeatFreqWeeks,
                        FreqMonths: this.repeatFreqMonths,
                        FreqYears: this.repeatFreqYears,
                        DatesInSeries: modifiedDates,
                    }
                
                await db.collection('CustomAppointmentsSeries').doc(
                    this.isPartOfSeries).update(updateDocument) // Update document in firestore

                this.formLoading = false // End loading animation
                EventBus.$emit('function-activity', {functionName: 'EditCustomAppointmentModal_updateSeries', isActive: false})

            } catch(error) {
                console.error(error)
                await swal('Fejl ved opdatering af serie', `Fejlmeddelelse: ${error.message}`, 'error')
                this.formLoading = false // End loading animation
                this.clickAway() // Close form
                this.clearForm()
                EventBus.$emit('function-activity', {functionName: 'EditCustomAppointmentModal_updateSeries', isActive: false})
            }
        },

        /**
         * Method for creating or updating a series
         * 
         * Dates are generated based on the selected parameters 
         * A series document is created
         * Appointments are created based on found dates
         * 
         * If the series excists already, the series and appointments are updated
         */
        async saveChangesSeries()
        {
            EventBus.$emit('function-activity', {functionName: 'EditCustomAppointmentModal_saveChangesSeries', isActive: true})
            // Generate dates in series
            let generatedDates = []
            switch(this.recurrenceFrequency){
                case "daily":   generatedDates = this.generateDaily(); break;
                case "weekly":  generatedDates = this.generateWeekly(); break;
                case "monthly": generatedDates = this.generateMonthly(); break;
                case "yearly":  generatedDates = this.generateYearly(); break;
                default:        generatedDates = this.generateWeekly(); break;
            }

            // let week = ['son','mon','tue','wed','thu','fri','sat']
            // console.log("Generated dates:")
            // for(let i in generatedDates){
            //     console.log("  ",generatedDates[i].toISOString().split('T')[0], week[generatedDates[i].getDay()])
            // }
            // generatedDates = []

            if (generatedDates.length){
                if (this.isPartOfSeries){ // This appointment is already part of a series (update)
                    await this.updateSeries(generatedDates)

                    this.disableClearForm = true // Disables clearform
            
                    this.$parent.onReceiveItemClicked(null) // Clear active installation
                    for (let i in this.appointmentsInSeriesID){ // Delete all existing appointments
                        await db.collection('CustomAppointments').doc(this.appointmentsInSeriesID[i]).delete()
                    }
                    this.appointmentsInSeriesID = []

                    for (let i in generatedDates){ // Create all appointments in series
                        let dateString = generatedDates[i].toISOString().split('T')[0]
                        await this.saveAppointment(dateString)
                    }
                    
                    this.disableClearForm = false

                } else { // This appointment is not part of a series (create new)
                    await this.saveSeries(generatedDates)
                    
                    this.disableClearForm = true // Disables clearform
                    if(this.customAppointmentId){
                        await db.collection('CustomAppointments').doc(this.customAppointmentId).delete()
                        this.$parent.onReceiveItemClicked(null) // Clear active installation
                    }

                    for (let i in generatedDates){ // Create all appointments in series
                        let dateString = generatedDates[i].toISOString().split('T')[0]
                        await this.saveAppointment(dateString)
                    }
                    this.disableClearForm = false
                }
                this.clickAwaySeries()
                this.clickAway()
                this.clearForm()                  
            } else {
                console.log("Warning: No valid dates in series")

                swal({
                    title: "Ingen gyldige datoer i serie",
                    icon: "warning",
                })
            }   
            EventBus.$emit('function-activity', {functionName: 'EditCustomAppointmentModal_saveChangesSeries', isActive: false})     
        },

        /**
         * Method for deleting an appointment 
         * 
         * If the appointment is a part of a series a swal will popup
         * to handle whether the entire series or just the selected appointment should be deleted
         * 
         * If not a swal will popup to confirm or deny deletion of the appointment
         */
        async deleteAppointment(){
            EventBus.$emit('function-activity', {functionName: 'EditCustomAppointmentModal_deleteAppointment', isActive: true})
            
            let deleteState = 'CANCEL'
            if (this.customAppointmentId && this.isPartOfSeries){ 
                deleteState = await swal({
                    title: 'Denne aftale er en del af en serie',
                    text: 'Ønsker du kun at slette denne aftale, eller alle aftaler i serien?',
                    icon: 'warning',
                    dangerMode: true,
                    buttons: {
                        cancel: {
                            text: 'Annullér',
                            value: 'CANCEL',
                            visible: true,
                        },
                        delete: {
                            text: 'Slet Kun Denne',
                            value: 'DELETE',
                            visible: true,
                            className: 'sweet-warning',
                        },
                        deleteAll: {
                            text: 'Slet Alle',
                            value: 'DELETE ALL',
                            visible: true,
                            className: 'sweet-warning',
                        },
                    },
                })
            } else {
                deleteState = await swal({
                    title: 'Er du sikker på at du vil slette denne aftale',
                    text: 'Brugerdefinerede aftaler som denne (der ikke er bundet sammen med en opgave i PilotBI) kan ikke gendannes efter sletning',
                    icon: 'warning',
                    buttons: {
                        cancel: {
                            text: 'Annullér',
                            value: 'CANCEL',
                            visible: true,
                        },
                        delete: {
                            text: 'Slet',
                            value: 'DELETE',
                            visible: true,
                        },
                    },
                    dangerMode: true 
                })
            }
            
            if (deleteState == 'DELETE'){
                await db.collection('CustomAppointments').doc(this.customAppointmentId).delete()
                this.$parent.onReceiveItemClicked(null) // Clear active installation
                await swal('Aftale slettet', 'Den brugerdefinerede aftale er nu slettet permanent', 'success')
                this.clickAway()
                this.clearForm()
                EventBus.$emit('function-activity', {functionName: 'EditCustomAppointmentModal_deleteAppointment', isActive: false})
            } else if (deleteState == 'DELETE ALL'){
                for (let i in this.appointmentsInSeriesID){ // Delete all appointments in series 
                    await db.collection('CustomAppointments').doc(this.appointmentsInSeriesID[i]).delete()
                }
                this.$parent.onReceiveItemClicked(null) // Clear active installation

                await db.collection('CustomAppointmentsSeries').doc(this.isPartOfSeries).delete() // Delete series
                await swal('Serie slettet', 'Den brugerdefinerede serie af aftaler er nu slettet permanent', 'success')
                
                this.clearForm()
                this.clickAway()
                this.clickAwaySeries()
                EventBus.$emit('function-activity', {functionName: 'EditCustomAppointmentModal_deleteAppointment', isActive: false})
            } else {
                await swal('Aftalen blev ikke slettet','','success')
                this.clickAway()
                this.clearForm()
                EventBus.$emit('function-activity', {functionName: 'EditCustomAppointmentModal_deleteAppointment', isActive: false})
            }    
        },

        /**
         * Method for deleting a series
         * 
         * A swal will handle confirmation 
         * If confirmed, the series doc will be deleted from firestore 
         * along with all appointments in the series
         */
        async deleteSeries() {
            EventBus.$emit('function-activity', {functionName: 'EditCustomAppointmentModal_deleteSeries', isActive: true})

            let willDelete = await swal({
                title: 'Er du sikker på at du vil slette denne serie',
                text: 'Brugerdefinerede aftaler som denne (der ikke er bundet sammen med en opgave i PilotBI) kan ikke gendannes efter sletning',
                icon: 'warning',
                buttons: ['Annullér', 'Slet'],
                dangerMode: true
            })
            
            if (willDelete){
                for (let i in this.appointmentsInSeriesID){ // Delete all appointments in series 
                    await db.collection('CustomAppointments').doc(this.appointmentsInSeriesID[i]).delete()
                }

                await db.collection('CustomAppointmentsSeries').doc(this.isPartOfSeries).delete() // Delete series
                await swal('Serie slettet', 'Den brugerdefinerede serie af aftaler er nu slettet permanent', 'success')
                
                this.clearForm()
                this.clickAway()
                this.clickAwaySeries()
                EventBus.$emit('function-activity', {functionName: 'EditCustomAppointmentModal_deleteSeries', isActive: false})
            } else {
                await swal('Serien blev ikke slettet','','success')
                
                this.clearForm()
                this.clickAway()
                this.clickAwaySeries()
                EventBus.$emit('function-activity', {functionName: 'EditCustomAppointmentModal_deleteSeries', isActive: false})
            }
        },
    },

    beforeMount() {
        this.prePopulateForm()
    },

    watch: {
        appliesToAllProjectsInput: {
            handler(b){
                if (b) {
                    this.appliesToTheseProjectsInput = []
                }
            }
        },
        customAppointment: {
            handler(){
                this.prePopulateForm()
            }
        },
        customAppointmentId: {
            handler(){
                this.prePopulateForm()
            }
        },
        isOpen: {
            handler(open){
                if (open) {
                    this.prePopulateForm()
                }
            }
        },
    }
}
</script>
<style scoped>

</style>