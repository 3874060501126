<template>
    <div>
        <!-- <portal to="page-title">{{ this.project.Name }}</portal> -->

        <edit-worker-modal
            v-if="firebaseUser"
            :isModalOpen="editingWorker"
            :worker="firebaseUser"
        />

        <DataCard 
            title="Intern brugerdata" 
            no-padding 
            class="full-height"
            :actions="[{ type: 'button', title: 'Redigér', icon: 'edit', eventName: 'edit-button' }]"
        >
            <div class="scrollable">
                <sui-table v-if="firebaseUser" class="workers-table">
                    <sui-table-body>
                        <sui-table-row>
                            <sui-table-cell colspan="2">
                                <TableWorker
                                    :worker="firebaseUser"
                                />
                            </sui-table-cell>
                        </sui-table-row>
                        <sui-table-row>
                            <sui-table-cell>Initialer</sui-table-cell>
                            <sui-table-cell>{{firebaseUser.Initials}}</sui-table-cell>
                        </sui-table-row>
                        <sui-table-row>
                            <sui-table-cell>Telefon<br>Mobil</sui-table-cell>
                            <sui-table-cell>{{firebaseUser.Phone}}<br>{{firebaseUser.Mobile}}</sui-table-cell>
                        </sui-table-row>
                        <sui-table-row>
                            <sui-table-cell colspan="2"><strong>Roller</strong></sui-table-cell>
                        </sui-table-row>
                        <sui-table-row v-for="role in firebaseUser.Roles" :key="role">
                            <sui-table-cell colspan="2" :title="UserRoles.getTitleFromRole(role)"> - {{UserRoles.getDescriptionFromRole(role)}}</sui-table-cell>
                        </sui-table-row>
                        <sui-table-row>
                            <sui-table-cell><strong>Evt. speciel ressource-gruppe for time-tjek</strong></sui-table-cell>
                            <sui-table-cell>{{ firebaseUser.RessouceGroup }}</sui-table-cell>
                        </sui-table-row>
                    </sui-table-body>
                </sui-table>
                <div v-else>
                    <TableLoader/>
                </div>
            </div>
        </DataCard>

    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TableLoader from '@/components/TableLoader.vue'
import TableWorker from '@/components/Worker/TableWorker.vue'
import EditWorkerModal from '@/components/Worker/EditWorkerModal.vue'
import { Mixin } from '@/lib/Mixins/mixin.js'
import { DateMixin } from '../../lib/Mixins/dateMixin'
import { DataAPI } from '@/lib/DataAPI.js'
import { db } from '@/firebase.js'
import TaskState from '@/lib/Enums/TaskState.js'
import UserRoles from '@/lib/Enums/UserRoles.js'
import EventBus from '@/EventBus.js'

export default {
    name: 'WorkerDetailView',
    mixins: [Mixin, DateMixin, DataAPI],

    components: {
        TableLoader,
        TableWorker,
        EditWorkerModal,
    },

    enums: {
        TaskState,
        UserRoles,
    },

    data() {
        return {
            firebaseUsers: [],
            // apiWorkers: [],
            workerTasks: [],
            activeWorkerTask: {},
            workerTaskModalOpen: false,

            editingWorker: false,
        }
    },

    computed: {
        ...mapGetters({
            rawProject: 'activeProject'
        }),

        firebaseUser() {
            if (this.firebaseUsers.length > 1) {
                alert("Der blev fundet mere end en bruger med initialerne "+this.$route.params.WorkerId)
            }
            if (!this.firebaseUsers.length) {
                return null
            }
            return this.firebaseUsers[0]
        },

        workers() {
            if (this.firebaseUsers.length == 0) return []
            const initialValue = {}
            return this.firebaseUsers.reduce((obj, item) => {
                return {
                    ...obj,
                    [String(item['Email']).toLowerCase()]: item,
                }
            }, initialValue)
        },
    },

    methods: {
        generateStyleColorsFromName(name) {
            const imageData = this.generateUserProfileImageFromName(name)
            return {
                backgroundColor: imageData.bgColor,
                color: imageData.fgColor
            }
        },

        formatContacts() {
            if (this.rawProject.Contacts && this.rawProject.Contacts.length) {
                for (const i in this.rawProject.Contacts) {
                    this.rawProject.Contacts[i].Company.get()
                        .then(res => {
                            this.rawProject.Contacts[i].CompanyData = res.data()
                        })
                }
            }
        },

        async getAssociatedProjectTask() {
            EventBus.$emit('function-activity', {functionName: 'WorkerDetail_getAssociatedProjectTask', isActive: true})
            if (!this.activeWorkerTask.projectTaskObj){
                let projectTask = await this.dataGetProjectTaskWithoutNotes(this.activeWorkerTask.taskId)
                this.$set(this.activeWorkerTask,"projectTaskObj",projectTask)
                let i = this.workerTasks.findIndex(task => task.id == this.activeWorkerTask.id)
                this.$set(this.workerTasks[i],"projectTaskObj",projectTask)
                EventBus.$emit('function-activity', {functionName: 'WorkerDetail_getAssociatedProjectTask', isActive: false})
                return projectTask
            } else{
                EventBus.$emit('function-activity', {functionName: 'WorkerDetail_getAssociatedProjectTask', isActive: false})
              return this.activeWorkerTask.projectTaskObj  
            } 
        },

        openWorkerTaskDetails(taskId) {
            this.activeWorkerTask = this.workerTasks.find(task => task.id == taskId)
            this.getAssociatedProjectTask()
            this.workerTaskModalOpen = true
        }
    },

    async created() {
        EventBus.$emit('function-activity', {functionName: 'WorkerDetail_created', isActive: true})
        this.$bind('firebaseUsers', db.collection("Users").where("Initials", "==", this.$route.params.WorkerId));
        // this.apiWorkers = await this.dataGetWorkers()

        EventBus.$on('edit-button-click', () => {
            this.editingWorker = true
        })

        EventBus.$on('edit-worker-modal-closing', () => {
            this.editingWorker = false
        })
        EventBus.$emit('function-activity', {functionName: 'WorkerDetail_created', isActive: false})
    },

    watch: {
        firebaseUser: {
            immediate: true,
            handler(user) {
                if (user?.Name) {
                    document.title = `${user.Name} - FiberTeam`
                }
            }
        }
    }
}
</script>
<style scoped>
.workers-table td {
    padding-left: 1.5rem !important;
}
.scrollable {
    overflow-y: auto;
    overflow-x: auto;
    max-height: 100%;
    position: relative;
}
.half-height {
    max-height: calc(50vh - 110px);
}
table.selectable > tbody > tr {
    cursor: pointer;
}
</style>
