
class FormToPdfComponents {

    // Component codes used for the dropdown
    static DOCUMENT_TITLE = 'documentTitle'
    static EFB_AUTO_DOCUMENT_TITLE = 'efbAutoDocumentTitle'
    static HEADER = 'header'
    static EFB_CUSTOMER_AND_CONSULTANT = 'efbCustomerAndConsultant'
    static DESCRIPTION = 'description'
    static YES_NO_LIST = 'yesNoList'
    static IMAGE_LIST = 'imageList'
    static PRINT_SIGNATURE = 'printSignature'
    static CONTACT_TABLE = 'contactTable'
    static DUAL_CONTACT_TABLE = 'dualContactTable'


    // Different parameters for the components
    static DOCUMENT_TITLE_PARAMS = [
        'documentTitle']

    static EFB_AUTO_DOCUMENT_TITLE_PARAMS = []

    static HEADER_PARAMS = [
        'headerText',
        'headerHierarchy',
        'fieldTitle',
        'fieldPlaceholder',
        'pageBreakBefore',
        'includeInPdf',
        'includeInForm',
        'readOnly',
        'required']

    static EFB_CUSTOMER_AND_CONSULTANT_PARAMS = [
        'pageBreakBefore',
        'includeInPdf',
        'includeInForm',
        'readOnly',
        'required',
        'rightColumnWidth']

    static DESCRIPTION_PARAMS = [
        'fieldTitle',
        'text',
        'fieldPlaceholder',
        'headerHierarchy',
        'pageBreakBefore',
        'includeInPdf',
        'includeInForm',
        'readOnly',
        'required',
        'isShortText',
        'showHeaderInPdf']

    static YES_NO_LIST_PARAMS = [
        'list',
        'pageBreakBefore',
        'includeInPdf',
        'includeInForm',
        'readOnly',
        'required']

    static IMAGE_LIST_PARAMS = [
        'pageBreakBefore',
        'includeInPdf',
        'includeInForm',
        'readOnly',
        'required',
        'fieldTitle',
        'allowMultiple']

    static PRINT_SIGNATURE_PARAMS = [
        'pageBreakBefore',
        'includeInPdf',
        'includeInForm',
        'readOnly',
        'required',
        'disclaimerText']

    static getComponentParamsFromCode(code){
        switch(code) {
            case this.DOCUMENT_TITLE: return this.DOCUMENT_TITLE_PARAMS
            case this.EFB_AUTO_DOCUMENT_TITLE: return this.EFB_AUTO_DOCUMENT_TITLE_PARAMS
            case this.HEADER: return this.HEADER_PARAMS
            case this.EFB_CUSTOMER_AND_CONSULTANT: return this.EFB_CUSTOMER_AND_CONSULTANT_PARAMS
            case this.DESCRIPTION: return this.DESCRIPTION_PARAMS
            case this.YES_NO_LIST: return this.YES_NO_LIST_PARAMS
            case this.IMAGE_LIST: return this.IMAGE_LIST_PARAMS
            case this.PRINT_SIGNATURE: return this.PRINT_SIGNATURE_PARAMS
            default: "Ukendt Form til PDF Komponent"
        }
    }

    static getAllUniqueParams(){
        const flattendArray = [
            this.DOCUMENT_TITLE_PARAMS, 
            this.EFB_AUTO_DOCUMENT_TITLE_PARAMS,
            this.HEADER_PARAMS, 
            this.EFB_CUSTOMER_AND_CONSULTANT_PARAMS, 
            this.DESCRIPTION_PARAMS, 
            this.YES_NO_LIST_PARAMS, 
            this.IMAGE_LIST_PARAMS, 
            this.PRINT_SIGNATURE_PARAMS
        ].flat()
        const uniqueValuesArray = [...new Set(flattendArray)]

        return uniqueValuesArray
    }

    // Display names used for the dropdown
    static getDisplayNameFromCode(code){
        switch(code) {
            case this.DOCUMENT_TITLE: return "Dokument Titel"
            case this.EFB_AUTO_DOCUMENT_TITLE: return "EFB Auto Dokument Titel (Ikke implementeret)"
            case this.HEADER: return "Overskrift"
            case this.EFB_CUSTOMER_AND_CONSULTANT: return "EFB Kunde og Konsulent"
            case this.DESCRIPTION: return "Beskrivelse"
            case this.YES_NO_LIST: return "Ja/Nej Liste"
            case this.IMAGE_LIST: return "Billeder"
            case this.PRINT_SIGNATURE: return "Printet Signatur"
            case this.CONTACT_TABLE: return "Kontakt Tabel"
            case this.DUAL_CONTACT_TABLE: return "Dobbelt Kontakt Tabel"
            default: "Ukendt Form til PDF Komponent"
        }
    }

    // Prepopulate methods used for auto retrival of data
    static getPrepoulateMethodFromCode(code){
        switch(code) {
            case this.DOCUMENT_TITLE: return null
            case this.HEADER: return null
            case this.EFB_CUSTOMER_AND_CONSULTANT: return "prepopulateEfbCustomerAndConsultantComponent"
            case this.DESCRIPTION: return null
            case this.YES_NO_LIST: return null
            case this.IMAGE_LIST: return null
            case this.PRINT_SIGNATURE: return null
            case this.CONTACT_TABLE: return null
            case this.DUAL_CONTACT_TABLE: return null
            default: "Ukendt Form til PDF Komponent"
        }
    }

    // Generate methods used for generating the PDF
    static getGeneratePdfMethodFromCode(code){
        switch(code) {
            case this.DOCUMENT_TITLE: return 'generatePdfMakeDocumentTitle'
            case this.HEADER: return 'generatePdfMakeHeader'
            case this.EFB_CUSTOMER_AND_CONSULTANT: return 'generatePdfMakeDualContactTable'
            case this.DESCRIPTION: return 'generatePdfMakeDescription'
            case this.YES_NO_LIST: return 'generatePdfMakeYesNoList'
            case this.IMAGE_LIST: return 'generatePdfMakeImages'
            case this.PRINT_SIGNATURE: return 'generatePdfMakePrintSignature'
            case this.CONTACT_TABLE: return 'generatePdfMakeContactTable'
            case this.DUAL_CONTACT_TABLE: return 'generatePdfMakeDualContactTable'
            default: "Ukendt Form til PDF Komponent"
        }
    }

    // Validation methods
    static getValidationMethodFromCode(code){
        switch(code) {
            case this.DOCUMENT_TITLE: return null
            case this.HEADER: return null
            case this.EFB_CUSTOMER_AND_CONSULTANT: return null
            case this.DESCRIPTION: return null
            case this.YES_NO_LIST: return null
            case this.IMAGE_LIST: return null
            case this.PRINT_SIGNATURE: return null
            case this.CONTACT_TABLE: return null
            default: "Ukendt Form til PDF Komponent"
        }
    }

    // Generate methods used for generating the template document
    static getCreateTemplateMethodFromCode(code){
        switch(code) {
            case this.DOCUMENT_TITLE: return "createDocumentTitleComponent"
            case this.HEADER: return "createHeaderComponent"
            case this.EFB_CUSTOMER_AND_CONSULTANT: return "createEfbCustomerAndConsultantComponent"
            case this.DESCRIPTION: return "createDescriptionComponent"
            case this.YES_NO_LIST: return "createYesNoListComponent"
            case this.IMAGE_LIST: return "createImageListComponent"
            case this.PRINT_SIGNATURE: return "createPrintSignatureComponent"
            case this.CONTACT_TABLE: return "createContactTableComponent"
            case this.DUAL_CONTACT_TABLE: return "createDualContactTableComponent"
            default: "Ukendt Form til PDF Komponent"
        }
    }

    // Methods for adding the form fields to the template or form data
    static getAddFormFieldMethodFromCode(code){
        switch(code) {
            case this.DOCUMENT_TITLE: return "addFieldsToDocumentTitleComponent"
            case this.HEADER: return "addFieldsToHeaderComponent"
            case this.EFB_CUSTOMER_AND_CONSULTANT: return "addFieldsToEfbCustomerAndConsultantComponent"
            case this.DESCRIPTION: return "addFieldsToDescriptionComponent"
            case this.YES_NO_LIST: return "addFieldsToYesNoListComponent"
            case this.IMAGE_LIST: return "addFieldsToImageListComponent"
            case this.PRINT_SIGNATURE: return "addFieldsToPrintSignatureComponent"
            case this.CONTACT_TABLE: return "addFieldsToContactTableComponent"
            case this.DUAL_CONTACT_TABLE: return "addFieldsToDualContactTableComponent"
            default: "Ukendt Form til PDF Komponent"
        }
    }

    // Generate methods used for generating the firestore document from the form data
    static getGenerateFirestoreMethodFromCode(code){
        switch(code) {
            case this.DOCUMENT_TITLE: return "convertDocumentTitleToData"
            case this.HEADER: return "convertHeaderToData"
            case this.EFB_CUSTOMER_AND_CONSULTANT: return "convertEdfCustomerAndConsultantToData"
            case this.DESCRIPTION: return "convertDescriptionToData"
            case this.YES_NO_LIST: return "convertYesNoListToData"
            case this.IMAGE_LIST: return "convertImageListToData"
            case this.PRINT_SIGNATURE: return "convertPrintSignatureToData"
            case this.CONTACT_TABLE: return "convertContactTableToData"
            case this.DUAL_CONTACT_TABLE: return "convertDualContactTableToData"
            default: "Ukendt Form til PDF Komponent"
        }
    }

    // Dropdown options
    static DROPDOWN_OPTIONS = [
        {value: this.DOCUMENT_TITLE, text: this.getDisplayNameFromCode(this.DOCUMENT_TITLE)},
        {value: this.EFB_AUTO_DOCUMENT_TITLE, text: this.getDisplayNameFromCode(this.EFB_AUTO_DOCUMENT_TITLE)},
        {value: this.HEADER, text: this.getDisplayNameFromCode(this.HEADER)},
        {value: this.EFB_CUSTOMER_AND_CONSULTANT, text: this.getDisplayNameFromCode(this.EFB_CUSTOMER_AND_CONSULTANT)},
        {value: this.DESCRIPTION, text: this.getDisplayNameFromCode(this.DESCRIPTION)},
        {value: this.YES_NO_LIST, text: this.getDisplayNameFromCode(this.YES_NO_LIST)},
        {value: this.IMAGE_LIST, text: this.getDisplayNameFromCode(this.IMAGE_LIST)},
        {value: this.PRINT_SIGNATURE, text: this.getDisplayNameFromCode(this.PRINT_SIGNATURE)},
        {value: this.CONTACT_TABLE, text: this.getDisplayNameFromCode(this.CONTACT_TABLE)},
        {value: this.DUAL_CONTACT_TABLE, text: this.getDisplayNameFromCode(this.DUAL_CONTACT_TABLE)}
    ]

}

export default FormToPdfComponents

