<template>
    <sui-modal mini :open="isOpen" v-on:clickAwayModal="closeModal()">
        <sui-modal-header>
            <div class="row" v-if="!!activeItem">
                <div class="col-sm-11">
                    <span>{{activeItem.typeSource}} {{activeItem.identification}}</span>
                    <sui-item-meta><small>Timestamp: {{toUserFriendlyTimestamp(activeItem.timestamp)}}</small></sui-item-meta>
                </div>
                <div class="col-sm-1" style="text-align: right;">
                    <sui-button icon="times" @click="closeModal()"></sui-button>
                </div>
            </div>
        </sui-modal-header>
        <sui-modal-content v-if="!!activeItem" scrolling class="centered">
            <div class="row">
                <div class="col-sm-4">
                    <h3>Splidseplan</h3>
                    <span>
                        <sui-button @click="downloadReport('SPLICE_REPORT','XLSX')" :loading="loading.SPLICE_REPORT.XLSX">Download Excel</sui-button>
                        <!-- <sui-button @click="downloadReport('SPLICE_REPORT','PDF')" :loading="loading.SPLICE_REPORT.PDF">Download pdf</sui-button> -->
                    </span>
                </div>
                <div class="col-sm-4">
                    <h3>POP-Splidseplan</h3>
                    <span>
                        <sui-button @click="downloadReport('POP_SPLICE_REPORT','XLSX')" :loading="loading.POP_SPLICE_REPORT.XLSX">Download Excel</sui-button>
                        <!-- <sui-button @click="downloadReport('POP_SPLICE_REPORT','PDF')" :loading="loading.POP_SPLICE_REPORT.PDF">Download pdf</sui-button> -->
                    </span>
                </div>
                <div class="col-sm-4">
                    <h3>POP-Patchplan</h3>
                    <span>
                        <sui-button @click="downloadReport('POP_PATCH_REPORT','XLSX')" :loading="loading.POP_PATCH_REPORT.XLSX">Download Excel</sui-button>
                        <!-- <sui-button @click="downloadReport('POP_PATCH_REPORT','PDF')" :loading="loading.POP_PATCH_REPORT.PDF">Download pdf</sui-button> -->
                    </span>
                </div>
            </div>
        </sui-modal-content>
    </sui-modal>
</template>
<script>
import { Mixin } from '../../lib/Mixins/mixin'
import { Mime } from '../../lib/helpers/mime'
import EventBus from '../../EventBus'
import { analytics } from '../../firebase'
import { DataAPI } from '../../lib/DataAPI'

export default {
    name: 'SpliceReportsDownloadModal',
    mixins: [Mixin, Mime, EventBus, analytics, DataAPI],

    props: {
        activeItem: {
            type: Object,
            requred: true,
        },
        isOpen: {
            type: Boolean,
            requred: true,
            default: false,
        },
    },

    data() {
        return {
            loading: {
                SPLICE_REPORT: {
                    XLSX: false,
                    PDF: false
                },
                POP_SPLICE_REPORT: {
                    XLSX: false,
                    PDF: false
                },
                POP_PATCH_REPORT: {
                    XLSX: false,
                    PDF: false
                },
            },
        }
    },

    methods: {
        closeModal() {
            EventBus.$emit('report-download-modal-close')
        },

        downloadBase64File(contentType, base64Data, fileName) {

            analytics.logEvent('report_download', {contentType})

            const linkSource = `data:${contentType};base64,${base64Data}`;
            const downloadLink = document.createElement("a");
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            // console.log(linkSource)
            downloadLink.click();
       },

        async downloadReport(planType, formatType) {
            EventBus.$emit('function-activity', {functionName: 'SpliceReportsDownloadModal_downloadReport', isActive: true})
            this.loading[planType][formatType] = true
            let report = await this.dataGetReport(this.activeItem.idSource, planType, formatType)
            this.downloadBase64File( this.getMimeTypeByExtension(report.data.format), report.data.base64Content, `${this.activeItem.identification} ${planType}`)
            this.loading[planType][formatType] = false
            EventBus.$emit('function-activity', {functionName: 'SpliceReportsDownloadModal_downloadReport', isActive: false})
        }
    },

}
</script>