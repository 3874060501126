<template>
    <div>
        <state-change-modal 
            :isOpen="stateModalOpen" 
            :serviceOrder="activeServiceOrder" 
            :task="activeTask" 
            :projectProp="project" 
            :installationLabel="(activeTask && activeTask.configurationItem) ? activeTask.configurationItem.label : null" 
            :technicalData="(activeTask && activeTask.configurationItem) ? activeTask.configurationItem.technicalData : null" 
            :availableWorkers="availableWorkers"
            :relevantAppointments="taskAppointments"
        />
        <DataCard no-padding title="Tidsplan" class="timeline-card">
            <ProjectTimeline
                :itemClickHandlerType="BookingItemHandlerType.OPEN_INSTALLATION"
                :bookings="bookedInstallations"
                :loading="timelineLoading"
                :showCloseCPETaskShortcut="true"
            />
        </DataCard>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { db, auth } from '../../firebase'
import { Mixin } from '../../lib/Mixins/mixin'
import { DateMixin } from '../../lib/Mixins/dateMixin'
import { DataAPI } from '../../lib/DataAPI'
import { Bookingmixin } from '../../lib/Bookingmixin'
import EventBus from '../../EventBus'
import TaskState from '../../lib/Enums/TaskState'
import TaskCode from '../../lib/Enums/TaskCode'
import AppointmentState from '../../lib/Enums/AppointmentState'
import { EmptySubTasks } from '../../lib/Enums/SubtaskType'
import BookingItemHandlerType from '../../lib/Enums/BookingItemHandlerType'
import Timeline from '../../components/Project/Timeline'
import ProjectType from '../../lib/Enums/ProjectType'
import StateChangeModal from '../../components/Project/StateChangeModal.vue'

export default {
    name: 'ProjectTimelinePage',
    mixins: [Mixin, DateMixin, DataAPI, Bookingmixin],

    components: {
        'ProjectTimeline': Timeline,
        StateChangeModal,
    },

    enums: {
        TaskCode,
        TaskState,
        BookingItemHandlerType,
        ProjectType
    },

    data() {
        return {
            appointments: [],
            projectCustomAppointments: [],
            globalCustomAppointments: [],
            
            coordinates: [],
            searchFilterValue: '',
            timelineLoading: true,

            // Page action settings
            showPendingTasks: window.localStorage.getItem('booking-page-show-pending-tasks') != 'false',
            showOnHoldTasks: window.localStorage.getItem('booking-page-show-onhold-tasks') != 'false',
            showSubtaskStatus: window.localStorage.getItem('booking-page-show-subtask-status') != 'false',

            sortingCurrent: 0,
            sortingOptions: [
                { text: 'Stigende', value: 0 },
                { text: 'Faldende', value: 1 }
            ],

            firebaseInternalSubtasks: [],

            stashedTechnicals: {
                hubs: {},
                uubs: {}
            },

            inVainVisits: [],

            firebaseUsers: [],

            stateModalOpen: false,
            activeServiceOrder: null,
            activeTask: null, //TODO: Populate with full task data
        }
    },

    computed: {
        ...mapGetters({
            project: 'activeProject'
        }),

        areaCodes() {
            return ['373']
        },

        user() {
            return auth.currentUser
        },

        firebaseUser() {
            return this.firebaseUsers.find(u => u.Email == this.user.email)
        },

        sortAscending() {
            return !this.sortingCurrent
        },

        bookedInstallations() {
            let appointments = [
                ...this.projectCustomAppointments,
                ...this.globalCustomAppointments,
                ...this.appointments,
                // ...this.closedAppointments,
            ]
            var mergedData = [];

            let appointmentsWithNoInstallation = 0
            for (let i in appointments) {
                const appointment = appointments[i]
                let installation = this.installations.find(ins => {
                    if (!ins.tasks[0]) return false
                    return ins.label == appointment.InstallationLabel
                })

                if (appointment.AppointmentType == 'Custom') {
                    installation = {
                        label: appointment.id,
                        tasks: [{
                            AppointmentDate: this.readTimeWindowString(appointment.TimeWindowString).Date,
                            state: {
                                value: TaskState.OPEN
                            }
                        }],
                    }
                } 
                
                if (!installation && appointment.InstallationLabel) {
                    installation = {
                        label: appointment.InstallationLabel,
                        tasks: [],
                    }
                }

                if (!installation) {
                    appointmentsWithNoInstallation += 1
                    continue
                }

                if (this.searchFilterValue && !this.matchSearchCriteria(installation)) {
                    continue
                }

                let timeWindowStringObj = this.readTimeWindowString(appointment.TimeWindowString)

                installation.appointment = appointment
                installation.appointment.Date = timeWindowStringObj.Date
                installation.appointment.Time = timeWindowStringObj.Time

                if (installation.tasks[0]) {
                    installation.tasks[0].AppointmentDate = timeWindowStringObj.Date
                    installation.subtasks = Object.assign({}, ...this.internalSubTasks[installation.label].map(entry => { 
                        return { [entry.code]: entry }
                    }))
                    installation.inVainVisits = this.inVainVisits.filter(ivv => ivv.ConfigurationItem.Label == installation.label)
                    // if (installation.AppointmentType != 'Custom') {
                    // }
                }

                // console.log(installation)
                mergedData.push(installation)
            }
            if(appointmentsWithNoInstallation){
                console.error(`${appointmentsWithNoInstallation}/${appointments.length} appointments were missing a referenced installation`)
            }

            let groupedByDates = this.uniqueBookingDates(mergedData, 'AppointmentDate', true) //Find unique dates from firebase appointments

            mergedData.forEach(value => { //For each appointment
                if(!groupedByDates[this.formatMachineDate(value.appointment.Date)]){
                    console.error("Date from appointment not found in dates", value.appointment, Object.keys(groupedByDates))
                } else {
                    groupedByDates[this.formatMachineDate(value.appointment.Date)].push(value) //Push all relevant appointments to the relevant date
                }
            })

            for(let i in groupedByDates) { //Loop through dates, and sort by worker - appointments are already sorted by date and time, so no other sorting than worker is required
                const date = groupedByDates[i]
                let sortedDate = date.sort((a, b) => {
                    if (!a || !b) return 0
                    let aWorkerInitials = a.appointment?.Worker?.Email?.split('@')[0].toLowerCase() || a.appointment?.WorkerInitials?.toLowerCase()
                    let bWorkerInitials = b.appointment?.Worker?.Email?.split('@')[0].toLowerCase()  || b.appointment?.WorkerInitials?.toLowerCase()
                    // console.log(aWorkerInitials, bWorkerInitials)
                    if(aWorkerInitials > bWorkerInitials) return this.sortAscending ? 1 : -1
                    if(aWorkerInitials < bWorkerInitials) return this.sortAscending ? -1 : 1
                    if(aWorkerInitials == bWorkerInitials) {
                        if(a.appointment.TimeWindowString > b.appointment.TimeWindowString) return this.sortAscending ? 1 : -1
                        if(a.appointment.TimeWindowString < b.appointment.TimeWindowString) return this.sortAscending ? -1 : 1
                    }
                    return 0
                })
                groupedByDates[i] = sortedDate
                // this.$set(groupedByDates, i, sortedDate)
            }

            return groupedByDates
        },

        internalSubTasks() {
            let outputData = {}
            const firebaseData = this.firebaseInternalSubtasks
            for (let i in firebaseData) {
                const instSubtasks = firebaseData[i]
                outputData[instSubtasks.InstallationLabel] = this.constructSubTasksArray(instSubtasks)
            }
            for (let i in this.installations){
                const inst = this.installations[i]
                if (!outputData[inst.label]) {
                    outputData[inst.label] = [...EmptySubTasks]
                }
            }
            return outputData
        },

        availableWorkers() {
            // if (this.project.Workers.length == 0) return []

            let workersArray = this.project.Workers || []
            workersArray.push({Email: "Ingen"})

            let workerOptions = []
            for (let i in workersArray) {
                let w = workersArray[i]
                let firWorker = this.firebaseUsers.find((worker) => w.Email.toLowerCase() == worker.Email.toLowerCase())
                if (firWorker) {
                    workerOptions.push({key: i, value: w.Email.toLowerCase(), text: `${firWorker.Name} (${firWorker.Initials})`})
                }
            }

            return workerOptions
        },

        taskAppointments() {
            if (!this.activeTask || !this.activeTask.configurationItem) return []
            return this.appointments.filter(app => app.InstallationLabel == this.activeTask.configurationItem.label)
        },
    },

    methods: {

        getStatusIcon(ins) {
            if (this.insContainsState(ins, TaskState.ON_HOLD)) {
                return { class: "far fa-stopwatch", title:"Mindst én opgave på kunden er 'On Hold'" }
            }
            if (this.insContainsOnlyState(ins, TaskState.CLOSED_COMPLETE)) {
                return { class: "fas fa-check", title: "Alle opgaver er afsluttede" }
            }
            if (this.insContainsState(ins, TaskState.CLOSED_INCOMPLETE) || this.insContainsState(ins, TaskState.CLOSED_SKIPPED)) {
                return { class: "fas fa-times", title: "Mindst én opgave på kunden er 'Closed Incomplete' eller 'Closed Skipped'" }
            }

            // if (!!ins.tasks[TaskCode.BOOKKUNDE] && !!ins.tasks[TaskCode.BOOKKUNDE].Date && this.formatMachineDate(ins.tasks[TaskCode.BOOKKUNDE].Date) != this.formatMachineDate(ins.tasks[TaskCode.BOOKKUNDE].connectionDate)) return {class: "fas fa-calendar-edit", title: "Aftale kladde ikke bekræftet"}

            return false
        },

        // matchSearchCriteria(ins) {
        //     let task = Object.values(ins.tasks)[0]
        //     if (!task) return true
        //     return !!(
        //         String(ins.label).toLowerCase().match(this.searchFilterValue) || 
        //         (task.configurationItem.address && String(task.configurationItem.address.city).toLowerCase().match(this.searchFilterValue)) ||
        //         (task.configurationItem.address && String(task.configurationItem.address.road).toLowerCase().match(this.searchFilterValue))
        //     )
        // },

        sortInstallations(a, b) {
            let addressA = this.formatAddress(Object.values(a.tasks)[0].configurationItem.address, false)
            let addressB = this.formatAddress(Object.values(b.tasks)[0].configurationItem.address, false)

            if (addressA > addressB) return this.sortAscending ? 1 : -1
            if (addressA < addressB) return this.sortAscending ? -1 : 1

            return 0
        },

        toggleShowPendingTasks() {
            this.showPendingTasks = !this.showPendingTasks
            localStorage.setItem('booking-page-show-pending-tasks', this.showPendingTasks)
        },

        toggleShowOnHoldTasks() {
            this.showOnHoldTasks = !this.showOnHoldTasks
            localStorage.setItem('booking-page-show-onhold-tasks', this.showOnHoldTasks)
        },

        toggleShowSubtaskStatus() {
            this.showSubtaskStatus = !this.showSubtaskStatus
            localStorage.setItem('booking-page-show-subtask-status', this.showSubtaskStatus)
        },

        uniqueBookingDates(installations, dateField, returnAsKeys) {
            let uniqueDates = [...new Map(installations.map(ins => {
                let date
                if (ins.appointment?.Date && dateField == 'AppointmentDate'){
                    date = this.formatMachineDate(ins.appointment.Date)
                } else if (!ins.tasks[0]) {
                    return ['N/A', 'N/A']
                } else {
                    date = ins.tasks[0][dateField]
                }
                if (!date) date = 'N/A'
                return [this.formatMachineDate(date), this.formatMachineDate(date)]
            })).values()]

            if (returnAsKeys == true) {
                return uniqueDates.reduce((v,i) => (v[i] = [], v), {})
            }
            return uniqueDates
        },

        getAppointments(sortAscending) {
            if (!this.project.Type) return
            
            let projectType = this.project.Type
            if (projectType == ProjectType.GLOBAL_LATE_SIGNUP) projectType = ProjectType.LATE_SIGNUP
            console.log(`Filtering Project Type: ${projectType}, Actual Project Type: ${this.project.Type}`)

            let sorting = sortAscending ? 'asc' : 'desc'

            // All
            this.$bind('appointments', db.collection(`Appointments`)
                .where('LinkedProjects', 'array-contains', this.project.id)
                .where('State', '==', AppointmentState.ACTIVE)
                // .orderBy('State', sorting)
                .orderBy('TimeWindowString', sorting)
            )

            this.$bind('coordinates', db.collection('Coordinates')
                .where('LinkedProjects', 'array-contains', this.project.id)
            )
        },

        getTaskDataWithRetry(attempt, maxAttempts) {
            // console.log(this.project.Name)
            if (this.project.Name) {
                try {
                    let projectTypes = [...this.project.Type]
                    if (projectTypes.indexOf(String(ProjectType.CABINET)) != -1){
                        projectTypes.splice(projectTypes.indexOf(String(ProjectType.CABINET)), 1)
                    }
                    // console.log('Getting base booking data')
                    this.bookGetBaseBookingData(this.project.AreaCodes, projectTypes, false, false, false, false, true, this.project.ReferenceIdWhitelist, this.project.ReferenceIdBlacklist)
                        .then(() => {console.log('Got base booking data')})
                }
                catch (error) {
                    console.error(error)
                }
            } else {
                if (attempt < maxAttempts) {
                    setTimeout(() => {this.getTaskDataWithRetry(attempt+1, maxAttempts)}, 100)
                } else {
                    throw new Error('No project found after max retries')
                }
            }
        },
    },

    beforeMount() {
        EventBus.$on('top-search-type', (payload) => {
            this.searchFilterValue = String(payload).toLowerCase()
            if (!payload) this.searchFilterValue = ''
        })

        EventBus.$on('shortcut-to-close-task', (task) => {
            this.activeTask = task
            this.stateModalOpen = true
        })

        EventBus.$on('stateChange-modal-closing', () => {this.stateModalOpen = false})
    },

    mounted() {
        this.$bind('firebaseInternalSubtasks', db
            .collection(`InternalSubTasks`)
            .where('LinkedProjects', 'array-contains', this.$route.params.projectIdentifier)
        )
        this.$bind('firebaseUsers', db
            .collection('Users')
        )
        this.$bind('inVainVisits', db
            .collection('UnitWork')
            .where('Unit.Id', '==', '931.F.99')
            .where('LinkedProjects', 'array-contains', this.$route.params.projectIdentifier)
        )
        this.getTaskDataWithRetry(0,100)

        document.title = `${this.project.Name} Tidsplan - FiberTeam`
        localStorage.setItem('last-visited-page', 'timeline')
    },

    watch: {
        project: {
            immediate: false,
            handler() {
                document.title = `${this.project.Name} Tidsplan - FiberTeam`
                this.getAppointments(this.sortAscending)
            }
        },
        sortAscending: {
            immediate: true,
            handler(sortAscending) {
                this.getAppointments(sortAscending)
            }
        },
        firebaseInternalSubtasks: {
            immediate: false,
            handler() {
                EventBus.$emit('reload-subtask-status-icons')
            }
        },
        bookedInstallations: {
            immediate: false,
            handler(/* data */) {
                this.timelineLoading = false //Object.keys(data).length == 0
            }
        },
        // appointments: {
        //     immediate: false,
        //     handler(appointments) {
        //         this.bookGetTasksForAppointments(appointments) //Done directly from Bookingmixin instead
        //     }
        // },
        activeTask: {
            immediate: false,
            async handler(task) {
                EventBus.$emit('function-activity', {functionName: 'TimelinePage_watch_activeTask_handler', isActive: true})
                this.activeServiceOrder = null

                if (!task.configurationItem || !task.configurationItem.technicalData) { //IF Task is not full task, but from list call
                    let fullTask = await this.dataGetProjectTaskWithoutNotes(task.id) //Get full task
                    fullTask.project.type = task.project.type //Preserve project type (not included in full task)
                    this.activeTask = fullTask
                    return false //Watcher will be triggered again, so stop here
                }

                let soId = task.serviceOrder.id
                await this.dataGetServiceOrder(soId).then((serviceOrder) => {
                    this.activeServiceOrder = serviceOrder
                })
                EventBus.$emit('function-activity', {functionName: 'TimelinePage_watch_activeTask_handler', isActive: false})
            },
        },
    },
}
</script>
<style>
table.timeline-table {
    margin-bottom: 0 !important;
}

table.timeline-table tr th {
    border-top: 2px solid #dee2e6 !important;
    border-bottom: none !important;
}

table.timeline-table tr td {
    border-top: none !important;
}

table.timeline-table:first-child tr th {
    border-top: none !important;
}

@media only screen and (max-width: 420px) {
    .timeline-card {
        width: fit-content;
    }   
}
</style>
