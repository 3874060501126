import Firebase from 'firebase/compat/app'
// import 'firebase/compat/auth'
import { getAuth, OAuthProvider, GoogleAuthProvider } from 'firebase/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/functions'
import 'firebase/compat/analytics'
import 'firebase/compat/storage'
import firebaseConfig from '../firebase.config'

Firebase.initializeApp(firebaseConfig)
// let auth = Firebase.auth()
let auth = getAuth()
let db = Firebase.firestore()
let FieldPath = Firebase.firestore.FieldPath
let FieldValue = Firebase.firestore.FieldValue
let Timestamp = Firebase.firestore.Timestamp
let firestore = Firebase.firestore
let cloudFunctions = Firebase.app().functions('europe-west1')
let analytics = Firebase.analytics()
let storage = Firebase.storage()
let storageRef = storage.ref()

export { auth, db, FieldPath, FieldValue, Timestamp, firestore, cloudFunctions, analytics, storage, storageRef }

// Auth Provider
var authProvider = new OAuthProvider('microsoft.com')
authProvider.setCustomParameters({
    prompt: 'consent', //Get user consent for added scopes (see scopes below)
    login_hint: 'user@fiberlan.dk',
    tenant: process.env.VUE_APP_MICROSOFT_TENANT_ID
})

authProvider.addScope('User.Read');
// authProvider.addScope('User.ReadBasic.All') //This would allow us to read all users basic profiles on any users behalf - may not be required

var gAuthProvider = new GoogleAuthProvider()

//DEPRECATED GOOGLE AUTH:
// var authProvider = new Firebase.auth.GoogleAuthProvider()
// authProvider.setCustomParameters({
//     'login_hint': 'user@fiberlan.dk'
// })

export { authProvider, gAuthProvider }


const usersCollection = db.collection('Users')
export { usersCollection }

