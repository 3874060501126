<template>
    <div>
        <strong>Description:</strong>
        <p>
            Goes through all Appointments, and links those with appointmentType 'ticket' to project ADmEWfuxjCuOCNkTyiRK
        </p>

        <hr />

        <sui-button primary :loading="isRunning" :disabled="isRunning" @click="triggerDry">Run Dry</sui-button>
        <sui-button negative :loading="isRunning" :disabled="isRunning" @click="triggerNormal">Run</sui-button>
    </div>
</template>
<script>
import { db } from '../../../firebase'

export default {
    name: 'TRTAppointments',

    data() {
        return {
            isRunning: false,

            dryRun: false,

            projectId: null,

            appointments: [],
        }
    },

    methods: {
        log(message) {
            this.$emit('log', message)
        },

        async triggerDry() {
            this.dryRun = true
            await this.trigger()
        },

        async triggerNormal() {
            this.dryRun = false
            await this.trigger()
        },

        async trigger() {
            this.isRunning = true
            this.log(`----------------`)
            this.log(`Starting process`)
            
            if (this.dryRun) {
                this.log(`Dry mode - No updates will actually occur.`)
            }

            this.log(`Getting appointments`)
            this.appointments = await db.collection('Appointments').where('AppointmentType', '==', 'ticket').get()
            this.log(`Got ${this.appointments.docs.length} appointments`)
            var failedAppointments = []

            for (var i in this.appointments.docs) {
                this.log(`----`)
                const appointment = this.appointments.docs[i]
                const appointmentData = appointment.data()
                this.log(`Appointment #${i}, ID ${appointment.id}, timeWindow: ${appointmentData.TimeWindowString}`)
                if (appointmentData.LinkedProjects.length) {
                    this.log(`Skipping appointment already linked the following projects: ${JSON.stringify(appointmentData.LinkedProjects)}`)
                    continue
                }
                let updateDoc = {
                    LinkedProjects: ['ADmEWfuxjCuOCNkTyiRK']
                }
                if (!this.dryRun){
                    try {
                        await db.collection('Appointments').doc(appointment.id).update(updateDoc)
                        this.log('updated appointment')
                    }
                    catch {
                        failedAppointments.push(appointment.id)
                        this.log('Unable to update appointment in firebase')
                    }
                } else {
                    this.log('Writing to firebase skipped in dry run. Would otherwise have updated firebase with the following data:')
                    this.log(updateDoc)
                }


                this.log(`Done with this appointment.`)
            }

            this.log(`Finished looping through appointments, with ${failedAppointments.length} errors, listed below`)
            this.log(failedAppointments)

            this.isRunning = false
        }
    }
}
</script>
<style scoped>
.float.right {
    float: right;
}
</style>