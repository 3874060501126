import axios from 'axios'
import swal from 'sweetalert'
import NetworkError from '../Errors/Network'
import EventBus from '../../EventBus'
import { analytics } from '../../firebase'

export default class PilotBi {

    BaseURL = "Not yet retrieved from firebase"
    SupplierID = "Not yet retrieved from firebase"
    APIKey = "Not yet retrieved from firebase"

    AxiosConfig = {
        headers: {
            'x-api-key': this.APIKey,
            'Content-Type': 'application/json'
        }
    }

    stringifyPayload(payload){
        let keys = Object.keys(payload)
        let string = '?'
        for (let i in keys) {
            let key = keys[i]
            if (payload[key]){
                string += `${key}=${payload[key]}`
            }
        }
        if (string.length <= 1) return ''
        return string
    }

    async call(type, endpoint, payload, errorShouldPopup=true) {
        const perfStart = performance.now()
        const generalizedEndpoint = String(endpoint)
            .replace(this.SupplierID, '{{supplierId}}')
            .replace(/\d{4}-\d{2}-\d{2}/g, '{{date}}') //Date in ISO-format, followed by end of string
            .replace(/\d{1,3}-\d{3,4}(?=\/[HU]UB)/g, '{{UUBid}}') //UUB-ID followed by /HUB or /UUB
            .replace(/([0-9]{4,}(?=\/))/g, '{{number}}') //4 or more digits in a row, followed by a /
            .replace(/([0-9]{4,}$)/g, '{{number}}') //4 or more digits in a row, followed by end of string
            .replace(/([0-9a-fA-F]{14,}(?=\/))/g, '{{hex}}') //14 or more hex digits in a row, followed by a /
            .replace(/([0-9a-fA-F]{14,}$)/g, '{{hex}}') //14 or more hex digits in a row, followed by end of string
        
        try {
            let response;
            let stringifiedPayload
            switch (type) {
                case 'post':
                    response = await axios.post(`${this.BaseURL}${endpoint}`, payload, this.AxiosConfig)
                    break
                case 'patch':
                    response = await axios.patch(`${this.BaseURL}${endpoint}`, payload, this.AxiosConfig)
                    break
                case 'put':
                    response = await axios.put(`${this.BaseURL}${endpoint}`, payload, this.AxiosConfig)
                    break
                case 'delete':
                    response = await axios.delete(`${this.BaseURL}${endpoint}`, this.AxiosConfig)
                    break
                case 'getPayloaded': 
                    stringifiedPayload = this.stringifyPayload(payload)
                    // console.log(stringifiedPayload)
                    response = await axios.get(`${this.BaseURL}${endpoint}${stringifiedPayload}`, this.AxiosConfig)
                    break
                case 'get':
                default:
                    response = await axios.get(`${this.BaseURL}${endpoint}`, this.AxiosConfig)
                    break
            }

            if (response.headers['x-ratelimit-limit']) {
                let rateLimitObj = {
                    remaining: response.headers['x-ratelimit-remaining'],
                    limit: response.headers['x-ratelimit-limit'],
                    reset: new Date(response.headers['x-ratelimit-reset'] * 1000)
                }
                EventBus.$emit('data-provider-api-ratelimit-usage', rateLimitObj)
            }
            // let response = await axios[type](`https://httpstat.us/429`)

            const responseTime = Math.round(performance.now() - perfStart)
            // console.log(generalizedEndpoint, responseTime)
            analytics.logEvent('api_call_pilotbi', {generalizedEndpoint, responseTime, rateLimit: response.headers['x-ratelimit-limit'], rateLimitRemaining: response.headers['x-ratelimit-remaining'], reset: new Date(response.headers['x-ratelimit-reset'] * 1000)})

            return response
        }
        catch (xhrError) {

            if (xhrError.response?.status == 429) { // Too many requests
                let time = xhrError.response.headers['x-ratelimit-reset']
                time = this.getTime(new Date(time * 1000))
                swal(
                    'Pilotbi loft ramt.',
                    `Loftet for antal kald systemet må lave til pilotbi pr 10 min er desværre ramt. Loftet nulstilles kl ${time}`,
                    'error'
                )
            } else if (endpoint.substr(0,34) == '/v1/Technicals/Nexel/Installation/' && endpoint.substr(42) == '/Status' && xhrError.response?.status == 404) {
                console.dir(xhrError)
                if (errorShouldPopup){
                    swal(`Fejl: ASR svarer ikke`, `Følgende HTTP fejl fra Pilotbi/Nexel opstod i forsøget på at hente CPE linkstatus:\n${xhrError.response ? `${xhrError.response.status} ${xhrError.response.statusText}` : xhrError.message}.\n\nDet sker som regel når ASR'en ikke er aktiv for "data harvest" hos EnergiFyn, så prøv at høre driften om de vil aktivere den.`, 'error')
                }
            } else {
                console.dir(xhrError)
                if (errorShouldPopup){
                    swal(`HTTP Fejl: ${xhrError.response ? `${xhrError.response.status} ${xhrError.response.statusText}` : xhrError.message}`, `Følgende HTTP fejl fra Pilotbi opstod: ${xhrError.response ? `${xhrError.response.status} ${xhrError.response.statusText}` : xhrError.message}. ${xhrError.response?.data ? '\n\nData: '+JSON.stringify(xhrError.response.data) : '' }\n\nEndpoint: ${endpoint}`, 'error')
                }
            }

            // Always re-throw error
            throw xhrError
        }
    }

    getTime(date) {
        return `${String('0'+date.getHours()).substr(-2)}:${String('0'+date.getMinutes()).substr(-2)}`
    }

    //GET-requests
    async getProjectTasks() {
        try {
            let response = await this.call('get', `/v1/ServiceNow/Suppliers/Supplier/${this.SupplierID}/Tasks`)
            return response.data
        }
        catch (xhrError) {
            console.error(xhrError)
            throw new NetworkError(`Error in receiving data from provider 'Pilotbi' in 'dataGetProjectTasks':\n${JSON.stringify(xhrError, null, 4)}`)
        }
    }

    async getTasksV2(taskType, installationId, serviceOrderId, query) {
        try {
            let payload = {
                installationId,
                serviceOrderId,
                query,
            }
            // console.log(payload)
            let response = await this.call('getPayloaded', `/v2/ServiceNow/Suppliers/Supplier/${this.SupplierID}/Tasks/${taskType}`, payload)
            return response.data
        }
        catch (xhrError) {
            console.error(xhrError)
            throw new NetworkError(`Error in receiving data from provider 'Pilotbi' in 'dataGetTasksV2':\n${JSON.stringify(xhrError, null, 4)}`)
        }
    }

    async getProjectTasksV2All() {
        try {
            let response = await this.call('get', `/v2/ProjectTasks/All/By/${this.SupplierID}`)
            return response.data
        } catch (xhrError) {
            console.error(xhrError)
            throw new NetworkError(`Error in receiving data from provider 'Pilotbi' in 'dataGetProjectTasksV2All':\n${JSON.stringify(xhrError, null, 4)}`)
        }
    }

    async getTroubleTicketsV2(state) {
        try {
            if (state) {
                let response = await this.call('getPayloaded', `/v2/TroubleTickets/All/By/${this.SupplierID}`, {state})
                return response.data
            } else {
                let response = await this.call('get', `/v2/TroubleTickets/All/By/${this.SupplierID}`)
                return response.data
            }
        } catch (error) {
            console.error(error)
            throw new NetworkError(`Error in receiving data from provider 'PilotBI' in 'dataGetTroubleTicketsV2:\n${JSON.stringify(error, null, 4)}`)
        }
    }

    async getTroubleTicketsByInst(instLabel) {
        try {
            let response = await this.call('get', `/v1/ServiceNow/TroubleTickets/all-by-customer-&-installation?installation=${instLabel}`)
            return response.data
        } catch (error) {
            console.log(error)
            throw new NetworkError(`Error in receiving data from provider 'PilotBI' in 'dataGetTroubleTicketsByInst:'\n${JSON.stringify(error, null, 4)}`)
        }
    }

    async getTasksCount(taskType) {
        try {
            let response = await this.call('get', `/v2/ServiceNow/Suppliers/Supplier/${this.SupplierID}/Tasks/${taskType}/Count`)
            return response.data
        }
        catch (xhrError) {
            console.error(xhrError)
            throw new NetworkError(`Error in receiving data from provider 'Pilotbi' in 'dataGetTasksV2':\n${JSON.stringify(xhrError, null, 4)}`)
        }
    }

    /**
     * @param {Date} since 
     * @returns {Object}
     * @throws {NetworkError}
     */
    async getClosedProjectTasks(since) {
        let sinceString = since.toISOString().split('T')[0]
        try {
            let response = await this.call('get', `/v1/ServiceNow/Suppliers/Supplier/${this.SupplierID}/Tasks/Since/${sinceString}`)
            return response.data
        }
        catch (xhrError) {
            console.error(xhrError)
            throw new NetworkError(`Error in receiving data from provider 'Pilotbi' in 'dataGetClosedProjectTasks':\n${JSON.stringify(xhrError, null, 4)}`)
        }
    }

    async attachLocationDataToProjectTasks(tasks) {
        try {
            let response = await this.call('put', `/v1/ServiceNow/ProjectTasks/Locations`, tasks)
            return response.data
        }
        catch (xhrError) {
            console.error(xhrError)
            throw new NetworkError(`Error in receiving data from provider 'Pilotbi' in 'attachLocationDataToProjectTasks':\n${JSON.stringify(xhrError, null, 4)}`)
        }
    }

    async getProjectTask(projectTaskId) {
        try {
            // let response = await this.call('get', `/v1/ServiceNow/ProjectTasks/ProjectTask/${projectTaskId}`)
            let response = await this.call('get', `/v3/ProjectTasks/${projectTaskId}`)
            return response.data
        }
        catch (xhrError) {
            console.error(xhrError)
            throw new NetworkError(`Error in receiving data from provider 'Pilotbi' in 'dataGetProjectTask':\n${JSON.stringify(xhrError, null, 4)}`)
        }
    }

    async getTroubleTicket(troubleTicketId) {
        try {
            let response = await this.call('get', `/v1/ServiceNow/TroubleTickets/TroubleTicket/${troubleTicketId}`)
            return response.data
        }
        catch (xhrError) {
            console.error(xhrError)
            throw new NetworkError(`Error in receiving data from provider 'Pilotbi' in 'dataGetTroubleTicket':\n${JSON.stringify(xhrError, null, 4)}`)
        }
    }

    async getAttachmentList(projectTaskId){
        try {
            let response = await this.call('get', `/v3/ProjectTasks/${projectTaskId}/attachments`)
            return response.data
        }
        catch (xhrError){
            throw new NetworkError(`Error in receiving data from provider 'Pilotbi' in 'dataGetAttachmentList':\n${JSON.stringify(xhrError, null, 4)}`)
        }
    }

    async getAttachment(attachmentId) {
        this.AxiosConfig.params = {
            supplierId: this.SupplierID
        }
        try {
            let response = await this.call('get', `/v1/ServiceNow/ProjectTasks/ProjectTask/Attachment/${attachmentId}`)
            delete this.AxiosConfig.params
            return response.data
        }
        catch (xhrError) {
            delete this.AxiosConfig.params
            console.error(xhrError)
            if (xhrError.response?.status == 403) {
                swal('Adgang nægtet','FiberLAN har ikke tilladelse til at åbne denne fil','error')
            } else {
                throw new Error(`Error in receiving data from provider 'Pilotbi' in 'dataGetAttachment':\n${JSON.stringify(xhrError, null, 4)}`)
            }
        }
    }

    async getProducts(orderId) {
        try {
            if (!orderId) throw new Error('Cannot get products without orderId (sonWinId from serviceOrder)')
            let response = await this.call('get', `/v1/Sonwin/Products/By/${orderId}`, null, false)
            return response.data
        }
        catch (xhrError) {
            console.error(xhrError)
            throw new NetworkError(`Error in receiving data from provider 'Pilotbi' in 'dataGetProducts':\n${JSON.stringify(xhrError, null, 4)}`)
        }
    }

    async getServiceOrder(serviceOrderId, responseMeta) {
        if (!serviceOrderId) {
            throw new Error('Cannot get serviceOrder without ID')
        }
        try {
            let response = await this.call('get', `/v1/ServiceNow/ServiceOrders/ServiceOrder/${serviceOrderId}`, null, false) //Supress popup for this call, as there is a fallback
            if (responseMeta) responseMeta.http = response
            return response.data
        }
        catch (xhrError) {
            console.error(xhrError)
            throw new NetworkError(`Error in receiving data from provider 'Pilotbi' in 'getServiceOrder':\n${JSON.stringify(xhrError, null, 4)}`)
        }
    }

    async getAllServiceOrdersByInst(instLabel) { //TODO: Consider re-writing to support getting serviceOrder by customer ID
        if (!instLabel) {
            throw new Error('Cannot get serviceOrders without Installation label')
        }
        try {
            let response = await this.call('get', `/v2/ServiceNow/ServiceOrders/all-by-customer-&-installation/By/${this.SupplierID}?installation=${instLabel}`)
            return response
        } catch (xhrError) {
            console.error(xhrError)
            throw new NetworkError(`Error in receiving data from provider 'Pilotbi' in 'getAllServiceOrdersByInst':\n${JSON.stringify(xhrError, null, 4)}`)
        }
    }

    // async getWorkers() {
    //     try {
    //         let response = await this.call('get', `/v1/Dispatch/Workers/All`)
    //         return response.data
    //     }
    //     catch (xhrError) {
    //         console.error(xhrError)
    //         throw new NetworkError(`Error in receiving data from provider 'Pilotbi' in 'getWorkers:'\n${JSON.stringify(xhrError, null, 4)}`)
    //     }
    // }

    // async getWorkerTasks(workerId) {
    //     try{
    //         let response = await this.call('get', `/v1/Dispatch/Workers/WorkerTasks/${workerId}`)
    //         return response.data
    //     }
    //     catch (xhrError) {
    //         console.error(xhrError)
    //         throw new NetworkError(`Error in receiving data from provider 'Pilotbi' in 'getWorkerTasks':\n${JSON.stringify(xhrError, null, 4)}`)
    //     }
    // }

    async getTechnicalFromId(technical, id) {
        try {
            let technicalId = id
            if (!parseInt(technicalId.substr(0,1))) {
                console.error(`technical ID '${technicalId}' seems to be formatted incorrectly`)
                return []
            }
            if (id.indexOf('-') == -1) technicalId += '-'
            let response = await this.call('get', `/v1/Technicals/Containers/By/${technicalId}/${technical}`)
            return response
        }
        catch (xhrError) {
            console.error(xhrError)
            throw new NetworkError(`Error in receiving data from provider 'Pilotbi' in 'getTechnical':\n${JSON.stringify(xhrError, null, 4)}`)
        }
    }

    async getReportFromIdSource(idSource, planType, formatType = 'XLSX') {
        try {
            let response = await this.call('get', `/v1/Technicals/Report/${idSource}/${planType}/${formatType}`)
            return response
        }
        catch (xhrError) {
            console.error(xhrError)
            throw new NetworkError(`Error in receiving data from provider 'Pilotbi' in 'getReport':\n${JSON.stringify(xhrError, null, 4)}`)
        }
    }

    async getNexelData(instLabel) {
        try {
            let response = await this.call('get', `/v1/Technicals/Nexel/Installation/${instLabel}`)
            return response
        } catch (xhrError) {
            console.error(xhrError)
            throw new NetworkError(`Error in retrieving Nexel data for inst:${instLabel} from provider 'PilotBI' in 'getNexelData':\n${JSON.stringify(xhrError, null, 4)}`)
        }
    }

    async getNexelStatus(instLabel) {
        try {
            let response = await this.call('get', `/v1/Technicals/Nexel/Installation/${instLabel}/Status`)
            return response
        } catch (xhrError) {
            console.error(xhrError)
            throw new NetworkError(`Error in retrieving Nexel status for inst:${instLabel} from provider 'PilotBI' in 'getNexelStatus':\n${JSON.stringify(xhrError, null, 4)}`)
        }
    }

    //POST Requests
    async postNote(projectTaskId, noteBody, middleRequestText = 'ProjectTasks/ProjectTask', endRequestText = '/Note') {
        try {
            let response = await this.call('post', `/v1/ServiceNow/${middleRequestText}/${projectTaskId}${endRequestText}`, String('"'+String(noteBody)+'"'))
            return response.data
        }
        catch (xhrError) {
            console.error(xhrError)
            throw new NetworkError(`Error in posting data to provider 'PilotBI' in 'dataPostNote':\n${JSON.stringify(xhrError, null, 4)}`)
        }
    }

    async postAttachment(projectTaskId, name, type, base64Content, middleRequestText = 'ProjectTasks/ProjectTask', endRequestText = '/Attachment'){
        try {
            let fileObj = {
                name: name,
                type: type,
                base64Content: base64Content
            }
            let response = await this.call('post', `/v1/ServiceNow/${middleRequestText}/${projectTaskId}${endRequestText}`, JSON.stringify(fileObj))
            return response
        }
        catch (xhrError) {
            console.error(xhrError)
            throw new NetworkError(`Error in posting data to provider 'PilotBI' in 'dataPostAttachment':\n${JSON.stringify(xhrError, null, 4)}`)
        }
    }

    //PUT Requests
    async updateProjectTask(projectTaskId, state, assignee, connectionDate, onHoldReason, onHoldSubReason, plannedStart, plannedEnd, signalStrengthData, signalStrengthCaTV, speedTestFiberbox, dataportCheckPerformed) {
        let projectTaskObj = {
            state,
            assignee,
            connectionDate,
            onHoldReason,
            onHoldSubReason,
            plannedStart,
            plannedEnd,
        }
        
        let techDataObj = { //TODO: use this objects with new function: updateTechnicalData(taskId, payload)
            signalStrengthData,
            signalStrengthCaTV,
            speedTestFiberbox,
            dataportCheckPerformed: String(!!dataportCheckPerformed),
        }
        
        try {
            let techResponse
            if (signalStrengthData || signalStrengthCaTV || speedTestFiberbox || dataportCheckPerformed) {
                techResponse = await this.updateTechnicalData(projectTaskId, techDataObj) //Sends PATCH-request to update technical data first
                // console.log(techResponse)
            }
            let response = await this.call('put', `/v2/ProjectTasks/${this.SupplierID}/ProjectTask/${projectTaskId}`, JSON.stringify(projectTaskObj))

            if (techResponse) {
                response.data.configurationItem.technicalData = techResponse.data
            }

            // console.group(`updateProjectTask ${projectTaskId}`)
            // console.log(projectTaskObj, techDataObj)
            // console.log(response.data)
            // console.groupEnd()

            //Compare response to sent update, to verify update went through
            let unMatchedKeys = []
            if (state && state != response.data.state.value) unMatchedKeys.push('state')
            if (assignee && assignee != response.data.assignee) unMatchedKeys.push('assignee')
            if (connectionDate && connectionDate.substring(0,10) != response.data.connectionDate?.substring(0,10)) unMatchedKeys.push('connectionDate')
            if (onHoldReason && onHoldReason != response.data.state.reason.value) unMatchedKeys.push('state.reason')
            if (plannedStart && new Date(plannedStart).toISOString() != new Date(response.data.plannedStart).toISOString()) unMatchedKeys.push('plannedStart')
            if (plannedEnd && new Date(plannedEnd).toISOString() != new Date(response.data.plannedEnd).toISOString()) unMatchedKeys.push('plannedEnd')
            if (signalStrengthData && signalStrengthData != response.data.configurationItem.technicalData?.signalStrengthData) unMatchedKeys.push('configurationItem.technicalData.signalStrengthData')
            if (signalStrengthCaTV && signalStrengthCaTV != response.data.configurationItem.technicalData?.signalStrengthCaTV) unMatchedKeys.push('configurationItem.technicalData.signalStrengthCaTV')
            if (speedTestFiberbox && speedTestFiberbox != response.data.configurationItem.technicalData?.speedTestFiberbox) unMatchedKeys.push('configurationItem.technicalData.speedTestFiberbox')
            if (dataportCheckPerformed && Boolean(JSON.parse(dataportCheckPerformed)) != Boolean(Number(response.data.configurationItem.technicalData?.portCheckPerformed))) unMatchedKeys.push('configurationItem.technicalData.dataportCheckPerformed')

            if (unMatchedKeys.length) { //If any of the above checks failed, throw an error
                throw new NetworkError(`The task was updated, but the following field(s) did not update correctly: ${unMatchedKeys.join(', ')}`)
            }

            return response.data
        }
        catch (error) {
            console.error(error)
            throw new NetworkError(`Error in posting data to provider 'PilotBI' in 'dataUpdateProjectTask':\n${error.message}`)
        }
    }

    async updateTroubleTicket(troubleTicketId, state, assignee, onHoldReason, plannedStart, plannedEnd) {
        let troubleTicketObj = {
            troubleTicketId,
            state,
            assignee,
            onHoldReason,
            plannedStart,
            plannedEnd,
        }

        try {
            let response = await this.call('put', `/v2/TroubleTickets/${this.SupplierID}/TroubleTicket/${troubleTicketId}`, JSON.stringify(troubleTicketObj))
            console.group(`updateTroubleTicket ${troubleTicketId}`)
            console.log(troubleTicketObj)
            console.log(response)
            console.groupEnd()

            let unMatchedKeys = []
            if (state && state != response.data.state.value) unMatchedKeys.push('state')
            if (assignee && assignee != response.data.assignee) unMatchedKeys.push('assignee')
            if (onHoldReason && onHoldReason != response.data.state.reason.value) unMatchedKeys.push('state.reason.value')
            if (plannedStart && plannedStart != response.data.plannedStart) unMatchedKeys.push('plannedStart')
            if (plannedEnd && plannedEnd != response.data.plannedEnd) unMatchedKeys.push('plannedEnd')

            if (unMatchedKeys.length) { //If any of the above checks failed, throw an error
                throw new NetworkError(`The ticket was updated, but the following field(s) did not update correctly: ${unMatchedKeys.join(', ')}`)
            }

            return response
        }
        catch (xhrError) {
            console.error(xhrError)
            throw new NetworkError(`Error in posting data to provider 'PilotBI' in 'dataUpdateTroubleTicket':\n${xhrError.message}`)
        }
    }

    // async updateWorkerTask(dataObj){
    //     try {
    //         let response = await this.call('put', `/v1/Dispatch/Workers/WorkerTasks/Update-Multiple`, JSON.stringify({workerTasks: [dataObj]}))
    //         // console.group('updateWorkerTask')
    //         // console.log({workerTasks: [dataObj]})
    //         // console.groupEnd()
    //         return response
    //     }
    //     catch (xhrError) {
    //         console.error(xhrError)
    //         throw new NetworkError(`Error in posting data to provider 'PilotBI' in 'updateWorkerTask':\n${JSON.stringify(xhrError, null, 4)}`)
    //     }
    // }

    async updateMultipleProjectTasks(projectTasks) {
        try {
            let response = await this.call('put', `/v2/ProjectTasks/${this.SupplierID}/Update-Multiple`, JSON.stringify({ projectTasks: projectTasks }))
            console.log('Updated multiple project tasks, and got the following response:',response)
            return response
        }
        catch (xhrError) {
            console.error(xhrError)
            throw new NetworkError(`Error in updating project tasks to provider 'PilotBI' in 'updateMultipleProjectTasks':\n${JSON.stringify(xhrError, null, 4)}`)
        }
    }

    // async deleteWorkerTask(taskIds) {
    //     let _taskIds = taskIds
    //     if (Array.isArray(taskIds)) {
    //         _taskIds = taskIds.join(',')
    //     }

    //     try {
    //         let response = await this.call('delete', `/v1/Dispatch/Workers/WorkerTasks/Delete-Multiple/${_taskIds}`)
    //         return response
    //     }
    //     catch (xhrError) {
    //         console.error(xhrError)
    //         throw new NetworkError(`Error in deleting worker tasks in provider 'PilotBI' in 'deleteWorkerTask':\n${JSON.stringify(xhrError, null, 4)}`)
    //     }
    // }

    //PATCH Requests
    async deleteConnectionDate(taskId) {
        try {
            let response = await this.call('patch', `/v1/ServiceNow/ProjectTasks/${taskId}/ConnectionDate/Delete`)
            return response
        } catch (xhrError) {
            console.error(xhrError)
            throw new NetworkError(`Error in deleting connectionDate in provider 'PilotBI' in 'deleteConectionDate'`)
        }
    }

    async updateTechnicalData(taskId, payload) {
        try {
            let response = await this.call('patch', `/v1/Technicals/Data/${taskId}`, payload)
            return response
        } catch (error) {
            console.error(error)
        }
    }
}
