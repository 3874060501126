<template>
    <div class="logPage">
        <h1>Offentlige logfiler for database-oprydning i FiberLAN's databaser</h1>
        <div v-for="logFile in logFiles" :key="logFile.id">
            <h2>Logfil fra {{toUserFriendlyTimestamp(logFile.timeStamp, false, true)}}</h2>
            <sui-table striped compact>
                <sui-table-header>
                    <sui-table-row>
                        <sui-table-header-cell>Timestamp</sui-table-header-cell>
                        <sui-table-header-cell>Type</sui-table-header-cell>
                        <sui-table-header-cell>Besked</sui-table-header-cell>
                    </sui-table-row>
                </sui-table-header>
                <sui-table-body>
                    <sui-table-row v-for="(logMessage, index) of logFile.logArray" :key="index" :class="`_${logMessage.logType}`">
                        <sui-table-cell>{{toUserFriendlyTimestamp(logMessage.timeStamp, true, true, true)}}</sui-table-cell>
                        <sui-table-cell>{{logMessage.logType}}</sui-table-cell>
                        <sui-table-cell>{{logMessage.string}}</sui-table-cell>
                    </sui-table-row>
                </sui-table-body>
            </sui-table>
            <hr>
        </div>
    </div>
</template>
<script>
import { db } from '../../firebase'
import { Mixin } from '../../lib/Mixins/mixin'
import { DateMixin } from '../../lib/Mixins/dateMixin'

export default {
    name: 'PublicLog',

    mixins: [Mixin, DateMixin],

    data() {
        return {
            logFiles: []
        }
    }, 

    firestore: {
        logFiles: db.collection('logs').orderBy('timeStamp', 'desc')
    }
}
</script>
<style scoped>
    tr._warn{
        background: LightGoldenRodYellow !important;
    }
    tr._debug{
        background: #EEEEFF !important;
    }
    tr._error{
        background: #FFEAEA !important;
    }
    .logPage{
        max-height: 100svh;
        padding: 20px;
        overflow: auto;
    }
</style>