<template>
    <!--As a div, so it can be in a modal, or DataCard or wherever you want-->
    <div>
        <div class="row">
            <div class="col-am-2"> 
                <i v-if="overallState == 'pending'" class="fa-solid fa-circle-pause" style="color: grey;"/>
                <i v-if="overallState == 'in progress'" class="fas fa-spinner-third fa-spin"/>
                <i v-if="overallState == 'success'" class="fa-solid fa-circle-check" style="color: green;"/>
                <i v-if="overallState == 'error'" class="fa-solid fa-circle-xmark" style="color: red;"/>
            </div>
            <div class="col-sm-10">
                Vent venligst på følgende processer:
            </div>
        </div>
        <div class="row" v-for="(step, index) in steps" :key="index">
            <div class="col-sm-2">
                <i v-if="step.state == 'pending'" class="fa-solid fa-circle-pause" style="color: grey;"/>
                <i v-if="step.state == 'in progress' && (!step.percentage && step.percentage !== 0)" class="fas fa-spinner-third fa-spin"/>
                <sui-progress v-if="step.state == 'in progress' && (step.percentage || step.percentage === 0)" size="small" state="active" indicating :percent="step.percentage" style="margin: 0 0 0 0"/>
                <i v-if="step.state == 'success'" class="fa-solid fa-circle-check" style="color: green;"/>
                <i v-if="step.state == 'error'" class="fa-solid fa-circle-xmark" style="color: red;"/>
            </div>
            <div class="col-sm-10">{{step.title}}</div>
        </div>
    </div>
</template>
<script>
import { Mixin } from '../../lib/Mixins/mixin'
export default {
    name: 'MultiStepLoader', //Synonymus with 'mutli-step-loader'
    mixins: [Mixin],

    props: {
        steps: {
            type: Array,
            required: true,
        }
    },

    data() {
        return {
            stepModel: {
                code: '', //Used for searching for the step after initializing it
                title: '', //Name of the step, for the list
                state: '', //['pending', 'in progress', 'success', 'error']
                percentage: 0, //0-100 optional
            },
        }
    },

    computed: {
        overallState() {
            // ConsideredStepStates = ['pending', 'in progress', 'success', 'error']
            let states = [] //array of unique states of steps
            for (let step of this.steps) {
                if (step.state == 'in progress') {
                    return 'in progress' //If any step is in progress, overAll is in progress
                }
                if (!states.includes(step.state)) states.push(step.state) //push current state to unique states array
            }
            if (states.length == 1) return states[0] //If there is only one unique state, return that
            if (states.includes('error')) return 'error' //If none of the above applies, and there is an error, return error
            return 'pending' //If none of the above applies, there must be both pending and success states, so overAll is pending (waiting state)
        },
    },
}
</script>
<style scoped>

</style>