
class OnHoldReason {

    // On Hold reasons
    static CALLER = '1'
    static PROBLEM = '3'
    static VENDOR = '4'
    static CHANGE = '5'
    static REQUEST = '6'

    // On Hold subreasons
    static CALLER_CUSTOMER = '7050b1161bfa35903fa7f646464bcb5a'

    static PROBLEM_ETABLISSEMENT = '5890f5161bfa35903fa7f646464bcbe4'
    static PROBLEM_INCLEMENT_WEATHER = '44a079161bfa35903fa7f646464bcb10'
    static PROBLEM_LARGER_DAMAGE = '43a0b9161bfa35903fa7f646464bcb6c'
    static PROBLEM_INVOICE_APPROVAL = 'bfb03d161bfa35903fa7f646464bcbec'   // TODO: (Only visible for admin)
    static PROBLEM_INVOICE_REJECTED = '80d0bd161bfa35903fa7f646464bcb4a'   // TODO: (Only visible for admin)
    static PROBLEM_INVOICE_APPROVED = '03d031561bfa35903fa7f646464bcb69'   // TODO: (Only visible for admin)

    static VENDOR_NO_CONTACT = 'a57fa9921bfa35903fa7f646464bcb6d'
    static VENDOR_INCORRECT_CONT_INFO = '7810b9d21bfa35903fa7f646464bcb74'
    static VENDOR_CANCELLATION = 'bd3079d21bfa35903fa7f646464bcbb9'
    static VENDOR_AWAITING_SP = '114031161bfa35903fa7f646464bcbd8'

    static CHANGE_LARGER_DAMAGE = '0c7035161bfa35903fa7f646464bcbd3'
    static CHANGE_NETWORK = 'b270b5161bfa35903fa7f646464bcb18'

    static REQUEST_FOLLOW_UP = 'd460f1161bfa35903fa7f646464bcb92'

    static NO_ONHOLD_SUBREASON = '100' // Used for tickets without onhold subreasons


    // Methods for getting the description of the on hold reason
    static getDesciptionFromCode(reason, subreason = this.NO_ONHOLD_SUBREASON){
        reason = Number(reason).toString() //Get rid of any accidental decimals
        switch(reason) {
            case this.CALLER: return this.getCallerDescriptionFromCode(subreason)
            case this.PROBLEM: return this.getProblemDescriptionFromCode(subreason)
            case this.VENDOR: return this.getVendorDescriptionFromCode(subreason)
            case this.CHANGE: return this.getChangeDescriptionFromCode(subreason)
            case this.REQUEST: return this.getRequestDescriptionFromCode(subreason)
            default: "Ukendt On-hold reason"
        }
    }

    static getCallerDescriptionFromCode(code){
        switch(code) {
            case this.CALLER_CUSTOMER: return "Afventer slutkunde"
            case this.NO_ONHOLD_SUBREASON: return "Afventer opringning"
            default: "Ukendt On-hold subreason"
        }
    }

    static getProblemDescriptionFromCode(code){
        switch(code) {
            case this.PROBLEM_ETABLISSEMENT: return "Afventer etablering"
            case this.PROBLEM_INCLEMENT_WEATHER: return "Vejrlig"
            case this.PROBLEM_LARGER_DAMAGE: return "Større skade/nedbrud"
            case this.PROBLEM_INVOICE_APPROVAL: return "Faktura til godkendelse"
            case this.PROBLEM_INVOICE_REJECTED: return "Faktura afvist"
            case this.PROBLEM_INVOICE_APPROVED: return "Faktura godkendt"
            case this.NO_ONHOLD_SUBREASON: return "Afventer eksternt problem"
            default: "Ukendt On-hold subreason"
        }
    }

    static getVendorDescriptionFromCode(code){
        switch(code) {
            case this.VENDOR_NO_CONTACT: return "Ingen eller manglende kontakt til kunden"
            case this.VENDOR_INCORRECT_CONT_INFO: return "Mangler/forkert kontaktinfo på kunden"
            case this.VENDOR_CANCELLATION: return "Slutkunde ønsker at annullere"
            case this.VENDOR_AWAITING_SP: return "Afventer SP - se ticket"
            case this.NO_ONHOLD_SUBREASON:return "Afventer service-provider"
            default: "Ukendt On-hold subreason"
        }
    }

    static getChangeDescriptionFromCode(code){
        switch(code){
            case this.CHANGE_LARGER_DAMAGE: return "Større skade/nedbrud"
            case this.CHANGE_NETWORK: return "Afklaring netværk"
            case this.NO_ONHOLD_SUBREASON: return "Afventer projektering"
            default: "Ukendt On-hold subreason"
        }
    }

    static getRequestDescriptionFromCode(code){
        switch(code){
            case this.REQUEST_FOLLOW_UP: return "Afventer opfølgning"
            case this.NO_ONHOLD_SUBREASON: return "Afventer EnergiFyn"
            default: "Ukendt On-hold subreason"
        }
    }


    // Methods for getting the details for the on hold reason
    static getDetailsFromCode(reason, subreason = this.NO_ONHOLD_SUBREASON){
        reason = Number(reason).toString() //Get rid of any accidental decimals
        switch(reason) {
            case this.CALLER: return this.getCallerDetailsFromCode(subreason)
            case this.PROBLEM: return this.getProblemDetailsFromCode(subreason)
            case this.VENDOR: return this.getVendorDetailsFromCode(subreason)
            case this.CHANGE: return this.getChangeDetailsFromCode(subreason)
            case this.REQUEST: return this.getRequestDetailsFromCode(subreason)
            default: "Ukendt On-hold reason"
        }
    }

    static getCallerDetailsFromCode(code){
        switch(code){
            case this.CALLER_CUSTOMER: return "'Awaiting Caller': Anvendes når en slutkunde skal vende retur til EF med afklaring inden leverancen kan fortsætte."
            case this.NO_ONHOLD_SUBREASON: return "'Awaiting Caller': Afventer en oplysning fra kunden. Er allerede i dialog med kunden eller i proces med at komme det."
            default: "Ukendt On-hold subreason"
        }
    }

    static getProblemDetailsFromCode(code){
        switch(code){
            case this.PROBLEM_ETABLISSEMENT: return "'Awaiting Problem': Henleder hovedsageligt til problemer opstået i etableringsøjemed. Fx ved manglende tilladelser til at grave på privatvej, blæsestop, jernbaneovergang m.m."
            case this.PROBLEM_INCLEMENT_WEATHER: return "'Awaiting Problem': Anvendes når leverance forhindres af særlige vejrforhold, fx hård frost, oversvømmelser m.m."
            case this.PROBLEM_LARGER_DAMAGE: return "'Awaiting Problem': Anvendes når der sker en større graveskade, strømafbrydelse, systemnedbrud eller lign., som gør, at vi ikke kan fortsætte normal leverance."
            case this.PROBLEM_INVOICE_APPROVAL: return "'Awaiting Problem': Benyttes på project task 'Dan fakturagrundlag' til entreprenørerne på eftertilslutninger"
            case this.PROBLEM_INVOICE_REJECTED: return "'Awaiting Problem': Benyttes på project task 'Dan fakturagrundlag' til entreprenørerne på eftertilslutninger"
            case this.PROBLEM_INVOICE_APPROVED: return "'Awaiting Problem': Benyttes på project task 'Dan fakturagrundlag' til entreprenørerne på eftertilslutninger"
            case this.NO_ONHOLD_SUBREASON: return "'Awaiting Problem': Der er opstået et problem i etableringen, som kræver hjælp/ændring udefra (fx vejrlig)"
            default: "Ukendt On-hold subreason"
        }
    }

    static getVendorDetailsFromCode(code){
        switch(code){
            case this.VENDOR_NO_CONTACT: return "'Awaiting Vendor': Anvendes når EF ikke har mulighed for at træffe kunden eller kunden ikke er vendt tilbage på henvendelse, og der ikke kan opnås kontakt."
            case this.VENDOR_INCORRECT_CONT_INFO: return "'Awaiting Vendor': Anvendes når der mangler telefonnummer eller mailadresse på slutkunden"
            case this.VENDOR_CANCELLATION: return "'Awaiting Vendor': Anvendes når slutkunde ytrer ønske om at ville annullere ordre under leverance"
            case this.VENDOR_AWAITING_SP: return "'Awaiting Vendor': Anvendes når kunden har sprøgsmål til deres udbyder, som leverancen ikke kan svare på. Fx. spørgsmål til priser, manglende ordrebekræftelse, forkert leveringsadresse. Følges altid op af ticket med detaljer på forespørgslen."
            case this.NO_ONHOLD_SUBREASON: return "'Awaiting Vendor': Afventer en oplysning eller afklaring fra service-provider"
            default: "Ukendt On-hold subreason"
        }
    }

    static getChangeDetailsFromCode(code){
        switch(code){
            case this.CHANGE_LARGER_DAMAGE: return "'Awaiting Change': Anvendes når der sker større graveskade, strømafbrydelse eller lign., som gør, at vi ikke kan fortsætte normal leverance."
            case this.CHANGE_NETWORK: return "'Awaiting Change': Henleder hovedsageligt til problemer opstået i driften - orienteret mod netværket. Fx. manglende aktivt udstyr, afventer teknikerhjælp eller hjælp fra Fibia/Waoo m.m."
            case this.NO_ONHOLD_SUBREASON: return "'Awaiting Change': Der er opstået en mindre fejl eller mangel i nettet eller i projekteringen af opgaven, som skal rettes, inden vi kan komme videre"
            default: "Ukendt On-hold subreason"
        }
    }

    static getRequestDetailsFromCode(code){
        switch(code){
            case this.REQUEST_FOLLOW_UP: return "'Awaiting Request': EF har lavet aftale med slutkunden om at følge op på en igangværende sag. Fx. i tilfælde hvor tekniker har udbedret fejl, mengerne vil sikre sig, at alt fungerer. Eller hvis, slutkunden har særlige ønsker til etablering, som skal afklaret m.m."
            case this.NO_ONHOLD_SUBREASON: return "'Awaiting Request': Der er kommet et ønske fra kunde eller de udførende folk, som behandles i EnergiFyn, inden processen går videre"
            default: "Ukendt On-hold subreason"
        }
    }

    // Dropdown options
    static DROPDOWN_OPTIONS = [
        {value: this.CALLER, text: this.getDesciptionFromCode(this.CALLER), title: this.getDetailsFromCode(this.CALLER)},
        {value: this.PROBLEM, text: this.getDesciptionFromCode(this.PROBLEM), title: this.getDetailsFromCode(this.PROBLEM)},
        {value: this.VENDOR, text: this.getDesciptionFromCode(this.VENDOR), title: this.getDetailsFromCode(this.VENDOR)},
        {value: this.CHANGE, text: this.getDesciptionFromCode(this.CHANGE), title: this.getDetailsFromCode(this.CHANGE)},
        {value: this.REQUEST, text: this.getDesciptionFromCode(this.REQUEST), title: this.getDetailsFromCode(this.REQUEST)},
    ]

    static CALLER_DROPDOWN_OPTIONS = [
        {value: this.CALLER_CUSTOMER, text: this.getCallerDescriptionFromCode(this.CALLER_CUSTOMER), title: this.getCallerDetailsFromCode(this.CALLER_CUSTOMER)}
    ]

    static PROBLEM_DROPDOWN_OPTIONS = [
        {value: this.PROBLEM_ETABLISSEMENT, text: this.getProblemDescriptionFromCode(this.PROBLEM_ETABLISSEMENT), title: this.getProblemDetailsFromCode(this.PROBLEM_ETABLISSEMENT)},
        {value: this.PROBLEM_INCLEMENT_WEATHER, text: this.getProblemDescriptionFromCode(this.PROBLEM_INCLEMENT_WEATHER), title: this.getProblemDetailsFromCode(this.PROBLEM_INCLEMENT_WEATHER)},
        {value: this.PROBLEM_LARGER_DAMAGE, text: this.getProblemDescriptionFromCode(this.PROBLEM_LARGER_DAMAGE), title: this.getProblemDetailsFromCode(this.PROBLEM_LARGER_DAMAGE)},
        {value: this.PROBLEM_INVOICE_APPROVAL, text: this.getProblemDescriptionFromCode(this.PROBLEM_INVOICE_APPROVAL), title: this.getProblemDetailsFromCode(this.PROBLEM_INVOICE_APPROVAL)},
        {value: this.PROBLEM_INVOICE_REJECTED, text: this.getProblemDescriptionFromCode(this.PROBLEM_INVOICE_REJECTED), title: this.getProblemDetailsFromCode(this.PROBLEM_INVOICE_REJECTED)},
        {value: this.PROBLEM_INVOICE_APPROVED, text: this.getProblemDescriptionFromCode(this.PROBLEM_INVOICE_APPROVED), title: this.getProblemDetailsFromCode(this.PROBLEM_INVOICE_APPROVED)}
    ]

    static VENDOR_DROPDOWN_OPTIONS = [
        {value: this.VENDOR_NO_CONTACT, text: this.getVendorDescriptionFromCode(this.VENDOR_NO_CONTACT), title: this.getVendorDetailsFromCode(this.VENDOR_NO_CONTACT)},
        {value: this.VENDOR_INCORRECT_CONT_INFO, text: this.getVendorDescriptionFromCode(this.VENDOR_INCORRECT_CONT_INFO), title: this.getVendorDetailsFromCode(this.VENDOR_INCORRECT_CONT_INFO)},
        {value: this.VENDOR_CANCELLATION, text: this.getVendorDescriptionFromCode(this.VENDOR_CANCELLATION), title: this.getVendorDetailsFromCode(this.VENDOR_CANCELLATION)},
        {value: this.VENDOR_AWAITING_SP, text: this.getVendorDescriptionFromCode(this.VENDOR_AWAITING_SP), title: this.getVendorDetailsFromCode(this.VENDOR_AWAITING_SP)}
    ]

    static CHANGE_DROPDOWN_OPTIONS = [
        {value: this.CHANGE_LARGER_DAMAGE, text: this.getChangeDescriptionFromCode(this.CHANGE_LARGER_DAMAGE), title: this.getChangeDetailsFromCode(this.CHANGE_LARGER_DAMAGE)},
        {value: this.CHANGE_NETWORK, text: this.getChangeDescriptionFromCode(this.CHANGE_NETWORK), title: this.getChangeDetailsFromCode(this.CHANGE_NETWORK)}
    ]

    static REQUEST_DROPDOWN_OPTIONS = [
        {value: this.REQUEST_FOLLOW_UP, text: this.getRequestDescriptionFromCode(this.REQUEST_FOLLOW_UP), title: this.getRequestDetailsFromCode(this.REQUEST_FOLLOW_UP)}
    ]

}

export default OnHoldReason

