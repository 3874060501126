import { Timestamp } from "../../firebase"

export const DateMixin = {

    enums: {

    },

    methods: {
        parseDate(date) {
            if (date instanceof Date) {
                return new Date(date) //Return a copy of the date
            } else if (typeof date == 'string') {
                let _date = date
                if (date.includes('T')) {
                    _date = this.parseISODate(date)
                }
                return new Date(_date)
            } else if (date instanceof Timestamp) { //Firebase Timestamp class
                return date.toDate() //Firebase Timestamp class method converts to js date
            } else if (date?.seconds && date?.nanoseconds){ // Firebase Timestamp is JSON format
                return new Date(date.seconds * 1000 + date.nanoseconds / 1000000)
            }

            return new Date(date)
        },

        parseISODate(s) {
            var b = s.split(/\D/);
            let date = new Date(b[0], b[1]-1, b[2], b[3], b[4], b[5]);
            if (s.includes('Z')) { //Time is Zulu (UTC)
                date.setUTCHours(b[3], b[4], b[5]) //Handle UTC timezone difference
            }
            return date
        },

        formatDate(date) {
            if (date == null) {
                return 'N/A'
            }
            date = this.parseDate(date)
            return `${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()}`
        },

        formatMachineDate(date, delimiter = '/') {
            if (date == null || date == 'N/A') {
                return 'N/A'
            }
            date = this.parseDate(date)
            if (isNaN(date)) return null
            return `${date.getFullYear()}${delimiter}${String(date.getMonth()+1).padStart(2,'0')}${delimiter}${String(date.getDate()).padStart(2,'0')}`
        },

        formatMachineTimestamp(dateTime) {
            if (dateTime == null) {
                return 'N/A'
            }
            dateTime = this.parseDate(dateTime)
            return dateTime.toISOString()
            // return `${dateTime.getFullYear()}-${String("0"+(dateTime.getMonth()+1)).substr(-2)}-${String("0"+dateTime.getDate()).substr(-2)}T${String("0"+dateTime.getHours()).substr(-2)}:${String("0"+dateTime.getMinutes()).substr(-2)}:${String("0"+dateTime.getSeconds()).substr(-2)}`
        },

        formatMachineDateToHumanDate(date) {
            if (String(date)) {
                if (String(date) == 'N/A') return 'N/A'
                return String(date).split('/').reverse().join('/')
            }
            else return null
        },

        getWeekdayFromMachineDate(date)
        {
            return this.translateWeekDay(new Date(date).getDay())
        },

        translateWeekDay(day) {
            const days =  ['Søn', 'Man', 'Tirs', 'Ons', 'Tors', 'Fre', 'Lør']
            return days[day]
        },

        getWeekNum(date) {
            let msPerDay = 86400000
            date = this.parseDate(date) //Ensure date is a js date obj
            let startOfYear = new Date(`${date.getFullYear()}-01-01`) //First of january same year as the date
            let startOfYearWeekday = (startOfYear.getDay() + 6) % 7 //Weekday of 1. jan, (mon = 0)
            startOfYear.setDate(1 - startOfYearWeekday) //Go back to Monday before 1. jan
            if (startOfYearWeekday >= 4) { //If weekday of 1. jan is thur, fri, sat or sun
                startOfYear.setDate(startOfYear.getDate()+7) //Week 1 starts the following week
            }
            let daysSinceStartOfYear = (date - startOfYear) / msPerDay + 1
            let weeksSinceStartOfYear = Math.ceil(daysSinceStartOfYear / 7)
            if (weeksSinceStartOfYear <= 0) { //The week before week 1 is either 52 or 53
                let firstJanYearBeforeWeekday = new Date(date.getFullYear()-1, 0).getDay()
                if (firstJanYearBeforeWeekday == 4) weeksSinceStartOfYear = 53 //If 1/1 is a Thursday, that year has 53 weeks
                if (firstJanYearBeforeWeekday == 3 && new Date(date.getFullYear()-1, 1, 29).getMonth() == 1) weeksSinceStartOfYear = 53 //If 1/1 is a Wednesday, and the 29/2 exists (leap year), that year has 53 weeks
                weeksSinceStartOfYear = 52 //Otherwise, a year has 52 weeks
            }
            
            return weeksSinceStartOfYear
        },

        toUserFriendlyDate(date, includeYear = true, includeWeeknum = false) {
            var str = ""
            let mDate = this.formatMachineDate(date)
            if(date){
                if (!mDate) {
                    console.error(`'${date}' could not be parsed as a date`)
                    return null //If the date cannot be formatted, return null
                }
                str += this.getWeekdayFromMachineDate(mDate)
                str += " "+this.formatMachineDateToHumanDate(mDate)
                if (!includeYear){
                    str = str.substring(0,str.indexOf("/")+3)
                }
                if (includeWeeknum) {
                    try {
                        let parsedDate = new Date (mDate)
                        // console.log(parsedDate, typeof parsedDate)
                        str += ` (Uge ${this.getWeekNum(parsedDate)})`
                    } catch (error) {
                        console.error(error)
                    }
                }
            }
            else {
                str = ""
            }
            return str
        },

        toUserFriendlyTimestamp(timestamp, includeSeconds = true, includeDate = true, includeMilliSeconds = false) {
            var str = ""
            if(timestamp){
                timestamp = this.parseDate(timestamp)
                if (includeDate) {
                    str += this.getWeekdayFromMachineDate(this.formatMachineDate(timestamp))
                    str += " "+this.formatMachineDateToHumanDate(this.formatMachineDate(timestamp))
                    str += " kl. "
                }
                // str += this.formatMachineTimestamp(timestamp).substring(this.formatMachineTimestamp(timestamp).indexOf("T")+1)
                let localTimeString = timestamp.toLocaleTimeString().replaceAll('.',':')
                if (!includeSeconds) {
                    // str = str.substring(0, str.length-3)
                    localTimeString = localTimeString.substring(0,5)
                }
                str += localTimeString
                if (includeMilliSeconds) {
                    let milliSecondsString = `000${timestamp.getMilliseconds()}`.slice(-3)
                    str += `.${milliSecondsString}`
                }
            }
            else {
                str = ""
            }
            return str
        },

        decimalToHourString(decimal, includeSeconds = false) {
            let hours = Math.floor(decimal)
            let minutes = Math.floor((decimal - hours) * 60)
            let seconds = Math.round((decimal - hours - (minutes/60)) * 3600)
            if (seconds == 60) {
                minutes += 1
                seconds = 0
            }
            let returnString = `${hours}:${String("0"+minutes).substr(-2)}`
            if (includeSeconds) {
                returnString += ':'
                returnString += String("0"+seconds).substr(-2)
            }
            return returnString
        },

        hourStringToDecimal(hoursString) {
            if (!hoursString) return 0
            let splitString = String(hoursString).split(/:|\.|;|\/|\*/g) //Splits on the following delimeters:  : . ; / * 
            let length = splitString.length
            splitString.forEach(stringPart => {
                if (isNaN(Number(stringPart))) {
                    throw new Error(`Could not parse "${stringPart}" as number, when converting hoursString`)
                }
            })
            switch(length) {
                case 1: 
                    return Number(splitString[0])
                case 2:
                    return ( Number(splitString[1]) / 60 + Number(splitString[0]) )
                case 3: 
                    return ( Number(splitString[2]) / 3600  + Number(splitString[1]) / 60 + Number(splitString[0]) )
                case 4: 
                    return ( Number(splitString[3]) / 3600000 + Number(splitString[2]) / 3600  + Number(splitString[1]) / 60 + Number(splitString[0]) )
                default: 
                    throw new Error(`An error occured when converting hoursString to decimal:\n${length} is not an expected length of splitString when converting hoursString to decimal`)
            }
        },

        timeStringToDecimal(string){
            if(string.includes(":")){
                let hours = parseInt(string.substr(0,string.indexOf(":")+1))
                let minutes = parseInt(string.substr(string.indexOf(":")))
                let minutesAsDecimal = minutes / 60
                return (hours + minutesAsDecimal)
            } else return parseInt(String)
        },

        formatTimeString(time){
            time = String(time)
            if (!time) return null
            if (time.includes(":")){
                let hours = String(parseInt(time.substr(0,time.indexOf(":")+1))).padStart(2, '0')
                let minutes = String(parseInt(time.substr(time.indexOf(":")+1))).padStart(2,'0')
                return hours+":"+minutes
            } else {
                let hours = String(parseInt(time)).padStart(2,'0')
                let minutes = "00"
                return hours+":"+minutes
            }
        },

        shortenTimeString(timeString){
            // console.log(timeString)
            if (timeString.substr(2) == ":00"){
                timeString = timeString.substr(0,2) //If minutes are 00, remove them
            }
            if (timeString.substr(0,1) == "0") {
                timeString = timeString.substr(1) //If first digit is 0, remove it
            }
            return timeString //Return whatever string remains (expected output examples: '7', '10', '7:30', '10:30')
        },

        makeTimeWindowString(date, timeFrom, timeTo){
            return this.formatMachineDate(date)+";"+this.formatTimeString(timeFrom)+";"+this.formatTimeString(timeTo)
        },

        readTimeWindowString(timeWindowString){
            if (!timeWindowString) return false
            var stringArr = timeWindowString.split(";")
            return {
                Date: stringArr[0],
                Time: {
                    From: this.shortenTimeString(stringArr[1]),
                    To: this.shortenTimeString(stringArr[2])
                }
            }

        },

        /**
         * @param {Date} date 
         * @param {Number} monthsToSubtract 
         * @returns {Date}
         */
        subtractMonths(date, monthsToSubtract) {
            const month = date.getMonth()
            date.setMonth(date.getMonth() - monthsToSubtract)

            /*
                Handles edge-case where e.g. 30th march - 1 month will return 2nd march.
                Traverses backwards until it reaches end of previous month.
            */
            while (date.getMonth() === month) {
                date.setDate(date.getDate() - 1)
            }

            return date
        },

        /**
         * @param {Date} date 
         * @param {Number} daysToSubtract 
         * @returns {Date}
         */
         subtractDays(date, daysToSubtract) {
            date.setDate(date.getDate() - daysToSubtract)
            return date
        },

        standardHoursForDate(date) {
            if (this.parseDate(date).getDay() == 5) { //Fridays
                return 7
            } else {
                return 7.5
            }
        },

    }
}
