<template>
    <sui-modal
        small
        :open="isOpen"
    >
        <sui-modal-header>
            {{ (fitterHourBefore && fitterHourBefore.ID) ? 'Redigér timeregistrering' : 'Opret timeregistrering' }}
            <small v-if="(fitterHourBefore && fitterHourBefore.ID)"><small><br>{{ `ID: ${fitterHourBefore.ID}` }}</small></small>
        </sui-modal-header>
        <sui-modal-content scrolling style="max-height: 70svh;">
            <sui-form 
                :error="!!(fitterHourBefore && fitterHourBefore.ID)"
                :loading="isLoading"
            >
                <sui-message error>
                    <sui-message-header>Redigering af timer kommer (forhåbentligt) snart!</sui-message-header>
                    <p>
                        Pga integrationen med e-komplets API er det desværre PT kun muligt at oprette nye timeregistreringer.<br>
                        Jeg er i dialog med E-komplets support om at få redigering af eksisterende timer til at fungere :-)
                    </p>
                </sui-message>
                
                <sui-form-field required>
                    <label>Dato</label>
                    <input type="date" required v-model="inputDate">
                </sui-form-field>

                <sui-form-field v-if="fitterOptions && fitterOptions.length" required>
                    <label>Montør</label>
                    <sui-dropdown
                        placeholder="Montør"
                        search selection
                        :options="fitterOptions"
                        v-model="inputFitter"
                        :disabled="!this.$parent.userHasHourCheckRole"
                    />
                </sui-form-field>
                <sui-form-field v-else required>
                    <label>Montør ID</label>
                    <input type="text" required v-model="inputFitter" :readonly="!this.$parent.userHasHourCheckRole">
                </sui-form-field>
                
                <sui-form-field v-if="projectOptions && projectOptions.length" required>
                    <label>Sag</label>
                    <sui-dropdown
                        placeholder="Sag"
                        search selection
                        :options="projectOptions"
                        v-model="inputProject"
                    />
                </sui-form-field>
                <sui-form-field v-else required>
                    <label>Sags ID</label>
                    <input type="text" required v-model="inputProject">
                </sui-form-field>

                <sui-form-field v-if="categoryOptions && categoryOptions.length" required>
                    <label>Løndel</label>
                    <sui-dropdown
                        placeholder="Løndel"
                        search selection
                        :options="categoryOptions"
                        v-model="inputCategory"
                    />
                </sui-form-field>
                <sui-form-field v-else required>
                    <label>Løndel ID</label>
                    <input type="text" required v-model="inputCategory">
                </sui-form-field>

                <sui-form-field required>
                    <label>Antal</label>
                    <input type="number" step="0.25" max="37" required v-model="inputCount">
                </sui-form-field>

                <sui-form-field>
                    <label>Note</label>
                    <textarea v-model="inputNote"></textarea>
                </sui-form-field>
            </sui-form>
        </sui-modal-content>
        <sui-modal-actions>
            <sui-button positive @click.prevent="saveHour()" :disabled="!!(fitterHourBefore && fitterHourBefore.ID)">Gem timeregistrering</sui-button>
            <sui-button negative @click.prevent="cancelEdit()">Annullér</sui-button>
        </sui-modal-actions>
    </sui-modal>
</template>
<script>
import { Mixin } from '@/lib/Mixins/mixin.js'
import { DateMixin } from '../../lib/Mixins/dateMixin'
import { FitterHourMixin } from '@/lib/Mixins/FitterHourMixin.js'
import EventBus from '../../EventBus'

export default {
    name: 'editFitterHourModal',
    mixins: [Mixin, DateMixin, FitterHourMixin],
    props: {
        fitterHourBefore: Object,
        isOpen: Boolean,
    },
    data(){
        return {
            inputDate: null,
            inputFitter: null,
            inputProject: null,
            inputCategory: null,
            inputCount: 0,
            inputNote: '',

            isLoading: false
        }
    },
    methods: {
        closeModal(){
            EventBus.$emit('edit-fitter-hour-modal-close')
        },
        prePopulateForm(hourBefore){
            if (!hourBefore) { //If no "before" value is received, clear the form
                this.inputDate = null
                this.inputFitter = null
                this.inputProject = null
                this.inputCategory = null
                this.inputCount = 0
                this.inputNote = ''
                return
            }
            console.log(hourBefore)
            this.inputDate = this.formatMachineDate(hourBefore.Date, '-')
            this.inputFitter = hourBefore.FitterID
            this.inputProject = hourBefore.ProjectID
            this.inputCategory = hourBefore.FitterCategoryID
            this.inputCount = hourBefore.Hours
            this.inputNote = hourBefore.Note
        },
        async saveHour() {
            console.log('User pressed save')
            this.isLoading = true
            try {
                await this.postFitterHours(this.updateHourDoc)
            } catch (error) {
                this.isLoading = false
                //TODO: Throw some alert to the user that something went wrong
                throw new Error(error)
            }
            this.closeModal()
            EventBus.$emit('update-fitter-hours', this.$parent.fitterName) //Trigger getting updated data after writing to database
            this.isLoading = false
        },
        cancelEdit() {
            this.prePopulateForm(null)
            this.closeModal()
        },
    },
    computed: {
        updateHourDoc() {
            const foundCategory = this.fitterCategories.find((cat) => cat.ID == this.inputCategory)
            if (!foundCategory) return null
            let dateObj = new Date(this.inputDate)
            dateObj.setUTCHours(0,0,0,0) //Default to midnight, UTC
            let hourObj = {
                "date": this.formatMachineTimestamp(dateObj),
                "fitterID": Number(this.inputFitter),
                "projectID": Number(this.inputProject),
                "fitterCategoryID": Number(this.inputCategory),
                "count": Number(this.inputCount),
                "hourRate": foundCategory.HourRate,
            }
            if (this.fitterHourBefore && this.fitterHourBefore.ID) { //Add ID if we had it from before - otherwise a new hour will be created
                hourObj.fitterHourID = this.fitterHourBefore.ID
            }
            if (this.inputNote) { //Add note if it is not empty - otherwise don't include, to minimize risk of overwriting
                hourObj.note = String(this.inputNote)
            }
            // console.log(hourObj)
            return [hourObj]
        },
        fitterOptions() {
            if (!this.fitters || !this.fitters.length) return []
            let optionsArray = []
            for (let fitter of this.fitters) {
                optionsArray.push({
                    value: fitter.ID,
                    text: `${fitter.Name}`
                })
            }
            return optionsArray
        },
        categoryOptions() {
            if (!this.fitterCategories || !this.fitterCategories.length) return []
            let optionsArray = []
            for (let category of this.fitterCategories) {
                optionsArray.push({
                    value: category.ID,
                    text: `${category.Reference}: ${category.Description}`
                })
            }
            return optionsArray
        },
        projectOptions() {
            if (!this.projects || !this.projects.length) return []
            let optionsArray = []
            for (let project of this.projects) {
                optionsArray.push({
                    value: project.ID,
                    text: `${project.ProjectReference}: ${project.Name}`
                })
            }
            return optionsArray
        },
    },
    watch: {
        isOpen: {
            handler(isOpen) {
                if (isOpen) {
                    this.prePopulateForm(this.fitterHourBefore)
                }
                this.getFitterCategories()
                this.getFitters()
                this.getProjects()
            }
        }
    }
}
</script>