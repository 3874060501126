<template>
    <sui-container>
        <sui-modal small v-model="newPostModalOpen">
            <sui-modal-header>
                Ny Feedback Post
            </sui-modal-header>
            <sui-modal-content>
                <sui-form :success="newPostFormSuccess" @submit.prevent="submitNewPost" :loading="newPostFormLoading">
                    <sui-form-field>
                        <label>Overskrift</label>
                        <input v-model="newPostName"/>
                    </sui-form-field>
                    <sui-form-field>
                        <label>Kategori</label>
                        <sui-dropdown
                            placeholder="Kategori"
                            selection
                            :options="categories"
                            v-model="newPostCategory"
                        />
                    </sui-form-field>
                    <sui-form-field>
                        <label>Beskrivelse</label>
                        <textarea rows="5" v-model="newPostDescription"></textarea>
                    </sui-form-field>
                    <sui-button type="submit" :disabled="!newPostName">Gem</sui-button>
                </sui-form>
            </sui-modal-content>
        </sui-modal>

        <sui-modal v-model="readPostModalOpen">
            <sui-modal-header v-if="activeFeedbackPost">
                {{activeFeedbackPost.Name}}
                <div :class="'ui small label right floated header '+getStatusColor(activeFeedbackPost.State)"> {{readFromDropdown(activeFeedbackPost.State,states)}} </div>
                <div class="ui right floated header"><sui-button class="tiny vote-button-modal" v-if="activeFeedbackPost.Votes.includes(currentUser.email)" basic positive @click="unvote(activeFeedbackPost)"><i class="fas fa-thumbs-up"></i></sui-button><sui-button class="tiny" v-else basic @click="upvote(activeFeedbackPost)"><i class="fas fa-thumbs-up"></i></sui-button></div>
            </sui-modal-header>
            <sui-modal-content v-if="activeFeedbackPost" class="scrolling">
                <div>
                    <sui-item-meta class="inline"> {{activeFeedbackPost.CreatedBy}} - {{toUserFriendlyTimestamp(activeFeedbackPost.CreatedAt)}} <span v-if="activeFeedbackPost.Votes">- {{activeFeedbackPost.Votes.length}} Stemme<span v-if="activeFeedbackPost.Votes.length > 1">r</span></span> </sui-item-meta>
                    &nbsp;
                    <sui-dropdown 
                        v-if="userIsSuperAdmin"
                        class="inline"
                        selection
                        :options="states"
                        v-model="selectedState"
                    />
                    <sui-button
                        class="inline"
                        v-if="selectedState"
                        positive
                        @click="saveSelectedState"
                    >
                        Gem
                    </sui-button>
                </div>
                <sui-segment>
                    <label>Beskrivelse</label><br>
                    <p class="text-body">{{activeFeedbackPost.Description}}</p>
                </sui-segment>
                <sui-segment>
                    <label>Kommentarer</label>
                    <sui-message v-for="(comment, index) in activeFeedbackPost.Comments" :key="index">
                        <sui-item-meta> {{toUserFriendlyTimestamp(comment.CreatedAt)}} - {{comment.CreatedBy}} : </sui-item-meta>
                        <p class="text-body">{{comment.Body}}</p>
                    </sui-message>
                </sui-segment>
                <sui-form :loading="newCommentFormLoading" @submit.prevent="addComment">
                    <sui-form-fields inline>
                        <input type="text" placeholder="Kommenter..." v-model="newCommentText"/>
                        <sui-button type="submit" :disabled="!newCommentText">Kommentér</sui-button>
                    </sui-form-fields>
                </sui-form>
            </sui-modal-content>
        </sui-modal>

        <DataCard title="Feedback" no-padding :actions="[
            { type: 'button', label: 'Tilføj feedback', eventName: 'open-feedback-modal' }
        ]">
            <!-- <sui-button @click="newPostModalOpen = true">Opret ny feedback-post</sui-button> -->
            <sui-table striped selectable>
                <sui-table-header>
                    <sui-table-row>
                        <sui-table-header-cell>Post</sui-table-header-cell>
                        <sui-table-header-cell>Kategori</sui-table-header-cell>
                        <sui-table-header-cell></sui-table-header-cell>
                        <sui-table-header-cell>Status</sui-table-header-cell>
                        <!-- <sui-table-header-cell><i class="fas fa-thumbs-up"></i></sui-table-header-cell> -->
                    </sui-table-row>
                </sui-table-header>
                <sui-table-body>
                    <sui-table-row v-for="post in feedBackList" :key="post.id" @click="postClicked(post)">
                        <sui-table-cell>{{post.Name}}</sui-table-cell>
                        <sui-table-cell>{{readFromDropdown(post.Category,categories)}}</sui-table-cell>
                        <sui-table-cell>
                            <div class="vote-icon">
                                <sui-label
                                    :color="post.Votes.includes(currentUser.email) ? 'green' : 'blue'"
                                    @click.stop="post.Votes.includes(currentUser.email) ? unvote(post) : upvote(post)"
                                    image
                                >
                                    <i class="fas fa-thumbs-up"></i>
                                    <sui-label-detail v-if="post.Votes">{{ post.Votes.length }}</sui-label-detail>
                                </sui-label>
                            </div>
                            <div class="vote-icon">
                                <sui-label color="yellow" image>
                                    <i class="fas fa-comments"></i>
                                    <sui-label-detail v-if="post.Comments">{{ post.Comments.length }}</sui-label-detail>
                                </sui-label>
                            </div>
                        </sui-table-cell>
                        <sui-table-cell>
                            <sui-button
                                size="mini"
                                :color="getStatusColor(post.State)"
                            >
                            {{readFromDropdown(post.State,states)}}
                            </sui-button>
                        </sui-table-cell>
                        <!-- <sui-table-cell><sui-button v-if="post.Votes.includes(currentUser.email)" basic positive @click="unvote(post)"><i class="fas fa-thumbs-up"></i></sui-button><sui-button v-else basic @click="upvote(post)"><i class="fas fa-thumbs-up"></i></sui-button></sui-table-cell> -->
                    </sui-table-row>
                </sui-table-body>
            </sui-table>
        </DataCard>
    </sui-container>
</template>

<script>
import { db, auth } from '../firebase'
import EventBus from '../EventBus'
// import TableLoader from '../components/TableLoader'
import { DataAPI } from '../lib/DataAPI'
import { Mixin } from '../lib/Mixins/mixin'
import { DateMixin } from '../lib/Mixins/dateMixin'
import UserRoles from '../lib/Enums/UserRoles'

export default {
    name: 'FeedbackPage',
    mixins: [DataAPI, Mixin, DateMixin],

    enums: {
        UserRoles
    },

    // components: {
    //     TableLoader
    // },

    data() {
        return {
            firebaseUser: {},
            feedBackList: [],
            activeFeedbackPostId: "",
            readPostModalOpen: false,

            newCommentText: "",
            newCommentFormLoading: false,

            categories: [
                {
                    text: 'Fejlrapport',
                    value: 0,
                },
                {
                    text: 'Ønsket ny feature',
                    value: 1,
                },
                {
                    text: 'Ønsket ændring af feature',
                    value: 2,
                },
                {
                    text: 'Ønsket ændring af design',
                    value: 3,
                },
            ],

            states: [
                {
                    text: 'Ny',
                    value: 0
                },
                {
                    text: 'Accepteret',
                    value: 1
                },
                {
                    text: 'Afventer',
                    value: 1.5
                },
                {
                    text: 'Planlagt',
                    value: 2
                },
                {
                    text: 'Under udvikling',
                    value: 3
                },
                {
                    text: 'Implementeret',
                    value: 4
                },
                {
                    text: 'Annuleret/Afvist',
                    value: 5
                },
            ],

            newPostModalOpen: false,
            newPostFormLoading: false,
            newPostFormSuccess: false,
            newPostName: "",
            newPostDescription: "",
            newPostCategory: "",

            selectedState: null
        }
    },

    computed: {
        currentUser(){
            return  auth.currentUser;
        },
        activeFeedbackPost(){
            return this.feedBackList.find(post => post.id == this.activeFeedbackPostId)
        },
        userIsSuperAdmin(){
            if (!this.firebaseUser && !this.firebaseUser.Roles) return false
            if (this.firebaseUser.Roles.includes(UserRoles.SUPER_ADMIN)) return true
            else return false
        }
    },

    firestore: {
        feedBackList: db.collection('Feedback').orderBy("State").orderBy("VoteCount", "desc").orderBy("CreatedAt", "desc"),
    },

    methods: {
        async submitNewPost(){
            EventBus.$emit('function-activity', {functionName: 'Feedback_submitNewPost', isActive: true})
            this.newPostFormLoading = true
            let postObj = {
                Name: this.newPostName,
                Description: this.newPostDescription,
                Category: this.newPostCategory,
                Votes: [this.currentUser.email], //Add user self to votes array
                VoteCount: 1,
                Comments: [],
                CreatedAt: this.formatMachineTimestamp(new Date()),
                State: 0
            }
            await this.dataPostFeedback(postObj)

            this.newPostName = null
            this.newPostDescription = null
            this.newPostCategory = null

            this.newPostFormLoading = false
            this.newPostFormSuccess = true
            this.newPostModalOpen = false
            EventBus.$emit('function-activity', {functionName: 'Feedback_submitNewPost', isActive: false})
        },
        postClicked(post){
            this.activeFeedbackPostId = post.id
            this.readPostModalOpen = true
        },
        readFromDropdown(n, arr){
            if (n == null || !arr) return null
            let obj = arr.find(c => c.value == n)
            return obj.text
        },
        async upvote(postObj){
            EventBus.$emit('function-activity', {functionName: 'Feedback_upvote', isActive: true})
            await this.dataUpvoteFeedback(postObj)
            EventBus.$emit('function-activity', {functionName: 'Feedback_upvote', isActive: false})
        },
        async unvote(postObj){
            EventBus.$emit('function-activity', {functionName: 'Feedback_unvote', isActive: true})
            await this.dataUnvoteFeedback(postObj)
            EventBus.$emit('function-activity', {functionName: 'Feedback_unvote', isActive: false})
        },
        async addComment(){
            EventBus.$emit('function-activity', {functionName: 'Feedback_addComment', isActive: true})
            this.newCommentFormLoading = true
            let comment = {
                CreatedAt: this.formatMachineTimestamp(new Date()),
                Body: this.newCommentText
            }
            await this.dataPostFeedbackComment(this.activeFeedbackPostId, comment)
            this.newCommentText = null
            this.newCommentFormLoading = false
            EventBus.$emit('function-activity', {functionName: 'Feedback_addComment', isActive: false})
        },
        getStatusColor(state){
            switch(state){
                case (0):
                    return "yellow "
                case (1):
                    return "blue"
                case (1.5):
                    return "grey"
                case (2):
                case (3):
                    return "teal"
                case (4):
                    return "green"
                case (5):
                    return "red"
                default: 
                    return "grey"
            }
        },
        async saveSelectedState(){
            if (!this.selectedState || !this.activeFeedbackPostId) return;
            EventBus.$emit('function-activity', {functionName: 'Feedback_saveSelectedState', isActive: true})
            if (!this.userIsSuperAdmin) {
                EventBus.$emit('function-activity', {functionName: 'Feedback_saveSelectedState', isActive: false})
                throw new Error("Only SuperAdmins can change feedback state")
            }
            await this.dataUpdateFeedbackState(this.activeFeedbackPostId, this.selectedState)
            this.selectedState = null
            EventBus.$emit('function-activity', {functionName: 'Feedback_saveSelectedState', isActive: false})
        }
    },

    watch: {
        currentUser: {
            immediate: true,
            async handler() {
                EventBus.$emit('function-activity', {functionName: 'Feedback_watch_currentUser_handler', isActive: true})
                this.firebaseUser = await this.dataGetUser(this.currentUser.email)
                EventBus.$emit('function-activity', {functionName: 'Feedback_watch_currentUser_handler', isActive: false})
            }
        }
    },

    beforeMount() {
        EventBus.$on('open-feedback-modal-click', () => {
            this.newPostModalOpen = true
        })
    },
}
</script>
<style scoped>

.table tr td {
    cursor: pointer;
}

.text-body {
    white-space: break-spaces;
}

.vote-icon {
    display: inline-block;
    margin-right: 10px;
}

.vote-icon:last-child {
    margin-right: 0;
}

.vote-button-modal {
    top: -10px;
    position: relative;
}
.inline {
    display: inline;
}
</style>
