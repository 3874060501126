<template>
    <div :class="this.classes('user-profile row')">
        
        <!-- Profile image -->
        <UserProfilePhoto
            class="profile-image col-sm-3"
            v-if="worker && worker.Photo"
            :base64ImgSrc="worker.Photo"
            :alternateText="worker.Name"
        />
        <div v-else class="profile-image col-sm-3" :style="generateStyleColorsFromName(worker.Name)">
            {{ worker.Name.substring(0, 1) }}
        </div>

        <!-- Basic info for contact -->
        <div class="user-name col-sm-9" v-if="type=='contact'">
            <span>
                <p>
                    <strong>{{ worker.Name }}</strong> <span v-if="worker.Initials">({{worker.Initials.toUpperCase()}})</span>
                    <template v-if="Array.isArray(role)">
                        <div v-for="(_role, index) in role" :key="`r-${index}`">
                            <span  :class="`ui ${roleColor(_role)} horizontal label right-label mt5`">{{ roleLabel(_role) }}</span>
                        </div>
                    </template>
                </p>
            </span>
            <p>
                <template>
                    {{ worker.Email }}
                    <a :href="'mailto:' + worker.Email"><sui-icon name="envelope" link/></a> 
                    <span v-if="worker && worker.JobTitle"> <br/> {{ worker.JobTitle }} </span>
                    <span v-if="worker && worker.LastLogin"> <br/> Sidste login: {{ toUserFriendlyTimestamp(worker.LastLogin) }} </span>
                    <template v-if="phone && Array.isArray(phone)">
                        <br />
                        <div v-for="(_phone, index) in phone" :key="index">
                            <span v-if="!!_phone">
                                {{ _phone }} 
                                <a :href="'tel:' + _phone"><sui-icon name="phone" link/></a>
                                <a v-if="checkIfMobile(_phone)" :href="'sms:' + _phone"><i class="fas fa-sms link"></i></a>
                                <span v-if="phone[index+1]"> / </span>
                            </span>
                        </div>
                    </template>
                </template>
            </p>
        </div>

        <!-- Basic info for worker -->
        <div class="user-name col-sm-9" v-else-if="type=='worker'">
                <strong>{{ worker.Name }}</strong> <span v-if="worker.Initials">({{worker.Initials.toUpperCase()}})</span>
                <span>
                    <span v-if="worker.Name=='Ukendt (Ikke fundet)'"> <br/> {{ worker.Email }} </span>
                    <span v-if="worker && worker.JobTitle"> <br/> {{ worker.JobTitle }} </span>
                    <template v-if="phone && Array.isArray(phone)">
                        <br />
                        <div v-for="(_phone, index) in phone" :key="index">
                            <span v-if="!!_phone">
                                {{ _phone }} 
                                <a :href="'tel:' + _phone"><sui-icon name="phone" link/></a>
                                <a v-if="checkIfMobile(_phone)" :href="'sms:' + _phone"><i class="fas fa-sms link"></i></a>
                                <span v-if="phone[index+1]"> / </span>
                            </span>
                        </div>
                    </template>
                </span>
        </div>

        <!-- Full info on firebase user -->
        <div class="user-name col-sm-4" v-else-if="type=='firebaseUser'">
            <strong>{{ worker.Name }}</strong> <span v-if="worker.Initials">({{worker.Initials.toUpperCase()}})</span>
            <span v-if="worker && worker.JobTitle"> <br/> {{ worker.JobTitle }} </span>
        </div>

        <div class="user-name col-sm-2" v-if="type=='firebaseUser'">
            <template>
                {{ worker.Email }}
                <a :href="'mailto:' + worker.Email"><sui-icon name="envelope" link/></a> 
                
                <template v-if="phone && Array.isArray(phone)">
                    <br />
                    <div v-for="(_phone, index) in phone" :key="index">
                        <span v-if="!!_phone">
                            {{ _phone }} 
                            <a :href="'tel:' + _phone"><sui-icon name="phone" link/></a>
                            <a v-if="checkIfMobile(_phone)" :href="'sms:' + _phone"><i class="fas fa-sms link"></i></a>
                            <span v-if="phone[index+1]"> / </span>
                        </span>
                    </div>
                </template>
            </template>
        </div>

        <div class="user-name col-sm-3" v-if="type=='firebaseUser' && worker && worker.LastLogin">
            <span> Sidste Login: <br/></span>
            <span> {{ toUserFriendlyTimestamp(worker.LastLogin) }} </span>
        </div>

        <!-- <div class="user-name col-sm-3" v-if="type=='firebaseUser'">             
            <template v-if="Array.isArray(worker.Roles)">
                <p v-for="(_role, index) in worker.Roles" :key="`r-${index}`" style="margin-bottom: 4px;">
                    <span :class="`ui ${UserRoles.getColorFromRole(_role)} horizontal label left-label mt5`">{{ UserRoles.getDescriptionFromRole(_role) }}</span>
                    <br/>
                </p>
            </template>
        </div> -->


    </div>

</template>
<script>
import { Mixin } from '../../lib/Mixins/mixin'
import { phoneMixin } from '../../lib/Mixins/phoneMixin'
import { DateMixin } from '../../lib/Mixins/dateMixin'
import ContactRole from '../../lib/Enums/ContactRole'
import UserProfilePhoto from './UserProfilePhoto.vue'
import UserRoles from '../../lib/Enums/UserRoles'

export default {
    name: 'TableWorker',
    mixins: [Mixin, DateMixin, phoneMixin],

    components: { UserProfilePhoto },

    enums: {
        ContactRole,
        UserRoles
    },

    props: {
        worker: {
            type: Object,
            required: false
        },
        role: {
            type: Array,
            required: false
        },
        type: {
            type: String,
            required: false,
            default: 'firebaseUser' // contact, firebaseUser, worker
        },
    },

    computed: {
        phone() {
            if (Array.isArray(this.worker.Phone)) return this.worker.Phone
            return [this.worker.Mobile, this.worker.Phone]
        }
    },

    methods: {
        generateStyleColorsFromName(name) {
            const imageData = this.generateUserProfileImageFromName(name)
            return {
                backgroundColor: imageData.bgColor,
                color: imageData.fgColor
            }
        },

        roleLabel(role) {
            switch(role) {
                case ContactRole.EFB_CONTACT:
                    return 'EFB Kontakt'
                
                case ContactRole.PROJECT_LEAD:
                    return 'Projekt Leder'

                default:
                    return role
            }
        },

        roleColor(role) {
            switch(role) {
                case ContactRole.EFB_CONTACT:
                    return 'green'
                
                case ContactRole.PROJECT_LEAD:
                    return 'teal'

                default:
                    return 'grey'
            }
        }
    }
}
</script>
<style scoped>
.profile-image {
    max-width: 60px;
    min-width: 60px;
    width: 60px;
    height: 60px;
    text-align: center;
    padding-top: 18px;
    border-radius: 50%;
    margin-top: 3px;
    margin-right: 10px;
}

.right-label {
    position: absolute;
    right: -25px;
}

.left-label {
    position: absolute;
    left: 0px;
}

.mt5 {
    margin-top: 5px !important;
}

.user-name {
    word-break: break-all;
    white-space: normal;
}
</style>
