import EventBus from "../../EventBus.js";

export const CoreMixin = {

    methods: {
        
        runWhenApiReady(callbackFunction){
            // Get the name of the callback function or set it to 'Anonymous Function' if it doesn't have one
            let callbackName = callbackFunction.name || 'Anonymous Function'; 
            return new Promise((resolve, reject) => {
                if (this.$root.$children[0].isReady){
                    try {
                        const result = callbackFunction();
                        resolve(result); // Resolve the promise with the result of the callback function
                    } catch (error) {
                        reject(error); // Reject the promise if the callback throws an error
                    }
                } else {
                    // Set a timeout to limit waiting to 10 seconds
                    const timeoutId = setTimeout(() => {
                        EventBus.$off('api-meta-data-loaded');
                        reject(new Error(`API did not become ready within 10 seconds. Callback function '${callbackName}' was not executed.`));
                    }, 10000);
    
                    // Listen for the 'api-meta-data-loaded' event
                    EventBus.$on('api-meta-data-loaded', () => {
                        clearTimeout(timeoutId); // Clear the timeout if the event is triggered in time

                        try {
                            const result = callbackFunction();
                            resolve(result); // Resolve the promise with the result of the callback function
                        } catch (error) {
                            reject(error); // Reject the promise if the callback throws an error
                        }
                        EventBus.$off('api-meta-data-loaded')
                    })
                }
            })
        },

        retryOperation(callbackFunction, maxRetries = 3, initialInterval = 1000, delayMultiplier = 2){
            let attempts = 0;

            let callbackName = callbackFunction.name || 'Anonymous Function'; 

            function tryOperation() {
                // Increment attempt count before executing the operation
                attempts++;

                // Execute the operation and return the result
                return callbackFunction()
                    .then((result) => {
                        // Operation was successful, return the result
                        return result;
                    })
                    .catch((error) => {
                        // If the maximum number of retries has been reached, throw the error
                        if (attempts >= maxRetries) {
                            throw new Error(`Callback function '${callbackName}' failed after ${maxRetries} attempts: ${error.message}`);
                        }

                        // Calculate the delay before the next attempt
                        const delay = initialInterval * Math.pow(delayMultiplier, attempts - 1); // Delay increases with each attempt
                        console.log(`Attempt ${attempts} failed. Retrying in ${delay} ms...`);

                        // Return a new promise that will resolve after the delay
                        return new Promise((resolve) => {
                            setTimeout(() => {
                                resolve(tryOperation());
                            }, delay);
                        });
                    });
            }

            // Start the operation
            return tryOperation();
        },

        exampleOperationWithErrorRate(errorRate = 0.1) {
            return new Promise((resolve, reject) => {
                // Simulate a failure with a error rate
                Math.random() > errorRate ? resolve('Success') : reject(new Error('Failed'));
            });
        },
         
    },
}
