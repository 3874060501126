<template>
    <div>
        <div class="ui negative message">
            <div class="header">
                Warning!
            </div>
            <p>
                Modifies appointments.<br />
                Calls PilotBi <u>once</u> per appointment.
            </p>
        </div>

        <strong>Description:</strong>
        <p>
            <sui-label color="blue">Action 1</sui-label> copies all <sui-label>/Projects/ID/Appointments</sui-label> to <sui-label>/Appointments</sui-label> and assign the given project id in the <sui-label>LinkedProjects</sui-label> field.
        </p>
        <p>
            <sui-label color="orange">Action 2</sui-label> Removes appointments from <sui-label>/Projects/ID/Appointments</sui-label> that exists globally.
        </p>
        <hr />

        <h4 is="sui-header" color="blue">
            Appointments Copy
        </h4>
        <sui-button primary size="tiny" :loading="isRunning" :disabled="isRunning" @click="triggerDry(Actions.APPOINTMENTS)">Run Dry</sui-button>
        <sui-button negative size="tiny" :loading="isRunning" :disabled="isRunning" @click="triggerNormal(Actions.APPOINTMENTS)">Run</sui-button>
        <hr />

        <h4 is="sui-header" color="blue">
            InternalSubTasks Copy
        </h4>
        <sui-button primary size="tiny" :loading="isRunning" :disabled="isRunning" @click="triggerDry(Actions.INTERNAL_SUB_TASKS)">Run Dry</sui-button>
        <sui-button negative size="tiny" :loading="isRunning" :disabled="isRunning" @click="triggerNormal(Actions.INTERNAL_SUB_TASKS)">Run</sui-button>
        <hr />

        <h4 is="sui-header" color="blue">
            Notes Copy
        </h4>
        <sui-button primary size="tiny" :loading="isRunning" :disabled="isRunning" @click="triggerDry(Actions.NOTES)">Run Dry</sui-button>
        <sui-button negative size="tiny" :loading="isRunning" :disabled="isRunning" @click="triggerNormal(Actions.NOTES)">Run</sui-button>
        <hr />

        <h4 is="sui-header" color="blue">
            TroubleTickets Appointments Copy
        </h4>
        <sui-button primary size="tiny" :loading="isRunning" :disabled="isRunning" @click="triggerDry(Actions.TT_APPOINTMENTS)">Run Dry</sui-button>
        <sui-button negative size="tiny" :loading="isRunning" :disabled="isRunning" @click="triggerNormal(Actions.TT_APPOINTMENTS)">Run</sui-button>


        <sui-button class="float right tiny" :disabled="!projectId || isRunning" @click="resetProjectId">Reset Project ID</sui-button>
    </div>
</template>
<script>
import swal from 'sweetalert'
import { db } from '../../../firebase'
import { DataAPI } from '../../../lib/DataAPI'
import TaskCode from '../../../lib/Enums/TaskCode'
import ProjectType from '../../../lib/Enums/ProjectType'
import AppointmentType from '../../../lib/Enums/AppointmentType'

const Actions = {
    APPOINTMENTS: 'action1',
    INTERNAL_SUB_TASKS: 'action2',
    NOTES: 'action3',
    TT_APPOINTMENTS: 'action4'
}

export default {
    name: 'ProjectAppointmentsToGlobalMigration',

    mixins: [DataAPI],

    enums: {
        TaskCode,
        Actions,
        ProjectType
    },

    data() {
        return {
            isRunning: false,

            dryRun: false,

            projectId: null,

            appointments: [],

            LPCEntities: [],

            notes: []
        }
    },

    methods: {
        log(message) {
            this.$emit('log', message)
        },

        resetProjectId() {
            this.projectId = null
        },

        async triggerDry(action) {
            this.dryRun = true
            await this.trigger(action)
        },

        async triggerNormal(action) {
            this.dryRun = false
            await this.trigger(action)
        },

        async trigger(action) {

            switch(action) {
                case Actions.APPOINTMENTS:
                    await this.action1()
                    break
                
                case Actions.INTERNAL_SUB_TASKS:
                    await this.action2()
                    break

                case Actions.NOTES:
                    await this.action3()
                    break

                case Actions.TT_APPOINTMENTS:
                    await this.action4()
                    break

                default:
                    throw new Error(`Unknown action "${action}"`)
            }        
        },

        async action1() {
            this.isRunning = true
            this.log(`----------------`)
            this.log(`Starting migration "ProjectAppointmentsToGlobalMigration" Action 1`)

            if (!this.projectId) {
                this.log(`Please provide Project ID`)
                this.projectId = await swal({
                    content: {
                        element: 'input',
                        attributes: {
                            placeholder: 'Project ID',
                            value: this.projectId
                        },
                    },
                })
            }

            if (!this.projectId) {
                this.log(`No Project ID provided.`)
                this.isRunning = false
                return
            }
            this.log(`Provided Project ID: ${this.projectId}`)
            
            if (this.dryRun) {
                this.log(`Dry mode - No updates will actually occur.`)
            }

            
            this.log(`Getting appointments`)
            this.appointments = await this.dataGetAppointments(this.projectId)
            this.log(`Got ${this.appointments.length} appointments`)


            Appointments:
            for (var i in this.appointments) {
                this.log(`----`)
                const app = this.appointments[i]
                this.log(`Appointment #${i}, ID ${app.id}, label: ${app.InstallationLabel}`)

                if (!(app.ProjectTasks && app.ProjectTasks[0] && app.ProjectTasks[0].Id)) {
                    this.log('Appointment does not contain a valid ProjectTask. Skipping...')
                    continue Appointments
                }

                const installationTask = await this.dataGetProjectTaskWithoutNotes(app.ProjectTasks[0].Id)

                if (!installationTask?.configurationItem?.area?.sonWinProjectId) {
                    this.log('ProjectTask from Pilotbi does not contain sonWinProjectId. Skipping...')
                    continue Appointments
                }

                // Construct new object
                let newSubTasksObject = {
                    ...app,
                    AreaCode: installationTask.configurationItem.area.sonWinProjectId,
                    ProjectInstallationType: ProjectType.LATE_SIGNUP,
                    LinkedProjects: [this.projectId]
                }

                // Remove old/not-used props.
                if (newSubTasksObject.id) delete newSubTasksObject.id
                if (newSubTasksObject.ProjectType) delete newSubTasksObject.ProjectType

                if (!this.dryRun) {
                    this.log('Saving new appointment in global appointment')
                    await this.dataAddOrUpdateAppointment(this.projectId, null, newSubTasksObject)
                } else {
                    console.log(newSubTasksObject)
                    this.log('[Dry] Fake saving data *See browser console for output*')
                }

                this.log('Checking /Appointments for the newly copied appointment.')
                let checkAppointment = this.dataGetAppointmentByLabelAndProjectIdAndType(app.InstallationLabel, this.projectId, AppointmentType.INSTALLATION)

                if (!this.dryRun && !checkAppointment) {
                    this.log(`Error: Appointment not found in global collection!`)
                    continue Appointments
                }
                
                if (this.dryRun) {
                    this.log('Appointment should be copied.')
                } else {
                    this.log('Appointment was copied successfully.')
                }

                // Get all Project/ID/Appointments
                // Iterate through them
                    // Call pilotbi, get Area Code and ProjectType from Task 
                    // Create object with data from firebase and pilotbi with LinkedProjects property
                    // Save in new firebase collection.

                    // Look in /Appointments if entry exists with same label, project id and type.

                // End
            }

            this.isRunning = false
        },


        async action2() {
            this.isRunning = true
            this.log(`----------------`)
            this.log(`Starting migration "LPCToGlobalMigration" Action 2`)

            if (!this.projectId) {
                this.log(`Please provide Project ID`)
                this.projectId = await swal({
                    content: {
                        element: 'input',
                        attributes: {
                            placeholder: 'Project ID',
                            value: this.projectId
                        },
                    },
                })
            }

            if (!this.projectId) {
                this.log(`No Project ID provided.`)
                this.isRunning = false
                return
            }
            this.log(`Provided Project ID: ${this.projectId}`)

            if (this.dryRun) {
                this.log(`Dry mode - No updates will actually occur.`)
            }

            this.log(`Getting Tasks from Pilotbi`)
            const _openTasks = await this.dataGetProjectTasks()
            const openTasks = _openTasks.projectTasks.reduce(this.taskReducer, {})
            console.log(openTasks)

            const _closedTasks = await this.dataGetClosedProjectTasks(new Date('2020-12-01T00:00:00.00Z'))
            const closedTasks = _closedTasks.projectTasks.reduce(this.taskReducer, {})
            console.log(closedTasks)

            const tasks = Object.assign(openTasks, closedTasks)
            console.log(tasks)

            this.log(`Getting Internal subtasks`)
            this.LPCEntities = []
            let snapshot = await db.collection(`Projects/${this.projectId}/InternalSubTasks`).get()
            snapshot.forEach(doc => {
                let data = doc.data()
                data.id = doc.id
                this.LPCEntities.push(data)
            })
            this.log(`Got ${this.LPCEntities.length} entities.`)

            console.log(this.LPCEntities)

            let skippedEntities = []

            Entities:
            for (var i in this.LPCEntities) {
                this.log(`----`)
                const LPCEntity = this.LPCEntities[i]
                this.log(`Entity #${i}, ID ${LPCEntity.id}, label: ${LPCEntity.InstallationLabel}`)
                const task = tasks[LPCEntity.InstallationLabel]
                if (!task) {
                    this.log(`Task is undefined. Skipping...`)
                    skippedEntities.push(LPCEntity.InstallationLabel)
                    continue Entities
                }
                this.log(`Task no: ${task.number}`)

                if (!task?.configurationItem?.area?.sonWinProjectId) {
                    this.log('ProjectTask from Pilotbi does not contain sonWinProjectId. Skipping...')
                    skippedEntities.push(LPCEntity.InstallationLabel)
                    continue Entities
                }

                // Construct new object
                let newSubTasksObject = {
                    ...LPCEntity,
                    AreaCode: task.configurationItem.area.sonWinProjectId,
                    ProjectInstallationType: ProjectType.LATE_SIGNUP,
                    LinkedProjects: [this.projectId]
                }

                // Remove old/not-used props.
                if (newSubTasksObject.id) delete newSubTasksObject.id

                if (!this.dryRun) {
                    this.log('Saving new appointment in global appointment')
                    await db.collection('InternalSubTasks').doc(LPCEntity.InstallationLabel).set(newSubTasksObject)
                } else {
                    console.log(newSubTasksObject)
                    this.log('[Dry] Fake saving data *See browser console for output*')
                }

                this.log('Checking /InternalSubTasks for the newly copied appointment.')
                // let checkAppointment = this.dataGetAppointmentByLabelAndProjectIdAndType(LPCEntity.InstallationLabel, this.projectId, AppointmentType.INSTALLATION)
                let checkSubTasks = await db.collection('InternalSubTasks').doc(LPCEntity.InstallationLabel).get()

                if (!this.dryRun && !checkSubTasks.exists) {
                    this.log(`Error: SubTasks not found in global collection!`)
                    continue Entities
                }
                
                if (this.dryRun) {
                    this.log('SubTasks should be copied.')
                } else {
                    this.log('SubTasks was copied successfully.')
                }

                // Get all Project/ID/Appointments
                // Iterate through them
                    // Call pilotbi, get Area Code and ProjectType from Task 
                    // Create object with data from firebase and pilotbi with LinkedProjects property
                    // Save in new firebase collection.

                    // Look in /Appointments if entry exists with same label, project id and type.

                // End
            }

            this.log('---------------')
            this.log('Skipped Entities: ')
            this.log(skippedEntities.join(', '))

            this.isRunning = false
        },


        async action3() {
            this.isRunning = true
            this.log(`----------------`)
            this.log(`Starting migration "LPCToGlobalMigration" Action 3`)

            if (!this.projectId) {
                this.log(`Please provide Project ID`)
                this.projectId = await swal({
                    content: {
                        element: 'input',
                        attributes: {
                            placeholder: 'Project ID',
                            value: this.projectId
                        },
                    },
                })
            }

            if (!this.projectId) {
                this.log(`No Project ID provided.`)
                this.isRunning = false
                return
            }
            this.log(`Provided Project ID: ${this.projectId}`)

            if (this.dryRun) {
                this.log(`Dry mode - No updates will actually occur.`)
            }

            this.log(`Getting Internal notes`)
            this.notes = []
            let snapshot = await db.collection(`Projects/${this.projectId}/Notes`).get()
            snapshot.forEach(doc => {
                let data = doc.data()
                data.id = doc.id
                this.notes.push(data)
            })
            this.log(`Got ${this.notes.length} notes.`)

            console.log(this.notes)

            Notes:
            for (var i in this.notes) {
                this.log(`----`)
                const note = this.notes[i]
                this.log(`Note #${i}, ID ${note.id}, label: ${note.configurationItemLabel}`)
                
                // Construct new object
                let newNoteObject = {
                    ...note
                }

                // Remove old/not-used props.
                if (newNoteObject.id) delete newNoteObject.id

                let res;
                if (!this.dryRun) {
                    this.log('Saving new note in global note collection')
                    res = await db.collection('Notes').add(newNoteObject)
                } else {
                    console.log(newNoteObject)
                    this.log('[Dry] Fake saving data *See browser console for output*')
                }

                this.log('Checking /Notes for the newly copied note.')

                if (!this.dryRun && !res.id) {
                    this.log(`Error: Note was not found in global collection!`)
                    continue Notes
                }
                
                if (this.dryRun) {
                    this.log('Note should be copied.')
                } else {
                    this.log('Note was copied successfully.')
                }
            }

            this.log('---------------')
            this.isRunning = false
        },

        async action4() {
            this.isRunning = true
            this.log(`----------------`)
            this.log(`Starting migration "ProjectAppointmentsToGlobalMigration" Action 4`)

            if (!this.projectId) {
                this.log(`Please provide Project ID`)
                this.projectId = await swal({
                    content: {
                        element: 'input',
                        attributes: {
                            placeholder: 'Project ID',
                            value: this.projectId
                        },
                    },
                })
            }

            if (!this.projectId) {
                this.log(`No Project ID provided.`)
                this.isRunning = false
                return
            }
            this.log(`Provided Project ID: ${this.projectId}`)
            
            if (this.dryRun) {
                this.log(`Dry mode - No updates will actually occur.`)
            }

            
            this.log(`Getting appointments`)
            let _appointments = await db.collection(`Projects/${this.projectId}/Appointments`).get()
            let appointmentArray = []
            _appointments.forEach(a => {
                appointmentArray.push({
                    id: a.id, 
                    ...a.data()
                })
            })
            this.appointments = appointmentArray
            this.log(`Got ${this.appointments.length} appointments`)


            Appointments:
            for (var i in this.appointments) {
                this.log(`----`)
                const app = this.appointments[i]
                this.log(`Appointment #${i}, ID ${app.id}, label: ${app.InstallationLabel}`)

                if (!(app.ProjectTasks && app.ProjectTasks[0] && app.ProjectTasks[0].Id)) {
                    this.log('Appointment does not contain a valid ProjectTask. Skipping...')
                    continue Appointments
                }

                const installationTask = await this.dataGetTroubleTicketWithoutNotes(app.ProjectTasks[0].Id)

                if (!installationTask?.installation?.area?.sonWinProjectId) {
                    this.log('ProjectTask from Pilotbi does not contain sonWinProjectId. Skipping...')
                    continue Appointments
                }

                // Construct new object
                let newSubTasksObject = {
                    ...app,
                    AreaCode: installationTask.installation.area.sonWinProjectId,
                    ProjectInstallationType: ProjectType.TROUBLE_TICKETS,
                    LinkedProjects: [this.projectId]
                }

                // Remove old/not-used props.
                if (newSubTasksObject.id) delete newSubTasksObject.id
                if (newSubTasksObject.ProjectType) delete newSubTasksObject.ProjectType

                if (!this.dryRun) {
                    this.log('Saving new appointment in global appointment')
                    await this.dataAddOrUpdateAppointment(this.projectId, null, newSubTasksObject)
                } else {
                    console.log(newSubTasksObject)
                    this.log('[Dry] Fake saving data *See browser console for output*')
                }

                this.log('Checking /Appointments for the newly copied appointment.')
                let checkAppointment = this.dataGetAppointmentByLabelAndProjectIdAndType(app.InstallationLabel, this.projectId, AppointmentType.TICKET)

                if (!this.dryRun && !checkAppointment) {
                    this.log(`Error: Appointment not found in global collection!`)
                    continue Appointments
                }
                
                if (this.dryRun) {
                    this.log('Appointment should be copied.')
                } else {
                    this.log('Appointment was copied successfully.')
                }

                // Get all Project/ID/Appointments
                // Iterate through them
                    // Call pilotbi, get Area Code and ProjectType from Task 
                    // Create object with data from firebase and pilotbi with LinkedProjects property
                    // Save in new firebase collection.

                    // Look in /Appointments if entry exists with same label, project id and type.

                // End
            }

            this.isRunning = false
        },

        // async action4() {

        //     this.log(`----------------`)
        //     this.log(`Starting migration "ProjectAppointmentsToGlobalMigration" Action 2`)

        //     this.log('Asking for user confirmation before deletion.')

        //     let dryRunMessage = ''
        //     if (this.dryRun) {
        //         dryRunMessage = 'Dry run enabled, nothing will get deleted.'
        //         this.log(dryRunMessage)
        //     }

        //     let confirm = await swal({
        //         content: {
        //             text: 'Confirm by typing "delete" in the box below.' + dryRunMessage,
        //             element: 'input',
        //             attributes: {
        //                 placeholder: 'Type delete here',
        //                 value: this.projectId
        //             },
        //         },
        //     })

        //     if (confirm != 'delete') {
        //         swal('Did not confirm. Stopping task.')
        //         this.log('Did not confirm. Stopping task.')
        //         return
        //     }

        //     this.log('Continuing... Not implemented yet.')
        // },

        taskReducer(map, obj) {
            // if (obj.code == 'BOOKKUNDE' || obj.code == 'CPE') { // Removed because `code` is null on closed tasks.
                map[obj.configurationItem.label] = obj
            //}
            return map
        }
    }
}
</script>
<style scoped>
.float.right {
    float: right;
}

h4 {
    margin-top: 0 !important;
}
</style>