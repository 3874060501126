export const pdfGeneratorMixin = {
    data() {
        return {
            imageRefCount: 0
        }
    },
    methods: {

        /**
         * 
         * @param {String} componentType Name of the component type to generate, e.g. 'header', 'description', 'image', 'yesNoList'
         * @param  {...any} params Parameters to be passed to the relevant function
         * @returns {Object} pdfMake object with relevant properties and styles
         */
        generatePdfMakeObject(componentType, component) {
            let output = {};
            switch (componentType) {
                case 'documentTitle':
                    output = this.generatePdfMakeDocumentTitle(component);
                    break;
                case 'header':
                    output = this.generatePdfMakeHeader(component);
                    break;
                case 'description':
                    output = this.generatePdfMakeDescription(component);
                    break;
                case 'image':
                    output = this.generatePdfMakeImage(component);
                    break;
                case 'yesNoList':
                    output = this.generatePdfMakeYesNoList(component);
                    break;
                case 'contactTable':
                    output = this.generatePdfMakeContactTable(component);
                    break;
                case 'dualContactTable':
                    output = this.generatePdfMakeDualContactTable(component);
                    break;
                case 'printSignature':
                    output = this.generatePdfMakePrintSignature(component);
                    break;
                default:
                    output = this.generatePdfMakeDescription(`Component type not recognized: "${componentType}"\n\n${JSON.stringify(component,null,4)},'ERR: Kunne ikke rendere komponentet.'`)
                    break;
            }
            return output;
        },

        /**
         * 
         * @param {Object} component Object with data property containing text and hierarchy properties 
         * @returns {Object} Formatted pdfMake text object with relevant style
         */
        generatePdfMakeDocumentTitle(component) {
            if (!component.data?.text) throw new Error('No text property found in document title component data');
            return {
                text: component.data.text,
                style: component.data.headerHierarchy ? component.data.headerHierarchy : 'pagetitle',
                pageBreak: component.pageBreakBefore ? 'before' : ''
            }
        },

        /**
         * 
         * @param {Object} component Object with data property containing text and hierarchy properties 
         * @returns {Object} Formatted pdfMake text object with relevant style
         */
        generatePdfMakeHeader(component) {
            if (!component.data?.text) throw new Error('No text property found in header component data');
            return {
                text: component.data.text,
                style: component.data.headerHierarchy ? component.data.headerHierarchy : 'header',
                pageBreak: component.pageBreakBefore ? 'before' : ''
            }
        },

        /**
         * 
         * @param {String} text 
         * @returns {Array} Array of pdfMake text objects with text and style properties, where style is 'bold' or 'italic'
         */
        generateInlineFormattedText(text) {

            console.group('Generating formatted text for:', text);
            //Define regex patterns for formatting tags
            const formattingTagsRegex = /<b>|<\/b>|<f>|<\/f>|<i>|<\/i>|<k>|<\/k>/gi
            const boldStartTagRegex = /<b>|<f>/i;
            const boldEndTagRegex = /<\/b>|<\/f>/i;
            const italicStartTagRegex = /<i>|<k>/i;
            const italicEndTagRegex = /<\/i>|<\/k>/i;

            let formattingTagsArr = text.match(formattingTagsRegex);
            let textArr = text.split(formattingTagsRegex);

            if (!formattingTagsArr) return [{text: text}]; //If no formatting tags are found, return the text as a single span object

            let currentlyBold = false;
            let currentlyItalic = false;
            let spanArr = [];

            console.log(`Generating formatted text for: "${text}", formattingTagsArr:`, formattingTagsArr, 'textArr:', textArr, 'indexOf first tag:', text.indexOf(formattingTagsArr[0]));
            if (text.indexOf(formattingTagsArr[0]) > 0) {
                spanArr.push({text: textArr[0]}); //Add the text before the first formatting tag to the span array
                console.log('textArr after shift:', textArr);
            }
            textArr.shift(); //Remove the first element from the textArr - If the first character is a formatting tag, the first element will be an empty string, and if not, it has already been added to the span array above
            for (let i = 0; i < textArr.length; i++) { //Iterate through the textArr and add the text and formatting tags to the span array
                console.log('i:', i, 'text: "', textArr[i], '" formattingTag:', formattingTagsArr[i]);
                if ( !formattingTagsArr[i] ) {
                    console.error('No formatting tag found for text:', textArr[i]);
                    continue;
                }
                if (formattingTagsArr[i].match(boldStartTagRegex)) {
                    if (currentlyBold) console.warn('Bold tag found inside another bold tag: "', textArr[i], '" - this may result in unexpected formatting');
                    currentlyBold = true;
                } else if (formattingTagsArr[i].match(boldEndTagRegex)) {
                    if (!currentlyBold) console.warn('Bold end tag not matched to start tag: "', textArr[i], '" - this may result in unexpected formatting');
                    currentlyBold = false;
                } else if (formattingTagsArr[i].match(italicStartTagRegex)) {
                    if (currentlyItalic) console.warn('Italic tag found inside another italic tag: "', textArr[i], '" - this may result in unexpected formatting');
                    currentlyItalic = true;
                } else if (formattingTagsArr[i].match(italicEndTagRegex)) {
                    if (!currentlyItalic) console.warn('Italic end tag not matched to start tag: "', textArr[i], '" - this may result in unexpected formatting');
                    currentlyItalic = false;
                }

                if (textArr[i] != ''){ //If the text is not an empty string, add it to the span array
                    spanArr.push({text: textArr[i], bold: currentlyBold, italics: currentlyItalic});
                }
            }

            console.groupEnd()

            return spanArr;
        },

        /**
         * 
         * @param {Object} component Object with data property containing bodyText (required) and headerText (optional) properties
         * @returns {Object} pdfMake Stack object containing 2 pdfMake text objects with relevant styles
         */
        generatePdfMakeDescription(component) {
            let stackArray = []
            
            if (component.data?.headerText) {
                let hierarchy = component.data.headerHierarchy ? component.data.headerHierarchy : 'header';
                stackArray.push(this.generatePdfMakeHeader({data: {text: component.data.headerText, headerHierarchy: hierarchy}}));
            }

            if (component.data?.bodyText === '') {
                component.data.bodyText = '--- Der er endnu ikke skrevet nogen beskrivelse ---';
            }

            if (!component.data?.bodyText || typeof component.data.bodyText != 'string') throw new Error('No bodyText string found in description component data');

            let bodyArr = component.data.bodyText.split(/\n+/g);
            // console.log(bodyArr)

            for (let line of bodyArr) { // Each line in the bodyText is processed separately
                stackArray.push({
                    text: this.generateInlineFormattedText(line),
                    style: 'body'
                });
            }
            // console.log(stackArray)

            return {stack: stackArray, pageBreak: component.pageBreakBefore ? 'before' : ''};
        },

        /**
         * 
         * @param {Object} component Object with data property containing imageURL, imageText (String) and renderingOption (largeImage | smallImage | largeImageWithoutText) properties
         * @returns {Object} pdfMake Stack of pdfMake objects with relevant properties and styles
         */
        generatePdfMakeImage(imageRef, imageText, renderingOption) {
            let output = {};
            if (renderingOption == 'largeImage' || renderingOption == 'largeImageWithoutText') {
                let stackArray = [];
                stackArray.push({
                    image: imageRef,
                    fit: [400, 300],
                    alignment: 'center',
                    margin: [0, 5, 0, 0], // [left, top, right, bottom]
                });
                if (renderingOption == 'largeImage') {
                    stackArray.push({
                        text: this.generateInlineFormattedText(imageText),
                        style: 'body'
                    });
                }
                output.stack = stackArray;
            } else if (renderingOption == 'smallImage') {

                output.layout = 'noBorders';
                output.table = {
                    headerRows: 0,
                    dontBreakRows: true,
                    widths: ['auto', 'auto'],
                    body: [
                        [
                            {image: imageRef, fit: [250, 300], alignment: 'left'},
                            {text: this.generateInlineFormattedText(imageText), style: 'body', width: '*'}
                        ]
                    ]
                }

                // For some reason, the following code does not guarantee that the image and the text is on the same page in the PDF
                // output.stack = [{
                //     columns: [
                //         {image: imageRef, fit: [250, 300], alignment: 'left'},
                //         {text: imageText, style: 'body', width: '*'}
                //     ],
                //     columnGap: 10
                // }];
            }
            return output;
        },

        generatePdfMakeImages(component) {
            let output = {
                stack: [],
                pageBreak: component.pageBreakBefore ? 'before' : ''
            };
            let imageRefs = {};

            if (!component.data?.images || !Array.isArray(component.data.images)) throw new Error('No images array found in images component data');

            for (let image of component.data.images) {
                if (!image.imageURL) throw new Error('No imageURL property found in image object');
                if (!image.includeInPdf) continue;
                let imageRef
                if (Object.values(imageRefs).includes(image.imageURL)) {
                    imageRef = Object.keys(imageRefs).find(key => imageRefs[key] === image.imageURL);
                } else if (Object.values(this.pdfDefinition.images).includes(image.imageURL)) {
                    imageRef = Object.keys(this.pdfDefinition.images).find(key => this.pdfDefinition.images[key] === image.imageURL);
                } else {
                    this.imageRefCount++;
                    imageRef = `${this.imageRefCount.toString(16)}`; //The count of all images generated so far, in hexadecimal, is used as the unique reference for the image
                    imageRefs[`${imageRef}`] = image.imageURL;
                }
                output.stack.push(this.generatePdfMakeImage(imageRef, image.imageText, image.renderingOption));
            }
            this.pdfDefinition.images = {...this.pdfDefinition.images, ...imageRefs}; //Add the new image references to the pdfDefinition object
            return output;
        },

        /**
         * 
         * @param {Object} component Objects containing data property, with 'list': Array of objects with text and value properties, where value is a boolean
         * @returns {Object} pdfMake Table object with 2 columns, one for text and one for the boolean value
         */
        generatePdfMakeYesNoList(component) {
            if (!component.data?.list || !Array.isArray(component.data.list)) throw new Error('No list array found in yesNoList component data');

            let layout = component.data?.layout ? component.data.layout : 'noBorders';
            let leftColumnWidth = component.data?.leftColumnWidth ? component.data.leftColumnWidth : 'auto';

            let output = {
                layout,
                table: {
                    headerRows: 0,
                    widths: [leftColumnWidth, 'auto'],
                    body: []
                },
                pageBreak: component.pageBreakBefore ? 'before' : ''
            }
            for (let yesNoObj of component.data.list) {
                output.table.body.push([
                    {text: this.generateInlineFormattedText(yesNoObj.text), style: 'body'}, 
                    {text: yesNoObj.value ? 'Ja' : 'Nej', style: yesNoObj.value ? 'trueStyle' : 'falseStyle'}
                ]);
            }
            return output;
        },

        /**
         * 
         * @param {Object} component Object with data property containing headerText and renderedKeys properties, and key-value pairs of contact information
         * @returns {Object} pdfMake Stack object with a header and a table containing the contact information
         */
        generatePdfMakeContactTable(component) {
            let outputObj = {
                stack: [],
                pageBreak: component.pageBreakBefore ? 'before' : ''
            }
            let headerHierarchy = component.data.headerHierarchy ? component.data.headerHierarchy : 'subheader';
            if (component.data.headerText) {
                outputObj.stack.push(this.generatePdfMakeHeader({data: {text: component.data.headerText, headerHierarchy}}));
            }
            let tableDefinition = {
                layout: 'noBorders',
                table: {
                    headerRows: 0,
                    widths: ['auto', '*'],
                    body: []
                }
            }
            for (let renderedKey of component.data.renderedKeys) {
                if (typeof component.data[renderedKey] == 'undefined') {
                    throw new Error(`No value found for key '${renderedKey}' in contact object`);
                }
                tableDefinition.table.body.push([
                    {text: `${renderedKey}:`, style: 'tableBody'}, 
                    {text: component.data[renderedKey], style: 'tableBody'}
                ])
            }
            outputObj.stack.push(tableDefinition);
            return outputObj;
        },

        /**
         * 
         * @param {Object} contactObj1 Object with key-value pairs of contact information to be displayed in the left column
         * @param {Object} contactObj2 Object with key-value pairs of contact information to be displayed in the right column
         * @param {String} headerText1 Optional text to be displayed as a header above the left column
         * @param {String} headerText2 Optional text to be displayed as a header above the right column
         * @param {String} rightColumnWidth Optional width of the right column, e.g. '"auto"' or '100'
         * @returns {Object} pdfMake Stack object with 2 columns, each containing a header and a table with contact information
         */
        generatePdfMakeDualContactTable(component) {
            let leftTableComponent = {
                data: component.data[component.data.left]
            }
            let rightTableComponent = {
                data: component.data[component.data.right]
            }
            return {
                stack: [
                    {
                        columns: [
                            {
                                width: '*',
                                ...this.generatePdfMakeContactTable(leftTableComponent)
                            },
                            {
                                width: component.data.rightColumnWidth ? component.data.rightColumnWidth : 'auto',
                                ...this.generatePdfMakeContactTable(rightTableComponent)
                            }
                        ],
                        columnGap: 10
                    }
                ],
                pageBreak: component.pageBreakBefore ? 'before' : ''
            }
        },

        generatePdfMakePrintSignature(component) {
            let contactFormLine = '______________________'
            let output = {
                stack: [
                    this.generatePdfMakeHeader({data: {text: 'Godkendelse af aftalen', headerHierarchy: 'subheader'}}),
                    {
                        text: 'Med sin underskrift bekræfter kunden at have bestilt arbejdet, og giver samtidigt tilladelse til at vores entreprenør må grave på kundens grund, af den aftalte føringsvej frem til husmuren.\n\nUnderskriver bekræfter også at være ejer af grunden, eller have ejers fuldmagt til at godkende aftalen.',
                        style: 'body'
                    },
                    {
                        columns: [
                            this.generatePdfMakeContactTable({ data: {
                                'Underskrivers Navn': component.data.signerName || contactFormLine,
                                'Email': component.data.signerEmail || contactFormLine,
                                'Tlf': component.data.signerPhone || contactFormLine,
                                renderedKeys: ['Underskrivers Navn', 'Email', 'Tlf']
                            }}),
                            {
                                width: 'auto',
                                stack: [
                                    {
                                        text: 'Underskift:         Dato: ____/____/________',
                                        style: ['body', 'rightAlign']
                                    },
                                    {
                                        text: '______________________________________',
                                        style: ['signatureLine', 'rightAlign']
                                    }
                                ]
                            }
                        ]
                    }
                ],
                pageBreak: component.pageBreakBefore ? 'before' : ''
            }
            if (component.data.absoluteYPosition) {
                output.absolutePosition = {x: 50, y: component.data.absoluteYPosition};
                output.style = 'absolutePositionBlock';
            }
            return output;
        }
    }
}