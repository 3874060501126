import { LPCTerminology, LPCType } from './Enums/LPCTypes'
import { db, firestore, cloudFunctions } from '../firebase'
import EventBus from '../EventBus'

export const LPCMixin = {
    
    data() {
        return {
            deleteDocumentCount: 1000, //To be used for a progress bar - starts at arbitrary high number, is set accurately later
            deleteHandledDocs: 0,
        }
    },

    computed: {
        progress(){
            return Math.round(this.handledDocs/this.documentCount*100)
        }
    },

    methods: {
        async LCPDeleteAssociationToProject(projectId, LPCtype) {
            EventBus.$emit('function-activity', {functionName: 'LPCMixin_LCPDeleteAssociationToProject', isActive: true})
            let data = await db.collection(LPCTerminology[LPCtype].collectionPath)
                .where('LinkedProjects', 'array-contains', projectId).get()
            this.documentCount = data.size
            let failedDocDeletes = []
            
            for (let i in data.docs) {
                this.deleteHandledDocs = i
                let document = data.docs[i]
                let docData = document.data()

                try {
                    await db.collection(LPCTerminology[LPCtype].collectionPath).doc(document.id).update({
                        LinkedProjects: firestore.FieldValue.arrayRemove(projectId) //Remove the current project from linkedProjects
                    })
                }
                catch(err) {
                    console.error(err)
                    failedDocDeletes.push(docData)
                    continue
                }

                if (docData.LinkedProjects.length == 1 && docData.LinkedProjects[0] == projectId) { //If the project we're deleting was the only linked project
                    db.collection(LPCTerminology[LPCtype].collectionPath).doc(document.id).delete() //Delete the LPC from firestore
                }

            }
            EventBus.$emit('function-activity', {functionName: 'LPCMixin_LCPDeleteAssociationToProject', isActive: false})
        },

        async LPCDeleteProject(projectId) {
            EventBus.$emit('function-activity', {functionName: 'LPCMixin_LPCDeleteProject', isActive: true})
            let promiseArray = []
            promiseArray.push(this.LCPDeleteAssociationToProject(projectId, LPCType.APPOINTMENT))
            // promiseArray.push(this.LCPDeleteAssociationToProject(projectId, LPCType.COORDINATE))
            // promiseArray.push(this.LCPDeleteAssociationToProject(projectId, LPCType.INTERNALSUBTASK))
            await Promise.all(promiseArray)
            let deleteHistory = cloudFunctions.httpsCallable('deleteHistory') //.call({'projectId': projectId}) //Delete the 'history' subcollection
            deleteHistory({projectId}).then(() => console.log('successfully deleted History subcollection')).catch((err) => console.error(err)) //Delete the 'history' subcollection
            //Once all the above are completed, delete the actual project document
            EventBus.$emit('function-activity', {functionName: 'LPCMixin_LPCDeleteProject', isActive: false})
            return db.collection('Projects').doc(projectId).delete()
        },
    }
}