<template>
    <sui-modal
        id="smsModal"
        :title="title"
        :open="isOpen"
        v-on:clickAwayModal="clickAway"
    >
        <sui-modal-header>
            {{phoneNumber}} - {{title}}
            <span v-if="isDevelopmentEnv()">({{ formattedPhoneNumber }})</span>
        </sui-modal-header>
        <sui-modal-content>
            <div class="container">
                <div class="row">
                    <div class="col-sm-6">
                        <h3>SMS-historik</h3>
                        <sui-segment :loading="historyLoading" class="historySegment">
                            <div class="message-wrapper" v-for="(message, index) in history" :key="index">
                                <p class="message">{{message.body}}</p>
                                <span class="message-time">{{toUserFriendlyTimestamp(message.dateUpdated)}} - {{message.status}}</span>
                            </div>
                            <div class="no-messages" v-if="!history.length && !historyLoading">
                                <em>Ingen sendte beskeder..</em>
                            </div>
                        </sui-segment>
                    </div>
                    <div class="col-sm-6">
                        <h3>Send ny besked</h3>
                        <sui-form :loading="formLoading"  @submit.prevent="sendSmsViaCloud">
                            <sui-form-field>
                                <textarea rows="3" placeholder="Skriv din besked her.." v-model="smsBody"/>
                            </sui-form-field>
                            <sui-button type="submit">Send</sui-button>
                        </sui-form>
                    </div>
                </div>
            </div>
        </sui-modal-content>
    </sui-modal>
</template>
<script>
import { cloudFunctions } from '../../firebase'
import { DataAPI } from '../../lib/DataAPI'
import { Mixin } from '../../lib/Mixins/mixin'
import { DateMixin } from '../../lib/Mixins/dateMixin'
import EventBus from '../../EventBus'

export default {
    mixins: [DataAPI, DateMixin, Mixin],

    props: {
        title: String,
        phoneNumber: String,
        isOpen: Boolean
    },

    data() {
        return {
            formattedPhoneNumber: "",

            smsBody: "",

            formLoading: false,

            historyLoading: false,
            history: [],
            historyError: {
                active: false,
                message: "Error in getting message history"
            }
        }
    },

    watch: {
        phoneNumber: {
            immediate: false,
            handler(phoneNumber) {
                if (this.isDevelopmentEnv()) {
                    this.formattedPhoneNumber = "+4530559140"
                } else {
                    this.formattedPhoneNumber = this.parsePhoneNumber(phoneNumber)
                }
                console.log(phoneNumber)
                this.getSmsHistory()
            },
        }
    },

    methods: {
        async sendSmsViaCloud(){
            //Start formLoading animation
            EventBus.$emit('function-activity', {functionName: 'SmsModal_sendSmsViaCloud', isActive: true})
            this.formLoading = true

            //Send message via firebase cloud function
            const sendSms = cloudFunctions.httpsCallable('sendSms')
            let sendSmsRsult = await sendSms({phoneTo: this.formattedPhoneNumber, body: this.smsBody})
            console.log(sendSmsRsult)

            //Clear modal content
            this.smsBody = ""

            //Reload history
            await this.getSmsHistory()

            //Close modal
            // EventBus.$emit('sms-modal-closing')

            //End formLoading animation
            this.formLoading = false
            EventBus.$emit('function-activity', {functionName: 'SmsModal_sendSmsViaCloud', isActive: false})
        },
        async getSmsHistory(){
            //Start historyLoading animation
            EventBus.$emit('function-activity', {functionName: 'SmsModal_getSmsHistory', isActive: true})
            this.historyLoading = true
            
            //Get history via firebase cloud function
            try {
                const firebaseGetSmsHistory = cloudFunctions.httpsCallable('getSmsHistory')
                let historyResult = await firebaseGetSmsHistory({phoneTo: this.formattedPhoneNumber})
                console.log(historyResult)

                //Render history to screen
                this.history = historyResult.data
            }
            catch (error) {
                console.error(error)
            } 

            //End historyLoading animation
            this.historyLoading = false
            EventBus.$emit('function-activity', {functionName: 'SmsModal_getSmsHistory', isActive: false})
        },

        clickAway() {
            EventBus.$emit('sms-modal-closing')
        },

        isDevelopmentEnv() {
            return process.env.VUE_APP_ENV == 'development'
        }
    }

    // async beforeMount(){
    //     console.log('before mount sms modal')
    //     let history = await this.getSmsHistory('+4552551506')
    //     console.log(history)
    // }
}
</script>
<style scoped>

    .historySegment {
        min-height: 10vw;
        max-height: 50vw;
        overflow-y: auto;
        display: flex;
        flex-direction: column-reverse;
    }
    .historySegment > * {
        flex-shrink: 0;
    }
    
    .message-wrapper .message {
        background: rgb(0, 153, 255);
        color: #FFF;
        padding: 7px 14px 8px;
        border-radius: 18px;
        white-space: pre-wrap;
        margin: 20px 0 0 20px;
    }

    .message-wrapper .message-time {
        color: #888;
        text-align: right;
        display: block;
        font-size: 12px;
    }

    .no-messages {
        top: -40px;
        position: relative;
        text-align: center;
        color: #9a9a9a;
    }
</style>