
export class SubTaskType {
    
    static BLOWING = 'Blowing'

    static SPLICING = 'Splicing'

    static PATCHING = 'Patching'

}

export const SubTaskTerminology = {
    [SubTaskType.BLOWING]: {
        Action: 'Blowing',
        Done: 'blæst',
        Handled: 'Length',
        HandledBy: 'BlownBy',
        HandledAt: 'BlownAt'
    },

    [SubTaskType.SPLICING]: {
        Action: 'Splicing',
        Done: 'splidset',
        Handled: 'Spliced',
        HandledBy: 'SplicedBy',
        HandledAt: 'SplicedAt'
    },

    [SubTaskType.PATCHING]: {
        Action: 'Patching',
        Done: 'patchet',
        Handled: 'Patched',
        HandledBy: 'PatchedBy',
        HandledAt: 'PatchedAt'
    }
}


export const EmptySubTasks = [
    {
        name: "Blæsning",
        code: "BLOW",
        type: SubTaskType.BLOWING,
        titles: {
            positive: "Kundefiber blæst",
            negative: "Mangler at blive blæst",
            inputLabel: "Blæsemeter: "
        },
        state: {
            isPositive: false,
            valueType: "num",
            value: null,
            text: "Nej"
        }
    },
    {
        name: "Splidsning",
        code: "SPLICE",
        type: SubTaskType.SPLICING,
        titles: {
            positive: "Kundefiber splidset i gadeskab",
            negative: "Mangler at blive splidset i gadeskab",
            inputLabel: "Splidset? "
        },
        state: {
            isPositive: false,
            valueType: "bool",
            value: false,
            text: "Nej"
        }
    },
    {
        name: "Patching",
        code: "PATCH",
        type: SubTaskType.PATCHING,
        titles: {
            positive: "Fiberen er patchet i teknikhus",
            negative: "Mangler at blive patchet i teknikhus",
            inputLabel: "Patchet? "
        },
        state: {
            isPositive: false,
            valueType: "bool",
            value: false,
            text: "Nej"
        }
    }
]
