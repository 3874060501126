
class ProjectType {

    
    static ESTABLISHMENT = 1 // Etableringsprojekt
    static CABINET = 2 // Skabsprojekt
    static INSTALLATION = 3 // Installationsprojekt
    static LATE_SIGNUP = 4 // Eftertilslutning
    static CONVERSION = 5 // Konvertering
    static INSTALLATION_REMAINING = 6 // Installationsprojekt (Opsamling)
    static BUSINESS = 9

    static TROUBLE_TICKETS = 'TroubleTickets'
    static ALL = 'all'

    static dropdownOptions = [
        {text: "Etableringsprojekt", value: this.ESTABLISHMENT},
        {text: "Skabsprojekt", value: this.CABINET},
        {text: "Installationsprojekt", value: this.INSTALLATION},
        {text: "Eftertilslutning", value: this.LATE_SIGNUP},
        {text: "Konvertering", value: this.CONVERSION},
        {text: "Installationsprojekt (opsamling)", value: this.INSTALLATION_REMAINING},
        {text: "Erhvervsprojekt", value: this.BUSINESS},
        {text: "Driftsager", value: this.TROUBLE_TICKETS},
    ]

    static getValueFromCode(code) {
        code = String(code)
        let foundDropDownOption = this.dropdownOptions.find((option) => String(option.value) == String(code))
        if (foundDropDownOption) return foundDropDownOption.text
        switch(code) {
            case String(this.ALL):
                return 'Alle'
            default:
                return isNaN(code) ? code : 'unknown'
        }
    }

    static getCodeFromValue(value) {
        let foundDropDownOption = this.dropdownOptions.find((option) => String(option.text) == String(value))
        if (foundDropDownOption) return foundDropDownOption.value
        switch(value) {
            case 'Alle':
            case 'all':
                return String(this.ALL)
            default:
                return 'unknown'
        }
    }

    static getColorFromCode(code) {
        code = String(code)
        switch(code) {
            case String(this.ESTABLISHMENT):
            case String(this.INSTALLATION):
                return 'blue'
                
            case String(this.INSTALLATION_REMAINING):
            case String(this.LATE_SIGNUP):
                return 'orange'

            case String(this.CABINET):
                return 'teal'

            case String(this.CONVERSION):
                return 'yellow'
            
            case String(this.BUSINESS):
                return 'cadetblue'

            case String(this.ALL):
                return 'purple'
            
            default:
                return 'black'
        }
    }
}

export default ProjectType
