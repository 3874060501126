<template>
    <sui-table-body>
        <sui-table-row>
            <sui-table-header-cell>
                Port-Status
            </sui-table-header-cell>
            <sui-table-cell>
                <span v-if="apiResponse">{{toUserFriendlyTimestamp(apiResponse.headers.date, true, false)}}</span> 
                <sui-button v-if="includeReloadBtn" size="mini" @click.prevent="getNexelStatus" style="float: right"><i class="fas fa-sync" v-bind:class="{'fa-spin': statusLoading}"></i></sui-button>
            </sui-table-cell>
        </sui-table-row>
        <template v-if="apiResponse">
            <sui-table-row>
                <sui-table-cell>CPE link</sui-table-cell>
                <sui-table-cell :class="apiResponse.operationalStatus=='up' ? 'signalInRange' : 'signalOutOfRange'">{{apiResponse.operationalStatus}}</sui-table-cell>
            </sui-table-row>
            <sui-table-row>
                <sui-table-cell>ASR link</sui-table-cell>
                <sui-table-cell :class="apiResponse.administrativeStatus=='up' ? 'signalInRange' : 'signalOutOfRange'">{{apiResponse.administrativeStatus}}</sui-table-cell>
            </sui-table-row>
            <sui-table-row>
                <sui-table-cell>Link speed</sui-table-cell>
                <sui-table-cell>{{Math.round(apiResponse.linkSpeedDownstream/1000)}}/{{Math.round(apiResponse.linkSpeedUpstream/1000)}}</sui-table-cell>
            </sui-table-row>
            <sui-table-row v-if="typeof apiResponse.inputErrorCount != 'undefined' || typeof apiResponse.outputErrorCount != 'undefined'">
                <sui-table-cell>Error Count (input/output)</sui-table-cell>
                <sui-table-cell>{{ String(apiResponse.inputErrorCount) }}/{{ String(apiResponse.outputErrorCount) }}</sui-table-cell>
            </sui-table-row>
            <sui-table-row v-if="apiResponse.opticalSignalPower && apiResponse.opticalSignalPower.access">
                <sui-table-cell>Access optical signal power (rx/tx)</sui-table-cell>
                <sui-table-cell>
                    <span v-if="apiResponse.opticalSignalPower.access.receive" :class="apiResponse.opticalSignalPower.access.receive.inRange ? 'signalInRange' : 'signalOutOfRange'">{{ apiResponse.opticalSignalPower.access.receive.value || 'null' }}</span>
                    <span v-else>null</span>
                    /
                    <span v-if="apiResponse.opticalSignalPower.access.transmit" :class="apiResponse.opticalSignalPower.access.transmit.inRange ? 'signalInRange' : 'signalOutOfRange'">{{ apiResponse.opticalSignalPower.access.transmit.value || 'null' }}</span>
                    <span v-else>null</span>
                </sui-table-cell>
            </sui-table-row>
            <sui-table-row v-if="apiResponse.opticalSignalPower && apiResponse.opticalSignalPower.ntp">
                <sui-table-cell>NTP optical signal power (rx/tx)</sui-table-cell>
                <sui-table-cell>
                    <span v-if="apiResponse.opticalSignalPower.ntp.receive" :class="apiResponse.opticalSignalPower.ntp.receive.inRange ? 'signalInRange' : 'signalOutOfRange'">{{ apiResponse.opticalSignalPower.ntp.receive.value || 'null' }}</span>
                    <span v-else>null</span>
                    /
                    <span v-if="apiResponse.opticalSignalPower.ntp.transmit" :class="apiResponse.opticalSignalPower.ntp.transmit.inRange ? 'signalInRange' : 'signalOutOfRange'">{{ apiResponse.opticalSignalPower.ntp.transmit.value || 'null' }}</span>
                    <span v-else>null</span>
                </sui-table-cell>
            </sui-table-row>
        </template>
    </sui-table-body>
</template>

<script>
import { Mixin } from '../../lib/Mixins/mixin'
import { DateMixin } from '../../lib/Mixins/dateMixin'
import { DataAPI } from '../../lib/DataAPI'
import EventBus from '../../EventBus'

export default {
    mixins: [Mixin, DateMixin, DataAPI],

    components: {
        // TableLoader,
    },

    props: {
        instLabel: String,
        includeReloadBtn: {
            type: Boolean,
            default: true
        },
    },

    data(){
        return {
            statusLoading: false,
            apiResponse: null,
        }
    },

    methods: {
        async getNexelStatus() {
            EventBus.$emit('function-activity', {functionName: 'NexelStatus_getNexelStatus', isActive: true})
            this.apiResponse = null
            EventBus.$emit('retrieved-nexel-status', {data: 'Henter status...', loading: true})
            if (!this.instLabel) {
                EventBus.$emit('retrieved-nexel-status', {data: 'Intet installations-nr', loading: false})
                EventBus.$emit('function-activity', {functionName: 'NexelStatus_getNexelStatus', isActive: false})
                return null
            }
            this.statusLoading = true
            try {
                this.apiResponse = await this.dataGetNexelStatus(this.instLabel)
                EventBus.$emit('retrieved-nexel-status', {data: this.apiResponse.id, loading: false})
                this.statusLoading = false
            } catch (error) {
                EventBus.$emit('retrieved-nexel-status', {data: 'Kunne ikke hente data!', loading: false})
                console.error(error)
                this.statusLoading = false
            }
            EventBus.$emit('function-activity', {functionName: 'NexelStatus_getNexelStatus', isActive: false})
            return this.apiResponse
        },
    },

    mounted() {
        EventBus.$on('reload-nexel-status-click', () => {this.getNexelStatus()})
    },

    beforeDestroy() {
        EventBus.$off('reload-nexel-status-click')
    },

    watch: {
        instLabel: {
            immediate: true,
            handler() {
                this.getNexelStatus()
            }
        }
    }
}
</script>
<style scoped>
    .signalInRange{
        color: green;
        font-weight: bold;
    }
    .signalOutOfRange{
        color: red;
        font-weight: bold;
    }
</style>