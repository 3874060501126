import { SubTaskType, SubTaskTerminology, EmptySubTasks } from './Enums/SubtaskType'
import { DateMixin } from './Mixins/dateMixin'

export const SubtaskMixin = {

    mixins: [DateMixin],

    methods: {
        // Subtask generation

        constructSubTasksArray(firebaseData) {

            let subtasks = []
            for (let i in EmptySubTasks) {
                const emptySubTask = EmptySubTasks[i]
                let subtask = { ...emptySubTask }

                if (firebaseData[SubTaskTerminology[subtask.type].Action]) {
                    subtask = this.buildSubTaskFromType(subtask.type, subtask, firebaseData)
                }

                subtasks.push(subtask)
            }

            return subtasks
        },

        buildSubTaskFromType(type, subtask, firebaseData) {

            const isHandled = firebaseData[SubTaskTerminology[type].Action][SubTaskTerminology[type].Handled]
            const handledBy = firebaseData[SubTaskTerminology[type].Action][SubTaskTerminology[type].HandledBy]
            const handledAt = firebaseData[SubTaskTerminology[type].Action][SubTaskTerminology[type].HandledAt]

            subtask.state = {
                isPositive: isHandled,
                valueType: 'bool',
                value: isHandled,
                text: isHandled ? 'Ja' : 'Nej',
                doneBy: handledBy,
                doneAt: handledAt
            }

            if (type == SubTaskType.BLOWING) {
                subtask.state.isPositive = !!isHandled // isHandled is not a bool when type is blowing, but instead the length in meters of cables blown.
                subtask.state.valueType = 'num'
                subtask.state.text = isHandled ? `${firebaseData.Blowing.Length}m` : subtask.state.text
            }

            subtask.Responsible = firebaseData[SubTaskTerminology[type].Action].Responsible
            subtask.Deferred = firebaseData[SubTaskTerminology[type].Action].Deferred

            if (isHandled) {
                subtask.titles = JSON.parse(JSON.stringify(subtask.titles))
                subtask.titles.positive = `${handledBy} har markeret denne fiber ${SubTaskTerminology[type].Done}\n ${this.toUserFriendlyTimestamp(handledAt)}`
            }

            return subtask
        },

        subtaskShouldBeDisabled(subtask, subtaskArray) {
            if(subtask.state.isPositive){
                return false
            }
            if(subtask.code == "SPLICE") {
                let blowTask = subtaskArray.find(task => task.code == "BLOW")
                if (!blowTask.state.isPositive){
                    return true
                }
            }
            return false
        },
    }
}