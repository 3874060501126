<template>
    <sui-container>

            <div class="row" :class="dataCardSizing()">
                <div class="col-sm-6">
                    <DataCard 
                        title="Status"
                        :subtitle="nexelStatusSubtitle"
                        no-padding
                        class="half-height"
                        :actions="[{type: 'button', title: 'Genindlæs', icon: 'fas fa-sync', eventName: 'reload-nexel-status', spinning: nexelStatusLoading}]"
                    >
                        <div class="scrollable">
                            <sui-table striped>
                                <NexelStatus
                                    :instLabel="installationLabel"
                                    :includeReloadBtn="false"
                                />
                            </sui-table>
                        </div>
                    </DataCard>
                </div>
                <div class="col-sm-6">
                    <DataCard 
                        title="Data"
                        :subtitle="nexelDataSubtitle"
                        no-padding
                        class="half-height"
                        :actions="[{type: 'button', title: 'Genindlæs', icon: 'fas fa-sync', eventName: 'reload-nexel-data', spinning: nexelDataLoading}]"
                    >
                        <div class="scrollable">
                            <sui-table striped>
                                <NexelData 
                                    :instLabel="installationLabel"
                                    :includeReloadBtn="false"
                                />
                            </sui-table>
                        </div>
                    </DataCard>
                </div>
            </div>
            <div class="row" :class="dataCardSizing()">
                <div class="col-sm-6">
                    <DataCard
                        :title="nexelServiceOrdersTitle"
                        :subtitle="nexelServiceOrdersSubtitle"
                        no-padding
                        class="half-height"
                        :actions="[{type: 'button', title: 'Genindlæs', icon: 'fas fa-sync', eventName: 'reload-nexel-service-orders', spinning: nexelServiceOrdersLoading}]"
                    >
                        <div class="scrollable">
                            <sui-table striped>
                                <NexelServiceOrders :instLabel="installationLabel"/>
                            </sui-table>
                        </div>
                    </DataCard>
                </div>
                <div class="col-sm-6">
                    <DataCard
                        :title="nexelTroubleTicketsTitle"
                        :subtitle="nexelTroubleTicketsSubtitle"
                        no-padding
                        class="half-height"
                        :actions="[{type: 'button', title: 'Genindlæs', icon: 'fas fa-sync', eventName: 'reload-nexel-trouble-tickets', spinning: nexelTroubleTicketsLoading}]"
                    >
                        <div class="scrollable">
                            <sui-table striped>
                                <NexelTroubleTickets :instLabel="installationLabel"/>
                            </sui-table>
                        </div>
                    </DataCard>
                </div>
            </div>
            <div class="row" :class="dataCardSizing()">
                <div class="col-sm-12">
                    <DataCard
                        :title="internalAppointmentsTitle"
                        :subtitle="internalAppointmentsSubtitle"
                        no-padding
                        :actions="[{type: 'button', title: 'Genindlæs', icon: 'fas fa-sync', eventName: 'reload-appointments', spinning: internalAppointmentsLoading}]"
                    >
                        <div class="scrollable">
                            <sui-table striped>
                                <InstCheckAppointments :instLabel="installationLabel"/>
                            </sui-table>
                        </div>
                    </DataCard>
                </div>
            </div>

        <nexel-service-order-modal
            :isOpen="serviceOrderModalIsOpen"
            :providedServiceOrder="selectedServiceOrder"
        />

        <nexel-trouble-ticket-modal
            :isOpen="troubleTicketModalIsOpen"
            :providedTroubleTicket="selectedTroubleTicket"
        />
    </sui-container>
</template>
<script>
import NexelStatus from '../components/Project/NexelStatus.vue'
import NexelData from '../components/Project/NexelData.vue'
import NexelServiceOrders from '../components/Project/NexelServiceOrders.vue'
import EventBus from '../EventBus'
import NexelServiceOrderModal from '../components/Project/NexelServiceOrderModal.vue'
import NexelTroubleTickets from '../components/Project/NexelTroubleTickets.vue'
import NexelTroubleTicketModal from '../components/Project/NexelTroubleTicketModal.vue'
import InstCheckAppointments from '../components/Project/InstCheckAppointments.vue'


export default {
    name: 'PortCheck',

    props: {
        installationLabel: String,
    },

    components: {
        NexelStatus,
        NexelData,
        NexelServiceOrders,
        NexelServiceOrderModal,
        NexelTroubleTickets,
        NexelTroubleTicketModal,
        InstCheckAppointments,
    },

    data() {
        return {
            nexelStatusSubtitle: 'Har endnu ikke modtaget status',
            nexelDataSubtitle: 'Har endnu ikke modtaget data',

            nexelServiceOrdersTitle: 'Service Orders',
            nexelServiceOrdersSubtitle: 'Har endnu ikke modtaget service orders',

            nexelTroubleTicketsTitle: 'Driftsager',
            nexelTroubleTicketsSubtitle: 'Har endnu ikke modtaget trouble tickets',

            internalAppointmentsTitle: 'Aftaler',
            internalAppointmentsSubtitle: 'Har endnu ikke modtaget aftaler',

            serviceOrderModalIsOpen: false,
            troubleTicketModalIsOpen: false, 

            selectedServiceOrder: null,
            selectedTroubleTicket: null,

            nexelDataLoading: false,
            nexelStatusLoading: false,
            nexelServiceOrdersLoading: false,
            nexelTroubleTicketsLoading: false,
            internalAppointmentsLoading: false,
        }
    },

    computed: {
        currentMedia(){
            return this.$root.$children[0].currentMedia
        },
    },

    methods: {
        dataCardSizing(){
            if (this.currentMedia.type == 'Mobile') return 'mobile'
            return 'desktop'
        }
    },

    beforeMount() {
        EventBus.$on('retrieved-nexel-status', (payload) => {
            this.nexelStatusSubtitle = payload.data
            this.nexelStatusLoading = payload.loading
        })

        EventBus.$on('retrieved-nexel-data', (payload) => {
            this.nexelDataSubtitle = payload.data
            this.nexelDataLoading = payload.loading
        })

        EventBus.$on('retrieved-nexel-service-orders-title', (label) => {this.nexelServiceOrdersTitle = label})
        EventBus.$on('retrieved-nexel-service-orders', (payload) => {
            this.nexelServiceOrdersSubtitle = payload.data
            this.nexelServiceOrdersLoading = payload.loading
        })

        EventBus.$on('retrieved-nexel-trouble-tickets-title', (label) => {this.nexelTroubleTicketsTitle = label})
        EventBus.$on('retrieved-nexel-trouble-tickets', (payload) => {
            this.nexelTroubleTicketsSubtitle = payload.data
            this.nexelTroubleTicketsLoading = payload.loading
        })
        
        EventBus.$on('retrieved-appointments-title', (label) => {this.internalAppointmentsTitle = label})
        EventBus.$on('retrieved-appointments', (payload) => {
            this.internalAppointmentsSubtitle = payload.data
            this.internalAppointmentsLoading = payload.loading
        })
        
        EventBus.$on('open-service-order-modal', (serviceOrder) => {
            this.selectedServiceOrder = serviceOrder
            this.serviceOrderModalIsOpen = true
        })
        EventBus.$on('view-service-order-modal-closing', () => {
            this.selectedServiceOrder = null
            this.serviceOrderModalIsOpen = false
        })

        EventBus.$on('open-trouble-ticket-modal', (troubleTicket) => {
            this.selectedTroubleTicket = troubleTicket
            this.troubleTicketModalIsOpen = true
        })
        EventBus.$on('view-trouble-ticket-modal-closing', () => {
            this.selectedTroubleTicket = null
            this.troubleTicketModalIsOpen = false
        })
    },

    beforeDestroy() {
        EventBus.$off('retrieved-nexel-status')
        EventBus.$off('retrieved-nexel-data')
        EventBus.$off('retrieved-nexel-service-orders-title')
        EventBus.$off('retrieved-nexel-service-orders')
        EventBus.$off('retrieved-nexel-trouble-tickets-title')
        EventBus.$off('retrieved-nexel-trouble-tickets')
        EventBus.$off('retrieved-appointments-title')
        EventBus.$off('retrieved-appointments')
        EventBus.$off('open-service-order-modal')
        EventBus.$off('view-service-order-modal-closing')
    }
    
}
</script>
<style scoped>
    .mobile{
        margin-left: -35px;
        margin-right: -35px;
    }
    .desktop{
        margin-left: -13px;
        margin-right: -13px;
    }

</style>