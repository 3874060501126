<template>
    <div>
        <router-view v-if="isAuthenticated && isAdmin"></router-view>
    </div>
</template>
<script>
import { auth } from '../../firebase'
import UserRoles from '../../lib/Enums/UserRoles'

export default {
    name: 'AdminWrapper',

    enums: {
        UserRoles
    },

    computed: {
        user() {
            return auth.currentUser
        },
        isAuthenticated() {
            return auth.currentUser !== null
        },
        isAdmin() {
            try {
                return this.$store.state.userProfile.Roles.includes(UserRoles.USER_ADMIN)
            }
            catch {
                return false
            }
        },
        isSuperAdmin() {
            try {
                return this.$store.state.userProfile.Roles.includes(UserRoles.SUPER_ADMIN)
            }
            catch {
                return false
            }
        }
    },
}
</script>
