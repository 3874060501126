<template>
    <sui-container>
        <DataCard title="Installationsopslag">
            <p>Tjekker ASR-port, Service Ordre og Driftsager tilhørende installationsnummer, via API fra Nexel</p>
            <sui-form @submit.prevent="updateInstLabel">
                <sui-form-field>
                    <label>Installationsnummer</label>
                    <input placeholder="70212345" v-model="installationLabelInput"/>
                </sui-form-field>
                <sui-button type="submit">Søg</sui-button>
            </sui-form>
            <hr/>
            <installation-check-content
                :installationLabel="installationLabel"
            />
        </DataCard>
    </sui-container>
</template>
<script>

import InstallationCheckContent from './InstallationCheckContent.vue'

export default {
    name: 'PortCheck',

    components: {
        InstallationCheckContent,
    },

    data() {
        return {
            installationLabelInput: null,
            installationLabel: null,
        }
    },

    methods: {
        updateInstLabel() {
            this.installationLabel = this.installationLabelInput
        }
    },
}
</script>