import swal from 'sweetalert'
import { db } from '../firebase'
import EventBus from '../EventBus'

export const unitWorkMixin = {

    data(){
        return {
            unitWorkUnits: [],
            unitWorkCompositeUnits: [],
        }
    },
    
    methods: {
        calculateEquivalentHours(unitId, number) {
            if (!unitId || !(number || number === 0)){
                console.error(`Cannot calculate equivalent hours without both unitId (${unitId}) and number (${number})`)
            }
            if(unitId.includes('F')){
                let compositeUnit = this.unitWorkCompositeUnits.find((u) => u.id == unitId)
                if (!compositeUnit) {
                    return 0
                }
                let equivalentHoursPerUnit = 0
                for (let i in compositeUnit.Parts) {
                    let part = compositeUnit.Parts[i]
                    let unit = this.unitWorkUnits.find((u) => u.id == part.BaseUnit)
                    if (!unit) continue;
                    equivalentHoursPerUnit += unit.EquivalentHours * (part.Percentage/100)
                }
                return (equivalentHoursPerUnit * number)
            } else {
                let unit = this.unitWorkUnits.find((u) => u.id == unitId)
                if (!unit) return 0
                return (unit.EquivalentHours * number)
            }
        },

        calculatePrice(unitId, number) {
            if (!unitId || !(number || number === 0)){
                console.error(`Cannot calculate price without both unitId (${unitId}) and number (${number})`)
            }
            if (typeof unitId != 'string') throw new Error('Unit ID for calculating price was not a string')
            if(unitId.includes('F')){
                let compositeUnit = this.unitWorkCompositeUnits.find((u) => u.id == unitId)
                let pricePerUnit = 0
                for (let i in compositeUnit.Parts) {
                    let part = compositeUnit.Parts[i]
                    let unit = this.unitWorkUnits.find((u) => u.id == part.BaseUnit)
                    pricePerUnit += unit.UnitPrice * (part.Percentage/100)
                }
                return (pricePerUnit * number)
            } else {
                let unit = this.unitWorkUnits.find((u) => u.id == unitId)
                return (unit.UnitPrice * number)
            }
        },

        calculateCompositeSum(parts, field) {
            return parts.reduce((p, c) => {
                return p += c[field] || 0
            }, 0)
        },

        sumUnitWorkForWorker(unitWorkArr, workerEmail) {
            let count = 0
            let dates = []
            let equivalentHours = 0
            let price = 0
            for (let uw of unitWorkArr.filter(uw => uw.Workers.includes(workerEmail))) {
                count += 1
                if (dates.indexOf(uw.Date) == -1) dates.push(uw.Date)
                equivalentHours += Number(uw.EquivalentHours / uw.Workers.length)
                price += Number(uw.Price / uw.Workers.length)
            }
            return {
                count,
                dates,
                equivalentHours,
                price,
            }
        },

        async markUnitBilled(unitId, Bool = true){
            // console.log(unitId)
            if (!this.userHasLeadPermissions) {
                swal('Kun for projektledere', 'Kun projektledere og administratorer kan ændre i godkendelse af enheds-arbejde', 'error')
                return false
            }
            EventBus.$emit('function-activity', {functionName: 'unitWorkMixin_markUnitBilled', isActive: true})
            let response = await db.collection('UnitWork').doc(unitId).update({
                Billed: {
                    Bool,
                    Updated: new Date()
                }
            })
            EventBus.$emit('function-activity', {functionName: 'unitWorkMixin_markUnitBilled', isActive: false})
            return response
        },
    },

    mounted() {
        this.$bind('unitWorkUnits', db.collection('UnitWorkUnits'))
        this.$bind('unitWorkCompositeUnits', db.collection('UnitWorkCompositeUnits'))
    },
}