<template>
    <div>
        <sui-modal v-model="createModalOpen">
            <sui-modal-header>
                Opret nyt teknikhus
            </sui-modal-header>
            <sui-modal-content scrolling style="max-height: 70vh">
                <sui-form :loading="createFormLoading">
                    <sui-form-fields>
                        <sui-form-field>
                            <label>Nummer</label>
                            <input type="number" min="1" max="9999" name="Nummer" placeholder="200" v-model="createdNumber">
                        </sui-form-field>
                        <sui-form-field>
                            <label>Navn</label>
                            <input type="text" name="Navn" placeholder="Tommerup" v-model="createdName">
                        </sui-form-field>
                        <sui-form-field>
                            <label>Fiber ring</label>
                            <input type="text" name="Fiberring" placeholder="asp1" v-model="createdFiberring">
                        </sui-form-field>
                        <sui-form-field>
                            <label>Id</label>
                            <input type="text" disabled :value="this.createdId">
                        </sui-form-field>
                    </sui-form-fields>
                    <label>Adresse</label>
                    <sui-form-fields>
                        <sui-form-field>
                            <label>Vej</label>
                            <input type="text" name="Vej" placeholder="Æblehaven" v-model="createdRoad">
                        </sui-form-field>
                        <sui-form-field>
                            <label>Husnr</label>
                            <input type="number" min="1" max="99999" name="Husnr" placeholder="16" v-model="createdHouseNum">
                        </sui-form-field>
                        <sui-form-field>
                            <label>Bogstav</label>
                            <input type="text" name="Bogstav" placeholder="B" v-model="createdLetter">
                        </sui-form-field>
                        <sui-form-field>
                            <label>Lejlighed</label>
                            <input type="text" name="Lejlighed" placeholder="2. TV" v-model="createdAppartment">
                        </sui-form-field>
                        <sui-form-field>
                            <label>Postnr</label>
                            <input type="number" min="1000" max="9999" name="Postnr" placeholder="5690" v-model="createdZipcode">
                        </sui-form-field>
                        <sui-form-field>
                            <label>By</label>
                            <input type="text" name="PostBy" placeholder="Tommerup" v-model="createdCity">
                        </sui-form-field>
                    </sui-form-fields>
                    <label>Koordinater</label>
                    <sui-form-fields>
                        <sui-button @click.prevent="getCoordinatesFromAddress" :loading="dawaLookupLoading">DAWA opslag</sui-button>
                        <sui-form-field>
                            <label>Breddegrad (Latitude)</label>
                            <input type="number" min="-180" max="180" name="Breddegrad" step="0.00000001" placeholder="55,35568566" v-model="createdLat">
                        </sui-form-field>
                        <sui-form-field>
                            <label>Længdegrad (Longitude)</label>
                            <input type="number" min="-90" max="90" name="Længdegrad" step="0.00000001" placeholder="10,46281374" v-model="createdLng">
                        </sui-form-field>
                        <sui-checkbox label="Manuelt tilpasset" v-model="createdCoordManual"/>
                    </sui-form-fields>
                    <label>Kundefiber</label>
                    <sui-form-fields>
                        <sui-form-field>
                            <label>ODF Type</label>
                            <input type="text" name="ODF Type" placeholder="LISA" v-model="createdInstODF">
                        </sui-form-field>
                        <sui-form-field>
                            <label>Porte pr tray</label>
                            <input type="number" name="Porte pr tray" min="1" max="999" placeholder="24" v-model="createdInstPorts">
                        </sui-form-field>
                        <sui-form-field>
                            <label>Connector</label>
                            <input type="text" name="Connector" placeholder="LC/APC" v-model="createdInstConnector">
                        </sui-form-field>
                        <sui-form-field>
                            <label>Splidsemetode</label>
                            <input type="text" name="Splidsemetode" placeholder="lige igennem" v-model="createdInstSpliceMethod">
                        </sui-form-field>
                        <sui-form-field>
                            <label>Patchkabel længder</label>
                            <input type="text" name="Patchkabel længder" placeholder="4/6m" v-model="createdPatchLength">
                        </sui-form-field>
                    </sui-form-fields>
                    <label>Backbone fiber</label>
                    <sui-form-fields>
                        <sui-form-field>
                            <label>ODF Type</label>
                            <input type="text" name="ODF Type" placeholder="LISA" v-model="createdBBODF">
                        </sui-form-field>
                        <sui-form-field>
                            <label>Porte pr tray</label>
                            <input type="number" name="Porte pr tray" min="1" max="999" placeholder="24" v-model="createdBBPorts">
                        </sui-form-field>
                        <sui-form-field>
                            <label>Connector</label>
                            <input type="text" name="Connector" placeholder="LC/APC" v-model="createdBBConnector">
                        </sui-form-field>
                    </sui-form-fields>
                    <label>Kabel TV</label>
                    <sui-form-fields>
                        <sui-form-field>
                            <sui-checkbox label="Har CATV?" v-model="createdCATV"/>
                        </sui-form-field>
                        <sui-form-field>
                            <sui-checkbox label="Har Forenings-TV?" v-model="createdAssociationTV"/>
                        </sui-form-field>
                        <sui-form-field>
                            <label>Klar til CATV</label>
                            <input type="text" name="Klar til CATV" placeholder="OK" v-model="createdCATVReady">
                        </sui-form-field>
                    </sui-form-fields>
                    <label>Særlige adgangsforhold</label>
                    <sui-form-fields>
                        <sui-form-field>
                            <sui-checkbox label="Har særlige adgangsforhold?" v-model="createdSpecialAccess"/>
                        </sui-form-field>
                        <sui-form-field>
                            <label>Note til særlige adgangsforhold</label>
                            <textarea rows="3" v-model="createdSpecialAccessDescription"></textarea>
                        </sui-form-field>
                    </sui-form-fields>
                    <div style="text-align: right;">
                        <sui-button positive type="submit" :disabled="!createdSubmitReady" @click.prevent="saveCreatedHub">Gem</sui-button>
                    </div>
                </sui-form>
            </sui-modal-content>
        </sui-modal>

        <div class="row">
            <div class="col-sm-6">
                <DataCard
                    class="full-height"
                    no-padding
                    title="Accesshuse"
                    :actions="[
                        { type: 'button', icon: 'plus', label: 'Opret ny', eventName: 'hubs-list-new-btn' },
                    ]"
                >
                    <!-- List -->
                    <div class="scrollable" v-if="currentMedia.type == 'Desktop'">
                        <sui-table striped selectable single-line compact>
                            <sui-table-header>
                                <sui-table-row>
                                    <sui-table-header-cell></sui-table-header-cell>
                                    <sui-table-header-cell>#</sui-table-header-cell>
                                    <sui-table-header-cell>Navn</sui-table-header-cell>
                                    <sui-table-header-cell>Ca. Adresse</sui-table-header-cell>
                                    <sui-table-header-cell><i class="fas fa-check-square" style="color: rgb(255, 204, 0);" title="Opgaver"></i></sui-table-header-cell>
                                    <sui-table-header-cell><i class="fas fa-key" style="color: rgb(255, 91, 71);" title="Særlige adgangsforhold"></i></sui-table-header-cell>
                                    <sui-table-header-cell><i class="fas fa-map-marker" title="Placering tilrettet manuelt?"></i></sui-table-header-cell>
                                </sui-table-row>
                            </sui-table-header>
                            <sui-table-body v-if="filteredHubs.length">
                                <sui-table-row 
                                    v-for="hub in filteredHubs"
                                    :key="hub.id"
                                    @click="listItemClicked(hub.id)"
                                >
                                    <sui-table-cell>
                                        <i
                                            class="fas fa-map-marker-alt text-primary"
                                            :title="`Klik for at navigere i Google Maps`"
                                            @click.stop="linkToGoogleMapsDirections(hub.Coordinates, hub.Address)"
                                        ></i>
                                    </sui-table-cell>
                                    <sui-table-cell>{{ hub.Number }}</sui-table-cell>
                                    <sui-table-cell>{{ hub.Name }}</sui-table-cell>
                                    <sui-table-cell>{{ formatAddress(hub.Address, false) }}</sui-table-cell>
                                    <sui-table-cell>
                                        <span v-if="hubTasksOnHub(hub.Number).length" style="color: rgb(255, 204, 0); font-weight: bold;" :title="`${hubTasksOnHub(hub.Number).length} aktiv${hubTasksOnHub(hub.Number).length > 1 ? 'e' : ''} opgave${hubTasksOnHub(hub.Number).length > 1 ? 'r' : ''} i teknikhus ${hub.Number}`">{{hubTasksOnHub(hub.Number).length}}</span>
                                    </sui-table-cell>
                                    <sui-table-cell>
                                        <i v-if="hub.SpecialAccess && hub.SpecialAccess.Bool" style="color: rgb(255, 91, 71);" class="fas fa-key" title="Der er særlige adgangsforhold i dette teknikhus"></i>
                                    </sui-table-cell>
                                    <sui-table-cell>
                                        <i v-if="hub.Coordinates && hub.Coordinates.ManuallySet" class="fas fa-map-marker-smile" title="Placeringen af dette teknikhus er tilrettet manuelt"></i>
                                    </sui-table-cell>
                                </sui-table-row>
                            </sui-table-body>
                            <sui-table-body v-if="!filteredHubs.length">
                                <sui-table-cell colspan="7">
                                    <TableLoader />
                                </sui-table-cell>
                            </sui-table-body>
                        </sui-table>
                    </div>

                    <!-- Tiles -->
                    <div class="scrollable" v-else>
                        <sui-card-group
                            :items-per-row="1"
                            stackable
                            style="margin-top: 20px; margin-bottom: 5px;"
                        >
                            <sui-card
                                v-for="hub in filteredHubs"
                                :key="hub.id"
                                class="link raised"
                                @click="listItemClicked(hub.id)"
                                style="margin-top: -5px;"
                            >
                                <sui-card-content>
                                    <sui-card-header>
                                        <span>
                                            <i
                                                class="fas fa-map-marker-alt text-primary"
                                                :title="`Klik for at navigere i Google Maps`"
                                                @click.stop="linkToGoogleMapsDirections(hub.Coordinates, hub.Address)"
                                            ></i>
                                        </span>
                                        <span>
                                            &nbsp;
                                            {{ hub.Number }}
                                        </span>
                                        <span>
                                            &nbsp;
                                            {{ hub.Name }}
                                        </span>
                                    </sui-card-header>
                                </sui-card-content>

                                <sui-card-content>
                                    <span>
                                        {{ formatAddress(hub.Address, false) }}
                                    </span>
                                </sui-card-content>

                                <sui-card-content v-if="(hubTasksOnHub(hub.Number).length) || (hub.SpecialAccess && hub.SpecialAccess.Bool) || (hub.Coordinates && hub.Coordinates.ManuallySet)">
                                    <div v-if="hubTasksOnHub(hub.Number).length">
                                        <span style="color: rgb(255, 204, 0); font-weight: bold;" :title="`${hubTasksOnHub(hub.Number).length} aktiv${hubTasksOnHub(hub.Number).length > 1 ? 'e' : ''} opgave${hubTasksOnHub(hub.Number).length > 1 ? 'r' : ''} i teknikhus ${hub.Number}`">{{hubTasksOnHub(hub.Number).length}}</span>
                                        <span>
                                            {{ ` aktiv${hubTasksOnHub(hub.Number).length > 1 ? 'e' : ''} opgave${hubTasksOnHub(hub.Number).length > 1 ? 'r' : ''} i teknikhus ${hub.Number}` }}
                                        </span>
                                    </div>

                                    <div v-if="hub.SpecialAccess && hub.SpecialAccess.Bool">
                                        <span>
                                        <i style="color: rgb(255, 91, 71);" class="fas fa-key" title="Der er særlige adgangsforhold i dette teknikhus"></i>
                                        </span>
                                        <span>
                                            Der er særlige adgangsforhold
                                        </span>
                                    </div>

                                    <div v-if="hub.Coordinates && hub.Coordinates.ManuallySet">
                                        <span>
                                            <i class="fas fa-map-marker-smile" title="Placeringen af dette teknikhus er tilrettet manuelt"></i>
                                        </span>
                                        <span>
                                            Placeringen er tilrettet manuelt
                                        </span>
                                    </div>
                                </sui-card-content>
                            </sui-card>
                        </sui-card-group>
                    </div>
                </DataCard>
            </div>

            <!-- Map -->
            <div class="col-sm-6">
                <DataCard no-padding class="full-height map-card">
                    <OKAPIMap
                        :mapMarkers="mapMarkers"
                    />
                </DataCard>
            </div>
        </div>
    </div>
</template>

<script>
import { db } from '../../firebase'
// import EventBus from '../../EventBus'
import TableLoader from '../../components/TableLoader'
import { DataAPI } from '../../lib/DataAPI'
import { Mixin } from '../../lib/Mixins/mixin'
import { DateMixin } from '../../lib/Mixins/dateMixin'
import ContactRole from '../../lib/Enums/ContactRole'
import TicketState from '../../lib/Enums/TicketState'
import OKAPIMap from '@/components/Maps/OKAPImap.vue'
import EventBus from '../../EventBus'

export default {
    name: 'HubsList',
    mixins: [DataAPI, Mixin, DateMixin],

    components: {
        TableLoader,
        OKAPIMap
    },

    enums: {
        ContactRole
    },

    data() {
        return {
            hubs: [],
            map: null,
            mapMarkers: {},
            createModalOpen: false,
            createFormLoading: false,

            createdNumber: null,
            createdName: null,
            createdFiberring: null,
            createdRoad: null,
            createdHouseNum: null,
            createdLetter: null,
            createdAppartment: null,
            createdZipcode: null,
            createdCity: null,
            createdLat: null,
            createdLng: null,
            createdCoordManual: false,
            createdInstODF: null,
            createdInstPorts: null,
            createdInstConnector: null,
            createdInstSpliceMethod: null,
            createdPatchLength: null,
            createdBBODF: null,
            createdBBPorts: null,
            createdBBConnector: null,
            createdCATV: false,
            createdAssociationTV: false,
            createdCATVReady: null,
            createdSpecialAccess: false,
            createdSpecialAccessDescription: null,
            createdSpecialAccessTimestamp: null,

            dawaLookupLoading: false,

            hubTasks: [],

            projects: [],
            searchFilterValue: '',
        }
    },

    computed: {
        currentMedia() {
            return this.$root.$children[0].currentMedia
        },

        filteredHubs() {
            let filteredHubs = this.cloneJson(this.hubs)
            
            for (let i in this.hubs){
                filteredHubs[i].id = this.hubs[i].id
            }

            if (this.searchFilterValue){
                filteredHubs = filteredHubs.filter((hub) => {
                    return this.matchSearchCriteria(hub)
                })
            }
            return filteredHubs
        },
        
        createdId() {
            return String(this.createdNumber).padStart(4,"0")
        },

        createdSubmitReady() {
            return true
        },

        hubsMentionedInProject() {
            let mentionedHubs = {}
            for (const proj of this.projects) {
                for (const hub of proj.Hubs) {
                    mentionedHubs[hub] = true
                }
            }
            return mentionedHubs
        }
    },

    firestore: {
        hubs: db.collection('HUBs'),
        hubTasks: db.collectionGroup('HubTasks').where('State.Value','<',String(TicketState.RESOLVED)),
        projects: db.collection('Projects').where('Status', '==', 1).orderBy('Name', 'asc'),
    },

    methods: {
        initMap(){
            this.regenerateMapMarkers()
        },
        listItemClicked(hubId){
            this.$router.push(`/hubs/${hubId}`)
        },
        async getCoordinatesFromAddress(){
            EventBus.$emit('function-activity', {functionName: 'Hubs/List_getCoordinatesFromAddress', isActive: true})
            let address = {
                road: this.createdRoad,
                number: this.createdHouseNum,
                letter: this.createdLetter,
                zipcode: this.createdZipcode
            }
            this.dawaLookupLoading = true
            try{
                let coords = await this.externalGetGeoCoordinatesFromAddress(address)
                this.createdLat = coords.lat
                this.createdLng = coords.lng
                this.createdCoordManual = false
                this.dawaLookupLoading = false
                EventBus.$emit('function-activity', {functionName: 'Hubs/List_getCoordinatesFromAddress', isActive: false})
                return coords
            }
            catch(err){
                console.error(err)
                this.dawaLookupLoading = false
                EventBus.$emit('function-activity', {functionName: 'Hubs/List_getCoordinatesFromAddress', isActive: false})
                return false
            }
        },
        clearCreateForm(){
            this.createdNumber = null
            this.createdName = null
            this.createdFiberring = null
            this.createdRoad = null
            this.createdHouseNum = null
            this.createdLetter = null
            this.createdAppartment = null
            this.createdZipcode = null
            this.createdCity = null
            this.createdLat = null
            this.createdLng = null
            this.createdCoordManual = false
            this.createdInstODF = null
            this.createdInstPorts = null
            this.createdInstConnector = null
            this.createdInstSpliceMethod = null
            this.createdPatchLength = null
            this.createdBBODF = null
            this.createdBBPorts = null
            this.createdBBConnector = null
            this.createdCATV = false
            this.createdAssociationTV = false
            this.createdCATVReady = null
            this.createdSpecialAccess = false
            this.createdSpecialAccessDescription = null
            this.createdSpecialAccessTimestamp = null
            this.dawaLookupLoading = false
            this.searchFilterValue = ''
        },
        async saveCreatedHub(){
            EventBus.$emit('function-activity', {functionName: 'Hubs/List_saveCreatedHub', isActive: true})
            console.log('save button pressed')
            this.createFormLoading = true

            let hubObj = {
                id: this.createdId,
                Address: {
                    appartment: this.createdAppartment,
                    city: this.createdCity,
                    letter: this.createdLetter,
                    number: this.createdHouseNum,
                    road: this.createdRoad,
                    zipcode: this.createdZipcode,
                },
                AssociationTV: Boolean(this.createdAssociationTV),
                Backbone: {
                    Connector: this.createdBBConnector,
                    ODFType: this.createdBBODF,
                    PortsPerTray: Number(this.createdBBPorts),
                },
                CATV: Boolean(this.createdCATV),
                CATVReadyODF: this.createdCATVReady,
                Coordinates: {
                    Lat: Number(this.createdLat),
                    Lng: Number(this.createdLng),
                },
                Fiberring: this.createdFiberring,
                InstallationFiber: {
                    Connector: this.createdInstConnector,
                    ODFType: this.createdInstODF,
                    PatchCableLength: this.createdPatchLength,
                    PortsPerTray: Number(this.createdInstPorts),
                    SpliceMethod: this.createdInstSpliceMethod,
                },
                Name: this.createdName,
                Notes: [],
                Number: Number(this.createdNumber),
                SpecialAccess: {
                    Bool: this.createdSpecialAccess,
                    Description: this.createdSpecialAccessDescription,
                    Updated: this.formatMachineTimestamp(new Date())
                }
            }
            await this.dataCreateNewHub(hubObj)

            this.createModalOpen = false
            this.clearCreateForm()
            this.createFormLoading = false
            EventBus.$emit('function-activity', {functionName: 'Hubs/List_saveCreatedHub', isActive: false})
        },
        hubTasksOnHub(hubNum){
            let tasks = this.hubTasks.filter((task) => {
                // console.log(`${task.HubNum} ${task.HubNum == hubNum ? '==' : '!='} ${hubNum}`)
                return task.HubNum == hubNum
            })
            // console.log(tasks)
            return tasks
        },
        getMarkerType(hub) {
            if (this.hubTasksOnHub(hub.Number)?.length) {
                return 'hub-task'
            }
            if (this.hubsMentionedInProject[hub.id]) {
                return 'hub-project'
            }
            if (hub.SpecialAccess?.Bool) {
                return 'hub-specialaccess'
            }
            return 'hub-default'
        },
        async regenerateMapMarkers() {
            console.log('Regenterating hub markers...')
            let typeString = `hub-default`//Default map for hubs
            let changeCounter = 0

            for (let marker of Object.keys(this.mapMarkers)) {
                if (!this.filteredHubs.find((hub) => hub.id == marker)) {
                    changeCounter += 1
                    delete this.mapMarkers[marker]
                }
            }

            for (let hub of this.filteredHubs) {
                if (!hub.id || !hub.Coordinates) {
                    continue; //Skip hubtallations without id or Coordinates
                }
                const markerObj = {
                    key: `mapMarker-${hub.id}`,
                    id: hub.id,
                    type: this.getMarkerType(hub) || typeString,
                    title: this.formatAddress(hub.Address, false),
                    coordinates: {
                        lat: parseFloat(hub.Coordinates.Lat),
                        lng: parseFloat(hub.Coordinates.Lng),
                    },
                    address: this.formatAddress(hub.Address, false),
                }
                if (JSON.stringify(this.mapMarkers[hub.id]) == JSON.stringify(markerObj)) { //Dont do anything if the new map marker is identical to an existing one
                    continue;
                } 

                this.$set(this.mapMarkers, hub.id, markerObj)
                changeCounter += 1
            }

            // console.log(`Regenerating all markers caused ${changeCounter} changes`)

            if (changeCounter) {
                await this.sleep(50)
                EventBus.$emit('okapi-map-refresh')
                return this.mapMarkers
            } else {
                return this.mapMarkers
            }
        },
    },

    watch: {
        hubs: {
            immediate: false,
            handler(newArr, oldArr) {
                if (JSON.stringify(newArr) != JSON.stringify(oldArr)) {
                    this.regenerateMapMarkers()
                }
            }
        },

        filteredHubs: {
            immediate: false,
            handler(newArr, oldArr) {
                if (JSON.stringify(newArr) != JSON.stringify(oldArr)) {
                    this.regenerateMapMarkers()
                }
            }
        }
    },

    created() {
        EventBus.$on('hubs-list-new-btn-click', () => {
            console.log('Create new Hub')
            this.createModalOpen = true
        })
        EventBus.$on('listItemClicked', this.listItemClicked.bind(this))
        EventBus.$on('okapi-map-item-clicked', this.listItemClicked.bind(this))
    },

    beforeDestroy() {
        EventBus.$off('listItemClicked', this.listItemClicked)
        EventBus.$off('okapi-map-item-clicked', this.listItemClicked)
    },

    beforeMount() {
        EventBus.$on('top-search-type', (payload) => {
            this.searchFilterValue = String(payload).toLowerCase()
            if (!payload) this.searchFilterValue = ''
        })
    },

    mounted() {
        EventBus.$on('okapi-map-initialized', () => {this.initMap()})
    }
}
</script>
<style scoped>

.full-height {
    height: calc(100vh - 205px);
}

.table tbody {
    overflow: auto;
}

.table tr td {
    cursor: pointer;
}

.scollable {
    overflow: auto;
}

</style>