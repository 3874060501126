<template>
    <div>
        <strong>Description:</strong>
        <p>
            Goes through all UnitWork, and links those with ProjectInstallationType 'TRT' to project ADmEWfuxjCuOCNkTyiRK
        </p>

        <hr />

        <sui-button primary :loading="isRunning" :disabled="isRunning" @click="triggerDry">Run Dry</sui-button>
        <sui-button negative :loading="isRunning" :disabled="isRunning" @click="triggerNormal">Run</sui-button>
    </div>
</template>
<script>
import { db } from '../../../firebase'

export default {
    name: 'TRTUnitWorks',

    data() {
        return {
            isRunning: false,

            dryRun: false,

            projectId: null,

            unitWorks: [],
        }
    },

    methods: {
        log(message) {
            this.$emit('log', message)
        },

        async triggerDry() {
            this.dryRun = true
            await this.trigger()
        },

        async triggerNormal() {
            this.dryRun = false
            await this.trigger()
        },

        async trigger() {
            this.isRunning = true
            this.log(`----------------`)
            this.log(`Starting process`)
            
            if (this.dryRun) {
                this.log(`Dry mode - No updates will actually occur.`)
            }

            this.log(`Getting unitWorks`)
            this.unitWorks = await db.collection('UnitWork').where('ProjectInstallationType', '==', 'TRT').get()
            this.log(`Got ${this.unitWorks.docs.length} unitWorks`)
            var failedUnitWorks = []

            for (var i in this.unitWorks.docs) {
                this.log(`----`)
                const unitWork = this.unitWorks.docs[i]
                const unitWorkData = unitWork.data()
                this.log(`UnitWork #${i}, ID ${unitWork.id}, areaCode: ${unitWorkData.AreaCode}, projectInstallationType: ${unitWorkData.ProjectInstallationType}`)
                // if (unitWorkData.LinkedProjects.length) {
                //     this.log(`Skipping unitWork already linked the following projects: ${JSON.stringify(unitWorkData.LinkedProjects)}`)
                //     continue
                // }
                let updateDoc = {
                    // LinkedProjects: ['ADmEWfuxjCuOCNkTyiRK'],
                    ProjectInstallationType: 'TroubleTickets'
                }
                if (!this.dryRun){
                    try {
                        await db.collection('UnitWork').doc(unitWork.id).update(updateDoc)
                        this.log('updated unitWork')
                    }
                    catch {
                        failedUnitWorks.push(unitWork.id)
                        this.log('Unable to update unitWork in firebase')
                    }
                } else {
                    this.log('Writing to firebase skipped in dry run. Would otherwise have updated firebase with the following data:')
                    this.log(updateDoc)
                }


                this.log(`Done with this unitWork.`)
            }

            this.log(`Finished looping through unitWorks, with ${failedUnitWorks.length} errors, listed below`)
            this.log(failedUnitWorks)

            this.isRunning = false
        }
    }
}
</script>
<style scoped>
.float.right {
    float: right;
}
</style>