// import EventBus from "../EventBus";
// import TaskState from "./Enums/TaskState"
// import TicketState from "./Enums/TicketState"
// import { Timestamp } from "../firebase"

export const ProjectSettingsMixin = {

    // enums: {
    //     TaskState,
    //     TicketState,
    // },

    data() {
        return {
            projectSettings: [
                {
                    name: 'docUpdateSendMail',
                    text: 'Send mail til EFB projektleder ved rettelser i dokumentationen',
                    value: false, 
                    default: false,
                },
                {
                    name: 'sortOverrideProductDeliveryDate',
                    text: "Vis produktopstartsdato '9999/12/31' øverst",
                    value: false, 
                    default: false,
                },
                {
                    name: 'allowFinishInspectionWithoutPDF',
                    text: 'Tillad at afslutte besigtigelse uden at uploade PDF',
                    value: false,
                    default: false,
                }
            ],
        }
    },

    methods: {
       
        loadProjectSettings(project){
            if (project.ProjectSettings?.length){
                for (let i in project.ProjectSettings){
                    let index = this.projectSettings.findIndex((sett) => sett.name == project.ProjectSettings[i].name)
                    this.projectSettings[index].value = project.ProjectSettings[i].value
                }
            }
        },

        getProjectSetting(project, name){
            let defaultIndex = 0
        
            if (JSON.stringify(project) == "{}" || !project?.ProjectSettings?.length){
                defaultIndex = this.projectSettings.findIndex((sett) => sett.name == name)
                return this.projectSettings[defaultIndex].default
            }

            let projectIndex = project.ProjectSettings.findIndex((sett) => sett.name == name)
            if (projectIndex == -1){
                return this.projectSettings[defaultIndex].default
            }

            return project.ProjectSettings[projectIndex].value 
        }
    }
}
