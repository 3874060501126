<template>
    <sui-container>
        <edit-unit-work-modal
            v-if="activeUnitWork"
            :isOpen="editUnitWorkModalOpen"
            :task="activeUnitWorkTask"
            :availableWorkers="availableWorkers"
            :user="user"
            :unitWorkId="activeUnitWork.id"
            :beforeChange="activeUnitWork"
            :userHasLeadPermissions="userHasLeadPermissions"
        />
        <work-day-modal
            :isOpen="workDayModalOpen"
            :availableWorkers="availableWorkers"
            :user="user"
            :userHasLeadPermissions="userHasLeadPermissions"
            :date="activeWorkDayDate"
            :firWorkDay="firWorkDays.find((workday) => workday.Date == activeWorkDayDate)"
            :unitWorkDate="activeWorkDay"
            :firebaseUsers="this.firebaseUsers"
        />
        <unit-work-output-note-modal
            :isOpen="outputNoteModalOpen"
            :unitWorkUnitsInput="unitWorkUnits"
            :unitWorkCompositeUnitsInput="unitWorkCompositeUnits"
            :unitWork="firebaseUnitWork"
        />
        <unit-work-transfer-modal
            :isOpen="transferUnitWorkModalOpen"
            @close="closeUnitWorkTransferModal()"
            :unitWork="activeUnitWork"
        />
        <portal to="page-actions">
            <div style="zoom: 0.85">
                <sui-dropdown
                    class="labeled icon"
                    icon="sort"
                    button
                    text="Sortering"
                    :options="sortingOptions"
                    v-model="sortingCurrent"
                />
                <sui-dropdown
                    button
                    text="Periode"
                    :options="dateRangeOptions"
                    v-model="selectedDateRangeOption"
                />
                <template v-if="selectedDateRangeOption == 'CUSTOM'">
                    <sui-input type="date" v-model="dateStartInput"/>
                    <sui-input type="date" v-model="dateEndInput"/>
                    <sui-button tiny positive @click="setCurrentDateRange" :disabled="dateStartInput==currentStartDate && dateEndInput==currentEndDate"><i class="fas fa-arrow-right"></i></sui-button>
                </template>
            </div>
        </portal>
        <DataCard no-padding title="Opsummering af hele projektet" :actions="getFullProjectSums ? [] : [{ type: 'button', title: 'Hent opsummering af timer for hele projektet', label:'Hent opsummering', icon: 'download', eventName: 'get-project-sums-button' }]">
            <sui-table striped v-if="getFullProjectSums">
                <sui-table-header>
                    <sui-table-row>
                        <sui-table-header-cell :title="fullProjectTotals.workers.join('\n')">{{fullProjectTotals.workerCount}} Medarbejdere</sui-table-header-cell>
                        <sui-table-header-cell>{{fullProjectTotals.workDayCount}} Arbejdsdage</sui-table-header-cell>
                        <sui-table-header-cell>{{decimalToHourString(fullProjectTotals.actualHours)}} Timer</sui-table-header-cell>
                        <sui-table-header-cell>{{decimalToHourString(fullProjectTotals.billableHours)}} Fakturerbare timer</sui-table-header-cell>
                        <sui-table-header-cell>Fakturerbare timer i procent: {{Math.round(fullProjectTotals.billablePercent*100)}}%</sui-table-header-cell>
                    </sui-table-row>
                </sui-table-header>
                <sui-table-body>
                    <sui-table-row v-for="worker in fullProjectTotals.totalsGroupedByUser" :key="worker.email">
                        <sui-table-cell>{{worker.email.substr(0,worker.email.indexOf('@')).toUpperCase()}}</sui-table-cell>
                        <sui-table-cell :title="worker.dates.sort().join('\n')">{{worker.dates.length}} Arbejdsdage</sui-table-cell>
                        <sui-table-cell>{{decimalToHourString(worker.actualHours)}} Timer</sui-table-cell>
                        <sui-table-cell>{{decimalToHourString(worker.billableHours)}} Fakturerbare timer</sui-table-cell>
                        <sui-table-cell>Fakturerbare timer i procent: {{Math.round(worker.billablePercent*100)}}%</sui-table-cell>
                    </sui-table-row>
                </sui-table-body>
            </sui-table>
        </DataCard>
        <DataCard no-padding title="Fakturérbar arbejdstid" :subtitle="unitArrayEHSumString(firebaseUnitWork)">
            <sui-table selectable striped>
                <sui-table-header>
                    <sui-table-row>
                        <sui-table-header-cell>Dato</sui-table-header-cell>
                        <sui-table-header-cell title="Antal montører der har noteret enhedsarbejde den givne dato"><i class="fas fa-user-hard-hat"></i></sui-table-header-cell>
                        <sui-table-header-cell title="Antal montørtimer i udgift på den givne dato"><i class="fas fa-user-clock"></i></sui-table-header-cell>
                        <sui-table-header-cell title="Tilsvarende fibertekniker-Timer"><i class="fas fa-business-time"/></sui-table-header-cell>
                        <!-- <sui-table-header-cell title="Fakturerede tilsvarende fibertekniker-timer"><i class="fas fa-money-check-alt"></i></sui-table-header-cell> -->
                        <sui-table-header-cell title="Gennemsnitlig Tilsvarende fibertekniker-Timer pr montør"><i class="fas fa-business-time"/>/<i class="fas fa-user-hard-hat"/></sui-table-header-cell>
                        <sui-table-header-cell title="Fakturerbare timer i procent"><i class="fas fa-business-time"/><i class="fas fa-percent"/></sui-table-header-cell>
                    </sui-table-row>
                </sui-table-header>
                <sui-table-body>
                    <sui-table-row v-for="date in unitWorkGroupedByDate" :key="date.date" @click="workDayClicked(date)">
                        <sui-table-cell>{{toUserFriendlyDate(date.date)}}</sui-table-cell>
                        <sui-table-cell :title="date.workers.join('\n')">{{date.workers.length}}</sui-table-cell>
                        <sui-table-cell>{{decimalToHourString(date.actualHours)}}</sui-table-cell>
                        <sui-table-cell>{{decimalToHourString(date.equivalentHours)}}</sui-table-cell>
                        <!-- <sui-table-cell>{{decimalToHourString(unitSums(date.units).billedEH)}}</sui-table-cell> -->
                        <sui-table-cell>{{decimalToHourString(date.equivalentHours/date.workers.length)}}</sui-table-cell>
                        <sui-table-cell><span v-if="!isNaN(Math.round(date.equivalentHours/date.actualHours*100))">{{Math.round(date.equivalentHours/date.actualHours*100)}}%</span></sui-table-cell>
                    </sui-table-row>
                </sui-table-body>
            </sui-table>
        </DataCard>
        <DataCard no-padding title="Opsummering" :subtitle="unitArrayEHSumString(firebaseUnitWork)" :actions="[{ type: 'button', title: 'Generér note til brug på faktura', label:'Generér faktura-note', icon: 'file alternate', eventName: 'open-output-note-modal-button' }]">
            <sui-table selectable striped>
                <sui-table-header>
                    <sui-table-row>
                        <sui-table-header-cell colspan="2">Enhed</sui-table-header-cell>
                        <sui-table-header-cell title="Antal montører der er noteret på de pågældende enheder"><i class="fas fa-user-hard-hat"></i></sui-table-header-cell>
                        <sui-table-header-cell title="Antal installationer den pågældende enhed er noteret på">Inst</sui-table-header-cell>
                        <sui-table-header-cell title="Antal enheder">#</sui-table-header-cell>
                        <sui-table-header-cell title="Tilsvarende fibertekniker-Timer"><i class="fas fa-business-time"/></sui-table-header-cell>
                        <!-- <sui-table-header-cell title="Faktureret"><i class="fas fa-money-check-alt"></i></sui-table-header-cell> -->
                    </sui-table-row>
                </sui-table-header>
                <sui-table-body>
                    <sui-table-row v-for="group in unitWorkGroupedById" :key="group.id" @click="scroll(group.id)">
                        <sui-table-cell>{{group.id}}</sui-table-cell>
                        <sui-table-cell>{{group.name}}</sui-table-cell>
                        <sui-table-cell :title="unitSums(group.units).workers.join('\n')">{{unitSums(group.units).workers.length}}</sui-table-cell>
                        <sui-table-cell :title="unitSums(group.units).insts.join('\n')">{{unitSums(group.units).insts.length}}</sui-table-cell>
                        <sui-table-cell :title="`Af denne enhed er der noteret ${unitSums(group.units).summedAmount} ${group.unit}`">{{unitSums(group.units).summedAmount}}</sui-table-cell>
                        <sui-table-cell :title="`Prisen for dette arbejde svarer til prisen for ${decimalToHourString(unitSums(group.units).summedEH)} timelønsarbejde`">{{decimalToHourString(unitSums(group.units).summedEH)}}</sui-table-cell>
                        <!-- <sui-table-cell>{{unitSums(group.units).billed}}/{{group.units.length}}</sui-table-cell> -->
                    </sui-table-row>
                </sui-table-body>
            </sui-table>
        </DataCard>
        <DataCard no-padding v-for="group in unitWorkGroupedById" :key="group.id" :ref="group.id" :title="`${group.name} (${group.id})`" :subtitle="unitGroupSumString(group)">
            <sui-table striped selectable>
                <sui-table-header>
                     <sui-table-row>
                        <sui-table-header-cell title="Den dato arbejdet er udført">Dato</sui-table-header-cell>
                        <sui-table-header-cell title="Installationsnummer, hvor arbejdet er udført">Inst#</sui-table-header-cell>
                        <sui-table-header-cell title="Skab tilhørende den installation hvor arbejdet er udført">Skab</sui-table-header-cell>
                        <sui-table-header-cell title="Installations-adresse, på installationen hvor arbejdet er udført">Adresse</sui-table-header-cell>
                        <sui-table-header-cell title="Arbejdsbeskrivelse, skrevet af medarbejderen i forbindelse med arbejdet">Beskrivelse</sui-table-header-cell>
                        <sui-table-header-cell title="Initaler på den eller de medarbejdere der har udført arbejdet">Medarbejder(e)</sui-table-header-cell>
                        <sui-table-header-cell title="Opdeling af arbejde der betales af netselskab og indholdsudbyder">Betales af</sui-table-header-cell>
                        <sui-table-header-cell title="Antal enheder">#</sui-table-header-cell>
                        <sui-table-header-cell title="Tilsvarende fibertekniker-Timer"><i class="fas fa-business-time"></i></sui-table-header-cell>
                        <sui-table-header-cell title="Godkendt"><i class="fa-solid fa-check"></i></sui-table-header-cell>
                        <sui-table-header-cell></sui-table-header-cell>
                     </sui-table-row>
                </sui-table-header>
                <sui-table-body>
                    <sui-table-row v-for="unit in group.units" :key="unit.id" @click="unitWorkClicked(unit)">
                        <sui-table-cell>{{toUserFriendlyDate(unit.Date)}}</sui-table-cell>
                        <sui-table-cell>
                            <i class="fa-solid fa-clone" v-if="objIsDuplicate(unit, 'ConfigurationItem.Label', group.units)" style="color: orange; margin: 0 5px 0 5px;" title="Enheden er registreret mere end én gang på denne installation i perioden"/>
                            {{unit.ConfigurationItem.Label}}
                        </sui-table-cell>
                        <sui-table-cell><span v-if="unit.ConfigurationItem.Cabinet && unit.ConfigurationItem.Cabinet.name">{{unit.ConfigurationItem.Cabinet.name}}</span></sui-table-cell>
                        <sui-table-cell>{{unit.ConfigurationItem.Address}}</sui-table-cell>
                        <sui-table-cell><span style="white-space: pre-line;">{{unit.Description}}</span></sui-table-cell>
                        <sui-table-cell>{{ unitWorkerString(unit) }}</sui-table-cell>
                        <sui-table-cell>{{ payedByString(unit.PayedBy) }}</sui-table-cell>
                        <sui-table-cell>{{unit.Amount}}</sui-table-cell>
                        <sui-table-cell :title="`Prisen for dette arbejde svarer til prisen for ${decimalToHourString(unit.EquivalentHours)} timelønsarbejde`">{{decimalToHourString(unit.EquivalentHours)}}</sui-table-cell>
                        <sui-table-cell @click.stop="markUnitBilled(unit.id, !(unit.Billed && unit.Billed.Bool))">
                            <i v-if="unit.Billed && unit.Billed.Bool" class="fa-regular fa-circle-check" :title="`Godkendt ${toUserFriendlyTimestamp(unit.Billed.Updated.toDate())}`"></i>
                            <i v-else class="fa-regular fa-circle" title="Endnu ikke godkendt"></i>
                        </sui-table-cell>
                        <sui-table-cell>
                            <sui-button
                                    floated="right"
                                    size="mini"
                                    title="Flyt / kopier enhed til et andet projekt"
                                    style="padding: 9.87755px;"
                                    @click.stop="openUnitWorkTransferModal(unit)"
                                ><i class="fa-solid fa-copy"></i></sui-button>
                        </sui-table-cell>
                    </sui-table-row>
                </sui-table-body>
            </sui-table>
        </DataCard>
    </sui-container>
</template>
<script>
import { db } from '../../firebase'
import { Mixin } from '../../lib/Mixins/mixin'
import { DateMixin } from '../../lib/Mixins/dateMixin'
import { unitWorkMixin } from '../../lib/unitWorkMixin'
import EditUnitWorkModal from '../../components/Project/EditUnitWorkModal.vue'
import UnitWorkTransferModal from '../../components/Project/UnitWorkTransferModal.vue'
import EventBus from '../../EventBus'
import WorkDayModal from '../../components/Project/WorkDayModal.vue'
import { mapGetters } from 'vuex'
import UnitWorkOutputNoteModal from '../../components/Project/UnitWorkOutputNoteModal.vue'
import UserRoles from '../../lib/Enums/UserRoles'

export default {
    name:"UnitWorkList",

    mixins: [Mixin, DateMixin, unitWorkMixin],

    enums: {
        UserRoles
    },

    components: {EditUnitWorkModal, WorkDayModal, UnitWorkOutputNoteModal, UnitWorkTransferModal},

    data(){
        return {
            firebaseUnitWork: [],
            firebaseUsers: [],

            sortingCurrent: Number(window.localStorage.getItem('unit-work-list-sorting')) || 0,
            sortingOptions: [
                {
                    text: 'Stigende',
                    value: 0,
                },
                {
                    text: 'Faldende',
                    value: 1,
                },
            ],

            selectedDateRangeOption: 'THISWEEK',
            dateRangeOptions: [
                {
                    text: 'I dag',
                    value: 'TODAY'
                },
                {
                    text: 'I går',
                    value: 'YESTERDAY'
                },
                {
                    text: 'Denne uge',
                    value: 'THISWEEK'
                },
                {
                    text: 'Sidste uge',
                    value: 'LASTWEEK'
                },
                {
                    text: 'Denne måned',
                    value: 'THISMONTH'
                },
                {
                    text: 'Sidste måned',
                    value: 'LASTMONTH'
                },
                {
                    text: 'Brugerdefineret',
                    value: 'CUSTOM'
                },
            ],

            dateStartInput: null,
            dateEndInput: null,
            currentStartDate: null,
            currentEndDate: null,

            editUnitWorkModalOpen: false,
            transferUnitWorkModalOpen: false,
            activeUnitWork: null,

            firWorkDays: [],
            workDayModalOpen: false,
            activeWorkDayDate: null,
            activeWorkDay: null,

            outputNoteModalOpen: false,

            getFullProjectSums: false,
            fullProjectUnitWork: null,
            fullProjectWorkDays: null,

            serviceProviders: null,

            ignoreLeadPermissions: window.localStorage.getItem('admin-ignore-lead-permissions') == 'true',
        }
    },

    computed: {
        ...mapGetters({
            project: 'activeProject'
        }),
        unitWorkGroupedById(){
            let uniqueUnitIds = []
            for (let i in this.firebaseUnitWork) {
                let unitWork = this.firebaseUnitWork[i]
                if (uniqueUnitIds.findIndex(group => group.id == unitWork.Unit.Id) == -1) {
                    uniqueUnitIds.push({
                        id: unitWork.Unit.Id,
                        name: unitWork.Unit.Name,
                        unit: unitWork.Unit.Unit,
                        units: [unitWork]
                    })
                } else {
                    let unitIndex = uniqueUnitIds.findIndex(unit => unit.id == unitWork.Unit.Id)
                    uniqueUnitIds[unitIndex].units.push(unitWork)
                    uniqueUnitIds[unitIndex].units = uniqueUnitIds[unitIndex].units.sort((a,b) => this.sortingCurrent ? (a.Date > b.Date ? -1 : 1) : (a.Date > b.Date ? 1 : -1))
                }
            }
            return uniqueUnitIds.sort((a,b) => {
                return this.sortingCurrent ? (a.id > b.id ? -1 : 1) : (a.id > b.id ? 1 : -1)
            })
        },
        unitWorkGroupedByDate(){
            let uniqueDates = []
            for (let i in this.firebaseUnitWork) {
                let unitWork = this.firebaseUnitWork[i]
                let dateIndex = uniqueDates.findIndex(date => date.date == unitWork.Date)
                let workers = []
                for (let w in unitWork.Workers) {
                    workers.push(String(unitWork.Workers[w]).substring(0,String(unitWork.Workers[w]).indexOf('@')).toUpperCase())
                }
                if (dateIndex == -1) {
                    uniqueDates.push({
                        date: unitWork.Date,
                        equivalentHours: unitWork.EquivalentHours,
                        units: [unitWork],
                        workers,
                    })
                } else {
                    uniqueDates[dateIndex].equivalentHours += unitWork.EquivalentHours
                    uniqueDates[dateIndex].units.push(unitWork)
                    for(let j in workers) {
                        if (!uniqueDates[dateIndex].workers.includes(workers[j])){
                            uniqueDates[dateIndex].workers.push(workers[j])
                        }
                    }
                }
            }
            // console.log(uniqueDates)
            for (let i in uniqueDates) {
                let d = uniqueDates[i]
                let actualHoursOnDay = 0
                let firWorkDay = this.firWorkDays.find(workDay => workDay.Date == d.date)
                for (let workerInitials of d.workers) {
                    // let firebaseWorker = this.firebaseUsers.find(u => u.Initials.toUpperCase() == workerInitials.toUpperCase())
                    if (firWorkDay && firWorkDay.Workers) {
                        let workDayWorker = firWorkDay.Workers.find(w => this.getInitialsFromEmail(w.Email).toUpperCase() ==  workerInitials.toUpperCase())
                        if (workDayWorker && workDayWorker.ActualHours) {
                            actualHoursOnDay += workDayWorker.ActualHours
                        } else if (this.firebaseUser.Roles.includes('Planning')) {
                            actualHoursOnDay += this.sumUnitWorkForWorker(uniqueDates[i].units, `${workerInitials.toLowerCase()}@fiberlan.dk`).equivalentHours
                        } else {
                            actualHoursOnDay += this.standardHoursForDate(d.date)
                        }
                    } else if (this.firebaseUser.Roles.includes('Planning')) {
                        actualHoursOnDay += this.sumUnitWorkForWorker(uniqueDates[i].units, `${workerInitials.toLowerCase()}@fiberlan.dk`).equivalentHours
                    } else {
                        actualHoursOnDay += this.standardHoursForDate(d.date)
                    }
                }
                uniqueDates[i].actualHours = actualHoursOnDay
            }
            return this.sortingCurrent ? uniqueDates.sort((a,b) => a.date > b.date ? -1 : 1) : uniqueDates.sort((a,b) => a.date > b.date ? 1 : -1)
        },
        user(){
            return this.$parent.$parent.user
        },

        firebaseUser() {
            return this.$root.$children[0].firebaseUser
        },

        userHasLeadPermissions() {
            if (this.ignoreLeadPermissions) return false
            if (this.firebaseUser.Roles.includes(UserRoles.USER_ADMIN)) return true //Admins always have lead permissions
            if (!this.project || !this.project.Contacts) return false //If page has not loaded project Contacts yet, no projectLead can be found
            let userContact = this.project.Contacts.find(contact => contact.Email == this.user.email) //Find user in project contacts
            if (userContact.includes('ProjectLead')) return true //Allow lead permissions for project lead
            return false
        },
        workers(){
            return this.$parent.project.Workers
        },
        availableWorkers() {
            let workersArray = this.$parent.project.Workers

            let workerOptions = []
            for (let i in workersArray) {
                let w = workersArray[i]
                let firWorker = this.firebaseUsers.find((worker) => w.Email.toLowerCase() == worker.Email.toLowerCase())
                if (firWorker) {
                    workerOptions.push({key: i, value: w.Email.toLowerCase(), text: `${firWorker.Name} (${firWorker.Initials})`})
                }
            }

            return workerOptions
        },
        activeUnitWorkTask(){
            if (!this.activeUnitWork) return null
            return{
                configurationItem: {
                    area: this.activeUnitWork.ConfigurationItem?.Area,
                    label: this.activeUnitWork.ConfigurationItem?.Label,
                    cabinet: this.activeUnitWork.ConfigurationItem?.Cabinet,
                    technicalData: this.activeUnitWork.ConfigurationItem?.TechnicalData,
                    type: this.activeUnitWork.ConfigurationItem?.Type,
                    value: this.activeUnitWork.ConfigurationItem?.Value,
                    address: this.activeUnitWork.ConfigurationItem?.Address,
                },
                project: {
                    type: {
                        value: this.activeUnitWork.ProjectInstallationType || this.activeUnitWork.Project.Type
                    }
                },
                serviceOrder: this.activeUnitWork.ServiceOrder
            }
        },
        fullProjectTotals(){
            let workers = []
            let dates = []
            let unitWorkGroupedByDate = {}
            let actualHours = 0
            let billableHours = 0
            let billablePercent = 0

            let totalsGroupedByUser = {}

            for (let i in this.fullProjectUnitWork) {
                let unitWork = this.fullProjectUnitWork[i]
                billableHours += Number(unitWork.EquivalentHours)
                for (let w in unitWork.Workers) {
                    if (!totalsGroupedByUser[unitWork.Workers[w]]){
                        totalsGroupedByUser[unitWork.Workers[w]] = {email: unitWork.Workers[w], billableHours: 0, actualHours: 0, dates: []}
                    }
                    if (!totalsGroupedByUser[unitWork.Workers[w]].dates.includes(unitWork.Date)){
                        totalsGroupedByUser[unitWork.Workers[w]].dates.push(unitWork.Date)
                    }
                }
                if (!dates.includes(unitWork.Date)){
                    dates.push(unitWork.Date)
                }
                for (let w in unitWork.Workers) {
                    if (!workers.includes(unitWork.Workers[w])) {
                        workers.push(unitWork.Workers[w])
                    }
                    if (!totalsGroupedByUser[unitWork.Workers[w]]){
                        totalsGroupedByUser[unitWork.Workers[w]] = {email: unitWork.Workers[w], billableHours: 0, actualHours: 0, dates: []}
                    }
                    totalsGroupedByUser[unitWork.Workers[w]].billableHours += Number( unitWork.EquivalentHours / unitWork.Workers.length )
                }
                if (!unitWorkGroupedByDate[unitWork.Date]) {
                    unitWorkGroupedByDate[unitWork.Date] = [unitWork]
                } else {
                    unitWorkGroupedByDate[unitWork.Date].push(unitWork)
                }
            }

            for (let i in this.fullProjectWorkDays) {
                let workDay = this.fullProjectWorkDays[i]
                if (!dates.includes(workDay.Date)){
                    dates.push(workDay.Date)
                }
                for (let w in workDay.Workers) {
                    let workerEmail = workDay.Workers[w].Email
                    if (!totalsGroupedByUser[workerEmail].dates.includes(workDay.Date)){
                        totalsGroupedByUser[workerEmail].dates.push(workDay.Date)
                    }
                }
            }

            for (let i in dates){
                let date = dates[i]
                let foundDate = this.fullProjectWorkDays.find((workday) => workday.Date == date)
                var currentDaysWorkers = []
                for (let j in unitWorkGroupedByDate[date]) {
                    let unitWork = unitWorkGroupedByDate[date][j]
                    for (let w in unitWork.Workers) {
                        // let firebaseWorker = this.firebaseUsers.find(u => u.Email == unitWork.Workers[w])
                        let foundDateThisWorker = foundDate?.Workers?.find(value => value.Email == unitWork.Workers[w])
                        if (this.firebaseUser.Roles.includes('Planning') && !foundDateThisWorker) {
                            if (!currentDaysWorkers.includes(unitWork.Workers[w])) {
                                currentDaysWorkers.push(unitWork.Workers[w])
                            }
                            let hours = Number(unitWork.EquivalentHours / unitWork.Workers.length)
                            actualHours += hours
                            totalsGroupedByUser[unitWork.Workers[w]].actualHours += hours
                            // console.log(`Added ${Number(unitWork.EquivalentHours / unitWork.Workers.length)} actualHours to worker '${unitWork.Workers[w]}' on date ${date}\nNew total is: ${actualHours}`)
                        } else if (!currentDaysWorkers.includes(unitWork.Workers[w])) {
                            currentDaysWorkers.push(unitWork.Workers[w])
                            if (foundDateThisWorker) {
                                // console.log(`found worker ${foundDateThisWorker.Name} on date ${foundDate.Date}, adding ${foundDateThisWorker.ActualHours} hours`)
                                actualHours += Number(foundDateThisWorker.ActualHours)
                                totalsGroupedByUser[unitWork.Workers[w]].actualHours += Number(foundDateThisWorker.ActualHours)
                            } else {
                                let hours = this.standardHoursForDate(date)
                                actualHours += hours
                                totalsGroupedByUser[unitWork.Workers[w]].actualHours += hours
                            }
                        }
                    }
                }
            }

            let workerCount = workers.length
            // console.log(dates)
            let workDayCount = dates.length

            for (let w in totalsGroupedByUser) {
                if (!this.userHasLeadPermissions && String(totalsGroupedByUser[w].email) != String(this.user.email)){
                    delete totalsGroupedByUser[w]
                    continue
                }
                if (totalsGroupedByUser[w].actualHours){
                    totalsGroupedByUser[w].billablePercent = Number(totalsGroupedByUser[w].billableHours / totalsGroupedByUser[w].actualHours)
                } else {
                    totalsGroupedByUser[w].billablePercent = 0
                }
            }

            if (actualHours) {
                billablePercent = billableHours / actualHours
            }

            if (actualHours) {
                billablePercent = billableHours / actualHours
            }

            return {
                workers,
                workerCount,
                workDayCount,
                actualHours,
                billableHours,
                billablePercent,
                totalsGroupedByUser,
            }
        }
    },

    methods: {
        unitWorkerString(unit){
            let unitWorkerInitialsArray = []
            for (let i in unit.Workers) {
                let atIndex = String(unit.Workers[i]).indexOf('@')
                unitWorkerInitialsArray.push(String(unit.Workers[i]).substring(0,atIndex).toUpperCase())
            }
            return unitWorkerInitialsArray.join('/')
        },

        openUnitWorkTransferModal(unitworkUnit){
            console.log("Opening unit work transfer modal")
            this.activeUnitWork = unitworkUnit
            this.transferUnitWorkModalOpen = true
        },

        closeUnitWorkTransferModal(){
            this.transferUnitWorkModalOpen = false
        },

        unitSums(unitArray){
            let summedAmount = 0
            let summedEH = 0
            let billed = 0
            let billedEH = 0
            let insts = []
            let workers = []
            for (let i in unitArray) {
                summedAmount += unitArray[i].Amount
                summedEH += unitArray[i].EquivalentHours
                if (unitArray[i].Billed?.Bool) {
                    billed += 1
                    billedEH += unitArray[i].EquivalentHours
                }
                if (!insts.includes(unitArray[i].ConfigurationItem.Label)){
                    insts.push(unitArray[i].ConfigurationItem.Label)
                }
                for (let j in unitArray[i].Workers) {
                    if (typeof unitArray[i].Workers[j] != 'string') {
                        console.error(`Worker email '${unitArray[i].Workers[j]}' is not a string, attempting to coerce`)
                    }
                    let initials = String(unitArray[i].Workers[j]).substring(0, String(unitArray[i].Workers[j]).indexOf('@')).toUpperCase()
                    if (!workers.includes(initials)) {
                        workers.push(initials)
                    }
                }
            }
            return {
                summedAmount,
                summedEH,
                billed,
                billedEH,
                insts: insts.sort((a,b) => a>b ? 1 : -1),
                workers: workers.sort((a,b) => a>b ? 1 : -1),
            }
        },

        unitGroupSumString(unitGroup){
            const sums = this.unitSums(unitGroup.units)
            return `${sums.summedAmount} ${unitGroup.unit}, svarende til ${this.decimalToHourString(sums.summedEH)}`
        },

        unitArrayEHSumString(unitArray, includeBilledEH = false, includeBilledCount = false){
            let sums = this.unitSums(unitArray)
            let returnString = `I alt svarende til ${this.decimalToHourString(sums.summedEH)}`
            if (includeBilledEH) returnString += `, heraf ${this.decimalToHourString(sums.billedEH)} faktureret`
            if (includeBilledCount) returnString += `, ${sums.billed}/${unitArray.length} poster faktureret`
            return returnString
        },

        getLastDateOfMonth(monthNum, janIndex = 0, isLeapYear = false){
            let month = monthNum - janIndex + 1 //Subtacts the index of january, and adds one, to make months 1-indexed
            let correctionCount = 0

            while (month > 12 || month < 1) { //In case month is not between 1-12, subtract or add 12 until it is.
                correctionCount += 1
                if (correctionCount > 3) throw new Error('month still not valid after 3 corrections, quitting function')
                month < 1 ? month += 12 : month -= 12
            }

            switch(month){
                case 1: //Jan
                case 3: //Mar
                case 5: //May
                case 7: //Jul
                case 8: //Aug
                case 10: //Okt
                case 12: //Dec
                    return 31
                case 2: //Feb
                    return isLeapYear ? 29 : 28
                case 4: //Apr
                case 6: //Jun
                case 9: //Sep
                case 11: //Nov
                    return 30
            }
        },

        setCurrentDateRange(){
            this.currentStartDate = this.dateStartInput
            this.currentEndDate = this.dateEndInput
        },

        unitWorkClicked(unitWork){
            console.log(unitWork)
            this.activeUnitWork = unitWork
            this.editUnitWorkModalOpen = true
        },

        workDayClicked(date){
            this.activeWorkDayDate = date.date
            this.activeWorkDay = date
            this.workDayModalOpen = true
        },

        scroll(ref){
            try{
                this.$refs[ref][0].$el.scrollIntoView({ behavior: 'smooth' })
            } catch (error) {
                console.log(`Could not scroll to ref: '${ref}'`)
            }
        },

        payedByString(serviceProviderId) {
            if (this.serviceProviders && this.serviceProviders.find(sp => sp.Id == serviceProviderId)) {
                return this.serviceProviders.find(sp => sp.Id == serviceProviderId).Name
            } else {
                return serviceProviderId
            }
        },

        objIsDuplicate(obj, keyPath, array) {
            let comparator = this.resolveObjPath(keyPath, obj)
            let filteredArr = array.filter((arrObj) => this.resolveObjPath(keyPath, arrObj) == comparator)
            return filteredArr.length > 1
        },
    },

    beforeMount() {
        EventBus.$on('edit-unit-work-modal-close', () => {this.editUnitWorkModalOpen = false})
        EventBus.$on('workday-modal-close', () => {this.workDayModalOpen = false})
        EventBus.$on('get-project-sums-button-click', () => {this.getFullProjectSums = true})
        EventBus.$on('open-output-note-modal-button-click', () => {this.outputNoteModalOpen = true})
        EventBus.$on('unitwork-output-note-modal-close', () => {this.outputNoteModalOpen = false})
        EventBus.$on('admin-ignore-lead-permissions', (value) => {this.ignoreLeadPermissions = value})
        this.$bind('serviceProviders', db.collection('ServiceProviders'))
    },

    mounted() {
        localStorage.setItem('last-visited-page','unitWork')
        this.$bind('firebaseUsers', db.collection('Users'))

        this.activeWorkDayDate = this.formatMachineDate(new Date(), '-')
    },

    watch: {
        selectedDateRangeOption: {
            immediate: true,
            handler(selectedDateRangeOption){
                let today = new Date()
                let firstDateOfWeek = new Date(today.setDate(today.getDate() - today.getDay() + 1))
                let lastDateOfWeek = new Date(today.setDate(today.getDate() - today.getDay() + 7))
                today = new Date()
                today.setDate(today.getDate()-7)
                let firstDateOfLastWeek = new Date(today.setDate(today.getDate() - today.getDay() + 1))
                let lastDateOfLastWeek =  new Date(today.setDate(today.getDate() - today.getDay() + 7))
                today = new Date()
                let oneMonthAgo = new Date(today.setMonth(today.getMonth()-1))
                today = new Date()
                let yesterday = new Date()
                yesterday.setDate(new Date().getDate()-1)
                switch (selectedDateRangeOption){
                    case 'TODAY': 
                        this.dateStartInput = `${today.getFullYear()}-${String(today.getMonth()+1).padStart(2,"0")}-${String(today.getDate()).padStart(2,"0")}`
                        this.currentStartDate = `${today.getFullYear()}-${String(today.getMonth()+1).padStart(2,"0")}-${String(today.getDate()).padStart(2,"0")}`
                        this.dateEndInput = `${today.getFullYear()}-${String(today.getMonth()+1).padStart(2,"0")}-${String(today.getDate()).padStart(2,"0")}`
                        this.currentEndDate = `${today.getFullYear()}-${String(today.getMonth()+1).padStart(2,"0")}-${String(today.getDate()).padStart(2,"0")}`
                        break;
                    case 'YESTERDAY': 
                        this.dateStartInput = `${yesterday.getFullYear()}-${String(yesterday.getMonth()+1).padStart(2,"0")}-${String(yesterday.getDate()).padStart(2,"0")}`
                        this.currentStartDate = `${yesterday.getFullYear()}-${String(yesterday.getMonth()+1).padStart(2,"0")}-${String(yesterday.getDate()).padStart(2,"0")}`
                        this.dateEndInput = `${yesterday.getFullYear()}-${String(yesterday.getMonth()+1).padStart(2,"0")}-${String(yesterday.getDate()).padStart(2,"0")}`
                        this.currentEndDate = `${yesterday.getFullYear()}-${String(yesterday.getMonth()+1).padStart(2,"0")}-${String(yesterday.getDate()).padStart(2,"0")}`
                        break;
                    case 'THISWEEK': 
                        this.dateStartInput = `${firstDateOfWeek.getFullYear()}-${String(firstDateOfWeek.getMonth()+1).padStart(2,"0")}-${String(firstDateOfWeek.getDate()).padStart(2,"0")}`
                        this.currentStartDate = `${firstDateOfWeek.getFullYear()}-${String(firstDateOfWeek.getMonth()+1).padStart(2,"0")}-${String(firstDateOfWeek.getDate()).padStart(2,"0")}`
                        this.dateEndInput = `${lastDateOfWeek.getFullYear()}-${String(lastDateOfWeek.getMonth()+1).padStart(2,"0")}-${String(lastDateOfWeek.getDate()).padStart(2,"0")}`
                        this.currentEndDate = `${lastDateOfWeek.getFullYear()}-${String(lastDateOfWeek.getMonth()+1).padStart(2,"0")}-${String(lastDateOfWeek.getDate()).padStart(2,"0")}`
                        break;
                    case 'LASTWEEK': 
                        this.dateStartInput = `${firstDateOfLastWeek.getFullYear()}-${String(firstDateOfLastWeek.getMonth()+1).padStart(2,"0")}-${String(firstDateOfLastWeek.getDate()).padStart(2,"0")}`
                        this.currentStartDate = `${firstDateOfLastWeek.getFullYear()}-${String(firstDateOfLastWeek.getMonth()+1).padStart(2,"0")}-${String(firstDateOfLastWeek.getDate()).padStart(2,"0")}`
                        this.dateEndInput = `${lastDateOfLastWeek.getFullYear()}-${String(lastDateOfLastWeek.getMonth()+1).padStart(2,"0")}-${String(lastDateOfLastWeek.getDate()).padStart(2,"0")}`
                        this.currentEndDate = `${lastDateOfLastWeek.getFullYear()}-${String(lastDateOfLastWeek.getMonth()+1).padStart(2,"0")}-${String(lastDateOfLastWeek.getDate()).padStart(2,"0")}`
                        break;
                    case 'THISMONTH': 
                        this.dateStartInput = `${today.getFullYear()}-${String(today.getMonth()+1).padStart(2,"0")}-01`
                        this.currentStartDate = `${today.getFullYear()}-${String(today.getMonth()+1).padStart(2,"0")}-01`
                        this.dateEndInput = `${today.getFullYear()}-${String(today.getMonth()+1).padStart(2,"0")}-${this.getLastDateOfMonth(today.getMonth(), 0, ((today.getFullYear() % 4 == 0) && (today.getFullYear() % 100 != 0)) || (today.getFullYear() % 400 == 0))}`
                        this.currentEndDate = `${today.getFullYear()}-${String(today.getMonth()+1).padStart(2,"0")}-${this.getLastDateOfMonth(today.getMonth(), 0, ((today.getFullYear() % 4 == 0) && (today.getFullYear() % 100 != 0)) || (today.getFullYear() % 400 == 0))}`
                        break;
                    case 'LASTMONTH':
                        this.dateStartInput = `${oneMonthAgo.getFullYear()}-${String(oneMonthAgo.getMonth()+1).padStart(2,"0")}-01`
                        this.currentStartDate = `${oneMonthAgo.getFullYear()}-${String(oneMonthAgo.getMonth()+1).padStart(2,"0")}-01`
                        this.dateEndInput = `${oneMonthAgo.getFullYear()}-${String(oneMonthAgo.getMonth()+1).padStart(2,"0")}-${this.getLastDateOfMonth(oneMonthAgo.getMonth(), 0, ((oneMonthAgo.getFullYear() % 4 == 0) && (oneMonthAgo.getFullYear() % 100 != 0)) || (oneMonthAgo.getFullYear() % 400 == 0))}`
                        this.currentEndDate = `${oneMonthAgo.getFullYear()}-${String(oneMonthAgo.getMonth()+1).padStart(2,"0")}-${this.getLastDateOfMonth(oneMonthAgo.getMonth(), 0, ((oneMonthAgo.getFullYear() % 4 == 0) && (oneMonthAgo.getFullYear() % 100 != 0)) || (oneMonthAgo.getFullYear() % 400 == 0))}`
                        break;
                    case 'CUSTOM': 
                        console.log('please input start and end dates')
                        break;
                }
                console.log(`showing unitWork for date-range ${this.currentStartDate} to ${this.currentEndDate}`)
            }
        }, 
        currentStartDate: {
            immediate: true,
            handler() {
                this.$bind('firebaseUnitWork', db.collection('UnitWork')
                    .where('LinkedProjects','array-contains',this.$route.params.projectIdentifier)
                    .where('Date','>=',this.currentStartDate).where('Date','<=',this.currentEndDate)
                )
                this.$bind('firWorkDays', db.collection(`Projects/${this.$route.params.projectIdentifier}/WorkDays`)
                    .where('Date','>=',this.currentStartDate).where('Date','<=',this.currentEndDate)
                )
                console.log(this.firebaseUnitWork)
            }
        },
        currentEndDate: {
            immediate: true,
            handler() {
                this.$bind('firebaseUnitWork', db.collection('UnitWork')
                    .where('LinkedProjects','array-contains',this.$route.params.projectIdentifier)
                    .where('Date','>=',this.currentStartDate).where('Date','<=',this.currentEndDate)
                )
                this.$bind('firWorkDays', db.collection(`Projects/${this.$route.params.projectIdentifier}/WorkDays`)
                    .where('Date','>=',this.currentStartDate).where('Date','<=',this.currentEndDate))
            }
        },
        getFullProjectSums: {
            immediate: true,
            handler(getFullProjectSums){
                if (!getFullProjectSums) return
                this.$bind('fullProjectUnitWork', db.collection('UnitWork')
                    .where('LinkedProjects','array-contains',this.$route.params.projectIdentifier)
                )
                this.$bind('fullProjectWorkDays', db.collection(`Projects/${this.$route.params.projectIdentifier}/WorkDays`))
            }
        },
        project: {
            immediate: true,
            handler(){
                document.title = `${this.project.Name} Udført arbejde - FiberTeam`
            }
        },
        sortingCurrent: {
            immediate: false,
            handler(sorting){
                localStorage.setItem('unit-work-list-sorting', sorting)
            }
        },
    }
}
</script>
<style scoped>
    table.selectable tr:hover td {
        cursor: pointer;
    }
</style>