
class ChangeLogEntry {
    constructor(user){

        this.date = this.timestamp()
        this.user = {
            displayName: user.displayName,
            email: user.email,
        }
        this.entries = []
    }

    setEntry(aPath, friendlyName, oldVal, newVal) {
        let entry = {path: aPath, friendlyFieldName: friendlyName, old: oldVal, new: newVal}
        this.entries.push(entry)
    }

    setNote(text) {
        let entry = {type: 'note', noteBody: text}
        this.entries.push(entry)
    }

    getFirebaseDoc(){
        let doc = {
            date: this.date,
            user: this.user,
            changes: this.entries
        }
        return doc
    }

    timestamp(){
        let date = new Date()
        return date.toISOString()
    }
}

export default ChangeLogEntry