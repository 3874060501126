<template>
    <div>
        <sui-modal
            size="mini"
            id="editAppointmentStateModal"
            :open="isOpen"
            v-on:clickAwayModal="clickAway()"
        >
            <sui-modal-header>
                Ændre state på aftalen
            </sui-modal-header>
            <sui-modal-content v-if="appointment">
                <sui-form>
                    <sui-form-field 
                        inline
                        style="margin-bottom: 100pt" 
                    >
                        <label>Nyt state</label>
                        <sui-dropdown
                            :placeholder=currentAppointmentState
                            selection
                            :options="appointmentStateOptions"
                            v-model="newState"
                        />
                    </sui-form-field>
                </sui-form>
            </sui-modal-content>
            <sui-modal-actions>
                <sui-button 
                    negative
                    @click.prevent="clickAway"
                >
                    Luk
                </sui-button>
                <sui-button 
                    positive
                    @click.prevent="updateAppointmentState"
                >
                    Gem
                </sui-button>
            </sui-modal-actions>
        </sui-modal>
    </div>
</template>
<script>
import swal from 'sweetalert'
import { Mixin } from '../../lib/Mixins/mixin'
import { DataAPI } from '../../lib/DataAPI'
import { mapGetters } from 'vuex'
import EventBus from '../../EventBus'
import AppointmentState from '../../lib/Enums/AppointmentState'

export default {
    name: 'SubtaskStateChangeForm',
    mixins: [Mixin, DataAPI],
    enums: {AppointmentState},
    
    props: {
        isOpen: Boolean,
        appointmentID: String,
    },

    data() {
        return {
            appointmentStateOptions: AppointmentState.DropdownOptions,
            currentAppointmentState: 'null',
            newState: null,
            appointment: null,
            // activeSubtaskValue: null,
            // activeSubtaskResponsible: null,
            // activeSubtaskResponsibleEmail: null,
            // activeSubtaskDeferred: false,
            // activeSubtaskCompletedBy: [],

            // documentationErrorReportModalOpen: false,

            // firebaseUser: null
        }
    },

    computed: {
        ...mapGetters({
            project: 'activeProject',
            // user: 'userProfile'
        }),

    },

    methods: {
        clickAway(){
            this.clearForm()
            EventBus.$emit('edit-appointment-state-modal-closing')
        },

        clearForm(){
            this.appointment = null
            this.currentAppointmentState = null
            this.newState = null
        },

        async prePopulateForm(){
            console.log("Really cool pre population of data")
            console.log("Appointment ID", this.appointmentID)

            if (this.isOpen && this.appointmentID){
                this.appointment = await this.dataGetAppointmentById(this.project.id, this.appointmentID)
                this.currentAppointmentState = this.appointment.State.toUpperCase()
                console.log("Appointment", this.appointment)
            }           
        },

        async updateAppointmentState(){
            EventBus.$emit('function-activity', {functionName: 'EditAppointmentState_updateAppointmentState', isActive: true})
            console.log("Updating state for appointment:")
            console.log("New state",this.newState)

            let shouldUpdate = await swal({
                title: 'Er du sikker på at du vil ændre state?',
                text: `Fra: ${this.currentAppointmentState} Til: ${this.newState.toUpperCase()}`,
                icon: 'warning',
                buttons: ['Nej','Ja'],
            })

            if (shouldUpdate) {
                await this.dataUpdateAppointmentState(this.appointmentID, {State: this.newState})
                this.clickAway()
            }
            
            EventBus.$emit('function-activity', {functionName: 'EditAppointmentState_updateAppointmentState', isActive: false})
        },
    },

    watch: {
        isOpen: {
            handler(open){
                if (open) {
                    this.prePopulateForm()
                }
            }
        }
    }
}
</script>