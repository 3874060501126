<template>
    <div class="loading-wrapper">
        <div :class="this.classes(
            this.overlay && 'loading-overlay'
        )">
            <div v-if="active" class="ui active text loader">
                {{ content }}
            </div>
        </div>
    </div>
</template>
<script>
import { Mixin } from '../lib/Mixins/mixin'

export default {
    name: 'TableLoader',
    mixins: [Mixin],

    props: {
        active: {
            type: Boolean,
            default: true
        },
        content: {
            type: String,
            default: 'Loading...'
        },
        overlay: {
            type: Boolean,
            default: false
        }
    }
}
</script>
<style scoped>
/*.loading-wrapper {
    transform: rotate(0deg);
}
*/
.ui.loader {
    position: relative !important;
    margin-top: 4.5rem !important;
}
/*
.loading-overlay {
    position: absolute;
    z-index: 99;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: rgb(234 234 234 / 80%);
}*/
</style>
