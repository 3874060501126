<template>
    <div class="illness-stats-page">
        <portal to="page-title">
            <h1>Sygdoms-statistik</h1>
        </portal>
        <portal to="page-actions">
            <div style="zoom: 0.85">
                <label>Tidsinterval:</label> &nbsp;
                <sui-dropdown
                        placeholder="Vælg interval"
                        selection
                        button
                        pointing="top right"
                        :options="dateRangeOptions"
                        v-model="selectedDateRange"
                        @input="selectDateRange(selectedDateRange)"
                    />
                &nbsp;&nbsp;&nbsp;
                <label>Sortering:</label> &nbsp;
                <sui-dropdown
                        placeholder="Sortér efter"
                        selection
                        button
                        pointing="top right"
                        :options="sortingOptions"
                        v-model="selectedSorting"
                    />
            </div>
        </portal>
        <sui-modal
            v-model="activeFitterModalOpen"
        >
            <sui-modal-header>
                <span>{{ activeFitterIllness.fitterName }}</span>
            </sui-modal-header>
            <sui-modal-content scrolling>
                <IllnessStatsFitter
                    :fitterIllness="activeFitterIllness"
                    :dataIsReady="activeFitterDataReady"
                />
            </sui-modal-content>
        </sui-modal>
        <small><em v-if="fromDate && toDate">Viser sygdomsstatistik fra E-komplet for {{ toUserFriendlyDate(fromDate) }} til {{ toUserFriendlyDate(toDate) }}</em></small>
        <sui-segment>
            <h2>Egen sygdom</h2>
            <IllnessStatsFitter
                :fitterIllness="myIllness"
                :dataIsReady="myIllnessDataReady"
            />
        </sui-segment>
        <sui-segment v-if="userHasHourCheckRole">
            <div class="row" v-if="newIllnessStatsTotal">
                <div class="col-sm-4">
                    <h2>Ugedag</h2>
                    <Bar 
                        chart-id="total-weekday-chart" 
                        :chart-data="totalWeekdayChartData" 
                        :chart-options="illnessChartOptions"
                    />
                </div>
                <div class="col-sm-4">
                    <h2>Måned</h2>
                    <Bar 
                        chart-id="total-month-chart" 
                        :chart-data="totalMonthChartData" 
                        :chart-options="illnessChartOptions"
                    />
                </div>
                <div class="col-sm-4">
                    <h2>Afdeling</h2>
                    <Bar 
                        chart-id="total-department-chart" 
                        :chart-data="totalDepartmentChartData" 
                        :chart-options="illnessChartOptions"
                    />
                </div>
            </div>
            <h2>Alt sygdom efter montør</h2>
            <sui-table selectable v-if="newIllnessStatsTotal">
                <sui-table-header>
                    <sui-table-row>
                        <sui-table-header-cell>Montør</sui-table-header-cell>
                        <sui-table-header-cell>Sygetimer</sui-table-header-cell>
                        <sui-table-header-cell>%Sygetimer</sui-table-header-cell>
                        <sui-table-header-cell>Sygedage</sui-table-header-cell>
                        <sui-table-header-cell>%Sygedage</sui-table-header-cell>
                        <sui-table-header-cell>Afdeling</sui-table-header-cell>
                    </sui-table-row>
                    <sui-table-row>
                        <sui-table-header-cell>I alt</sui-table-header-cell>
                        <sui-table-header-cell>{{ newIllnessStatsTotal.totalIllnessHours }}</sui-table-header-cell>
                        <sui-table-header-cell>{{ formatPercent(newIllnessStatsTotal.illnessHoursPercentage) }}</sui-table-header-cell>
                        <sui-table-header-cell>{{ newIllnessStatsTotal.totalIllnessDays }}</sui-table-header-cell>
                        <sui-table-header-cell>{{ formatPercent(newIllnessStatsTotal.illnessDaysPercentage) }}</sui-table-header-cell>
                        <sui-table-header-cell></sui-table-header-cell>
                    </sui-table-row>
                </sui-table-header>
                <sui-table-body>
                    <sui-table-row v-for="fitter of sortedGroupedIllness" :key="fitter.fitterSalaryID" @click="selectFitterIllness(fitter)" class="clickable">
                        <sui-table-cell>{{ fitter.fitterName }}</sui-table-cell>
                        <sui-table-cell>{{ fitter.totalIllnessHours }}</sui-table-cell>
                        <sui-table-cell>{{ formatPercent(fitter.illnessHoursPercentage) }}</sui-table-cell>
                        <sui-table-cell>{{ fitter.totalIllnessDays }}</sui-table-cell>
                        <sui-table-cell>{{ formatPercent(fitter.illnessDaysPercentage) }}</sui-table-cell>
                        <sui-table-cell>{{ fitter.department }}</sui-table-cell>
                    </sui-table-row>
                </sui-table-body>
            </sui-table>
        </sui-segment>
    </div>
</template>
<script>
import EKomplet from '@/lib/DataProviders/EKomplet.js'
import { Mixin } from '@/lib/Mixins/mixin.js'
import { DateMixin } from '../../lib/Mixins/dateMixin'
// import { FitterHourMixin } from '@/lib/Mixins/FitterHourMixin.js'
// import swal from 'sweetalert'
import EventBus from '@/EventBus.js'
import UserRoles from '@/lib/Enums/UserRoles.js'
import IllnessStatsFitter from '../../components/Salary/IllnessStatsFitter.vue'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
import { Bar } from 'vue-chartjs/legacy'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

const EKompletInstance = new EKomplet()

export default {
    name: 'IllnessStatsPage',
    mixins: [Mixin, DateMixin /* , FitterHourMixin */],
    components: { IllnessStatsFitter, Bar },
    enums: { UserRoles },

    data() {
        return {
            selectedDateRange: null,
            fromDate: null,
            toDate: null,
            dateRangeOptions: [
                { value: '1w', text: 'Seneste uge' },
                { value: '1mo', text: 'Seneste måned' },
                { value: '3mo', text: 'Seneste 3 måneder' },
                { value: '6mo', text: 'Seneste 6 måneder' },
                { value: '12mo', text: 'Seneste 12 måneder' },
            ],
            sortingOptions: [
                { value: 'totalIllnessHours', text: 'Sygdoms-timer' },
                { value: 'illnessHoursPercentage', text: '% Sygdoms-timer' },
                { value: 'totalIllnessDays', text: 'Sygedage' },
                { value: 'illnessDaysPercentage', text: '% Sygedage' },
                { value: 'fitterName', text: 'Navn (alfabetisk)' },
                { value: 'department', text: 'Afdeling'}
            ],
            selectedSorting: 'totalIllnessHours',
            monthListTemplate: [],
            
            newIllnessStatsGrouped: {},
            newIllnessStatsTotal: null,
            newIllnessStatsByMonth: [],

            activeFitterIllness: {},
            activeFitterModalOpen: false,
            activeFitterDataReady: false,

            myIllnessDataReady: false,
            illnessChartOptions: {
                responsive: true,
                scales: {
                    y: {
                        type: 'linear',
                        display: true,
                        position: 'left',
                    },
                    y1: {
                        type: 'linear',
                        display: true,
                        position: 'right',
                        grid: {
                            drawOnChartArea: false,
                        },
                    },
                },
            },
        };
    },
    methods: {
        clearData() {
            this.fromDate = null;
            this.toDate = null;
            this.newIllnessStatsGrouped = {};
            this.newIllnessStatsTotal = null;
            this.newIllnessStatsByMonth = [];
            this.allIllness = null;
            this.allIllnessGrouped = {};
            this.totalIllnessObj = {
                totalIllnessHours: 0,
                totalIllnessDays: 0,
                illnessByWeekday: [
                    {label: 'Man', value: 0},
                    {label: 'Tirs', value: 0},
                    {label: 'Ons', value: 0},
                    {label: 'Tors', value: 0},
                    {label: 'Fre', value: 0},
                ],
                illnessByDepartment: [],
                illnessByMonth: [],
                illnessDates: [],
                illnesses: [],
            };
            this.activeFitterDataReady = false;
            this.myIllnessDataReady = false;
        },
        async selectDateRange(rangeValue) {
            console.log(`Date range selected: ${rangeValue}`);
            this.clearData();
            let toDate = new Date();
            this.toDate = toDate.setDate(toDate.getDate() - 1);
            let dateVariable = new Date();
            switch (rangeValue) {
                case '1w':
                    dateVariable.setDate(dateVariable.getDate() - 7);
                    break;
                case '1mo':
                    dateVariable.setMonth(dateVariable.getMonth() - 1);
                    break;
                case '3mo':
                    dateVariable.setMonth(dateVariable.getMonth() - 3);
                    break;
                case '6mo':
                    dateVariable.setMonth(dateVariable.getMonth() - 6);
                    break;
                case '12mo':
                    dateVariable.setMonth(dateVariable.getMonth() - 12);
                    break;
            }
            this.fromDate = dateVariable;
            this.generateMonthYearList(dateVariable, toDate);
            console.log(`Getting illness logs for date range from ${dateVariable} to ${toDate}`);
            // this.getMyIllness(dateVariable, toDate).then((result) => {
            //     this.myIllness = result;
            // });
            this.getNewIllnessStats(dateVariable, toDate);
            // if (this.userHasHourCheckRole) {
            //     this.getAllIllness(dateVariable, toDate).then((result) => {
            //         this.allIllness = result;
            //         this.allIllnessGrouped = this.groupIllnessByFitter(result);
            //     });
            // }
        },
        generateMonthYearList(fromDate, toDate) {
            let tempDate = new Date(fromDate);
            let monthYearList = [];
            let i = 0
            while ((tempDate.getMonth() <= toDate.getMonth() || tempDate.getFullYear() < toDate.getFullYear()) && i < 100) { //Max 100 months as safety
                i++
                let monthIdString = `${tempDate.getFullYear()}-${String(tempDate.getMonth()+1).padStart(2, '0')}`
                let monthLabel = `${['N/A', 'Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'][tempDate.getMonth()+1]} ${String(tempDate.getFullYear()).substring(2)}`
                monthYearList.push({idString: monthIdString, label: monthLabel, totalHours: 0, illnessHours: 0, totalDays:0, illnessDays: 0, nonIllnessHours: 0, nonIllnessDays: 0})
                tempDate.setMonth(tempDate.getMonth()+1)
            }
            this.monthListTemplate = monthYearList
            this.newIllnessStatsByMonth = monthYearList
        },
        async getNewIllnessStats(fromDate, toDate) {
            EventBus.$emit('function-activity', { functionName: 'illnessStatsPage_getNewIllnessStats', isActive: true });
            let page = 1
            const pageSize = 1000
            let totalObjectCount = 0
            let currentObjectCount = 0
            let query = `fromDate=${fromDate.toISOString()}&toDate=${toDate.toISOString()}`
            if (!this.userHasHourCheckRole) {
                query += `&searchAttribute=FitterSalaryID&search=${this.userInitials}`
            } else {
                // query += `&searchAttribute=CategoryName&search=SYG`
            }
            query +=`&page=${page}&pageSize=${pageSize}`
            let response = await EKompletInstance.callAPI('get', '/api/v3.0/fitterhours', query)
            console.log(response)
            this.groupIllnessStats(response.data?.data?.[0]?.data)
            totalObjectCount = response.data?.data?.[0].total
            currentObjectCount += response.data?.data?.[0]?.data.length || 0
            let promises = []
            while (currentObjectCount < totalObjectCount && page < 100) { //Max 100 pages
                page++
                // query = `fromDate=${fromDate.toISOString()}&toDate=${toDate.toISOString()}&searchAttribute=FitterSalaryID&search=${this.userInitials}&page=${page}&pageSize=${pageSize}`
                let pageIndex = query.indexOf('&page=')
                query = query.substring(0, pageIndex) + `&page=${page}&pageSize=${pageSize}`
                promises.push(
                    EKompletInstance.callAPI('get', '/api/v3.0/fitterhours', String(query)).then((response) => {
                        console.log(response)
                        return this.groupIllnessStats(response.data?.data?.[0]?.data)
                    })
                )
                currentObjectCount += pageSize
            }
            await Promise.all(promises)
            // console.log(Object.keys(this.newIllnessStatsGrouped).length, ' fitters found')
            for (let month in this.newIllnessStatsByMonth) {
                this.$set(this.newIllnessStatsByMonth[month], 'value', this.newIllnessStatsByMonth[month].illnessHours)
            }
            this.myIllnessDataReady = false
            this.myIllnessDataReady = true
            EventBus.$emit('function-activity', { functionName: 'illnessStatsPage_getNewIllnessStats', isActive: false });
        },
        groupIllnessStats(fitterHoursArray) {
            console.log(`Grouping illness stats for ${fitterHoursArray.length} entries`);
            const fitterTemplate = {
                totalHours: 0,
                totalDays: 0,
                totalHoursByWeekday: [0,0,0,0,0,0,0],
                totalIllnessHours: 0,
                illnessHoursByWeekday: [0,0,0,0,0,0,0],
                illnessHoursPercentageByWeekday: [0,0,0,0,0,0,0],
                illnessHoursPercentage: 0,
                totalIllnessDays: 0,
                illnessDaysPercentage: 0,
                totalNonIllnessHours: 0,
                nonIllnessHoursByWeekday: [0,0,0,0,0,0,0],
                totalNonIllnessDays: 0,
                allDates: [],
                illnessDates: [],
                nonIllnessDates: [],
                statsByMonth: this.cloneJson(this.monthListTemplate),
                fitterName: '',
                fitterSalaryID: '',
            };
            // console.log(`Number of months: ${this.monthListTemplate.length}`)
            for (let entry of fitterHoursArray) {
                let jsWeekday = this.parseDate(entry.Date).getDay()
                if (jsWeekday === 0) jsWeekday = 7  //Change sunday from 0 to 7
                let weekDayIndex = jsWeekday - 1  //0-indexed weekday starting from monday
                let monthIdString = `${entry.Year}-${String(entry.Month).padStart(2, '0')}`
                let monthIndex = this.monthListTemplate.findIndex(month => month.idString == monthIdString)
                // console.log(`This month: ${monthIdString} (Index ${monthIndex})`)
                let fitterSalaryID = entry.FitterSalaryID.replaceAll(/(\s+)|(\d+)/g, '') //Remove any whitespace and numbers from the fitter ID
                // console.log(`Fitter ID ${entry.FitterSalaryID} -> ${fitterSalaryID}`)
                
                //TOTAL ENTRIES
                //TOTAL HOURS
                if (!this.newIllnessStatsTotal) {
                    this.newIllnessStatsTotal = this.cloneJson(fitterTemplate);
                    this.newIllnessStatsTotal.fitterName = 'Total';
                    this.newIllnessStatsTotal.fitterSalaryID = 'TOTAL';
                    this.newIllnessStatsTotal.byDepartment = {};
                }
                this.$set(this.newIllnessStatsTotal, 'totalHours', (Number(this.newIllnessStatsTotal.totalHours) + Number(entry.Hours)))
                this.$set(this.newIllnessStatsTotal.totalHoursByWeekday, weekDayIndex, (Number(this.newIllnessStatsTotal.totalHoursByWeekday[weekDayIndex]) + Number(entry.Hours)))
                if (!this.newIllnessStatsTotal.allDates.includes(this.formatMachineDate(entry.Date))) {
                    this.newIllnessStatsTotal.allDates.push(this.formatMachineDate(entry.Date));
                    this.$set(this.newIllnessStatsTotal, 'totalDays', (Number(this.newIllnessStatsTotal.totalDays) + 1))
                    this.$set(this.newIllnessStatsByMonth[monthIndex], 'totalDays', (Number(this.newIllnessStatsByMonth[monthIndex].totalDays) + 1))
                }
                this.$set(this.newIllnessStatsByMonth[monthIndex], 'totalHours', (Number(this.newIllnessStatsByMonth[monthIndex].totalHours) + Number(entry.Hours)))
                //ILLNESS HOURS
                if (entry.CategoryName.includes('SYG')) {
                    this.$set(this.newIllnessStatsTotal, 'totalIllnessHours', (Number(this.newIllnessStatsTotal.totalIllnessHours) + Number(entry.Hours)))
                    this.$set(this.newIllnessStatsTotal.illnessHoursByWeekday, weekDayIndex, (Number(this.newIllnessStatsTotal.illnessHoursByWeekday[weekDayIndex]) + Number(entry.Hours)))
                    if (!this.newIllnessStatsTotal.illnessDates.includes(this.formatMachineDate(entry.Date))) {
                        this.newIllnessStatsTotal.illnessDates.push(this.formatMachineDate(entry.Date));
                        this.$set(this.newIllnessStatsTotal, 'totalIllnessDays', (Number(this.newIllnessStatsTotal.totalIllnessDays) + 1))
                        this.$set(this.newIllnessStatsByMonth[monthIndex], 'totalIllnessDays', (Number(this.newIllnessStatsByMonth[monthIndex].totalIllnessDays) + 1))
                    }
                    this.$set(this.newIllnessStatsByMonth[monthIndex], 'illnessHours', (Number(this.newIllnessStatsByMonth[monthIndex].illnessHours) + Number(entry.Hours)))
                //NON-ILLNESS HOURS
                } else {
                    this.$set(this.newIllnessStatsTotal, 'totalNonIllnessHours', (Number(this.newIllnessStatsTotal.totalNonIllnessHours) + Number(entry.Hours)))
                    this.$set(this.newIllnessStatsTotal.nonIllnessHoursByWeekday, weekDayIndex, (Number(this.newIllnessStatsTotal.nonIllnessHoursByWeekday[weekDayIndex]) + Number(entry.Hours)))
                    if (!this.newIllnessStatsTotal.nonIllnessDates.includes(this.formatMachineDate(entry.Date))) {
                        this.newIllnessStatsTotal.nonIllnessDates.push(this.formatMachineDate(entry.Date));
                        this.$set(this.newIllnessStatsTotal, 'totalNonIllnessDays', (Number(this.newIllnessStatsTotal.totalNonIllnessDays) + 1))
                        this.$set(this.newIllnessStatsByMonth[monthIndex], 'nonIllnessDays', (Number(this.newIllnessStatsByMonth[monthIndex].nonIllnessDays) + 1))
                    }
                    this.$set(this.newIllnessStatsByMonth[monthIndex], 'nonIllnessHours', (Number(this.newIllnessStatsByMonth[monthIndex].nonIllnessHours) + Number(entry.Hours)))
                }
                //PERCENTAGES
                this.$set(this.newIllnessStatsTotal, 'illnessHoursPercentage', (this.newIllnessStatsTotal.totalIllnessHours / this.newIllnessStatsTotal.totalHours * 100))
                this.$set(this.newIllnessStatsTotal, 'illnessDaysPercentage', (this.newIllnessStatsTotal.totalIllnessDays / this.newIllnessStatsTotal.totalDays * 100))
                this.$set(this.newIllnessStatsTotal.illnessHoursPercentageByWeekday, weekDayIndex, ((this.newIllnessStatsTotal.illnessHoursByWeekday[weekDayIndex] / this.newIllnessStatsTotal.totalHoursByWeekday[weekDayIndex]) * 100))
                this.$set(this.newIllnessStatsByMonth[monthIndex], 'illnessHoursPercentage', (this.newIllnessStatsByMonth[monthIndex].illnessHours / this.newIllnessStatsByMonth[monthIndex].totalHours * 100))

                //BY DEPARTMENT
                if (!this.newIllnessStatsTotal.byDepartment[entry.Department]) {
                    this.newIllnessStatsTotal.byDepartment[entry.Department] = this.cloneJson(fitterTemplate);
                    this.newIllnessStatsTotal.byDepartment[entry.Department].fitterName = entry.Department;
                    this.newIllnessStatsTotal.byDepartment[entry.Department].fitterSalaryID = entry.Department;
                    this.newIllnessStatsTotal.byDepartment[entry.Department].department = entry.Department;
                }
                //TOTAL HOURS
                this.$set(this.newIllnessStatsTotal.byDepartment[entry.Department], 'totalHours', (Number(this.newIllnessStatsTotal.byDepartment[entry.Department].totalHours) + Number(entry.Hours)))
                this.$set(this.newIllnessStatsTotal.byDepartment[entry.Department].totalHoursByWeekday, weekDayIndex, (Number(this.newIllnessStatsTotal.byDepartment[entry.Department].totalHoursByWeekday[weekDayIndex]) + Number(entry.Hours)))
                if (!this.newIllnessStatsTotal.byDepartment[entry.Department].allDates.includes(this.formatMachineDate(entry.Date))) {
                    this.newIllnessStatsTotal.byDepartment[entry.Department].allDates.push(this.formatMachineDate(entry.Date));
                    this.$set(this.newIllnessStatsTotal.byDepartment[entry.Department], 'totalDays', (Number(this.newIllnessStatsTotal.byDepartment[entry.Department].totalDays) + 1))
                    this.$set(this.newIllnessStatsTotal.byDepartment[entry.Department].statsByMonth[monthIndex], 'totalDays', (Number(this.newIllnessStatsTotal.byDepartment[entry.Department].statsByMonth[monthIndex].totalDays) + 1))
                }
                this.$set(this.newIllnessStatsTotal.byDepartment[entry.Department].statsByMonth[monthIndex], 'totalHours', (Number(this.newIllnessStatsTotal.byDepartment[entry.Department].statsByMonth[monthIndex].totalHours) + Number(entry.Hours)))
                //ILLNESS HOURS
                if (entry.CategoryName.includes('SYG')) {
                    this.$set(this.newIllnessStatsTotal.byDepartment[entry.Department], 'totalIllnessHours', (Number(this.newIllnessStatsTotal.byDepartment[entry.Department].totalIllnessHours) + Number(entry.Hours)))
                    this.$set(this.newIllnessStatsTotal.byDepartment[entry.Department].illnessHoursByWeekday, weekDayIndex, (Number(this.newIllnessStatsTotal.byDepartment[entry.Department].illnessHoursByWeekday[weekDayIndex]) + Number(entry.Hours)))
                    if (!this.newIllnessStatsTotal.byDepartment[entry.Department].illnessDates.includes(this.formatMachineDate(entry.Date))) {
                        this.newIllnessStatsTotal.byDepartment[entry.Department].illnessDates.push(this.formatMachineDate(entry.Date));
                        this.$set(this.newIllnessStatsTotal.byDepartment[entry.Department], 'totalIllnessDays', (Number(this.newIllnessStatsTotal.byDepartment[entry.Department].totalIllnessDays) + 1))
                        this.$set(this.newIllnessStatsTotal.byDepartment[entry.Department].statsByMonth[monthIndex], 'totalIllnessDays', (Number(this.newIllnessStatsTotal.byDepartment[entry.Department].statsByMonth[monthIndex].totalIllnessDays) + 1))
                    }
                    this.$set(this.newIllnessStatsTotal.byDepartment[entry.Department].statsByMonth[monthIndex], 'illnessHours', (Number(this.newIllnessStatsTotal.byDepartment[entry.Department].statsByMonth[monthIndex].illnessHours) + Number(entry.Hours)))
                //NON-ILLNESS HOURS
                } else {
                    this.$set(this.newIllnessStatsTotal.byDepartment[entry.Department], 'totalNonIllnessHours', (Number(this.newIllnessStatsTotal.byDepartment[entry.Department].totalNonIllnessHours) + Number(entry.Hours)))
                    this.$set(this.newIllnessStatsTotal.byDepartment[entry.Department].nonIllnessHoursByWeekday, weekDayIndex, (Number(this.newIllnessStatsTotal.byDepartment[entry.Department].nonIllnessHoursByWeekday[weekDayIndex]) + Number(entry.Hours)))
                    if (!this.newIllnessStatsTotal.byDepartment[entry.Department].nonIllnessDates.includes(this.formatMachineDate(entry.Date))) {
                        this.newIllnessStatsTotal.byDepartment[entry.Department].nonIllnessDates.push(this.formatMachineDate(entry.Date));
                        this.$set(this.newIllnessStatsTotal.byDepartment[entry.Department], 'totalNonIllnessDays', (Number(this.newIllnessStatsTotal.byDepartment[entry.Department].totalNonIllnessDays) + 1))
                        this.$set(this.newIllnessStatsTotal.byDepartment[entry.Department].statsByMonth[monthIndex], 'totalNonIllnessDays', (Number(this.newIllnessStatsTotal.byDepartment[entry.Department].statsByMonth[monthIndex].totalNonIllnessDays) + 1))
                    }
                    this.$set(this.newIllnessStatsTotal.byDepartment[entry.Department].statsByMonth[monthIndex], 'nonIllnessHours', (Number(this.newIllnessStatsTotal.byDepartment[entry.Department].statsByMonth[monthIndex].nonIllnessHours) + Number(entry.Hours)))
                }
                //PERCENTAGES
                this.$set(this.newIllnessStatsTotal.byDepartment[entry.Department], 'illnessHoursPercentage', (this.newIllnessStatsTotal.byDepartment[entry.Department].totalIllnessHours / this.newIllnessStatsTotal.byDepartment[entry.Department].totalHours * 100))
                this.$set(this.newIllnessStatsTotal.byDepartment[entry.Department], 'illnessDaysPercentage', (this.newIllnessStatsTotal.byDepartment[entry.Department].totalIllnessDays / this.newIllnessStatsTotal.byDepartment[entry.Department].totalDays * 100))
                this.$set(this.newIllnessStatsTotal.byDepartment[entry.Department].illnessHoursPercentageByWeekday, weekDayIndex, ((this.newIllnessStatsTotal.byDepartment[entry.Department].illnessHoursByWeekday[weekDayIndex] / this.newIllnessStatsTotal.byDepartment[entry.Department].totalHoursByWeekday[weekDayIndex]) * 100))
                this.$set(this.newIllnessStatsTotal.byDepartment[entry.Department].statsByMonth[monthIndex], 'illnessHoursPercentage', (this.newIllnessStatsTotal.byDepartment[entry.Department].statsByMonth[monthIndex].illnessHours / this.newIllnessStatsTotal.byDepartment[entry.Department].statsByMonth[monthIndex].totalHours * 100))
                this.$set(this.newIllnessStatsTotal.byDepartment[entry.Department].statsByMonth[monthIndex], 'illnessDaysPercentage', (this.newIllnessStatsTotal.byDepartment[entry.Department].statsByMonth[monthIndex].totalIllnessDays / this.newIllnessStatsTotal.byDepartment[entry.Department].statsByMonth[monthIndex].totalDays * 100))
                
                //BY FITTER
                if (!this.newIllnessStatsGrouped[fitterSalaryID]) { //If the fitter is not already in the object, add them
                    this.newIllnessStatsGrouped[fitterSalaryID] = this.cloneJson(fitterTemplate);
                    this.newIllnessStatsGrouped[fitterSalaryID].fitterName = entry.FitterName;
                    this.newIllnessStatsGrouped[fitterSalaryID].fitterSalaryID = fitterSalaryID;
                    this.newIllnessStatsGrouped[fitterSalaryID].department = entry.Department;
                }
                //TOTAL HOURS
                this.$set(this.newIllnessStatsGrouped[fitterSalaryID], 'totalHours', (Number(this.newIllnessStatsGrouped[fitterSalaryID].totalHours) + Number(entry.Hours)))
                this.$set(this.newIllnessStatsGrouped[fitterSalaryID].totalHoursByWeekday, weekDayIndex, (Number(this.newIllnessStatsGrouped[fitterSalaryID].totalHoursByWeekday[weekDayIndex]) + Number(entry.Hours)))
                if (!this.newIllnessStatsGrouped[fitterSalaryID].allDates.includes(this.formatMachineDate(entry.Date))) { //If the date is not already in the array, add it
                    this.newIllnessStatsGrouped[fitterSalaryID].allDates.push(this.formatMachineDate(entry.Date));
                    this.$set(this.newIllnessStatsGrouped[fitterSalaryID], 'totalDays', (Number(this.newIllnessStatsGrouped[fitterSalaryID].totalDays) + 1))
                    this.$set(this.newIllnessStatsGrouped[fitterSalaryID].statsByMonth[monthIndex], 'totalDays', (Number(this.newIllnessStatsGrouped[fitterSalaryID].statsByMonth[monthIndex].totalDays) + 1))
                }
                this.$set(this.newIllnessStatsGrouped[fitterSalaryID].statsByMonth[monthIndex], 'totalHours', (Number(this.newIllnessStatsGrouped[fitterSalaryID].statsByMonth[monthIndex].totalHours) + Number(entry.Hours)))
                //ILLNESS HOURS
                if (entry.CategoryName.includes('SYG')) { //If the entry is an illness entry, add it to the illness stats
                    this.$set(this.newIllnessStatsGrouped[fitterSalaryID], 'totalIllnessHours', (Number(this.newIllnessStatsGrouped[fitterSalaryID].totalIllnessHours) + Number(entry.Hours)))
                    this.$set(this.newIllnessStatsGrouped[fitterSalaryID].illnessHoursByWeekday, weekDayIndex, (Number(this.newIllnessStatsGrouped[fitterSalaryID].illnessHoursByWeekday[weekDayIndex]) + Number(entry.Hours)))
                    if (!this.newIllnessStatsGrouped[fitterSalaryID].illnessDates.includes(this.formatMachineDate(entry.Date))) { //If the illness date is not already in the array, add it
                        this.newIllnessStatsGrouped[fitterSalaryID].illnessDates.push(this.formatMachineDate(entry.Date));
                        this.$set(this.newIllnessStatsGrouped[fitterSalaryID], 'totalIllnessDays', (Number(this.newIllnessStatsGrouped[fitterSalaryID].totalIllnessDays) + 1))
                        this.$set(this.newIllnessStatsGrouped[fitterSalaryID].statsByMonth[monthIndex], 'totalIllnessDays', (Number(this.newIllnessStatsGrouped[fitterSalaryID].statsByMonth[monthIndex].totalIllnessDays) + 1))
                    }
                    this.$set(this.newIllnessStatsGrouped[fitterSalaryID].statsByMonth[monthIndex], 'illnessHours', (Number(this.newIllnessStatsGrouped[fitterSalaryID].statsByMonth[monthIndex].illnessHours) + Number(entry.Hours)))
                //NON-ILLNESS HOURS
                } else { //If the entry is not an illness entry, add it to the non-illness stats
                    this.$set(this.newIllnessStatsGrouped[fitterSalaryID], 'totalNonIllnessHours', (Number(this.newIllnessStatsGrouped[fitterSalaryID].totalNonIllnessHours) + Number(entry.Hours)))
                    this.$set(this.newIllnessStatsGrouped[fitterSalaryID].nonIllnessHoursByWeekday, weekDayIndex, (Number(this.newIllnessStatsGrouped[fitterSalaryID].nonIllnessHoursByWeekday[weekDayIndex]) + Number(entry.Hours)))
                    if (!this.newIllnessStatsGrouped[fitterSalaryID].nonIllnessDates.includes(this.formatMachineDate(entry.Date))) { //If the non-illness date is not already in the array, add it
                        this.newIllnessStatsGrouped[fitterSalaryID].nonIllnessDates.push(this.formatMachineDate(entry.Date));
                        this.$set(this.newIllnessStatsGrouped[fitterSalaryID], 'totalNonIllnessDays', (Number(this.newIllnessStatsGrouped[fitterSalaryID].totalNonIllnessDays) + 1))
                        this.$set(this.newIllnessStatsGrouped[fitterSalaryID].statsByMonth[monthIndex], 'totalNonIllnessDays', (Number(this.newIllnessStatsGrouped[fitterSalaryID].statsByMonth[monthIndex].totalNonIllnessDays) + 1))
                    }
                    this.$set(this.newIllnessStatsGrouped[fitterSalaryID].statsByMonth[monthIndex], 'nonIllnessHours', (Number(this.newIllnessStatsGrouped[fitterSalaryID].statsByMonth[monthIndex].nonIllnessHours) + Number(entry.Hours)))
                }
                //PERCENTAGES
                this.$set(this.newIllnessStatsGrouped[fitterSalaryID], 'illnessHoursPercentage', ((this.newIllnessStatsGrouped[fitterSalaryID].totalIllnessHours / this.newIllnessStatsGrouped[fitterSalaryID].totalHours) * 100))
                this.$set(this.newIllnessStatsGrouped[fitterSalaryID], 'illnessDaysPercentage', ((this.newIllnessStatsGrouped[fitterSalaryID].totalIllnessDays / this.newIllnessStatsGrouped[fitterSalaryID].totalDays) * 100))
                this.$set(this.newIllnessStatsGrouped[fitterSalaryID].illnessHoursPercentageByWeekday, weekDayIndex, ((this.newIllnessStatsGrouped[fitterSalaryID].illnessHoursByWeekday[weekDayIndex] / this.newIllnessStatsGrouped[fitterSalaryID].totalHoursByWeekday[weekDayIndex]) * 100))
                this.$set(this.newIllnessStatsGrouped[fitterSalaryID].statsByMonth[monthIndex], 'illnessHoursPercentage', ((this.newIllnessStatsGrouped[fitterSalaryID].statsByMonth[monthIndex].illnessHours / this.newIllnessStatsGrouped[fitterSalaryID].statsByMonth[monthIndex].totalHours) * 100))
                this.$set(this.newIllnessStatsGrouped[fitterSalaryID].statsByMonth[monthIndex], 'illnessDaysPercentage', ((this.newIllnessStatsGrouped[fitterSalaryID].statsByMonth[monthIndex].totalIllnessDays / this.newIllnessStatsGrouped[fitterSalaryID].statsByMonth[monthIndex].totalDays) * 100))
            }

            //Hack to ensure sorted list updates
            let tempSorting = String(this.selectedSorting)
            this.selectedSorting = null
            this.selectedSorting = tempSorting
            this.newIllnessStatsGrouped = this.cloneJson(this.newIllnessStatsGrouped) //Fixes weird Vue reactivity bug
            this.$forceUpdate()

            return true
        },
        selectFitterIllness(fitterIllness) {
            this.activeFitterIllness = fitterIllness
            this.activeFitterDataReady = false
            this.activeFitterDataReady = true
            this.activeFitterModalOpen = true
        },
        formatPercent(value) {
            // return value
            return `${value.toFixed(1)} %`
        },
    },
    computed: {
        /**
         * Returns the initials of the logged in user, extracted from the email address
         */
        userInitials() {
            return this.$parent.user?.email?.substring(0, this.$parent.user?.email?.indexOf('@'))?.toUpperCase() || null;
        },
        /**
         * Returns true if the logged in user has the HourCheck role, so they should be allowed to see other users' hours
         */
        userHasHourCheckRole() {
            return this.$parent.firebaseUser?.Roles?.includes(UserRoles.HOUR_CHECK) || false;
        },
        sortedGroupedIllness() {
            return Object.values(this.newIllnessStatsGrouped).sort((a, b) => {
                if (this.selectedSorting == 'fitterName' || this.selectedSorting == 'department') {
                    if (a[this.selectedSorting] == b[this.selectedSorting]) { //Edge case where first choice of sorting is the same
                        return a.totalIllnessHours > b.totalIllnessHours ? -1 : 1;
                    }
                    return a[this.selectedSorting] > b[this.selectedSorting] ? 1 : -1;
                }
                return a[this.selectedSorting] > b[this.selectedSorting] ? -1 : 1;
            });
        },
        totalIllnessHoursByDepartment() {
            let output = []
            for (let department of Object.values(this.newIllnessStatsTotal.byDepartment)) {
                output.push({label: department.department, value: department.totalIllnessHours})
            }
            return output
        },
        myIllness() {
            return this.newIllnessStatsGrouped[this.userInitials] || null;
        },
        totalWeekdayChartData() {
            let tempObj = {
                datasets: [
                    { label: 'Sygdoms %', data: this.newIllnessStatsTotal.illnessHoursPercentageByWeekday.map((val, i) => {return {x: i, y: val}}), backgroundColor: '#d69d56', yAxisID: 'y' },
                    { label: 'Sygdoms timer', data: this.newIllnessStatsTotal.illnessHoursByWeekday.map((val, i) => {return {x: i, y: val}}), backgroundColor: '#ebd4be', yAxisID: 'y1' },
                ],
                labels: ['man', 'tirs', 'ons', 'tors', 'fre', 'lør', 'søn'],
            }
            if (!tempObj.datasets[1].data[6]?.y || tempObj.datasets[1].data[6].y == 0) {//Remove sunday if no hours
                tempObj.datasets[0].data.splice(6)
                tempObj.datasets[1].data.splice(6)
                tempObj.labels.splice(6)
            }
            if (!tempObj.datasets[1].data[5]?.y || tempObj.datasets[1].data[5].y == 0) {//Remove saturday if no hours
                tempObj.datasets[0].data.splice(5)
                tempObj.datasets[1].data.splice(5)
                tempObj.labels.splice(5)
            }
            // console.log(tempObj)
            return tempObj
        },
        totalMonthChartData() {
            let tempObj = {
                datasets: [
                    { label: 'Sygdoms %', data: this.newIllnessStatsByMonth.map(month => month.illnessHoursPercentage).map((val, i) => {return {x: i, y: val}}), backgroundColor: '#d69d56', yAxisID: 'y' },
                    { label: 'Sygdoms timer', data: this.newIllnessStatsByMonth.map(month => month.illnessHours).map((val, i) => {return {x: i, y: val}}), backgroundColor: '#ebd4be', yAxisID: 'y1' },
                ],
                labels: this.newIllnessStatsByMonth.map(month => month.label),
            }
            return tempObj
        },
        totalDepartmentChartData() {
            // if (!this.myIllnessDataReady) return {datasets: [], labels: []}
            this.newIllnessStatsByMonth //Force update as data comes in
            let labels = Object.keys(this.newIllnessStatsTotal.byDepartment)
            let hours = Object.values(this.newIllnessStatsTotal.byDepartment).map(department => department.totalIllnessHours)
            let percentages = Object.values(this.newIllnessStatsTotal.byDepartment).map(department => department.illnessHoursPercentage)
            let tempObj = {
                datasets: [
                    { label: 'Sygdoms %', data: percentages.map((val, i) => {return {x: i, y: val}}), backgroundColor: '#d69d56', yAxisID: 'y' },
                    { label: 'Sygdoms timer', data: hours.map((val, i) => {return {x: i, y: val}}), backgroundColor: '#ebd4be', yAxisID: 'y1' },
                ],
                labels: labels,
            }
            return tempObj
        },
    },
}
</script>
<style scoped>
    .clickable:hover {
        cursor: pointer;
    }
</style>