<template>
    <sui-table striped compact v-if="activeInstallationData && !loadingInstallationData">
        <sui-table-body>
            <sui-table-row>
                <sui-table-cell colspan="2">
                    <strong>Info</strong>
                </sui-table-cell>
            </sui-table-row>
            <sui-table-row>
                <sui-table-cell>Tekst</sui-table-cell>
                <sui-table-cell>{{activeInstallationData.appointment.Title}}</sui-table-cell>
            </sui-table-row>
            <sui-table-row>
                <sui-table-cell>Dato</sui-table-cell>
                <sui-table-cell>{{toUserFriendlyDate(activeInstallationData.appointment.Date)}}</sui-table-cell>
            </sui-table-row>
            <sui-table-row>
                <sui-table-cell>Tidsrum</sui-table-cell>
                <sui-table-cell>
                    <span v-if="activeInstallationData.appointment.Time && activeInstallationData.appointment.Time.From != 'null'">{{activeInstallationData.appointment.Time.From}} - {{activeInstallationData.appointment.Time.To}}</span>
                </sui-table-cell>
            </sui-table-row>
            <sui-table-row>
                <sui-table-cell>Montør</sui-table-cell>
                <sui-table-cell>{{activeInstallationData.appointment.WorkerInitials}}</sui-table-cell>
            </sui-table-row>
            <sui-table-row>
                <sui-table-cell colspan="2">
                    <strong>Noter</strong>
                    &nbsp; &nbsp; &nbsp;
                    <sui-button size="mini" @click="sendEvent('open-custom-appointment-note-modal')"><i class="fas fa-plus"></i></sui-button>
                </sui-table-cell>
            </sui-table-row>
            <sui-table-row v-for="(note, index) in activeInstallationData.appointment.Notes" :key="`n-${index}`">
                <sui-table-cell colspan="2">
                    <p>
                        <strong>{{ getInitialsFromEmail(note.authorEmail) }}</strong> - 
                        <span>{{ toUserFriendlyTimestamp(note.timeStamp) }}</span>
                    </p>
                    <p class="note-body">{{ note.noteBody }}</p>
                </sui-table-cell>
            </sui-table-row>
            <sui-table-row v-if="!activeInstallationData.appointment.Notes || !activeInstallationData.appointment.Notes.length">
                <sui-table-cell colspan="2">
                    <em>Ingen noter</em>
                </sui-table-cell>
            </sui-table-row>
            <sui-table-row>
                <sui-table-cell colspan="2">
                    <strong>Projekter</strong>
                </sui-table-cell>
            </sui-table-row>
            <sui-table-row v-if="activeInstallationData.appointment.LinkedProjects.length == 0">
                <sui-table-cell colspan="2"><em> Alle projekter </em></sui-table-cell>
            </sui-table-row>
            <sui-table-row v-for="project in activeInstallationData.appointment.LinkedProjects" :key="project">
                <sui-table-cell colspan="2">
                    {{ allProjects.find((p) => p.id == project).Name }}
                </sui-table-cell>
            </sui-table-row>
            <sui-table-row>
                <sui-table-cell colspan="2">
                    <strong>Meta-data</strong>
                </sui-table-cell>
            </sui-table-row>
            <sui-table-row>
                <sui-table-cell>Oprettet</sui-table-cell>
                <sui-table-cell>{{toUserFriendlyTimestamp(new Date(activeInstallationData.appointment.Created.seconds*1000))}}</sui-table-cell>
            </sui-table-row>
            <sui-table-row>
                <sui-table-cell>Oprettet af</sui-table-cell>
                <sui-table-cell>{{activeInstallationData.appointment.CreatedBy.displayName}}</sui-table-cell>
            </sui-table-row>
            <sui-table-row>
                <sui-table-cell>Senest opdateret</sui-table-cell> 
                <sui-table-cell>{{toUserFriendlyTimestamp(new Date(activeInstallationData.appointment.Updated.seconds*1000))}}</sui-table-cell>
            </sui-table-row>
            <sui-table-row>
                <sui-table-cell>Opdateret af</sui-table-cell>
                <sui-table-cell>{{activeInstallationData.appointment.UpdatedBy.displayName}}</sui-table-cell>
            </sui-table-row>
        </sui-table-body>
    </sui-table>
</template>

<script>
import { Mixin } from '../../lib/Mixins/mixin.js'
import { DateMixin } from '../../lib/Mixins/dateMixin'
import EventBus from '../../EventBus'
import AppointmentType from '../../lib/Enums/AppointmentType'

export default {
    mixins: [Mixin, DateMixin],

    enums: {
        AppointmentType,
    },

    props: {
        activeInstallationData: Object,
        loadingInstallationData: Boolean,
        allProjects: Array,
    },

    methods: {
        sendEvent(eventName){
            EventBus.$emit(eventName)
        },
    },
}
</script>