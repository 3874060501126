<template>
    <div>
        <portal to="page-title">
            <sui-button icon="arrow left" @click="up" />
            <span v-if="configurationItem.address != null">{{ formatAddress(configurationItem.address, false) }}</span><span v-else>{{configurationItem.label}}</span>
        </portal>
        <sms-modal title="SMS-Modal" :isOpen="smsModalOpen" :phoneNumber="activePhoneNumber"/>
        <state-change-modal 
            :isOpen="stateModalOpen" 
            :serviceOrder="serviceOrder" 
            :task="activeTask" 
            :projectProp="project" 
            :installationLabel="configurationItem.label" 
            :technicalData="configurationItem.technicalData"
        />
        <sui-modal v-model="showSubtaskModal" size="mini">
            <sui-modal-header>
                {{activeSubtask.name}}
            </sui-modal-header>
            <sui-modal-content v-if="showSubtaskModal">
                <SubtaskStateChangeForm 
                    :activeSubtask="activeSubtask"
                    :instLabel="configurationItem.label"
                    :project="project"
                    :subtaskModalLoading="subtaskModalLoading"
                    :firebaseSubTasks="internalSubTasks"
                    :user="user"
                    :ignoreLeadPermissions="ignoreLeadPermissions"
                />
            </sui-modal-content>
        </sui-modal>
        <sui-modal small v-model="noteModalOpen">
                <sui-modal-header>
                    Ny Note
                </sui-modal-header>
                <sui-modal-content>
                    <sui-form :success="noteFormSuccess" @submit.prevent="submitNote" :loading="noteFormLoading">
                        <sui-form-fields inline>
                            <label>Intern eller ekstern note?</label>
                            <sui-form-field>
                                <sui-checkbox label="Intern" radio value="Intern" v-model="noteType"/>
                            </sui-form-field>
                            <sui-form-field>
                                <sui-checkbox label="Ekstern" radio value="Ekstern" v-model="noteType"/>
                            </sui-form-field>
                        </sui-form-fields>
                        <sui-message> Eksterne noter skrives på sagen i PilotBI, Interne noter kan kun ses gennem ftth.fiberlan.dk <br> Der skrives automatisk navn på alle noter <br> Noter der er oprettet kan ikke slettes </sui-message>
                        <sui-form-field>
                            <label>Note tekst</label>
                            <textarea rows="5" v-model="noteText"></textarea>
                        </sui-form-field>
                        <sui-button type="submit" :disabled="!noteType">Gem</sui-button>
                    </sui-form>
                </sui-modal-content>
            </sui-modal>
            <sui-modal small v-model="uploadFileModalOpen">
                <sui-modal-header>
                    Vedhæft fil
                </sui-modal-header>
                <sui-modal-content>
                    <sui-form :success="fileFormSuccess" @submit.prevent="submitFile" :loading="fileFormLoading">
                        <sui-form-field>
                            <label>Navngiv den uploadede fil</label>
                            <input type="text" placeholder="FilNavn" v-model="fileNameInput"/>
                        </sui-form-field>
                        <sui-form-field>
                            <input type="file" @change="setFileUpload"/>
                        </sui-form-field>
                        <sui-message> Uploadede filer sendes til PilotBI </sui-message>
                        <sui-button type="submit" :disabled="!fileUpload">Gem</sui-button>
                        <sui-message success>Fil sendt til PilotBI</sui-message>
                    </sui-form>
                </sui-modal-content>
            </sui-modal>
            <file-viewer-modal
                :openFile="openFile"
                :isOpen="openFileModalOpen"
                :openFileLoading="openFileLoading"
            />
        <edit-tech-data-modal :task="lastTask" :isOpen="editTechDataModalOpen" :installationLabel="configurationItem.label" />
        <edit-unit-work-modal 
            :isOpen="editUnitWorkModalOpen" 
            :task="tasks[0] || {}"
            :availableWorkers="availableWorkers"
            :user="user"
            :userHasLeadPermissions="userHasLeadPermissions"
            :beforeChange="activeUnitWork"
            :unitWorkId="activeUnitWork.id"
            :preFilledDescription="preFilledUnitWorkDescription"
        />
        <sui-item-group>
            <sui-item>
                <sui-item-content>
                    <sui-item-meta>{{ configurationItem.type }} #{{configurationItem.label}} <span v-if="configurationItem.area"><span v-if="configurationItem.area.name"> - Område: {{configurationItem.area.name}} ( SonWin projekt {{ configurationItem.area.sonWinProjectId }} ) </span></span></sui-item-meta>
                    <div class="row">
                        <div class="col-sm-8">
                            <div class="row">
                                <div class="col-sm-12">
                                    <DataCard title="Opgaver" :subtitle="'Tilhørende '+configurationItem.type+' '+configurationItem.label" no-padding class="half-height">
                                        <div class="scrollable">
                                            <sui-table striped selectable>
                                                <sui-table-header>
                                                    <sui-table-row>
                                                        <sui-table-header-cell>ID</sui-table-header-cell>
                                                        <sui-table-header-cell>Opgave</sui-table-header-cell>
                                                        <sui-table-header-cell>Status</sui-table-header-cell>
                                                        <sui-table-header-cell>Pl. slut</sui-table-header-cell>
                                                    </sui-table-row>
                                                </sui-table-header>
                                                <sui-table-body v-if="tasks.length">
                                                    <sui-table-row 
                                                    v-for="task in tasks" 
                                                    :key="task.id"
                                                    @click="onClickTask(task)">
                                                        <sui-table-cell>{{ task.number }}</sui-table-cell>
                                                        <sui-table-cell>{{ task.shortDescription }}</sui-table-cell>
                                                        <sui-table-cell> <sui-button size="mini" :color="getStateColor(task.state)" @click.stop="statusClicked(task)" >{{ task.state.label }}</sui-button> </sui-table-cell>
                                                        <sui-table-cell>{{ toUserFriendlyDate(task.plannedEnd) }}</sui-table-cell>
                                                    </sui-table-row>
                                                </sui-table-body>
                                                <sui-table-body v-else>
                                                    <sui-table-cell colspan="6">
                                                        <TableLoader />
                                                    </sui-table-cell>
                                                </sui-table-body>
                                            </sui-table>
                                        </div>
                                    </DataCard>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-6">
                                    <DataCard :title="configurationItem.type+' data'" :subtitle="configurationItem.label" no-padding class="half-height">
                                        <div class="scrollable">
                                            <sui-table striped>
                                                <sui-table-body>
                                                    <sui-table-row>
                                                        <sui-table-cell>Nr. <br> Value</sui-table-cell>
                                                        <sui-table-cell>{{configurationItem.label}}<br> {{configurationItem.value}}</sui-table-cell>
                                                    </sui-table-row>
                                                    <sui-table-row>
                                                        <sui-table-cell>Gå til BBE</sui-table-cell>
                                                        <sui-table-cell><sui-button primary size="mini" compact @click="goToBroadBandSupplierPortal(configurationItem.label)">Bredbåndsportal</sui-button></sui-table-cell>
                                                    </sui-table-row>
                                                    <!-- <sui-table-row>
                                                        <sui-table-cell>Type</sui-table-cell>
                                                        <sui-table-cell>{{configurationItem.type}}</sui-table-cell>
                                                    </sui-table-row> -->
                                                    <sui-table-row>
                                                        <sui-table-cell>Adresse</sui-table-cell>
                                                        <sui-table-cell>
                                                            <span v-if="configurationItem.address">
                                                                <i
                                                                    class="fas fa-map-marker-alt text-primary"
                                                                    :title="`${formatAddress(configurationItem.address, false)}\nKlik for at navigere i Google Maps`"
                                                                    @click="linkToGoogleMapsDirections(coordinates, configurationItem.address)"
                                                                    style="cursor: pointer;"
                                                                ></i>
                                                                <!-- <a :href="generateGoogleMapsLink(coordinates, configurationItem.address)" target="_blank">
                                                                </a> -->
                                                                {{formatAddress(configurationItem.address, false)}}
                                                            </span>
                                                        </sui-table-cell>
                                                    </sui-table-row>
                                                    <sui-table-row>
                                                        <sui-table-cell>Forening</sui-table-cell>
                                                        <sui-table-cell>{{configurationItem.association}}</sui-table-cell>
                                                    </sui-table-row>
                                                    <sui-table-row v-if="configurationItem.cabinet">
                                                        <sui-table-cell>Skab</sui-table-cell>
                                                        <sui-table-cell>{{configurationItem.cabinet.name}}</sui-table-cell>
                                                    </sui-table-row>
                                                    <sui-table-row v-if="configurationItem.area">
                                                        <sui-table-cell>Område</sui-table-cell>
                                                        <sui-table-cell>{{configurationItem.area.name}} <span v-if="configurationItem.area.sonWinProjectId">(SonWin projekt {{configurationItem.area.sonWinProjectId}})</span></sui-table-cell>
                                                    </sui-table-row>
                                                    <sui-table-row v-if="firstTask.project">
                                                        <sui-table-cell>Type</sui-table-cell>
                                                        <sui-table-cell>{{ProjectType.getValueFromCode(firstTask.project.type.value)}}</sui-table-cell>
                                                    </sui-table-row>
                                                </sui-table-body>
                                            </sui-table>
                                        </div>
                                    </DataCard>
                                    <DataCard 
                                        v-if="lastTask"
                                        title="Noter" 
                                        :subtitle="lastTask.number" 
                                        :no-padding="!!lastTask.notes && !!lastTask.notes.length" 
                                        :actions="[{type: 'button', icon: 'plus', eventName: 'newNote'}]"
                                        class="half-height">
                                        <div class="scrollable" v-if="lastTask.notes && lastTask.notes.length">
                                            <sui-table striped>
                                                <sui-table-body>
                                                    <sui-table-row v-for="note in lastTask.notes" :key="note.id">
                                                        <sui-table-cell>
                                                            <p>
                                                                <strong><i v-if="note.createdBy.includes('@fiberlan.dk')" class="fa-solid fa-eye-slash" title="Intern note, kan ikke ses af andre end FiberLAN"/> {{ note.createdBy }}<span v-if="parseNote(note.value).author"> ({{ parseNote(note.value).author }})</span></strong> - 
                                                                <span>{{ toUserFriendlyTimestamp(note.createdAt) }}</span>
                                                                <span v-if="parseNote(note.value).origin"><br><small>{{ parseNote(note.value).origin }}</small></span>
                                                            </p>
                                                            <p class="note-body">{{ parseNote(note.value).body }}</p>
                                                        </sui-table-cell>
                                                    </sui-table-row>
                                                </sui-table-body>
                                            </sui-table>
                                        </div>
                                        <p v-else>
                                            Der er tilsyneladende ingen noter tilknyttet denne opgave
                                        </p>
                                    </DataCard>
                                </div>
                                <div class="col-sm-6">
                                    <DataCard title="Service Order" :subtitle="String(serviceOrder.number)" :no-padding="!!serviceOrder.id" class="half-height">
                                        <div v-if="serviceOrder.id" class="scrollable">
                                            <sui-table striped>
                                                <sui-table-body>
                                                    <sui-table-row>
                                                        <sui-table-cell>
                                                            Service Order #
                                                        </sui-table-cell>
                                                        <sui-table-cell>
                                                            {{ serviceOrder.number }}
                                                        </sui-table-cell>
                                                    </sui-table-row>
                                                    <sui-table-row>
                                                        <sui-table-cell>
                                                            SonWin ID
                                                        </sui-table-cell>
                                                        <sui-table-cell>
                                                            {{ serviceOrder.sonWinId }}
                                                        </sui-table-cell>
                                                    </sui-table-row>
                                                    <sui-table-row>
                                                        <sui-table-cell>
                                                            Oprettet
                                                        </sui-table-cell>
                                                        <sui-table-cell>
                                                            {{ serviceOrder.createdAt }}
                                                        </sui-table-cell>
                                                    </sui-table-row>
                                                    <sui-table-row>
                                                        <sui-table-cell>
                                                            Kort beskrivelse
                                                        </sui-table-cell>
                                                        <sui-table-cell>
                                                            {{ serviceOrder.shortDescription }}
                                                        </sui-table-cell>
                                                    </sui-table-row>
                                                    <sui-table-row>
                                                        <sui-table-cell>
                                                            Service Provider #
                                                        </sui-table-cell>
                                                        <sui-table-cell>
                                                            {{ serviceOrder.serviceProvider.number }}
                                                        </sui-table-cell>
                                                    </sui-table-row>
                                                    <sui-table-row>
                                                        <sui-table-cell>
                                                            Projekt
                                                        </sui-table-cell>
                                                        <sui-table-cell>
                                                            {{ serviceOrder.project.number }} - {{ serviceOrder.project.shortDescription }}
                                                        </sui-table-cell>
                                                    </sui-table-row>
                                                    <sui-table-row v-for="(task, index) in serviceOrderTasks" :key="index">
                                                        <sui-table-cell>
                                                            {{ task.shortDescription }} <br>
                                                            <sui-item-meta> {{ task.number }} </sui-item-meta>
                                                        </sui-table-cell>
                                                        <sui-table-cell>
                                                            {{ task.state.label }}
                                                        </sui-table-cell>
                                                    </sui-table-row>
                                                </sui-table-body>
                                            </sui-table>
                                        </div>
                                        <div v-else>
                                            <p>Der ser ikke ud til at være nogen ServiceOrder tilknyttet denne installation</p>
                                        </div>
                                    </DataCard>
                                    <DataCard title="Filer" 
                                        v-if="lastTask"
                                        :subtitle="lastTask.number" 
                                        :no-padding="!!lastTask.attachments && !!lastTask.attachments.length" 
                                        :actions="[{type: 'button', icon: 'plus', eventName: 'newFile'}]"
                                        class="half-height">
                                        <div class="scrollable" v-if="lastTask.attachments && lastTask.attachments.length">
                                            <sui-table striped selectable>
                                                <sui-table-header>
                                                    <sui-table-row>
                                                        <sui-table-header-cell>Navn</sui-table-header-cell>
                                                        <sui-table-header-cell>Type</sui-table-header-cell>
                                                        <sui-table-header-cell>Str</sui-table-header-cell>
                                                    </sui-table-row>
                                                </sui-table-header>
                                                <sui-table-body>
                                                    <sui-table-row 
                                                        v-for="file in lastTask.attachments" 
                                                        :key="file.id"
                                                        @click="openAttachment(file.id)">
                                                        <sui-table-cell> {{file.name}} </sui-table-cell>
                                                        <sui-table-cell> {{file.type}} </sui-table-cell>
                                                        <sui-table-cell> {{formatBytes(file.sizeBytes)}} </sui-table-cell>
                                                    </sui-table-row>
                                                </sui-table-body>
                                            </sui-table>
                                        </div>
                                        <p v-else>
                                            Der er tilsyneladende ingen filer tilknyttet denne opgave
                                        </p>
                                    </DataCard>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <DataCard title="Delopgaver" no-padding class="half-height">
                                <div class="scrollable">
                                    <sui-table striped>
                                        <sui-table-body v-if="this.internalSubTasks">
                                            <sui-table-row v-for="subtask in this.internalSubTasks" :key="subtask.code">
                                                <sui-table-cell>
                                                    <i v-if="subtask.Deferred && subtask.Deferred.Value" class="fas fa-calendar" style="color: #2185d0;" :title="`Gøres på dagen for installation\n${subtask.Deferred.DefferedBy}\n${toUserFriendlyTimestamp(subtask.Deferred.DeferredAt)}`"/>
                                                    <span v-if="subtask.Responsible">
                                                        <i v-if="subtask.Responsible.Email == user.email" class="fas fa-user-hard-hat" style="color: #2185d0;" :title="`Du har ansvar for denne delopgave\n${toUserFriendlyTimestamp(subtask.Responsible.ResponsibilityTakenAt)}`"/>
                                                        <i v-else class="fad fa-user-slash" style="--fa-primary-color: tomato; --fa-secondary-color: #2185d0; --fa-secondary-opacity: 1;" :title="`${subtask.Responsible.Name} har ansvar for denne delopgave\n${toUserFriendlyTimestamp(subtask.Responsible.ResponsibilityTakenAt)}`"/>
                                                    </span>
                                                    {{subtask.name}} 
                                                    <span v-if="subtask.Responsible">({{subtask.Responsible.Initials}})</span>
                                                </sui-table-cell>
                                                <sui-table-cell>
                                                    <sui-button v-if="subtask.state" size="mini" :title="subtask.state.isPositive ? subtask.titles.positive : subtask.titles.negative" :color="getSubtaskStateColor(subtask.state)" @click.stop="subtaskStatusClicked(subtask)">
                                                        <span v-if="subtask.state.isPositive"><i class="fas fa-check" :title="subtask.titles.positive"/></span>
                                                        <span v-else><i class="fas fa-times" :title="subtask.titles.negative"/></span> &nbsp;
                                                        <span>{{subtask.state.text}}</span>
                                                    </sui-button>
                                                </sui-table-cell>
                                            </sui-table-row>
                                        </sui-table-body>
                                        <sui-table-body v-else>
                                            <TableLoader/>
                                        </sui-table-body>
                                    </sui-table>
                                </div>
                            </DataCard>
                            <DataCard title="Udført enhedsarbejde" no-padding class="half-height" :actions="[{ type: 'button', icon: 'plus', title: 'Tilføj enhedsarbejde', eventName: 'add-unit-work' }]">
                                <div class="scrollable">
                                    <sui-table selectable striped>
                                        <sui-table-body v-if="this.unitWorks">
                                            <sui-table-row v-for="unitWork in unitWorks" :key="unitWork.id" class="hover-pointer" @click="unitWorkClicked(unitWork)">
                                                <sui-table-cell><i v-if="unitWork.Billed && unitWork.Billed.Bool" class="fas fa-lock" title="Denne postering er markeret som faktureret, og derfor låst for redigering"/> {{unitWork.Unit.Name}}</sui-table-cell>
                                                <sui-table-cell>{{unitWork.Unit.Unit != 't' ? unitWork.Amount : decimalToHourString(unitWork.Amount)}} {{unitWork.Unit.Unit}} ({{unitWork.Workers.join("/").toUpperCase().replaceAll("@FIBERLAN.DK","")}} - {{toUserFriendlyDate(unitWork.Date, false)}})</sui-table-cell>
                                            </sui-table-row>
                                        </sui-table-body>
                                    </sui-table>
                                </div>
                            </DataCard>
                            <DataCard title="Kontaktpersoner" :subtitle="String(contacts.length)" :no-padding="!!contacts.length" class="half-height">
                                <div class="scrollable">
                                    <sui-table striped v-if="contacts.length">
                                        <sui-table-body>
                                            <sui-table-row v-for="(contact, index) in contacts" :key="index">
                                                <sui-table-cell>
                                                    <p><strong v-if="contact.name">{{contact.name}}</strong> <span v-if="contact.role">({{contact.role}})</span></p>
                                                    <div class="contactData">
                                                        <p v-if="contact.phone">Tele: {{ contact.phone }} <a :href="'tel:' + contact.phone"><sui-icon name="phone" link/></a> <a><i class="fas fa-sms link" v-if="checkIfMobile(contact.phone)" @click="openSmsModal(contact.phone)"></i></a> </p>
                                                        <p v-if="contact.mobile">Mob: {{ contact.mobile }} <a :href="'tel:' + contact.mobile"><sui-icon name="phone" link/></a> <a><i class="fas fa-sms link" v-if="checkIfMobile(contact.mobile)" @click="openSmsModal(contact.mobile)"></i></a> </p>
                                                        <p v-if="contact.email">Mail: {{ contact.email }} <a :href="'mailto:' + contact.email"><sui-icon name="envelope" link/></a> </p>
                                                    </div>
                                                </sui-table-cell>
                                            </sui-table-row>
                                        </sui-table-body>
                                    </sui-table>
                                    <p v-else>Der er ingen kontaktpersoner knyttet til denne {{configurationItem.type}}</p>
                                </div>
                            </DataCard>
                            <DataCard 
                                title="Splidse-planer"
                                no-padding 
                                class="half-height"
                            >
                                <splice-reports-table v-if="this.configurationItem.cabinet" :id="this.configurationItem.cabinet.name ? this.configurationItem.cabinet.name : 'noCabinetFound'"/>
                            </DataCard>
                            <DataCard title="Teknisk data"
                                v-if="lastTask"
                                :subtitle="lastTask.number"
                                no-padding
                                class="half-height"
                                :actions="[{type: 'button', icon: 'edit', eventName: 'techDataEdit'}]">
                                <div class="scrollable">
                                    <sui-table striped>
                                        <sui-table-body v-if="lastTask.configurationItem && lastTask.configurationItem.technicalData">
                                            <sui-table-row>
                                                <sui-table-cell>Rack</sui-table-cell>
                                                <sui-table-cell>{{lastTask.configurationItem.technicalData.rack}}</sui-table-cell>
                                            </sui-table-row>
                                            <sui-table-row>
                                                <sui-table-cell>ASR Navn</sui-table-cell>
                                                <sui-table-cell>{{lastTask.configurationItem.technicalData.asrName}}</sui-table-cell>
                                            </sui-table-row>
                                            <sui-table-row>
                                                <sui-table-cell>ASR Port</sui-table-cell>
                                                <sui-table-cell>{{lastTask.configurationItem.technicalData.asrPort}}</sui-table-cell>
                                            </sui-table-row>
                                            <sui-table-row>
                                                <sui-table-cell>CATV Port</sui-table-cell>
                                                <sui-table-cell>{{lastTask.configurationItem.technicalData.caTVPort}}</sui-table-cell>
                                            </sui-table-row>
                                            <sui-table-row>
                                                <sui-table-cell>Signalstyrke data</sui-table-cell>
                                                <sui-table-cell>{{lastTask.configurationItem.technicalData.signalStrengthData}}</sui-table-cell>
                                            </sui-table-row>
                                            <sui-table-row>
                                                <sui-table-cell>Signalstyrke TV</sui-table-cell>
                                                <sui-table-cell>{{lastTask.configurationItem.technicalData.signalStrengthCaTV}}</sui-table-cell>
                                            </sui-table-row>
                                            <sui-table-row>
                                                <sui-table-cell>Port tjekket</sui-table-cell>
                                                <sui-table-cell>{{stringToBool(lastTask.configurationItem.technicalData.portCheckPerformed) ? 'Ja' : 'Nej'}}</sui-table-cell>
                                            </sui-table-row>
                                            <sui-table-row>
                                                <sui-table-cell>Hastighedstest</sui-table-cell>
                                                <sui-table-cell>{{lastTask.configurationItem.technicalData.speedTestFiberbox}}</sui-table-cell>
                                            </sui-table-row>
                                        </sui-table-body>
                                        <NexelStatus :instLabel="configurationItem.label"/>
                                        <NexelData :instLabel="configurationItem.label"/>
                                    </sui-table>
                                </div>
                            </DataCard>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6" v-if="configurationItem.type == 'Installation'">
                            <DataCard 
                                title="Aftaler"
                                :subtitle="'Tilhørende '+configurationItem.type+' '+configurationItem.label" 
                                no-padding
                                class="half-height">
                                <div class="scrollable">
                                    <sui-table striped v-if="appointments.length">
                                        <sui-table-header>
                                            <sui-table-row>
                                                <sui-table-header-cell>Dato</sui-table-header-cell>
                                                <sui-table-header-cell>Tid</sui-table-header-cell>
                                                <sui-table-header-cell>Montør</sui-table-header-cell>
                                                <sui-table-header-cell>Status</sui-table-header-cell>
                                                <sui-table-header-cell>Opdateret</sui-table-header-cell>
                                            </sui-table-row>
                                        </sui-table-header>
                                        <sui-table-body>
                                            <sui-table-row v-for="appointment in appointments" :key="appointment.id">
                                                <sui-table-cell> {{toUserFriendlyDate(readTimeWindowString(appointment.TimeWindowString).Date)}} </sui-table-cell>
                                                <sui-table-cell> {{readTimeWindowString(appointment.TimeWindowString).Time.From}} - {{readTimeWindowString(appointment.TimeWindowString).Time.To}} </sui-table-cell>
                                                <sui-table-cell><span v-if="appointment.Worker && appointment.Worker.Email"> {{appointment.Worker.Email.substr(0,appointment.Worker.Email.indexOf('@')).toUpperCase()}} </span></sui-table-cell>
                                                <sui-table-cell> {{appointment.State}} </sui-table-cell>
                                                <sui-table-cell> {{toUserFriendlyTimestamp(appointment.Updated)}} </sui-table-cell>
                                            </sui-table-row>
                                        </sui-table-body>
                                    </sui-table>
                                </div>
                            </DataCard>
                        </div>
                        <div class="col-sm-6" v-if="configurationItem.type == 'Installation'">
                            <DataCard 
                                title="Produkter" 
                                :subtitle="'Tilhørende '+configurationItem.type+' '+configurationItem.label" 
                                :no-padding="!!orderLines.length || loadingOrderLines" 
                                :actions="[{type: 'button', icon: 'redo', eventName: 'updateProducts'}]"
                                class="half-height">
                                <div class="scrollable">
                                    <sui-table striped v-if="orderLines.length || loadingOrderLines">
                                        <sui-table-body v-if="loadingOrderLines">
                                            <TableLoader />
                                        </sui-table-body>
                                        <sui-table-body v-else>
                                            <sui-table-row v-for="(orderLine, index) in orderLines" :key="index">
                                                <sui-table-cell>
                                                    {{orderLine.product.name}}
                                                </sui-table-cell>
                                                <sui-table-cell>
                                                    {{orderLine.product.productGroups[0].name}}
                                                </sui-table-cell>
                                                <sui-table-cell>
                                                    <span v-if="orderLine.actualDeliveryDate" class="success">
                                                        {{toUserFriendlyDate(orderLine.actualDeliveryDate)}}
                                                    </span>
                                                    <span v-else class="danger">
                                                        {{toUserFriendlyDate(orderLine.expectedDeliveryDate)}}
                                                    </span>
                                                </sui-table-cell>
                                                <!-- <sui-table-cell>
                                                    {{orderLine.productGroups[0].name}}
                                                </sui-table-cell> -->
                                            </sui-table-row>
                                        </sui-table-body>
                                    </sui-table>
                                    <p v-else>{{productsErrorMessage}}</p>
                                </div>
                            </DataCard>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-sm-4">
                            <sui-accordion exclusive styled>
                                <sui-accordion-title>
                                    <sui-icon name="dropdown" />
                                    Se rå opgave-data
                                </sui-accordion-title>
                                <sui-accordion-content>
                                    <pre>
                                        {{tasks}}
                                    </pre>
                                </sui-accordion-content>
                            </sui-accordion>
                        </div>
                        <div class="col-sm-4">
                            <sui-accordion exclusive styled>
                                <sui-accordion-title>
                                    <sui-icon name="dropdown" />
                                    Se rå serviceOrder data
                                </sui-accordion-title>
                                <sui-accordion-content>
                                    <pre>
                                        {{serviceOrder}}
                                    </pre>
                                </sui-accordion-content>
                            </sui-accordion>
                        </div>
                        <div class="col-sm-4">
                            <sui-accordion exclusive styled>
                                <sui-accordion-title>
                                    <sui-icon name="dropdown" />
                                    Se rå Produkt data
                                </sui-accordion-title>
                                <sui-accordion-content>
                                    <pre>
                                        {{products}}
                                    </pre>
                                </sui-accordion-content>
                            </sui-accordion>
                        </div>
                    </div>
                </sui-item-content>
            </sui-item>
        </sui-item-group>

    </div>
</template>

<script>
import swal from 'sweetalert'
import { Mixin } from '../../lib/Mixins/mixin'
import { DateMixin } from '../../lib/Mixins/dateMixin'
// import { Bookingmixin } from '../../lib/Bookingmixin'
import { SubtaskMixin } from '../../lib/SubtaskMixin'
import { Maps } from '../../lib/maps'
import { DataAPI } from '../../lib/DataAPI'
import { db } from '../../firebase'
import TableLoader from '../../components/TableLoader'
import EventBus from '../../EventBus'
import SmsModal from '../../components/Project/SmsModal'
import StateChangeModal from '../../components/Project/StateChangeModal.vue'
import SubtaskStateChangeForm from '../../components/Project/SubtaskStateChangeForm'
import SpliceReportsTable from '../../components/Project/SpliceReportsTable.vue'
import EditTechDataModal from '../../components/Project/EditTechDataModal'
import FileViewerModal from '../../components/Project/FileViewerModal.vue'
import NexelStatus from '../../components/Project/NexelStatus.vue'
import NexelData from '../../components/Project/NexelData.vue'
import TaskType from '../../lib/Enums/TaskType'
import ProjectType from '../../lib/Enums/ProjectType'
import DataCard from '../../components/Global/DataCard.vue'
import EditUnitWorkModal from '../../components/Project/EditUnitWorkModal.vue'
import UserRoles from '../../lib/Enums/UserRoles'

export default {
    name: 'InstallationDetailView',
    mixins: [Mixin, DateMixin, SubtaskMixin, Maps, DataAPI],

    enums: {
        TaskType,
        ProjectType,
        UserRoles
    },

    components: {
        TableLoader,
        SmsModal,
        StateChangeModal,
        SubtaskStateChangeForm,
        SpliceReportsTable,
        EditTechDataModal,
        FileViewerModal,
        DataCard,
        NexelStatus,
        NexelData,
        EditUnitWorkModal,
    },

    // components: {
    //     MglMap
    // },

    // props: {
    //     Accesshus: Number,
    //     Adresse: Object,
    //         // Husnr: null,
    //         // Postby: null,
    //         // Postnr: null,
    //         // Vej: null
    //     Email: Array,
    //     Navn: Array,
    //     KundeNr: Number,
    //     InstNr: Number,
    //     Skab: Number,
    //     Tlf: Array
    // },

    data() {
        return {
            project: {},
            tasks: [],
            appointments: [],
            configurationItem: {},
            contacts: [],
            serviceOrder: {},
            serviceOrderTasks: [],
            products: [],
            orderLines: [],
            loadingOrderLines: true,
            productsErrorMessage: "Der er tilsyneladende ingen produkter",

            unitWorks: [],

            smsModalOpen: false,
            activePhoneNumber: '',

            stateModalOpen: false,
            activeTask: {},
            
            noteModalOpen: false,
            noteType: "",
            noteText: "",
            noteFormSuccess: false,
            noteFormLoading: false,
            uploadFileModalOpen: false,
            fileNameInput: null,
            fileUpload: null,
            fileFormSuccess: false,
            fileFormLoading: false,
            openFileModalOpen: false,
            openFile: null,
            openFileLoading: false,

            coordinates: null,
            firebaseSubTasks: [],
            activeSubtask: {},
            activeSubtaskResponsibleIndex: null,

            showSubtaskModal: false,
            subtaskModalLoading: false,

            firebaseUser: {},

            editTechDataModalOpen: false,

            editUnitWorkModalOpen: false,
            activeInstallationUnitWork: [],
            activeUnitWork: {},
            preFilledUnitWorkDescription: null,

            ignoreLeadPermissions: window.localStorage.getItem('admin-ignore-lead-permissions') == 'true',
        }
    },

    computed: {
        user(){
            return this.$parent.$parent.user
        },
        userHasLeadPermissions() {
            if (this.ignoreLeadPermissions) return false
            if(this.firebaseUser && this.firebaseUser.Roles && this.firebaseUser.Roles.includes(UserRoles.EFB_PROJECT_EDITOR)) return true //Admins always have lead permissions
            if (!this.project.Contacts) return false
            let userContact = this.project.Contacts.find(contact => contact.Email == this.user.email) //Find user in project contacts
            if (!userContact) return false //if user is not in project contacts, they cannot be project lead
            if (userContact.Roles.includes('ProjectLead')) return true //Allow lead permissions for project lead
            return false
        },
        availableWorkers() {
            // if (this.project.Workers.length == 0) return []

            let workersArray = this.project.Workers || []
            workersArray.push({Email: "Ingen"})

            return workersArray.map((worker, index) => {
                return { key: index, value: index, text: worker.Email }
            })
        },

        internalSubTasks() {
            return this.constructSubTasksArray(this.firebaseSubTasks || [])
        },

        lastTask() {
            return this.findLastTask(this.tasks)
        },

        firstTask() {
            return this.findFirstTask(this.tasks)
        },
    },

    methods: {
        async getData() {
            EventBus.$emit('function-activity', {functionName: 'InstallationDetail_getData', isActive: true})
            await this.getProject()
            await this.getTasks()
            this.getCoordinates()
            if (this.tasks[0]?.serviceOrder?.id){
                await this.getServiceOrder()
                await this.getProducts()
            } else {
                this.loadingOrderLines = false // To turn off the loading animation when there's no way to load them.
            }
            EventBus.$emit('function-activity', {functionName: 'InstallationDetail_getData', isActive: false})
        },

        async getProject(){
            EventBus.$emit('function-activity', {functionName: 'InstallationDetail_getProject', isActive: true})
            let project = await this.dataGetProjectById(this.$route.params.projectIdentifier)
            this.project = project
            EventBus.$emit('function-activity', {functionName: 'InstallationDetail_getProject', isActive: false})
        },

        async getTasks() {
            EventBus.$emit('function-activity', {functionName: 'InstallationDetail_getTasks', isActive: true})
            // let tasks = await this.dataGetProjectTasks()
            let tasks = await this.dataGetTasksV2(TaskType.ALL, this.$route.params.installationId, null, null)
            let filteredTasks = tasks.projectTasks.filter(task => {
                return String(task.configurationItem.value) == String(this.$route.params.installationId)
            })

            if (!filteredTasks.length) {
                console.log('InstallationDetail found no tasks on installation, trying 3 months back, this may take a while')
                let dateFilter = this.subtractMonths(new Date(), 3)
                tasks = await this.dataGetClosedProjectTasks(dateFilter)
                filteredTasks = tasks.projectTasks.filter(task => {
                    return String(task.configurationItem.value) == String(this.$route.params.installationId)
                })
            }

            if (!filteredTasks.length) {
                swal('Fejl', 'Det var ikke muligt at finde opgaverne på denne kunde.', 'error')
                EventBus.$emit('function-activity', {functionName: 'InstallationDetail_getTasks', isActive: false})
                return
            }

            for (let i in filteredTasks) { //Loop through listed tasks and get full tasks instead
                let task = filteredTasks[i]
                let fulltask = await this.dataGetProjectTask(task)
                if (!fulltask.project) fulltask.project = filteredTasks[i].project
                filteredTasks[i]=fulltask
            }

            this.tasks = filteredTasks.sort((a, b) => (a.number > b.number) ? 1 : -1)
            this.configurationItem = filteredTasks[0].configurationItem
            this.contacts = filteredTasks[0].contacts
            EventBus.$emit('function-activity', {functionName: 'InstallationDetail_getTasks', isActive: false})
        },

        async getServiceOrder() {
            EventBus.$emit('function-activity', {functionName: 'InstallationDetail_getServiceOrder', isActive: true})
            var serviceOrder = await this.dataGetServiceOrder(this.tasks[0].serviceOrder.id)
            this.serviceOrder = serviceOrder
            this.serviceOrderTasks = serviceOrder.project.tasks.sort((a, b) => (a.number > b.number) ? 1 : -1)
            EventBus.$emit('function-activity', {functionName: 'InstallationDetail_getServiceOrder', isActive: false})
        },

        async getProducts() {
            if (this.configurationItem.type != 'Installation') return false //Do not get products for items that are not type=Installation
            EventBus.$emit('function-activity', {functionName: 'InstallationDetail_getProducts', isActive: true})
            this.loadingOrderLines = true
            console.log(this.tasks[0].serviceOrder.sonWinId)
            if (this.serviceOrder.sonWinId){
                var products = await this.dataGetProducts(String(this.serviceOrder.sonWinId))
                this.products = products
                this.orderLines = []
                for (var i=0; i<products.length; i ++){
                    for (var j=0; j<products[i].orderLines.length; j++){
                        this.orderLines.push(products[i].orderLines[j])
                    }
                }
                this.orderLines.sort((a,b) => a.product.productGroups[0].id > b.product.productGroups[0].id ? 1 : -1)
            } else {
                this.productsErrorMessage = "Kan ikke hente produter uden et ServiceOrder SonWinID"
            }
            this.loadingOrderLines = false
            EventBus.$emit('function-activity', {functionName: 'InstallationDetail_getProducts', isActive: false})
        },

        async getCoordinates() {
            // console.log(this.configurationItem.label)
            if (!this.configurationItem.label) return null
            EventBus.$emit('function-activity', {functionName: 'InstallationDetail_getCoordinates', isActive: true})
            this.coordinates = await this.dataGetCoordinatesFromFirebase(this.configurationItem.label, this.project.id)
            console.log(this.coordinates)
            EventBus.$emit('function-activity', {functionName: 'InstallationDetail_getCoordinates', isActive: false})
        },

        openSmsModal(phoneNumber) {
            this.activePhoneNumber = phoneNumber
            this.smsModalOpen = true
        },

        statusClicked(task) {
            this.activeTask = task
            this.stateModalOpen = true
        },

        //Lots and lots of duplicate code I'm too tired to fix right now
        
        subtaskStatusClicked(subtask = null) {
            if(!subtask || !subtask.code) {
                this.showSubtaskModal = false
                this.activeSubtaskResponsibleIndex = null
            }
            else if (this.activeSubtask.code == subtask.code && this.showSubtaskModal == true){
                this.showSubtaskModal = false
                this.activeSubtaskResponsibleIndex = null
            } else {
                this.activeSubtask = {...subtask}
                this.activeSubtaskValue = subtask.state.value
                this.activeSubtaskDeferred = subtask.Deferred?.Value
                if (subtask.Responsible && this.availableWorkers) {
                    this.activeSubtaskResponsibleIndex = this.availableWorkers.findIndex(worker => {
                        if (!worker) return false
                        return worker.text.toLowerCase() == subtask.Responsible.Email.toLowerCase()
                    })
                }
                this.showSubtaskModal = true
            }
        },
        
        onClickTask(task) {
            const { projectIdentifier, installationId } = this.$route.params
            let alias = this.$route.path.includes('timeline') ? 'timeline' : 'installations'
            const url = `/projects/${projectIdentifier}/${alias}/${installationId}/${task.number}`
            this.$router.push({ path: url })
        },

        async getAttachment(attachmentId){
            EventBus.$emit('function-activity', {functionName: 'InstallationDetail_getAttachment', isActive: true})
            let response = await this.dataGetAttachment(attachmentId)
            EventBus.$emit('function-activity', {functionName: 'InstallationDetail_getAttachment', isActive: false})
            return response
        },
        async openAttachment(attachmentId){
            EventBus.$emit('function-activity', {functionName: 'InstallationDetail_openAttachment', isActive: true})
            this.openFileLoading = true //Start loading animation
            this.openFileModalOpen = true //Open modal
            this.openFile = await this.getAttachment(attachmentId) //Get attachment from API
            this.openFileLoading = false //End loading animation
            EventBus.$emit('function-activity', {functionName: 'InstallationDetail_openAttachment', isActive: false})
        },
        openUploadNoteModal(){
            this.noteModalOpen = true //Open the note modal
        },
        async postNote(){
            EventBus.$emit('function-activity', {functionName: 'InstallationDetail_postNote', isActive: true})
            let response = await this.dataPostNote(this.firstTask.id,this.configurationItem.label,this.noteText,this.noteType,this.$route.params.projectIdentifier)
            EventBus.$emit('function-activity', {functionName: 'InstallationDetail_postNote', isActive: false})
            return(response)
        },
        async submitNote(){
            EventBus.$emit('function-activity', {functionName: 'InstallationDetail_submitNote', isActive: true})
            this.noteFormLoading = true //Start loading animation
            await this.postNote() //Post note to database
            this.noteText = "" //Clear textfield
            this.noteType = "" //Clear radio-buttons
            await this.getTasks() //Retrieve latest task data
            this.noteModalOpen = false //Close modal
            this.noteFormLoading = false //End loading animation
            EventBus.$emit('function-activity', {functionName: 'InstallationDetail_submitNote', isActive: false})
        },
        openFileModal(){
            this.uploadFileModalOpen = true //Open the file modal
        },
        setFileUpload(e){
            var files = e.target.files || e.dataTransfer.files;
            console.log(files)
            if (!files || !files.length) {
                this.fileUpload = null
                return false
            }
            this.fileUpload = files[0]
            return true
        },
        async encodeFile(file){
            EventBus.$emit('function-activity', {functionName: 'InstallationDetail_encodeFile', isActive: true})
            const toBase64 = file => new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = () => resolve (reader.result)
                reader.onerror = error => reject(error)
            })
            let encodedFile = await toBase64(file).catch(e => Error(e))
            if(encodedFile instanceof Error) {
                console.log('Error encoding file: ',encodedFile.message)
                EventBus.$emit('function-activity', {functionName: 'InstallationDetail_encodeFile', isActive: false})
                return
            }
            EventBus.$emit('function-activity', {functionName: 'InstallationDetail_encodeFile', isActive: false})
            return encodedFile
        },
        async submitFile(){
            EventBus.$emit('function-activity', {functionName: 'InstallationDetail_submitFile', isActive: true})
            this.fileFormLoading = true //Start loading animation
            let base64Content = await this.encodeFile(this.fileUpload)
            base64Content = String(base64Content).substring(base64Content.indexOf('base64,')+7)
            let fileName = this.fileNameInput
            if (!fileName) {fileName = this.fileUpload.name}
            await this.dataPostAttachment(this.firstTask.id, fileName, this.fileUpload.type, base64Content)
            this.uploadFileModalOpen = false //Close file modal
            this.fileNameInput = null
            await this.getTasks()
            this.fileFormLoading = false //End loading animation
            EventBus.$emit('function-activity', {functionName: 'InstallationDetail_submitFile', isActive: false})
        },
        addNewUnitWork(note = null) {
            this.preFilledUnitWorkDescription = note
            if (typeof this.preFilledUnitWorkDescription != 'string') {
                this.preFilledUnitWorkDescription = null
            }
            this.activeUnitWork = {}
            this.editUnitWorkModalOpen = true
        },
        unitWorkClicked(unitWork){
            console.log(unitWork)
            this.activeUnitWork = unitWork
            this.editUnitWorkModalOpen = true
        },
    },

    watch: {
        '$route': 'getData',

        configurationItem: {
            immediate: false,
            handler() {
                this.$bind('firebaseSubTasks', db.collection(`InternalSubTasks`).doc(this.configurationItem.label))
                this.$bind('appointments', db.collection('Appointments').where('InstallationLabel','==',this.configurationItem.label).orderBy('Updated'))
                this.$bind('unitWorks', db.collection('UnitWork').where('ConfigurationItem.Label','==',this.configurationItem.label).orderBy('Date', 'desc'))
                document.title = `${this.formatAddress(this.configurationItem.address)}, ${this.project.Name} - FiberTeam`
            }
        },

        appointments: {
            immediate: false,
            async handler() {
                EventBus.$emit('function-activity', {functionName: 'InstallationDetail_appointments_watch_handler', isActive: true})
                let allTasks = [...this.tasks]
                for (let i in this.appointments) {
                    const appointment = this.appointments[i]
                    for (let j in appointment.ProjectTasks) {
                        const appointmentTask = appointment.ProjectTasks[j]
                        if (allTasks.findIndex((task) => task.id == appointmentTask.Id) == -1) {
                            let fulltask = await this.dataGetProjectTask({
                                id: appointmentTask.Id,
                                number: appointmentTask.Number,
                            })
                            allTasks.push(fulltask)
                            console.log('Added task from appointment')
                        }
                    }
                }
                this.tasks = allTasks.sort((a, b) => (a.number > b.number) ? 1 : -1)
                EventBus.$emit('function-activity', {functionName: 'InstallationDetail_appointments_watch_handler', isActive: false})
            }
        },

        user: {
            immediate: true,
            handler(user) {
                // this.$unbind('firebaseUser')
                if (user){
                    this.$bind('firebaseUser', db.collection(`Users`).doc(user.email))
                }
            }
        },

        firebaseInternalSubtasks: {
            immediate: false,
            handler() {
                EventBus.$emit('reload-subtask-status-icons')
            }
        },
    },

    async beforeMount() {
        EventBus.$emit('function-activity', {functionName: 'InstallationDetail_beforeMount', isActive: true})
        await this.getData()
        document.title = `${this.formatAddress(this.configurationItem.address)}, ${this.project.Name} - FiberTeam`
        EventBus.$emit('function-activity', {functionName: 'InstallationDetail_beforeMount', isActive: false})
    },

    created(){
        EventBus.$on('updateProducts-click', () => {
            this.getProducts()
        })

        EventBus.$on('techDataEdit-click', () => {
            this.editTechDataModalOpen = true
        })

        EventBus.$on('sms-modal-closing', () => {
            this.smsModalOpen = false
        })

        EventBus.$on('state-changed', () => {
            this.getTasks()
        })

        //TODO: listen for 'add-unit-work' event, and open the editUnitWork form with note pre-filled

        EventBus.$on('stateChange-modal-closing', () => {
            this.stateModalOpen = false
        })

        EventBus.$on('edit-tech-data-modal-closing', () => {
            this.editTechDataModalOpen = false
        })

        EventBus.$on('subtaskModalLoading', (payload) => {
            this.subtaskModalLoading = payload
        })

        EventBus.$on('subtaskStateChangeFormSubmit', () => {
            this.showSubtaskModal = false
        })

        EventBus.$on('newNote-click', () => {
            this.openUploadNoteModal()
        })

        EventBus.$on('newFile-click', () => {
            this.openFileModal()
        })

        EventBus.$on('file-viewer-modal-close', () => {
            this.openFileModalOpen = false
        })

        EventBus.$on('add-unit-work-click', () => {
            this.addNewUnitWork()
        })

        EventBus.$on('edit-unit-work-modal-close', () => {
            this.activeUnitWork = {}
            this.editUnitWorkModalOpen = false
        })

        EventBus.$on('admin-ignore-lead-permissions', (value) => {this.ignoreLeadPermissions = value})
    },

    beforeDestroy(){
        EventBus.$off('updateProducts-click')
        EventBus.$off('state-changed')
    },
}
</script>
<style scoped>
    .half-height .scrollable {
        overflow-y: auto;
        overflow-x: auto;
        height: 100%;
        max-height: calc(50vh - 157px); /*Hack to keep scrollable divs (and sui-table's) inside half-height cards*/
    }

    .full-height .scrollable{
        overflow-y: auto;
        overflow-x: auto;
        height: 100%;
        max-height: calc(100vh - 252px); /*Hack to keep scrollable divs (and sui-table's) inside full-height cards*/
    }

    .scrollable{
        overflow-y: auto;
        overflow-x: auto;
        height: 100%;
    }

    .full-height {
        height: calc(100vh - 205px);
    }

    .half-height {
        height: auto;
        max-height: calc(50vh - 110px);
    }

    .card-body.p-0 .table tbody>tr>td:first-of-type {
        padding-left: 0.7rem;
    }

    table.selectable tr:hover td {
        cursor: pointer;
    }

    span.success {
        text-emphasis-color: green;
    }

    span.danger {
        text-emphasis-color: red;
    }
</style>