<template> <!-- Template is expected to be inside a <sui-table> with 2 columns -->
    <sui-table-body>
        <sui-table-row>
            <sui-table-header-cell>
                Port-data
            </sui-table-header-cell>
            <sui-table-cell>
                <span v-if="apiResponse">{{toUserFriendlyTimestamp(apiResponse.headers.date, true, false)}}</span> 
                <sui-button v-if="includeReloadBtn" size="mini" @click.prevent="getNexelData" style="float: right"><i class="fas fa-sync" v-bind:class="{'fa-spin': dataLoading}"></i></sui-button>
            </sui-table-cell>
        </sui-table-row>
        <template v-if="apiResponse">    
            <sui-table-row>
                <sui-table-cell>InstallationsNr</sui-table-cell>
                <sui-table-cell>
                    {{apiResponse.id}}
                </sui-table-cell>
            </sui-table-row>
            <sui-table-row>
                <sui-table-cell>ASR</sui-table-cell>
                <sui-table-cell>
                    <span v-if="apiResponse.fqdn">{{apiResponse.fqdn.replace('.energifyn.net', '')}}</span>
                </sui-table-cell>
            </sui-table-row>
            <sui-table-row>
                <sui-table-cell>Port</sui-table-cell>
                <sui-table-cell>{{apiResponse.port}}</sui-table-cell>
            </sui-table-row>
            <sui-table-row>
                <sui-table-header-cell colspan="2">Capabilities</sui-table-header-cell>
            </sui-table-row>
            <template v-if="apiResponse.capabilities">
                <sui-table-row v-for="key of Object.keys(apiResponse.capabilities)" :key="key">
                    <sui-table-cell>
                        <span v-if="key == 'cpeSerial'">Mac-adresse</span>
                        <span v-else>{{camelCaseToSentence(key)}}</span>
                    </sui-table-cell>
                    <sui-table-cell>
                        <span v-if="key == 'cpeSerial'">{{formatMacAdress(apiResponse.capabilities[key])}}</span>
                        <span v-else>{{apiResponse.capabilities[key]}}</span>
                    </sui-table-cell>
                </sui-table-row>
            </template>
        </template>
    </sui-table-body>
</template>

<script>
import { Mixin } from '../../lib/Mixins/mixin'
import { DateMixin } from '../../lib/Mixins/dateMixin'
import { DataAPI } from '../../lib/DataAPI'
import EventBus from '../../EventBus'

export default {
    mixins: [Mixin, DateMixin, DataAPI],

    components: {
        // TableLoader,
    },

    props: {
        instLabel: String,
        includeReloadBtn: Boolean,
    },

    data(){
        return {
            dataLoading: false,
            apiResponse: null,
        }
    },

    methods: {
        async getNexelData() {
            EventBus.$emit('function-activity', {functionName: 'NexelData_getNexelData', isActive: true})
            this.apiResponse = null
            EventBus.$emit('retrieved-nexel-data', {data: 'Henter data...', loading: true})
            if (!this.instLabel) {
                EventBus.$emit('retrieved-nexel-data', {data: 'Intet installations-nr', loading: false})
                EventBus.$emit('function-activity', {functionName: 'NexelData_getNexelData', isActive: false})
                return null
            }
            this.dataLoading = true
            try {
                this.apiResponse = await this.dataGetNexelData(this.instLabel)
                EventBus.$emit('retrieved-nexel-data', {data: this.apiResponse.id, loading: false})
                this.dataLoading = false
            } catch (error) {
                EventBus.$emit('retrieved-nexel-data', {data: 'Kunne ikke hente data!', loading: false})
                console.error(error)
                this.dataLoading = false
            }
            EventBus.$emit('function-activity', {functionName: 'NexelData_getNexelData', isActive: false})
            return this.apiResponse
        },

        formatMacAdress(macAdress) {
            if (!macAdress) return null
            macAdress = macAdress.toUpperCase().match(/([A-Z0-9])/g).join('') //Removes any special characters
            macAdress = macAdress.match(/.{1,2}/g).join(':') //Inserts colons in the right places
            return macAdress
        },
    },

    mounted() {
        EventBus.$on('reload-nexel-data-click', () => {this.getNexelData()})
    },

    beforeDestroy() {
        EventBus.$off('reload-nexel-data-click')
    },

    watch: {
        instLabel: {
            immediate: true,
            handler() {
                this.getNexelData()
            }
        }
    }
}
</script>