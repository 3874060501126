// Import JS Dependencies
import Vue from 'vue'
import SuiVue from 'semantic-ui-vue'
import PortalVue from 'portal-vue'
import enums from 'vue-enums'
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

if (process.env.VUE_APP_ENV == 'production' || process.env.VUE_APP_ENV == 'beta') {

    Sentry.init({
        Vue,
        dsn: "https://7dc2724b3ab945759c2185e8e7203548@o1034484.ingest.sentry.io/6001093",
        integrations: [new Integrations.BrowserTracing()],

        environment: process.env.NODE_ENV,
        release: process.env.VUE_APP_ENV == 'production' ? process.env.VUE_APP_VERSION : `${process.env.VUE_APP_VERSION}+beta`,

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
        timeout: 120000,
        logErrors: true
    })

}


import VueRouter from 'vue-router'
import { router } from './router'

import { firestorePlugin } from 'vuefire'

import store from './store'

import { auth } from './firebase'

import VueSignature from "vue-signature-pad";


// Import CSS Dependencies
import 'semantic-ui-css/semantic.min.css'


console.log(`Booting Fiberlan FTTH App v${process.env.VUE_APP_VERSION}. NodeEnv: ${process.env.NODE_ENV} VueEnv: ${process.env.VUE_APP_ENV}`)


// Suppresses navigation errors!
// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err)
// }

// Register Vue Dependencies/AddonsSet 
Vue.config.productionTip = false
Vue.use(SuiVue)
Vue.use(enums)
Vue.use(VueRouter)
Vue.use(PortalVue)
Vue.use(firestorePlugin)
Vue.use(VueSignature)
Vue.config.productionTip = false;

import DataCard from './components/Global/DataCard'
Vue.component('DataCard', DataCard);

import MapMarker from './components/Maps/MapMarker'
Vue.component('MapMarker', MapMarker);

// Import App Component
import App from './App.vue'



// Create the Vue App and mount it
let app
auth.onAuthStateChanged((user) => {
    if (!app) {
        app = new Vue({
            render: h => h(App),
            router,
            store
        }).$mount('#app')
    }
    if (user) {
        store.dispatch('fetchUserProfile', user)
    }
})
