<template>
    <sui-modal
        id="documentationErrorReportModal"
        :open="isModalOpen" 
        v-on:clickAwayModal="clickAway"
    >
        <sui-modal-header>Rapportér fejl i dokumentation</sui-modal-header>
        <sui-modal-content scrolling>
            <sui-form :loading="formLoading">
                <sui-message>
                    <sui-message-content>
                        Der skrives automatisk en note på opgaven ({{task.number}} - Inst#{{installationLabel}}), samt ekstra-arbejde på nedenstående tid.
                    </sui-message-content>
                </sui-message>
                <sui-form-fields>
                    <sui-form-field>
                        <label>Dato</label>
                        <sui-input type="date" v-model="dateInput"/>
                    </sui-form-field>
                    <sui-form-field>
                        <label>Medarbejder(e) på opgaven</label>
                        <sui-dropdown placeholder="Medarbejdere" selection search multiple :options="workerOptions" v-model="selectedWorkers"/>
                    </sui-form-field>
                    <sui-form-field>
                        <label>Mandetimer brugt i alt</label>
                        <sui-input placeholder="t:mm:ss" v-model="hoursSpentInput"/>
                    </sui-form-field>
                    <sui-form-field disabled v-if="selectedWorkers.length > 1">
                        <label>Timer per mand</label>
                        <span>{{decimalToHourString(hoursSpentDecimal / selectedWorkers.length)}}</span>
                    </sui-form-field>
                </sui-form-fields>
                <sui-form-fields>
                    <sui-form-field>
                        <label>Rør-type</label>
                        <sui-dropdown placeholder="Blæserør" selection search :options="conduitTypeOptions" v-model="selectedConduitType"/>
                    </sui-form-field>
                    <sui-form-field>
                        <label>Rørgruppe ændret til</label>
                        <span>
                            <sui-input type="number" min="1" max="9" style="width: 70px" v-model="conduitGroupNumberInput"/>
                            &nbsp;
                            <sui-dropdown style="width: 110px; min-width: 100px;" placeholder="G24" selection search :options="conduitGroupOptions" v-model="conduitGroupInput" />
                        </span>
                    </sui-form-field>
                    <sui-form-field>
                        <label>Rør ændret til</label>
                        <sui-dropdown placeholder="Blæserør" selection search :options="conduitColorOptions" v-model="conduitInput"/>
                    </sui-form-field>
                </sui-form-fields>
                <sui-form-field>
                    <label>Beskrivelse</label>
                    <textarea v-model="descriptionInput" placeholder="Beskrivelse af den fejl du har fundet i dokumentationen, herunder hvordan arbejdet i virkeligheden er lavet..."/>
                </sui-form-field>
                <sui-message>
                    <sui-message-header><small><strong>Note der skrives på opgave:</strong></small></sui-message-header>
                    <pre>{{this.finishedNote}}</pre>
                </sui-message>
            </sui-form>
        </sui-modal-content>
        <sui-modal-actions>
            <sui-button 
                negative
                @click.prevent="clickAway"
            >
                Luk
            </sui-button>
            <sui-button 
                positive
                @click.prevent="saveChanges"
            >
                Gem
            </sui-button>
        </sui-modal-actions>
    </sui-modal>
</template>
<script>
import EventBus from '../../EventBus'
import { DataAPI } from '../../lib/DataAPI'
import { Mixin } from '../../lib/Mixins/mixin'
import { DateMixin } from '../../lib/Mixins/dateMixin'
import { ProjectSettingsMixin } from '@/modules/ProjectSettings/projectSettingsMixin.js'
import { unitWorkMixin } from '../../lib/unitWorkMixin'
import { db } from '../../firebase'
import EmailTemplate from '../../lib/Enums/EmailTemplate'

export default {
    name: "DocumentationErrorReportModal",

    mixins: [Mixin, DateMixin, DataAPI, EventBus, unitWorkMixin, ProjectSettingsMixin],

    enums: {
        EmailTemplate
    },

    props: {
        task: Object,
        isOpen: Boolean,
        installationLabel: String,
        availableWorkers: Array,
        project: Object,
    },

    data(){
        return {
            dateInput: this.formatMachineDate(new Date(), '-'),
            hoursSpentInput: '0:30',
            descriptionInput: null,
            selectedWorkers: [],

            formLoading: false,

            conduitTypes: [],
            conduitGroupNumberInput: null,
            selectedConduitType: null,
            conduitGroupInput: null,
            conduitInput: null,

            appEnv: null,
        }
    },

    computed: {
        isModalOpen() {
            return this.isOpen
        },

        activeTask() {
            // if (!this.task) return null
            // if (!this.task.tasks) return null
            if (!this.task) return null
            return this.task
        },

        hoursSpentDecimal() {
            return this.hourStringToDecimal(this.hoursSpentInput)
        },

        user() {
            return this.$parent.user
        },

        firebaseUser() {
            return this.$parent.firebaseUser
        },

        workerOptions() {
            let optionsArray = []
            for (let worker of this.availableWorkers) {
                if (worker && worker.Email && worker.Email.includes('@')){
                    optionsArray.push({
                        text: this.getInitialsFromEmail(worker.Email),
                        value: worker.Email
                    })
                }
            }
            return optionsArray
        },

        conduitTypeOptions(){
            let options = []
            for (let ct of this.conduitTypes) {
                options.push({
                    text: ct.Name,
                    value: ct.id,
                })
            }
            return options  
        },

        conduitGroupOptions(){
            let ct = this.conduitTypes.find(ct => ct.id == this.selectedConduitType)
            if (!ct) return [{text: '   ', value: null}]
            let options = []
            for (let grp of ct.Groupings) {
                options.push({text: grp.Name, value: grp.Name})
            }
            return options
        },

        conduitColorCode() {
            if (this.selectedConduitType){
                let conduitType = this.conduitTypes.find(ct => ct.id == this.selectedConduitType)
                if (conduitType && conduitType.ColorCode) return conduitType.ColorCode.ColorCode
                else throw new Error(`Error selecting conduit type:\n${JSON.stringify({[this.selectedConduitType]: conduitType}, null, 4)}`)
            } else return []
        },

        conduitColorOptions() {
            if (!this.conduitGroupInput) return []
            let options = []
            let numberInGroup = Number(this.conduitGroupInput.match(/\d+/g))
            for (let cc of this.conduitColorCode) {
                if (Number(cc.Nr) > numberInGroup) continue;
                options.push({
                    text: `${cc.Nr} ${cc.Name}`,
                    value: `${cc.Nr} ${cc.Name}`
                })
            }
            return options
        },

        finishedNote() {
            if (!this.conduitGroupNumberInput && !this.conduitGroupInput && !this.conduitInput) return this.descriptionInput
            let noteString = `Blæserør ændret til ${this.conduitGroupNumberInput ? `${this.conduitGroupNumberInput}-` : ''}${this.conduitGroupInput ? `${this.conduitGroupInput} ` : ''}${this.conduitInput}`
            if (this.descriptionInput) noteString += `\n${this.descriptionInput}`
            return noteString
        },
    },

    methods: {
        clickAway() {
            EventBus.$emit('documentation-error-report-modal-closing')
        },

        resetForm(){
            this.dateInput = this.formatMachineDate(new Date(), '-')
            this.hoursSpentInput = '0:30'
            this.selectedWorkers = [this.user.email]
            this.selectedConduitType = null
            this.conduitGroupNumberInput = null
            this.conduitGroupInput = null,
            this.conduitInput = null,
            this.descriptionInput = null
        },

        async saveChanges() {
            EventBus.$emit('function-activity', {functionName: 'DocumentationErrorReportModal_saveChanges', isActive: true})
            this.formLoading = true

            let Unit = this.unitWorkCompositeUnits.find(u => u.Id == '951.F.01')

            const unitWorkDocument = {
                AreaCode: this.task.configurationItem?.area?.sonWinProjectId,
                ProjectInstallationType: this.task.project?.type?.value,
                ConfigurationItem: {
                    Label: this.task.configurationItem?.label || null,
                    Area: this.task.configurationItem?.area || null,
                    Cabinet: this.task.configurationItem?.cabinet || null,
                    TechnicalData: this.task.configurationItem?.technicalData || null,
                    Type: this.task.configurationItem?.type || null,
                    Value: this.task.configurationItem?.value || null,
                    Address: this.formatAddress(this.task.configurationItem?.address, false) || null,
                },
                ReferenceId: this.task.referenceId,
                ServiceOrder: this.task.serviceOrder,
                PayedBy: "EFB",
                Unit,
                Amount: this.hoursSpentDecimal,
                EquivalentHours: this.hoursSpentDecimal,
                Description: this.finishedNote,
                Workers: this.selectedWorkers,
                TimeStamp: new Date(),
                CreatedBy: {
                    Name: this.user.displayName,
                    Email: this.user.email,
                    Initials: this.user?.email?.substring(0, this.user.email.indexOf('@')-1).toUpperCase()
                },
                AutoGenerated: true,
                Date: this.formatMachineDate(this.dateInput, '-'),
                Billed: {
                    Bool: false,
                    Updated: new Date()
                },
                LinkedProjects: [this.$route.params.projectIdentifier],
                Price: this.calculatePrice(Unit.Id, this.hoursSpentDecimal),
                FromDocumentation: {},
            }

            let jobs = []
            jobs.push(this.dataPostNote(this.task.id,this.installationLabel,`Fejl i dokumentation:\n${this.finishedNote}`,'Ekstern',this.$route.params.projectIdentifier)) //Note on task
            jobs.push(this.dataAddOrUpdateUnitWork(unitWorkDocument).then(() => {console.log('Saved unitWork to Firebase')}).catch(reason => console.error(reason))) //UnitWork
            await Promise.allSettled(jobs)

            // Send mail to EFB
            let sendMailtoEFB = this.getProjectSetting(this.project,"docUpdateSendMail")
            if (sendMailtoEFB){
                let contactEFB = this.$parent.project.Contacts[this.$parent.project.Contacts.findIndex((i) => i.Company.id == "Energifyn")].Email
                if (this.appEnv != 'production' && this.appEnv != 'beta'){ //App env is not production or beta
                    contactEFB = this.user.email
                    console.log("This is a development environment!")
                    console.log("E-mails will be send to:",contactEFB)
                }
                this.dataAddEmail({
                    to: contactEFB,
                    bcc: this.user.email,
                    template: {
                        name: EmailTemplate.UPDATE_DOCUMENTATION,
                        data: {
                            username: this.firebaseUser.Name,
                            instLabel: this.installationLabel,
                            note: this.finishedNote
                        }
                    }
                })
            }


            

            this.resetForm()
            this.clickAway()
            this.formLoading = false
            EventBus.$emit('function-activity', {functionName: 'DocumentationErrorReportModal_saveChanges', isActive: false})
        },
    },

    beforeMount() {
        this.appEnv = process.env.VUE_APP_ENV
    },

    mounted(){
        this.$bind('conduitTypes', db.collection('ConduitTypes'))
    },

    watch: {
        user: {
            immediate: true,
            handler(user){
                // console.log('detected change to user', this.selectedWorkers)
                if (!this.selectedWorkers.length && this.workerOptions.find(w => w.value == user.email)){
                    console.log('Setting self as selected worker')
                    this.selectedWorkers = [user.email]
                }
            }
        },
        colorCodeOptions: {
            immediate: true,
            handler(cco) {
                if (!this.selectedColorCode && cco && cco.length){
                    this.selectedColorCode = cco[0].value
                }
            }
        }
    }
    
}
</script>