
class TaskCode {

    static BOOKBESIGT = 'KONBESIGT'
    static FIBERKONSU = 'FIBERKONSU'
    static PATCH = 'PATCH HUS'
    static BLAES = 'BLAES'
    static BOOKKUNDE = 'BOOKKUNDE'
    static CPE = 'CPE'
    static SPLIDS = 'SPLIDS'
    static UDVID = 'UDVID'
    static TICKET = 'TICKET'
    static EMPTY = ''

    static AllArray = [
        this.BOOKBESIGT,
        this.FIBERKONSU,
        this.PATCH, 
        this.BLAES, 
        this.BOOKKUNDE, 
        this.CPE, 
        this.UDVID, 
        this.TICKET, 
        this.EMPTY
    ]

    static BookRoleArray = [
        this.BOOKBESIGT,
        this.BOOKKUNDE,
    ]

    static PrimaryRoleArray = [
        this.FIBERKONSU,
        this.CPE,
        this.TICKET,
    ]

    static ConnectionDateArray = [
        this.CPE,
        this.PATCH,
        this.UDVID,
    ]

}

export default TaskCode