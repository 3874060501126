<template>
    <sui-modal
        id="showTaskData"
        :open="isOpen"
        v-on:clickAwayModal="closeModal()"
        v-if="task"
    >
        <sui-modal-header>
            <span> Task data for {{ task.number }}</span>
        </sui-modal-header>
        <sui-modal-content scrolling style="max-height: 70vh">
            <pre><code> {{ JSON.stringify(task, null, 4) }} </code></pre>
        </sui-modal-content>
            <sui-modal-actions>
            <sui-button
                @click="closeModal()"
            >
                Luk
            </sui-button>
        </sui-modal-actions>
    </sui-modal>
</template>

<script>
import EventBus from '../../EventBus'

export default {
    props: {
        isOpen: {
            type: Boolean,
            required: true,
            default: false,
        },
        task: {
            type: Object,
            required: true,
            default: null,
        },
    },

    data(){
        return {
            //
        }
    },

    methods: {
        closeModal(){
            EventBus.$emit('show-task-data-modal-close')
        },   
    },
}
</script>