<template>
    <sui-modal
        small
        :open="isOpen"
        v-on:clickAwayModal="closeModal()"
    >
        <sui-modal-header>
            {{beforeChange ? 'Redigér' : 'Tilføj'}} arbejds-enhed
        </sui-modal-header>
        <sui-modal-content scrolling>
            <sui-form warning :loading="formLoading">
                <div class="row">
                    <div class="col-sm-8">
                        <sui-form-fields>
                            <sui-form-field width="five" required>
                                <label>Enheds ID</label>
                                <sui-input required placeholder='Pos Nr' v-model="unitIdInput" />
                            </sui-form-field>
                            <sui-form-field width="eleven" required>
                                <label>Enheds Navn</label>
                                <sui-input required placeholder="Kort beskrivelse af enheden" v-model="unitNameInput"/>
                            </sui-form-field>
                        </sui-form-fields>
                        <sui-form-fields>
                            <sui-form-field width="five" required>
                                <label>Enhed <small>(stk, m, t, ...)</small></label>
                                <sui-input required placeholder="stk" v-model="unitInput"/>
                            </sui-form-field>
                        </sui-form-fields>
                    </div>
                    <div class="col-sm-4">
                        <sui-form-field required>
                            <label>Kategorier</label>
                            <sui-dropdown
                                fluid multiple search selection
                                :options="UnitWorkCategories.DROPDOWN_OPTIONS"
                                v-model="selectedCategories"
                            />
                        </sui-form-field>
                    </div>
                </div>
                <sui-form-fields>
                    <sui-form-field required width="six"><label>Enheds ID</label></sui-form-field>
                    <sui-form-field required width="two"><label>Procent</label></sui-form-field>
                    <sui-form-field width="three"><label>Tilsvarende timer</label></sui-form-field>
                    <sui-form-field width="three"><label>Pris</label></sui-form-field>
                    <sui-form-field width ="two"><label>Slet</label></sui-form-field>
                </sui-form-fields>
                <sui-form-fields v-for="(part, index) in partsInput" :key="index">
                    <sui-form-field required width="six">
                        <sui-dropdown
                            fluid search selection
                            :options="unitWorkUnitsDropdown"
                            v-model="part.BaseUnit"
                        />
                    </sui-form-field>
                    <sui-form-field required width="two">
                        <sui-input required type="number" min="0" max="1000" step="0.01" placeholder="100" v-model="part.Percentage" @blur="reCalculateEH(index, 'percentage')"/>
                    </sui-form-field>
                    <sui-form-field width="three">
                        <sui-input placeholder="0:00:00" v-model="part.EquivalentHours" @blur="reCalculateEH(index, 'hours')"/>
                    </sui-form-field>
                    <sui-form-field width="three">
                        <sui-input type="number" min="0" step="0.01" placeholder="0" v-model="part.Price" @blur="reCalculateEH(index, 'price')"/>
                    </sui-form-field>
                    <sui-form-field width="two">
                        <sui-button @click.prevent="partsInput.splice(index, 1)"><i style="color: red; opacity: 0.7;" class="fas fa-minus-circle"/></sui-button>
                    </sui-form-field>
                </sui-form-fields>
                <sui-form-fields>
                    <sui-form-field>
                        <sui-button @click.prevent="addPart"><i style="color: green; opacity: 0.7;" class="fas fa-plus-circle"/> Tilføj enhed</sui-button>
                    </sui-form-field>
                </sui-form-fields>
                <sui-form-field>
                    <label>Beskrivelse</label>
                    <textarea v-model="descriptionInput" placeholder="Eventuel beskrivelse af det arbejde der hører med i enheden"/>
                </sui-form-field>
            </sui-form>
        </sui-modal-content>
        <sui-modal-actions>
            <sui-button
                negative
                @click="closeModal"
            >
                Luk
            </sui-button>
            <sui-button
                positive
                type="submit"
                @click="saveChanges()"
            >
                Gem og luk
            </sui-button>
            <sui-button
                positive
                type="submit"
                @click="saveChanges(true)"
            >
                Gem og opret ny
            </sui-button>
        </sui-modal-actions>
    </sui-modal>
</template>
<script>
import EventBus from '../../EventBus'
import ProjectType from '../../lib/Enums/ProjectType'
import UnitWorkCategories from '../../lib/Enums/UnitWorkCategories'
import { Mixin } from '../../lib/Mixins/mixin'
import { DateMixin } from '../../lib/Mixins/dateMixin'
import { db } from '../../firebase'
import { DataAPI } from '../../lib/DataAPI'
import { unitWorkMixin } from '../../lib/unitWorkMixin'
import swal from 'sweetalert'

export default {
    name: 'EditUnitWorkCompositeUnitModal',
    mixins: [Mixin, DateMixin, DataAPI, unitWorkMixin],

    enums: {
        ProjectType,
        UnitWorkCategories,
    },

    props: {
        isOpen: {
            type: Boolean,
            requred: true,
            default: false,
        },
        user: {
            type: Object,
            required: false,
        },
        beforeChange: {
            type: Object,
            required: false,
        },
        baseData: {
            type: Object,
            requred: true,
        },
    },

    data() {
        return {
            formLoading: false,

            unitIdInput: null,
            unitNameInput: null,
            selectedCategories: [],
            unitInput: null,
            partsInput: [],
            descriptionInput: null,
        }
    },

    computed: {
        unitWorkUnitsDropdown() {
            let dropdownOptions = []
            for (let uwu of this.unitWorkUnits) {
                dropdownOptions.push({
                    text: `${uwu.id} - ${uwu.Name}`,
                    value: uwu.id
                })
            }
            return dropdownOptions
        }
    },

    methods: {
        closeModal() {
            EventBus.$emit('edit-unit-work-composite-unit-modal-close')
        },
        prePopulateForm() {
            console.log('pre-populating form')
            this.unitIdInput = this.beforeChange?.id 
            this.unitNameInput = this.beforeChange?.Name 
            this.unitInput = this.beforeChange?.Unit
            this.partsInput = this.beforeChange?.Parts
            this.selectedCategories = this.beforeChange?.Categories 
            this.descriptionInput = this.beforeChange?.Description
        },
        async saveChanges(startOver = false) {
            EventBus.$emit('function-activity', {functionName: 'EditUnitWorkCompositeUnitModal_saveChanges', isActive: true})
            this.formLoading = true
            console.log('Saving changes...')
            
            let unitObject = {
                Id: this.unitIdInput,
                Name: this.unitNameInput,
                Unit: this.unitInput,
                Parts: this.partsInput,
                Categories: this.selectedCategories ? this.selectedCategories : [],
                Description: this.descriptionInput || null,
                LastUpdated: new Date(),
            }

            try {
                db.collection('UnitWorkCompositeUnits').doc(unitObject.Id).set(unitObject)
                console.log(unitObject)
            } catch (error) {
                swal('Fejl i opdatering af enhed', error.message, 'error')
                console.error(`Error in updating unit: ${error.message}`)
            }

            this.prePopulateForm()
            this.formLoading = false
            EventBus.$emit('function-activity', {functionName: 'EditUnitWorkCompositeUnitModal_saveChanges', isActive: false})
            if(startOver){
                console.log('Restarting form...')   
            }
            else this.closeModal()
        },
        reCalculateEH(index, updateValue){
            console.log(`Should update based on ${updateValue}`)
            let hourPrice = 0
            if (this.baseData.EquivalentHourPrice) {
                hourPrice = this.baseData.EquivalentHourPrice
            } else {
                try {
                    throw new Error('Could not calculate price from hours, because hourly price was not available')
                } catch (error) {
                    console.error(error)
                    return 0
                }
            }
            var ehDecimal = 0
            var percentage = 0
            var price = 0
            var baseUnit = this.unitWorkUnits.find(unit => String(unit.id) == String(this.partsInput[index].BaseUnit))
            if (!baseUnit) throw new Error(`composite unit seems to contain non-existant base unit '${this.partsInput[index].BaseUnit}'`)
            switch (updateValue) {
                case 'hours': 
                    ehDecimal = this.hourStringToDecimal(this.partsInput[index].EquivalentHours)
                    percentage = Math.round( ( ehDecimal / baseUnit.EquivalentHours ) * 10000 ) / 100
                    // console.log(`${ehDecimal}/${baseUnit.EquivalentHours}=${(ehDecimal/baseUnit.EquivalentHours)} rounded to ${percentage}`)
                    this.$set(this.partsInput[index], 'Percentage', percentage)
                    price = Math.round( ehDecimal * hourPrice * 100 ) / 100
                    this.$set(this.partsInput[index], 'Price', price)
                    return 
                case 'price':
                    percentage = Math.round( this.partsInput[index].Price / baseUnit.UnitPrice * 10000 ) /100
                    this.$set(this.partsInput[index], 'Percentage', percentage)
                    ehDecimal = ( this.partsInput[index].Price / hourPrice )
                    this.$set(this.partsInput[index], 'EquivalentHours', this.decimalToHourString(ehDecimal, true))
                    this.$set(this.partsInput[index], 'EquivalentHoursNum', ehDecimal)
                    return
                case 'percentage': 
                default:
                    ehDecimal = (baseUnit.UnitPrice * (this.partsInput[index].Percentage / 100)) / hourPrice
                    this.$set(this.partsInput[index], 'EquivalentHours', this.decimalToHourString(ehDecimal, true))
                    this.$set(this.partsInput[index], 'EquivalentHoursNum', ehDecimal)
                    price = Math.round( baseUnit.UnitPrice * this.partsInput[index].Percentage ) / 100
                    this.$set(this.partsInput[index], 'Price', price)
                    return
            }
        },
        addPart(){
            this.$set(this.partsInput, this.partsInput.length, {
                BaseUnit: null,
                Percentage: 100,
                Price: null,
                EquivalentHours: null,
                EquivalentHoursNum: null,
            })
        }
    },

    beforeMount(){

    },

    watch: {
        deep: true,
        beforeChange: {
            immediate: false,
            handler() {
                this.prePopulateForm()
            }
        },
    },
}
</script>