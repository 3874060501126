import axios from 'axios'

export default class Dawa {

    BaseURL = 'https://dawa.aws.dk'

    AxiosConfig = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    nameCapitalization(string){
        let arr = string.split(' ') //In case of multi-word strings, split into words
        for (let i in arr) { //Loop through words in string
            arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1).toLowerCase() //Replace word with correctly capitalized word
        }
        return arr.join(' ') //Return joined string of words
    }

    async getGeoCoordinatesFromAddress(address) {

        address.road = this.nameCapitalization(address.road) //Ensure road is correctly capitalized, otherwise DAWA does not recognize it

        let response = await axios.get(`${this.BaseURL}/adresser?vejnavn=${address.road}&husnr=${address.number}&postnr=${address.zipcode}&struktur=mini`)
        
        if (!response.data[0] && address.letter != null) { //If the first response was not successful, try with the letter appended to the number
            response = await axios.get(`${this.BaseURL}/adresser?vejnavn=${address.road}&husnr=${address.number}${address.letter}&postnr=${address.zipcode}&struktur=mini`)
        }

        //TODO: Try washing the address if it was not found as is, before throwing the error and letting the google api take over
        
        if (!response.data[0] || !response.data[0].x || !response.data[0].y) { //Throw error if nothing was found, or the found value does not contain both x and y
            throw new Error(`Failed to get coordinates for ${JSON.stringify(address)}. Dawa response: ${JSON.stringify(response.data)}`)
        }

        return {
            lat: response.data[0].y,
            lng: response.data[0].x,
            id: response.data[0].id,
            provider: 'dawa'
        }
    }

    async listZipCodes() {

        let url = 'https://api.dataforsyningen.dk/postnumre' //All zipcodes
        let query = 'cirkel=10.294189453125002,55.16631894125083,57000' //Circle around Fyn, to limit response object
        let response = await axios.get(`${url}?${query}`)
        let data = response.data.filter((zipcode) => {
            return (zipcode.nr >= 5000 && zipcode.nr < 6000)
        })
        return data
        
    }

}