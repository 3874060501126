<template>
    <div class="container-fluid">
        <portal to="page-title">
            <div class="row" style="margin-top:-10px">
                <div class="col-sm-2" v-if="showSettingsInMobile">
                    <sui-button v-if="firebaseUser.Roles.includes(UserRoles.SUPER_ADMIN)"
                        style="font-size:14px"
                        @click="handleTemplateBtnClick()"
                    >
                        Skabeloner
                    </sui-button>
                    <sui-button 
                        style="font-size:14px"
                        class="labeled icon button"
                        icon="cog"
                        @click="onEditSettings()"
                    >
                        Indstillinger
                    </sui-button>
                </div>
                <div class="col-sm-10" v-if="!moveProjectLead" style="line-height: 70% !important; margin-top:10px; margin-bottom:-11px;">
                    <div>
                        {{ this.pageHeader }}<br/>
                        <small v-if="projectID"><small><small><small><em>
                            Projektleder: {{ projectLeads }}
                        </em></small></small></small></small>
                    </div>
                </div>
                <div class="col-sm-10" v-if="moveProjectLead" style="line-height: 95% !important; margin-top:10px; margin-bottom:-11px">
                    <span>
                        {{ this.pageHeader }}<br/>
                        <small v-if="projectID"><small><small><small><em>
                            Projektleder: {{ projectLeads }}
                        </em></small></small></small></small>
                    </span>
                </div>
            </div>
        </portal>

        <div class="row">
            <portal to="page-actions">
                <div style="zoom: 0.85">   
                    <sui-button v-if="!showSettingsInMobile"
                        floated="right"
                        class="labeled icon button"
                        icon="cog"
                        @click="onEditSettings()"
                    >
                        Indstillinger
                    </sui-button>
                    <sui-button v-if="!showSettingsInMobile && firebaseUser.Roles.includes(UserRoles.SUPER_ADMIN)"
                        floated="right"
                        @click="handleTemplateBtnClick()"
                    >
                        Skabeloner
                    </sui-button>
                </div>
            </portal>

            <!-- LEFT COLUMN -->
            <div class="col-sm-6">
                <!-- Scheduled appointments -->
                <DataCard 
                    :title="activeFormsHeader()" 
                    :subtitle="allFormsCount" 
                    success 
                    outline 
                    no-padding 
                    class="full-height" 
                    :actions="[{type: 'button', title: 'Opret ny PDF Formular', icon: 'plus', eventName: 'event-add-new-pdf-form-button'}]"
                >
                    <div class="scrollable">
                        <FormTimeline
                            :loading="false"
                            :disableLoadingAnimation="false"
                            :project="project"
                            :allForms="allFormsSorted"
                        />
                    </div>
                </DataCard>
            </div>

            <!-- RIGHT COLUMN -->
            <div class="col-sm-6">
                <!-- Non scheduled appointments -->
                <DataCard 
                    :title="inAssignHeader()" 
                    :subtitle="availableInstallationsLength" 
                    violet 
                    outline 
                    no-padding 
                    class="full-height"
                >
                    <!-- <div class="scrollable"> -->
                        <div class="text-center">
                            <h2 style="margin:20px">Der er på nuværende tidspunkt ikke lavet en integration til at hente opgaver</h2>
                        </div>

                    <!-- </div> -->
                </DataCard>
            </div>

            <!-- MODALS -->
            <EditPDFFormModal
                :isOpen="openEditFormModal"
                :project="project"
                :activeForm="activeForm"
                :mode="modalMode"
                :activeTemplate="activeTemplate"
            />

            <SelectTemplateActionModal
                :isOpen="isTemplateActionModalOpen"
                @close="closeSelectTemplateActionModal()"
                @template-action="handleTemplateAction"
            />


            <!-- <edit-settings-modal
                :isOpen="editSettings"
                :project="project"
                :settings="settingsBD"
                :hasProjectTasks="loadProjectTasks"
                :hasTroubleTickets="loadTickets"
                page="booking dashboard"
            /> -->

        </div>
    </div>

</template>
<script>

// Import mixins and libraries
import EventBus from '@/EventBus.js'
import swal from 'sweetalert'
import { db } from '@/firebase.js'
import { DataAPI } from '@/lib/DataAPI.js'
import { Mixin } from '@/lib/Mixins/mixin.js'
import { DateMixin } from '../../lib/Mixins/dateMixin'
import { JSON2HTML } from '../../lib/Mixins/FormToPdf/JSON2HTMLMixin.js'
import { FormToPdfEFBMixin } from '../../lib/Mixins/FormToPdf/FormToPdfEFBMixin.js'
import { Mime } from '@/lib/helpers/mime.js'

// Import components
import EditPDFFormModal from '../../components/FormToPdf/EditPDFFormModal.vue'
import FormTimeline from '../../components/FormToPdf/FormTimeline.vue'
import SelectTemplateActionModal from '../../components/FormToPdf/SelectTemplateActionModal.vue'
// import TimelineV2 from '@/components/Project/TimelineV2.vue'
// import EditSettingsModal from '@/components/Project/EditSettingsModal.vue'

// Import enums
import UserRoles from '../../lib/Enums/UserRoles'
import FormToPDFState from '../../lib/Enums/FormToPDFState'

export default {
    name: 'FormToPDFPage',
    mixins: [
        DataAPI,
        Mixin, 
        DateMixin,
        JSON2HTML,
        FormToPdfEFBMixin,
        Mime,
    ],

    components: {
        EditPDFFormModal,
        FormTimeline,
        SelectTemplateActionModal,
        // 'ProjectTimelineV2': TimelineV2,
        // EditSettingsModal,
    },

    enums: {
        UserRoles,
    },

    data() {
        return {

            editSettings: false,
            projectID: null,
            project: null,

            openEditFormModal: false,
            activeForm: null,

            allForms: [],

            isTemplateActionModalOpen: false,

            activeTemplate: null,
            modalMode: 'form',
           
        }
    },

    computed: {
        user() {
            return this.$root.$children[0].user
        },

        firebaseUser() {
            return this.$root.$children[0].firebaseUser
        },

        currentMedia(){
            return this.$root.$children[0].currentMedia
        },

        showSettingsInMobile(){
            return (
                this.currentMedia.type == 'Mobile' && this.currentMedia.orientation == 'Portrait'
            )
        },

        moveProjectLead(){
            if (this.currentMedia.type == 'Mobile' && this.currentMedia.orientation == 'Portrait') return true
            return false
        },

        projectLeads() {
            if (!this.project || !this.project.Contacts) return null
            let projectLeads = this.project.Contacts.filter((contact) => contact.Roles.includes('ProjectLead'))
            if (!projectLeads.length) return null
            let projectLeadNames = projectLeads.reduce((array, contact) => {
                array.push(contact.Name)
                return array
            }, [])
            return projectLeadNames.join(' & ')
        },

        pageHeader() {
            if (this.project && this.project.Name) return `${this.project.Name} - ${this.project?.Integrations?.formToPDF?.name}`
            return 'PDF Generator'
        },

        availableInstallationsLength() {
            let counter = 0
            return String(counter)
        },

        allFormsCount() {
            let counter = 0
            return String(counter)
        },

        allFormsSorted() {
            let uniqueGroups = []

            for (let form of this.allForms){
                let groupKey = FormToPDFState.getStateFromBaseState(form.state)
                if (!uniqueGroups.includes(groupKey)){
                    uniqueGroups.push(groupKey)
                }
            }

            uniqueGroups = uniqueGroups.reduce((v,i) => (v[i] = [], v), {})
            for (let form of this.allForms){
                let groupKey = FormToPDFState.getStateFromBaseState(form.state)
                uniqueGroups[groupKey].push(form)
            }

            console.log("Unique groups:",uniqueGroups)

            return uniqueGroups
        }


    },

    methods: {
        inAssignHeader(){
            let returnStr = `<div class='headerWrapper'><span>`
            returnStr += "Indkomne opgaver"
            returnStr += `</span></div>`
            return returnStr
        },

        activeFormsHeader() {
            let returnStr = `<div class='headerWrapper'><span>`
            
            if (this.project && this.project?.Integrations?.formToPDF && this.project?.Integrations?.formToPDF?.name) {
                returnStr += 'Aktive '
                returnStr += this.project.Integrations.formToPDF.name
            } else {
                returnStr += "Aktive formularer"
            }
            returnStr += `</span></div>`
            return returnStr
        },

        onEditSettings(){
            this.editSettings = true
            console.log("Opening settings modal")
        },

        getAllForms() {
            let projectID = this.projectID
            if (!projectID) projectID = this.GLOBAL_PROJECT_ID

            this.$bind('allForms', db.collection('FormToPdfForms')
                .where('linkedProjects', 'array-contains', projectID).where('state', '!=', FormToPDFState.getBaseStateFromCode(FormToPDFState.CANCELLED))
            )
        },

        handleTemplateBtnClick() {
            this.isTemplateActionModalOpen = true
            console.log("Template button clicked")
        },

        handleTemplateAction(action){
            if (action === null) {
                this.activeTemplate = null
                console.log("Create new template")
            } else {
                this.activeTemplate = action
                console.log("Edit template", action)
            }
            this.modalMode = 'template'
            this.openEditFormModal = true
        },

        closeSelectTemplateActionModal() {
            this.isTemplateActionModalOpen = false
        },
    },

    watch: {
        $route: {
            immediate: true,
            handler(newVal) {
                if (newVal.query.project_id) {
                    this.projectID = newVal.query.project_id
                } else {
                    this.projectID = null
                    this.project = null
                }
            }
        },

        projectID: {
            immediate: true,
            async handler(newVal) {
                if (newVal) {
                    
                    await this.$bind('project', db.collection('Projects').doc(newVal))

                    // Enable left menu navigation by setting the active project
                    if (this.project){
                        this.$store.dispatch('setActiveProject', this.project)
                        this.$store.dispatch('setActiveProjectId', this.project.id)
    
                        if (!(this.project?.Integrations?.formToPDF && this.project?.Integrations?.formToPDF?.enabled)) {
                            await this.$unbind('project')
                            return
                        }
    
                        document.title = `${this.project.Name} ${this.project?.Integrations?.formToPDF?.name} - FiberTeam`
                    } else {
                        document.title = `PDF Generator - FiberTeam`
                        swal('Projektet kunne ikke findes i databasen', '', 'error')
                    }
                } else {
                    document.title = `PDF Generator - FiberTeam`
                }

                this.getAllForms()
                console.log("All forms:",this.allForms)
            }
        },

        // Set the background color of the project
        project: {
            immediate: true,
            async handler() {
                if (this.project && this.project?.BackgroundColor){
                    EventBus.$emit('update-project-background-color',this.project.BackgroundColor)
                } else {
                    EventBus.$emit('update-project-background-color','')
                }

                if (this.project && this.$route.query.project_id) {
                    if (!this.project?.Integrations?.formToPDF && !this.project?.Integrations?.formToPDF?.enabled){
                        await swal('Der er ikke nogen PDF Generator integration til dette projekt', '', 'info')
                    }
                }
            }
        },


    },

    beforeDestroy() {
        // EventBus.$off('booking-form-save', this.onSaveBooking)
    },

    beforeMount() {
        // this.settingsBD.setValue('bookedSelectedOptions',this.bookedSortingOptions)
        // this.settingsBD.setOptions('sortUnbookedPTBy',this.unbookedSortingOptProjTasks)
        // this.settingsBD.setOptions('sortUnbookedTTBy',this.unbookedSortingOptTroubleTickets)
        // this.settingsBD.setOptions('sortInstsBy',this.instListSortingOptions)
        EventBus.$on('edit-settings-modal-closing', () => {this.editSettings = false})
        EventBus.$on('edit-settings-modal-open', () => {this.editSettings = true})

        EventBus.$on('event-add-new-pdf-form-button-click', () => {
            this.activeForm = null
            this.modalMode = 'form'
            this.openEditFormModal = true
            console.log("Add new PDF form button clicked")
        })
        EventBus.$on('event-edit-pdf-form-modal-closing', () => {
            this.openEditFormModal = false
            this.activeForm = null
        })

        EventBus.$on('form-timeline-item-clicked', (payload) => {
            this.activeForm = payload
            this.openEditFormModal = true
        })

        // EventBus.$on('edit-settings-loaded', (payload) => {
        //     console.log("settings received:",JSON.stringify(payload))
        //     this.settingsParams = payload

        //     for (let i in this.bookedSortingOptions)
        //     {
        //         let index = this.settingsBD.get("bookedSelectedOptions").findIndex(p => {
        //              return p.value == this.bookedSortingOptions[i].value})
        //         this.bookedSortingOptions[i].rank = this.settingsBD.get("bookedSelectedOptions")[index].rank
        //     }

        //     if (this.settingsBD.get("sortUnbookedPTBy") == "productDeliveryDate") this.getAllProducts()
        // })

        // EventBus.$on('edit-settings-updated', (payload) => {

        //     // console.log("The following settings have been updated")
        //     // console.log(JSON.stringify(payload))

        //     for (let i in payload){
        //         // console.log(payload[i])
        //         this.onSettingUpdate(payload[i])
        //     }
        // })

    },

    async mounted() {
        EventBus.$emit('function-activity', {functionName: 'FormToPDF_mounted', isActive: true})

        document.body.classList.add('sidebar-collapse')

        EventBus.$emit('function-activity', {functionName: 'FormToPDF_mounted', isActive: false})
    },

    destroyed() {
        document.body.classList.remove('sidebar-collapse')
    }
}
</script>


<style>

    .scrollable {
        overflow-y: auto;
        overflow-x: auto;
        height: 100%;
        position: relative;
    }

    .full-height {
        height: calc(100svh - 205px);
    }

    .half-height {
        height: calc(50svh - 110px);
    }

    .centered{
        text-align: center;
    }

    .hover-pointer{
        cursor: pointer;
    }

    .headerWrapper{
        margin-bottom: -10px !important;
        display:table;
    }

</style>