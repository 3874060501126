<template>
    <div>    
        <splice-reports-download-modal
            :activeItem="activeItem"
            :isOpen="downloadModalOpen"
        />
        <sui-table striped selectable>
            <!-- <sui-table-header>
                <sui-table-row>
                    <sui-table-header-cell></sui-table-header-cell>
                </sui-table-row>
            </sui-table-header> -->
            <sui-table-body>
                <sui-table-row v-for="hub in hubs" :key="hub.identification" @click="reportItemClicked(hub)">
                    <sui-table-cell>{{hub.typeSource}} {{hub.identification.replaceAll(' ','').substr(0, hub.identification.replaceAll(' ','').indexOf('-'))}}</sui-table-cell>
                    <sui-table-cell>{{hub.status}}</sui-table-cell>
                </sui-table-row>
                <sui-table-row v-for="cabinet in uubs" :key="cabinet.identification" @click="reportItemClicked(cabinet)">
                    <sui-table-cell>{{cabinet.typeSource}} {{cabinet.identification}}</sui-table-cell>
                    <sui-table-cell>{{cabinet.status}}</sui-table-cell>
                </sui-table-row>
                <sui-table-row v-if="!hubs.length && !uubs.length">
                    <sui-table-cell colspan="2">
                        <em>{{id ? `Der blev ikke fundet splidseplaner på ${id}` : 'Intet acceshus- eller skabsnummer'}}</em>
                    </sui-table-cell>
                </sui-table-row>
            </sui-table-body>
        </sui-table>
    </div>
</template>
<script>
import { Mixin } from '../../lib/Mixins/mixin'
import { DataAPI } from '../../lib/DataAPI'
import SpliceReportsDownloadModal from './SpliceReportsDownloadModal.vue'
import EventBus from '../../EventBus'

export default {
    name: 'SpliceReportsTable',

    mixins: [Mixin, DataAPI],

    components: { SpliceReportsDownloadModal },

    props: {
        id: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            hubs: [],
            uubs: [],
            activeItem: null,
            downloadModalOpen: false,
        }
    },

    methods: {
        async getHubs(){
            EventBus.$emit('function-activity', {functionName: 'SpliceReportsTable_getHubs', isActive: true})
            let hubs = await this.dataGetHubs(this.id)
            this.hubs = hubs
            EventBus.$emit('function-activity', {functionName: 'SpliceReportsTable_getHubs', isActive: false})
        },
        async getUubs(){
            EventBus.$emit('function-activity', {functionName: 'SpliceReportsTable_getUubs', isActive: true})
            let uubs = await this.dataGetUubs(this.id)
            EventBus.$emit('function-activity', {functionName: 'SpliceReportsTable_getUubs', isActive: false})
            if (!uubs) return
            this.uubs = uubs.sort((a, b) => {
                return (a.identification > b.identification) ? 1 : -1
            })
        },
        reportItemClicked(item){
            // console.log('click')
            this.activeItem = item
            this.downloadModalOpen = true
        },
    },

    watch: {
        id: {
            immediate: true,
            handler(id){
                if (typeof(id) != 'undefined' && id != 'undefined') {
                    console.log("Hub id",id)
                    this.getHubs()
                    this.getUubs()
                }
            }
        }
    },

    beforeMount(){
        EventBus.$on('report-download-modal-close', () => {this.downloadModalOpen = false})
    },

}
</script>
<style scoped>
    table.selectable tr:hover td {
        cursor: pointer;
    }
</style>