
class FormToPDFState {

    static REJECTED = '01_rejected'
    static EXPIRED = '02_expired'
    static REOPENED = '03_reopened'
    static BOOKED = '04_booked'
    static REQUEST_IMAGES = '05_requestImages'
    static ON_HOLD = '06_onHold'
    static DRAFT = '07_draft'
    static READY = '08_ready'
    static AWAITING_APPROVAL = '09_awaitingApproval'
    static SIGNED = '10_signed'
    static DISMISSED = '11_dismissed'
    static CLOSED = '12_closed'
    static CANCELLED = '13_cancelled'

    static AllArray = [ //Array of all the types from above
        this.REJECTED, 
        this.EXPIRED, 
        this.REOPENED, 
        this.BOOKED, 
        this.REQUEST_IMAGES, 
        this.ON_HOLD, 
        this.DRAFT, 
        this.READY, 
        this.AWAITING_APPROVAL, 
        this.SIGNED, 
        this.DISMISSED, 
        this.CLOSED,
        this.CANCELLED
    ]

    static getDisplayNameFromCode(code){
        switch(code) {
            case this.REJECTED: return "Afvist"
            case this.EXPIRED: return "Udløbet"
            case this.REOPENED: return "Genåbnet"
            case this.BOOKED: return "Booket"
            case this.REQUEST_IMAGES: return "Afvender anmodning om billeder"
            case this.ON_HOLD: return "På hold"
            case this.DRAFT: return "Udkast"
            case this.READY: return "Klar"
            case this.AWAITING_APPROVAL: return "Afventer underskrift"
            case this.SIGNED: return "Underskrevet"
            case this.DISMISSED: return "Afsluttet"
            case this.CLOSED: return "Lukket"
            case this.CANCELLED: return "Slettet"
        }
    }

    static getBaseStateFromCode(code){
        switch(code) {
            case 'rejected': return 'rejected'
            case 'expired': return 'expired'
            case 'reopened': return 'reopened'
            case 'booked': return 'booked'
            case 'requestImages': return 'requestImages'
            case 'onHold': return 'onHold'
            case 'draft': return 'draft'
            case 'ready': return 'ready'
            case 'awaitingApproval': return 'awaitingApproval'
            case 'signed': return 'signed'
            case 'dismissed': return 'dismissed'
            case 'closed': return 'closed'
            case 'cancelled': return 'cancelled'
        }

        return code.split('_')[1]
    }

    static getStateFromBaseState(baseState){
        switch(baseState) {
            case 'rejected': return this.REJECTED
            case 'expired': return this.EXPIRED
            case 'reopened': return this.REOPENED
            case 'booked': return this.BOOKED
            case 'requestImages': return this.REQUEST_IMAGES
            case 'onHold': return this.ON_HOLD
            case 'draft': return this.DRAFT
            case 'ready': return this.READY
            case 'awaitingApproval': return this.AWAITING_APPROVAL
            case 'signed': return this.SIGNED
            case 'dismissed': return this.DISMISSED
            case 'closed': return this.CLOSED
            case 'cancelled': return this.CANCELLED

            case this.REJECTED: return this.RREJECTED
            case this.EXPIRED: return this.EXPIRED
            case this.REOPENED: return this.REOPENED
            case this.BOOKED: return this.BOOKED
            case this.REQUEST_IMAGES: return this.REQUEST_IMAGES
            case this.ON_HOLD: return this.ON_HOLD
            case this.DRAFT: return this.DRAFT
            case this.READY: return this.READY
            case this.AWAITING_APPROVAL: return this.AWAITING_APPROVAL
            case this.SIGNED: return this.SIGNED
            case this.DISMISSED: return this.DISMISSED
            case this.CLOSED: return this.CLOSED
            case this.CANCELLED: return this.CANCELLED
        }
    }

    static DropdownOptions = [ //Array of objects, with 'value' and 'text' properties, for dropdown options etc
        { value: this.REJECTED, text: this.getDisplayNameFromCode(this.REJECTED) },
        { value: this.EXPIRED, text: this.getDisplayNameFromCode(this.EXPIRED) },
        { value: this.REOPENED, text: this.getDisplayNameFromCode(this.REOPENED) },
        { value: this.BOOKED, text: this.getDisplayNameFromCode(this.BOOKED) },
        { value: this.REQUEST_IMAGES, text: this.getDisplayNameFromCode(this.REQUEST_IMAGES) },
        { value: this.ON_HOLD, text: this.getDisplayNameFromCode(this.ON_HOLD) },
        { value: this.DRAFT, text: this.getDisplayNameFromCode(this.DRAFT) },
        { value: this.READY, text: this.getDisplayNameFromCode(this.READY) },
        { value: this.AWAITING_APPROVAL, text: this.getDisplayNameFromCode(this.AWAITING_APPROVAL) },
        { value: this.SIGNED, text: this.getDisplayNameFromCode(this.SIGNED) },
        { value: this.DISMISSED, text: this.getDisplayNameFromCode(this.DISMISSED) },
        { value: this.CLOSED, text: this.getDisplayNameFromCode(this.CLOSED) }
    ]

}

export default FormToPDFState

