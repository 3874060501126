class TicketState {

    static NEW = '1'
    static WORK_IN_PROGRESS = '2'
    static ON_HOLD = '3'
    static RESOLVED = '6'
    static CLOSED = '7'
    static CANCELED = '8'

    static getValueFromCode(code) {
        switch(code) {
            case this.NEW:
                return 'New'
            
            case this.WORK_IN_PROGRESS:
                return 'Work in Progress'
            
            case this.ON_HOLD:
                return 'On Hold'

            case this.RESOLVED: 
                return 'Resolved'
            
            case this.CLOSED:
                return 'Closed'

            case this.CANCELED: 
                return 'Canceled'
            
            default:
                return 'Unknown State'
        }
    }

    static DROPDOWN_OPTIONS = [
        {value: this.NEW, text: this.getValueFromCode(this.NEW)},
        {value: this.WORK_IN_PROGRESS, text: this.getValueFromCode(this.WORK_IN_PROGRESS)},
        {value: this.ON_HOLD, text: this.getValueFromCode(this.ON_HOLD)},
        {value: this.RESOLVED, text: this.getValueFromCode(this.RESOLVED)},
        {value: this.CLOSED, text: this.getValueFromCode(this.CLOSED)},
        {value: this.CANCELED, text: this.getValueFromCode(this.CANCELED)},
    ]

    static openStatesArray = [
        this.NEW,
        this.WORK_IN_PROGRESS
    ]

    static closedStatesArray = [
        this.RESOLVED,
        this.CLOSED,
        this.CANCELED,
    ]
}

export default TicketState
