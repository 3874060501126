import { DataAPI } from "../DataAPI"
import SingletonRunner from "../core/singletonRunner.js"

const runner = new SingletonRunner();

export const ZipCodeMixin = {

    mixins: [
        DataAPI, // Import the DataAPI mixin
    ],

    data() {
        return {
            zipCodes: [],
        }
    },

    async mounted() {
        this.zipCodes = await runner.runOnce(this.dataGetZipCodes)
    },

    methods: {
        
        zipCodeName(code) {
            if (!this.zipCodes) return null
            let zipCode = this.zipCodes.find((zip) => zip.nr == code) //Find full zipcode obj
            if (!zipCode) return null //If not found, return null (fail silently)
            return zipCode.navn //Return name of zipcode
        },
    },
}
