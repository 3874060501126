export class LPCType {
    static PROJECT = 0
    static APPOINTMENT = 1
    static INTERNALSUBTASK = 2
    static COORDINATE = 3
}

export const LPCTerminology = {
    [LPCType.PROJECT]: {
        collectionPath: 'Projects',
    },
    [LPCType.APPOINTMENT]: {
        collectionPath: 'Appointments',
    },
    [LPCType.INTERNALSUBTASK]: {
        collectionPath: 'InternalSubTasks',
    },
    [LPCType.COORDINATE]: {
        collectionPath: 'Coordinates',
    },
}