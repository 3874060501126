<template v-if="apiResponse">
    <sui-table-body>
        <template v-for="troubleTicket of apiResponse">
            <sui-table-row :key="`${troubleTicket.id}-number`">
                <sui-table-headerCell>
                    Trouble Ticket
                </sui-table-headerCell>
                <sui-table-headerCell>
                    <span v-if="apiResponse">{{ troubleTicket.number }}</span>
                    <sui-button size="mini" @click.prevent="openTroubleTicketModal(troubleTicket)" style="float: right" icon="info"></sui-button>
                </sui-table-headerCell>
            </sui-table-row>
            <sui-table-row :key="`${troubleTicket.id}-inst#`">
                <sui-table-cell>InstallationsNr</sui-table-cell>
                <sui-table-cell>{{ troubleTicket.installation.number }}</sui-table-cell>
            </sui-table-row>
            <sui-table-row :key="`${troubleTicket.id}-createdAt`">
                <sui-table-cell>Oprettet</sui-table-cell>
                <sui-table-cell>{{ toUserFriendlyTimestamp(troubleTicket.createdAt) }}</sui-table-cell>
            </sui-table-row>
            <sui-table-row :key="`${troubleTicket.id}-dueDate`">
                <sui-table-cell>Frist</sui-table-cell>
                <sui-table-cell>{{ toUserFriendlyTimestamp(troubleTicket.dueDate) }}</sui-table-cell>
            </sui-table-row>
            <sui-table-row :key="`${troubleTicket.id}-resolvedAt`">
                <sui-table-cell>Løst</sui-table-cell>
                <sui-table-cell>{{ toUserFriendlyTimestamp(troubleTicket.resolvedAt) }}</sui-table-cell>
            </sui-table-row>
            <sui-table-row :key="`${troubleTicket.id}-assignee`">
                <sui-table-cell>Medarbejder</sui-table-cell>
                <sui-table-cell>{{ troubleTicket.assignee }}</sui-table-cell>
            </sui-table-row>
            <sui-table-row :key="`${troubleTicket.id}-shortDescription`">
                <sui-table-cell>Kort beskrivelse</sui-table-cell>
                <sui-table-cell>{{ troubleTicket.shortDescription }}</sui-table-cell>
            </sui-table-row>
            <sui-table-row :key="`${troubleTicket.id}-description`">
                <sui-table-cell>Beskrivelse</sui-table-cell>
                <sui-table-cell>{{ troubleTicket.description }}</sui-table-cell>
            </sui-table-row>
            <sui-table-row :key="`${troubleTicket.id}-priority`">
                <sui-table-cell>Prioritet</sui-table-cell>
                <sui-table-cell v-if="troubleTicket.priority">{{ troubleTicket.priority.label }}</sui-table-cell>
                <sui-table-cell v-if="!troubleTicket.priority"></sui-table-cell>
            </sui-table-row>
            <sui-table-row :key="`${troubleTicket.id}-state`">
                <sui-table-cell>Status</sui-table-cell>
                <sui-table-cell v-if="troubleTicket.state">{{ troubleTicket.state.label }}</sui-table-cell>
                <sui-table-cell v-if="!troubleTicket.state"></sui-table-cell>
            </sui-table-row>
        </template>
        <sui-table-row>
            <sui-table-headerCell v-if="apiResponse && !apiResponse.length">
                Der er ingen driftsager fundet
            </sui-table-headerCell>
        </sui-table-row>
    </sui-table-body>
</template>

<script>
import { Mixin } from '../../lib/Mixins/mixin'
import { DateMixin } from '../../lib/Mixins/dateMixin'
import { DataAPI } from '../../lib/DataAPI'
import EventBus from '../../EventBus'

export default {
    mixins: [Mixin, DateMixin, DataAPI],

    props: {
        instLabel: String,
    },

    data(){
        return {
            apiResponse: null,
        }
    },

    methods: {
        openTroubleTicketModal(troubleTicket){
            EventBus.$emit('open-trouble-ticket-modal', troubleTicket)
        },

        async getNexelTroubleTickets() {
            EventBus.$emit('function-activity', {functionName: 'NexelTroubleTickets_getNexelTroubleTickets', isActive: true})
            this.apiResponse = null
            EventBus.$emit('retrieved-nexel-trouble-tickets', {data: 'Henter trouble tickets...', loading: true})
            if (!this.instLabel) {
                EventBus.$emit('retrieved-nexel-trouble-tickets', {data: 'Intet installations-nr', loading: false})
                EventBus.$emit('function-activity', {functionName: 'NexelTroubleTickets_getNexelTroubleTickets', isActive: false})
                return null
            }
            try {
                this.apiResponse = await this.dataGetTroubleTicketsByInst(this.instLabel)
                if (this.apiResponse && this.apiResponse.length) {
                    this.apiResponse = this.apiResponse.sort((a, b) => (b.number > a.number) ? 1 : -1)
                    EventBus.$emit('retrieved-nexel-trouble-tickets', {data: this.apiResponse[0].installation.number, loading: false})
                    EventBus.$emit('retrieved-nexel-trouble-tickets-title', `Driftsager (${this.apiResponse.length})`)
                }
                else {
                    EventBus.$emit('retrieved-nexel-trouble-tickets', {data: this.instLabel, loading: false})
                    EventBus.$emit('retrieved-nexel-trouble-tickets-title', 'Driftsager')
                }
            } catch (error) {
                EventBus.$emit('retrieved-nexel-trouble-tickets', {data: 'Kunne ikke hente data!', loading: false})
                console.error(error)
            }
            EventBus.$emit('function-activity', {functionName: 'NexelTroubleTickets_getNexelTroubleTickets', isActive: false})
            return this.apiResponse
        },
    },

    mounted() {
        EventBus.$on('reload-nexel-trouble-tickets-click', () => {this.getNexelTroubleTickets()})
    },

    beforeDestroy() {
        EventBus.$off('reload-nexel-trouble-tickets-click')
    },

    watch: {
        instLabel: {
            immediate: true,
            handler() {
                this.getNexelTroubleTickets()
            }
        }
    }
}
</script>