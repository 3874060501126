<template>
    <div :class="this.classes(
            'card',
            this.default && 'card-default',
            this.outline && 'card-outline',
            this.primary && 'card-primary',
            this.secondary && 'card-secondary',
            this.info && 'card-info',
            this.success && 'card-success',
            this.warning && 'card-warning',
            this.danger && 'card-danger',
            this.violet && 'card-violet'
    )">
        <div v-if="this.title || this.actions" class="card-header d-flex">
            <h3 class="card-title" v-html="this.title"></h3>

            <div v-if="this.actions || this.subtitle" class="ml-auto">
                <h5 v-if="this.subtitle" class="text-muted subtitle" v-html="this.subtitle"></h5>
                <div
                    v-if="this.actions"
                    :class="classes(
                        'card-action-button-wrapper',
                        this.isActionGroup && 'ui tiny buttons'
                    )"
                >
                    
                    <template
                        v-for="(action, index) in this.actions"
                    >
                        <div
                            v-if="action.type != 'dropdown'"
                            :key="index"
                            :class="classes(
                                'ui',
                                'tiny',
                                (action.icon && action.iconRight) && 'right',
                                (action.icon && action.label) && 'labeled icon',
                                (action.icon && !action.label) && 'icon',
                                (action.basic && action.basic) && 'basic',
                                action.type == 'button' && 'button',
                            )"
                            @click="triggerEvent('click', action)"
                            :title="action.title"
                        >
                            <i v-if="action.icon" :class="classes('icon', action.icon, (action.spinning && 'fa-spin'))"></i>
                            <span class="text">{{ action.label }}</span>
                        </div>
                        <sui-dropdown
                            v-if="action.type == 'dropdown' && action.options.length > 1"
                            :key="index"
                            :class="classes(
                                'tiny',
                                action.iconRight && 'right',
                                action.label && 'labeled icon',
                                action.icon && 'icon',
                            )"
                            :icon="action.icon"
                            :options="action.options"
                            :text="action.label"
                            :search="action.search || false"
                            :value="action.bind ? dropdownCurrentState[index] : null"
                            v-on:input="selectDropdownItem(action, index, ...arguments)"
                            button
                            pointing="top right"
                        />
                        <sui-button
                            v-if="action.type == 'dropdown' && action.options.length == 1"
                            :key="index"
                            :class="classes(
                                'ui',
                                'tiny',
                                (action.icon && action.iconRight) && 'right',
                                (action.icon && action.label) && 'labeled icon',
                                (action.icon && !action.label) && 'icon',
                                (action.basic && action.basic) && 'basic',
                                'button',
                            )"
                            :title="action.title"
                            @click="selectDropdownItem(action, index, action.options[0].value)"
                        >
                            <i v-if="action.icon" :class="classes('icon', action.icon)"></i>
                            <span class="text">{{ action.label }}</span>
                        </sui-button>
                    </template>

                </div>
            </div>
        </div>
        <div :class="classes(
            'card-body',
            this.noPadding && 'p-0',
            this.overflowX && 'overflow-x'
        )">
            <slot></slot>
        </div>
    </div>
</template>
<script>
import { Mixin } from '../../lib/Mixins/mixin'
import EventBus from '../../EventBus'

export default {
    name: 'DataCard',
    mixins: [Mixin],

    props: {
        outline: {
            type: Boolean
        },
        primary: {
            type: Boolean
        },
        secondary: {
            type: Boolean
        },
        info: {
            type: Boolean
        },
        success: {
            type: Boolean
        },
        warning: {
            type: Boolean
        },
        danger: {
            type: Boolean
        },
        violet: {
            type: Boolean
        },
        noPadding: {
            type: Boolean
        },
        overflowX: {
            type: Boolean
        },

        title: {
            type: String
        },
        subtitle: {
            type: String
        },

        actions: {
            type: Array,
            default: null
            /*
                Must be an array containing 1 or more objects.
                Action props:
                type: Enum ['button', 'dropdown'] (Required)
                icon: String (Uses icons bundled from SemanticUIVue)
                iconRight: Boolean
                basic: Boolean
                label: String
                title: Native hover title
                eventName: String (Note this will be prepended from the event type)
                search: Boolean (Enables a dropdown to be searchable)
                bind: Boolean (Toggles v-bind like behaviour for dropdown selected item)
                options: Array (Exactly the same format as SematicUIVue)
            */
        }
    },

    data() {
        return {
            dropdownCurrentState: {}
        }
    },

    computed: {
        default() {
            return (
                !this.primary
                && !this.secondary
                && !this.info
                && !this.success
                && !this.warning
                && !this.danger
            )
        },

        isActionGroup() {
            return Object.keys(this.actions).length > 1
        }
    },

    created() {
        if (this.actions == null) return
        this.actions.forEach((action, index) => {
            if (action.type == 'dropdown') {
                this.dropdownCurrentState[index] = null
            }
        })
    },

    methods: {

        selectDropdownItem(action, index, payload) {
            if (action.bind) {
                this.dropdownCurrentState[index] = payload
                this.$forceUpdate()
            }
            this.triggerEvent('click', action, payload)
        },

        triggerEvent(eventType, action, payload) {
            if (!action.eventName) return
            EventBus.$emit(`${action.eventName}-${eventType}`, payload)
        }
    }


}
</script>
<style scoped>
.card-action-button-wrapper {
    margin-top: -10px;
    margin-bottom: -10px;
    margin-right: -12px;
    display: inline-flex;
}

.subtitle {
    display: inline;
    font-weight: normal;
}

.subtitle + .card-action-button-wrapper {
    margin-left: 10px;
}

.card-violet {
    border-top: 3px solid #c182c1;
}

</style>
