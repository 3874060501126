<template>
    
    <div class="row">
        <portal to="page-actions">
            <div style="zoom: 0.85">
                <sui-button v-if="showSettings"
                    class="labeled icon button"
                    icon="cog"
                    @click="onEditSettingsButtonClicked()"
                >
                    Indstillinger
                </sui-button>

                <template v-if="showClosed">
                    <label>Afsluttede siden: </label>
                    <sui-input type="date" v-model="closedSinceInput" />
                    <sui-button icon="download" color="green" :disabled="closedSince == closedSinceInput" :loading="getClosedLoading" @click="closedSince = closedSinceInput"/>
                </template>
            </div>
        </portal>
        <sui-modal small v-model="noteModalOpen">
            <sui-modal-header>
                Ny Note
            </sui-modal-header>
            <sui-modal-content>
                <sui-form :success="noteFormSuccess" @submit.prevent="submitNote" :loading="noteFormLoading">
                    <sui-form-fields inline>
                        <label>Intern eller ekstern note?</label>
                        <sui-form-field>
                            <sui-checkbox label="Intern" radio value="Intern" v-model="noteType"/>
                        </sui-form-field>
                        <sui-form-field>
                            <sui-checkbox label="Ekstern" radio value="Ekstern" v-model="noteType"/>
                        </sui-form-field>
                    </sui-form-fields>
                    <sui-message> Eksterne noter skrives på sagen i PilotBI, Interne noter kan kun ses gennem ftth.fiberlan.dk <br> Der skrives automatisk navn på alle noter <br> Noter der er oprettet kan ikke slettes </sui-message>
                    <sui-form-field>
                        <label>Note tekst</label>
                        <textarea rows="5" v-model="noteText"></textarea>
                    </sui-form-field>
                    <sui-button type="submit" :disabled="!noteType">Gem</sui-button>
                </sui-form>
            </sui-modal-content>
        </sui-modal>
        <sui-modal small v-model="uploadFileModalOpen">
            <sui-modal-header>
                Vedhæft fil
            </sui-modal-header>
            <sui-modal-content>
                <sui-form :success="fileFormSuccess" @submit.prevent="submitFile" :loading="fileFormLoading">
                    <sui-form-field>
                        <label>Navngiv den uploadede fil</label>
                        <input type="text" placeholder="FilNavn" v-model="fileNameInput"/>
                    </sui-form-field>
                    <sui-form-field>
                        <input type="file" @change="setFileUpload"/>
                    </sui-form-field>
                    <sui-message> Uploadede filer sendes til PilotBI </sui-message>
                    <sui-button type="submit" :disabled="!fileUpload">Gem</sui-button>
                    <sui-message success>Fil sendt til PilotBI</sui-message>
                </sui-form>
            </sui-modal-content>
        </sui-modal>
        <file-viewer-modal
            :openFile="openFile"
            :isOpen="openFileModalOpen"
            :openFileLoading="openFileLoading"
        />
        <state-change-modal :isOpen="stateModalOpen" :task="this.activeTask" :projectProp="this.project" :installationLabel="this.activeInstallationLabel" />
        <sms-modal title="SMS" :isOpen="smsModalOpen" :phoneNumber="activePhoneNumber" />
        <splice-reports-download-modal :activeItem="downloadReportActiveItem" :isOpen="downloadReportModalOpen"/>
        <edit-unit-work-modal 
            v-if="activeInstallationData" 
            :isOpen="editUnitWorkModalOpen" 
            :task="activeInstallationData.tasks[0]"
            :availableWorkers="availableWorkers"
            :user="user"
            :userHasLeadPermissions="userHasLeadPermissions"
            :beforeChange="activeUnitWork"
            :unitWorkId="activeUnitWork.id"
            :preFilledDescription="preFilledUnitWorkDescription"
        />

        <div :class="settingsBD.get('showMap') ? 'col-sm-3' : 'col-sm-6'">
            <DataCard title="Skabe" :subtitle="availableInstallationsLength" violet outline no-padding class="full-height">
                <div class="scrollable">
                    <ProjectTimeline
                        :itemClickHandlerType="BookingItemHandlerType.SEND_EVENT"
                        :bookings="this.availableInstallations"
                        :loading="this.loadingUnBookedTimeline"
                        :showBookingData="false"
                        :showSubtaskStatus="false"
                        :showCounters="this.settingsBD.get('showCounters')"
                        :settings="this.settingsBD"
                    />
                </div>
            </DataCard>
        </div>
        <div :class="settingsBD.get('showMap') ? 'col-sm-3' : 'col-sm-6'">
            <div ref="activeInstallationCard">
                <DataCard
                    no-padding
                    primary
                    outline
                    :title="this.activeInstallationData ? `<i class='${this.activeInstallationBoxIcon}' title='${this.activeInstallationBoxTitle}'></i> ${this.activeInstallationAddress}` : ''"
                    :actions="[
                        { type: 'button', title: 'Installations detaljer', icon: 'info', eventName: 'details-button' }
                    ]"
                    class="full-height active-installation-box"
                >
                    <div class="scrollable">
                        <div v-if="this.loadingInstallationData">
                            <TableLoader />
                        </div>
                        <sui-table striped compact v-if="this.activeInstallationData && !this.loadingInstallationData" size="small">
                            <sui-table-row>
                                <sui-table-cell colspan="2">
                                    <strong>Info</strong>
                                </sui-table-cell>
                            </sui-table-row>
                            <sui-table-row>
                                <sui-table-cell>Adresse</sui-table-cell>
                                <sui-table-cell>
                                    <span v-if="this.activeInstallationData.tasks[0].configurationItem.address">
                                        <i
                                            class="fas fa-map-marker-alt text-primary"
                                            :title="`${formatAddress(this.activeInstallationData.tasks[0].configurationItem.address, false)}\nKlik for at navigere i Google Maps`"
                                            @click.stop="linkToGoogleMapsDirections(activeInstallationData.coordinates, activeInstallationData.tasks[0].configurationItem.address)"
                                            style="cursor: pointer;"
                                        ></i>
                                        <!-- <a :href="this.LinktoGoogleMapsDirections(this.activeInstallationData.coordinates, this.activeInstallationData.tasks[0].configurationItem.address)" target="_blank">
                                        </a> -->
                                        {{ this.formatAddress(this.activeInstallationData.tasks[0].configurationItem.address, false) }}
                                    </span>
                                </sui-table-cell>
                            </sui-table-row>
                            <sui-table-row>
                                <sui-table-cell>Inst Nr.</sui-table-cell>
                                <sui-table-cell>{{ this.activeInstallationData.tasks[0].configurationItem.label }}</sui-table-cell>
                            </sui-table-row>
                            <sui-table-row v-if="this.activeInstallationData.tasks[0].configurationItem.association">
                                <sui-table-cell>Forening</sui-table-cell>
                                <sui-table-cell>{{this.activeInstallationData.tasks[0].configurationItem.association}}</sui-table-cell>
                            </sui-table-row>
                            <sui-table-row>
                                <sui-table-cell>Planlagt Start</sui-table-cell>
                                <sui-table-cell>{{ toUserFriendlyDate(this.getMinPropValue(this.activeInstallationData.tasks, 'plannedStart')) }}</sui-table-cell>
                            </sui-table-row>
                            <sui-table-row>
                                <sui-table-cell>Planlagt Slut</sui-table-cell>
                                <sui-table-cell>{{ toUserFriendlyDate(this.getMaxPropValue(this.activeInstallationData.tasks, 'plannedEnd')) }}</sui-table-cell>
                            </sui-table-row>

                            <sui-table-row>
                                <sui-table-cell colspan="2">
                                    <strong>Opgaver</strong>
                                </sui-table-cell>
                            </sui-table-row>
                            <sui-table-row v-for="task in this.relevantTasks.sort(this.sortTasks)" :key="task.id">
                                <sui-table-cell>
                                    {{task.shortDescription}}
                                </sui-table-cell>
                                <sui-table-cell>
                                    <sui-button size="mini" :color="getStateColor(task.state)" @click.stop="statusClicked(task)" >{{ task.state.label }}</sui-button>
                                </sui-table-cell>
                            </sui-table-row>
                            <sui-table-row v-if="!activeInstallationData.tasks.length">
                                <sui-table-cell colspan="2">
                                    <em>Ingen opgaver fundet</em>
                                </sui-table-cell>
                            </sui-table-row>

                            <sui-table-row>
                                <sui-table-cell>
                                    <strong>Udført arbejde</strong>
                                </sui-table-cell>
                                <sui-table-cell>
                                    <sui-button size="mini" color="blue" @click="addNewUnitWork">Registrér</sui-button>
                                </sui-table-cell>
                            </sui-table-row>
                            <sui-table-row v-for="unitWork in activeInstallationUnitWork" :key="unitWork.id" class="hover-pointer" @click="unitWorkClicked(unitWork)">
                                <template v-if="unitWork.ConfigurationItem.Label == activeInstallationLabel">
                                    <sui-table-cell><i v-if="unitWork.Billed && unitWork.Billed.Bool" class="fas fa-lock" title="Denne postering er markeret som faktureret, og derfor låst for redigering"/> {{unitWork.Unit.Name}}</sui-table-cell>
                                    <sui-table-cell>{{unitWork.Unit.Unit != 't' ? unitWork.Amount : decimalToHourString(unitWork.Amount)}} {{unitWork.Unit.Unit}} ({{unitWork.Workers.join("/").toUpperCase().replaceAll("@FIBERLAN.DK","")}} - {{toUserFriendlyDate(unitWork.Date, false)}})</sui-table-cell>
                                </template>
                            </sui-table-row>

                            <sui-table-row>
                                <sui-table-cell colspan="2">
                                    <strong>Noter</strong>
                                    &nbsp; &nbsp; &nbsp;
                                    <sui-button size="mini" @click="openUploadNoteModal"><i class="fas fa-plus"></i></sui-button>
                                </sui-table-cell>
                            </sui-table-row>
                            <template v-for="(note, index) in this.activeInstallationData.tasks[0].notes">
                                <sui-table-row :key="`n-${index}`">
                                    <sui-table-cell colspan="2">
                                        <p>
                                            <strong>{{ note.createdBy }}</strong> - 
                                            <span>{{ toUserFriendlyTimestamp(note.createdAt) }}</span>
                                        </p>
                                        <p class="note-body">{{ note.value }}</p>
                                    </sui-table-cell>
                                </sui-table-row>
                            </template>
                            <sui-table-row v-if="!this.activeInstallationData.tasks[0].notes || !this.activeInstallationData.tasks[0].notes.length">
                                <sui-table-cell colspan="2">
                                    <em>Ingen noter</em>
                                </sui-table-cell>
                            </sui-table-row>

                            <sui-table-row>
                                <sui-table-cell colspan="2">
                                    <strong>Filer</strong>
                                    &nbsp; &nbsp; &nbsp;
                                    <sui-button  size="mini" @click="openFileModal"><i class="fas fa-plus"></i></sui-button>
                                </sui-table-cell>
                            </sui-table-row>
                            <template v-for="(file) in this.activeInstallationData.tasks[0].attachments">
                                <sui-table-row :key="file.id" @click="openAttachment(file.id)" class="hover-pointer">
                                    <sui-table-cell colspan="2">
                                        <p>
                                            <strong>{{ file.name }}</strong> - 
                                            <span>{{ file.type }}</span>
                                        </p>
                                        <p>{{ formatBytes(file.sizeBytes) }}</p>
                                    </sui-table-cell>
                                </sui-table-row>
                            </template>
                            <sui-table-row v-if="!this.activeInstallationData.tasks[0].attachments || !this.activeInstallationData.tasks[0].attachments.length">
                                <sui-table-cell colspan="2">
                                    <em>Ingen filer</em>
                                </sui-table-cell>
                            </sui-table-row>

                            <sui-table-row>
                                <sui-table-cell colspan="2">
                                    <strong>Splidseplaner</strong>
                                </sui-table-cell>
                            </sui-table-row>
                            <sui-table-row 
                                v-for="technical in this.activeInstallationData.technicals" 
                                :key="technical.identification"
                                @click="openDownloadReportModal(technical)"
                                class="hover-pointer"
                            >
                                <sui-table-cell>{{technical.typeSource}}</sui-table-cell>
                                <sui-table-cell>{{technical.identification}}</sui-table-cell>
                            </sui-table-row>
                            <sui-table-row v-if="this.loadingTechnicals">
                                <sui-table-cell colspan="2">
                                    <TableLoader />
                                </sui-table-cell>
                            </sui-table-row>
                            <sui-table-row v-else-if="!this.activeInstallationData.technicals || !this.activeInstallationData.technicals.length">
                                <sui-table-cell colspan="2">
                                    <em v-if="this.activeInstallationData.tasks[0].configurationItem.cabinet">{{`Fandt ingen splidseplaner for skab ${this.activeInstallationData.tasks[0].configurationItem.cabinet.name}`}}</em>
                                    <em v-else>Denne installation har ikke et tilknyttet skab</em>
                                </sui-table-cell>
                            </sui-table-row>
                        </sui-table>
                    </div>
                </DataCard>
            </div>
        </div>
        <div class="col-sm-6">
            <DataCard no-padding class="full-height map-card" v-if="this.settingsBD.get('showMap')">
                <!-- <div class="map" ref="map">
                    <template v-for="(ins, index) in this.installations">
                        <MapMarker
                            v-if="ins.coordinates"
                            :key="`m-${index}`"
                            :id="ins.label"
                            :lat="parseFloat(ins.coordinates.Lat)"
                            :lng="parseFloat(ins.coordinates.Lng)"
                            :title="formatAddress(ins.tasks[0].configurationItem.address, false)"
                            :clusterGroup="getClusterGroup(ins)"
                            :active="activeInstallationLabel == ins.tasks[0].configurationItem.label"
                            :showPending="settingsBD.get('showPendingTasks')"
                            :showOnHold="showOnHoldTasks"
                            :isOnHold="ins.tasks[0].state.value == TaskState.ON_HOLD"
                            :matchSearch="matchSearchCriteria(ins)"
                            eventListenerName="project-timeline-item-clicked"
                            eventTriggerName="project-timeline-map-item-clicked"
                        />
                    </template>
                </div> -->
                <OKAPIMap
                    :mapMarkers="mapMarkers"
                />
            </DataCard>
        </div>

        <portal to="semantic-ui-modal">
            <sui-modal small v-model="showCoordinateImportModal" @displayChanged="this.checkMissingCoordinateStateForModal">
                <sui-modal-header>Manglende geo koordinater</sui-modal-header>
                <sui-modal-content>

                    <div v-if="coordinateImportStage == 0">
                        <p>Der er fundet <strong>{{ installationMissingCoordinates.length }}</strong> installationer hvor der mangler geo koordinater på, dette er et krav for systemet og du bedes derfor lade systemet importere disse.</p>
                        <p>Den estimerede import tid er: <strong>{{ estimatedCoordinatesImportTime }} sekunder</strong></p>
                        <p></p>
                        <p>Du kan også vælge at vente til senere, dog vil siden have begrænset funktionalitet.</p>
                    </div>
                    <div v-if="coordinateImportStage == 1">
                        <strong>Henter koordinater.. {{ parseInt(100 / Math.min(installationMissingCoordinates.length / installationMissingCoordinatesIteratorIndex, 100)) }}%</strong>
                        <sui-progress
                            :percent="parseInt(100 / Math.min(installationMissingCoordinates.length / installationMissingCoordinatesIteratorIndex, 100))"
                            indicating
                            state="active"
                        />


                        Estimeret tid tilbage: {{ estimatedCoordinatesImportTimeLeft }} sekunder.
                    </div>
                    <div v-if="coordinateImportStage == 2">
                        <div v-if="!failedInstallationMissingCoordinates.length" class="ui success message">
                            <h4>
                                Hentede {{ installationMissingCoordinates.length - failedInstallationMissingCoordinates.length }} 
                                af {{ installationMissingCoordinates.length }}
                            </h4>
                            <p>Du kan nu lukke denne popup og bruge siden.</p>
                            <p>Tid brugt: {{ parseInt(coordinateImportTime) }} sekunder.</p>
                        </div>

                        <div v-else>
                            <div class="ui warning message">
                                <h4>
                                    Hentede {{ installationMissingCoordinates.length - failedInstallationMissingCoordinates.length }} 
                                    af {{ installationMissingCoordinates.length }}
                                </h4>
                                <p>Desværre opstod der fejl ved en eller flere. Prøv venligst at genindlæse siden eller kontakt IT</p>
                                <p>Tid brugt: {{ parseInt(coordinateImportTime) }} sekunder.</p>
                            </div>

                            <h4>Fejlede:</h4>
                            <pre class="failed-coordinate-imports">{{ failedInstallationMissingCoordinates }}</pre>
                        </div>
                    </div>

                </sui-modal-content>
                <sui-modal-actions>
                    <sui-button v-if="coordinateImportStage == 0" @click.native="showCoordinateImportModal = false">
                        Senere
                    </sui-button>
                    <sui-button v-if="coordinateImportStage == 0" primary @click.native="startCoordinateImport">
                        Start import
                    </sui-button>
                    <sui-button v-if="coordinateImportStage == 1" negative @click.native="showCoordinateImportModal = false">
                        Afbryd
                    </sui-button>
                    <sui-button v-if="coordinateImportStage == 2" primary @click.native="showCoordinateImportModal = false">
                        Færdig
                    </sui-button>
                </sui-modal-actions>
            </sui-modal>

        </portal>

        <edit-settings-modal
            :isOpen="editSettings"
            :project="project"
            :settings="settingsBD"
            :hasProjectTasks="true"
            :hasTroubleTickets="true"
            page="cabinet list"
        />

    </div>

</template>
<script>
import { mapGetters } from 'vuex'
import swal from 'sweetalert'

import { db } from '../../firebase'
// import { Maps } from '../../lib/maps'
import OKAPIMap from '@/components/Maps/OKAPImap.vue'
import { Mixin } from '../../lib/Mixins/mixin'
import { DateMixin } from '../../lib/Mixins/dateMixin'
import { DataAPI } from '../../lib/DataAPI'
import { Bookingmixin } from '../../lib/Bookingmixin'
import { Mime } from '../../lib/helpers/mime'
import EventBus from '../../EventBus'
// import NetworkError from '../../lib/Errors/Network'
import TaskCode from '../../lib/Enums/TaskCode'
import TaskState from '../../lib/Enums/TaskState'
import AppointmentState from '../../lib/Enums/AppointmentState'
import BookingItemHandlerType from '../../lib/Enums/BookingItemHandlerType'
import ProjectType from '../../lib/Enums/ProjectType'

import SmsModal from '../../components/Project/SmsModal'
import Timeline from '../../components/Project/Timeline'
import TableLoader from '../../components/TableLoader'
import StateChangeModal from '../../components/Project/StateChangeModal.vue'
import FileViewerModal from '../../components/Project/FileViewerModal.vue'
import SpliceReportsDownloadModal from '../../components/Project/SpliceReportsDownloadModal.vue'
import EditUnitWorkModal from '../../components/Project/EditUnitWorkModal.vue'
import EditSettingsModal from '@/modules/Settings/EditSettingsModal.vue'
import UserRoles from '../../lib/Enums/UserRoles'

export default {
    name: 'CabinetDashboard',
    mixins: [Mixin, DateMixin, /* Maps, */ DataAPI, Bookingmixin, Mime],

    components: {
        'ProjectTimeline': Timeline,
        TableLoader,
        StateChangeModal,
        SmsModal,
        FileViewerModal,
        SpliceReportsDownloadModal,
        EditUnitWorkModal,
        EditSettingsModal,
        OKAPIMap,
    },

    enums: {
        TaskCode,
        TaskState,
        AppointmentState,
        BookingItemHandlerType,
        ProjectType,
        UserRoles,
    },

    data() {
        return {
            closedTasks: [],

            appointments: [],
            closedAppointments: [],
            coordinates: [],
            availableWorkersData: [],
            firebaseUsers: [],

            closedSinceInput: this.subtractMonths(new Date(),1).toISOString().substr(0,10),
            closedSince: this.subtractMonths(new Date(),1).toISOString().substr(0,10),
            getClosedLoading: false,

            searchFilterValue: '',
            lastIteratedDate: null,

            activeInstallationLabel: null,
            // activeInstallationProducts: [],
            activeInstallationData: null,
            activeInstallationMapClusterGroup: null,
            productError: null,

            editUnitWorkModalOpen: false,
            activeInstallationUnitWork: [],
            activeUnitWork: {},
            preFilledUnitWorkDescription: null,
            
            stateModalOpen: false,
            activeTask: {},

            installationMissingCoordinates: [],
            installationMissingCoordinatesIteratorIndex: 0,
            failedInstallationMissingCoordinates: [],
            showCoordinateImportModal: false,
            coordinateImportStage: 0,
            coordinateImportTime: 0,
            ignoreCoordinateImport: false,
            googleCoordinateLookupsPerSecond: 20, // Max allowed by Google: 50 req/s // Dawa max 30 req/s
            coordinatesImportIntervalLoopRef: null,

            loadingProducts: false,
            loadingInstallationData: false,
            loadingUnBookedTimeline: true,

            sortingCurrent: 0,
            sortingOptions: [
                {
                    text: 'Stigende',
                    value: 0,
                },
                {
                    text: 'Faldende',
                    value: 1,
                },
            ],

            map: null,
            showOnHoldTasks: window.localStorage.getItem('cabinet-dashboard-show-onhold-tasks') != 'false',
            showClosed: false,
            ignoreLeadPermissions: window.localStorage.getItem('admin-ignore-lead-permissions') == 'true',

            clusterGroups: {
                'done': {
                    icon: 'fas fa-calendar-check',
                    color: '#1E8449',
                    name: 'Dark green',
                    cluster: null,
                    title: 'Booking bekræftet'
                },
                'urgent': {
                    icon: 'fas fa-calendar-exclamation',
                    color: '#c7141a',
                    name: 'Red',
                    cluster: null,
                    title: 'Energi Fyns planlagte slutdato er overskredet'
                },
                'draft': {
                    icon: 'fas fa-calendar-edit',
                    color: '#FFCC00',
                    name: 'Yellow',
                    cluster: null,
                    title: 'Har en aftale-kladde'
                },
                'open': {
                    icon: 'fas fa-home',
                    color: '#c182c1',
                    name: 'Violet',
                    cluster: null,
                    title: 'Klar til at blive booket'
                },
                'pending': {
                    icon: 'fas fa-digging',
                    color: '#666666',
                    name: 'Grey',
                    cluster: null,
                    title: 'Afventer forudgående arbejde (pending)'
                },
                'active': {
                    icon: 'fas fa-star',
                    color: '#037be4',
                    name: 'Blue',
                    cluster: null,
                    title: 'Dette er den aktive kunde'
                },
                'default': {
                    icon: 'fas fa-home',
                    color: '#666666',
                    name: 'Grey',
                    cluster: null,
                    title: 'Kunne ikke finde relevant ikon'
                }
            },

            noteModalOpen: false,
            noteType: "",
            noteText: "",
            noteFormSuccess: false,
            noteFormLoading: false,

            uploadFileModalOpen: false,
            fileNameInput: null,
            fileUpload: null,
            fileFormSuccess: false,
            fileFormLoading: false,

            openFileModalOpen: false,
            openFile: null,
            openFileLoading: false,

            smsModalOpen: false,
            activePhoneNumber: "",
            editSettings: false,

            // stashedServiceOrders: [],

            firebaseUser: {},

            loadAttempt: 0,

            stashedTechnicals: {
                hubs: {},
                uubs: {}
            },
            loadingTechnicals: false,

            downloadReportModalOpen: false,
            downloadReportActiveItem: {},

            mapMarkers: {},
        }
    },

    computed: {
        user() {
            return this.$root.$children[0].user
        },

        currentMedia() {
            return this.$root.$children[0].currentMedia
        },

        showSettings() {
            if (this.currentMedia.type == 'Mobile' && this.currentMedia.orientation == 'Portrait') return false
            return true
        },

        userHasLeadPermissions() {
            if (this.ignoreLeadPermissions) return false
            if(this.firebaseUser && this.firebaseUser.Roles && this.firebaseUser.Roles.includes(UserRoles.EFB_PROJECT_EDITOR)) return true //Admins always have lead permissions
            let userContact = this.project.Contacts.find(contact => contact.Email == this.user.email) //Find user in project contacts
            if (!userContact) return false //if user is not in project contacts, they cannot be project lead
            if (userContact.Roles.includes('ProjectLead')) return true //Allow lead permissions for project lead
            return false
        },

        ...mapGetters({
            project: 'activeProject',
        }),

        sortAscending() {
            return !this.settingsBD.get('sortCabinetsAscending')
        },

        availableInstallations() {
            if (!this.installations) return []

            let installations = this.installations
                .filter((ins) => {
                    if (!ins.tasks[0]) return false
                    return !this.appointments.some(b => {
                        const task = ins.tasks[0]
                        return this.objExistsInArray('Id', task.id, b.ProjectTasks)
                    })
                })
                .sort((a, b) => {
                    const taskA = a.tasks[0]
                    const taskB = b.tasks[0]
                    if (!taskA || !taskB) return 0
                    if (taskA.plannedEnd > taskB.plannedEnd) return this.sortAscending ? 1 : -1
                    if (taskB.plannedEnd > taskA.plannedEnd) return this.sortAscending ? -1 : 1
                    if (taskA.assignee > taskB.assignee) return this.sortAscending ? 1 : -1
                    if (taskB.assignee > taskA.assignee) return this.sortAscending ? -1 : 1
                    if (taskA.configurationItem.label > taskB.configurationItem.label) return this.sortAscending ? 1 : -1
                    if (taskB.configurationItem.label > taskA.configurationItem.label) return this.sortAscending ? -1 : 1
                    return 0;
                })

            if (!this.settingsBD.get('showPendingTasks') || !this.showOnHoldTasks) {
                installations = installations.filter((ins) => {

                    if (!this.settingsBD.get('showPendingTasks') && !this.showOnHoldTasks) {
                        return !this.insContainsOnlyState(ins, TaskState.PENDING) && !this.insContainsState(ins, TaskState.ON_HOLD)
                    } else if (!this.settingsBD.get('showPendingTasks')) {
                        return !this.insContainsOnlyState(ins, TaskState.PENDING)
                    } else if (!this.showOnHoldTasks) {
                        return !this.insContainsState(ins, TaskState.ON_HOLD)
                    }

                    return true
                })
            }

            if (this.searchFilterValue) {
                installations = installations.filter((ins) => {
                    return this.matchSearchCriteria(ins)
                })
            }

            let groupedByDates = this.uniqueBookingDates(installations, 'plannedEnd', true)
            installations.forEach(ins => {
                const task = ins.tasks[0]
                if (!task) {
                    if (!groupedByDates['N/A']) groupedByDates['N/A'] = []
                    groupedByDates['N/A'].push(ins)
                    return
                }
                groupedByDates[this.formatMachineDate(task.plannedEnd)].push(ins)
            })

            return groupedByDates
        },

        availableInstallationsLength() {
            const bookingDates = this.availableInstallations
            let counter = 0

            for(let i in bookingDates) {
                let date = bookingDates[i]
                for (let j in date) { // eslint-disable-line no-unused-vars
                    counter++
                }
            }

            return String(counter)
        },

        availableWorkers() {
            // if (this.project.Workers.length == 0) return []

            let workersArray = this.project.Workers
            workersArray.push({Email: "Ingen"})

            let workerOptions = []
            for (let i in workersArray) {
                let w = workersArray[i]
                let firWorker = this.firebaseUsers.find((worker) => w.Email.toLowerCase() == worker.Email.toLowerCase())
                if (firWorker) {
                    workerOptions.push({key: i, value: w.Email.toLowerCase(), text: `${firWorker.Name} (${firWorker.Initials})`})
                }
            }

            return workerOptions
        },

        activeInstallationAddress() {
            if (!this.activeInstallationData.tasks[0].configurationItem || !this.activeInstallationData.tasks[0].configurationItem.address) return "Ingen adresse"
            return this.formatAddress(this.activeInstallationData.tasks[0].configurationItem.address)
        },

        activeInstallationBoxIcon() {

            const group = this.clusterGroups[this.activeInstallationMapClusterGroup]
            if (!group) {
                return 'fas fa-home'
            }
            
            return `${group.icon} color-${group.color.substr(1)}`
        },

        activeInstallationBoxTitle() {
            const group = this.clusterGroups[this.activeInstallationMapClusterGroup]
            return group?.title
        },

        relevantTasks() {
            return this.rawTasks.filter((task) => {
                return task.configurationItem?.label == this.activeInstallationLabel
            })
        },

        // activeServiceOrder() {
        //     // console.log(this.activeInstallationData.tasks[0])
        //     if (!this.activeInstallationData || !this.activeInstallationData.tasks[0] || !this.activeInstallationData.tasks[0].serviceOrder) return null
        //     if (!this.objExistsInArray('id', this.activeInstallationData.tasks[0].serviceOrder.id, this.stashedServiceOrders)) {
        //         //console.error(`No such serviceOrder in stash: ${this.activeInstallationData.tasks[0].serviceOrder.id}`)
        //     }
        //     let i = this.stashedServiceOrders.findIndex(so => so.id == this.activeInstallationData.tasks[0].serviceOrder.id)
        //     return this.stashedServiceOrders[i]
        // },

        // relevantServiceOrderTasks() {
        //     // console.log("Getting relevant tasks...")
        //     if(!this.activeServiceOrder || !this.activeServiceOrder.project || !this.activeServiceOrder.project.tasks){
        //         // console.log(JSON.stringify(this.activeServiceOrder.project.tasks))
        //         return []
        //     }
        //     return this.activeServiceOrder.project.tasks.filter((task) => {
        //         // console.log(task.code)
        //         return [TaskCode.CPE, TaskCode.BOOKKUNDE, TaskCode.BLAES].includes(task.code)
        //     })
        // },

        estimatedCoordinatesImportTime() {
            const estimate = Math.round(this.installationMissingCoordinates.length / this.googleCoordinateLookupsPerSecond) + 1
            return estimate > 2 ? estimate : 2
        },

        estimatedCoordinatesImportTimeLeft() {
            const estimate = Math.round(
                (this.installationMissingCoordinates.length - this.installationMissingCoordinatesIteratorIndex)
                / this.googleCoordinateLookupsPerSecond) + 1
            return estimate
        },

        mapsBounds() {
            console.log('calculating bounds')
            let bounds = null
            for (let ins of this.installations) {
                if (!ins || !ins.coordinates || !ins.coordinates.Lat || !ins.coordinates.Lng){
                    console.error('invalid coordinates forinstallation', ins)
                    continue;
                }
                if (!bounds) {
                    bounds = {
                        north: Number(ins.coordinates.Lat),
                        south: Number(ins.coordinates.Lat),
                        east: Number(ins.coordinates.Lng),
                        west: Number(ins.coordinates.Lng),
                    }
                } else {
                    bounds.north = Math.max(Number(ins.coordinates.Lat), bounds.north)
                    bounds.south = Math.min(Number(ins.coordinates.Lat), bounds.south)
                    bounds.east = Math.max(Number(ins.coordinates.Lng), bounds.east)
                    bounds.west = Math.min(Number(ins.coordinates.Lng), bounds.west)
                }
            }
            console.log(bounds)
            return bounds
        },
    },

    methods: {

        onEditSettingsButtonClicked(){
            this.editSettings = true
            console.log("Opening settings modal")
        },

        uniqueBookingDates(installations, dateField, returnAsKeys) {
            let uniqueDates = [...new Map(installations.map(ins => {
                if (!ins.tasks[0]) return ['N/A', 'N/A']
                let date = ins.tasks[0][dateField]
                if (!date) date = 'N/A'
                return [this.formatMachineDate(date), this.formatMachineDate(date)]
            })).values()]

            if (returnAsKeys == true) {
                return uniqueDates.reduce((v,i) => (v[i] = [], v), {})
            }
            return uniqueDates
        },

        getAvailableWorkersData() {
            if (!this.project || !this.project.Workers) return

            try {
                this.availableWorkersData = this.project.Workers.map(worker => {
                    let _worker = this.firebaseUsers.find(w => {return this.lowercase(w.Email) == this.lowercase(worker.Email)})
                    if (!_worker) return
                    return _worker
                })
            }
            catch (err) {console.error(err)}
        },

        async getHubs(id, forcefetch){
            EventBus.$emit('function-activity', {functionName: 'CabinetDashboard_getHubs', isActive: true})
            let hubId = id.replaceAll(' ', '').substr(0, id.replaceAll(' ', '').indexOf('-'))
            let hubs = this.stashedTechnicals.hubs[hubId]
            if (!hubs?.length || forcefetch){
                hubs = await this.dataGetHubs(id)
                this.stashedTechnicals.hubs[hubId] = hubs
            }
            EventBus.$emit('function-activity', {functionName: 'CabinetDashboard_getHubs', isActive: false})
            return hubs
        },

        async getUubs(id, forcefetch){
            EventBus.$emit('function-activity', {functionName: 'CabinetDashboard_getUubs', isActive: true})
            let uubs = this.stashedTechnicals.hubs[id]
            if (!uubs?.length || forcefetch){
                uubs = await this.dataGetUubs(id)
                this.stashedTechnicals.uubs[id] = uubs.sort((a, b) => { return (a.identification > b.identification) ? 1 : -1 })
            }
            if (!uubs) {
                EventBus.$emit('function-activity', {functionName: 'CabinetDashboard_getUubs', isActive: false})
                return
            }
            EventBus.$emit('function-activity', {functionName: 'CabinetDashboard_getUubs', isActive: false})
            return uubs.sort((a, b) => {
                return (a.identification > b.identification) ? 1 : -1
            })
        },

        async getTechnicals(forcefetch = false) {
            EventBus.$emit('function-activity', {functionName: 'CabinetDashboard_getTechnicals', isActive: true})
            this.loadingTechnicals = true
            const startingInstallationLabel = this.activeInstallationLabel.toLowerCase()
            let cabinetName = this.activeInstallationData?.tasks?.[0]?.configurationItem?.cabinet?.name

            if (!cabinetName) {
                cabinetName = this.activeInstallationData?.label
            }

            if (!cabinetName) {
                console.error(`Installation ${startingInstallationLabel} has no cabinet`)
                this.loadingTechnicals = false
                EventBus.$emit('function-activity', {functionName: 'CabinetDashboard_getTechnicals', isActive: false})
                return
            }

            await Promise.all([
                this.getHubs(cabinetName, forcefetch),
                this.getUubs(cabinetName, forcefetch)
            ])

            if (startingInstallationLabel != this.activeInstallationLabel.toLowerCase()){
                console.error('Installation changed while getting technicals, quitting function')
                EventBus.$emit('function-activity', {functionName: 'CabinetDashboard_getTechnicals', isActive: false})
                return
            }
            await this.bookFormatBookingData()

            if (startingInstallationLabel != this.activeInstallationLabel.toLowerCase()){
                console.error('Installation changed while rendering technicals, quitting function')
                EventBus.$emit('function-activity', {functionName: 'CabinetDashboard_getTechnicals', isActive: false})
                return
            }

            let technicals = []
            let id = this.activeInstallationData.tasks[0]?.configurationItem?.cabinet?.name
            if (!id) { id = this.activeInstallationData.label }
            let hubId = id.replaceAll(' ', '').substr(0, id.replaceAll(' ', '').indexOf('-'))
            if (this.stashedTechnicals.hubs[hubId]) {
                technicals = technicals.concat(this.stashedTechnicals.hubs[hubId])
            }
            if (this.stashedTechnicals.uubs[id]) {
                technicals = technicals.concat(this.stashedTechnicals.uubs[id])
            }

            this.activeInstallationData.technicals = technicals

            this.loadingTechnicals = false
            EventBus.$emit('function-activity', {functionName: 'CabinetDashboard_getTechnicals', isActive: false})
        },

        onReceiveItemClicked(installationLabel) { //The user clicked on an installation

            console.log(installationLabel)

            if (installationLabel == null) {
                this.activeInstallationLabel = null
                this.activeInstallationData = null
                this.loadingInstallationData = false
            }

            if (this.activeInstallationLabel != installationLabel) {
                this.activeInstallationLabel = installationLabel
                try {
                    this.$refs.activeInstallationCard.scrollIntoView()
                    // eslint-disable-next-line
                } catch(e) {} // Shhh 🤫
            }

            // Nothing more happens here because the rest is triggered via watchers.
        },


        
       

        toggleShowOnHoldTasks() {
            this.showOnHoldTasks = !this.showOnHoldTasks
            localStorage.setItem('cabinet-dashboard-show-onhold-tasks', this.showOnHoldTasks)
        },

        toggleShowClosed() {
            this.showClosed = !this.showClosed
        },

        onDetailsButtonClick() {
            const url = `/projects/${this.$route.params.projectIdentifier}/installations/${this.activeInstallationData.tasks[0].configurationItem.value}`

            /* 
                It seems it is necessary to push the booking page to the history API every time,
                otherwise the history will look like the follwing and every new button click will be appended:
                    /booking
                    /installation/xx
                    /installation/xx
                    /installation/xx
            */
            this.$router.push(`/projects/${this.$route.params.projectIdentifier}/booking`)
            this.$router.push(url)
        },

        appointmentExists(label) {
            return this.appointments.some((a) => {
                return a.InstallationLabel == label
            })
        },

        async setActiveInstallationData(forceFetch = false) {
            if (!this.activeInstallationLabel) {
                return
            }

            EventBus.$emit('function-activity', {functionName: 'CabinetDashboard_setActiveInstallationData', isActive: true})
            this.loadingInstallationData = true
            this.activeInstallationData = null

            let activeIns = this.installations.find(ins => {
                return ins.label == this.activeInstallationLabel
            })
            if (!activeIns) {
                swal('Kunne ikke finde installation', 'Installation blev desværre ikke fundet i systemet ved en fejl.', 'error')
                this.loadingInstallationData = false
                EventBus.$emit('function-activity', {functionName: 'CabinetDashboard_setActiveInstallationData', isActive: false})
                return
            }

            this.activeInstallationData = activeIns

            // await this.getServiceOrder(forceFetch)
            await this.getFullTasks(activeIns.tasks,forceFetch)
            this.loadingInstallationData = false
            await this.getTechnicals(forceFetch)
            EventBus.$emit('function-activity', {functionName: 'CabinetDashboard_setActiveInstallationData', isActive: false})
        },

        async checkMissingCoordinateStateForModal(payload) {
            if (payload != 'closing') return
            if (this.installationMissingCoordinates.length == 0) return
            EventBus.$emit('function-activity', {functionName: 'CabinetDashboard_checkMissingCoordinateStateForModal', isActive: true})
            if (this.coordinateImportStage == 0) {
                this.ignoreCoordinateImport = true
                this.bookFormatBookingData(false)
                EventBus.$emit('function-activity', {functionName: 'CabinetDashboard_checkMissingCoordinateStateForModal', isActive: false})
                return
            }

            if (this.coordinateImportStage == 2) {
                EventBus.$emit('function-activity', {functionName: 'CabinetDashboard_checkMissingCoordinateStateForModal', isActive: false})
                return
            }

            if (this.ignoreCoordinateImport) {
                await this.bookFormatBookingData(false)
                EventBus.$emit('function-activity', {functionName: 'CabinetDashboard_checkMissingCoordinateStateForModal', isActive: false})
                return
            }
            
            let resp = await swal('Advarsel', {
                icon: 'warning',
                text: 'Ønsker du at stoppe processen?',
                dangerMode: true,
                buttons: ['Nej, gå tilbage', 'Stop process']
            })
            
            if (resp) {
                this.ignoreCoordinateImport = true
                clearInterval(this.coordinatesImportIntervalLoopRef)
                await this.bookFormatBookingData(false)
                EventBus.$emit('function-activity', {functionName: 'CabinetDashboard_checkMissingCoordinateStateForModal', isActive: false})
                return
            }
            
            this.showCoordinateImportModal = true
            EventBus.$emit('function-activity', {functionName: 'CabinetDashboard_checkMissingCoordinateStateForModal', isActive: false})
        },

        async startCoordinateImport() {
            EventBus.$emit('function-activity', {functionName: 'CabinetDashboard_startCoordinateImport', isActive: true})
            this.coordinateImportStage = 1
            let startTime = new Date()
            let index = 0
            let installations = this.installationMissingCoordinates.reduce((group, ins) => {
                if (!group[index]) group[index] = []
                if (group[index].length == this.googleCoordinateLookupsPerSecond) {
                    index++
                    group[index] = []
                }
                group[index].push(ins)
                return group
            }, [])
            
            index = 0
            let results = new Map()
            this.coordinatesImportIntervalLoopRef = setInterval(async () => {
                if (!installations[index]) {
                    clearInterval(this.coordinatesImportIntervalLoopRef)
                    let stopTime = new Date()
                    let timeDiff = stopTime - startTime
                    this.onFinishedCoordinateImport(results, timeDiff)
                    EventBus.$emit('function-activity', {functionName: 'CabinetDashboard_startCoordinateImport', isActive: false})
                    return
                }
                for (let i in installations[index]) {
                    let ins
                    try {
                        if (!installations[index]) continue
                        ins = installations[index][i]
                        let result = await this.externalGetGeoCoordinatesFromAddress(ins.configurationItem.address)
                        results.set(ins.configurationItem.label, { ...result, areaCode: ins?.configurationItem?.area?.sonWinProjectId, type: ins?.project?.type?.value })
                        this.installationMissingCoordinatesIteratorIndex++
                    }
                    catch (error) {
                        this.failedInstallationMissingCoordinates.push(ins)
                        console.error(error)
                    }
                }
                index++
            }, 1000)
        },

        async onFinishedCoordinateImport(results, timeDiff) {
            EventBus.$emit('function-activity', {functionName: 'CabinetDashboard_onFinishedCoordinateImport', isActive: true})
            this.coordinateImportStage = 2
            this.coordinateImportTime = parseInt(timeDiff / 1000, 10)

            try {
                await this.dataSaveCoordinates(results, this.project.id)
            }
            catch (error) {
                swal('System Fejl', `Kunne ikke gemme data i systemet. Firebase fejl.\n\n${error.message}`, 'error')
                console.error(error)
            }

            const countSucceeded = this.installationMissingCoordinates.length - this.failedInstallationMissingCoordinates.length
            if (countSucceeded == this.installationMissingCoordinates.length) {
                await this.bookFormatBookingData(true)
            }
            EventBus.$emit('function-activity', {functionName: 'CabinetDashboard_onFinishedCoordinateImport', isActive: false})
        },

        async getAttachment(attachmentId){
            EventBus.$emit('function-activity', {functionName: 'CabinetDashboard_getAttachment', isActive: true})
            let response = await this.dataGetAttachment(attachmentId)
            EventBus.$emit('function-activity', {functionName: 'CabinetDashboard_getAttachment', isActive: false})
            return response
        },

        async openAttachment(attachmentId){
            EventBus.$emit('function-activity', {functionName: 'CabinetDashboard_openAttachment', isActive: true})
            this.openFileLoading = true //Start loading animation
            this.openFileModalOpen = true //Open modal
            this.openFile = await this.getAttachment(attachmentId) //Get attachment from API
            this.openFileLoading = false //End loading animation
            EventBus.$emit('function-activity', {functionName: 'CabinetDashboard_openAttachment', isActive: false})
        },

        openUploadNoteModal(){
            this.noteModalOpen = true //Open the note modal
        },

        async postNote(){
            EventBus.$emit('function-activity', {functionName: 'CabinetDashboard_postNote', isActive: true})
            // console.log(this.activeInstallationData)
            let firstTaskNum = this.getMinPropValue(this.activeInstallationData.tasks, 'number')
            let taskId = this.activeInstallationData.tasks.find(task => task.number == firstTaskNum).id
            let response = await this.dataPostNote(taskId,this.activeInstallationLabel,this.noteText,this.noteType,this.$route.params.projectIdentifier)
            await this.setActiveInstallationData(true) //Retrieve latest task data
            EventBus.$emit('function-activity', {functionName: 'CabinetDashboard_postNote', isActive: false})
            return(response)
        },

        async submitNote(){
            EventBus.$emit('function-activity', {functionName: 'CabinetDashboard_submitNote', isActive: true})
            this.noteFormLoading = true //Start loading animation
            await this.postNote() //Post note to database
            this.noteText = "" //Clear textfield
            this.noteType = "" //Clear radio-buttons
            await this.setActiveInstallationData(true) //Retrieve latest task data
            this.noteModalOpen = false //Close modal
            this.noteFormLoading = false //End loading animation
            EventBus.$emit('function-activity', {functionName: 'CabinetDashboard_submitNote', isActive: false})
        },

        openFileModal(){
            this.uploadFileModalOpen = true //Open the file modal
        },

        setFileUpload(e){
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) {
                this.fileUpload = null
                return false
            }
            this.fileUpload = files[0]
            return true
        },

        async encodeFile(file){
            EventBus.$emit('function-activity', {functionName: 'CabinetDashboard_encodeFile', isActive: true})
            const toBase64 = file => new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = () => resolve (reader.result)
                reader.onerror = error => reject(error)
            })
            let encodedFile = await toBase64(file).catch(e => Error(e))
            if(encodedFile instanceof Error) {
                console.log('Error encoding file: ',encodedFile.message)
                EventBus.$emit('function-activity', {functionName: 'CabinetDashboard_encodeFile', isActive: false})
                return
            }
            EventBus.$emit('function-activity', {functionName: 'CabinetDashboard_encodeFile', isActive: false})
            return encodedFile
        },

        async submitFile(){
            EventBus.$emit('function-activity', {functionName: 'CabinetDashboard_submitFile', isActive: true})
            this.fileFormLoading = true //Start loading animation
            let base64Content = await this.encodeFile(this.fileUpload)
            base64Content = String(base64Content).substring(base64Content.indexOf('base64,')+7)
            
            let fileName = this.fileNameInput
            if (!fileName) fileName = this.fileUpload.name
            
            const expectedExtension = this.getExtenstionByMimeType(this.fileUpload.type)
            const fileExtension = this.getFileExtension(fileName)
            if (fileExtension != expectedExtension) {
                fileName += `.${expectedExtension}`
            }

            await this.dataPostAttachment(this.activeInstallationData.tasks[0].id, fileName, this.fileUpload.type, base64Content)

            // reset modal
            this.uploadFileModalOpen = false
            this.fileNameInput = null
            await this.setActiveInstallationData(true)
            this.fileFormLoading = false //End loading animation
            EventBus.$emit('function-activity', {functionName: 'CabinetDashboard_submitFile', isActive: false})
        },

        matchSearchCriteria(ins) {
            if (!this.searchFilterValue) return true

            return !!(
                String(ins.tasks[0].configurationItem.label).toLowerCase().match(this.searchFilterValue) || 
                String(ins.tasks[0].configurationItem?.address?.city).toLowerCase().match(this.searchFilterValue) ||
                String(ins.tasks[0].configurationItem?.address?.road).toLowerCase().match(this.searchFilterValue)
            )
        },

        sortTasks(t1, t2) {
            // PRJTASK0012357
            const t1Task = t1.number.replace('PRJTASK', '')
            const t2Task = t2.number.replace('PRJTASK', '')

            return parseInt(t1Task) > parseInt(t2Task) ? 1 : -1
        },

        statusClicked(task) {
            this.activeTask = task
            this.stateModalOpen = true
        },

        openSmsModal(phoneNumber) {
            this.activePhoneNumber = phoneNumber
            this.smsModalOpen = true
        },
        
        initializeMaps() {
            if (!this.settingsBD.get('showMap')) return
            
            
        },

        setMapBounds(retries = 3, timeBtwnTries = 2000, currentAttempt = 0){
            if (this.map && this.mapsBounds){
                let bounds = new window.google.maps.LatLngBounds(
                    new window.google.maps.LatLng(this.mapsBounds.south,this.mapsBounds.west), //Bottom left
                    new window.google.maps.LatLng(this.mapsBounds.north,this.mapsBounds.east) //Top right
                )
                this.map.fitBounds(bounds)
            } else if (currentAttempt < retries) {
                console.log('Map or bounds was not ready, trying again...')
                setTimeout(() => {
                    this.setMapBounds(retries, timeBtwnTries, currentAttempt+1)
                }, timeBtwnTries)
            } else {
                console.error(`Failed to set bounds of map after ${retries} retries`)
            }
        },

        triggerLoadBookingData() {
            // Failsafe loading of booking data. Project must be propulated before this.
            if (this.project.Name) {
                this.bookGetCabinetTasks(this.project.Uubs, this.settingsBD.get('showPendingTasks'), this.showOnHoldTasks)
            } else {
                this.loadAttempt++
                if (this.loadAttempt < 1000) {
                    setTimeout(this.triggerLoadBookingData, 100)
                } else {
                    swal('Fejl', 'Fejl i load af data. Venligst genindlæs siden.', 'error')
                }
            }
        },

        openDownloadReportModal(technical) {
            this.downloadReportActiveItem = technical
            this.downloadReportModalOpen = true
        },

        async getFullTasks(tasks) {
            EventBus.$emit('function-activity', {functionName: 'CabinetDashboard_getFullTasks', isActive: true})
            let taskArray = []
            for (let i in tasks) {
                let task = tasks[i]
                let fulltask = await this.dataGetProjectTask(task)
                // console.log(fulltask)
                let rawTasksIndex = this.rawTasks.findIndex((rawTask) => {return rawTask.id == task.id})
                // console.log(rawTasksIndex)
                if (rawTasksIndex != -1){
                    this.rawTasks[rawTasksIndex] = fulltask
                } else {
                    this.rawTasks.push(fulltask)
                }
                taskArray.push(fulltask)
            }
            await this.bookFormatBookingData(true)

            if (this.activeInstallationLabel == taskArray[0].configurationItem.label) {
                let activeIns = this.installations.find(ins => {
                    return ins.label == this.activeInstallationLabel
                })
                if (activeIns) {
                    this.activeInstallationData = activeIns
                }
            }

            EventBus.$emit('function-activity', {functionName: 'CabinetDashboard_getFullTasks', isActive: false})
            return taskArray
        },

        addNewUnitWork(note = null) {
            this.preFilledUnitWorkDescription = note
            if (typeof this.preFilledUnitWorkDescription != 'string') {
                this.preFilledUnitWorkDescription = null
            }
            this.activeUnitWork = {}
            this.editUnitWorkModalOpen = true
        },

        unitWorkClicked(unitWork){
            console.log(unitWork)
            this.activeUnitWork = unitWork
            this.editUnitWorkModalOpen = true
        },

        async regenerateMapMarkers() {
            // console.log('Regenterating all markers...')
            let typeString = `undefined-pending`//Default map marker
            let changeCounter = 0

            //TODO: Remove map markers for installations no longer present in this.installations
            // this.mapMarkers = {}
            for (let marker of Object.keys(this.mapMarkers)) {
                if (!this.installations.find((ins) => ins.label == marker)) {
                    changeCounter += 1
                    delete this.mapMarkers[marker]
                }
            }

            for (let ins of this.installations) {
                if (!ins.label || !ins.coordinates) {
                    continue; //Skip installations without label or coordinates
                }
                const markerObj = {
                    key: `mapMarker-${ins.label}`,
                    id: ins.label,
                    type: this.getMarkerTypeFromInstallation(ins, this.activeInstallationLabel) || typeString,
                    title: this.formatAddress(ins.tasks[0].configurationItem.address, false),
                    coordinates: { //TODO: Refer to Hub Address for PATCH-tasks
                        lat: parseFloat(ins.coordinates.Lat),
                        lng: parseFloat(ins.coordinates.Lng),
                    },
                    address: this.formatAddress(ins.tasks[0].configurationItem.address, false), //TODO: Refer to Hub Address for PATCH-tasks
                }
                if (JSON.stringify(this.mapMarkers[ins.label]) == JSON.stringify(markerObj)) { //Dont do anything if the new map marker is identical to an existing one
                    continue;
                } 

                this.$set(this.mapMarkers, ins.label, markerObj)
                changeCounter += 1
            }

            // console.log(`Regenerating all markers caused ${changeCounter} changes`)

            if (changeCounter) {
                await this.sleep(50)
                EventBus.$emit('okapi-map-refresh')
                return this.mapMarkers
            } else {
                return this.mapMarkers
            }
        },

        async updateMarkerType(ins, activeInsLabel) {
            if (!ins || !ins.label || !this.mapMarkers || !this.mapMarkers[ins.label]) return;
            console.log(`Updating marker type for installation ${ins.label} (${activeInsLabel} is active)`)
            let tempMarker = this.mapMarkers[ins.label]
            tempMarker.type = this.getMarkerTypeFromInstallation(ins, activeInsLabel)
            this.$set(this.mapMarkers, ins.label, tempMarker)
            await this.sleep(50)
            EventBus.$emit('okapi-map-refresh')
        },
    },

    watch: {
        activeInstallationLabel: {
            immediate: false,
            async handler() {
                this.$bind('activeInstallationUnitWork', db.collection('UnitWork').where('ConfigurationItem.Label','==',this.activeInstallationLabel))
                await this.setActiveInstallationData()
            }
        },
        project: {
            immediate: false,
            async handler() {
                document.title = `${this.project.Name} Skabe - FiberTeam`
                await this.getAvailableWorkersData()
            }
        },
        user: {
            immediate: true,
            handler(user) {
                // this.$unbind('firebaseUser')
                if (user){
                    this.$bind('firebaseUsers', db.collection('Users'))
                    this.$bind('firebaseUser', db.collection(`Users`).doc(user.email))
                }
            }
        },
        showClosed: {
            immediate: false,
            handler() {
                this.showHideClosedTasks(this.project.AreaCodes)
            }
        },
        closedSince: {
            immediate: false,
            handler() {
                this.showHideClosedTasks(this.project.AreaCodes)
            }
        },
        showOnHoldTasks: {
            immediate: false,
            handler(val) {
                if (val) {
                    this.bookAddOrUpdateOnHoldTasks(this.project.AreaCodes, [this.ProjectType.CABINET])
                }
            }
        },
        // showPendingTasks: {
        //     immediate: false,
        //     handler(val) {
        //         if (val) {
        //             this.bookAddOrUpdatePendingTasks(this.project.AreaCodes, [this.ProjectType.CABINET])
        //         }
        //     }
        // }
    },

    beforeMount() {
        EventBus.$on('edit-settings-modal-closing', () => {this.editSettings = false})
        EventBus.$on('edit-settings-modal-open', () => {this.editSettings = true})
        EventBus.$on('project-timeline-item-clicked', this.onReceiveItemClicked)
        EventBus.$on('okapi-map-item-clicked', this.onReceiveItemClicked)
        EventBus.$on('details-button-click', this.onDetailsButtonClick)
        EventBus.$on('map-marker-update', (marker) => {
            if (marker.active) {
                this.activeInstallationMapClusterGroup = marker.prevClusterGroup
            }
        })
        
        EventBus.$on('newFile-click', () => {this.openFileModal()})
        EventBus.$on('top-search-type', (payload) => {
            this.searchFilterValue = String(payload).toLowerCase()
            if (!payload) this.searchFilterValue = ''
        })
        EventBus.$on('state-changed', () => {this.setActiveInstallationData(true)})
        //TODO: listen for 'add-unit-work' event, and open the editUnitWork form with note pre-filled
        EventBus.$on('stateChange-modal-closing', () => {this.stateModalOpen = false})
        EventBus.$on('sms-modal-closing', () => {this.smsModalOpen = false})
        EventBus.$on('loading-timelines', (value) => { this.loadingBookedTimeline = value; this.loadingUnBookedTimeline = value })

        EventBus.$on('timeline-update-task', async () => {
            await this.setActiveInstallationData(true)
            await this.setActiveInstallationData() //Why twice?
        })
        
        EventBus.$on('file-viewer-modal-close', () => {
            this.openFileModalOpen = false
        })

        EventBus.$on('report-download-modal-close', () => {this.downloadReportModalOpen = false})

        EventBus.$on('admin-ignore-lead-permissions', (value) => {this.ignoreLeadPermissions = value})

        EventBus.$on('edit-unit-work-modal-close', () => {
            this.activeUnitWork = {}
            this.editUnitWorkModalOpen = false
        })

        EventBus.$on('okapi-map-initialized', () => {this.initializeMaps()})
    },

    async mounted() {
        EventBus.$emit('function-activity', {functionName: 'CabinetDashboard_mounted', isActive: true})
        document.body.classList.add('sidebar-collapse')

        this.getAvailableWorkersData()

        this.triggerLoadBookingData()

        this.initializeMaps()

        document.title = `${this.project.Name} Skabe - FiberTeam`
        localStorage.setItem('last-visited-page','cabinet-dashboard')
        EventBus.$emit('function-activity', {functionName: 'CabinetDashboard_mounted', isActive: false})
    },

    destroyed() {
        document.body.classList.remove('sidebar-collapse')
    }
}
</script>
<style>
.scrollable {
    overflow-y: auto;
    overflow-x: auto;
    height: 100%;
    position: relative;
}

.full-height {
    height: calc(100vh - 205px);
}

.half-height {
    height: calc(50vh - 110px);
}

.scrollable table.timeline-table {
    margin-bottom: 0 !important;
}

.scrollable table.timeline-table tr th {
    border-top: 2px solid #dee2e6 !important;
    border-bottom: none !important;
}

.scrollable table.timeline-table tr td {
    border-top: none !important;
}

.scrollable table.timeline-table:first-child tr th {
    border-top: none !important;
}

.table-md td, .table-md th {
    padding: .5rem;
}

.card-body.p-0 .table tbody>tr>td:first-of-type,
.card-body.p-0 .table thead>tr>th:first-of-type {
    padding-left: 0.7rem;
}

.table-md td > span,
.address-cell {
    font-size: 14px;
}

/*.address-cell i {
    top: -5px;
    position: relative;
}*/

.address-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 200px;
    display: inline-block;
}

.map {
    height: 100%;
}

.note-body {
    white-space: break-spaces;
}

.booking-mini-modal {
    /*position: absolute;*/
    position: fixed;
    right: -350px;
    top: 0;
    height: 100vh;
    width: 300px;
    z-index: 9999;
    box-shadow: -11px 0 45px 15px rgb(60 60 60 / 60%);
    -webkit-animation: slide 0.2s forwards;
    animation: slide 0.2s forwards;
}

.booking-mini-modal .card {
    margin-bottom: 0;
    height: 100%;
    border-left: 4px solid #037be4;
    border-radius: 0;
}

.ui.mini.input {
    font-size: .78571429em !important;
}

.input.size-3 {
    width: 80px !important;
    margin-right: 10px !important;
}

.input.size-5 {
    width: 140px !important;
    margin-right: 10px !important;
}

.zoom-8 {
    zoom: 0.8;
}

.active-installation-box .card-title {
    font-size: 1rem;
}

.color-1eb230 {
    color: #1eb230;
}
.color-c7141a {
    color: #c7141a;
}
.color-c3ad00 {
    color: #c3ad00;
}
.color-037be4 {
    color: #037be4;
}
.color-666666 {
    color: #666666;
}
.color-ffc107 {
    color: #ffc107;
}
.color-FFCC00 {
    color: #FFCC00;
}
.color-1E8449 {
    color: #1E8449;
}
.color-58D68D {
    color: #58D68D;
}
.color-ee82ee {
    color: #ee82ee;
}

.ui.standard.modal {
    height: auto;
}

.failed-coordinate-imports {
    max-height: 400px;
}

.imageAttachment {
    min-width: 35%;
    max-width: 100%;
    max-height: 600px;
}

.centered{
    text-align: center;
}

.hover-pointer{
    cursor: pointer;
}

.fa-rotate-135 {
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
}

.checkbox-fix-position {
    margin-top: 5px;
}

.link {
    cursor: pointer;
}

@-webkit-keyframes slide {
    100% { right: 0; }
}

@keyframes slide {
    100% { right: 0; }
}


@media only screen and (max-width: 575px) {
    .full-height:not(.map-card),
    .half-height { 
        height: auto !important;
        min-height: 200px;
    }
}

</style>
