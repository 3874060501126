<script>
import EventBus from '../../EventBus'

export default {
    name: 'MapMarker',

    props: {
        lat: {
            type: Number,
            required: true
        },
        lng: {
            type: Number,
            required: true
        },
        icon: {
            type: String, // Font awesome icon
            default: null
        },
        color: {
            type: String,
            default: null
        },
        clusterGroup: {
            type: String,
            default: 'default'
        },
        id: {
            type: String,
            default: null
        },
        title: {
            type: String,
            default: null
        },
        active: {
            type: Boolean,
            default: null
        },
        showPending: {
            type: Boolean,
            default: null
        },
        showOnHold: {
            type: Boolean,
            default: null
        },
        isOnHold: {
            type: Boolean,
            defualt: false
        },
        matchSearch: {
            type: Boolean,
            default: null
        },
        eventListenerName: {
            type: String,
            default: null
        },
        eventTriggerName: {
            type: String,
            default: null
        }
    },

    data() {
        return {
            marker: null,
            prevClusterGroup: null
        }
    },

    methods: {
        toggleBounceAnimation() {
            if (this.marker === null) return
            if (this.marker.getAnimation() !== null) {
                this.marker.setAnimation(null)
            } else {
                this.marker.setAnimation(window.google.maps.Animation.BOUNCE)
                setTimeout(() => {
                    this.toggleBounceAnimation()
                }, 200)
            }
        },

        handleOnClickMarker() {
            if (this.eventListenerName == this.eventTriggerName) {
                throw new Error('Event listener and trigger name cannot be the same.')
            }
            this.setMarkerInFocus()
            if (this.active) {
                EventBus.$emit(this.eventTriggerName, null)
            } else {
                EventBus.$emit(this.eventTriggerName, this.id)
            }
        },

        handleEventReceiver(id) {
            if (this.id != id) return
            this.setMarkerInFocus()
        },

        setMarkerInFocus() {
            if (!this.active) this.toggleBounceAnimation()

            this.$parent.$parent.getMap((map) => {
                map.setCenter({ lat: this.lat, lng: this.lng })

                if (this.$parent.$parent.mapUseAutoZoom) {
                    map.setZoom(12)
                }
            })
        },

        updateMarker() {
            if (this.prevClusterGroup != null) {
                this.$parent.$parent.getCluster(this.prevClusterGroup, (cluster) => {
                    cluster.removeMarker(this.marker)
                    this.marker.setMap(null)
                })
            }

            const _prevClusterGroup = this.prevClusterGroup // Becomes overwritten in `addMarker()` call.
            this.addMarker()
            
            EventBus.$emit('map-marker-update', {
                icon: this.icon,
                color: this.color,
                active: this.active,
                title: this.title,
                clusterGroup: this.clusterGroup,
                prevClusterGroup: _prevClusterGroup
            })
        },
    
        addMarker() {
            this.$parent.$parent.getMap((map) => {

                if (!this.showPending && this.clusterGroup == 'pending') {
                    return
                }

                if (!this.showOnHold && this.isOnHold) {
                    return
                }

                if (!this.matchSearch) {
                    return
                }

                let _color;
                if (this.color == null && this.clusterGroup != null) {
                    if (this.$parent.$parent.clusterGroups){
                        _color = this.$parent.$parent.clusterGroups[this.clusterGroup].color
                    } else {
                        _color = this.$parent.clusterGroups[this.clusterGroup].color
                    }
                } else if (this.color != null && this.clusterGroup == null) {
                    _color = this.color
                } else { // Both is null
                    _color = '#F00'
                }

                let _icon;
                if (this.icon == null && this.clusterGroup != null) {
                    if (this.$parent.$parent.clusterGroups){
                        _icon = this.$parent.$parent.clusterGroups[this.clusterGroup].icon
                    } else {
                        _icon = this.$parent.clusterGroups[this.clusterGroup].icon
                    }
                } else if (this.icon != null && this.clusterGroup == null) {
                    _icon = this.icon
                } else { // Both is null
                    _icon = 'fas fa-home'
                }

                this.marker = new window.Marker({
                    position: { lat: this.lat, lng: this.lng },
                    icon: {
                        path: window.MAP_PIN,
                        fillColor: _color,
                        fillOpacity: 1,
                        strokeColor: '',
                        strokeWeight: 0
                    },
                    animation: null,
                    title: this.title,
                    map_icon_label: `<i class="${_icon}"></i>`,
                    map: map
                })

                this.marker.addListener('click', this.handleOnClickMarker)

                this.$parent.$parent.getCluster(this.clusterGroup, (cluster) => {
                    cluster.addMarker(this.marker)
                    this.prevClusterGroup = this.clusterGroup
                })
            })

            EventBus.$on(this.eventListenerName, this.handleEventReceiver.bind(this))
        }
    },

    mounted() {
        this.addMarker()
    },

    beforeDestroy() {
        if (this.marker != null) {
            this.marker.setMap(null)
            this.$parent.$parent.getCluster(this.clusterGroup, (cluster) => {
                cluster.removeMarker(this.marker)
            })
            window.google.maps.event.clearInstanceListeners(this.marker)
            this.marker = null
        }
    },

    watch: {
        'active': 'updateMarker',
        'showPending': 'updateMarker',
        'showOnHold' : 'updateMarker',
        'matchSearch': 'updateMarker',
        'clusterGroup': 'updateMarker',
    },

    render() {
        return null
    }
}
</script>