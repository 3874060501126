<template>
    <div class="row">
        <div class="col-sm-6">
            <!-- Opgave typer -->
            <sui-table>
                <sui-table-header>
                    <sui-table-row>
                        <sui-table-header-cell colspan="2">Opgave typer</sui-table-header-cell>
                    </sui-table-row>
                </sui-table-header>
                <sui-table-body>
                    <sui-table-row>
                        <sui-table-cell style="width: 55px;"><i title="Dette er en driftsag" class="fa-solid fa-bug" style="color: #FFAD00;"></i></sui-table-cell>
                        <sui-table-cell>Driftsag</sui-table-cell>
                    </sui-table-row>
                    <sui-table-row>
                        <sui-table-cell style="width: 55px;"><i title="Dette er en besigtigelsesopgave" class="fa-solid fa-file-contract" style="color: #C182C1;"></i></sui-table-cell>
                        <sui-table-cell>Besigtigelse inden installation</sui-table-cell>
                    </sui-table-row>
                    <sui-table-row>
                        <sui-table-cell style="width: 55px;"><i title="Dette er en installationsopgave" class="fa-solid fa-earth-europe" style="color: #C182C1;"></i></sui-table-cell>
                        <sui-table-cell>Installation eller udskiftning af CPE</sui-table-cell>
                    </sui-table-row>
                    <sui-table-row>
                        <sui-table-cell style="width: 55px;"><i title="Dette er en patch opgave" class="fas fa-plug" style="color: green;"></i></sui-table-cell>
                        <sui-table-cell>Ompatchning uden skift af CPE</sui-table-cell>
                    </sui-table-row>
                    <sui-table-row>
                        <sui-table-cell style="width: 55px;"><i title="Dette er en udvidet installation" class="fa-solid fa-router" style="color: #C182C1;"></i></sui-table-cell>
                        <sui-table-cell>Udvidet installation uden skift af CPE</sui-table-cell>
                    </sui-table-row>
                    <sui-table-row>
                        <sui-table-cell style="width: 55px;"><i title="Denne installation passer ikke på predifinerede opgavetyper" class="fa-solid fa-question" style="color: tomato;"></i></sui-table-cell>
                        <sui-table-cell>Systemet kunne ikke kende opgavetypen</sui-table-cell>
                    </sui-table-row>
                </sui-table-body>
            </sui-table>

            <!-- Primære status'er -->
            <sui-table>
                <sui-table-header>
                    <sui-table-row>
                        <sui-table-header-cell colspan="2">Primære status'er</sui-table-header-cell>
                    </sui-table-row>
                </sui-table-header>
                <sui-table-body>
                    <sui-table-row>
                        <sui-table-cell style="width: 55px;">
                            <i class="fas fa-check" title="Alle opgaver er afsluttede" style="color: green;"></i>
                        </sui-table-cell>
                        <sui-table-cell>Alle FiberLAN's opgaver på installationen er lukkede</sui-table-cell>
                    </sui-table-row>
                    <sui-table-row>
                        <sui-table-cell style="width: 55px;">
                            <i class="fas fa-times" title="Mindst én opgave på kunden er 'Closed Incomplete', 'Closed Skipped' eller 'Canceled'" style="color: red"></i>
                        </sui-table-cell>
                        <sui-table-cell>En eller flere af FiberLAN's opgaver står som 'Closed Incomplete', 'Closed Skipped' eller 'Canceled'</sui-table-cell>
                    </sui-table-row>
                    <sui-table-row>
                        <sui-table-cell style="width: 55px;">
                            <span style="padding: 0 3px 0 3px"
                                title="Mindst én opgave på kunden er 'On Hold' (Ingen årsag valgt)"
                            >
                                <i opt="nestedIcon" class="fas fa-pause" style="color: dimgrey;"></i>
                            </span>
                        </sui-table-cell>
                        <sui-table-cell>En eller flere af FiberLAN's opgaver står 'On Hold'</sui-table-cell>
                    </sui-table-row>
                    <sui-table-row>
                        <sui-table-cell style="width: 55px;">
                            <span style="padding: 0 3px 0 3px" title="Mindst én opgave på kunden er 'On Hold' (Afventer opringning)">
                                <i opt="nestedIcon" class="fas fa-pause" style="color: dimgrey;"></i>
                                <sub><small>
                                    <i opt="nestedIcon" class="fas fa-house-user text-primary"></i>
                                </small></sub>
                            </span>
                        </sui-table-cell>
                        <sui-table-cell>Mindst én opgave er 'On Hold Awaiting Caller' (Afventer opringning)</sui-table-cell>
                    </sui-table-row>
                    <sui-table-row>
                        <sui-table-cell style="width: 55px;">
                            <span style="padding: 0 3px 0 3px"
                                title="Mindst én opgave på kunden er 'On Hold' (Afventer eksternt problem)"
                            >
                                <i opt="nestedIcon" class="fas fa-pause" style="color: dimgrey;"></i>
                                <sub><small>
                                    <i opt="nestedIcon" class="fas fa-exclamation-triangle" style="color: tomato"></i>
                                </small></sub>
                            </span>
                        </sui-table-cell>
                        <sui-table-cell>Mindst én opgave er 'On Hold Awaiting Problem' (Afventer eksternt problem)</sui-table-cell>
                    </sui-table-row>
                    <sui-table-row>
                        <sui-table-cell style="width: 55px;">
                            <span style="padding: 0 3px 0 3px"
                                title="Mindst én opgave på kunden er 'On Hold' (Afventer eksternt problem)"
                            >
                                <i opt="nestedIcon" class="fas fa-pause" style="color: dimgrey;"></i>
                                <sub><small>
                                    <i opt="nestedIcon" class="fas fa-building" style="color: violet"></i>
                                </small></sub>
                            </span>
                        </sui-table-cell>
                        <sui-table-cell>Mindst én opgave er 'On Hold Awaiting Vendor' (Afventer service-provider)</sui-table-cell>
                    </sui-table-row>
                    <sui-table-row>
                        <sui-table-cell style="width: 55px;">
                            <span style="padding: 0 3px 0 3px"
                                title="Mindst én opgave på kunden er 'On Hold' (Afventer projektering)"
                            >
                                <i opt="nestedIcon" class="fas fa-pause" style="color: dimgrey;"></i>
                                <sub><small>
                                    <i opt="nestedIcon" class="fas fa-sync" style="color: gold"></i>
                                </small></sub>
                            </span>
                        </sui-table-cell>
                        <sui-table-cell>Mindst én opgave er 'On Hold Awaiting Change' (Afventer projektering)</sui-table-cell>
                    </sui-table-row>
                    <sui-table-row>
                        <sui-table-cell style="width: 55px;">
                            <span style="padding: 0 3px 0 3px"
                                title="Mindst én opgave på kunden er 'On Hold' (Afventer EnergiFyn)"
                            >
                                <i opt="nestedIcon" class="fas fa-pause" style="color: dimgrey;"></i>
                                <sub><small>
                                    <i opt="nestedIcon" class="fas fa-question" style="color: gold"></i>
                                </small></sub>
                            </span>
                        </sui-table-cell>
                        <sui-table-cell>Mindst én opgave er 'On Hold Awaiting Request' (Afventer EnergiFyn)</sui-table-cell>
                    </sui-table-row>
                </sui-table-body>
            </sui-table>
        </div>

        <div class="col-sm-6">
            <!-- Vedr. aftaler og bookinger -->
            <sui-table>
                <sui-table-header>
                    <sui-table-row>
                        <sui-table-header-cell colspan="2">Vedr. aftaler og bookinger</sui-table-header-cell>
                    </sui-table-row>
                </sui-table-header>
                <sui-table-body>
                    <sui-table-row>
                        <sui-table-cell style="width: 55px;"><i class="fa-solid fa-badge-check" style="color: black;"></i></sui-table-cell>
                        <sui-table-cell>Genvej til opgave-afslutning (tryk for at gå direkte til afslutnings-formular)</sui-table-cell>
                    </sui-table-row>
                    <sui-table-row>
                        <sui-table-cell style="width: 55px;"><i class="fa-solid fa-flag" style="color: tomato;" title="Husk at læse noterne inden du kører ud til kunden" ></i></sui-table-cell>
                        <sui-table-cell>Aftalen er manuel markeret med flag - læs noten for at se hvorfor</sui-table-cell>
                    </sui-table-row>
                    <sui-table-row>
                        <sui-table-cell style="width: 55px;"><i class="fa-solid fa-lock" style="color: dimgray;" title="Aftalen er låst for ombooking"></i></sui-table-cell>
                        <sui-table-cell>Aftalen er låst, og må ikke bookes om.</sui-table-cell>
                    </sui-table-row>
                    <sui-table-row>
                        <sui-table-cell style="width: 55px;"><i title="Aftale kladde ikke varslet" class="fas fa-calendar-edit" style="color: #FFCC00;"></i></sui-table-cell>
                        <sui-table-cell>Den aftale der ligger i vores system er ikke varslet</sui-table-cell>
                    </sui-table-row>
                    <sui-table-row>
                        <sui-table-cell style="width: 55px;"><i title="Aftale ikke korrekt synkroniseret med EFB" class="fas fa-calendar-exclamation" style="color: #FFAD00;"></i></sui-table-cell>
                        <sui-table-cell>Den aftale der ligger i vores system er ikke synkroniseret til Virkplan</sui-table-cell>
                    </sui-table-row>
                    <sui-table-row>
                        <sui-table-cell style="width: 55px;">
                            <i class="fa-solid fa-square-1" title="<antal> forgæves besøg" style="color: tomato;"></i>
                        </sui-table-cell>
                        <sui-table-cell>Viser antallet af forgæves besøg der er registreret på installationen (vises for mellem 1 og 10)</sui-table-cell>
                    </sui-table-row>
                    <sui-table-row>
                        <sui-table-cell style="width: 55px;">
                            <i class="fa-solid fa-phone" title="Tekniker bedes ringe til kunden <tid> før ankomst" style="color: #FF9A14"></i>
                        </sui-table-cell>
                        <sui-table-cell>Markérer, at kunden ønsker en opringning fra montøren inden ankomst</sui-table-cell>
                    </sui-table-row>
                    <sui-table-row>
                        <sui-table-cell style="width: 55px;">
                            <i class="fa-solid fa-user-xmark" style="color: tomato;" :title="`[Navn] er booket til denne aftale\n[Navn] er assignet opgaven i PilotBI`"></i>
                        </sui-table-cell>
                        <sui-table-cell>Advarsel, når medarbejderen på en aftale i FiberTeam ikke matcher den assignee EFB kan se i PilotBI</sui-table-cell>
                    </sui-table-row>
                    <sui-table-row>
                        <sui-table-cell style="width: 55px;">
                            <i class="fa-solid fa-bell" style="color: #2D2D2D" title="Sender SMS- og Email-påmindelse, til ovenstående kontaktinfo, dagen før aftalen kl 9"></i>
                        </sui-table-cell>
                        <sui-table-cell>Viser, at der sendes påmindelser til kunden dagen før kl 9</sui-table-cell>
                    </sui-table-row>
                    <sui-table-row>
                        <sui-table-cell style="width: 55px;">
                            <i class="fa-solid fa-paper-plane" style="color: #2D2D2D" title="Sender SMS- og Email booking-bekræftigelse til kunde på ovenstående kontaktinfo."></i>
                        </sui-table-cell>
                        <sui-table-cell>Viser, at der bliver sendt en bekræftigelse til kunden</sui-table-cell>
                    </sui-table-row>
                    <sui-table-row>
                        <sui-table-cell style="width: 55px;">
                            <i class="fa-solid fa-file-invoice-dollar" style="color: #158FAD" title="Ektra Arbejde"></i>
                        </sui-table-cell>
                        <sui-table-cell>Udført ekstra-arbejde</sui-table-cell>
                    </sui-table-row>
                    <sui-table-row>
                        <sui-table-cell style="width: 55px;">
                            <i title="Gemmer aftale i PilotBI / Virkplan">
                                <img src="../../../public/assets/icons/energi-fyn-e.png" style="width: 15px; height: 15px;">
                            </i>
                        </sui-table-cell>
                        <sui-table-cell>Markérer, at aftalen sendes til Energi Fyn</sui-table-cell>
                    </sui-table-row>
                    <sui-table-row>
                        <sui-table-cell style="width: 55px;">
                            <i class="fa-kit fa-solid-list-clock pink-1" title="Føj kunden til listen af kunder, vi kan kontakte i tilfælde af afbud."></i>
                        </sui-table-cell>
                        <sui-table-cell>Markérer, at kunden er tilføjet til listen af mulige kunder vi kan ringe til, hvis et hul skulle opstå i planlægningen</sui-table-cell>
                    </sui-table-row>
                    <sui-table-row>
                        <sui-table-cell style="width: 55px;">
                            <i class="fa-solid fa-eye-slash" title="Intern note, kan ikke ses af andre end FiberLAN"></i>
                        </sui-table-cell>
                        <sui-table-cell>Markérer, at noten er intern og kun kan ses af FiberLAN</sui-table-cell>
                    </sui-table-row>
                    <sui-table-row>
                        <sui-table-cell style="width: 55px;">
                            <i class="fa-solid fa-house-building" title="Installation er boligforening" style="color: #007bff"></i>
                        </sui-table-cell>
                        <sui-table-cell>Markérer, at installationen er boligforening</sui-table-cell>
                    </sui-table-row>
                </sui-table-body>
            </sui-table>

            <!-- Delopgave status'er -->
            <sui-table>
                <sui-table-header>
                    <sui-table-row>
                        <sui-table-header-cell colspan="2">Delopgave status'er</sui-table-header-cell>
                    </sui-table-row>
                </sui-table-header>
                <sui-table-body>
                    <sui-table-row>
                        <sui-table-cell style="width: 55px;">
                            <i class="fas fa-wind-warning" 
                                title="Kundefiberen er ikke blæst" 
                                style="color:tomato;"></i>
                        </sui-table-cell>
                        <sui-table-cell>Installationen er ikke markeret som blæst</sui-table-cell>
                    </sui-table-row>
                    <sui-table-row>
                        <sui-table-cell style="width: 55px;">
                            <span class="fa-stack fa-1x" style="font-size: 0.63em"> 
                                <i class="far fa-calendar fa-stack-2x" style="color: #2185d0;"></i> 
                                <i class="fas fa-wind-warning fa-stack-1x on-calendar" 
                                    title="Kundefiberen skal blæses på dagen for installation" 
                                    style="color:tomato;"></i>
                            </span>
                        </sui-table-cell>
                        <sui-table-cell>Blæsning udføres på installationsdatoen</sui-table-cell>
                    </sui-table-row>
                    <sui-table-row>
                        <sui-table-cell style="width: 55px;">
                            <i class="fad fa-wind-warning" :title="'Kundefiberen er ikke blæst\nDu har taget ansvaret'" 
                                style="--fa-primary-color: tomato; --fa-secondary-color: #2185d0;"></i>
                        </sui-table-cell>
                        <sui-table-cell>Installationen er ikke markeret som blæst, du står som ansvarlig</sui-table-cell>
                    </sui-table-row>
                    <sui-table-row>
                        <sui-table-cell style="width: 55px;">
                            <span class="fa-stack fa-1x" style="font-size: 0.63em"> 
                                <i class="far fa-calendar fa-stack-2x" style="color: #2185d0;"></i> 
                                <i class="fad fa-wind-warning fa-stack-1x on-calendar" :title="'Kundefiberen skal blæses på dagen\nDu har taget ansvaret'" 
                                    style="--fa-primary-color: tomato; --fa-secondary-color: #2185d0;"></i>
                            </span>
                        </sui-table-cell>
                        <sui-table-cell>Blæsning udføres på installationsdatoen, og du står som ansvarlig</sui-table-cell>
                    </sui-table-row>
                    <sui-table-row>
                        <sui-table-cell style="width: 55px;">
                            <i class="fad fa-wind-warning" 
                                title="Kundefiberen er ikke blæst\n<navn> har ansvaret" 
                                style="--fa-primary-color: tomato; --fa-secondary-color: darkorange;"></i>
                        </sui-table-cell>
                        <sui-table-cell>Installationen er ikke markeret som blæst, en kollega står som ansvarlig</sui-table-cell>
                    </sui-table-row>
                    <sui-table-row>
                        <sui-table-cell style="width: 55px;">
                            <span class="fa-stack fa-1x" style="font-size: 0.63em"> 
                                <i class="far fa-calendar fa-stack-2x" style="color: #2185d0;"></i> 
                                <i class="fad fa-wind-warning fa-stack-1x on-calendar" 
                                    title="'Kundefiberen skal blæses på dagen for installation\n <navn> har ansvaret'" 
                                    style="--fa-primary-color: tomato; --fa-secondary-color: darkorange;"></i>
                            </span>
                        </sui-table-cell>
                        <sui-table-cell>Blæsning udføres på installationsdatoen, en kollega står som ansvarlig</sui-table-cell>
                    </sui-table-row>
                    <sui-table-row>
                        <sui-table-cell style="width: 55px;">
                            <i class="fas fa-compress-alt fa-rotate-45" 
                                title="Kundefiberen er ikke splidset i skabet" 
                                style="color: tomato;"></i>
                        </sui-table-cell>
                        <sui-table-cell>Kundefiberen er ikke markeret som splidset (Vises kun hvis den er markeret som blæst)</sui-table-cell>
                    </sui-table-row>
                    <sui-table-row>
                        <sui-table-cell style="width: 55px;">
                            <span class="fa-stack fa-1x" style="font-size: 0.63em" 
                                title="Kundefiberen skal splidses i skabet på dagen for installation\n<navn> har ansvaret">
                                <i class="far fa-calendar fa-stack-2x" style="color: #2185d0;"></i>
                                <i class="fas fa-compress-alt fa-rotate-45 fa-stack-1x on-calendar" style="color: tomato;"></i>
                            </span>
                        </sui-table-cell>
                        <sui-table-cell>Kundefiberen splidses på dagen for installation (Vises kun hvis den er markeret som blæst)</sui-table-cell>
                    </sui-table-row>
                    <sui-table-row>
                        <sui-table-cell style="width: 55px;">
                            <i title="Kundefiberen er ikke patchet i teknikhuset\n<navn> har ansvaret" 
                                class="fas fa-plug" style="color:tomato;"></i>
                        </sui-table-cell>
                        <sui-table-cell>Fiberen er ikke markeret som patchet i teknikhus</sui-table-cell>
                    </sui-table-row>
                    <sui-table-row>
                        <sui-table-cell style="width: 55px;">
                            <span class="fa-stack fa-1x" style="font-size: 0.63em" 
                                title="Kundefiberen skal patches i teknikhuset på dagen for installation\n<navn> har ansvaret">
                                <i class="far fa-calendar fa-stack-2x" style="color: #2185d0;"></i>
                                <i class="fas fa-plug fa-stack-1x on-calendar" style="color: tomato;"></i>
                            </span>
                        </sui-table-cell>
                        <sui-table-cell>Fiberen patches på dagen for installation</sui-table-cell>
                    </sui-table-row>
                </sui-table-body>
            </sui-table>
        </div>
        
    </div>
</template>
<script>
export default {
    name: 'iconLegend',
}
</script>
<style scoped>
.fa-calendar-edit {
    color: #FFCC00;
}
.fa-rotate-45 {
    transform: rotate(45deg);
}
.on-calendar {
    margin-top: .2em;
}

 i:not([opt="nestedIcon"]) {
    padding: 0 3px;
} 

</style>