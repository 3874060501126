<template>
    <div>
        <sui-modal
            size="tiny"
            id="editTemplateElementsModal"
            :open="isOpen"
        >
            <sui-modal-header>
                {{ modalHeader }}
            </sui-modal-header>
            <sui-modal-content scrolling style="max-height: 70svh;">

                <sui-form v-if="data.mode === 'createComponentGroup' || data.mode === 'editComponentGroup'">
                    <sui-form-field>
                        <label> Navn på Overskrift </label>
                        <input 
                        type="text"
                        placeholder="Overskrift (Hold tom for at indsætte linje inddeling)" 
                        v-model="groupData.headerText"  
                    /></sui-form-field>

                    <sui-form-field>
                        <sui-checkbox 
                            label="Skal overskrift kunne foldes sammen?" 
                            v-model="groupData.accordion"
                        ></sui-checkbox>
                    </sui-form-field>

                    <sui-form-field>
                        <sui-checkbox 
                            :disabled="!groupData.accordion"
                            label="Default udfoldet?" 
                            v-model="groupData.accordionOpen"
                        ></sui-checkbox>
                    </sui-form-field>

                </sui-form>

                <sui-form v-if="data.mode === 'createComponent' || data.mode === 'editComponent'">
                    <sui-form-field>
                        <sui-dropdown
                            v-if="data.mode === 'createComponent'"
                            placeholder="Vælg komponent"
                            selection
                            search
                            v-model="selectedDropdownOption"
                            :options="dropdownOptions"
                        ></sui-dropdown>
                    </sui-form-field>

                    <sui-header v-if="data.mode === 'editComponent' && selectedDropdownOption"> 
                        {{ dropdownOptions.find(option => option.value === selectedDropdownOption).text }} 
                    </sui-header>

                    <div v-if="selectedDropdownOption && !neededParams.length">
                        <p> Ingen yderligere handling krævet </p>
                    </div>

                    <div 
                        v-if="selectedDropdownOption === null || !neededParams.length"
                        :class="classes(
                            currentMedia.type == 'Desktop' && 'placeholder-desktop',
                            currentMedia.type == 'Mobile' && 'placeholder-mobile'
                            )
                    "></div>

                    <div v-if="selectedDropdownOption && neededParams.length">

                        
                        <!-- documentTitle -->
                        <sui-form-field v-if="neededParams.includes('documentTitle')">
                            <label> Default Overskrift </label>
                            <input 
                                type="text"
                                placeholder="Overskrift på feltet" 
                                v-model="params['documentTitle']"
                            />
                        </sui-form-field>

                        <!-- headerText -->
                        <sui-form-field v-if="neededParams.includes('headerText')">
                            <label> Default Overskrift </label>
                            <input 
                                type="text"
                                placeholder="Overskrift på feltet" 
                                v-model="params['headerText']"
                            />
                        </sui-form-field>

                        <!-- headerHierarchy -->
                        <sui-form-field v-if="neededParams.includes('headerHierarchy')">
                            <label> Overskrift niveau </label>
                            <div v-for="option in headerHierarchyOptions" :key="option.text"> 
                                <sui-form-field>
                                    <sui-checkbox radio
                                        name="header-level" 
                                        :label="option.text" 
                                        :value="option.value" 
                                        v-model="params['headerHierarchy']"
                                    />
                                </sui-form-field>
                            </div>
                        </sui-form-field>

                        <!-- fieldTitle -->
                        <sui-form-field v-if="neededParams.includes('fieldTitle')">
                            <label> Overskrift på komponentet </label>
                            <input 
                                type="text"
                                placeholder="Overskrift på komponentet" 
                                v-model="params['fieldTitle']"
                            />
                        </sui-form-field>

                        <!-- fieldPlaceholder -->
                        <sui-form-field v-if="neededParams.includes('fieldPlaceholder')">
                            <label> Placeholder </label>
                            <input 
                                type="text"
                                placeholder="Placeholder på feltet" 
                                v-model="params['fieldPlaceholder']"
                            />
                        </sui-form-field>

                        <!-- pageBreakBefore -->
                        <sui-form-field v-if="neededParams.includes('pageBreakBefore')">
                            <sui-checkbox 
                                label="Skal der indsættes tvungen sideskift før komponentet?" 
                                v-model="params['pageBreakBefore']"
                            ></sui-checkbox>
                        </sui-form-field>

                        <!-- includeInPdf -->
                        <sui-form-field v-if="neededParams.includes('includeInPdf')">
                            <sui-checkbox 
                                label="Skal komponentet inkluderes i PDF?" 
                                v-model="params['includeInPdf']"
                            ></sui-checkbox>
                        </sui-form-field>

                        <!-- includeInForm -->
                        <sui-form-field v-if="neededParams.includes('includeInForm')">
                            <sui-checkbox 
                                label="Skal komponentet inkluderes i formular?" 
                                v-model="params['includeInForm']"
                            ></sui-checkbox>
                        </sui-form-field>

                        <!-- readOnly -->
                        <sui-form-field v-if="neededParams.includes('readOnly')">
                            <sui-checkbox 
                                label="Skal komponentet være skrivebeskyttet?" 
                                v-model="params['readOnly']"
                            ></sui-checkbox>
                        </sui-form-field>

                        <!-- required -->
                        <sui-form-field v-if="neededParams.includes('required')">
                            <sui-checkbox 
                                label="Er komponenten påkrævet?" 
                                v-model="params['required']"
                            ></sui-checkbox>
                        </sui-form-field>

                        <!-- isShortText -->
                        <sui-form-field v-if="neededParams.includes('isShortText')">
                            <sui-checkbox 
                                label="Er teksten kort?" 
                                v-model="params['isShortText']"
                            ></sui-checkbox>
                        </sui-form-field>

                        <!-- showHeaderInPdf -->
                        <sui-form-field v-if="neededParams.includes('showHeaderInPdf')">
                            <sui-checkbox 
                                label="Skal overskriften vises i PDF?" 
                                v-model="params['showHeaderInPdf']"
                            ></sui-checkbox>
                        </sui-form-field>

                        <!-- allowMultiple -->
                        <sui-form-field v-if="neededParams.includes('allowMultiple')">
                            <sui-checkbox 
                                label="Må der tilføjes mere end et billede?" 
                                v-model="params['allowMultiple']"
                            ></sui-checkbox>
                        </sui-form-field>

                        <!-- list -->
                        <sui-form-field v-if="neededParams.includes('list')">
                            <sui-divider fitted/>
                            <label> Ja / Nej liste </label>
                            <div v-for="(item, index) in params['list']" :key="index">
                                <sui-form-fields>
                                    <sui-form-field width="thirteen">
                                        <input 
                                            type="text"
                                            placeholder="Vist tekst" 
                                            v-model="item.text"  
                                        />
                                    </sui-form-field>
                                    <sui-button
                                        style="padding: 14px;"
                                        :disabled="index === 0"
                                        title="Tilføj punkt"
                                        @click.prevent="moveUp(params['list'], index)"
                                    ><i class="fa-solid fa-up"></i></sui-button>
                                    <sui-button
                                        floated="right"
                                        style="padding: 14px;"
                                        title="Slet punkt"
                                        @click.prevent="params['list'].splice(index, 1)"
                                    ><i class="fa-solid fa-trash"></i></sui-button>
                                </sui-form-fields>

                                <sui-form-field style="margin-top: -15px;">
                                    <sui-checkbox 
                                        label="Default værdi for punkt"
                                        v-model="item.value"
                                    ></sui-checkbox>
                                </sui-form-field>
                                
                                <sui-form-fields style="margin-top: -15px;">
                                    <sui-form-field>
                                        <sui-checkbox 
                                            label="Inkluder i PDF?" 
                                            v-model="item.includeInPdf"
                                        ></sui-checkbox>
                                    </sui-form-field>
                                    <sui-form-field>
                                        <sui-checkbox 
                                            label="Inkluder i formular?" 
                                            v-model="item.includeInForm"
                                        ></sui-checkbox>
                                    </sui-form-field>
                                    <sui-form-field>
                                        <sui-checkbox 
                                            label="Påkrævet?" 
                                            v-model="item.required"
                                        ></sui-checkbox>
                                    </sui-form-field>
                                </sui-form-fields>
                            </div>

                            <sui-button 
                                title="Tilføj punkt"
                                size="mini"
                                style="margin-bottom: 5px;"
                                @click.prevent="addYesNoListItem()"
                            >Tilføj</sui-button>
                            <sui-divider fitted/>
                        </sui-form-field>

                        <!-- rightColumnWidth -->
                        <sui-form-field v-if="neededParams.includes('rightColumnWidth')">
                            <sui-divider fitted/>
                            <label> Højre kolonne bredde </label>
                            <div v-for="option in rightColumnWidthOptions" :key="option.text"> 
                                <sui-form-field>
                                    <sui-checkbox radio
                                        name="column-width" 
                                        :label="option.text" 
                                        :value="option.value" 
                                        v-model="selectedColumnWidth"
                                        @change="params['rightColumnWidth'] = selectedColumnWidth === 'number' ? '' : selectedColumnWidth"
                                    />
                                </sui-form-field>
                            </div>
                            <input 
                                v-if="selectedColumnWidth === 'number'"
                                style="margin-bottom: 5px;"
                                type="text"
                                maxlength="3"
                                placeholder="Bredde på højre kolonne (< 400)" 
                                v-model="params['rightColumnWidth']"
                            />
                            <sui-divider fitted/>
                        </sui-form-field>

                        <!-- text -->
                         <sui-form-field v-if="neededParams.includes('text')">
                            <label> Default Tekst </label>
                            <textarea 
                                placeholder="Tekst" 
                                v-model="params['text']"
                                rows="3" 
                            ></textarea>
                        </sui-form-field>

                        <!-- disclaimerText -->
                        <sui-form-field v-if="neededParams.includes('disclaimerText')">
                            <label> Disclaimer Tekst </label>
                            <textarea 
                                placeholder="Disclaimer tekst til underskrift" 
                                v-model="params['disclaimerText']"
                                rows="3" 
                            ></textarea>
                        </sui-form-field>



                        <!-- Options -->
                        <!-- <sui-form-field v-if="requiresOptions">
                            <label> Valgmuligheder </label>
                            <sui-form-fields v-for="(option, index) in neededParams[selectedDropdownOption].params.options" :key="index">
                                <sui-form-field>
                                    <input 
                                        type="text"
                                        placeholder="Vist tekst" 
                                        v-model="option.text"  
                                    />
                                </sui-form-field>
                                <sui-form-field style="margin-left: -7px;">
                                    <input 
                                        type="text"
                                        placeholder="Værdi" 
                                        v-model="option.value"  
                                    />
                                </sui-form-field>
                                <sui-button
                                    style="padding: 14px;"
                                    :disabled="index === 0"
                                    title="Tilføj valgmulighed"
                                    @click.prevent="moveUp(neededParams[selectedDropdownOption].params.options, index)"
                                ><i class="fa-solid fa-up"></i></sui-button>
                                <sui-button
                                    floated="right"
                                    style="padding: 14px;"
                                    :disabled="neededParams[selectedDropdownOption].params.options.length < 2"
                                    title="Slet valgmulighed"
                                    @click.prevent="onDeleteOptionClicked(index)"
                                ><i class="fa-solid fa-trash"></i></sui-button>
                            </sui-form-fields>
                            <sui-button 
                                title="Tilføj valgmulighed"
                                @click.prevent="onCreateOptionClicked()"
                            >Tilføj</sui-button>
                        </sui-form-field> -->

                        <!-- Main message -->
                        <!-- <sui-form-field v-if="requiresMainMessage">
                            <label> Besked </label>
                            <textarea 
                                placeholder="Besked" 
                                v-model="neededParams[selectedDropdownOption].params.messageBody"
                                rows="2" 
                            ></textarea>
                        </sui-form-field> -->

                        <!-- Bullets -->
                        <!-- <sui-form-field v-if="requiresBullets">
                            <label> Punkter </label>
                            <sui-form-fields v-for="(bullet, index) in neededParams[selectedDropdownOption].params.bullets" :key="index">
                                <sui-form-field width="thirteen">
                                    <input 
                                        type="text"
                                        placeholder="Punkt" 
                                        v-model="neededParams[selectedDropdownOption].params.bullets[index]"  
                                    />
                                </sui-form-field>
                                <sui-button
                                    style="padding: 14px;"
                                    :disabled="index === 0"
                                    title="Tilføj punkt"
                                    @click.prevent="moveUp(neededParams[selectedDropdownOption].params.bullets, index)"
                                ><i class="fa-solid fa-up"></i></sui-button>
                                <sui-button
                                    floated="right"
                                    style="padding: 14px;"
                                    title="Slet punkt"
                                    @click.prevent="neededParams[selectedDropdownOption].params.bullets.splice(index, 1)"
                                ><i class="fa-solid fa-trash"></i></sui-button>
                            </sui-form-fields>
                            <sui-button 
                                title="Tilføj valgmulighed"
                                @click.prevent="onCreateBulletClicked()"
                            >Tilføj</sui-button>
                        </sui-form-field> -->

                    </div>  
                </sui-form>
            </sui-modal-content>

            <sui-modal-actions>
                <sui-button @click.prevent="clickAway()">Annullér</sui-button>
                <sui-button @click.prevent="onSave()">Gem</sui-button> 
            </sui-modal-actions>

        </sui-modal>
       
    </div>                             
</template>

<script>
import EventBus from '../../EventBus'
import { Mixin } from '../../lib/Mixins/mixin'

import FormToPdfComponents from '../../lib/Enums/FormToPdfComponents'
    
export default {

        mixins: [
            Mixin,
            EventBus,
        ],

        enums: {
            FormToPdfComponents,
        },

        props: {
            isOpen: Boolean,
            data: Object,
        },

        data() {
            return {
                dropdownOptions: FormToPdfComponents.DROPDOWN_OPTIONS,

                groupData: {
                    accordion: false,
                    accordionOpen: false,
                    headerText: '',
                },

                neededParams: [],
                params: {},

                radioOptions: [
                    { text: 'Ja', value: '1' },
                    { text: 'Nej', value: '0' },
                ],

                headerHierarchyOptions: [
                    { text: 'Dokument Titel', value: 'pagetitle' },
                    { text: 'Hovedoverskrift', value: 'header' },
                    { text: 'Underoverskrift', value: 'subheader' },
                ],

                rightColumnWidthOptions: [
                    { text: 'Auto', value: 'auto' },
                    { text: '*', value: '*' },
                    { text: 'Vilkårligt tal', value: 'number' },
                ],
                selectedColumnWidth: 'auto',

                selectedDropdownOption: null,
            }
        },

        computed: {

            currentMedia(){
                return this.$root.$children[0].currentMedia
            },

            modalHeader() {
                if (this.data.mode === 'createComponentGroup'){
                    return "Tilføj gruppe til skalabelonen";
                }

                if (this.data.mode === 'editComponentGroup'){
                    return "Redigér gruppe i skalabelonen";
                }

                if (this.data.mode === 'createComponent'){
                    return "Tilføj komponent til skalabelonen";
                }

                return "Redigér komponent i skalabelonen";
            },
        },

        methods: {
            clickAway() {
                this.selectedDropdownOption = null;
                this.groupData = {
                    accordion: false,
                    accordionOpen: false,
                    headerText: '',
                }
                EventBus.$emit('event-edit-template-elements-modal-click-away');
            },

            addYesNoListItem(){
                this.params['list'].push({text: '', value: false, includeInPdf: true, includeInForm: true, required: false})
                this.$forceUpdate()
            },

            setDefalutValues() {

                if (this.neededParams.includes('documentTitle')){
                    this.params['documentTitle'] = ''
                }
                
                if (this.neededParams.includes('headerText')){
                    this.params['headerText'] = ''
                }
                
                if (this.neededParams.includes('headerHierarchy')){
                    if (this.selectedDropdownOption === FormToPdfComponents.HEADER){
                        this.params['headerHierarchy'] = 'header'
                    } else {
                        this.params['headerHierarchy'] = 'subheader'
                    }
                }

                if (this.neededParams.includes('fieldTitle')){
                    this.params['fieldTitle'] = ''
                }

                if (this.neededParams.includes('fieldPlaceholder')){
                    this.params['fieldPlaceholder'] = ''
                }

                if (this.neededParams.includes('pageBreakBefore')){
                    this.params['pageBreakBefore'] = false
                }

                if (this.neededParams.includes('includeInPdf')){
                    this.params['includeInPdf'] = true
                }

                if (this.neededParams.includes('includeInForm')){
                    this.params['includeInForm'] = true
                }

                if (this.neededParams.includes('readOnly')){
                    this.params['readOnly'] = false
                }

                if (this.neededParams.includes('required')){
                    this.params['required'] = false
                }

                if (this.neededParams.includes('rightColumnWidth')){
                    this.params['rightColumnWidth'] = 'auto'
                }

                if (this.neededParams.includes('text')){
                    this.params['text'] = ''
                }

                if (this.neededParams.includes('isShortText')){
                    this.params['isShortText'] = false
                }

                if (this.neededParams.includes('showHeaderInPdf')){
                    this.params['showHeaderInPdf'] = true
                }

                if (this.neededParams.includes('list')){
                    this.params['list'] = []
                    this.addYesNoListItem()
                }

                if (this.neededParams.includes('allowMultiple')){
                    this.params['allowMultiple'] = true
                }

                if (this.neededParams.includes('disclaimerText')){
                    this.params['disclaimerText'] = ''
                }
            },

            /**
             * Generel method for moving up an option in the options array
             * 
             * @param {Array} arr
             * @param {Number} index
             * @returns {Array}
             */
            moveUp(arr, index) {
                if (index === 0) return arr;
                let temp = arr[index];
                arr[index] = arr[index - 1];
                arr[index - 1] = temp;
                this.$forceUpdate();
            },

            onSave(){
                console.log('saving')

                if (this.data.mode === 'createComponentGroup'){
                    this.groupData.groupIndex = this.data.groupIndex
                    EventBus.$emit('event-add-component-group-to-template', this.groupData)
                    this.clickAway()
                }
                
                if (this.data.mode === 'editComponentGroup'){
                    this.groupData.groupIndex = this.data.groupIndex
                    console.log("groupData", this.groupData)
                    EventBus.$emit('event-edit-component-group-in-template', this.groupData)
                    this.clickAway()
                }

                if (this.data.mode === 'createComponent'){
                    console.log("params", this.params)

                    let payload = {
                        groupIndex: this.data.groupIndex,
                        componentIndex: this.data.componentIndex,
                        data: {
                            componentType: this.selectedDropdownOption,
                            data: this.params,
                        }
                    }

                    console.log('adding component with data:', payload)
                    EventBus.$emit('event-add-component-to-group-in-template', payload)
                    this.clickAway()
                }

                if (this.data.mode === 'editComponent'){
                    let payload = {
                        groupIndex: this.data.groupIndex,
                        componentIndex: this.data.componentIndex,
                        componentType: this.data.content.componentType,
                        component: this.params,
                    }

                    console.log('editing component with data:', payload)
                    EventBus.$emit('event-edit-component-in-template', payload)
                    this.clickAway()
                }
            },

            prePopulateForm(){
                console.log('all unique params', FormToPdfComponents.getAllUniqueParams())

                if (this.data.mode === 'editComponentGroup'){
                    this.groupData.accordion = this.cloneJson(this.data.content.accordion)
                    this.groupData.accordionOpen = this.cloneJson(this.data.content.accordionOpen)
                    this.groupData.headerText = this.cloneJson(this.data.content.headerText)
                }

                if (this.data.mode === 'editComponent'){
                    this.selectedDropdownOption = this.cloneJson(this.data.content.componentType)
                    console.log('prepopulating form with:', this.data.content.data)
                    this.params = this.cloneJson(this.data.content.data)

                    if (this.data.content.data?.headerText){
                        this.params['fieldTitle'] = this.data.content.data.headerText
                    }

                    if (this.data.content.componentType === FormToPdfComponents.IMAGE_LIST){
                        this.params['allowMultiple'] = this.data.content.data.allowMultiple
                    }

                    // if (this.data.content.method === 'addMessage'){
                    //     this.neededParams[this.selectedDropdownOption].params.messageBody = this.data.content.message.text.replace(/<br\/>/g, '\n')
                    //     this.neededParams[this.selectedDropdownOption].params.bullets = this.data.content.message.bullets || []
                    // }
                    
                    console.log('internal modal data:', this.params)
                }
            },

            onCreateOptionClicked(){
                this.neededParams[this.selectedDropdownOption].params.options.push({text: "", value: ""})
            },

            onCreateBulletClicked(){
                this.neededParams[this.selectedDropdownOption].params.bullets.push('')
                this.$forceUpdate()
            },

            onDeleteOptionClicked(index){
                this.neededParams[this.selectedDropdownOption].params.options.splice(index, 1)
            },
        },

        watch: {
        isOpen: {
            handler(open){
                if (open) {
                    this.prePopulateForm()
                }
            }
        },

        selectedDropdownOption: {
            handler(selectedDropdownOption){
                if (selectedDropdownOption){
                    this.neededParams = FormToPdfComponents.getComponentParamsFromCode(selectedDropdownOption)
                    console.log('neededParams', this.neededParams)

                    if (this.data.mode === 'createComponent'){
                        this.setDefalutValues()
                    }
                }
            }
        }
    }

    }
</script>

<style>
    .placeholder-desktop {
        height: 250px;
    }
    .placeholder-mobile {
        height: 150px;
    }
</style>