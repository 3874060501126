<template>
    <div>
        <div class="ui negative message">
            <div class="header">
                Warning!
            </div>
            <p>
                Modifies appointments and makes many calls to Pilotbi service.
            </p>
        </div>

        <strong>Description:</strong>
        <p>
            Will loop through appointments, and find referenceId based on attached task, to add the referenceId to the appointment
        </p>

        <hr />

        <sui-button primary size="tiny" :loading="isRunning" :disabled="isRunning" @click="triggerDry">Run Dry</sui-button>
        <sui-button negative size="tiny" :loading="isRunning" :disabled="isRunning" @click="triggerNormal">Run</sui-button>
    </div>
</template>
<script>
import { db } from '../../../firebase'
import { DataAPI } from '../../../lib/DataAPI'
import TaskCode from '../../../lib/Enums/TaskCode'

export default {
    name: 'ReferenceIdOnAppointments',

    mixins: [DataAPI],

    enums: {TaskCode},

    data() {
        return {
            isRunning: false,
            dryRun: false,

            appointments: [],
        }
    },

    methods: {
        log(message) {
            this.$emit('log', message)
        },

        async triggerDry() {
            this.dryRun = true
            await this.trigger()
        },

        async triggerNormal() {
            this.dryRun = false
            await this.trigger()
        },

        async trigger() {
            this.isRunning = true
            this.log(`----------------`)
            this.log(`Starting process`)

            await db.collection('Appointments').orderBy('State', 'asc').orderBy('TimeWindowString', 'desc').get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    this.appointments.push({
                        ...doc.data(),
                        id: doc.id
                    })
                })
            }).catch((error) => {
                this.log(`ERROR getting appointments: ${error}`)
            })
            let appointmentsLength = this.appointments.length
            this.log(`Got ${appointmentsLength} appointments from Firebase`)

            let counter = 0
            var errorCount = 0
            this.log(`-----Starting loop-----`)
            for (let app of this.appointments) {
                counter += 1
                this.log(`---Appointment ${counter}/${appointmentsLength} id: ${app.id}---`)
                let task = app.ProjectTasks.find(task => task.Code == TaskCode.BOOKKUNDE)
                if (!task) task = app.ProjectTasks[0]
                if (!task) {
                    this.log(`ERROR: skipping appointment containing no tasks: ${JSON.stringify(app)}`)
                    continue;
                }
                try {
                    task = await this.dataGetProjectTaskWithoutNotes(task.Id)
                } catch (error) {
                    this.log(`Error getting task: ${JSON.stringify(task)}\n${JSON.stringify(error)}`)
                    continue;
                }
                this.log(`Got full task ${task.number} with reference '${task.referenceId}', writing ref to appointment '${app.id}'`)
                let updateDoc = {ReferenceId: task.referenceId}
                if (this.dryRun){
                    this.log(`DryRun preventet writing to document '${app.id}': ${JSON.stringify(updateDoc)}`)
                } else {
                    db.collection('Appointments').doc(app.id).update(updateDoc).then(() => {
                        this.log(`Sucessfully wrote to doc '${app.id}': ${JSON.stringify(updateDoc)}`)
                    }).catch((error) => {
                        this.log(`ERROR writing to doc '${app.id}': ${JSON.stringify(error)}\nShould have written: ${JSON.stringify(updateDoc)}`)
                    })
                }
            }

            this.log('----------------')
            this.log(`FINISHED looping through ${counter}/${appointmentsLength} appointments with ${errorCount} errors.`)
            this.log('----------------')
            this.isRunning = false
        },
    }
}
</script>