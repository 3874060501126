import axios from 'axios'

export default class Google {

    BaseURL = 'https://maps.googleapis.com/maps/api/geocode/json?' //TODO: Move to firebase
    APIKey = 'AIzaSyBRhm7ZeFv-b8ALb0J4LIYBxK2burLpzG0' //TODO: Move to firebase

    AxiosConfig = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    async getGeoCoordinatesFromAddress(address) {
        if (!address) {
            return {
                lat: 0.0,
                lng: 0.0,
                provider: 'Error: no address',
                id: 'Error: no address',
            }
        }

        // if (address.road == 'Skåstrup Strand Øst') {
        //     throw new Error('Test failed address lookup')
        // }

        let encodedAddress = encodeURI(`${address.road} ${address.number}, ${address.zipcode}`)

        let response = await axios.get(`${this.BaseURL}address=${encodedAddress}&key=${this.APIKey}`, this.AxiosConfig)
        let location = response.data?.results?.[0]?.geometry?.location
        if (response.data.status == 'OK' && location.lat && location.lng) {
            return {
                lat: location.lat,
                lng: location.lng,
                provider: 'Google',
                id: response.data.results[0].place_id,
            }
        } else {
            throw new Error(`Failed to get coordinates for ${JSON.stringify(address)}. Google response: ${JSON.stringify(response.data)}`)
        }
    }

}
