export const phoneMixin = {
    methods: {
        checkIfMobile(no) { //According to danish number plan
            if (String(no).substring(0,3) == '+45'){
                no = no.substring(3)
            } else if (String(no).substring(0,4) == '0045'){
                no = no.substring(4)
            }
            if (String(no).length != 8){
                return false
            }
            if (String(no).substring(0,1) == '0' || String(no).substring(0,1) == '1') {
                return false
            } else {
                if (['20','21','22','23','24','25','26','27','28','29','30','31','40','41','42','50','51','52','53','60','61','71','81','91','92','93'].includes(String(no).substring(0,2))){
                    return true
                } else {
                    return ['342','344','345','346','347','348','349','356','357','359','362','365','366','389','398','431','441','462','466','468',
                    '472','474','476','478','485','486','488','489','493','494','495','496','498','499','542','543','545','551','552','556','571',
                    '572','573','574','577','579','584','586','587','589','597','598','627','629','641','649','658','662','663','664','665','667',
                    '692','693','694','697','771','772','782','783','785','786','788','789','826','827','829'].includes(String(no).substring(0,3))
                }   
            }
        },
        parsePhoneNumber(phoneNumber) {
            if (!phoneNumber) return ''

            let p = String(phoneNumber)
            p = p.replace(/\s+/g, '') //Removes spaces

            if (p.substring(0,2) == '00') {
                p = '+'+p.substring(2) //Replace leading '00' with '+'
            } else if (p.substring(0,1) != '+'){
                if(p.length == 8){ //For 8 digit numbers
                    p = '+45'+ p //Adds DK country code if missing
                }
                else if(p.length == 10 && p.substring(0,2) == '45') { //10 digit number starting with 45 (DK country code without the '+')
                    p = '+'+p // Append '+' to DK country code
                }
                else {
                    throw new Error('Missing country code for non-8-digit number')
                }
            }

            let numberRegex = new RegExp('^[0-9]*$')
            if (!numberRegex.test(p.substr(1))) {
                throw new Error('Phone number must contain only numbers from 0-9 and optionally a leading +')
            }

            // return "+4530559140"
            return p
        },

        /**
         * A function to consistently format phone-numbers in a user-friendly & readable way
         * @param {String || Number} phoneNumber phone number to be formatted
         * @returns {String} phone numer formatted as user friendly String
         */
        formatPhoneNumber(phoneNumber){
            let p = ''
            try {
                p = this.parsePhoneNumber(phoneNumber) //Throws an error when phoneNumer is invalid
            } catch (error) {
                console.error(error)
                return phoneNumber //Return invalid phoneNumer as is
            }
            if (p.substring(0,3) == '+45' && p.length == 11) { //Normal danish phone number
                return `(+45) ${p.substring(3,5)} ${p.substring(5,7)} ${p.substring(7,9)} ${p.substring(9)}` //Insert spaces for readability
            }
            else return p
        },
    }
}