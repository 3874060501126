<template>
    <sui-container>
        <edit-unit-work-unit-modal
            :isOpen="editUnitWorkUnitModalOpen"
            :user="user"
            :beforeChange="activeUnit"
            :baseData="unitWorkCompositeUnits ? unitWorkCompositeUnits.find(u => u.id == 'BaseData') : {}"
        />
        <edit-unit-work-composite-unit-modal
            :isOpen="editUnitWorkCompositeUnitModalOpen"
            :user="user"
            :beforeChange="activeUnit"
            :baseData="unitWorkCompositeUnits ? unitWorkCompositeUnits.find(u => u.id == 'BaseData') : {}"
        />
        <DataCard no-padding title="Arbejds-enheder" subtitle="Enheder defineret af EnergiFyn">
            <sui-table selectable striped>
                <sui-table-header>
                    <sui-table-row>
                        <sui-table-header-cell>Id</sui-table-header-cell>
                        <sui-table-header-cell>Navn</sui-table-header-cell>
                        <sui-table-header-cell text-align="right">Pris</sui-table-header-cell>
                        <sui-table-header-cell text-align="right">Tilsvarende timer</sui-table-header-cell>
                        <sui-table-header-cell>Opdateret</sui-table-header-cell>
                    </sui-table-row>
                </sui-table-header>
                <sui-table-body>
                    <sui-table-row v-for="unit in unitWorkUnits" :key="unit.id" @click="openEditUnitWorkUnitModal(unit)">
                        <sui-table-cell>{{unit.id}}</sui-table-cell>
                        <sui-table-cell>{{unit.Name}}</sui-table-cell>
                        <sui-table-cell text-align="right">{{Number(unit.UnitPrice).toLocaleString()}} kr /{{unit.Unit}}</sui-table-cell>
                        <sui-table-cell text-align="right">{{decimalToHourString(unit.EquivalentHours, true)}}</sui-table-cell>
                        <sui-table-cell>{{toUserFriendlyDate(unit.LastUpdated)}}</sui-table-cell>
                    </sui-table-row>
                </sui-table-body>
            </sui-table>
        </DataCard>
        <DataCard no-padding title="Sammensatte og del-enheder" subtitle="Enheder defineret af FiberLAN">
            <sui-table selectable striped>
                <sui-table-header>
                    <sui-table-row>
                        <sui-table-header-cell>Id</sui-table-header-cell>
                        <sui-table-header-cell>Navn</sui-table-header-cell>
                        <sui-table-header-cell text-align="right">Pris</sui-table-header-cell>
                        <sui-table-header-cell text-align="right">Tilsvarende timer</sui-table-header-cell>
                        <sui-table-header-cell>Opdateret</sui-table-header-cell>
                    </sui-table-row>
                </sui-table-header>
                <sui-table-body>
                    <template v-for="unit in unitWorkCompositeUnits">
                        <sui-table-row v-if="unit.id != 'BaseData'" :key="unit.id" @click="openEditUnitWorkCompositeUnitModal(unit)">
                            <sui-table-cell>{{unit.id}}</sui-table-cell>
                            <sui-table-cell>{{unit.Name}}</sui-table-cell>
                            <sui-table-cell text-align="right">{{calculateCompositeSum(unit.Parts, 'Price') || Number(Math.round(calculatePrice(unit.id, 1)*100)/100).toLocaleString()}} kr. /{{unit.Unit}}</sui-table-cell>
                            <sui-table-cell text-align="right">{{ calculateCompositeSum(unit.Parts, 'EquivalentHoursNum') ? decimalToHourString(calculateCompositeSum(unit.Parts, 'EquivalentHoursNum'), true) : decimalToHourString(calculateEquivalentHours(unit.id, 1), true)}}</sui-table-cell>
                            <sui-table-cell>{{toUserFriendlyDate(unit.LastUpdated)}}</sui-table-cell>
                        </sui-table-row>
                    </template>
                </sui-table-body>
            </sui-table>
        </DataCard>
    </sui-container>
</template>
<script>
// import { db } from '../../firebase'
import { Mixin } from '../../lib/Mixins/mixin'
import { DateMixin } from '../../lib/Mixins/dateMixin'
import { unitWorkMixin } from '../../lib/unitWorkMixin'
// import EventBus from '../../EventBus'
import EditUnitWorkUnitModal from '../../components/UnitWorkUnits/EditUnitWorkUnitModal.vue'
import EventBus from '../../EventBus'
import EditUnitWorkCompositeUnitModal from '../../components/UnitWorkUnits/EditUnitWorkCompositeUnitModal.vue'

export default {
    name:"UnitWorkUnitsList",

    mixins: [Mixin, DateMixin, unitWorkMixin],
    components: { EditUnitWorkUnitModal, EditUnitWorkCompositeUnitModal },

    data(){
        return {
            // unitWorkUnits: null,
            // unitWorkCompositeUnits: null,

            editUnitWorkUnitModalOpen: false,
            editUnitWorkCompositeUnitModalOpen: false,
            activeUnit: null,
        }
    },

    computed: {
        user(){
            return this.$parent.user
        }
    },

    methods: {
        openEditUnitWorkUnitModal(unit){
            this.activeUnit = unit
            this.editUnitWorkUnitModalOpen = true
        },
        openEditUnitWorkCompositeUnitModal(unit){
            this.activeUnit = unit
            this.editUnitWorkCompositeUnitModalOpen = true
        },
    },

    beforeMount() {
        EventBus.$on('edit-unit-work-unit-modal-close', () => {
            this.editUnitWorkUnitModalOpen = false
        })
        EventBus.$on('edit-unit-work-composite-unit-modal-close', () => {
            this.editUnitWorkCompositeUnitModalOpen = false
        })
    },

    mounted() {
        // this.$bind('unitWorkUnits', db.collection('UnitWorkUnits'))
        // this.$bind('unitWorkCompositeUnits', db.collection('UnitWorkCompositeUnits'))
    }
}
</script>
<style scoped>
    table.selectable tr:hover td {
        cursor: pointer;
    }
</style>