<template>
    <img v-if="base64ImgSrc" :src="base64ImgSrc" :alt="alternateText" class="profilePicture"/>
</template>
<script>
export default {
    name: 'UserProfilePhoto',

    props: {
        base64ImgSrc: String,
        alternateText: String,
    }
}
</script>
<style scoped>
.profilePicture {
    object-fit: cover;
    border-radius: 50%;
    aspect-ratio: 1/1;
    padding: 0 !important;
}
</style>