<template>
    <div>
        <edit-worker-modal
            :isModalOpen="addWorkerModalOpen"
            :worker="{}"
        />

        <div class="row">
            <div class="col-sm-12">
                <DataCard 
                    title="Brugere" 
                    no-padding
                    :actions="[{ type: 'button', title: 'Tilføj medarbejder', icon: 'plus', eventName: 'add-worker-button' }]"
                >
                    <sui-table selectable v-if="Object.values(workers).length" class="workers-table">
                        <sui-table-header>
                            <sui-table-row>
                                <sui-table-header-cell :width="14">Bruger data</sui-table-header-cell>
                                <sui-table-header-cell :width="2"></sui-table-header-cell>
                            </sui-table-row>
                        </sui-table-header>
                        <sui-table-body>
                            <!-- TODO: Convert detail page to modal and update @click -->
                            <sui-table-row v-for="(worker, index) in filteredWorkers" :key="index" @click="openWorkerDetails(worker)">
                                <sui-table-cell>
                                    <TableWorker
                                        :worker="worker"
                                    />
                                </sui-table-cell>
                                <sui-table-cell>
                                    <sui-button 
                                        v-if="worker.FirebaseUID && firebaseUser.Roles.includes(UserRoles.REVOKE_USER_SESSIONS)" 
                                        @click.stop="dataRevokeUserRefreshToken(worker.FirebaseUID)" 
                                        negative size="mini" 
                                        :title="`Log ud overalt${worker.TokenLastRevoked ? '\nSidst smidt af '+toUserFriendlyTimestamp(worker.TokenLastRevoked) : 'Aldrig før smidt af'}`">Log ud overalt
                                    </sui-button>
                                </sui-table-cell>
                            </sui-table-row>
                        </sui-table-body>
                    </sui-table>
                    <div v-else>
                        <TableLoader />
                    </div>
                </DataCard>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TableLoader from '../../components/TableLoader'
import TableWorker from '../../components/Worker/TableWorker'
import { Mixin } from '../../lib/Mixins/mixin'
import { DataAPI } from '../../lib/DataAPI'
import { db } from '../../firebase'
import EditWorkerModal from '../../components/Worker/EditWorkerModal.vue'
import EventBus from '../../EventBus'
import UserRoles from '../../lib/Enums/UserRoles'

export default {
    name: 'WorkerList',
    mixins: [Mixin, DataAPI, EventBus],

    enums: {
        UserRoles
    },

    components: {
        TableLoader,
        TableWorker,
        EditWorkerModal
    },

    data() {
   
        return {
            firebaseUsers: [],
            addWorkerModalOpen: false,
            searchFilterValue: '',
        }
    },

    computed: {
        ...mapGetters({
            rawProject: 'activeProject'
        }),

        user() {
            return this.$root.$children[0].user
        },

        firebaseUser() {
            return this.$root.$children[0].firebaseUser
        },

        workers() {
            if (this.firebaseUsers.length == 0) return []
            const initialValue = {}
            return this.firebaseUsers.reduce((obj, item) => {
                return {
                    ...obj,
                    [String(item['Email']).toLowerCase()]: item,
                }
            }, initialValue)
        },

        filteredWorkers() {
            let filteredWorkers = this.cloneJson(this.firebaseUsers)

            if (this.searchFilterValue){
                filteredWorkers = filteredWorkers.filter((worker) => {
                    return this.matchSearchCriteria(worker)
                })
            }
            return filteredWorkers
        }

    },

    methods: {
        generateStyleColorsFromName(name) {
            const imageData = this.generateUserProfileImageFromName(name)
            return {
                backgroundColor: imageData.bgColor,
                color: imageData.fgColor
            }
        },

        formatContacts() {
            if (this.rawProject.Contacts && this.rawProject.Contacts.length) {
                for (const i in this.rawProject.Contacts) {
                    this.rawProject.Contacts[i].Company.get()
                        .then(res => {
                            this.rawProject.Contacts[i].CompanyData = res.data()
                        })
                }
            }
        },

        openWorkerDetails(worker) {
            this.$router.push(`workers/${worker.Initials}`)
        }
    },

    async created() {
        EventBus.$emit('function-activity', {functionName: 'Workers_created', isActive: true})
        this.$bind('firebaseUsers', db.collection("Users"));

        EventBus.$on('add-worker-button-click', () => {
            this.addWorkerModalOpen = true
        })

        EventBus.$on('edit-worker-modal-closing', () => {
            this.addWorkerModalOpen = false
        })
        EventBus.$emit('function-activity', {functionName: 'Workers_created', isActive: false})
    },

    watch: {
        'project': 'update',
        'rawProject': 'formatContacts'
    },

    beforeMount() {
        EventBus.$emit('update-project-background-color','') // Reset background color
        EventBus.$on('top-search-type', (payload) => {
            this.searchFilterValue = String(payload).toLowerCase()
            if (!payload) this.searchFilterValue = ''
        })
    },
}
</script>
<style scoped>
.workers-table td {
    padding-left: 1.5rem !important;
}
.table tr td {
    cursor: pointer;
}
</style>
