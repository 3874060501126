<template>
  <sui-modal
        small
        :open="isOpen"
        v-on:clickAwayModal="closeModal()"
    >
        <sui-modal-header>
            Udtræk til fakturering
        </sui-modal-header>
        <sui-modal-content scrolling>
            <sui-form>
                <sui-form-fields>
                    <sui-form-field title="Vælg evt et gemt pre-set i stedet for at skulle udfylde formularen hver gang">
                        <label>Pre-set</label>
                        <sui-dropdown
                            placeholder="Pre-set"
                            selection
                            :options="settingPresetOptions"
                            v-model="selectedPreset"
                        />
                    </sui-form-field>
                    <sui-form-field title='Vælg de enheder der skal inkluderes i teksten\nVed ingen valgt inkludéres alle'>
                        <label>Inkludér enheder:</label>
                        <sui-dropdown
                            multiple
                            selection
                            search
                            :options="includedUnitsOptions"
                            v-model="settings.includedUnits"
                        />
                    </sui-form-field>
                    <sui-form-field title="Vælg den enhed der skal vises efter mængden af enhedsarbejde">
                        <label>Enhed</label>
                        <sui-dropdown
                            selection
                            :options="unitunitOptions"
                            v-model="settings.unitunit"
                        />
                    </sui-form-field>
                </sui-form-fields>
                <sui-form-fields>
                    <sui-form-field title="Inkludér kun enhder der er godkendt">
                        <label>Kun godkendte</label>
                        <sui-checkbox toggle v-model="settings.filterBilled"/>
                    </sui-form-field>
                    <sui-form-field title="Antallet af bindestreger på linjerne imellem hver enhed">
                        <label>Skille-linjer</label>
                        <sui-input type="number" min="0" max="99" step="1" v-model="settings.numberOfLines"/>
                    </sui-form-field>
                    <sui-form-field title="Vis time-baserede enheder som timer og minutter i stedet for decimaltal">
                        <label>Vis minutter</label>
                        <sui-checkbox toggle v-model="settings.amountAsHhMm"/>
                    </sui-form-field>
                    <sui-form-field title="Vis time-baserede enheder som timer, minutter og sekunder">
                        <label>Vis sekunder</label>
                        <sui-checkbox toggle v-model="settings.includeSeconds"/>
                    </sui-form-field>
                    <sui-form-field :title="settingPresetChanged ? 'Der er ændringer der ikke er gemt' : 'Ingen ændringer at gemme'">
                        <label>Gem som pre-set:</label>
                        <sui-button positive @click.prevent="saveSettings" :disabled="!settingPresetChanged">Gem indstillinger</sui-button>
                    </sui-form-field>
                    <sui-form-field>
                        <label>Tilvarende timer i alt</label>
                        <input readonly v-model="equivalentHoursSum"/>
                    </sui-form-field>
                </sui-form-fields>
                <sui-form-field>
                    <textarea
                        readonly
                        v-model="outputText"
                    />
                </sui-form-field>
            </sui-form>
        </sui-modal-content>
  </sui-modal>
</template>

<script>
import { Mixin } from '../../lib/Mixins/mixin'
import { DateMixin } from '../../lib/Mixins/dateMixin'
import { DataAPI } from '../../lib/DataAPI'
import EventBus from '../../EventBus'
import swal from 'sweetalert'
import { db } from '../../firebase'

export default {
    name: 'UnitWorkOutputNoteModal',

    mixins: [Mixin, DateMixin, DataAPI],

    props: {
        isOpen: {
            type: Boolean,
            requred: true,
            default: false,
        },
        unitWorkUnitsInput: {
            type: Array,
            required: true,
        },
        unitWorkCompositeUnitsInput: {
            type: Array,
            required: true,
        },
        unitWork: {
            type: Array,
            required: true,
        }
    },

    data() {
        return {
            settingPresets: [],
            selectedPreset: JSON.stringify({}),

            unitunitOptions: [
                {value: 'NULL', text: 'Ingen antal'},
                {value: '', text: 'Ingen enhed' },
                {value: 'unitunit', text: 't, stk, m, ...'},
                {value: 'unitname', text: 'Enhedsnavn'},
                {value: 'equivalent', text: 'Tilsvarende timer'},
            ],

            settings: {
                includedUnits: [],
                numberOfLines: 20,
                unitunit: '',
                amountAsHhMm: false,
                includeSeconds: false,
                filterBilled: false,
            }
        }
    },

    computed: {
        filteredUnitWork(){
            // this.settings.includedUnits.length ? this.settings.includedUnits.includes(uw.Unit.Id) : true
            return this.unitWork.filter(uw => {
                if (this.settings.includedUnits.length) {
                    if (!this.settings.includedUnits.includes(uw.Unit.Id)) return false
                }
                if (this.settings.filterBilled) {
                    return (uw.Billed && uw.Billed.Bool)
                }
                return true
            })
        },
        equivalentHoursSum(){
            let sum = this.filteredUnitWork.reduce((sum, uw) => {
                return sum += uw.EquivalentHours
            }, 0)
            return this.formatAmount(sum)
        },
        formattedUnitWork(){
            let output = []
            for (let uw of this.filteredUnitWork) {
                let uwString = `${this.toUserFriendlyDate(uw.Date)}\n${uw.ConfigurationItem ? (uw.ConfigurationItem.cabinet ? uw.ConfigurationItem.Label + ' (' + uw.ConfigurationItem.Cabinet.name + ')' : uw.ConfigurationItem.Label) : 'Ingen installation'}\n${uw.Description || uw.Unit.Name}${this.shownAmount(uw) ? '\n' : ''}${this.shownAmount(uw)} ${this.shownUnit(uw)}`
                output.push(uwString)
            }
            return output
        },
        outputText(){
            return this.formattedUnitWork.join(`\n${'-'.repeat(this.settings.numberOfLines)}\n`)
        },
        includedUnitsOptions(){
            let options = []
            for (let unit of this.unitWorkUnitsInput) {
                options.push({value: unit.id, text: `${unit.id} - ${unit.Name}`})
            }
            for (let unit of this.unitWorkCompositeUnitsInput) {
                options.push({value: unit.id, text: `${unit.id} - ${unit.Name}`})
            }
            return options
        },
        settingPresetOptions(){
            let optionsArr = [{text: 'Ikke gemt preset', value: JSON.stringify({})}]
            for (let preset of this.settingPresets) {
                optionsArr.push({
                    text: preset.id,
                    value: JSON.stringify(preset.settings)
                })
            }
            return optionsArr
        },
        settingPresetChanged(){
            return JSON.stringify(this.settings) != this.selectedPreset
        },
    },

    methods: {
        closeModal() {
            EventBus.$emit('unitwork-output-note-modal-close')
        },

        formatAmount(amountAsDecimal, forceDecimal = false) {
            if (!this.settings.amountAsHhMm || forceDecimal){
                return Math.ceil(amountAsDecimal * 100) / 100 //Round UP to two decimal places
            } else {
                return this.decimalToHourString(amountAsDecimal, this.settings.includeSeconds)
            }
        },

        shownAmount(unitWork){
            let amountIsHourly = unitWork.Unit.Unit == 't' //Boolean: true if amount is hours, false if amount is any other unit

            let amountAsDecimal = unitWork.Amount

            if (this.settings.unitunit == 'equivalent') { //If unit is set to 'equivalent' use equivalentHours instead of amount
                amountAsDecimal = unitWork.EquivalentHours
                amountIsHourly = true
            }

            if (this.settings.unitunit == 'NULL') { //If the unit is set to 'NULL', don't show any amount
                return ''
            }

            return this.formatAmount(amountAsDecimal, !amountIsHourly)
        },

        shownUnit(unitWork) {
            switch(this.settings.unitunit) {
                case 'unitunit':
                    return unitWork.Unit.Unit
                case 'unitname':
                    return unitWork.Unit.Name
                case 'equivalent':
                    return 't'
                case '':
                default: 
                    return ''
            }
        },

        saveSettings() {
            swal("Hvilket navn vil du gemme dit preset under?", {
                content: 'input',
                buttons: true,
            }).then((presetId) => {
                console.log(presetId)
                if (presetId.trim()) {
                    this.dataSaveUnitWorkOutputSettings(presetId, this.settings)
                }
            })
        },
    },

    beforeMount() {
        this.$bind('settingPresets', db.collection('UnitWorkOutputNoteSettings'))
    },

    watch: {
        'settings.amountAsHhMm': {
            immediate: false,
            handler(value) {
                if (!value) {
                    this.settings.includeSeconds = false
                }
            }
        },
        'settings.includeSeconds': {
            immediate: false,
            handler(value) {
                if (value) {
                    this.settings.amountAsHhMm = true
                }
            }
        },
        selectedPreset: {
            immediate: false,
            handler(preset) {
                if (preset == JSON.stringify({})) return null
                try {
                    let settingsObj = JSON.parse(preset)
                    this.settings = settingsObj
                } catch (error) {
                    swal('Fejl')
                    console.error(error)
                }
            }
        },
    },
}
</script>

<style scoped>

</style>