
class SMSTemplate {

    static APPOINTMENT_CONFIRMATION = 'AppointmentConfirmation'

    static APPOINTMENT_CANCELLED = 'AppointmentCancelled'

}

export default SMSTemplate
