import EventBus from "../../EventBus.js"
import EKomplet from "../DataProviders/EKomplet.js"

const EKompletInstance = new EKomplet()

export const FitterHourMixin = {
    data() {
        return {
            internalOnlyCategories: ['ORLOV', 'FRI UDEN LØN', 'FRITVALG 1 DAG', 'FERIETIMER MED LØN - LÆRLINGE - FUNKTIONÆRER', 'FERIEDAG TIMELØNNEDE', 'TAKE BACK TIME', 'SYGDOM', 'AFSPADSERINGSTIMER', 'KURSUS/SKOLE', 'VAGT MAN-TORS ANTAL DAGE', 'VAGT FRE-SØN ANTAL DAGE', 'VÆRKSTED', 'BARNS FØRSTE SYGEDAG', 'HELLIGDAG LÆRLING/FUNKTIONÆR', 'HELLIGDAG MONTØR', 'AFSPADSERINGSTIMER' ],
            nonEffectiveCategories: ['ORLOV', 'FRI UDEN LØN', 'FRITVALG 1 DAG', 'FERIETIMER MED LØN - LÆRLINGE - FUNKTIONÆRER', 'FERIEDAG TIMELØNNEDE', 'SYGDOM', 'BARNS FØRSTE SYGEDAG', 'HELLIGDAG LÆRLING/FUNKTIONÆR', 'HELLIGDAG MONTØR', 'TAKE BACK TIME', 'ARBEJDSDAG - TBT'],
            offCategories: ['ORLOV', 'FRI UDEN LØN', 'FRITVALG 1 DAG', 'FERIETIMER MED LØN - LÆRLINGE - FUNKTIONÆRER', 'FERIEDAG TIMELØNNEDE', 'SYGDOM', 'BARNS FØRSTE SYGEDAG', 'HELLIGDAG LÆRLING/FUNKTIONÆR', 'HELLIGDAG MONTØR'],
            
            fitterCategories: [], //TODO: Find and code a soultion where this data is shared between components (eg Vuex store)
            fitters: [],
            projects: [],
        }
    },
    methods: {
        estimateTbTFromEffectiveHours(effectiveHours, workDayCount = 8, allowExceedingMax = false) {
            let estimate = effectiveHours / 8.5 * 0.5 + (workDayCount * 0.25) //Half an hour for every 8.5 hour workday, plus 15 minutes for every workday for tbtWorkdays
            let roundedEstimate = Math.floor(estimate*2)/2 //Rounded down to nearest half hour
            // console.log(effectiveHours, workDayCount, roundedEstimate)
            if (allowExceedingMax){
                return roundedEstimate //Return the estimate, even if it exceeds the max
            } 
            return Math.min(roundedEstimate, workDayCount*0.75) //Max 3 hours per 4-day workweek
        },
        estimateTbTFromTotalsObject(totalsObject, expectedTBTWorkHours = 0) {
            if (!totalsObject?.total?.effectiveHours && !totalsObject?.total?.effectiveHours === 0) {
                console.error('No effective hours found in totals object')
                return 0
            }

            let workDayCount = 0
            for (let day of Object.keys(totalsObject)) { //Loop through all the days in the totals object
                const weekday = new Date(day).getDay() //Get the day of the week
                if (weekday > 0 && weekday < 5) {
                    workDayCount += 1 //Count the number of workdays, based on the day of the week
                }
            }

            const nonOvertimeHours = totalsObject.total.effectiveHours - totalsObject.total.overtimeHours
            let fullPeriodEstimate = this.estimateTbTFromEffectiveHours(nonOvertimeHours, workDayCount, false) //Estimate the tbt from the total effective hours, never exceeding the max
            // console.log(workDayCount, fullPeriodEstimate)
            
            let TBTWorkHourDeficit = expectedTBTWorkHours - totalsObject.total.tbtWorkday
            if (TBTWorkHourDeficit < 0) TBTWorkHourDeficit = 0 //The deficit kan never be negative - if it is, too much tbtWorkday was registered, caught seperately

            if (fullPeriodEstimate == workDayCount * 0.75) return fullPeriodEstimate - TBTWorkHourDeficit //If the estimate is equal to the max, return the estimate (no need to check for partial days), subtracting deficit in TBTWorkHours

            //Check for partial off-days
            let nonFullDaysMissingHours = [] //Array of all missing hours for non-full days
            let totalFlexHours = 0 //Sum of all hours beyond 8.5 hours per workday

            for (let day of Object.keys(totalsObject)) { //Loop through all the days in the totals object
                if (day == 'total') continue; //Skip the total
                // console.log(totalsObject, day)
                const weekday = new Date(day).getDay() //Get the day of the week
                if (weekday > 0 && weekday < 5) { //If the day is a mon-thurs
                    let overflexHours = Number(totalsObject[day].effectiveHours - totalsObject[day].overtimeHours - 8.5) //Calculate the non-overtime flexed in hours
                    // console.log(overflexHours)
                    if (overflexHours < 0) nonFullDaysMissingHours.push(overflexHours * -1) //If the day is not a full day, add it to the list of non-full days
                    if (overflexHours > 0) totalFlexHours += overflexHours //If the day is an overtime day, add it to overtime hours
                    // console.log(`Added to Overtime, new sum: ${totalFlexHours}`)
                } else {
                    if (totalsObject[day].effectiveHours > 0) totalFlexHours += (Number(totalsObject[day].effectiveHours)) //If the day is not a workday, add it to the list of overtime days
                    // console.log(`Added to Overtime, new sum: ${totalFlexHours} - Weekend day: ${day}`)
                }
            }

            // let fitterNameString = totalsObject.total.searchableFields[0]
            // console.log(fitterNameString, nonFullDaysMissingHours, totalFlexHours)

            let partialDayEstimate = workDayCount * 0.75 //Start with the max estimate
            for (let missingHours of nonFullDaysMissingHours.sort((a,b) => a-b)) { //Loop through all the non-full days, sorted by missing hours (least to most)
                if ( missingHours <= totalFlexHours ) { //If the missing hours are less than or equal to the overFlex hours
                    totalFlexHours -= missingHours //Remove the missing hours from the overFlex hours
                    continue; //Continue to the next missing hours, without subtracting from the partial day estimate
                } else {
                    partialDayEstimate -= 0.5 //Subtract 0.5 hours from the estimate TBT
                }
            }
            // if (nonFullDaysMissingHours.length != (6-partialDayEstimate)/0.5){
            //     console.log(nonFullDaysMissingHours.length,"!=",(6-partialDayEstimate)/0.5)
            // }
            return partialDayEstimate - TBTWorkHourDeficit
        },
        showTbTWarning(totalsObj, expectedTBTWorkHours) {
            if (!totalsObj.total.tbtWorkday && !totalsObj.total.takeBackTime) return false
            const estimate = this.estimateTbTFromTotalsObject(totalsObj, expectedTBTWorkHours)
            if (totalsObj.total.takeBackTime > estimate) {
                return 'Det ser ud til at der er skrevet for meget TBT'
            } else if (totalsObj.total.takeBackTime < estimate) {
                return 'Det ser ud til at der er skrevet for lidt TBT'
            }
            return false
        },
        showTbTWorkWarning(totalsObj, expectedTBTWorkHours) {
            if (totalsObj.total.tbtWorkday > expectedTBTWorkHours) {
                return `Det ser ud til at der er skrevet for meget arbejdsdag-TBT (skrevet: ${totalsObj.total.tbtWorkday},forventet: ${expectedTBTWorkHours})\nHvis der er arbejdet mere end planlagt skal dette skrives som lønnede timer.`
            }
            if (totalsObj.total.tbtWorkday < 0) {
                return `Der skal aldrig skrives negative timer her, men der er skrevet ${totalsObj.total.tbtWorkday}\nVed udeblivelse fra TBT-arbejdsdag skrives de negative time som TAKE BACK TIME`
            }
            return false
        },
        internalWarningGroup(groupKey){
            try {
                const splitGroup = groupKey.split(' // ')
                if (splitGroup.length != 2) {
                    throw new Error(`Invalid group name: ${groupKey}`)
                }
                if (splitGroup[0].toLowerCase().includes('intern')) {
                    return false;
                }
                if (this.internalOnlyCategories.includes(splitGroup[1].toUpperCase())) {
                    return true
                }
            } catch (error) {
                console.error(error)
                return false; //Fail silently
            }
        },
        showInternalWarning(totalsObj) {
            for (let group of Object.keys(totalsObj)) {
                if (group == 'total' || group == 'TOTAL') continue;
                if (this.internalWarningGroup(group)) return true;
            }
            return false
        },
        showBasisHoursWarning(totalsObj, weeksCount = 2) {
            if (totalsObj.TOTAL) {
                totalsObj = totalsObj.TOTAL
            }
            if (totalsObj.total) {
                totalsObj = totalsObj.total
            }
            const expectedBasisHours = Number(weeksCount * 37)
            const basis = Number(totalsObj.basisHours)
            const overtime = Number(totalsObj.overtimeHours)
            const TBTworkday = Number(totalsObj.tbtWorkday)
            const nonOvertimeHours = Number(basis - overtime - TBTworkday)
            const calculatedBasisHours = Number(expectedBasisHours + overtime + TBTworkday)

            if (nonOvertimeHours < expectedBasisHours) {
                let warningString = `Det ser ud til at der er skrevet ${calculatedBasisHours - basis} normaltimer for lidt:\n`
                if (overtime ||TBTworkday) {
                    warningString += `Med ${overtime} timers overarbejde, og ${TBTworkday} timers arbejdsfredag giver det ${calculatedBasisHours} normaltimer (${expectedBasisHours}+${overtime}+${TBTworkday})`
                } else {
                    warningString += `Der skal normalt skrives ${expectedBasisHours} timer på en ${weeksCount == 1 ? 'uge' : 'lønperiode'}`
                }
                warningString += `, men der er kun skrevet ${basis}t`
                return warningString
            }
            return false
        },
        async getFitterCategories() {
            if (!this.fitterCategories || !this.fitterCategories.length){
                EventBus.$emit('function-activity', {functionName: 'FitterHourMixin_getFitterCategories', isActive: true})
                try {
                    this.fitterCategories = await EKompletInstance.getFitterCategories()
                    // console.log(this.fitterCategories)
                } catch (error) {
                    EventBus.$emit('function-activity', {functionName: 'FitterHourMixin_getFitterCategories', isActive: false})
                    throw new Error(error)
                }
                EventBus.$emit('function-activity', {functionName: 'FitterHourMixin_getFitterCategories', isActive: false})
                return this.fitterCategories
            }
        },
        async getFitters() {
            if (!this.fitters || !this.fitters.length) {
                EventBus.$emit('function-activity', {functionName: 'FitterHourMixin_getFitters', isActive: true})
                try {
                    this.fitters = await EKompletInstance.getFitters()
                    // console.log(this.fitters)
                    EventBus.$emit('function-activity', {functionName: 'FitterHourMixin_getFitters', isActive: false})
                } catch (error) {
                    EventBus.$emit('function-activity', {functionName: 'FitterHourMixin_getFitters', isActive: false})
                    throw new Error(error)
                }
                return this.fitters
            }
        },
        async getProjects() {
            if (!this.projects ||!this.projects.length) {
                EventBus.$emit('function-activity', {functionName: 'FitterHourMixin_getProjects', isActive: true})
                try {
                    this.projects = await EKompletInstance.getProjects()
                } catch (error) {
                    EventBus.$emit('function-activity', {functionName: 'FitterHourMixin_getProjects', isActive: false})
                    throw new Error(error)
                }
                EventBus.$emit('function-activity', {functionName: 'FitterHourMixin_getProjects', isActive: false})
            }
        },
        async postFitterHours(data) {
            EventBus.$emit('function-activity', {functionName: 'FitterHourMixin_saveHour', isActive: true})
            let response = null
            try {
                response = await EKompletInstance.postFitterHours(data)
            } catch (error) {
                EventBus.$emit('function-activity', {functionName: 'FitterHourMixin_saveHour', isActive: false})
                throw new Error(error)
            }
            EventBus.$emit('function-activity', {functionName: 'FitterHourMixin_saveHour', isActive: false})
            return response
        }
    }
}