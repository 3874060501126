<template>
    <div>
        <div class="ui negative message">
            <div class="header">
                Warning!
            </div>
            <p>
                Modifies unitWork and makes many calls to Pilotbi service.
            </p>
        </div>

        <strong>Description:</strong>
        <p>
            Will loop through unitWork, and find serviceOrder based on <sui-label>ConfigurationItem.Label</sui-label>, to add the address to the unitWork
        </p>

        <hr />

        <sui-button primary size="tiny" :loading="isRunning" :disabled="isRunning" @click="triggerDry">Run Dry</sui-button>
        <sui-button negative size="tiny" :loading="isRunning" :disabled="isRunning" @click="triggerNormal">Run</sui-button>
    </div>
</template>
<script>
import { db } from '../../../firebase'
import { DataAPI } from '../../../lib/DataAPI'
import TaskCode from '../../../lib/Enums/TaskCode'

export default {
    name: 'UnitWorkToIncludeAddress',

    mixins: [DataAPI],

    enums: {TaskCode},

    data() {
        return {
            isRunning: false,
            dryRun: false,

            unitWork: [],
            installations: {},
        }
    },

    methods: {
        log(message) {
            this.$emit('log', message)
        },

        async triggerDry() {
            this.dryRun = true
            await this.trigger()
        },

        async triggerNormal() {
            this.dryRun = false
            await this.trigger()
        },

        async trigger() {
            this.isRunning = true
            this.log(`----------------`)
            this.log(`Starting process`)

            await db.collection('UnitWork').orderBy('Date', 'desc').get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    this.unitWork.push({
                        ...doc.data(),
                        id: doc.id
                    })
                })
            }).catch((error) => {
                this.log(`ERROR getting unitWork: ${error}`)
            })
            let unitWorkLength = this.unitWork.length
            this.log(`Got ${unitWorkLength} unitWorks from Firebase`)

            let counter = 0
            var errorCount = 0
            this.log(`-----Starting loop-----`)
            for (let uw of this.unitWork) {
                counter += 1
                this.log(`---UnitWork ${counter}/${unitWorkLength} id: ${uw.id}---`)
                if (!uw.ConfigurationItem || !uw.ConfigurationItem.Label) {
                    errorCount += 1
                    this.log(`Skipping unitWork with ID '${uw.id}' because its ConfigurationItem.Label is falsy'`)
                    continue;
                }
                if (uw.ConfigurationItem && uw.ConfigurationItem.Address) {
                    this.log(`Skipping unitWork with ID '${uw.id}' because it already has an address associated`)
                    continue;
                }
                let instLabel = uw.ConfigurationItem.Label
                var updateDoc = {}
                if (this.installations[instLabel]) {
                    this.log(`Already got installation ${instLabel}, using the same data`)
                    updateDoc = this.installations[instLabel]
                } else {
                    this.log(`Getting serviceOrders for installation ${instLabel}`)
                    let serviceOrders = await this.dataGetAllServiceOrdersByInst(instLabel, true)
                    this.log(`Got ${serviceOrders.length} serviceOrders for installation ${instLabel}`)
                    let soWithTasks = serviceOrders.find((so) => so.project && so.project.tasks && so.project.tasks.length)
                    if (!soWithTasks) {
                        errorCount += 1
                        this.log(`ERROR: Found no serviceOrders with tasks for installation ${instLabel}, skipping`)
                        continue;
                    }
                    let firstRelevantTask = soWithTasks.project.tasks.find(t => TaskCode.AllArray.includes(t.code))
                    if (!firstRelevantTask) {
                        errorCount += 1
                        this.log(`ERROR: Found no relevant tasks on serviceOrder for installation ${instLabel}`)
                        continue;
                    }
                    let fullTask = await this.dataGetProjectTaskWithoutNotes(firstRelevantTask.id)
                    this.log(`Got full task for inst ${instLabel} from API`)
                    updateDoc = {
                        'ConfigurationItem.Address': this.formatAddress(fullTask.configurationItem.address, false),
                        ReferenceId: fullTask.referenceId
                    }
                    this.installations[instLabel] = updateDoc
                }
                if (this.dryRun) {
                    this.log(`DRY: Would have updated unitWork ${uw.id} with data: ${JSON.stringify(updateDoc)}`)
                } else {

                    await db.collection('UnitWork').doc(uw.id).update(updateDoc).then(() => {
                        this.log(`Successfully updated unitWork ${uw.id} with data: ${JSON.stringify(updateDoc)}`)
                    }).catch((error) => {
                        errorCount += 1
                        this.log(`ERROR updating unitWork: ${error}`)
                    })
                }
            }

            this.log('----------------')
            this.log(`FINISHED looping through ${counter}/${unitWorkLength} unitWork with ${errorCount} errors.`)
            this.log('----------------')
            this.isRunning = false
        },
    }
}
</script>