// import EventBus from "../../EventBus.js";
// import TaskState from "../Enums/TaskState.js"
// import TaskCode from "../Enums/TaskCode.js";
// import TicketState from "../Enums/TicketState.js"
// import AppointmentType from "../Enums/AppointmentType.js"
// import { phoneMixin } from "./phoneMixin.js";
import { db, storageRef } from '../../../firebase'
import FormToPdfComponents from '../../Enums/FormToPdfComponents'

export const JSON2HTML = {

    mixins: [
        db,
        storageRef,
    ],

    enums: {
        FormToPdfComponents,
    },

    data() {
        return {
            KEY_LENGTH: 8,

            GLOBAL_PROJECT_ID: "global",
        }
    },

    computed: {
        firebaseUser() {
            return this.$root.$children[0].firebaseUser
        },
    },

    methods: {
        /*
        ======================================================================================================
        SIMPLE FORM ELEMENTS

        These are simple form elements that can be added to a form.
        These elements will be used as building blocks for more complex form components.
        The elements do not have any data manipulation methods.
        They are only used for rendering the form.
        ======================================================================================================
        */

        /**
         * Adds a space element to a form.
         * 
         * @returns {Object} An object representing the space element.
         */
        addSpace(){
            return {
                methodName: "addSpace",
                formElementType: "space",
            }
        },

        /**
         * Adds a simple text input field with the specified title and placeholder.
         * 
         * @param {Object} data - The data object for the text field.
         * @returns {Object} An object representing the form field element.
         */
        addFormField(data = {}){
            const {title, placeholder, value, required = false} = data
            return {
                id: this.generateAlphanumericKey(this.KEY_LENGTH),
                methodName: "addFormField",
                title: title,
                placeholder: placeholder,
                formElementType: "field",
                value: value,
                required: required,
            }
        },

        /**
         * Adds a simple description to a form with the specified title and placeholder.
         * 
         * @param {Object} data - The data object for the description field.
         * @returns {Object} An object representing the form field.
         */
        addText(data = {}){
            const {title, placeholder, value, required = false} = data
            return {
                id: this.generateAlphanumericKey(this.KEY_LENGTH),
                methodName: "addText",
                title: title,
                placeholder: placeholder,
                formElementType: "text",
                value: value,
                required: required,
            }
        },

        /**
         * Adds a simple short description to a form with the specified title and placeholder.
         * 
         * @param {Object} data - The data object for the short description field.
         * @returns {Object} An object representing the form field.
         */
        addShortText(data = {}){
            const {title, placeholder, value, required = false} = data
            return {
                id: this.generateAlphanumericKey(this.KEY_LENGTH),
                methodName: "addShortText",
                title: title,
                placeholder: placeholder,
                formElementType: "shortText",
                value: value,
                required: required,
            }
        },

        /**
         * Adds a single dropdown/select element to a form with the specified title and options.
         * 
         * @param {Object} data - The data object for the dropdown field.
         * @returns {Object} An object representing the single dropdown element.
         */
        addSingleDropdown(data = {}){
            const {title, options, value, required = false} = data
            return {
                id: this.generateAlphanumericKey(this.KEY_LENGTH),
                methodName: "addSingleDropdown",
                title: title,
                formElementType: "dropdownSingle",
                value: value,
                options: options,
                required: required,
            }
        },

        /**
         * Adds a single dropdown/select element with multiselect to a form with the specified title and options.
         * 
         * @param {Object} data - The data object for the dropdown field.
         * @returns {Object} An object representing the single dropdown element.
         */
        addMultiDropdown(data = {}){
            const {title, options, value, required = false} = data
            return {
                id: this.generateAlphanumericKey(this.KEY_LENGTH),
                methodName: "addMultiDropdown",
                title: title,
                type: "dropdownMultiple",
                value: value,
                options: options,
                required: required,
            }
        },

        /**
         * Adds a single checkbox element to a form with the specified title.
         *  
         * @param {Object} data - The data object for the checkbox field.
         * @returns {Object} An object representing the checkbox element.
         */
        addCheckbox(data = {}){
            const {title, value, required = false} = data
            return {
                id: this.generateAlphanumericKey(this.KEY_LENGTH),
                methodName: "addCheckbox",
                title: title,
                formElementType: "checkbox",
                value: value,
                required: required,
            }
        },

        /**
         * Adds a single radio button element to a form with the specified title and options.
         * 
         * @param {Object} data - The data object for the radio button field.
         * @returns {Object} An object representing the radio button element.
         */
        addRadio(data = {}){
            const {title, options, value, required = false} = data
            return {
                id: this.generateAlphanumericKey(this.KEY_LENGTH),
                methodName: "addRadio",
                title: title,
                formElementType: "radio",
                value: value,
                options: options,
                required: required,
            }
        },

        /**
         * Adds a message element to a form with a specified title and message.
         * 
         * @param {Object} data - The data object for the message field.
         * @returns {Object} An object representing the message element.
         */
        addMessage(data = {}){
            const {title, messageBody, bullets = []} = data
            return {
                id: this.generateAlphanumericKey(this.KEY_LENGTH),
                methodName: "addMessage",
                title: title,
                formElementType: "message",
                text: messageBody,
                bullets: bullets,
            }
        },

        /**
         * Adds an array of images to a form with the specified title.
         * 
         * @param {Object} data - The data object for the image array field.
         * @returns {Object} An object representing the image array.
         */
        addImageArray(data = {}){
            const {title, value = null, required = false} = data
            return {
                id: this.generateAlphanumericKey(this.KEY_LENGTH),
                methodName: "addImageArray",
                title: title,
                formElementType: "imageArray",
                value: value, // Array of image objects
                required: required,
            }
        },

        /**
         * Adds a single image to an imageArray in a form.
         * Also genereated the imageURL for the image.
         * 
         * @param {Object} imageArray - The imageArray object to add the image to.
         * @param {String} imagePath - The path to the image in storage.
         * @returns {Object} The updated imageArray object.
         */
        async addImage(imageArray, imagePath, options = {}){
            const {imageText = '', renderingOption = 'largeImage', includeInPdf = true} = options
            if (imageArray.value === null){
                imageArray.value = new Array()
            }

            let image = {
                imagePath: imagePath,
                imageURL: '',
                imageText: imageText,
                imageFound: true,
                renderingOption: renderingOption, 
                includeInPdf: includeInPdf,
            }

            let imageURL = null
            try {
                imageURL = await this.getImageURL(imagePath)
            } catch (error) {
                image.imageFound = false
                try {
                    imageURL = await this.getImageURL('FormToPdfAssets/Placeholder_view_vector.jpg')
                } catch (error) {
                    console.error("Error getting image URL: ", error)
                }
                console.error("Error getting image URL: ", error)
            }

            image.imageURL = imageURL
            console.log("image", image)
            imageArray.value.push(image)
        },

        /**
         * Adds a signature field to a form with the specified title.
         * It doen't need to show up in the form as such, but it's a placeholder for the signature in the PDF.
         * It tells the logic that a signature is needed.
         * 
         * NOTE: This is Copilot generated code, and only a placeholder for the signature field.
         * 
         * @param {Object} data - The data object for the signature field.
         * @returns {Object} An object representing the signature field.
         */
        addSignature(data = {}){
            const {title, value, required = false} = data
            return {
                id: this.generateAlphanumericKey(this.KEY_LENGTH),
                method: "addSignature",
                title: title,
                formElementType: "signature",
                value: value,
                required: required,
            }
        },

        /*
        =================================================================================================
        DOUCMENT TITLE COMPONENT
        =================================================================================================
        */

        /**
         * 
         * 
         */
        addFieldsToDocumentTitleComponent(component){
            component.fields = {
                documentTitle: this.addFormField({
                    title: "Navn på formularen",
                    placeholder: "Indtast navn på formularen",
                    value: component.data.text,
                    required: component.required,
                })
            }
            component.keys = ['documentTitle']
        },

        /**
         * Adds a form header component to a form with the specified title.
         * 
         * This method adds a group without a group header.
         * Then adds a formField 
         * 
         * @param {Object} form - The form object to add the form header to.
         * @returns {Object} An object representing the form header component.
         */
        createDocumentTitleComponent(optionalInput = {}){
            const {
                documentTitle = '', 
            } = optionalInput

            let newComponent = {
                componentType: FormToPdfComponents.DOCUMENT_TITLE,
                id: this.generateAlphanumericKey(this.KEY_LENGTH),
                pageBreakBefore: false,
                includeInPdf: true,
                includeInForm: true,
                readOnly: false,
                required: true,
                data: {
                    text: documentTitle,
                    headerHierachy: 'title',
                },
            }

            this.addFieldsToDocumentTitleComponent(newComponent)
            return newComponent
        },

        /**
         * 
         * 
         */
        convertDocumentTitleToData(component, deleteFields = true){

            // Set data values from form fields
            component.data.text = component.fields.documentTitle.value

            // Remove fields and keys
            if (deleteFields){
                delete component.fields
                delete component.keys
            }

            return component
        },

        /*
        =================================================================================================
        HEADER COMPONENT
        =================================================================================================
        */

        /**
         * 
         * 
         */
        addFieldsToHeaderComponent(component){
            component.fields = {
                headerText: this.addFormField({
                    title: component.data.fieldTitle,
                    placeholder: component.data.fieldPlaceholder,
                    value: component.data.text,
                    required: component.required,
                })
            }
            component.keys = ['headerText']
        },

        /**
         * 
         * 
         */
        createHeaderComponent(optionalInput = {}){
            const {
                headerText = '', 
                headerHierachy = 'subheader',
                fieldTitle = '',
                fieldPlaceholder = '',
                pageBreakBefore = false,
                includeInPdf = true,
                includeInForm = true,
                readOnly = false,
                required = false,
            } = optionalInput

            let newComponent = {
                componentType: FormToPdfComponents.HEADER,
                id: this.generateAlphanumericKey(this.KEY_LENGTH),
                pageBreakBefore: pageBreakBefore,
                includeInPdf: includeInPdf,
                includeInForm: includeInForm,
                readOnly: readOnly,
                required: required,
                data: {
                    text: headerText,
                    headerHierachy: headerHierachy,
                    fieldTitle: fieldTitle,
                    fieldPlaceholder: fieldPlaceholder,
                },

            }

            this.addFieldsToHeaderComponent(newComponent)
            return newComponent
        },

        convertHeaderToData(component, deleteFields = true){
            // Set data values from form fields
            component.data.text = component.fields.headerText.value

            // Remove fields and keys
            if (deleteFields){
                delete component.fields
                delete component.keys
            }

            return component
        },
        
        
        /*
        =================================================================================================
        DESCRIPTION COMPONENT
        =================================================================================================
        */

        /**
         * 
         * 
         */
        addFieldsToDescriptionComponent(component){
            let fieldArgs = {
                title: component.data.headerText,
                placeholder: component.data.fieldPlaceholder,
                value: component.data.bodyText,
                required: component.required,
            }

            if (component.isShortText){
                component.fields = {
                    description: this.addShortText(fieldArgs)
                }
            } else {
                component.fields = {
                    description: this.addText(fieldArgs)
                }
            }

            component.fields.space = this.addSpace()

            component.keys = ['description']
        },

        /**
         * 
         * 
         */
        createDescriptionComponent(optionalInput = {}){
            const {
                fieldTitle = '', 
                text = '', 
                fieldPlaceholder = '', 
                headerHierachy = 'subheader', 
                pageBreakBefore = false,
                includeInPdf = true,
                includeInForm = true,
                readOnly = false,
                required = false, 
                isShortText = false,
                showHeaderInPdf = true,
            } = optionalInput

            let newComponent = {
                componentType: FormToPdfComponents.DESCRIPTION,
                id: this.generateAlphanumericKey(this.KEY_LENGTH),
                pageBreakBefore: pageBreakBefore,
                includeInPdf: includeInPdf,
                includeInForm: includeInForm,
                readOnly: readOnly,
                required: required,
                showHeaderInPdf: showHeaderInPdf,
                isShortText: isShortText,
                data: {
                    headerText: fieldTitle,
                    headerHierachy: headerHierachy,
                    bodyText: text,
                    fieldPlaceholder: fieldPlaceholder,
                },
            }

            this.addFieldsToDescriptionComponent(newComponent)
            return newComponent
        },

        /**
         * 
         * 
         */
        convertDescriptionToData(component, deleteFields = true){
            // Set data values from form fields
            component.data.bodyText = component.fields.description.value

            // Remove fields and keys
            if (deleteFields){
                delete component.fields
                delete component.keys
            }

            return component
        },

        /*
        =================================================================================================
        YES NO LIST COMPONENT
        =================================================================================================
        */

        /**
         * 
         * 
         */
        addFieldsToYesNoListComponent(component){

            let yesNoListCount = 0
            component.fields = {space: this.addSpace()}
            component.keys = []
            for (let yesNoListItem of component.data.list){
                if (component.includeInForm && yesNoListItem.includeInForm){
                    let newKey = 'yesNoList' + yesNoListCount.toString().padStart(3, '0')
                    component.keys.push(newKey)

                    component.fields[newKey] = this.addCheckbox({
                        title: yesNoListItem.text,
                        value: yesNoListItem.value,
                        required: component.required || yesNoListItem.required,
                    })
                    yesNoListCount++;
                }
            }
            // component.keys.push('space')
        },

        /**
         * 
         * 
         */
        createYesNoListComponent(optionalInput = {}){
            const {
                list = [], 
                pageBreakBefore = false,
                includeInPdf = true,
                includeInForm = true,
                readOnly = false,
                required = false,
            } = optionalInput

            let newComponent = {
                componentType: FormToPdfComponents.YES_NO_LIST,
                id: this.generateAlphanumericKey(this.KEY_LENGTH),
                pageBreakBefore: pageBreakBefore,
                includeInPdf: includeInPdf,
                includeInForm: includeInForm,
                readOnly: readOnly,
                required: required,
                data: {
                    list: list, //{text: '', value: false, includeInPdf: true, includeInForm: true, reuqired: false}
                },
            }

            this.addFieldsToYesNoListComponent(newComponent)
            return newComponent
        },

        /**
         * 
         * 
         */
        convertYesNoListToData(component, deleteFields = true){
            let list = component.data.list
            for (let key of component.keys){
                if (key !== 'space'){
                    let listItem = list.find(item => item.text === component.fields[key].title)
                    listItem.value = component.fields[key].value
                }
            }

            // Remove fields and keys
            if (deleteFields){
                delete component.fields
                delete component.keys
            }

            return component
        },

        /*
        =================================================================================================
        IMAGE LIST COMPONENT
        =================================================================================================
        */

        addFieldsToImageListComponent(component){
            if (component.includeInForm){
                component.fields = {
                    images: this.addImageArray({
                        title: component.data.headerText,
                        value: component.data.images,
                        required: component.required,
                    }),
                }

                component.keys = ['images']
            }
        },

        createImageListComponent(optionalInput = {}){
            const {
                pageBreakBefore = false,
                includeInPdf = true,
                includeInForm = true,
                readOnly = false,
                required = false,
                fieldTitle = '',
                allowMultiple = true,
            } = optionalInput

            let newComponent = {
                componentType: FormToPdfComponents.IMAGE_LIST,
                id: this.generateAlphanumericKey(this.KEY_LENGTH),
                pageBreakBefore: pageBreakBefore,
                includeInPdf: includeInPdf,
                includeInForm: includeInForm,
                readOnly: readOnly,
                required: required,
                data: {
                    headerText: fieldTitle,
                    allowMultiple: allowMultiple,
                    images: null, //{imageURL: '', imageText: '', renderingOption: 'largeImageWithoutText', includeInPdf: true}
                },
            }

            this.addFieldsToImageListComponent(newComponent)
            return newComponent
        },

        convertImageListToData(component, deleteFields = true){
            component.data.images = component.fields.images.value

            // Remove fields and keys
            if (deleteFields){
                delete component.fields
                delete component.keys
            }

            return component
        },

        /*
        =================================================================================================
        PRINT SIGNATURE COMPONENT
        =================================================================================================
        */

        addFieldsToPrintSignatureComponent(component){
            component.fields = {
                signerName: this.addFormField({
                    title: "Underskrivers navn",
                    placeholder: "Indtast navn",
                    value: component.data.signerName,
                    required: component.required,
                }),
                signerEmail: this.addFormField({
                    title: "Underskrivers e-mail",
                    placeholder: "Indtast email",
                    value: component.data.signerEmail,
                    required: component.required,
                }),
                signerPhone: this.addFormField({
                    title: "Underskrivers tlf.",
                    placeholder: "Indtast telefon",
                    value: component.data.signerPhone,
                    required: component.required,
                }),
            }
            component.keys = ['signerName', 'signerEmail', 'signerPhone']
        },

        createPrintSignatureComponent(optionalInput = {}){
            const {
                pageBreakBefore = false,
                includeInPdf = true,
                includeInForm = true,
                readOnly = false,
                required = false,
                disclaimerText = '',
            } = optionalInput

            let newComponent = {
                componentType: FormToPdfComponents.PRINT_SIGNATURE,
                id: this.generateAlphanumericKey(this.KEY_LENGTH),
                pageBreakBefore: pageBreakBefore,
                includeInPdf: includeInPdf,
                includeInForm: includeInForm,
                readOnly: readOnly,
                required: required,
                data: {
                    signerName: '',
                    signerEmail: '',
                    signerPhone: '',
                    disclaimerText: disclaimerText,
                },
            }

            this.addFieldsToPrintSignatureComponent(newComponent)
            return newComponent
        },

        convertPrintSignatureToData(component, deleteFields = true){
            component.data.signerName = component.fields.signerName.value
            component.data.signerEmail = component.fields.signerEmail.value
            component.data.signerPhone = component.fields.signerPhone.value

            // Remove fields and keys
            if (deleteFields){
                delete component.fields
                delete component.keys
            }

            return component
        },

        /*
        =================================================================================================
        SINGLE CONTACT COMPONENT
        =================================================================================================
        */

        // TODO: make sure you can add a contact element dynamically

        /*
        =================================================================================================
        DUAL CONTACT COMPONENT
        =================================================================================================
        */

        /*
        =================================================================================================
        FORM COMMENTS COMPONENT
        =================================================================================================
        */


        /*
        =================================================================================================
        EFB CUSTOMER AND CONSULTANT COMPONENT
        =================================================================================================
        */

        /**
         * Method for adding the form fields for the EFB customer and consultant component.
         * 
         * 
         */
        addFieldsToEfbCustomerAndConsultantComponent(component){
            component.fields = {
                consultant: this.addSingleDropdown({
                    title: "Konsulent",
                    options: [],
                    value: component.data.consultantContact['Email'],
                    required: component.required,
                }),
                customerName: this.addFormField({
                    title: "Kundenavn",
                    placeholder: "Indtast kundenavn",
                    value: component.data.customerContact['Navn'],
                    required: component.required,
                }),
                customerAddress: this.addFormField({
                    title: "Kundeadresse",
                    placeholder: "Indtast kundeadresse",
                    value: component.data.customerContact['Adresse'],
                    required: component.required,
                }),
                customerPhone: this.addFormField({
                    title: "Kundetelefon",
                    placeholder: "Indtast kundetelefon",
                    value: component.data.customerContact['Tlf.'],
                    required: component.required,
                }),
                customerEmail: this.addFormField({
                    title: "Kundemail",
                    placeholder: "Indtast kundemail",
                    value: component.data.customerContact['Email'],
                    required: component.required,
                }),
            }
            component.keys = ['consultant','customerName', 'customerAddress', 'customerEmail','customerPhone']
        },

        /**
         * Method for prepopulating the EFB customer and consultant component.
         */
        async prepopulateEfbCustomerAndConsultantComponent(component){
            let options = await this.getAllUsersAsOptions()
            component.fields.consultant.options = options

            // If no consultant is selected, set the consultant to the current user
            if (component.data.consultantContact['Email'] === ""){
                component.fields.consultant.value = this.firebaseUser.Email
            } 
        },

        /**
         * Adds a EFB customer and consultant component to a form.
         * 
         * This method adds a group with a group header.
         * Then adds a single dropdown field for selecting a consultant.
         * Adds a formField for the customer name.
         * Adds a formField for the customer address.
         * Adds a formField for the customer phone number.
         * Adds a formField for the customer email.
         * 
         * @param {Object} form - The form object to add the EFB customer and consultant to.
         */
        async createEfbCustomerAndConsultantComponent(optionalInput = {}){
            const {
                pageBreakBefore = false,
                includeInPdf = true,
                includeInForm = true,
                readOnly = false,
                required = true,
                rightColumnWidth = 'auto',// 'auto' or '*' or number less than 400
            } = optionalInput

            let newComponent = {
                componentType: FormToPdfComponents.EFB_CUSTOMER_AND_CONSULTANT,
                id: this.generateAlphanumericKey(this.KEY_LENGTH),
                pageBreakBefore: pageBreakBefore,
                includeInPdf: includeInPdf,
                includeInForm: includeInForm,
                readOnly: readOnly,
                required: required,
                data: {
                    customerContact: {
                        headerText: "Kundeoplysninger",
                        'Navn': "",
                        'Adresse': "",
                        'Email': "",
                        'Tlf.': "",
                        renderedKeys: ['Navn', 'Adresse', 'Email', 'Tlf.'],
                    },
                    consultantContact: {
                        headerText: "Konsulentoplysninger",
                        'Navn': "",
                        'Adresse': "",
                        'Email': "",
                        'Tlf': "",
                        renderedKeys: ['Navn', 'Email', 'Tlf.'],
                    },
                    left: 'customerContact',
                    right: 'consultantContact',
                    rightColumnWidth: rightColumnWidth,
                },
            }

            this.addFieldsToEfbCustomerAndConsultantComponent(newComponent)
            await this.prepopulateEfbCustomerAndConsultantComponent(newComponent)

            return newComponent
        },

        /**
         * 
         * 
         */
        convertEdfCustomerAndConsultantToData(component, deleteFields = true){    
            // Set data values from form fields
            component.data.customerContact['Navn'] = component.fields.customerName.value
            component.data.customerContact['Adresse'] = component.fields.customerAddress.value
            component.data.customerContact['Email'] = component.fields.customerEmail.value
            component.data.customerContact['Tlf.'] = component.fields.customerPhone.value

            component.data.consultantContact['Email'] = component.fields.consultant.value
            component.data.consultantContact['Navn'] = component.fields.consultant.options.find(option => option.value === component.fields.consultant.value).text
            component.data.consultantContact['Tlf.'] = component.fields.consultant.options.find(option => option.value === component.fields.consultant.value).phone

            // Remove fields and keys
            if (deleteFields){
                delete component.fields
                delete component.keys
            }

            return component
        },


        /*
        =================================================================================================
        DATA MANIPULATION METHODS
        =================================================================================================
        */

        async getAllUsersAsOptions(){
            let users = await db.collection('Users').get()
            users = users.docs.map(doc => {
                const data = doc.data()
                return {
                    text: data.Name,
                    value: data.Email,
                    phone: data.Phone || '',
                }
            })
            return users
        },

        setConsultantData(obj){
            obj.data.name = obj.options.find(option => option.value === obj.value).text
            obj.data.email = obj.value
            obj.data.phone = obj.options.find(option => option.value === obj.value).phone
        },

        /*
        =================================================================================================
        GENERIC METHODS
        =================================================================================================
        */

        /**
         * Generates a random alphanumeric key of a specified length.
         * 
         * @param {number} length - The length of the key to generate.
         * @returns {String} The generated alphanumeric key.
         */
        generateAlphanumericKey(length) {
            let result = '';
            let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            let charactersLength = characters.length;
            for (let i = 0; i < length; i++) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            return result;
        },

        /** 
         * Method that converts an addComponent method to convert to firebase data method 
         * 
         * This method removed the 'add' in from of the componentType and adds 'ToData' at the end.
         * It also converts the first letter to lowercase.
         * 
         * @param {String} componentType - The type of component to add.
         * @returns {String} The name of the method to execute.
         */
        addMethodToDataMethod(componentType){
            let tmpStr = componentType.substring(3)
            return tmpStr.charAt(0).toLowerCase() + tmpStr.slice(1) + "ToData"
        },
        




        /*
        =================================================================================================
        METHODS FOR GENERATING FORMS
        =================================================================================================
        */


        /*
        =================================================================================================
        METHODS FOR GENERATING FORM TEMPLATES
        =================================================================================================
        */

        /**
         * Creates an empty group that can be added to a form template.
         * 
         * @param {Boolean} isAccordion - Whether the group is an accordion or not.
         * @param {Boolean} accordionDefaultOpen - Whether the accordion is open by default or not.
         * @param {String} groupHeaderText - The header text of the group.
         * @returns {Array} The updated array of form component groups.
         */
        createGroup(isAccordion, accordionDefaultOpen, groupHeaderText){
            return {
                id: this.generateAlphanumericKey(this.KEY_LENGTH),
                accordion: isAccordion,
                accordionOpen: accordionDefaultOpen,
                headerText: groupHeaderText,
                components: [],
            }
        },

        /**
         * Inserts a empty group at a specified index in a form template.
         * 
         * @param {Array} formComponentGroups - The array of form component groups.
         * @param {Number} groupIndex - The index of the group in the array.
         * @param {Boolean} isAccordion - Whether the group is an accordion or not.
         * @param {Boolean} accordionDefaultOpen - Whether the accordion is open by default or not.
         * @param {String} groupHeaderText - The header text of the group.
         * @returns {Array} The updated array of form component groups.
         */
        insertGroup(formComponentGroups, groupIndex, isAccordion, accordionDefaultOpen, groupHeaderText){
            formComponentGroups.splice(groupIndex, 0, this.createGroup(isAccordion, accordionDefaultOpen, groupHeaderText))
        },

        /**
         * Adds a component to a group in a form template.
         * 
         * @param {Array} formComponentGroups - The array of form component groups.
         * @param {Number} groupIndex - The index of the group in the array.
         * @param {Object} componentData - The data for the component to add.
         * @returns {Array} The updated array of form component groups.
         */
        async addComponent(formComponentGroups, groupIndex, componentData){
            if (typeof componentData.data === 'undefined' || componentData.data === null){
                componentData.data = {}
            }
            let component = await this.executeFunction(componentData.componentType, componentData.data)
            formComponentGroups[groupIndex].components.push(component)
        },

        /**
         * Inserts a component at a specified index in a specified group in a form template.
         * 
         * @param {Array} formComponentGroups - The array of form component groups.
         * @param {Number} groupIndex - The index of the group in the array.
         * @param {Number} componentIndex - The index of the component in the group.
         * @param {Object} componentData - The data for the component to add.
         * @returns {Array} The updated array of form component groups.
         */
        async insertComponent(formComponentGroups, groupIndex, componentIndex, componentData){
            if (typeof componentData.data === 'undefined' || componentData.data === null){
                componentData.data = {}
            }
            let component = await this.executeFunction(FormToPdfComponents.getCreateTemplateMethodFromCode(componentData.componentType), componentData.data)
            formComponentGroups[groupIndex].components.splice(componentIndex, 0, component)
        },

        /**
         * General method for moving up a group in a form template.
         * 
         * @param {Array} formComponentGroups - The array of form component groups.
         * @param {Number} groupIndex - The index of the group in the array.
         * @returns {Array} The updated array of form component groups.
         */
        moveGroupUp(formComponentGroups, groupIndex){
            if (groupIndex > 0){
                let temp = formComponentGroups[groupIndex]
                formComponentGroups[groupIndex] = formComponentGroups[groupIndex - 1]
                formComponentGroups[groupIndex - 1] = temp
            }
        },

        /**
         * General method for moving down a group in a form template.
         * 
         * @param {Array} formComponentGroups - The array of form component groups.
         * @param {Number} groupIndex - The index of the group in the array.
         * @returns {Array} The updated array of form component groups.
         */
        moveGroupDown(formComponentGroups, groupIndex){
            if (groupIndex < formComponentGroups.length - 1){
                let temp = formComponentGroups[groupIndex]
                formComponentGroups[groupIndex] = formComponentGroups[groupIndex + 1]
                formComponentGroups[groupIndex + 1] = temp
            }
        },

        /** 
         * General method for moving up a component in a group in a form template.
         * 
         * @param {Array} formComponentGroups - The array of form component groups.
         * @param {Number} groupIndex - The index of the group in the array.
         * @param {Number} componentIndex - The index of the component in the group.
         * @returns {Array} The updated array of form component groups.
         */
        moveComponentUp(formComponentGroups, groupIndex, componentIndex){
            if (componentIndex > 0){
                let temp = formComponentGroups[groupIndex].components[componentIndex]
                formComponentGroups[groupIndex].components[componentIndex] = formComponentGroups[groupIndex].components[componentIndex - 1]
                formComponentGroups[groupIndex].components[componentIndex - 1] = temp
            }
        },

        /**
         * General method for moving down a component in a group in a form template.
         * 
         * @param {Array} formComponentGroups - The array of form component groups.
         * @param {Number} groupIndex - The index of the group in the array.
         * @param {Number} componentIndex - The index of the component in the group.
         * @returns {Array} The updated array of form component groups.
         */
        moveComponentDown(formComponentGroups, groupIndex, componentIndex){
            if (componentIndex < formComponentGroups[groupIndex].components.length - 1){
                let temp = formComponentGroups[groupIndex].components[componentIndex]
                formComponentGroups[groupIndex].components[componentIndex] = formComponentGroups[groupIndex].components[componentIndex + 1]
                formComponentGroups[groupIndex].components[componentIndex + 1] = temp
            }
        },

        /**
         * General method for deleting a group from a form template.
         * 
         * @param {Array} formComponentGroups - The array of form component groups.
         * @param {Number} groupIndex - The index of the group in the array.
         * @returns {Array} The updated array of form component groups.
         */
        deleteGroup(formComponentGroups, groupIndex){
            formComponentGroups.splice(groupIndex, 1)
        },

        /**
         * General method for deleting a component from a group in a form template.
         * 
         * @param {Array} formComponentGroups - The array of form component groups.
         * @param {Number} groupIndex - The index of the group in the array.
         * @param {Number} componentIndex - The index of the component in the group.
         * @returns {Array} The updated array of form component groups.
         */
        deleteComponent(formComponentGroups, groupIndex, componentIndex){
            formComponentGroups[groupIndex].components.splice(componentIndex, 1)
        },

        /**
         * Method for generating the formComponentGroups object for a form template.
         * If a form template is not provided, an empty formComponentGroups object is returned.
         * If a form template is provided, the formComponentGroups object is generated based on the template.
         * If a form and a form template is provided, the formComponentGroups object is generated based on the template, but with the form data.
         */
        // async generateFormComponentGroups(formTemplate = null, form = null){
        //     let formComponentGroups = []

        //     if (!formTemplate){
        //         return formComponentGroups
        //     }

        //     let usedFormData = formTemplate
        //     if (form){
        //         usedFormData = form
        //     }

        //     for (let group of usedFormData.componentGroups){
        //         this.addGroup(formComponentGroups, group.accordion, group.accordionOpen, group.headerText)

        //         for (let component of group.components){
        //             await this.addComponent(formComponentGroups, formComponentGroups.length - 1, component)
        //         }
        //     }   
        //     return formComponentGroups
        // },

        /**
         * Method for generating a new form template.
         * This method creates a new form template.
         * It also includes a default group with a single text field.
         * This will be the name of the form.
         */
        async generateNewFormTemplate(){
            let formTemplate = {
                templateName: "",
                templateVersion: 1,
                dynamicPageBreak: true,
                formComponentGroups: [],
            }

            formTemplate.formComponentGroups.push(this.createGroup(false, true, ""))
            formTemplate.formComponentGroups[0].components.push(this.createDocumentTitleComponent())

            formTemplate.formComponentGroups.push(this.createGroup(true, true, "Ny tom gruppe"))

            console.log("Form template: ", formTemplate)
            return formTemplate
        },


        async getImageURL(imageName){
            let imageRef = await storageRef.child(imageName)
            let downloadURL = await imageRef.getDownloadURL()
            return downloadURL
        },

        generateFirestoreDocument(form, deleteFields = true){

            let tmpForm = this.cloneJson(form)

            for (let group of tmpForm.formComponentGroups){
                for (let component of group.components){
                    component = this.executeFunction(FormToPdfComponents.getGenerateFirestoreMethodFromCode(component.componentType), component, deleteFields)
                    if (component.componentType === FormToPdfComponents.DESCRIPTION){
                        console.log("Description component", component)
                    }
                }
            }

            return tmpForm
        },

        async addFormFieldsToFormDocument(form){
            for (let group of form.formComponentGroups){
                for (let component of group.components){
                    console.log("Adding form fields to component: ", component.componentType)
                    await this.executeFunction(FormToPdfComponents.getAddFormFieldMethodFromCode(component.componentType), component)
                    await this.executeFunction(FormToPdfComponents.getPrepoulateMethodFromCode(component.componentType), component)
                }
            }
        },

        updateFormState(form, state){
            form.state = state
            let timeStamp = new Date()
            timeStamp = timeStamp.toISOString()
            form.stateUpdatedAt = timeStamp
        },

        async getFirstImage(form){
            for (let group of form.formComponentGroups){
                console.log("Group")
                for (let component of group.components){
                    console.log("Component", component.componentType)
                    if (component.componentType == FormToPdfComponents.IMAGE_LIST){
                        console.log("Has images")
                        if (component.data.images){
                            return component.data.images[0].imageURL
                        }
                    }
                }
            }
            return await this.getImageURL('FormToPdfAssets/Placeholder_view_vector.jpg')
        },
    }
}
