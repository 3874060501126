class UserRoles {
    // Genereal permissions
    static USER = 'user'; // Used as before
    static HOUR_CHECK = 'hourCheck'; // Used as before
    static HOUR_CHECK_GLOBAL = 'hourCheckGlobal'; // TODO: Implement this
    static ILLNESS_STATISTICS = 'illnessStatistics'; // TODO: Implement this
    
    // Energi Fyn Bredbånd specific permissions
    static EFB_API_USER = 'EFBAPIUser'; // TODO: Implement this
    static EFB_UNITWORK_EDITOR = 'EFBUnitworkEditor'; // TODO: Implement this
    static EFB_UNITWORK_ADMIN = 'EFBUnitworkAdmin'; // TODO: Implement this
    static EFB_BYPASS_BUSINESS_RULES = 'EFBBypassBusinessRules'; // Used as before
    static EFB_PROJECT_EDITOR = 'EFBProjectEditor'; // Implemented
    
    // Admin permissions
    static USER_ADMIN = 'userAdmin'; // Used as before
    static SUPER_ADMIN = 'superAdmin'; // Used as before
    static REVOKE_USER_SESSIONS = 'revokeUserSessions'; // Implemented

    // Internal permissions
    static INTERNAL_PROJECTS = 'internalProjects'; // TODO: Implement this

    /**
     * General method to get the description of a role
     * 
     * @param {*} role Role to get description for
     * @returns {string} Description of the role
     */
    static getDescriptionFromRole = (role) => {
        switch (role) {
            case UserRoles.USER:
                return 'Bruger';
            case UserRoles.HOUR_CHECK:
                return 'Time-tjekker';
            case UserRoles.HOUR_CHECK_GLOBAL:
                return 'Time-tjekker (Global)';
            case UserRoles.ILLNESS_STATISTICS:
                return 'Sygefraværsstatistik';
            case UserRoles.EFB_API_USER:
                return 'EFB API Adgang';
            case UserRoles.EFB_UNITWORK_EDITOR:
                return 'EFB Enhedsarbejde Redaktør';
            case UserRoles.EFB_UNITWORK_ADMIN:
                return 'EFB Arbejdsenhed Admin';
            case UserRoles.EFB_BYPASS_BUSINESS_RULES:
                return 'EFB Business Rules Bypass';
            case UserRoles.EFB_PROJECT_EDITOR:
                return 'EFB Projektredaktør';
            case UserRoles.USER_ADMIN:
                return 'Bruger Admin';
            case UserRoles.SUPER_ADMIN:
                return 'Super Admin';
            case UserRoles.REVOKE_USER_SESSIONS:
                return 'Tilbagekald brugersessioner';
            case UserRoles.INTERNAL_PROJECTS:
                return 'Interne Projekter';
            default:
                return `Ukendt (${role})`;
        }
    }

    /**
     * 
     * @param {*} role Role to get title for
     * @returns {string} Label for the role
     */
    static getTitleFromRole = (role) => {
        switch  (role) {
            case UserRoles.USER:
                return 'Giver basis adgang til FiberTeam';
            case UserRoles.HOUR_CHECK:
                return 'Giver adgang til time-tjek';
            case UserRoles.HOUR_CHECK_GLOBAL:
                return 'Giver adgang til time-tjek på alle ressourcegrupper';
            case UserRoles.ILLNESS_STATISTICS:
                return 'Giver adgang til at se sygefraværsstatistik';
            case UserRoles.EFB_API_USER:
                return 'Giver adgang til at tilgå Energi Fyn data';
            case UserRoles.EFB_UNITWORK_EDITOR:
                return 'Giver adgang til at redigere Energi Fyn udført enhedsarbejde (Unitwork)';
            case UserRoles.EFB_UNITWORK_ADMIN:
                return 'Giver adgang til at administrere Energi Fyn arbejdsenheder (herunder at oprette og slette)';
            case UserRoles.EFB_BYPASS_BUSINESS_RULES:
                return 'Giver adgang til at omgå Energi Fyn forretningsregler';
            case UserRoles.EFB_PROJECT_EDITOR:
                return 'Giver adgang til at redigere Energi Fyn projekter';
            case UserRoles.USER_ADMIN:
                return 'Giver adgang til at administrere brugere i FiberTeam';
            case UserRoles.SUPER_ADMIN:
                return 'Giver adgang til at administrere alle aspekter af FiberTeam';
            case UserRoles.REVOKE_USER_SESSIONS:
                return 'Giver adgang til at tilbagekalde brugersessioner';
            case UserRoles.INTERNAL_PROJECTS:
                return 'Giver adgang til at se interne projekter';
            default:
                return `Ukendt (${role})`;
        }
    }

    /**
     * 
     * @param {*} role Role to get color for
     * @returns {string} Color for the role
     */
    static getColorFromRole = (role) => {
        switch (role) {
            case UserRoles.USER:
                return 'blue';
            case UserRoles.HOUR_CHECK:
                return 'green';
            case UserRoles.HOUR_CHECK_GLOBAL:
                return 'green';
            case UserRoles.ILLNESS_STATISTICS:
                return 'green';
            case UserRoles.EFB_API_USER:
                return 'purple';
            case UserRoles.EFB_UNITWORK_EDITOR:
                return 'orange';
            case UserRoles.EFB_UNITWORK_ADMIN:
                return 'orange';
            case UserRoles.EFB_BYPASS_BUSINESS_RULES:
                return 'orange';
            case UserRoles.EFB_PROJECT_EDITOR:
                return 'orange';
            case UserRoles.USER_ADMIN:
                return 'red';
            case UserRoles.SUPER_ADMIN:
                return 'red';
            case UserRoles.REVOKE_USER_SESSIONS:
                return 'red';
            case UserRoles.INTERNAL_PROJECTS:
                return 'green';
            default:
                return 'black';
        }
    }

    // Used for user creation form
    static DROPDOWN_OPTIONS_ALL = [
        {value: this.USER, text: this.getDescriptionFromRole(this.USER), title: this.getTitleFromRole(this.USER)},
        {value: this.HOUR_CHECK, text: this.getDescriptionFromRole(this.HOUR_CHECK), title: this.getTitleFromRole(this.HOUR_CHECK)},
        {value: this.HOUR_CHECK_GLOBAL, text: this.getDescriptionFromRole(this.HOUR_CHECK_GLOBAL), title: this.getTitleFromRole(this.HOUR_CHECK_GLOBAL)},
        {value: this.ILLNESS_STATISTICS, text: this.getDescriptionFromRole(this.ILLNESS_STATISTICS), title: this.getTitleFromRole(this.ILLNESS_STATISTICS)},
        {value: this.EFB_API_USER, text: this.getDescriptionFromRole(this.EFB_API_USER), title: this.getTitleFromRole(this.EFB_API_USER)},
        {value: this.EFB_UNITWORK_EDITOR, text: this.getDescriptionFromRole(this.EFB_UNITWORK_EDITOR), title: this.getTitleFromRole(this.EFB_UNITWORK_EDITOR)},
        {value: this.EFB_UNITWORK_ADMIN, text: this.getDescriptionFromRole(this.EFB_UNITWORK_ADMIN), title: this.getTitleFromRole(this.EFB_UNITWORK_ADMIN)},
        {value: this.EFB_BYPASS_BUSINESS_RULES, text: this.getDescriptionFromRole(this.EFB_BYPASS_BUSINESS_RULES), title: this.getTitleFromRole(this.EFB_BYPASS_BUSINESS_RULES)},
        {value: this.EFB_PROJECT_EDITOR, text: this.getDescriptionFromRole(this.EFB_PROJECT_EDITOR), title: this.getTitleFromRole(this.EFB_PROJECT_EDITOR)},
        {value: this.USER_ADMIN, text: this.getDescriptionFromRole(this.USER_ADMIN), title: this.getTitleFromRole(this.USER_ADMIN)},
        {value: this.SUPER_ADMIN, text: this.getDescriptionFromRole(this.SUPER_ADMIN), title: this.getTitleFromRole(this.SUPER_ADMIN)},
        {value: this.REVOKE_USER_SESSIONS, text: this.getDescriptionFromRole(this.REVOKE_USER_SESSIONS), title: this.getTitleFromRole(this.REVOKE_USER_SESSIONS)},
        {value: this.INTERNAL_PROJECTS, text: this.getDescriptionFromRole(this.INTERNAL_PROJECTS), title: this.getTitleFromRole(this.INTERNAL_PROJECTS)},
    ]

    static DROPDOWN_OPTIONS_USER_ADMIN = [
        {value: this.USER, text: this.getDescriptionFromRole(this.USER), title: this.getTitleFromRole(this.USER)},
        {value: this.HOUR_CHECK, text: this.getDescriptionFromRole(this.HOUR_CHECK), title: this.getTitleFromRole(this.HOUR_CHECK)},
        {value: this.ILLNESS_STATISTICS, text: this.getDescriptionFromRole(this.ILLNESS_STATISTICS), title: this.getTitleFromRole(this.ILLNESS_STATISTICS)},
        {value: this.EFB_API_USER, text: this.getDescriptionFromRole(this.EFB_API_USER), title: this.getTitleFromRole(this.EFB_API_USER)},
        {value: this.EFB_UNITWORK_EDITOR, text: this.getDescriptionFromRole(this.EFB_UNITWORK_EDITOR), title: this.getTitleFromRole(this.EFB_UNITWORK_EDITOR)},
        {value: this.EFB_UNITWORK_ADMIN, text: this.getDescriptionFromRole(this.EFB_UNITWORK_ADMIN), title: this.getTitleFromRole(this.EFB_UNITWORK_ADMIN)},
        {value: this.EFB_PROJECT_EDITOR, text: this.getDescriptionFromRole(this.EFB_PROJECT_EDITOR), title: this.getTitleFromRole(this.EFB_PROJECT_EDITOR)},
        {value: this.INTERNAL_PROJECTS, text: this.getDescriptionFromRole(this.INTERNAL_PROJECTS), title: this.getTitleFromRole(this.INTERNAL_PROJECTS)},
    ]

    // Used for project creation form
    static DROPDOWN_OPTIONS_DATA_PROVIDER = [
        {value: this.USER, text: this.getDescriptionFromRole(this.USER), title: this.getTitleFromRole(this.USER)},
        {value: this.EFB_API_USER, text: this.getDescriptionFromRole(this.EFB_API_USER), title: this.getTitleFromRole(this.EFB_API_USER)},
        {value: this.INTERNAL_PROJECTS, text: this.getDescriptionFromRole(this.INTERNAL_PROJECTS), title: this.getTitleFromRole(this.INTERNAL_PROJECTS)},
    ]
}

export default UserRoles